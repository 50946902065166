import React, { ReactElement, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { getCurrenLang } from '../../../redux/selector';
import styles from './styles.module.css';
import { EXERC_SUMMARY_COLORS } from '../../../utils/enums';

const IndividualExerciseItem = ({
  exercise,
}: {
  exercise: {
    timesPerDay: number;
    completed: number;
    skipped: number;
    stopped: number;
    name: string;
  };
}): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const completed = t?.dashboard.hcp.profile_patient.completion_and_steps.completed;
  const partly_done = t?.dashboard.hcp.profile_patient.completion_and_steps.partly_done;
  const skipped_t = t?.dashboard.hcp.profile_patient.completion_and_steps.skipped;
  const not_started = t?.dashboard.hcp.profile_patient.completion_and_steps.not_started;

  const exerciseChartItems = [
    {
      label: completed,
      color: EXERC_SUMMARY_COLORS.COMPLETED,
      status: 'completed',
    },
    {
      label: partly_done,
      color: EXERC_SUMMARY_COLORS.PARTLY_DONE,
      status: 'partlyDone',
    },
    {
      label: skipped_t,
      color: EXERC_SUMMARY_COLORS.SKIPPED,
      status: 'attempts',
    },
    {
      label: not_started,
      color: EXERC_SUMMARY_COLORS.NOT_STARTED,
      status: undefined,
    },
  ];

  const getItemColor = (exerciseStatus: { status: string }): EXERC_SUMMARY_COLORS | undefined => {
    return exerciseChartItems.find((item) => {
      let status = exerciseStatus?.status;
      if (status === 'noAttempt') {
        status = 'attempts';
      }
      return item.status === status;
    })?.color;
  };

  const getItem = (color: string, index: number, timesPerDay: number): JSX.Element => (
    <div
      style={{
        height: '15px',
        width: `${100 / timesPerDay}%`,
        overflow: 'hidden',
      }}
      key={`${String(index)}Item`}
    >
      <div
        style={{
          height: '15px',
          marginLeft: index > 0 ? 1 : 0,
          backgroundColor: color,
        }}
      />
    </div>
  );

  const renderAvaragesChartItems = useCallback(() => {
    const res = [];
    const statuses: any = [
      ...new Array(exercise.completed).fill('completed'),
      ...new Array(exercise.stopped).fill('partlyDone'),
      ...new Array(exercise.skipped).fill('attempts'),
    ];

    const { timesPerDay } = exercise;

    for (let i = 0; i < timesPerDay; i += 1) {
      const color: any = getItemColor({ status: statuses[i] });
      const item = getItem(color, i, timesPerDay);
      res.push(item);
    }

    return res;
  }, [exercise]);

  return (
    <div className={styles['individual-chart__completion-container']}>
      {renderAvaragesChartItems()}
    </div>
  );
};

export default IndividualExerciseItem;
