import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import Select from 'react-select';

import overviewStyles from '../styles.module.css';
import style from './style.module.css';
import { ChartCardProps } from '../types';
import {
  getCurrenLang,
  getHeartRateData,
  getMotivationData,
  getPainData,
  getSelectedPeriodOverview,
  getSleepData,
  GetState,
  getStepsData,
} from '../../../redux/selector';
import useCheckRole from '../../../hooks/useCheckRole';
import { CardTitle } from '../Elements';
import { ActivitiesIcon } from '../../../theme/icons';
import { tabsConfig } from '../../../utils/helper_charts';
import { CHART_TABS } from '../../../utils/enums';
import { overviewChartsPeriods } from '../../../utils/share/options';
import { dropdownIndicatorStyles, selectStyles } from '../../../common/Input/styles';
import { setSelectedPeriodOverviewTab } from '../../../redux/dropdown';
import {
  useGetExercisesCompletion,
  useGetHeartRate,
  useGetMotivationLevel,
  useGetPainLevel,
  useGetPatientSteps,
  useGetSleepData,
} from '../../../graphql/charts';
import { MotivationsChart } from '../../Charts/MotivationsChart';
import { Barchart } from '../../Charts/StepCount';
import { PainChart } from '../../Charts/PainChart';
import { HeartRateChart } from '../../Charts/HeartRateChart';
import { SleepChart } from '../../Charts/SleepChart';
import ExerciseCompletionContainer from '../../Charts/ExerciseCompletion/ExerciseCompletionContainer';
import {
  useGetPatientCompletedExercisesByPeriod,
  useGetPatientNotCompletedExercisesForGraphs,
} from '../../../graphql/exercises';
import PromsLayout from '../../Charts/Proms/PromsLayout';

const ChartCard = ({ patientId, procedureKey }: ChartCardProps): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const patients_data = t?.title.patients_data;
  const optionLabels = t?.dashboard.hcp.profile_patient.completion_and_steps;

  // const { isAdmin } = useCheckRole();
  const dispatch = useDispatch();
  const periodListOptions = overviewChartsPeriods(optionLabels);

  // Endpoints
  const { _getMotivationLevel } = useGetMotivationLevel();
  const { _getSteps } = useGetPatientSteps();
  const { _getPainLevel } = useGetPainLevel();
  const { _getSleepData } = useGetSleepData();
  const { _getHeartRate } = useGetHeartRate();
  const { _getExercCompletion } = useGetExercisesCompletion();
  const { _getNotCompletedExercises } = useGetPatientNotCompletedExercisesForGraphs();
  const { _getPatientCompletedExercisesByPeriod } = useGetPatientCompletedExercisesByPeriod();

  // Redux
  const selectedPeriod: any = useSelector((state: GetState) => getSelectedPeriodOverview(state));
  const painLevelData: any = useSelector(getPainData);
  const motivationData: any = useSelector(getMotivationData);
  const stepsData: any = useSelector(getStepsData);
  const sleepData: any = useSelector(getSleepData);
  const heartRateData: any = useSelector(getHeartRateData);

  // Local state
  const [categoryState, setCategoryState] = useState(CHART_TABS.exercise_completion);

  const onChangeTab = (key: CHART_TABS): void => {
    setCategoryState(key);
  };
  useEffect(() => {
    if (t) {
      dispatch(setSelectedPeriodOverviewTab(periodListOptions[0]));
    }
  }, [t]);

  // tab periods
  const handleChange = (tabValue: any): void => {
    dispatch(setSelectedPeriodOverviewTab(tabValue));
  };

  useEffect(() => {
    if (categoryState === CHART_TABS.exercise_completion && selectedPeriod) {
      _getPatientCompletedExercisesByPeriod({ patientId, selectedPeriod });
    }
  }, [categoryState, patientId, selectedPeriod]);

  useEffect(() => {
    switch (categoryState) {
      case CHART_TABS.exercise_completion:
        _getExercCompletion(patientId);
        _getNotCompletedExercises(patientId);
        break;
      case CHART_TABS.motivation:
        _getMotivationLevel(patientId);
        break;
      case CHART_TABS.step_count:
        _getSteps(patientId);
        break;
      case CHART_TABS.pain:
        _getPainLevel(patientId);

        break;
      case CHART_TABS.heart_rate:
        _getHeartRate(patientId);

        break;
      case CHART_TABS.sleep:
        _getSleepData(patientId);
        break;
      default:
        break;
    }
  }, [categoryState, patientId, procedureKey]);

  const chartJsx = useMemo(() => {
    switch (categoryState) {
      case CHART_TABS.exercise_completion:
        return <ExerciseCompletionContainer />;
      case CHART_TABS.motivation:
        return (
          <MotivationsChart
            motivationData={motivationData}
            isOverview
            type={CHART_TABS.motivation}
          />
        );
      case CHART_TABS.step_count:
        return <Barchart stepsData={stepsData} isOverview type={CHART_TABS.step_count} />;
      case CHART_TABS.pain:
        return (
          <PainChart
            painLevelData={painLevelData}
            procedureKey={procedureKey}
            type={CHART_TABS.pain}
            isOverview
          />
        );
      case CHART_TABS.heart_rate:
        return (
          <HeartRateChart heartRateData={heartRateData} isOverview type={CHART_TABS.heart_rate} />
        );
      case CHART_TABS.sleep:
        return <SleepChart sleepData={sleepData} isOverview type={CHART_TABS.sleep} />;
      case CHART_TABS.proms:
        return <PromsLayout patientId={patientId} procedureKey={procedureKey} />;
      default:
        return <></>;
    }
  }, [
    categoryState,
    patientId,
    procedureKey,
    painLevelData,
    motivationData,
    stepsData,
    sleepData,
    heartRateData,
  ]);

  return (
    <div
      className={cx({
        [overviewStyles['overview__card-container']]: true,
        [style['chart-card__container']]: true,
      })}
    >
      {/* Tabs */}
      <div className={style['chart-card__tabs-container']}>
        <CardTitle icon={<ActivitiesIcon />} title={patients_data} />
        <div className={style['chart-card__tabs']}>
          {tabsConfig(t).map((tab: { icon: JSX.Element; label: string; key: CHART_TABS }) => (
            <div
              key={tab.key}
              className={cx({
                [style['chart-card__tab-container']]: true,
                [style['chart-card__tab-container-active']]: tab.key === categoryState,
              })}
              aria-hidden
              onClick={(): void => onChangeTab(tab.key)}
            >
              <div className={style['chart-card__tab-icon']}>{tab.icon}</div>
              <div className={style['chart-card__tab-label']}>{tab.label}</div>
            </div>
          ))}
        </div>
      </div>
      {/* Content */}
      <div className={style['chart-card__content-container']}>
        {categoryState !== CHART_TABS.proms && (
          <div className={style['chart-card__select']}>
            <Select
              options={periodListOptions}
              styles={{ ...selectStyles, ...dropdownIndicatorStyles() }}
              value={selectedPeriod}
              onChange={handleChange}
              defaultValue={selectedPeriod}
              isSearchable={false}
            />
          </div>
        )}
        {chartJsx}
      </div>
    </div>
  );
};

export default ChartCard;
