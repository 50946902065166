import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';

import styles from './styles.module.css';
import { Button } from '../../common';
import { getCurrenLang } from '../../redux/selector';
import { CustomCheckbox } from '../../common/Input';
import { RewardsCheckboxesFormData, RewardsCheckboxesFormProps } from './types';

export const HospitalCheckboxesForm = ({
  data,
  onSave,
  loading,
}: RewardsCheckboxesFormProps): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const save = t?.common.save;

  // Initial form state
  const getInit = (dataSrc: any[]): any => {
    const init: any = {};
    dataSrc.map((option: RewardsCheckboxesFormData) => {
      init[option.id] = option.status;
      return null;
    });
    return init;
  };

  const formik = useFormik({
    initialValues: getInit(data),
    onSubmit: (values) => onSave(values),
  });

  const { values } = formik;

  // Check the selected checkbox, make the rest unchecked
  const selectOption = (e: any, id: number): void => {
    const currentEvent = e.target.checked;
    if (currentEvent) {
      if (values[id]) {
        return;
      }
      // If the checkbox is unchecked, then check it.
      formik.setFieldValue(String(id), currentEvent);
      // make the remaining checkboxes unchecked
      data.map((option: RewardsCheckboxesFormData) => {
        const itemId = option.id;
        if (itemId !== id) {
          formik.setFieldValue(String(itemId), false);
        }
        return null;
      });
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      {data.map((option: RewardsCheckboxesFormData) => (
        <div className={styles.reward_admin__row} key={option.name}>
          <CustomCheckbox
            htmlId={`rewards${option.id}`}
            name={String(option.id)}
            checked={formik.values[String(option.id)]}
            onChangeMethod={(e: any): void => selectOption(e, option.id)}
            isTouched={false}
            hasErrors={false}
            bigSize
          />
          <div className={styles.reward_admin__checkbox_name}>{option.name}</div>
        </div>
      ))}

      <Button
        buttonType="submit"
        buttonName={save}
        disabledButton={loading || !formik.dirty}
        buttonClass={styles.reward_admin__btn}
      />
    </form>
  );
};
