import { ITEMS_PER_PATIENT_PAGE } from '../../utils/variables';
import { NOTIF_STATUS } from '../../utils/enums';
import { TNotification } from '../../components/HeaderApp/types';
import { SearchPropsType } from '../../utils/model';
import { initialPage } from '../../utils/helper';

const SET_NOTIFICATIONS_STATUS = 'SET_NOTIFICATIONS_STATUS';
const SET_HCP_NOTIFICATIONS = 'SET_HCP_NOTIFICATIONS';
const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
const CHANGE_PAGE = 'CHANGE_PAGE';

const SET_PATIENT_NOTIFICATIONS_STATUS = 'SET_PATIENT_NOTIFICATIONS_STATUS';
const SET_PATIENT_NOTIFICATIONS = 'SET_PATIENT_NOTIFICATIONS';
const SET_ITEMS_PER_PAGE_CUMULATIVE_PATIENT = 'SET_ITEMS_PER_PAGE_CUMULATIVE_PATIENT';
const SET_SEARCH_NOTIFICATIONS = 'SET_SEARCH_NOTIFICATIONS';
const CLEAR_STATE = 'CLEAR_STATE';

interface ActionTypes {
  type: string;
  payload: any;
}
export const searchQueryInit = {
  searchField: 'title',
  searchValue: '',
};

export interface HcpNotificationsStateType {
  notificationsList: Array<TNotification>;
  notificationsStatus: string;
  countUnread: number;
  totalItems: number;
  currentPage: number;
  itemsPerPage: number;
  error: string;
  searchQuery: SearchPropsType;
  patientNotifications: {
    notificationsList: Array<TNotification>;
    notificationsStatus: string;
    countUnread: number;
    totalPages: number;
    totalItems: number;
    itemsPerPageCumulative: number;
  };
}

// Action creator
export const setNotificationsStatus = (data: string): ActionTypes => ({
  type: SET_NOTIFICATIONS_STATUS,
  payload: data,
});
export const setPatientNotificationsStatus = (data: string): ActionTypes => ({
  type: SET_PATIENT_NOTIFICATIONS_STATUS,
  payload: data,
});
export const setHcpNotifications = (payload: any) => ({
  type: SET_HCP_NOTIFICATIONS,
  payload,
});
export const onChangePage = (payload: any) => ({
  type: CHANGE_PAGE,
  payload,
});
export const setItemsPerPageCumulativeForPatient = (payload: any) => ({
  type: SET_ITEMS_PER_PAGE_CUMULATIVE_PATIENT,
  payload,
});
export const setPatientNotifications = (payload: any) => {
  const newList = {
    notificationsList: payload.list,
    countUnread: payload.totalUnread,
    totalPages: payload.totalPages,
    totalItems: payload.totalItems,
  };
  return {
    type: SET_PATIENT_NOTIFICATIONS,
    payload: newList,
  };
};
export const setNotificationsError = (payload: any) => ({
  type: SET_ERROR_MESSAGE,
  payload,
});
export const setSearchQuery = (data: SearchPropsType): ActionTypes => ({
  type: SET_SEARCH_NOTIFICATIONS,
  payload: data,
});
export const clearState = () => ({
  type: CLEAR_STATE,
});

// Reducer
const initialState: HcpNotificationsStateType = {
  notificationsList: [],
  notificationsStatus: NOTIF_STATUS.UNREAD,
  countUnread: 0,
  totalItems: 0,
  itemsPerPage: 15,
  currentPage: 1,
  error: '',
  searchQuery: { ...searchQueryInit },
  patientNotifications: {
    notificationsList: [],
    notificationsStatus: NOTIF_STATUS.UNREAD,
    countUnread: 0,
    totalPages: 0,
    totalItems: 0,
    itemsPerPageCumulative: ITEMS_PER_PATIENT_PAGE,
  },
};

export const globalNotificationsReduser = (
  state = initialState,
  { type, payload }: ActionTypes,
): HcpNotificationsStateType => {
  switch (type) {
    case SET_PATIENT_NOTIFICATIONS:
      return {
        ...state,
        patientNotifications: {
          ...state.patientNotifications,
          notificationsList: payload.notificationsList,
          countUnread: payload.countUnread,
          totalPages: payload.totalPages,
          totalItems: payload.totalItems,
        },
      };
    case SET_ITEMS_PER_PAGE_CUMULATIVE_PATIENT:
      return {
        ...state,
        patientNotifications: {
          ...state.patientNotifications,
          itemsPerPageCumulative: payload,
        },
      };
    case SET_ERROR_MESSAGE:
      return {
        ...state,
        error: payload,
      };
    case SET_PATIENT_NOTIFICATIONS_STATUS:
      return {
        ...state,
        patientNotifications: {
          ...state.patientNotifications,
          notificationsStatus: payload,
        },
      };
    case SET_HCP_NOTIFICATIONS:
      return {
        ...state,
        notificationsList: payload.list,
        countUnread: payload.totalUnread,
        totalItems: payload.totalItems,
      };
    case CHANGE_PAGE:
      return {
        ...state,
        itemsPerPage: payload.itemsPerPage,
        currentPage: payload.currentPage,
      };
    case SET_NOTIFICATIONS_STATUS:
      return {
        ...state,
        notificationsStatus: payload,
      };
    case SET_SEARCH_NOTIFICATIONS:
      return {
        ...state,
        searchQuery: payload,
      };
    case CLEAR_STATE:
      return {
        ...state,
        currentPage: initialPage,
        searchQuery: { ...searchQueryInit },
      };
    default:
      return state;
  }
};
