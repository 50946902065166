import React, { ReactElement } from 'react';
import cx from 'classnames';
import moment from 'moment';
import { sanitize } from 'dompurify';

import { getPatientAnswers } from '../../../../../utils/helper_charts';
import style from './style.module.css';
import { Answer, QuestionnaireContentProps, Question } from './types';
import { RemoveQuestionnaire } from './common/RemoveQuestionnaire';
import { CalculationDescription } from './common/Calculation';
import { PROMS_NAME } from '../../../../../utils/enums';

export const EqContent = ({
  questionnairesList,
  completedQuestionnaire,
  index,
  onDeleteItem,
  formatDate,
  t,
}: QuestionnaireContentProps): ReactElement => {
  const remove_questionnaire = t?.dashboard.hcp.profile_patient.questionnaires.remove_questionnaire;
  const your_health_today = t?.dashboard.hcp.profile_patient.questionnaires.your_health_today;
  const best_health = t?.dashboard.hcp.profile_patient.questionnaires.best_health;
  const worst_health = t?.dashboard.hcp.profile_patient.questionnaires.worst_health;
  const means_the_best = t?.dashboard.hcp.profile_patient.questionnaires.means_the_best;
  const means_the_worst = t?.dashboard.hcp.profile_patient.questionnaires.means_the_worst;
  const mark_an_x = t?.dashboard.hcp.profile_patient.questionnaires.mark_an_x;
  const please_write_number = t?.dashboard.hcp.profile_patient.questionnaires.please_write_number;
  const this_scale_is_numbered =
    t?.dashboard.hcp.profile_patient.questionnaires.this_scale_is_numbered;
  const code_for_the_impact = t?.dashboard.hcp.profile_patient.questionnaires.code_for_the_impact;
  const response_to_the_scale =
    t?.dashboard.hcp.profile_patient.questionnaires.response_to_the_scale;
  const under_each_heading = t?.dashboard.hcp.profile_patient.questionnaires.under_each_heading;

  const questionnaire_name = 'EQ-5D-5L';
  const { questions: completedQuestions, healthLevel, completedDate } = completedQuestionnaire;
  const patientAnswers = getPatientAnswers(completedQuestions);
  const eqList = questionnairesList?.filter((item: any) => item.name === PROMS_NAME.EQ5D5L)[0];
  const { questions } = eqList;
  const questionPage2Config = [
    questions[5].name,
    this_scale_is_numbered,
    means_the_best,
    mark_an_x,
    please_write_number,
  ];
  const onePxPerScale = 7.55;
  const bigScaleConfig = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const midScaleConfig = [1, 2];
  const smScaleConfig = [1, 2, 3, 4, 5];
  const getScaleNumberConfig = (): number[] => {
    const config = [];
    for (let i = 100; i >= 0; i -= 5) {
      config.push(i);
    }
    return config;
  };
  const calc_description = (
    <>
      <p>{code_for_the_impact}</p>
      <p>{response_to_the_scale}</p>
    </>
  );

  return (
    <>
      <RemoveQuestionnaire onMethod={() => onDeleteItem(index)} title={remove_questionnaire} />
      <div className="questionnaire-to-PDF">
        <div
          className={cx({
            [style.eq__container]: true,
            [style.description__container]: true,
          })}
        >
          <CalculationDescription
            name={questionnaire_name}
            content={calc_description}
            date={completedDate ? moment(completedDate).format(formatDate) : ''}
            t={t}
          />
        </div>
      </div>
      <div className="questionnaire-to-PDF">
        {/* PAGE 2 */}
        <div className={style.eq__container}>
          <p className={style.eq__title}>{under_each_heading}</p>
          {questions.map((question: Question, i: number) => {
            if (i !== 5) {
              return (
                <div key={question.name} className={style.eq__box}>
                  <p
                    className={style['eq__box-name']}
                    dangerouslySetInnerHTML={{ __html: sanitize(question.name) }}
                  />
                  {question.answers.map((answer: Answer) => (
                    <div key={answer.name} className={style['eq__box-row']}>
                      <div className={style['eq__box-question']}>{answer.name}</div>
                      <div className={style['eq__box-sqr']}>
                        {patientAnswers[question.id] === answer.id && <>&#10004;</>}
                      </div>
                    </div>
                  ))}
                </div>
              );
            }
            return <React.Fragment key={String(question.name)} />;
          })}
          <p className={style['eq__page-number']}>2</p>
          <i className={style.eq__footer}>
            UK (English) © 2009 EuroQol Group EQ-5D™ is a trade mark of the EuroQol Group
          </i>
        </div>
      </div>

      {/* PAGE 3 */}
      <div className="questionnaire-to-PDF">
        <div className={style.eq__container}>
          <div className={style.eq__page3cont}>
            <div className={style['eq__left-cont']}>
              <ul className={style['eq__left-list']}>
                {questionPage2Config.map((item: string, i: number) => {
                  if (i !== 2) {
                    return <li key={item}>{item}</li>;
                  }
                  return (
                    <li key={item}>
                      <div>{means_the_best}</div>
                      <div>{means_the_worst}</div>
                    </li>
                  );
                })}
              </ul>
              <div className={style['eq__left-health-cont']}>
                <div className={style['eq__left-health']}>
                  {your_health_today}
                  &nbsp; =
                </div>
                <div className={style['eq__left-health-value']}>{healthLevel}</div>
              </div>
            </div>
            <div className={style['eq__right-cont']}>
              <div className={style['eq__right-sens']}>{best_health}</div>
              {/* SCALE */}
              <div className={style['eq__right-scale-container']}>
                <div className={style['eq__right-scale-cont']}>
                  {bigScaleConfig.map((bigScale: number) => (
                    <div key={String(bigScale)} className={style['eq__right-scale-big-elem']}>
                      {midScaleConfig.map((midScale: number) => (
                        <div
                          key={`${bigScale}${midScale}`}
                          className={style['eq__right-scale-mid-elem']}
                        >
                          {smScaleConfig.map((smScale: number) => (
                            <div
                              key={`${bigScale}${midScale}${smScale}`}
                              className={style['eq__right-scale-sm-elem']}
                            />
                          ))}
                        </div>
                      ))}
                    </div>
                  ))}
                  <div className={style['eq__right-scale-vertikal-line']} />
                  {true && (
                    <span
                      className={style['eq__right-scale-val']}
                      style={{ bottom: `${healthLevel * onePxPerScale}px` }}
                    >
                      X
                    </span>
                  )}
                </div>
                <div className={style['eq__right-scale-numbers-cont']}>
                  {getScaleNumberConfig().map((i: number) => (
                    <div key={String(i)} className={style['eq__right-scale-number']}>
                      {i}
                    </div>
                  ))}
                </div>
              </div>
              <div className={style['eq__right-sens']}>{worst_health}</div>
            </div>
          </div>
          <p className={style['eq__page-number']}>3</p>
          <i className={style.eq__footer}>
            UK (English) © 2009 EuroQol Group EQ-5D™ is a trade mark of the EuroQol Group
          </i>
        </div>
      </div>
    </>
  );
};
