import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Pagination } from 'antd';
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from 'react-beautiful-dnd';

import styles from './styles.module.css';
import { Loading, MainTitle } from '../../../common';
import {
  GetState,
  getCurrenLang,
  getExercLibTotalItems,
  getExerciseLibrary,
  getStartUpd,
} from '../../../redux/selector';
import { EXERCISE_TYPE } from '../../../utils/enums';
import { FilterByFocus } from '../../Filter/ExerciseFilter';
import { getItemStyle, getListStyle, initialPage } from '../../../utils/helper';
import { useGetCurrentPatientExerciseLibrary } from '../../../graphql/videoBank';
import { PAGE_SIZE_OPTIONS_FOR_VIDEO_EXERCISE } from '../../../utils/variables';
import useGetFocuses from '../../../hooks/useGetFocuses';
import { PatientExerciseLibraryIcon } from '../../../theme/icons';
import { ExerciseStatusGuide, GuideTitle } from '../../../common/Giude';
import SearchClient from '../../Search/SearchClient';
import ExerciseLibreryTableHeader from './ExerciseLibreryTableHeader';
import MediaItem from './ExerciseLibraryItem/MediaItem';
import FolderItem from './ExerciseLibraryItem/FolderItem';
import useDebounce from '../../../hooks/useDebounce';
import { startUpdateExerciseLib } from '../../../redux/patient';

const PatientExerciseLibrary = ({ patientId, isInvitee }: any): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const see_status_guide = t?.dashboard.hcp.profile_patient.see_status_guide;
  const search_exercise = t?.dashboard.hcp.profile_patient.search_exercise;
  const patient_exercise_library = t?.menu.patient_exercise_library;
  const no_data = t?.common.no_data;

  const searchQueryInit = {
    searchField: 'exercise.name',
    searchValue: '',
  };

  // Queris
  // get list video exercises for Team video bank page from the database
  const { _getCurrentPatientExerciseLibrary, loading, data } = useGetCurrentPatientExerciseLibrary(
    isInvitee,
  );

  const { focusType1Arr, exercisesProcedureFocus } = useGetFocuses();

  // Redux
  const dispatch = useDispatch();
  const videoExersisesList: any = useSelector<any>((state: GetState) => getExerciseLibrary(state));
  const totalItems: any = useSelector<any>((state: GetState) => getExercLibTotalItems(state));
  const isStartUpdate: any = useSelector<any>((state: GetState) => getStartUpd(state));

  // Local state});
  const [currentPage, setCurrentPage] = useState<number>(initialPage);
  const [pageSize, setPageSize] = useState<number>(15);
  const [search, setSearch] = useState<string>('');
  const [searchQuery, setSearchQuery] = useState<any>({ ...searchQueryInit });
  const [sortBy, setSortby] = useState<string>('name');
  const [sortDirectionName, setSortDirectionName] = useState<string>('');
  const [focusFilter, setFocusFilter] = useState<number[]>([]);
  const [folderFilter, setFolderFilter] = useState(false);

  // Get exercise list
  useEffect(() => {
    if (patientId) {
      _getCurrentPatientExerciseLibrary(
        currentPage,
        pageSize,
        sortBy,
        sortDirectionName,
        searchQuery,
        folderFilter,
        patientId,
        focusFilter,
      );
    }
  }, [patientId]);

  const debounceValue = useDebounce(search, 300);

  // Get search result from DB after delay
  useEffect(() => {
    if (debounceValue.length >= 2) {
      setSearchQuery({ searchField: 'exercise.name', searchValue: debounceValue });
    } else {
      setSearchQuery({ ...searchQueryInit });
    }
  }, [debounceValue]);

  // Search exercise
  const changeSearch = ({ target: { value } }: any): void => {
    setSearch(value);
  };

  // Get data after search
  useEffect(() => {
    if (patientId) {
      setCurrentPage(initialPage);
      _getCurrentPatientExerciseLibrary(
        initialPage,
        pageSize,
        sortBy,
        sortDirectionName,
        searchQuery,
        folderFilter,
        patientId,
        focusFilter,
      );
    }
  }, [searchQuery, folderFilter, focusFilter]);

  // Get data after search
  useEffect(() => {
    if (isStartUpdate) {
      _getCurrentPatientExerciseLibrary(
        currentPage,
        pageSize,
        sortBy,
        sortDirectionName,
        searchQuery,
        folderFilter,
        patientId,
        focusFilter,
      );
    }
  }, [isStartUpdate]);

  // Get data after search
  useEffect(() => {
    if (data && !loading) {
      dispatch(startUpdateExerciseLib(false));
    }
  }, [data]);

  // Sort exercise list by name
  const sortByColumnName = (name: string, sortdirection: string): void => {
    setSortby(name);
    setSortDirectionName(sortdirection);
    _getCurrentPatientExerciseLibrary(
      currentPage,
      pageSize,
      name,
      sortdirection,
      searchQuery,
      folderFilter,
      patientId,
      focusFilter,
    );
  };

  // Go to the next page
  const onChangePagination = (page: any, itemsPerPage: any): void => {
    setPageSize(itemsPerPage);
    setCurrentPage(page);
    _getCurrentPatientExerciseLibrary(
      page,
      itemsPerPage,
      sortBy,
      sortDirectionName,
      searchQuery,
      folderFilter,
      patientId,
      focusFilter,
    );
  };

  // JSX
  const loadingJSX = loading && <Loading />;
  const noData = videoExersisesList && videoExersisesList.length === 0 && (
    <div className={styles.prescrebed__nodata}>{no_data}</div>
  );

  return (
    <div className={styles.prescrebed__container}>
      {loadingJSX}
      <MainTitle title={patient_exercise_library} icon={<PatientExerciseLibraryIcon />} />
      <GuideTitle text={see_status_guide} content={<ExerciseStatusGuide t={t} />} />
      <div className={styles['prescrebed__search-wrapper']}>
        <SearchClient
          inputId="searchExercise"
          name="searchExercise"
          placeholder={search_exercise}
          inputValue={search}
          changeFilter={changeSearch}
        />
        <FilterByFocus
          focusProcedureTypeArr={focusType1Arr}
          focusFilter={focusFilter}
          setFocusFilter={setFocusFilter}
          folderFilter={folderFilter}
          setFolderFilter={setFolderFilter}
          exercisesProcedureFocus={exercisesProcedureFocus}
          isFolder
        />
      </div>

      {/* Header */}
      <ExerciseLibreryTableHeader t={t} sortByColumnName={sortByColumnName} sortBy={sortBy} />

      <Droppable droppableId="PatientExerciseLibrary">
        {(provided: DroppableProvided, snapshot: DroppableStateSnapshot): JSX.Element => (
          <div
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
            {...provided.droppableProps}
          >
            {/* List */}
            {!!videoExersisesList?.length &&
              videoExersisesList.map((exercise: any, i: number) => (
                <Draggable
                  key={`${exercise.type}_${exercise.currentId}${String(i)}`}
                  draggableId={`${exercise.type}_${exercise.currentId}`}
                  index={i}
                >
                  {(
                    providedSecondary: DraggableProvided,
                    snapshotSecondary: DraggableStateSnapshot,
                  ): JSX.Element => (
                    <div
                      ref={providedSecondary.innerRef}
                      {...providedSecondary.draggableProps}
                      {...providedSecondary.dragHandleProps}
                      style={getItemStyle(
                        snapshotSecondary.isDragging,
                        providedSecondary.draggableProps.style,
                      )}
                    >
                      {(exercise.type === EXERCISE_TYPE.PHOTO ||
                        exercise.type === EXERCISE_TYPE.VIDEO) && <MediaItem data={exercise} />}
                      {exercise.type === EXERCISE_TYPE.FOLDER && <FolderItem data={exercise} />}
                    </div>
                  )}
                </Draggable>
              ))}
            {noData}
          </div>
        )}
      </Droppable>

      <div className="wrapper_pagination" style={{ zIndex: 1 }}>
        <Pagination
          size="small"
          current={currentPage}
          pageSize={pageSize}
          total={totalItems}
          onChange={onChangePagination}
          pageSizeOptions={PAGE_SIZE_OPTIONS_FOR_VIDEO_EXERCISE}
          showSizeChanger
        />
      </div>
    </div>
  );
};

export default PatientExerciseLibrary;
