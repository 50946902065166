import { useLazyQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import {
  setNotificationsError,
  setHcpNotifications,
  setPatientNotifications,
} from '../../redux/notifications';
import { config } from '../../utils/configs';
import { NOTIF_STATUS } from '../../utils/enums';
import { useMutationRequest } from '../../hooks/feature/useMutationRequest';
import { SearchPropsType } from '../../utils/model';

const getAllNotificationsForHcp = loader('./gql/getAllNotificationsForHcp.graphql');
const getPatientNotifications = loader('./gql/getPatientNotifications.graphql');
const setReadStateHcpNotifications = loader('./gql/mutationSetReadStateHcpNotifications.graphql');

// Get all hcp notifications
export const useGetHcpNotificationList = () => {
  const [_getHcpNotificationList, { loading, error, data }] = useLazyQuery(
    getAllNotificationsForHcp,
    {
      fetchPolicy: 'no-cache',
      pollInterval: config.pollInterval,
    },
  );
  const dispatch = useDispatch();

  const _getHcpNotifications = (
    notificationsStatus: NOTIF_STATUS,
    currentPage: number,
    itemsPerPage: number,
    searchProps: SearchPropsType,
  ): void => {
    _getHcpNotificationList({
      variables: {
        readState: notificationsStatus,
        listProps: { page: currentPage, itemsPerPage },
        searchProps,
      },
    });
  };

  let response;
  useEffect(() => {
    if (data) {
      response = data.getAllNotificationsForHcp;
      dispatch(setHcpNotifications(response));
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      dispatch(setNotificationsError(error));
    }
  }, [error]);
  //   useEffect(() => {
  // dispatch()
  //   }, [loading])

  return {
    _getHcpNotifications,
    loadingHcpNotifications: loading,
    errorHcpNotifications: error,
    hcpNotifications: response,
    hcpNotificationsData: data,
  };
};

// Get notifications for patient
export const useGetPatientNotificationsList = () => {
  const [_getPatientNotificationList, { loading, error, data }] = useLazyQuery(
    getPatientNotifications,
    {
      fetchPolicy: 'no-cache',
      pollInterval: config.pollInterval,
    },
  );
  const dispatch = useDispatch();

  const _getCurrentPatientNotifications = (
    notificationsStatus: NOTIF_STATUS,
    nextNumberItemsPerPage: number,
    patientId: number,
  ): void => {
    _getPatientNotificationList({
      variables: {
        patientId,
        readState: notificationsStatus,
        listProps: { page: 1, itemsPerPage: nextNumberItemsPerPage },
      },
    });
  };

  let response;
  useEffect(() => {
    if (data) {
      response = data.getCurrentHcpNotificationsForPatient;
      dispatch(setPatientNotifications(response));
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      dispatch(setNotificationsError(error));
    }
  }, [error]);
  //   useEffect(() => {
  // dispatch()
  //   }, [loading])

  return {
    _getCurrentPatientNotifications,
    loadingPatientNotifications: loading,
    errorPatientNotifications: error,
    patientNotifications: response,
    patientNotificationsData: data,
  };
};

// Change Notifications status
export const useChangeStateNotifications = () => {
  const [_changeNotificationStatus, { data, errorText, loading }] = useMutationRequest(
    setReadStateHcpNotifications,
    {},
  );

  const _changeHcpNotifStatus = (hcpNotificationIds: number[]): any => {
    _changeNotificationStatus({
      variables: {
        hcpNotificationIds,
      },
    });
  };

  let response;
  if (data) {
    response = data.setReadStateHcpNotifications;
  }

  return {
    _changeHcpNotifStatus,
    changeHcNotifsStatusError: errorText,
    changeHcpNotifsStatusLoading: loading,
    changedHcpNotifsStatus: response,
  };
};
