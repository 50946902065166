import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import cx from 'classnames';

import { ConfirmatioModal, Loading, Modal, WarningNotification } from '../../../common';
import styles from '../styles.module.css';
import { SelectTableStatusField } from '../../../common/Input/Select';
import { selectStyleProfileStatus, selectTableStatusStyle } from '../../../common/Input/styles';
import {
  getStatusOptions,
  getSuccessfullText,
  getWarningNotificationContant,
  getWarningNotificationTitle,
} from '../../../utils/helper';
import { getColorOptionsTable, EXCLUDE_FROM_TABLE_STATUS_SELECT } from '../../../utils/variables';
import { UserStatusProps } from '../types';
import { TStatusOptions } from '../../../utils/model';
import useCheckPermissions from '../../../hooks/useCheckPermissions';
import { LIST_TYPE, PERMISSIONS, StatusKeyNameEnum } from '../../../utils/enums';
import { useChangePatientConnectByHcp } from '../../../graphql/dashboard';
import useCheckRole from '../../../hooks/useCheckRole';
import { PatientTeams } from '../../../pages/Hcp/RehabTeams/types';
import { CloseBtn } from '../../../common/Button/Button';

const UserStatus = ({
  mode = LIST_TYPE.PATIENT,
  record,
  refresh,
  isResponsive = false,
  disabledProp = false,
  t,
}: UserStatusProps): ReactElement => {
  const ok = t?.common.ok;
  const yes = t?.common.yes;
  const no = t?.common.no;
  const would_apply = t?.hcp.manage.would_apply_this_change_to_all_members;

  const [patientChangeConnectionError, setPatientChangeConnectionError] = useState<
    string | undefined
  >();
  const [successCangedConnection, setSuccessCangedConnection] = useState<boolean>(false);

  const {
    _changePatientConnectByHcp,
    changePatientConnectLoading,
    changePatientConnectError,
    patientConnectChanged,
  } = useChangePatientConnectByHcp();

  useEffect(() => {
    setPatientChangeConnectionError(changePatientConnectError);
  }, [changePatientConnectError, changePatientConnectLoading]);

  useEffect(() => {
    setSuccessCangedConnection(patientConnectChanged);
  }, [patientConnectChanged, changePatientConnectLoading]);

  const { status, id } = record;
  const statusLowerCase = status && status.toLowerCase();

  const [isModalOpen, setModal] = useState(false);
  const [mutableValue, setMutableValue] = useState<TStatusOptions>(
    getStatusOptions(statusLowerCase, 'table', t),
  );
  const [selectValueState, setSelectValueState] = useState<TStatusOptions>(
    getStatusOptions(statusLowerCase, 'table', t),
  );
  const [isTeamChangeRequest, setTeamChangeRequest] = useState('');

  const statusOptions = useMemo(() => getColorOptionsTable(t), [t]);

  useEffect(() => {
    setSelectValueState(getStatusOptions(statusLowerCase, 'table', t));
  }, [status, t]);

  const isPermissionCreateTeam = useCheckPermissions(PERMISSIONS.CREATE_TEAM_OTM);
  const { isAdmin } = useCheckRole();

  const getWarningText = (item: any): string => {
    if (!isPermissionCreateTeam) {
      return '';
    }
    if (item.teams?.length) {
      const teamNames = item.teams.map((teams: PatientTeams) => teams.name);
      const joinedNames = teamNames?.join(', ');
      const tip = would_apply ? would_apply.replace('<teamName>', joinedNames) : '';
      return tip;
    }
    return '';
  };
  const openModal = (value: TStatusOptions): void => {
    if (selectValueState.value === value.value) return;
    setModal(() => true);
    setMutableValue(value);
  };

  const onclose = (): void => {
    setModal(() => false);
    setPatientChangeConnectionError(undefined);
    setTeamChangeRequest('');
    if (successCangedConnection) {
      setSuccessCangedConnection(false);
      refresh(mutableValue);
    }
  };

  const onSubmit = (isDeleteFromTeam: string): void => {
    let teamIds = null;

    if (isDeleteFromTeam === 'yes') {
      teamIds = record.teams?.map((item: PatientTeams) => item.id);
    }
    _changePatientConnectByHcp(mutableValue.value, id, teamIds);
  };

  const content = (
    <div className={styles.contant}>{getWarningNotificationContant(mutableValue.value, t)}</div>
  );

  const checkTeamExistence = (): void => {
    if (selectValueState.value === StatusKeyNameEnum.connected) {
      const isTeamExist = getWarningText(record);
      if (isTeamExist) {
        setTeamChangeRequest(() => isTeamExist);
        return;
      }
    }
    onSubmit('no');
  };

  const onConfirmCangeStatusPatient = !successCangedConnection &&
    !patientChangeConnectionError &&
    !isTeamChangeRequest && (
      <WarningNotification
        title={getWarningNotificationTitle(mutableValue.value, t)}
        contant={content}
        onCancel={onclose}
        onSubmit={checkTeamExistence}
        buttonName={yes}
        cancelButtonName={no}
        foreignStyle={styles.warningNotification__container}
        foreignBtnStyle={styles.warningNotification__btn}
        disabledBtn={changePatientConnectLoading}
      />
    );

  const onConfirmChangeTeam = !successCangedConnection &&
    !patientChangeConnectionError &&
    isTeamChangeRequest && (
      <WarningNotification
        title=""
        contant={isTeamChangeRequest}
        onCancel={(): void => onSubmit('no')}
        onSubmit={(): void => onSubmit('yes')}
        buttonName={yes}
        cancelButtonName={no}
        foreignStyle={styles.warningNotification__container}
        foreignBtnStyle={styles.warningNotification__btn}
        disabledBtn={changePatientConnectLoading}
      />
    );

  const saccessCangeStatusPatient = successCangedConnection &&
    !patientChangeConnectionError &&
    !changePatientConnectLoading && (
      <>
        <ConfirmatioModal
          contant={getSuccessfullText(mutableValue.value, t)}
          buttonTitle={ok}
          buttonMethod={onclose}
        />
      </>
    );

  const errorCangeStatusPatient = !changePatientConnectLoading && patientChangeConnectionError && (
    <>
      <WarningNotification
        title=""
        contant={patientChangeConnectionError}
        buttonName={ok}
        onCancel={onclose}
        onSubmit={onclose}
        foreignStyle={styles.warningNotification__container}
        foreignBtnStyle={styles.warningNotification__btn}
      />
    </>
  );
  const customStyles = isResponsive
    ? selectStyleProfileStatus()
    : selectTableStatusStyle(selectValueState.color);
  const marker = (
    <div
      className={cx({
        [styles['user-status__color']]: true,
        [styles['margin-left']]: isResponsive,
      })}
      style={{ backgroundColor: selectValueState.color }}
    />
  );

  return (
    <div className={styles['user-status__container']}>
      <SelectTableStatusField
        options={statusOptions}
        onChange={openModal}
        inputValue={selectValueState}
        disabledField={
          mode === LIST_TYPE.HCP ||
          selectValueState.value === StatusKeyNameEnum.invited ||
          selectValueState.value === StatusKeyNameEnum.expired ||
          selectValueState.value === StatusKeyNameEnum.pending ||
          selectValueState.value === StatusKeyNameEnum.invite_sent ||
          selectValueState.value === StatusKeyNameEnum.declined ||
          isAdmin ||
          disabledProp
        }
        filterOptionsArr={EXCLUDE_FROM_TABLE_STATUS_SELECT}
        customStyles={customStyles}
        marker={marker}
      />
      {/* Popups */}
      {isModalOpen && (
        <>
          <Modal onClose={onclose} style={styles.modal__action}>
            {!successCangedConnection && !patientChangeConnectionError && (
              <CloseBtn close={onclose} />
            )}
            {onConfirmCangeStatusPatient}
            {onConfirmChangeTeam}
            {saccessCangeStatusPatient}
            {errorCangeStatusPatient}
            {changePatientConnectLoading && <Loading />}
          </Modal>
        </>
      )}
    </div>
  );
};

export default UserStatus;
