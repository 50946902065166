import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  Tooltip,
  Cell,
} from 'recharts';
import cx from 'classnames';

import styles from '../styles.module.css';
import { getCurrenLang, getExercComplLoading } from '../../../redux/selector';
import { ChartValuesType } from '../../../redux/motivations';
import { TooltipRecharts } from '../../../common/TooltipRecharts';
import { BAR_COLOR, PERIOD_FOR_CHART } from '../../../utils/enums';
import { spinner } from '../../../common/Loader';
import { LegendBarChart } from '../../../common/Legend';

const ExerciseCompletionChart = ({ exercComplData, type, isOverview }: any): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const no_data = t?.common.no_data;
  const today_t = t?.dashboard.hcp.table.today;
  const past_t = t?.common.past;
  const exercise_completion =
    t?.dashboard.hcp.profile_patient.completion_and_steps.exercise_completion;

  const loading = useSelector<any>((state) => getExercComplLoading(state));
  const windowClientWidth = useSelector((state: any) => state.common.screen.innerWidth);
  const chartData: any = exercComplData?.values;
  const chartPeriod = exercComplData?.title;
  const chartDescription = exercComplData?.description;
  const axisName = exercComplData?.axis;
  const YAxisDomain = exercComplData?.yDomen;
  const periodType = exercComplData?.graph;
  const legendConfig = [
    { name: past_t, color: BAR_COLOR.PAST },
    { name: today_t, color: BAR_COLOR.TODAY },
  ];
  const isLabelAngle =
    periodType === PERIOD_FOR_CHART.W2 ||
    periodType === PERIOD_FOR_CHART.M6 ||
    periodType === PERIOD_FOR_CHART.M3;
  const isAllLabels = periodType !== PERIOD_FOR_CHART.M6 && windowClientWidth > 500;

  // Custom Tooltip
  const renderTooltip = (payload: any): JSX.Element => {
    const pay = payload.payload;
    return <TooltipRecharts payload={pay} type={type} t={t} />;
  };

  // JSX
  const chartDataLength = chartData?.length;
  const noData = !loading && (chartDataLength === 0 || !chartDataLength) && (
    <span className={styles.nodata}>{no_data}</span>
  );

  return (
    <div
      className={cx({
        [styles['chart-wrapper']]: true,
        'chart-to-PDF': true,
      })}
    >
      {!isOverview && (
        <div>
          <div className={styles['chart-title']}>{exercise_completion}</div>
          <div className={styles['chart-desciption']}>{chartDescription}</div>
          <LegendBarChart config={legendConfig} />
        </div>
      )}
      {isOverview && (
        <>
          <div className={styles['chart-period-overview']}>{chartPeriod}</div>
          <div className={styles['chart-desciption--overview']}>{chartDescription}</div>
        </>
      )}

      <div className={styles['chart-container']}>
        <div className={styles['chart-label']}>{axisName}</div>
        {loading && spinner}
        {noData}
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={chartData}
            margin={{
              top: 10,
              left: -20,
              right: isLabelAngle ? 7 : 0,
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="name"
              axisLine={false}
              tickLine={false}
              // @ts-ignore
              style={{
                fontSize: isLabelAngle ? '11px' : '14px',
                fill: '#23335B',
              }}
              angle={isLabelAngle ? -45 : 0}
              interval={isAllLabels ? 0 : 'preserveEnd'} // 0 - To show all ticks
            />
            <YAxis
              domain={YAxisDomain || [0, 100]}
              interval={0} // To show all ticks
              axisLine={false}
              // @ts-ignore
              style={{
                fontSize: '14px',
                fill: '#23335B',
              }}
            />
            {!noData && (
              <Tooltip
                content={renderTooltip}
                allowEscapeViewBox={{ x: false, y: false }}
                offset={windowClientWidth > 1000 ? 10 : 500}
              />
            )}
            <Bar strokeWidth={3} dataKey="value" fill="1C9CE4" isAnimationActive>
              {chartData ? (
                chartData.map((entry: ChartValuesType, index: number) => (
                  <Cell
                    fill={index === chartDataLength - 1 ? BAR_COLOR.TODAY : BAR_COLOR.PAST}
                    key={`cell-${String(index)}`}
                  />
                ))
              ) : (
                <></>
              )}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default ExerciseCompletionChart;
