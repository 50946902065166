import React, { ReactElement, useMemo, useState } from 'react';
import cx from 'classnames';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Tooltip } from 'antd';
import { sanitize } from 'dompurify';

import style from './style.module.css';
import {
  CardTitleProps,
  EndMessageProps,
  NotificationItemProps,
  OtherHcpProps,
  PatientMediaProps,
  PatientTeamProps,
} from '../types';
import useCheckRole from '../../../hooks/useCheckRole';
import { NOTIF_STATUS, SOURCE_PI } from '../../../utils/enums';
import { CustomCheckbox } from '../../../common/Input';
import {
  MsWordIcon,
  NoImage,
  NotAllowedIcon,
  PlayVideoIcon,
  RedidectIcon,
  ShowImgIcon,
} from '../../../theme/icons';
import { WoundImagesItemProps } from '../../WoundImages/types';
import { getCurrenLang, GetState } from '../../../redux/selector';
import { PhotoModal, VideoModal } from '../../../common/VideoModal';
import { PatientInfoType } from '../../PatientInformation/types';
import PdfView from '../../PatientInformation/components/PdfView';
import { TeamPatientsType } from '../../RehabTeams/types';
import { showTheStatus } from '../../../utils/helper';

export const CardTitle = ({ icon, title }: CardTitleProps): ReactElement => (
  <div className={style['card-title__container']}>
    {icon}
    <div className={style['card-title']}>{title}</div>
  </div>
);

export const EndMessage = ({
  totalItems,
  ITEMS_PER_PAGE,
  description,
}: EndMessageProps): ReactElement => (
  <div className={style['overview__nomore-exercises']}>
    {totalItems && totalItems > ITEMS_PER_PAGE ? description : ''}
  </div>
);

export const NotificationItem = ({
  data,
  index,
  checkItem,
  isCheked,
  patientId,
  patientStatus,
  isHcpNotif,
  redirectTo,
}: NotificationItemProps): ReactElement => {
  const { isAdmin } = useCheckRole();

  const { notifDate, title, message, isUnread, type, notifId } = useMemo(() => {
    const date = data.timestamp;
    const result = {
      notifDate: '',
      title: data.title,
      message: data.message,
      isUnread: data.readState === NOTIF_STATUS.UNREAD,
      type: data.type,
      notifId: data.hcpNotificationId,
    };
    if (date) {
      const now = moment().format('YYYY-MM-DD');
      const dateWithoutTime = moment(date).format('YYYY-MM-DD');
      const isToday = now === dateWithoutTime;
      if (isToday) {
        result.notifDate = moment(data.timestamp).fromNow();
      } else {
        result.notifDate = moment(date).format('DD MMM YYYY');
      }
    }
    return result;
  }, [data]);

  return (
    <div
      className={cx({
        [style.notifications__row]: true,
        [style['notifications__row--unread']]: isUnread,
        [style['notifications__row--hcpnotif']]: isHcpNotif,
      })}
    >
      <div className={style['notifications__checkbox-wrapper']}>
        <CustomCheckbox
          htmlId={`SelectNotifId${index}`}
          name="selectNotif"
          checked={isCheked}
          onChangeMethod={(e: any): void => checkItem(e, data.hcpNotificationId)}
          isTouched={false}
          hasErrors={false}
        />
      </div>
      <div className={style['notifications__content-wrapper']}>
        <div
          className={cx({
            [style.notifications__date]: true,
            [style['notifications__date--hcpnotif']]: isHcpNotif,
          })}
        >
          {notifDate}
        </div>
        <div
          className={cx({
            [style.notifications__title]: true,
            [style['notifications__title--unread']]: isUnread,
            [style['notifications__title--hcpnotif']]: isHcpNotif,
          })}
        >
          {title}
        </div>
        <div
          className={cx({
            [style.notifications__description]: true,
            [style['notifications__description--hcpnotif']]: isHcpNotif,
          })}
        >
          {message}
        </div>
      </div>
      <div
        className={style.notifications__redirect}
        aria-hidden
        onClick={
          isAdmin
            ? (): null => null
            : (): void => redirectTo(type, patientId, patientStatus, notifId, isUnread)
        }
      >
        <RedidectIcon />
      </div>
    </div>
  );
};

export const WoundMedia = ({ data }: WoundImagesItemProps): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const no_patients_note = t?.dashboard.hcp.profile_patient.video_bank.no_patients_note;
  const video_t = t?.common.video;
  const image_t = t?.common.image;

  const mediaLabel = data.fileLink ? image_t : video_t;

  // Local state
  const [isModalOpen, setModal] = useState<boolean>(false);

  const { thumbnailLink, videoLink, fileLink, documentDate, description } = data;

  // Get Video link from AWS
  const showMedia = async (): Promise<void> => {
    if (fileLink || videoLink) {
      setModal(() => true);
    }
  };

  // Clouse open modal windows
  const onclose = (): void => {
    setModal(() => false);
  };

  // JSX
  // Action icon
  const setPlayVideoIcon = (videolink: string, fotolink: string): JSX.Element => {
    if (fotolink) {
      return <ShowImgIcon className={style['btn--slider']} />;
    }
    if (videoLink) {
      return <PlayVideoIcon className={style['start--btn']} />;
    }
    return <img src={NotAllowedIcon} alt="No video" className={style['btn--not-allowed']} />;
  };

  const setThumbnailJsx = (thumbLink: string): JSX.Element => {
    if (thumbLink) {
      return <img src={thumbLink} alt="Thumbnail" />;
    }
    return <img src={NoImage} alt="NoImage" />;
  };

  const isMediaExist = !!(data.videoLink || data.fileLink);

  return (
    <div className={style.media__row}>
      {/* Thumbnail */}
      <div>
        <div
          className={cx({
            [style.preview]: true,
            [style['preview-not-allowed']]: !isMediaExist,
          })}
          role="presentation"
          onClick={isMediaExist ? showMedia : (): null => null}
        >
          {setThumbnailJsx(thumbnailLink || fileLink)}
          {setPlayVideoIcon(thumbnailLink, fileLink)}
        </div>
      </div>

      {/* Comment */}
      <div className={style['media__description-container']}>
        {/* Date sent */}
        <div className={style.media__date}>
          {`${mediaLabel}: ${documentDate ? moment(documentDate).format('MMMM Do YYYY ') : '-'}`}
        </div>
        <div
          className={cx({
            [style.media__comment]: true,
          })}
        >
          {description || no_patients_note}
        </div>
      </div>

      {/* Popups */}
      {/* Video content */}
      {isModalOpen && videoLink && <VideoModal videoSrc={videoLink} onclose={onclose} />}
      {/* Image content */}

      {isModalOpen && fileLink && (
        <PhotoModal photoLink={thumbnailLink || fileLink} onclose={onclose} />
      )}
    </div>
  );
};

export const PatientMedia = ({ data }: PatientMediaProps): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const video_t = t?.common.video;
  const image_t = t?.common.image;
  const file_t = t?.common.file;
  const content_t = t?.common.content;
  const no_image = t?.common.no_image;
  const pdf_t = t?.common.pdf?.toUpperCase();
  const url_t = t?.dashboard.hcp.profile_patient.url_t?.toUpperCase();

  const imageTypes = ['.jpeg', '.jpg', '.png'];
  const wordTypes = ['.doc', '.docx'];
  const videoTypes = ['.MOV', '.mov', '.mp4', '.MP4', '.AVI', '.avi', '.wmv', '.webm'];

  const { lastChanged, description, content } = data;

  const isIndividual = data.source === SOURCE_PI.HCP;
  const mediaLabel = useMemo(() => {
    // Get Icon for each file type
    const fileName = data.name;
    if (fileName) {
      const typeFile = `.${fileName.split('.').reverse()[0]}`;
      if (imageTypes.includes(typeFile)) {
        return image_t;
      }
      if (typeFile === '.pdf') {
        return pdf_t;
      }
      if (wordTypes.includes(typeFile)) {
        return file_t;
      }
      if (videoTypes.includes(typeFile)) {
        return video_t;
      }
    }
    if (content) {
      return content_t;
    }
    if (data.url) {
      return url_t;
    }
    return file_t;
  }, [t, data]);

  // Local state
  const [isVideoModalOpen, setVideoModal] = useState(false);
  const [videoSrcState, setVideoSrcState] = useState<any>('');

  // Open video player
  const videoModalOpen = (): void => {
    setVideoModal(() => true);
  };

  // Get Video link from AWS
  const showMedia = async (): Promise<void> => {
    videoModalOpen();
    setVideoSrcState(data.videoUuid);
  };

  // Clouses open modal windows
  const onclose = (): void => {
    setVideoModal(() => false);
  };

  // JSX
  const setPlayVideoIcon = (videoUuid: string): JSX.Element => {
    if (videoUuid) {
      return <PlayVideoIcon className={style['start--btn']} />;
    }
    return <img src={NotAllowedIcon} alt="No video" className={style['btn--not-allowed']} />;
  };

  // Get Icon for each file type
  const getPreview = (item: PatientInfoType): JSX.Element => {
    const fileName: any = item.name;
    if (fileName) {
      const typeFile = `.${fileName.split('.').reverse()[0]}`;
      if (imageTypes.includes(typeFile)) {
        return (
          <div>
            <a href={item.fileUuid}>
              <img src={item.fileUuid} alt="Preview" />
            </a>
          </div>
        );
      }
      if ('.pdf'.includes(typeFile)) {
        return (
          <div>
            <a href={item.fileUuid}>
              <PdfView pdfLink={item.fileUuid} />
            </a>
          </div>
        );
      }
      if (wordTypes.includes(typeFile)) {
        return (
          <div>
            <a href={item.fileUuid}>
              <img src={MsWordIcon} alt="Preview" />
            </a>
          </div>
        );
      }
      if (videoTypes.includes(typeFile)) {
        return (
          <div
            onClick={(): void => {
              videoModalOpen();
              setVideoSrcState(item.videoUuid);
            }}
            role="presentation"
          >
            {item.thumbnailUuid ? (
              <img src={item.thumbnailUuid} alt="Preview" />
            ) : (
              <img src={NoImage} alt="Preview" />
            )}
            {/* </a> */}
            <PlayVideoIcon className={style['start--btn']} />
          </div>
        );
      }
    }
    return <div className={style['no-image']}>{no_image}</div>;
  };

  return (
    <div className={style.media__row}>
      {/* Thumbnail */}
      <div>
        <div
          className={cx({
            [style.preview]: true,
            // [style['preview-not-allowed']]: !isMediaExist,
          })}
          role="presentation"
          onClick={data.videoUuid ? showMedia : (): null => null}
        >
          {isIndividual ? (
            getPreview(data)
          ) : (
            <>
              <img src={data.thumbnailUuid || NoImage} alt="Thumbnail" />
              {setPlayVideoIcon(data.videoUuid)}
            </>
          )}
        </div>
      </div>

      {/* Comment */}
      <div className={style['media__description-container']}>
        {/* Date sent */}
        <div className={style.media__date}>
          {`${mediaLabel}: ${lastChanged ? moment(lastChanged, 'x').format('MMMM Do YYYY ') : '-'}`}
        </div>
        {!content && (
          <div
            className={cx({
              [style.media__comment]: true,
            })}
          >
            {description || '-'}
          </div>
        )}
        {content && (
          <div
            className={cx({
              [style.media__comment]: true,
            })}
            dangerouslySetInnerHTML={{ __html: sanitize(JSON.parse(content)) }}
          />
        )}
      </div>

      {/* Popups */}
      {isVideoModalOpen && <VideoModal videoSrc={videoSrcState} onclose={onclose} />}
    </div>
  );
};

export const PatientTeam = ({ data, patientId }: PatientTeamProps): ReactElement => {
  const t: any = useSelector<GetState>((state) => getCurrenLang(state));
  const patient_requests = t?.dashboard.hcp.profile_patient.patient_requested_removal_oveview;

  const [reqDate, setReqDate] = useState('');

  // Check patient requests
  const isPatientReqIcon = useMemo(() => {
    if (data.teamPatients?.length) {
      let isHasRequest = false;
      data.teamPatients.map((patient: TeamPatientsType) => {
        if (patient.removeRequest && patient.removeRequestDate && patientId === patient.id) {
          isHasRequest = true;
          const dateReq = moment(patient.removeRequestDate).format('MMMM DD YYYY');
          const normReq = patient_requests?.replace('<date>', dateReq);
          setReqDate(normReq);
        }
        return null;
      });
      return isHasRequest;
    }
  }, [data?.teamPatients, t]);

  return (
    <div className={style.team__row}>
      {/* Name and Description */}
      <div className={style.team__wrapper}>
        <div className={style['team__name-descr-wrapper']}>
          <div className={style.team__name}>{data.name}</div>
          <div className={style.team__descr}>{data.description}</div>
        </div>
        {isPatientReqIcon && (
          <Tooltip title={reqDate}>
            <div className={style['team__patient-request']}>i</div>
          </Tooltip>
        )}
      </div>
      <div className={style.team__border} />
    </div>
  );
};

export const OtherHCP = ({ data }: OtherHcpProps): ReactElement => {
  const t: any = useSelector<GetState>((state) => getCurrenLang(state));
  const [, , hex] = showTheStatus(data.status, t);

  return (
    <div className={style.team__row}>
      {/* Name and Profession */}
      <div className={style.team__wrapper}>
        <div className={style['team__name-descr-wrapper']}>
          <div className={style.team__name}>{data.profession}</div>
          <div className={style.team__descr}>{`${data.firstName} ${data.lastName}`}</div>
        </div>
        <div className={style['other-hcp__status']} style={{ backgroundColor: hex }} />
      </div>
      <div className={style.team__border} />
    </div>
  );
};
