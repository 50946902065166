/* eslint-disable jsx-a11y/media-has-caption */
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import cx from 'classnames';

import styles from './styles.module.css';
import pageStyles from '../../../../../pages/styles.module.css';
import { Button, Loading, MainTitle, Modal } from '../../../../../common';
import { getCurrenLang } from '../../../../../redux/selector';
import { Popup } from '../../../../../common/Popup';
import { InputFloatLabel, InputTypeFileAsText } from '../../../../../common/Input';
import {
  deepCopyObj,
  defaultValue,
  getCamAndMics,
  getExercisesCategories,
  uploadBase64Files,
} from '../../../../../utils/helper';
import { validationExercise, validationFocusCheckboxes } from '../../../../../utils/validators';
import { EXERCISE_TYPE } from '../../../../../utils/enums';
import { ButtonWithContent, CloseBtn } from '../../../../../common/Button/Button';
import {
  AddPhotoGreenIcon,
  CheckIcon,
  DeleteIcon,
  FlipIcon,
  WarningIcon,
} from '../../../../../theme/icons';
import { path } from '../../../../../utils';
import { DataVideoExercises } from '../../../VideoExercise/types';
import useGetFocuses from '../../../../../hooks/useGetFocuses';
import AddMediaSettingsForm from '../AddMediaSettingsForm';
import useCheckRole from '../../../../../hooks/useCheckRole';
import { useSaveHcpExercise } from '../../../../../graphql/videoBank';

const AddPhoto = (): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const cancel = t?.common.cancel;
  const save = t?.common.save;
  const ok = t?.common.ok;
  const add_photo = t?.common.add_photo;
  const add_photo_title = t?.common.add_photo_title;
  const exercise_name = t?.dashboard.hcp.profile_patient.video_bank.exercise_name;
  const attachment = t?.dashboard.hcp.profile_patient.attachment;
  const take_new_photo = t?.dashboard.hcp.profile_patient.take_new_photo;
  const access_to_device = t?.validation.access_to_device;
  const max_photos = t?.validation.max_photos;
  const maximum_photos = max_photos?.replace('<number>', '10') || '';
  const large_file = t?.help.large_file;
  const large_file_50 = large_file?.replace('<size>', '50MB');
  const wrong_format = t?.help.wrong_format;
  const delete_all = t?.help.delete_all;
  const uploading_file_error = t?.dashboard.hcp.profile_patient.uploading_file_error;
  const createExercise = t?.notifications.created_exercise;
  const descr_one = t?.hcp.org_video_bank?.add_media_descr_one;
  const descr_two = t?.hcp.org_video_bank?.add_media_descr_two;

  const filesSizeLimit = 52428800; // max 50Mb
  const fileLimit = 10;
  const initErrState = {
    error: false,
    text: '',
  };

  // Endpoints  // save exercise by HCP to Team video bank
  const {
    _saveHcpExercise,
    savedOrgVideoExercise,
    errorSaveExercise,
    loadingSaveExercise,
  } = useSaveHcpExercise();

  // Local state
  const [imgSrc, setImgSrc] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [fileInputKey, setFileInputKey] = useState<number>(Date.now());
  const [isSuccessSaved, setSuccessSaved] = useState(false);
  const [fileSizes, setFileSizes] = useState(0);
  const [errorMessage, setErrorMessage] = useState(deepCopyObj(initErrState));

  const [currentTrack, setCurrentTrack] = useState<any>(null);
  const [camList, setCamList] = useState([]);
  const [front, setFront] = useState(true);
  const [zoom, setZoom] = useState(1);
  const [zoomMin, setZoomMin] = useState(1);
  const [zoomMax, setZoomMax] = useState(1);
  const [zoomStep, setZoomStep] = useState(1);
  const [isHiddenZoom, setHiddenZoom] = useState(true);
  const [isDeviceDeny, setIsDeviceDeny] = useState(false);
  const [stream, setStream] = useState<any>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  const {
    focusType1Arr,
    focusType2Arr,
    exercisesCategories,
    exercisesProcedureFocus,
    loadingFocuses,
  } = useGetFocuses();
  const { token } = useCheckRole();
  const history = useHistory();

  const redirectToVideoBank = (): void => {
    if (stream) {
      stream.getTracks().forEach((x: any) => x.stop());
    }
    // history.push({ pathname: redirectPath, search });
    history.push({ pathname: path.organisationVideoBank });
  };

  const initialValues: any = {
    name: '',
    exerciseName: '',
    id: null,
    type: EXERCISE_TYPE.PHOTO,
    exerciseArchived: null,
    exerciseCategories: null,
    exerciseComment: null,
    exerciseId: null,
    exerciseReps: null,
    exerciseRestDays: null,
    exerciseSets: null,
    exerciseTime: null,
    exerciseTimesPerDay: null,
    exerciseVideoUploaded: true,
    exerciseThumbnailUploaded: true,
    status: 'Inactive',
    thumbnailName: '',
    videoName: null,
    dim: 'reps',
    reps: '',
    sets: '',
    timesDay: '',
    restDaysWeek: '',
    minutes: defaultValue('0'),
    seconds: defaultValue('0'),
    comments: '',
    videobankId: null,
    focus1: false,
    focus2: false,
    focus3: false,
    focus4: false,
    procedure1: false,
    procedure2: false,
    procedure3: false,
    procedure4: false,
    procedure5: false,
    procedure6: false,
    procedure7: false,
    file: '',
    isTakePhoto: false,
  };

  const formik = useFormik({
    initialValues,
    validate: (values) => validationFocusCheckboxes(values, t),
    validationSchema: () => validationExercise(t),
    onSubmit: async (values) => {
      if (imgSrc.length) {
        const request: DataVideoExercises = {
          isVideoOwnerHcp: true,
          exerciseCategoriesId: getExercisesCategories(values, exercisesCategories, 'focus'),
          exerciseProcedureTypesId: getExercisesCategories(
            values,
            exercisesProcedureFocus,
            'procedure',
          ),
          exerciseData: {
            id: null,
            name: values.name,
            thumbnailName: values.thumbnailName,
            videoName: values.videoName,
            videobankId: values.videobankId,
            sets: Number(values.sets) || null,
            reps: values.dim === 'time' ? null : Number(values.reps) || null,
            time:
              values.dim === 'time'
                ? Number(values.seconds.value) + Number(values.minutes.value) * 60
                : null,
            timesPerDay: Number(values.timesDay),
            restDays: Number(values.restDaysWeek) || null,
            comment: values.comments,
            videoUploaded: values.exerciseVideoUploaded,
            thumbnailUploaded: values.exerciseThumbnailUploaded,
          },
        };
        setLoading(() => true);
        const urlSecondPartPic = 'pictures/upload-hcp-photo-body';
        // Save photo files to AWS
        const { attachmentPic, isLoadingErr } = await uploadBase64Files(
          imgSrc,
          urlSecondPartPic,
          token,
        );
        setLoading(() => false);
        if (isLoadingErr) {
          toast.error(uploading_file_error);
          return;
        }
        request.photos = attachmentPic;
        _saveHcpExercise(request);
      }
    },
  });

  const { values, touched, errors } = formik;

  // Delete attached video
  const closeAttachment = (): void => {
    setImgSrc([]);
    formik.setErrors({});
    formik.setFieldValue('fileNameOrigin', '');
    formik.setFieldValue('isTakePhoto', false);
    setIsDeviceDeny(() => false);
    if (stream) {
      stream.getTracks().forEach((x: any) => x.stop());
    }
  };

  // Take Photo logik
  // Camera init
  useEffect(() => {
    if (values.isTakePhoto) {
      (async (): Promise<void> => {
        // get device list
        if (!camList.length) {
          await getCamAndMics(setCamList, setLoading);
        }
        navigator.mediaDevices
          .getUserMedia({
            video: {
              facingMode: front ? 'user' : { exact: 'environment' },
              // @ts-ignore
              advanced: [{ zoom }],
            },
          })
          .then((mediaStream: MediaStream) => {
            if (videoRef.current) {
              videoRef.current.srcObject = mediaStream;
              setStream(mediaStream);
              const track: any = mediaStream.getVideoTracks()[0];
              setCurrentTrack(track);
              const capabilities: any = track.getCapabilities();
              const settings: any = track.getSettings();
              // Check whether zoom is supported or not.
              // if (!('zoom' in settings)) {
              //   throw new Error(`Zoom is not supported by ${track.label}`);
              // }
              if ('zoom' in settings) {
                setHiddenZoom(() => false);
                setZoomMin(capabilities.zoom.min);
                setZoomMax(capabilities.zoom.max);
                setZoomStep(capabilities.zoom.step);
                setZoom(settings.zoom);
                // if (input) {
                //   input.min = capabilities.zoom.min;
                //   input.max = capabilities.zoom.max;
                //   input.step = capabilities.zoom.step;
                //   input.value = settings.zoom;
                //   input.hidden = false;
                // }
              }
            }
          })
          .catch((error: any) => {
            setIsDeviceDeny(true);
            console.error('Device deny', error);
          });
      })();
    }
  }, [front, values.isTakePhoto]);

  // Apply zoom change to current video track
  useEffect(() => {
    if (currentTrack) {
      currentTrack.applyConstraints({ advanced: [{ zoom }] });
    }
  }, [zoom]);

  // Take a photo
  const capture = (): void => {
    const video = videoRef.current;
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (video && context && canvas) {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
      const base64Image = canvas.toDataURL('image/jpeg');
      setImgSrc([...imgSrc, base64Image]);
    }
  };

  // Increase or decrease camera zoom
  const handleZoomChange = (action: 'minus' | 'plus'): void => {
    if (action === 'minus') {
      if (zoom <= zoomMin) return;
      setZoom(zoom - zoomStep);
    }
    if (action === 'plus') {
      if (zoom >= zoomMax) return;
      setZoom(zoom + zoomStep);
    }
  };
  // End Take Photo logik

  /* After the exercise video is saved to team video bank,
    corresponding message is displayed and clouse settigs options */
  useEffect(() => {
    if (savedOrgVideoExercise) {
      setSuccessSaved(() => true);
    }
  }, [savedOrgVideoExercise]);

  const onCheckIncomingFiles = async (e: any): Promise<void> => {
    setFileInputKey(Date.now());
    setLoading(() => true);
    let size = fileSizes;
    let wrongFormat = false;
    let currentFileCount = imgSrc.length;

    const clearUrl = (urls: string[]): void => {
      urls.map((url: string) => window.URL.revokeObjectURL(url));
    };

    const toBase64 = async (fileSrc: any): Promise<unknown> => {
      const reader = new FileReader();
      return new Promise((resolve, reject) => {
        reader.readAsDataURL(fileSrc);
        reader.onload = (): void => resolve(reader.result);
        reader.onerror = reject;
      });
    };

    async function* asyncGenerator(filesArray: any): AsyncGenerator<number, void, unknown> {
      let j = 0;
      while (j < filesArray.length) {
        yield (j += 1);
      }
    }

    try {
      const f = e.target.files;
      const previews: any = [];
      const filesArray = Array.from(f);
      if (filesArray.length) {
        // eslint-disable-next-line no-restricted-syntax
        for await (const j of asyncGenerator(filesArray)) {
          const i = j - 1;
          const fileOrigin: any = filesArray[i];
          size += fileOrigin.size;
          currentFileCount += 1;
          const { type } = fileOrigin;
          const base64 = await toBase64(fileOrigin);
          if (type !== 'image/jpeg' && type !== 'image/jpg' && type !== 'image/png') {
            wrongFormat = true;
          }
          previews.push(base64);
        }
      }
      if (wrongFormat) {
        clearUrl(previews);
        setErrorMessage({
          error: true,
          text: wrong_format,
        });
      } else if (size > filesSizeLimit) {
        clearUrl(previews);
        setErrorMessage({
          error: true,
          text: large_file_50,
        });
      } else if (currentFileCount > fileLimit) {
        clearUrl(previews);
        setErrorMessage({
          error: true,
          text: maximum_photos,
        });
      } else {
        setFileSizes(size);
        setImgSrc([...imgSrc, ...previews]);
      }
      setLoading(() => false);
    } catch (error) {
      setLoading(() => false);
      console.error('Upload file error:', error);
    }
  };

  // Delete photo from list
  const deletePhoto = (ind: number, type: 'single' | 'all'): void => {
    const copySrcForView = [...imgSrc];
    if (type === 'all') {
      setImgSrc([]);
      return;
    }
    copySrcForView.splice(ind, 1);
    setImgSrc([...copySrcForView]);
  };

  // JSX
  const loadingJSX = (loading || loadingFocuses || loadingSaveExercise) && <Loading />;

  const viewPhotoJsx = (
    <div className={styles['add-photo__attachment-container']}>
      <div className={styles['add-photo__attachment-wrapper']}>
        {imgSrc.map((photo: any, i: number) => (
          <div key={`Photo${String(i)}`}>
            <div className={styles['add-photo__img-container']}>
              <img src={photo} alt="Exercise" />
            </div>
            <div className={styles['add-photo__delete-btn']}>
              <DeleteIcon onClick={(): void => deletePhoto(i, 'single')} />
            </div>
          </div>
        ))}
      </div>
      <div className={styles['add-photo__attachments-label-container']}>
        {imgSrc.length > 1 && (
          <div
            className={styles['add-photo__delete-all']}
            onClick={(): void => deletePhoto(0, 'all')}
            role="presentation"
          >
            {delete_all}
          </div>
        )}
      </div>
    </div>
  );

  const btnsJsx = (
    <div className={styles['add-video__btns-container']}>
      <Button
        buttonType="button"
        buttonName={cancel}
        buttonClass={styles['add-video__btn']}
        buttonMethod={closeAttachment}
        disabledButton={!!loadingJSX}
      />
      <Button
        buttonClass={styles['add-video__btn']}
        buttonType="submit"
        buttonMethod={(): void => formik.handleSubmit()}
        buttonName={save}
        disabledButton={!!loadingJSX || !imgSrc.length}
      />
    </div>
  );

  return (
    <>
      {loadingJSX}
      <div className={pageStyles['left-section']}>
        <MainTitle title={add_photo_title} />
        <div className={pageStyles['section-description']}>{descr_one}</div>
        <div className={pageStyles['section-description']}>{descr_two}</div>

        {/* Form */}
        <form>
          <InputFloatLabel
            inputId="AddPhoto_name"
            inputName="name"
            inputType="text"
            placeholder={exercise_name}
            hasErrors={errors.name}
            inputValue={values.name}
            isTouched={touched.name}
            onChangeMethod={formik.handleChange}
            disabled={!!loadingJSX}
            isRedStar
          />
          {!imgSrc.length && !values.isTakePhoto && (
            <>
              <InputTypeFileAsText
                id="upload-file"
                inputName="file"
                labelName={add_photo}
                hasErrors={false}
                onChangeMethod={onCheckIncomingFiles}
                disabled={!!loadingJSX}
                fileInputKey={fileInputKey}
                accept="image/png, image/jpeg, image/jpg"
                isTouched
                isMultiple
              />
              <div className={styles['add-video__video-length']}>{maximum_photos}</div>
            </>
          )}
          {/* Settings */}
          {!!imgSrc.length && (
            <AddMediaSettingsForm
              formik={formik}
              focusType1Arr={focusType1Arr}
              focusType2Arr={focusType2Arr}
              exercisesCategories={exercisesCategories}
              exercisesProcedureFocus={exercisesProcedureFocus}
              errorSaveExercise={errorSaveExercise}
              loadingJSX={loadingJSX}
            />
          )}
          <div className={styles['add-video__btns-container']}>
            <ButtonWithContent
              buttonClass={styles['add-video__btn']}
              buttonType="button"
              buttonName={take_new_photo}
              buttonMethod={(): void => formik.setFieldValue('isTakePhoto', true)}
              icon={<AddPhotoGreenIcon />}
              colorStyle="green"
              active={values.isTakePhoto}
              disabledButton={!!imgSrc.length || !!loadingJSX}
            />
            <Button
              buttonClass={styles['add-video__btn']}
              buttonType="button"
              buttonName={cancel}
              buttonMethod={redirectToVideoBank}
              disabledButton={!!loadingJSX}
            />
          </div>
        </form>
      </div>

      {/* Attach file */}
      {!values.isTakePhoto && !!imgSrc.length && (
        <div className={pageStyles['right-section']}>
          <MainTitle title={attachment} />

          {/* View photo container */}
          {viewPhotoJsx}
          {btnsJsx}
        </div>
      )}

      {/* Record video */}
      {values.isTakePhoto && (
        <div className={pageStyles['right-section']}>
          <MainTitle title={take_new_photo} />

          <div className={styles['take-photo__container']}>
            <div className={styles['take-photo__recorder']} id="videoWrapper">
              <video ref={videoRef} autoPlay muted />

              {/* Flip */}
              {camList?.length > 1 && (
                <div className={styles['take-photo__flip-container']}>
                  <div
                    className={styles['take-photo__recorder-btn']}
                    onClick={(): void => setFront(!front)}
                    role="presentation"
                  >
                    <img src={FlipIcon} alt="Flip" />
                  </div>
                </div>
              )}

              {/* Recorder buttons */}
              <div className={styles['take-photo__recorder-btn-cont']}>
                <div className={styles['take-photo__recorder-btn-item']}>
                  <div
                    className={cx({
                      [styles['take-photo__recorder-btn']]: true,
                      [styles['take-photo__recorder-btn--start']]: true,
                      [styles['take-photo__recorder-btn--disable']]: imgSrc.length === fileLimit,
                    })}
                    onClick={imgSrc.length === fileLimit ? (): null => null : capture}
                    role="presentation"
                  >
                    <div className={styles['start-icon']} />
                  </div>
                </div>
              </div>

              {/* zoom */}
              {!isHiddenZoom && (
                <div className={styles['take-photo__zoom-container']}>
                  <div
                    className={cx({
                      [styles['take-photo__zoom-item']]: true,
                      [styles['take-photo__recorder-btn--disable']]: zoom <= zoomMin,
                    })}
                    onClick={(): void => handleZoomChange('minus')}
                    role="presentation"
                  >
                    -
                  </div>
                  <div className={styles['take-photo__zoom-solid']} />
                  <div
                    className={cx({
                      [styles['take-photo__zoom-item']]: true,
                      [styles['take-photo__recorder-btn--disable']]: zoom >= zoomMax,
                    })}
                    onClick={(): void => handleZoomChange('plus')}
                    role="presentation"
                  >
                    +
                  </div>
                </div>
              )}
            </div>
            <div className={styles['take-photo__recorder-warn']}>{maximum_photos}</div>
          </div>
          {viewPhotoJsx}
          {btnsJsx}
        </div>
      )}

      {/* Popups */}
      {errorMessage.error && (
        <Popup
          content=""
          title={errorMessage.text}
          buttonName={ok}
          onClosePopup={(): void => setErrorMessage(deepCopyObj(initErrState))}
        />
      )}

      {/* Success save folder */}
      {isSuccessSaved && (
        <Modal onClose={redirectToVideoBank}>
          <CloseBtn close={redirectToVideoBank} />
          <div>
            <div className={styles.icon}>
              <CheckIcon />
            </div>
            <div className={styles.content}>{`${values.name} ${createExercise}`}</div>
            <Button
              buttonClass={styles['exercise-folder-settings__btn-ok']}
              buttonType="button"
              buttonName={ok}
              buttonMethod={redirectToVideoBank}
            />
          </div>
        </Modal>
      )}

      {isDeviceDeny && (
        <Modal onClose={closeAttachment}>
          <CloseBtn close={closeAttachment} />
          <img
            className={styles['device-deny-modal__warn-img']}
            src={WarningIcon}
            alt="Warning icon"
          />
          <div className={styles['device-deny-modal__content-wrapper']}>{access_to_device}</div>
          <Button
            buttonType="button"
            buttonName={cancel}
            buttonClass={styles.modal__btn}
            buttonMethod={closeAttachment}
            disabledButton={loading}
          />
        </Modal>
      )}
    </>
  );
};

export default AddPhoto;
