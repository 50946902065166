import React, { ReactElement, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import cx from 'classnames';

import pageStyles from '../../styles.module.css';
import patientStyles from '../style.module.css';
import {
  GetState,
  getCurrenLang,
  getErrorPatientProfile,
  getLoadingPatientProfile,
  getPatientProfile,
} from '../../../redux/selector';
import { Loading } from '../../../common';
import { BreadcrumbAnt } from '../../../utils/routers/Breadcrumb';
import { getPatientName, getQueryParams } from '../../../utils/helper';
import { path } from '../../../utils';
import useCheckRole from '../../../hooks/useCheckRole';
import { useQueryPatientProfile } from '../../../graphql/patients';
import { StatusKeyNameEnum } from '../../../utils/enums';
import { fillPatientProfile } from '../../../redux/patient';

import ExercisePrescriptions from '../../../components/Video/ExercisePrescriptions';
import PdfPlans from '../../../components/Video/pdfPlans/pdfPlans';

const PrescriptionHistoryPage = (): ReactElement => {
  const t: any = useSelector<any>((state: GetState) => getCurrenLang(state));
  const patient_list = t?.title.patient_list;
  const patient_not_found = t?.common.patient_not_found;
  const prescription_history = t?.menu.prescription_history || 'Prescription History';

  const location = useLocation();
  const dispatch = useDispatch();
  const { userId, userStatus } = getQueryParams(location.search);
  const patientId = Number(userId);
  const patientStatus = String(userStatus);
  const { isAdmin, isHcp } = useCheckRole();

  // Redux
  const profileData: any = useSelector<any>((state: GetState) => getPatientProfile(state));
  const profileLoading = useSelector<any>((state: GetState) => getLoadingPatientProfile(state));
  const profileError: any = useSelector<any>((state: GetState) => getErrorPatientProfile(state));

  // Local state

  const email = profileData?.email || '';
  const userName = profileData ? `${profileData.firstName} ${profileData.lastName}` : '';

  // No data available for this user
  const notAllowed = useMemo(() => {
    if (!userStatus || !patientId || profileError) {
      return true;
    }
    return false;
  }, [patientId, userStatus, profileError]);

  const isInvitee = userStatus === StatusKeyNameEnum.invite_sent;

  // Endpoints
  // Get current patient profile from DB
  const { _getProfileById } = useQueryPatientProfile(patientStatus);

  // Send request to the database
  useEffect(() => {
    if (!notAllowed) {
      _getProfileById({
        variables: {
          patientId,
        },
      });
    }
  }, [notAllowed, patientId]);

  // Clear state after componentWillUnmount
  useEffect(
    () => (): void => {
      dispatch(fillPatientProfile(null));
    },
    [],
  );

  // JSX
  const loadingJSX = profileLoading && <Loading />;

  // Breadcrumb path
  const routes = useMemo(
    () => [
      {
        path: isAdmin ? path.adminDashboardList : path.dashboardList,
        breadcrumbName: patient_list,
      },
      {
        path: isAdmin
          ? `${path.patient_overview_admin}${location.search}`
          : `${path.patient_overview}${location.search}`,
        breadcrumbName: getPatientName(profileData),
      },
      {
        path: prescription_history,
        breadcrumbName: prescription_history,
      },
    ],
    [t, profileData],
  );

  return (
    <div className={pageStyles.main__container}>
      {loadingJSX}
      <BreadcrumbAnt currentRoutes={routes} />
      <div className={cx([pageStyles.main__wrapper])}>
        <div className={pageStyles['flex-container']}>
          {/* Exercise Prescriptions */}
          <div className={pageStyles['left-section']}>
            {notAllowed ? (
              <div className={patientStyles['profile-user__notfound']}>{patient_not_found}</div>
            ) : (
              <ExercisePrescriptions
                patientId={patientId}
                isInvitee={isInvitee}
                patient={profileData}
              />
            )}
          </div>

          {/* Prescribed Exercise List */}
          {!notAllowed && (
            <div className={pageStyles['right-section']}>
              <PdfPlans
                email={email}
                userName={userName}
                patientId={patientId}
                isInvitee={isInvitee}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PrescriptionHistoryPage;
