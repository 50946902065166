import React, { useEffect, useState } from 'react';
import cx from 'classnames';

import styles from './styles.module.css';
import { CheckedIcon } from '../../../theme/icons';
import { useQueryHcpPic } from '../../../graphql/hcpProfile';

const HcpAvatar = ({ hcpMemberHandler, isChecked, teamMember }: any): JSX.Element => {
  const { _getHcpPic, loadingHcpPic, hcpPic, dataHcpPic } = useQueryHcpPic();

  const [avatarSrc, setAvatarSrc] = useState<any>(null);

  useEffect(() => {
    if (teamMember?.photo) {
      _getHcpPic(teamMember?.photo);
    }
  }, [teamMember?.photo]);

  useEffect(() => {
    if (hcpPic && !loadingHcpPic) {
      setAvatarSrc(hcpPic);
    }
  }, [dataHcpPic]);

  return (
    <div
      onClick={(): void => hcpMemberHandler(teamMember.id)}
      aria-hidden
      className={styles['team-settings__hcp-avatar-wrapper']}
    >
      {avatarSrc ? (
        <img
          src={`data:image/jpeg;charset=utf-8;base64,${avatarSrc}`}
          alt="Avatar"
          crossOrigin="anonymous"
        />
      ) : (
        <></>
      )}
      <div
        className={cx({
          [styles.hide]: true,
          [styles['team-settings__hcp-avatar--checked-bg']]: isChecked,
        })}
      />
      <div
        className={cx({
          [styles.hide]: true,
          [styles['team-settings__checked']]: isChecked,
        })}
      >
        <CheckedIcon />
      </div>
    </div>
  );
};

export default HcpAvatar;
