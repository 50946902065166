import React, { ReactElement } from 'react';
import cx from 'classnames';

import style from './styles.module.css';

interface EmojiPropTypes {
  id: string;
  selectedEmoji: any;
  defaultEmoji: any;
  selected: boolean;
  anySelected: boolean;
  onSelect: (id: string) => void;
}

export const Emoji = ({
  id,
  selected = false,
  anySelected,
  selectedEmoji,
  defaultEmoji,
  onSelect,
}: EmojiPropTypes): ReactElement => (
  <div
    onClick={(): void => onSelect(id)}
    className={cx({
      [style.emoji__container]: true,
      [style['emoji__container--active']]: !anySelected || !selected,
      [style['emoji__container--selected']]: selected,
    })}
    aria-hidden
  >
    {!anySelected || selected ? (
      <img src={selectedEmoji} alt="Emoji" />
    ) : (
      <img src={defaultEmoji} alt="Emoji" />
    )}
  </div>
);
