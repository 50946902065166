import React from 'react';

import styles from './style.module.css';
import { MEDIA_TYPE } from '../../../../utils/enums';
import { PatientInfoType } from '../../types';
import PdfView from '../PdfView';
import { MsWordIcon, NoImage, PlayVideoIcon } from '../../../../theme/icons';

// Get Icon for each file type
const getPreview = (
  item: PatientInfoType,
  videoModalOpen: any,
  setVideoSrcState: any,
): JSX.Element => {
  const { mediaType } = item;
  if (mediaType) {
    // const typeFile = `.${fileName.split('.').reverse()[0]}`;
    // if (imageTypes.includes(typeFile)) {
    if (mediaType === MEDIA_TYPE.PHOTO) {
      return (
        <div>
          <a href={item.fileUuid}>
            <img src={item.fileUuid} alt="Preview" />
          </a>
        </div>
      );
    }
    if (mediaType === MEDIA_TYPE.PDF) {
      // if (pdfType.includes(typeFile)) {
      return (
        <div>
          <a href={item.fileUuid}>
            <PdfView pdfLink={item.fileUuid} />
          </a>
        </div>
      );
    }
    if (mediaType === MEDIA_TYPE.WORD) {
      // if (wordTypes.includes(typeFile)) {
      return (
        <div>
          <a href={item.fileUuid}>
            <img src={MsWordIcon} alt="Preview" />
          </a>
        </div>
      );
    }
    if (mediaType === MEDIA_TYPE.VIDEO) {
      // if (videoTypes.includes(typeFile)) {
      return (
        <div
          onClick={(): void => {
            videoModalOpen();
            setVideoSrcState(item.videoUuid);
          }}
          role="presentation"
        >
          {/* <a href={item.fileUuid}> */}
          {item.thumbnailUuid ? (
            <img src={item.thumbnailUuid} alt="Preview" />
          ) : (
            <img src={NoImage} alt="Preview" />
          )}
          {/* </a> */}
          <PlayVideoIcon className={styles['start--btn']} />
        </div>
      );
    }
    return <>-</>;
  }
  return <>-</>;
};

export default getPreview;
