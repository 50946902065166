import React, { ReactElement } from 'react';

import styles from './styles.module.css';
import { SearchBlueIcon, SearchGreyIcon } from '../../theme/icons';

interface SearchClientProps {
  inputId: string;
  name: string;
  placeholder: string;
  inputValue: string;
  changeFilter: any;
  dataQa?: string;
}

const SearchClient = ({
  inputId,
  name,
  placeholder,
  inputValue,
  changeFilter,
  dataQa,
}: SearchClientProps): ReactElement => (
  <label htmlFor="dashSearch" className={styles.table__search_container}>
    <input
      id={inputId}
      name={name}
      className={styles['table__search-input']}
      placeholder={placeholder}
      type="text"
      value={inputValue}
      onChange={changeFilter}
      data-qa={dataQa}
    />
    <div className={styles['table__search-icon']}>
      {inputValue ? <SearchBlueIcon /> : <SearchGreyIcon />}
    </div>
  </label>
);

export default SearchClient;
