// Core
import React, { useLayoutEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import storage from '../storage';
import { path } from './book';
import { parseJwt } from '../parseJwt';
import { PrivatRouteProps } from './types';
import PrivateCommonComponent from '../../components/auth/PrivateCommonComponent';
import { Dashboard } from '../../components';

const PrivateRoute = ({
  component: Component,
  path: routePath,
  successPath,
  routes,
  ...rest
}: PrivatRouteProps): JSX.Element => {
  const user = storage.get('user');
  const userRole = user?.role;
  const isAuthenticated = user?.token;
  const validToken = parseJwt(isAuthenticated);

  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;

  const pathAdmin = pathname === path.admin;
  const pathHcp = pathname === path.home;

  const pathAdminSignin = pathname === path.adminSignIn || pathname === path.admin;
  const pathHcpSignin = pathname === path.signIn || pathname === path.home;

  // const isAdmin = userRole === constants.admin || pathname === path.adminSignIn;
  // const isHcp = userRole === constants.hcp || pathname === path.signIn;

  const isPathAdmin = pathname && !!pathname.match(/admin/);

  useLayoutEffect(() => {
    if (isAuthenticated) {
      if (validToken.exp * 1000 < Date.now()) {
        storage.save('user', '');
        if (isPathAdmin) {
          history.push(path.adminSignIn);
        } else {
          history.push(path.signIn);
        }
      }
    }
    if (!isAuthenticated) {
      history.push({
        pathname: pathAdmin ? path.adminSignIn : path.signIn,
      });
    }
  }, [pathHcp, pathAdmin, userRole, pathAdminSignin, pathHcpSignin]);

  return (
    <>
      {isAuthenticated ? (
        <>
          <PrivateCommonComponent />
          <Dashboard successPath={successPath} routes={routes} />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default PrivateRoute;
