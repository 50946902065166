import React, { ReactElement, useMemo } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import moment from 'moment';

import { Button } from '../../../common';
import styles from '../styles.module.css';
import { GetState, getCurrenLang, getCurrentFormatDate } from '../../../redux/selector';
import { PatientInvitationProp } from '../types';

const PatientInvitation = ({
  invitation,
  isModal,
  acceptHandler,
  loading,
}: PatientInvitationProp): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const accept = t?.notifications.accept;
  const decline = t?.notifications.decline;
  const { id, firstName, lastName, email, phone, dateInvited } = invitation;

  const formatsDate = useSelector((state: GetState) => getCurrentFormatDate(state));

  const concatName = useMemo(() => {
    if (isModal) {
      const firstLastName = firstName || lastName ? `${firstName} ${lastName}` : '-';
      return firstLastName;
    }
    return firstName || '-';
  }, [firstName, lastName]);

  // JSX
  const actionBtns = (
    <div
      className={cx({
        [styles['table__row-btn-container']]: true,
        [styles['table__row-btn-container--modal']]: isModal,
      })}
    >
      <Button
        buttonName={decline}
        buttonType="button"
        buttonClass={styles['btn__decline-invitation']}
        buttonMethod={(): void => acceptHandler('decline', id)}
        disabledButton={loading}
      />
      <Button
        buttonName={accept}
        buttonType="button"
        buttonClass={styles['btn__accept-invitation']}
        buttonMethod={(): void => acceptHandler('accept', id)}
        disabledButton={loading}
        colorStyle="green"
      />
    </div>
  );

  return (
    <div className={styles.table__row}>
      {isModal ? <></> : <div className={styles.table__bg}>{actionBtns}</div>}
      <div
        className={cx({
          [styles['table__first-name']]: true,
          [styles.table__bg]: true,
          [styles['table__first-name--modal']]: isModal,
        })}
      >
        {concatName}
      </div>
      <div
        className={cx({
          [styles['table__last-name']]: true,
          [styles.table__bg]: true,
          [styles['hide-in-modal']]: isModal,
        })}
      >
        {lastName || '-'}
      </div>
      {/* Sm screen */}
      {concatName !== '-' && (
        <div
          className={cx({
            [styles['table__first-name--sm']]: true,
            [styles.table__sm]: true,
          })}
        >
          {concatName}
        </div>
      )}
      <div
        className={cx({
          [styles.table__email]: true,
          [styles['table__email--modal']]: isModal,
        })}
      >
        {email}
      </div>
      <div
        className={cx({
          [styles.table__phone]: true,
          [styles['table__phone--modal']]: isModal,
        })}
      >
        {phone}
      </div>
      <div
        className={cx({
          [styles.table__date]: true,
          [styles['hide-in-modal']]: isModal,
        })}
      >
        {dateInvited ? moment(Number(dateInvited)).format(formatsDate.momentFormat) : '-'}
      </div>
      {!isModal ? <div className={styles.table__sm}>{actionBtns}</div> : <></>}
      {isModal ? actionBtns : <></>}
    </div>
  );
};

export default PatientInvitation;
