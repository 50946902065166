import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import moment from 'moment';

import styles from './styles.module.css';
import { Button, Modal } from '../../../../common';
import {
  GetState,
  getCurrenLang,
  getCurrentFormatDate,
  getHcpName,
  getPatientProfile,
} from '../../../../redux/selector';
import { CloseBtn } from '../../../../common/Button/Button';
import { LogoOTM } from '../../../../theme/icons';
import AppStore from '../../../../theme/image/appStore.png';
import GooglePlay from '../../../../theme/image/googlePlay.png';
import { config } from '../../../../utils/configs';
import { getPatientName } from '../../../../utils/helper';
import ExerciseView from '../ExerciseView';
import { PdfExercisesPlanPreviewProps } from '../types';

const PdfExercisesPlanPreview = ({
  data,
  closePreview,
  isCreateDPF,
  counting,
  setCreateDPF,
  loading,
}: PdfExercisesPlanPreviewProps): ReactElement => {
  const t: any = useSelector<any>((state: GetState) => getCurrenLang(state));
  const continue_btn = t?.common.continue;
  const exercise_plan = t?.dashboard.hcp.profile_patient.exercise_plan;
  const send_by_name = t?.dashboard.hcp.profile_patient.video_bank.send_by_name;
  const pdf_created = t?.dashboard.hcp.profile_patient.video_bank.pdf_created;
  const export_pdf_preview_descr =
    t?.dashboard.hcp.profile_patient.video_bank.export_pdf_preview_descr;
  const formatDate = useSelector((state: GetState) => getCurrentFormatDate(state));
  const hcp: any = useSelector<any>((state: GetState) => getHcpName(state));
  const send_by = send_by_name
    ?.replace('<first_last_name>', `${hcp.firstName} ${hcp.lastName}`)
    .replace('<organisation>', hcp.hospital);
  const pdf_plan_created = pdf_created?.replace('<date>', moment().format(formatDate.momentFormat));

  // Redux
  const profileData: any = useSelector<any>((state: GetState) => getPatientProfile(state));
  const patientName = getPatientName(profileData);

  return (
    <Modal
      onClose={closePreview}
      style={cx({
        [styles['preview-pdf__modal']]: true,
        [styles['preview-pdf__modal_hide']]: isCreateDPF,
      })}
    >
      <CloseBtn close={closePreview} />
      <div id="video-exercises-to-pdf">
        {/* Header */}
        <div className={styles['preview-pdf__header-container']}>
          <div className={styles['preview-pdf__logo-wrapper']}>
            <div className={styles['preview-pdf__logo']}>
              <img src={LogoOTM} alt="Otm" />
            </div>
            {/* <LogoOtm /> */}
            <Button
              buttonName={continue_btn}
              buttonMethod={(): void => setCreateDPF(() => true)}
              disabledButton={loading}
              buttonType="button"
              buttonClass={cx({
                [styles['preview-pdf__btn']]: true,
                [styles['preview-pdf__hide']]: isCreateDPF,
              })}
            />
          </div>
          <div
            className={cx({
              [styles['preview-pdf__header-description']]: true,
              [styles['preview__create-pdf']]: isCreateDPF,
            })}
          >
            {export_pdf_preview_descr}
          </div>

          <div
            className={cx({
              [styles['preview-pdf__store-wrapper']]: true,
              [styles['preview-pdf__store-wrapper--create-pdf']]: isCreateDPF,
            })}
          >
            <div>
              <a href={config.ANDROID_LINK}>
                <img
                  src={GooglePlay}
                  alt="Google Play"
                  className={styles['preview-pdf__store-img']}
                />
              </a>
            </div>
            <div>
              <a href={config.IOS_LINK}>
                <img src={AppStore} alt="App Store" className={styles['preview-pdf__store-img']} />
              </a>
            </div>
          </div>
          <div
            className={cx({
              [styles['preview-pdf__name-wrapper--create-pdf']]: isCreateDPF,
            })}
          >
            <div className={styles['preview-pdf__patient-name']}>
              {`${exercise_plan} ${patientName}`}
            </div>
            <div className={styles['preview-pdf__hcp-name']}>{send_by}</div>
            <div className={styles['preview-pdf__hcp-name']}>{pdf_plan_created}</div>
          </div>
        </div>

        {/* Exercises */}
        {data.map((exercise: any, indx: number) => (
          <ExerciseView
            t={t}
            key={`ExerciseView${String(indx)}`}
            data={exercise}
            counting={counting}
            isCreateDPF={isCreateDPF}
            formatDate={formatDate.momentFormat}
          />
        ))}
      </div>
    </Modal>
  );
};

export default PdfExercisesPlanPreview;
