import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';

import styles from './styles.module.css';
import { GetState, getCurrenLang } from '../../../../../redux/selector';
import { ActionItem, SelectElem, SortSearchItem } from '../HeaderItems';

const TableHeader = ({
  selectExercise,
  setSelectExercise,
  selectAction,
  sortByColumnName,
  sortBy,
  handleSearch,
  handleReset,
  searchMatches,
  findMatches,
  hideSearchMenu,
  isSearch,
  setHideSearchMenu,
  setSearchMatches,
  actionBtnName,
}: any): ReactElement => {
  const t: any = useSelector<GetState>((state) => getCurrenLang(state));
  const action = t?.dashboard.hcp.profile_patient.video_bank.action;
  const exercise_name = t?.dashboard.hcp.profile_patient.video_bank.exercise_name;
  const media_type = t?.hcp.org_video_bank.media_type;
  const setting_last_changed = t?.hcp.org_video_bank.setting_last_changed;

  return (
    <div className={styles['org-video-bank__header']}>
      {/* Select */}
      <div className={styles['org-video-bank__select-container']}>
        <SelectElem
          selectElem={selectExercise}
          setSelectElem={setSelectExercise}
          openMethod={selectAction}
          disabled={false}
          submitBtnName={actionBtnName}
        />
      </div>
      <div className={styles['org-video-bank__select-empty']} />

      {/* Media type */}
      <div className={styles['org-video-bank__media-container']}>
        <SortSearchItem
          fieldName={media_type}
          t={t}
          sortName="type"
          sortByColumnName={sortByColumnName}
          sortBy={sortBy}
        />
      </div>
      {/* Exercise name */}
      <div className={styles['org-video-bank__exercise-name-container']}>
        <SortSearchItem
          fieldName={exercise_name}
          t={t}
          sortName="name"
          sortByColumnName={sortByColumnName}
          sortBy={sortBy}
          withSearch
          handleSearch={handleSearch}
          handleReset={handleReset}
          searchMatches={searchMatches}
          findMatches={findMatches}
          hideSearchMenu={hideSearchMenu}
          isSearch={isSearch}
          setHideSearchMenu={setHideSearchMenu}
          setSearchMatches={setSearchMatches}
        />
      </div>
      {/* Setting last changed */}
      <div className={styles['org-video-bank__last-changed-container']}>
        <SortSearchItem
          t={t}
          fieldName={setting_last_changed}
          sortName="exerciseUpdatedAt"
          sortByColumnName={sortByColumnName}
          sortBy={sortBy}
        />
      </div>
      <div className={styles['org-video-bank__action']}>
        <ActionItem name={action} />
      </div>
    </div>
  );
};

export default TableHeader;
