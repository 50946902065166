import React, { ReactElement } from 'react';
import cx from 'classnames';
import moment from 'moment';
import { sanitize } from 'dompurify';

import style from './style.module.css';
import { AnswerConfig, AnswerScale, PcsContentProps, Question } from './types';
import { getPatientAnswers } from '../../../../../utils/helper_charts';
import { RemoveQuestionnaire } from './common/RemoveQuestionnaire';
import { CalculationDescription } from './common/Calculation';
import { PROMS_NAME } from '../../../../../utils/enums';

export const PcsContent = ({
  questionnairesList,
  completedQuestionnaire,
  patient,
  index,
  onDeleteItem,
  formatDate,
  t,
}: PcsContentProps): ReactElement => {
  const remove_questionnaire = t?.dashboard.hcp.profile_patient.questionnaires.remove_questionnaire;
  const pcs_calc_description = t?.dashboard.hcp.profile_patient.questionnaires.pcs_calc_description;
  const pcs_name = t?.dashboard.hcp.profile_patient.questionnaires.pcs_name;
  const not_at_all = t?.dashboard.hcp.profile_patient.questionnaires.not_at_all;
  const slight_degree = t?.dashboard.hcp.profile_patient.questionnaires.slight_degree;
  const moderate_degree = t?.dashboard.hcp.profile_patient.questionnaires.moderate_degree;
  const greate_degree = t?.dashboard.hcp.profile_patient.questionnaires.greate_degree;
  const all_the_time = t?.dashboard.hcp.profile_patient.questionnaires.all_the_time;
  const client_No = t?.dashboard.hcp.profile_patient.questionnaires.client_No;
  const age = t?.dashboard.hcp.profile_patient.questionnaires.age;
  const sex = t?.dashboard.hcp.profile_patient.questionnaires.sex;
  const m = t?.dashboard.hcp.profile_patient.questionnaires.m;
  const f = t?.dashboard.hcp.profile_patient.questionnaires.f;
  const date = t?.dashboard.hcp.profile_patient.questionnaires.date;
  const pcs_descr_one = t?.dashboard.hcp.profile_patient.questionnaires.pcs_descr_one;
  const pcs_descr_two = t?.dashboard.hcp.profile_patient.questionnaires.pcs_descr_two;
  const when_im_in_pain = t?.dashboard.hcp.profile_patient.questionnaires.when_im_in_pain;
  const total = t?.dashboard.hcp.profile_patient.questionnaires.total;
  const updated = t?.dashboard.hcp.profile_patient.questionnaires.updated;

  const pcsList = questionnairesList.filter((item: any) => item.name === PROMS_NAME.PCS)[0];
  const { questions } = pcsList;
  const { completedDate, totalScore, questions: completedQuestions } = completedQuestionnaire;
  const patientAnswers = getPatientAnswers(completedQuestions);
  const { gender, dob } = patient;
  const calculateAge = (): number | string => {
    if (dob) {
      const pastDate = Number(moment(dob).format('YYYYMMDD'));
      const currentDate = Number(moment().format('YYYYMMDD'));
      const ageCalc = Math.floor((currentDate - pastDate) * 0.0001);
      return ageCalc > 0 ? ageCalc : 'Under 1';
    }
    return '';
  };
  const answerConfig: AnswerConfig = {
    1: { name: not_at_all, value: 0 },
    2: { name: slight_degree, value: 1 },
    3: { name: moderate_degree, value: 2 },
    4: { name: greate_degree, value: 3 },
    5: { name: all_the_time, value: 4 },
  };
  const answerScale: AnswerScale[] = Object.values(answerConfig);

  return (
    <>
      <RemoveQuestionnaire onMethod={(): any => onDeleteItem(index)} title={remove_questionnaire} />
      <div className="questionnaire-to-PDF">
        <div
          className={cx({
            [style.pcs__container]: true,
            [style.container]: true,
            [style.description__container]: true,
          })}
        >
          <CalculationDescription
            name={pcs_name}
            content={pcs_calc_description}
            date={completedDate ? moment(completedDate).format(formatDate) : ''}
            t={t}
          />
        </div>
      </div>
      <div className="questionnaire-to-PDF">
        <div
          className={cx({
            [style.pcs__container]: true,
            [style.container]: true,
          })}
        >
          <div className={style['pcs__header-container']}>
            <div className={style['pcs__header-logo']}>&#936;</div>
            <div className={style['pcs__header-copy']}>
              <span>Copyright &copy; 1995</span>
              <span>Michael JL Sullivan</span>
            </div>
          </div>
          <div className={style['pcs__header-name']}>PCS-EN</div>
          <div className={style['pcs__client-info-container']}>
            <div>{client_No} __________</div>
            <div className={style['pcs_client-info-age-cont']}>
              {age}
              <div className={style['pcs_client-info-age']}>{calculateAge()}</div>
            </div>
            <div className={style['pcs_client-info-age-cont']}>
              {`${sex} ${m}(`}
              <div className={style['pcs__client-info-sex']}>
                {gender === 2 ? <>&#10004;</> : '-'}
              </div>
              {`) ${f}(`}
              <div className={style['pcs__client-info-sex']}>
                {gender === 1 ? <>&#10004;</> : '-'}
              </div>
              )
            </div>
            <div className={style['pcs_client-info-age-cont']}>
              {date}
              <div className={style['pcs_client-info-date']}>
                {completedDate ? moment(completedDate).format(formatDate) : ''}
              </div>
            </div>
          </div>
          <p className={style.pcs_text}>{pcs_descr_one}</p>
          <p className={style.pcs_text}>{pcs_descr_two}</p>
          <div className={style['pcs_common-info']}>
            {answerScale.map((answer: { name: string; value: number }, i: number) => (
              <span key={answer.name}>
                <b>{i}</b>
                &nbsp;-&nbsp;
                {answer.name}
              </span>
            ))}
          </div>
          <div className={style['pcs_common-when-pain']}>
            <b>{when_im_in_pain}</b>
          </div>
          {questions.map((question: Question, indx: number) => {
            const answerId = patientAnswers[question.id];
            return (
              <div className={style.pcs__row} key={question.name}>
                <sub>{indx + 1}</sub>
                <div className={style.pcs__squere}>{answerConfig[answerId]?.value}</div>
                <p
                  className={style.pcs__question}
                  dangerouslySetInnerHTML={{ __html: sanitize(question.name) }}
                />
              </div>
            );
          })}
          <div className={style['pcs__total-container']}>
            <div className={style.pcs__total}>
              ...
              {total}
              &nbsp;
              {totalScore || ''}
            </div>
            <div className={style.pcs__updated}>{`${updated} 11/11`}</div>
          </div>
        </div>
      </div>
    </>
  );
};
