import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import styles from './styles.module.css';
import { getCurrenLang } from '../../../../../redux/selector';
import { getOptions, getTouched } from '../../../../../utils/helper';
import { TypeValue, InitialValuesForm, SetsTypeValue } from '../../../VideoExercise/types';
import { exerciseConfig } from '../../../../../utils/variables';
import {
  InputFloatLabel,
  RadioConverter,
  RoundCheckbox,
  TextareaFeildFloatLabel,
} from '../../../../../common/Input';
import { SelectFloatLabel } from '../../../../../common/Input/Select';
import { ShowErrorNotification } from '../../../../Form/ErrorMessage';
import { TFocuses, TFocusesProcedure } from '../../types';

const AddMediaSettingsForm = ({
  formik,
  focusType1Arr,
  focusType2Arr,
  exercisesCategories,
  exercisesProcedureFocus,
  errorSaveExercise,
  loadingJSX,
  dataQa,
}: any): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const comments = t?.dashboard.hcp.profile_patient.video_bank.comments;
  const FocusOfTheExercise = t?.dashboard.hcp.profile_patient.video_bank.focus_of_the_exercise;
  const SelectAllThatApply = t?.dashboard.hcp.profile_patient.video_bank.select_all_that_apply;
  const exercise_details = t?.hcp.org_video_bank?.exercise_details;

  const { isValid, values } = formik;
  const hasFocusErrors = formik.errors.focus1;
  const isFocusTouched = getTouched(formik.touched);

  const onRadioChange = (dimProp: string, dimValue: string): void => {
    formik.setFieldValue(dimProp, dimValue);
  };

  return (
    <div className={styles.settings__details}>
      <div className={styles.settings__title}>{FocusOfTheExercise}</div>
      <div className={styles.settings__subtitle}>{SelectAllThatApply}</div>
      <div className={styles['settings__focuses-container']}>
        {focusType2Arr?.length > 0 &&
          focusType2Arr.map((focusItem: string, i: number) => {
            const currentFocusNumber = `focus${i + 1}` as keyof TFocuses;
            const currentName = exercisesCategories[currentFocusNumber]?.name;
            return (
              <label
                key={`focus${focusItem}`}
                htmlFor={currentName}
                className={cx({
                  [styles['checkbox-container']]: true,
                })}
              >
                <div
                  className={cx({
                    [styles.customCheckbox]: true,
                    [styles.error]: !isValid && isFocusTouched && hasFocusErrors,
                  })}
                >
                  <input
                    id={currentName}
                    name={`focus${i + 1}`}
                    type="checkbox"
                    value={currentName}
                    checked={values[currentFocusNumber]}
                    onChange={formik.handleChange}
                    disabled={!!loadingJSX}
                    data-qa={dataQa}
                  />
                  <span className={styles.checkmark} />
                </div>
                <div>{currentName}</div>
              </label>
            );
          })}
      </div>
      <div
        className={cx({
          [styles['settings__focuses-container']]: true,
        })}
      >
        {focusType1Arr?.length > 0 &&
          focusType1Arr.map((focusItem: string, i: number) => {
            const currentFocusNumber = `procedure${i + 1}` as keyof TFocusesProcedure;
            const currentName = exercisesProcedureFocus
              ? exercisesProcedureFocus[currentFocusNumber].name
              : '';
            return (
              <div className={styles['settings__roundchexbox-container']}>
                <RoundCheckbox
                  htmlId={currentName}
                  name={`procedure${i + 1}`}
                  label={currentName}
                  checked={values[currentFocusNumber]}
                  onChangeMethod={formik.handleChange}
                  isTouched={false}
                  hasErrors={false}
                  disabled={!!loadingJSX}
                />
              </div>
            );
          })}
      </div>
      <div className={styles.settings__title}>{exercise_details}</div>
      <div className={styles.settings__subrow}>
        {t &&
          exerciseConfig(t).repsTime.map((repsOrTime: { value: string; label: string }) => (
            <div className={styles.settings__elem} key={`repsOrTime${repsOrTime.label}`}>
              <RadioConverter
                labelName={repsOrTime.label}
                handleChange={(): void => onRadioChange('dim', repsOrTime.value)}
                inputName="time"
                value={values.dim}
                checkedRadio={values.dim === repsOrTime.value}
                tip=""
                id={`${repsOrTime.value}Radio`}
              />
            </div>
          ))}
      </div>

      <div
        className={cx({
          [styles.settings__subrow]: true,
          [styles['settings__space-subrow']]: true,
          [styles.invisible]: values.dim === 'reps',
        })}
      >
        {t &&
          exerciseConfig(t).minSec.map((minOrSec: TypeValue) => (
            <div
              className={styles['settings__select-field-time']}
              key={`minOrSec${minOrSec.label}`}
            >
              <SelectFloatLabel
                inputId={`minOrSec${minOrSec.label}`}
                name={minOrSec.value}
                onChange={formik.setFieldValue}
                inputValue={values[minOrSec.value as keyof InitialValuesForm]}
                placeholder={minOrSec.label}
                options={getOptions(0, 59)}
                type="org video bank"
                disabledField={!!loadingJSX}
              />
            </div>
          ))}
      </div>

      <div
        className={cx({
          [styles.settings__subrow]: true,
          [styles['settings__space-subrow']]: true,
          [styles['settings__is-time']]: values.dim === 'time',
        })}
      >
        {t &&
          exerciseConfig(t).sets.map((set: SetsTypeValue) => (
            <div
              key={`sets${set.label}`}
              className={cx({
                [styles['settings__reps-fields']]: true,
                [styles.invisible]: values.dim === 'time' && set.value === 'reps',
              })}
            >
              <InputFloatLabel
                inputId={`Videobank_${set.label}`}
                inputName={set.value}
                placeholder={set.label}
                inputType="text"
                inputValue={values[set.value as keyof InitialValuesForm]}
                hasErrors={formik.errors[set.value as keyof InitialValuesForm]}
                isTouched
                onChangeMethod={formik.handleChange}
                disabled={!!loadingJSX}
              />
            </div>
          ))}
      </div>
      <TextareaFeildFloatLabel
        id="exerciseTextArea"
        name="comments"
        value={values.comments}
        placeholder={comments}
        onChange={formik.handleChange}
        rows={4}
        disabled={!!loadingJSX}
      />

      {/* Errors */}
      {errorSaveExercise ||
        (hasFocusErrors && isFocusTouched && (
          <div className={styles.errorMessages}>
            <ShowErrorNotification errorMessage={errorSaveExercise} />
            <ShowErrorNotification errorMessage={hasFocusErrors} />
          </div>
        ))}
    </div>
  );
};

export default AddMediaSettingsForm;
