import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';

import styles from './styles.module.css';
import { GetState, getCurrenLang } from '../../../../redux/selector';
import {
  ActionItem,
  SortSearchItem,
} from '../../../Video/OrganisationVideoBank/components/HeaderItems';

const OrgPatientInfoTableHeader = ({
  sortByColumnName,
  sortBy,
  handleSearch,
  handleReset,
  searchMatches,
  findMatches,
  hideSearchMenu,
  isSearch,
  setHideSearchMenu,
  setSearchMatches,
}: any): ReactElement => {
  const t: any = useSelector<GetState>((state) => getCurrenLang(state));
  const action = t?.dashboard.hcp.profile_patient.video_bank.action;
  const media_type = t?.hcp.org_video_bank.media_type;
  const setting_last_changed = t?.hcp.org_video_bank.setting_last_changed;
  const name_t = t?.hcp.manage_patient_information?.name;
  const focus = t?.dashboard.hcp.table.procedure;

  return (
    <div className={styles['org-patient-info__header']}>
      {/* Media type */}
      <div className={styles['org-patient-info__media-container']}>
        <SortSearchItem
          t={t}
          fieldName={media_type}
          sortName="mediaType"
          sortByColumnName={sortByColumnName}
          sortBy={sortBy}
        />
      </div>
      {/* Exercise name */}
      <div className={styles['org-patient-info__exercise-name-container']}>
        <SortSearchItem
          t={t}
          fieldName={name_t}
          sortName="hospitals_videos.name"
          sortByColumnName={sortByColumnName}
          sortBy={sortBy}
          withSearch
          handleSearch={handleSearch}
          handleReset={handleReset}
          searchMatches={searchMatches}
          findMatches={findMatches}
          hideSearchMenu={hideSearchMenu}
          isSearch={isSearch}
          setHideSearchMenu={setHideSearchMenu}
          setSearchMatches={setSearchMatches}
        />
      </div>
      {/* Setting last changed */}
      <div className={styles['org-patient-info__last-changed-container']}>
        <SortSearchItem
          t={t}
          fieldName={setting_last_changed}
          sortName="lastChanged"
          sortByColumnName={sortByColumnName}
          sortBy={sortBy}
        />
      </div>
      {/* Focus */}
      <div className={styles['org-patient-info__focus']}>
        <ActionItem name={focus} />
      </div>
      {/* Action */}
      <div className={styles['org-patient-info__action']}>
        <ActionItem name={action} />
      </div>
    </div>
  );
};

export default OrgPatientInfoTableHeader;
