import React, { ReactElement, useEffect, useState } from 'react';
import cx from 'classnames';
import { Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import styles from './styles.module.css';
import { HcpTeamProps, TeamPatientsType } from '../types';
import { GetState, getCurrenLang, getTeamSettingsData } from '../../../redux/selector';
import { setOpenTeamSettingData } from '../../../redux/rehabTeams';

const HcpTeam = ({ data, isPermissionCreateTeam }: HcpTeamProps): ReactElement => {
  const t: any = useSelector<GetState>((state) => getCurrenLang(state));
  const settings_t = t?.dashboard.hcp.profile_patient.video_bank.settings;
  const dont_have_admin_right = t?.hcp.rehab_teams?.dont_have_admin_right;
  const patient_requests = t?.hcp.rehab_teams?.patient_requests;

  // Local state
  const [isPatientReqIcon, setPatientReqIcon] = useState(false);

  // Redux
  const isOpenSettings: any = useSelector<GetState>((state) => getTeamSettingsData(state));

  const dispatch = useDispatch();
  // const locale = useCheckLocale();
  // const { lng, lacaleArr } = locale;
  // moment.locale(lng, lacaleArr);

  const { id: hospitalTeamId } = data;
  const teamIsActive = isOpenSettings?.id === hospitalTeamId;

  // Check patient requests
  useEffect(() => {
    if (data.teamPatients?.length) {
      let isHasRequest = false;
      data.teamPatients.map((patient: TeamPatientsType) => {
        if (patient.removeRequest && patient.removeRequestDate) {
          isHasRequest = true;
        }
        return null;
      });
      if (isHasRequest) {
        setPatientReqIcon(() => true);
      }
    }
  }, [data?.teamPatients]);

  // Check data change if yes update Redux for opened settings
  useEffect(() => {
    if (teamIsActive) {
      dispatch(setOpenTeamSettingData(data));
    }
  }, [data]);

  // Close form and clear state
  const closeSettings = (): void => {
    dispatch(setOpenTeamSettingData(null));
  };

  // Open current team setting
  const openTeamSettings = (): void => {
    if (!isPermissionCreateTeam) {
      return;
    }
    if (teamIsActive) {
      closeSettings();
    } else {
      dispatch(setOpenTeamSettingData(data));
    }
  };

  const numberOfTeamHcps = data.teamHcps?.length;
  const numberOfTeamPatients = data.teamPatients?.length;

  return (
    <div className={styles.row}>
      {/* Name and Description */}
      <div
        className={cx({
          [styles['hcp-team__name-descr-container']]: true,
          [styles['hcp-team__name-descr-container--pd']]: isPatientReqIcon,
        })}
      >
        <div className={styles['hcp-team__name-descr-wrapper']}>
          <div className={styles['hcp-team__name']}>{data.name}</div>
          <div className={styles['hcp-team__descr']}>{data.description}</div>
        </div>
        {isPatientReqIcon && (
          <Tooltip title={patient_requests}>
            <div
              onClick={openTeamSettings}
              className={styles['hcp-team__patient-request']}
              aria-hidden
            >
              i
            </div>
          </Tooltip>
        )}
      </div>

      {/* Hcp */}
      <div className={styles['hcp-team__hcp-number']}>{numberOfTeamHcps || '-'}</div>

      {/* Patients */}
      <div className={styles['hcp-team__patient-number']}>{numberOfTeamPatients || '-'}</div>

      {/* Sm screen */}
      <div className={styles['rehab_teams__sm-screen']}>
        <div className={styles['hcp-team__hcp-number--sm']}>{numberOfTeamHcps || '-'}</div>
        <div className={styles['hcp-team__patient-number--sm']}>{numberOfTeamPatients || '-'}</div>
      </div>

      {/* Open settings button */}
      <div className={styles['hcp-team__action']}>
        <Tooltip title={isPermissionCreateTeam ? '' : dont_have_admin_right}>
          <div
            className={cx({
              [styles['hcp-team__settings-btn']]: true,
              [styles['hcp-team__settings-btn--active']]: teamIsActive,
              [styles['hcp-team__settings-btn--disable']]: !isPermissionCreateTeam,
            })}
            onClick={isPermissionCreateTeam ? openTeamSettings : (): null => null}
            aria-hidden
          >
            {settings_t}
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default HcpTeam;
