import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import cx from 'classnames';

import styles from './styles.module.css';
import { Button } from '../../common';
import { getCurrenLang } from '../../redux/selector';
import { CustomCheckbox } from '../../common/Input';
import {
  HospitalCheckboxesData,
  HospitalCheckboxesFormProps,
  HospitalQuestionnaire,
} from './types';

export const HospitalCheckboxesForm = ({
  data,
  onSave,
  loading,
}: HospitalCheckboxesFormProps): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const save = t?.common.save;

  // Initial form state
  const getInit = (dataSrc: HospitalCheckboxesData[]): any => {
    const init: any = {};
    dataSrc.map((section: HospitalCheckboxesData) => {
      section.questionnaire.map((questionnaireSet: HospitalQuestionnaire) => {
        init[questionnaireSet.id] = questionnaireSet.status;
        return null;
      });
      return null;
    });
    return init;
  };

  const formik = useFormik({
    initialValues: getInit(data),
    onSubmit: (values) => onSave(values),
  });

  return (
    <form className={styles['modal__manage-form']} onSubmit={formik.handleSubmit}>
      {data.map((section: HospitalCheckboxesData) => (
        <div className={styles.manage_proms__section} key={section.name}>
          <div className={styles.manage_proms__section_name}>{section.name}</div>

          {section.questionnaire.map((questionnaireSet: HospitalQuestionnaire) => (
            <div className={styles.manage_proms__row}>
              <CustomCheckbox
                htmlId={`proms${questionnaireSet.id}`}
                name={String(questionnaireSet.id)}
                checked={formik.values[String(questionnaireSet.id)]}
                onChangeMethod={formik.handleChange}
                isTouched={false}
                hasErrors={false}
                disabled={!questionnaireSet.isUsed}
                bigSize
              />
              <div
                className={cx({
                  [styles.manage_proms__checkbox_name]: true,
                  [styles.manage_proms__disabled]: !questionnaireSet.isUsed,
                })}
              >
                {questionnaireSet.description}
              </div>
            </div>
          ))}
        </div>
      ))}

      <Button
        buttonClass={styles['modal__manage-org-btn']}
        buttonType="submit"
        buttonName={save}
        disabledButton={loading || !formik.dirty}
      />
    </form>
  );
};
