import React, { ReactElement } from 'react';
import styles from './styles.module.css';
import { SearchFormProps } from './types';

const SearchForm = ({
  value,
  onChangeFilter,
  htmlFor = '',
  placeholder,
  dataQa,
}: SearchFormProps): ReactElement => (
  <>
    <input
      id={htmlFor}
      className={styles.input_search}
      type="text"
      value={value}
      onChange={onChangeFilter}
      name="filter"
      placeholder={placeholder}
      data-qa={dataQa}
    />
  </>
);

export { SearchForm };
