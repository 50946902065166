import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { Switch } from 'antd';

import styles from './styles.module.css';
import { GetState, getCurrenLang } from '../../../../redux/selector';
import { MediaSettingsProps } from '../../types';
import {
  InputFloatLabel,
  InputTypeFileAsText,
  RoundCheckbox,
  TextareaFeildFloatLabel,
} from '../../../../common/Input';
import { TFocusesProcedure } from '../../../Video/OrganisationVideoBank/types';
import { Button, SelectFloatLabelWithSearch } from '../../../../common';
import { DeleteIcon } from '../../../../theme/icons';
import { ShowErrorNotification } from '../../../Form/ErrorMessage';
import { Attachment } from '../../../../common/Attachments';

const MediaSettings = ({
  index,
  formik,
  focusType1Arr,
  exercisesProcedureFocus,
  isAdmin,
  hospitalNameOptions,
  onCheckIncomingFile,
  fileInputKey,
  onCancel,
  loadingJSX,
  isEdit,
  setWarningDeleteMediaModal,
  allowedTypeFile,
}: MediaSettingsProps): ReactElement => {
  const t: any = useSelector<GetState>((state) => getCurrenLang(state));
  const on = t?.common.on;
  const off = t?.common.off;
  const cancel = t?.common.cancel;
  const save = t?.common.save;
  const delete_text = t?.common.delete;
  const add_file = t?.dashboard.hcp.profile_patient.add_file;
  const patient_can_download_video = t?.hcp.manage_patient_information?.patient_can_download_video;
  const name = t?.hcp.manage_patient_information?.name;
  const attachment_label = `${add_file} (${allowedTypeFile})`;
  const organisation_name = t?.common.organisation_name;
  const no_options = t?.invite_patient.please_enter_min_3_characters;
  const comments = t?.dashboard.hcp.profile_patient.video_bank.comments;
  const selectAllThatApply = t?.dashboard.hcp.profile_patient.video_bank.select_all_that_apply;
  const focus_this_patient_info = t?.hcp.org_patient_info?.focus_this_patient_info;

  const { values, errors, touched } = formik;

  // Changing the Patient can download video
  const switchHandler = (checked: boolean): void => {
    formik.setFieldValue('isAllowDownloadVideos', checked);
  };

  // Delete elements from attachments
  const deleteAttachments = (): void => {
    formik.setFieldValue('file', '');
    formik.setFieldValue('fileName', '');
  };

  return (
    <form onSubmit={formik.handleSubmit} className={styles['org-patient-info__settings-form']}>
      <div className={styles['org-patient-info__settings-wrapper']}>
        {/* Left */}
        <div className={styles['org-patient-info__settings-left']}>
          {/* Organisation dropdown */}
          {isAdmin && (
            <div className={styles['org-patient-info__settings-hospital-container']}>
              <SelectFloatLabelWithSearch
                inputId="organisationName"
                name="organisationName"
                onChange={formik.setFieldValue}
                options={hospitalNameOptions}
                hasErrors={formik.errors.organisationName}
                isTouched={formik.touched.organisationName}
                selected={formik.values.organisationName}
                placeholder={organisation_name}
                noOptionsMessage={no_options}
                disabledField={isEdit || !!loadingJSX}
                inputValue={formik.values.organisationName}
                type="org patient info"
              />
            </div>
          )}
          {/* Upload file section */}
          {!isEdit && (
            <>
              {values.file && (
                <div className={styles['org-patient-info__add-file-container']}>
                  <Attachment
                    id="Attachment"
                    title={values.fileName || values.file.name}
                    index={1}
                    deleteAttachments={deleteAttachments}
                    disable={!!loadingJSX}
                  />
                </div>
              )}
              {!values.file && (
                <div className={styles['org-patient-info__add-file-container']}>
                  <InputTypeFileAsText
                    id="InputTypeFileUploadVideo"
                    inputName="file"
                    labelName={attachment_label}
                    hasErrors={errors.file}
                    isTouched={touched.file}
                    onChangeMethod={(e: any): Promise<void> => onCheckIncomingFile(e)}
                    fileInputKey={fileInputKey}
                    accept={allowedTypeFile}
                    isRedStar={false}
                  />
                </div>
              )}
            </>
          )}
          <InputFloatLabel
            inputId="name"
            inputName="name"
            inputType="text"
            hasErrors={errors.name}
            isTouched={touched.name}
            inputValue={values.name}
            onChangeMethod={formik.handleChange}
            placeholder={name}
            disabled={!!loadingJSX}
          />
          <TextareaFeildFloatLabel
            id="Description"
            inputName="description"
            placeholder={comments}
            inputValue={values.description}
            hasErrors={errors.description}
            isTouched={touched.description}
            onChecngeMethod={formik.handleChange}
            rows={3}
            disabled={!!loadingJSX}
          />
        </div>

        {/* Right */}
        <div className={styles['org-patient-info__settings-right']}>
          <div className={styles['org-patient-info__settings-title']}>
            {focus_this_patient_info}
          </div>
          <div className={styles['org-patient-info__settings-subtitle']}>{selectAllThatApply}</div>
          <div className={styles['org-patient-info__focuses-container']}>
            {focusType1Arr?.length > 0 &&
              focusType1Arr.map((focusItem: string, i: number) => {
                const currentFocusNumber = `procedure${i + 1}` as keyof TFocusesProcedure;
                const currentName = exercisesProcedureFocus[currentFocusNumber].name;
                const htmlId = `${currentName}${index}`;
                return (
                  <div key={htmlId} className={styles['org-patient-info__roundchexbox-container']}>
                    <RoundCheckbox
                      htmlId={htmlId}
                      name={`procedure${i + 1}`}
                      label={currentName}
                      checked={values[currentFocusNumber]}
                      onChangeMethod={formik.handleChange}
                      isTouched={false}
                      hasErrors={false}
                      disabled={!!loadingJSX}
                    />
                  </div>
                );
              })}
          </div>

          {/* Errors */}
          {errors.procedure1 && touched.procedure1 && (
            <div className={styles.errorMessages}>
              <ShowErrorNotification errorMessage={errors.procedure1} />
            </div>
          )}

          {/* Patient can download video */}
          <div className={styles['org-patient-info__switch-container']}>
            <div className={styles['org-patient-info__switch-descr']}>
              {patient_can_download_video}
            </div>
            <Switch
              checkedChildren={<span>{on}</span>}
              unCheckedChildren={<span>{off}</span>}
              checked={values.isAllowDownloadVideos}
              onChange={switchHandler}
              disabled={!!loadingJSX}
            />
          </div>
        </div>
      </div>

      {/* Buttons */}
      <div className={styles['org-patient-info__btns-container']}>
        {isEdit && (
          <div
            className={cx({
              [styles['settings__btn-delete-wraper']]: true,
              // [styles.notAllowed]: !checkPermission(),
            })}
            role="presentation"
            onClick={(): void => setWarningDeleteMediaModal(() => true)}
          >
            <DeleteIcon />
            <span className={styles['settings__btn-delete-text']}>{delete_text}</span>
          </div>
        )}
        <Button
          buttonName={cancel}
          buttonType="button"
          buttonClass={cx({
            [styles['org-patient-info__btn']]: true,
          })}
          buttonMethod={onCancel}
          disabledButton={!!loadingJSX}
        />
        <Button
          buttonName={save}
          buttonType="submit"
          buttonClass={styles['org-patient-info__btn']}
          disabledButton={!formik.dirty || !!loadingJSX}
        />
      </div>
    </form>
  );
};

export default MediaSettings;
