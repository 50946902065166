/* eslint-disable @typescript-eslint/no-explicit-any */
// Core
import * as Yup from 'yup';
import {
  RegExrSpecialCharacter,
  RegExrComma,
  RegExrLetter,
  alphanumeric,
  onlyDigits,
  maxValueIsSix,
  RegExrNumber,
  valueFrom0To100,
  valueFrom0To10,
  notZero,
} from '../regx';
import { splitString, isValid, getPhoneRegex } from '../helper';
import {
  TValuesFocus,
  ValidationCreateHcpUserProps,
  ValidationEmailProps,
  ValidationFieldProfile,
  ValidationInvitePatientFormTwoProps,
  ValidationPINProps,
  ValidationProfileHcpProps,
  ValidationProfilePatientProps,
  ValidationResetPassword,
  ValidationSchemaProps,
} from './types';
import { HEIGHT, UserCountryEnum, Weight } from '../enums';
import { TPatient } from '../model';

export const zero = 0;
export const two_decimal = 2;
export const three_decimal = 3;
// cm
export const min_cm = 67;
export const max_cm = 250;
// ft, inches
export const max_ft = 8;
export const min_ft = 4;
export const less_eight_ft = 7;
export const more_eight_ft = 9;
export const more_twenty_inches = 13;
// lbs
export const min_lbs = 65;
export const max_lbs = 999;
// kg
export const min_kg = 30;
export const max_kg = 1000;
// stone, lbs
export const min_stone = 4;
export const max_stone = 100;
export const less_max_stone = 99;
export const more_max_stone = 101;
export const max_stone_lbs = 14;

export const validationProfileHcp = (t: any, userCountry: string): any =>
  Yup.object({
    email: Yup.string().email(t?.validation.valid_email).required(t?.validation.required_email),
    firstName: Yup.string()
      .max(50, t?.validation.max_name)
      .min(2, t?.validation.min_name)
      .required(t?.validation.required_firstName)
      .matches(/^([A-Za-z])([A-Za-z'’`. -]+)$/g, t?.validation.name_as_letters),
    lastName: Yup.string()
      .required(t?.validation.required_lastName)
      .max(50, t?.validation.max_lastName)
      .min(2, t?.validation.min_lastName)
      .matches(/^([A-Za-z])([A-Za-z'’`. -]+)$/g, t?.validation.lastname_as_letters),
    phone: Yup.string()
      .matches(getPhoneRegex(userCountry), t?.validation.phone)
      .required(t?.validation.phone),
    professionId: Yup.string().required(t?.validation.professionId),
    organisationName: Yup.string().required(t?.validation.required_hospitalName),
  });

// export const validationPhoneNumberProfileHcp = (val: any, t: any) => {
//   const errors: any = {};
//   const phoneNumber = val.phone;
//   if (phoneNumber.length >= 1 && phoneNumber.slice(0, 1) === '1') {
//     if (!cellPhoneNumberUS.test(phoneNumber)) {
//       errors.phone = t?.validation.phone;
//       return errors;
//     }
//     return errors;
//   }
//   if (phoneNumber.length >= 2 && phoneNumber.slice(0, 2) === '44') {
//     if (!cellPhoneNumberGB.test(phoneNumber)) {
//       errors.phone = t?.validation.phone;
//       return errors;
//     }
//     return errors;
//   }
//   errors.phone = t?.validation.phone;
//   return errors;
// };

// Sign In page first step
export const validationSchema = (t: any): Yup.ObjectSchema<ValidationSchemaProps | undefined> =>
  Yup.object({
    email: Yup.string().email(t?.validation.valid_email).required(t?.validation.required_email),
    password: Yup.string().required(t?.validation.required_password),
  });

// Sign Up HCP
export const validationCreateHcpUser = (
  t: any,
): Yup.ObjectSchema<ValidationCreateHcpUserProps | undefined> =>
  Yup.object({
    password: Yup.string()
      .max(18, t?.validation.max_password)
      .required(t?.validation.required_password_create_account)
      .min(6, t?.validation.characters),
    repeatpassword: Yup.string()
      .when('password', {
        is: (val) => !!(val && val.length > 0),
        then: Yup.string().oneOf([Yup.ref('password')], t?.validation.password_not_match),
      })
      .required(t?.validation.repeat_password),
    acceptTerms: Yup.bool().oneOf([true], t?.validation.privacy_checkbox),
  });

export const validateCreateHcpUser = (values: any, t: any): any => {
  const errors: any = {};
  const currVal = values.password;
  if (!values || values.password.length === 0) return null;
  if (!RegExrSpecialCharacter.test(String(currVal))) {
    errors.password = t?.validation.special_charaster;
  }
  if (!RegExrLetter.test(String(currVal))) {
    errors.password = t?.validation.letter;
  }
  if (!RegExrNumber.test(String(currVal))) {
    errors.password = t?.validation.digit;
  }
  return errors;
};

export const validationEmail = (t: any): Yup.ObjectSchema<ValidationEmailProps | undefined> =>
  Yup.object({
    email: Yup.string().email(t?.validation.valid_email).required(t?.validation.required_email),
  });

export const validationPIN = (t: any): Yup.ObjectSchema<ValidationPINProps | undefined> =>
  Yup.object({
    code: Yup.string().required(t?.validation.enter_pin_code),
  });

export const validationResetEmail = (t: any): Yup.ObjectSchema<ValidationEmailProps | undefined> =>
  Yup.object({
    email: Yup.string().email(t?.validation.valid_email).required(t?.validation.required_email),
  });

export const validationResetPassword = (
  t: any,
): Yup.ObjectSchema<ValidationResetPassword | undefined> =>
  Yup.object({
    password: Yup.string()
      .required(t?.validation.required_password_create_account)
      .max(18, t?.validation.max_password)
      .min(6, t?.validation.min_password),
    repeatPassword: Yup.string()
      .when('password', {
        is: (val) => !!(val && val.length > 0),
        then: Yup.string().oneOf([Yup.ref('password')], t?.validation.password_not_match),
      })
      .required(t?.validation.repeat_password),
  });

export const validationChangePassword = (t: any): any =>
  Yup.object({
    currentPassword: Yup.string().required(t?.validation.required_password_create_account),
    newPassword: Yup.string()
      .required(t?.validation.required_password)
      .max(18, t?.validation.max_password)
      .min(6, t?.validation.min_password),
    repeatNewPassword: Yup.string()
      .when('newPassword', {
        is: (val) => !!(val && val.length > 0),
        then: Yup.string().oneOf([Yup.ref('newPassword')], t?.validation.password_not_match),
      })
      .required(t?.validation.repeat_password),
  });

export const validateChangePassword = (values: any, t: any): any => {
  const errors: any = {};
  if (!values || values.newPassword.length === 0) return null;
  if (String(values.newPassword).length < 6) {
    errors.newPassword = t?.validation.min_password;
  }
  if (!RegExrSpecialCharacter.test(String(values.newPassword))) {
    errors.newPassword = t?.validation.special_charaster;
  }
  if (!RegExrLetter.test(String(values.newPassword))) {
    errors.newPassword = t?.validation.letter;
  }
  if (!RegExrNumber.test(String(values.newPassword))) {
    errors.newPassword = t?.validation.digit;
  }
  return errors;
};

// HCP Profile (user profile)
export const validationFieldProfile = (
  t: any,
  userCountry: string,
): Yup.ObjectSchema<ValidationFieldProfile | undefined> =>
  Yup.object({
    firstName: Yup.string()
      .max(50, t?.validation.max_name)
      .min(2, t?.validation.min_name)
      .required(t?.validation.required_firstName)
      .matches(/^([A-Za-z])([A-Za-z'’`. -]+)$/g, t?.validation.name_as_letters),
    lastName: Yup.string()
      .required(t?.validation.required_lastName)
      .max(50, t?.validation.max_lastName)
      .min(2, t?.validation.min_lastName)
      .matches(/^([A-Za-z])([A-Za-z'’`. -]+)$/g, t?.validation.lastname_as_letters),
    email: Yup.string().email(t?.validation.valid_email).required(t?.validation.required_email),
    phone: Yup.string()
      .matches(getPhoneRegex(userCountry), t?.validation.phone)
      .required(t?.validation.phone),
    professionId: Yup.string().required(t?.validation.professionId),
    organisationName: Yup.string().required(t?.validation.required_hospitalName),
  });

// Invite HCP
export const validationInviteHcp = (
  t: any,
  userCountry: string,
): Yup.ObjectSchema<ValidationProfileHcpProps | undefined> =>
  Yup.object({
    email: Yup.string()
      .email(t?.validation.valid_email)
      // .matches(validationEmailDomain, t?.validation.valid_email)
      .required(t?.validation.required_email),
    firstName: Yup.string()
      .max(50, t?.validation.max_name)
      .min(2, t?.validation.min_name)
      .required(t?.validation.required_firstName)
      .matches(/^([A-Za-z])([A-Za-z'’`. -]+)$/g, t?.validation.name_as_letters),
    lastName: Yup.string()
      .required(t?.validation.required_lastName)
      .max(50, t?.validation.max_lastName)
      .min(2, t?.validation.min_lastName)
      .matches(/^([A-Za-z])([A-Za-z'’`. -]+)$/g, t?.validation.lastname_as_letters),
    phone: Yup.string()
      .required(t?.validation.phone)
      .matches(getPhoneRegex(userCountry), t?.validation.phone),
    profession: Yup.string().nullable(true).required(t?.validation.professionId),
    organisationName: Yup.string().required(t?.validation.required_hospitalName),
    department: Yup.string().nullable(true).required(t?.validation.required_department),
  });

export const validationInvitePatient = (
  t: any,
): Yup.ObjectSchema<ValidationFieldProfile | undefined> =>
  Yup.object({
    email: Yup.string().email(t?.validation.valid_email).required(t?.validation.required_email),
    firstName: Yup.string()
      .max(50, t?.validation.max_name)
      .min(2, t?.validation.min_name)
      .required(t?.validation.required_firstName)
      .matches(/^([A-Za-z])([A-Za-z'’`. -]+)$/g, t?.validation.name_as_letters),
    lastName: Yup.string()
      .required(t?.validation.required_lastName)
      .max(50, t?.validation.max_lastName)
      .min(2, t?.validation.min_lastName)
      .matches(/^([A-Za-z])([A-Za-z'’`. -]+)$/g, t?.validation.lastname_as_letters),
    phone: Yup.string()
      .required(t?.validation.phone)
      .matches(/^(\+?44\s?7\d{3}\s?\d{3}\s?\d{3})?$/, t?.validation.phone),
  });

export const validationInvitePatientFormTwo = (
  t: any,
): Yup.ObjectSchema<ValidationInvitePatientFormTwoProps | undefined> =>
  Yup.object({
    nhsNumber: Yup.string().length(12, t?.validation.min_nhsNumber).nullable(true),
    procedure: Yup.string().required(t?.validation.procedureTypeId).nullable(true),
  });

export const validationForTheConverter = (values: any, t: any): any => {
  const errors: any = {};

  if (RegExrLetter.test(values.weight)) {
    errors.weight = t?.validation.validation_format;
  }
  if (RegExrLetter.test(values.height)) {
    errors.height = t?.validation.validation_format;
  }
  if (RegExrSpecialCharacter.test(values.weight)) {
    errors.weight = t?.validation.validation_format;
  }
  if (RegExrSpecialCharacter.test(values.height)) {
    errors.height = t?.validation.validation_format;
  }
  if (values.weight.length > 0 && !isValid(values.weight)) {
    errors.weight = t?.validation.validation_format;
  }
  if (values.height.length > 0 && !isValid(values.height)) {
    errors.height = t?.validation.validation_format;
  }

  const a = splitString(values && values.weight, ',');
  const b = splitString(values && values.height, ',');

  // Weight
  if (values.weight.length > 0) {
    if (values.weightKg === 'kg') {
      if (Number(values.weight) < min_kg) {
        errors.weight = t?.validation.weight_kg_min;
      } else if (Number(values.weight) >= max_kg) {
        errors.weight = t?.validation.weight_kg_max;
      } else if (Number(a.num) < min_kg) {
        errors.weight = t?.validation.weight_kg_min;
      } else if (RegExrComma.test(values.weight)) {
        errors.weight = t?.validation.validation_on_come;
      } else if (a.sub && a.sub.length >= two_decimal && a.sub.match(/\D/gi)) {
        errors.weight = t?.validation.validation_format;
      } else if (a.sub && a.sub.length >= two_decimal) {
        errors.weight = t?.validation.validation_on_format;
      }
    }

    if (values.weightKg === 'stone/lbs') {
      if (Number(values.weight) < min_stone) {
        errors.weight = t?.validation.weight_stone_lbs_min;
      } else if (a.num && !a.sub && a.num >= more_max_stone) {
        errors.weight = t?.validation.weight_stone_max;
      } else if (Number(a.num) < min_stone) {
        errors.weight = t?.validation.weight_stone_lbs_min;
      } else if (a.num && a.sub && a.num >= more_max_stone) {
        errors.weight = t?.validation.weight_stone_lbs_max;
      } else if (a.num && a.num >= min_stone && a.num <= less_max_stone && a.sub >= max_stone_lbs) {
        errors.weight = t?.validation.weight_only_lbs_max;
      } else if (Number(a.num) >= max_stone && a.sub && a.sub >= max_stone_lbs) {
        errors.weight = t?.validation.weight_stone_lbs_max;
      } else if (RegExrComma.test(values.weight)) {
        errors.weight = t?.validation.validation_on_come;
      } else if (a.sub && a.sub.length >= three_decimal && a.sub.match(/\D/gi)) {
        errors.weight = t?.validation.validation_format;
      } else if (a.sub && a.sub.length >= three_decimal) {
        errors.weight = t?.validation.validation_on_two_decimal;
      }
    }

    if (values.weightKg === 'lbs') {
      if (Number(values.weight) < min_lbs) {
        errors.weight = t?.validation.weight_lbs_min;
      } else if (Number(values.weight) > max_lbs) {
        errors.weight = t?.validation.weight_lbs_max;
      } else if (Number(a.num) < min_lbs) {
        errors.weight = t?.validation.weight_lbs_min;
      } else if (RegExrComma.test(values.weight)) {
        errors.weight = t?.validation.validation_on_come;
      } else if (a.sub && a.sub.length > zero && a.sub.match(/\D/gi)) {
        errors.weight = t?.validation.validation_format;
      } else if (a.sub && a.sub.length > zero) {
        errors.weight = t?.validation.whole_number;
      }
    }
  }

  // height
  if (values.height.length > 0) {
    if (values.heightCm === 'cm') {
      if (Number(values.height) < min_cm) {
        errors.height = t?.validation.height_cm_min;
      } else if (Number(values.height) > max_cm) {
        errors.height = t?.validation.height_cm_max;
      } else if (Number(b.num) < min_cm) {
        errors.height = t?.validation.height_cm_min;
      } else if (RegExrComma.test(values.height)) {
        errors.height = t?.validation.validation_on_come;
      } else if (b.sub && b.sub.length > zero && b.sub.match(/\D/gi)) {
        errors.height = t?.validation.validation_format;
      } else if (b.sub && b.sub.length > zero) {
        errors.height = t?.validation.whole_number;
      }
    }
    if (values.heightCm === 'ft/inch') {
      if (Number(values.height) < min_ft) {
        errors.height = t?.validation.height_ft_min;
      } else if (b.num && !b.sub && b.num >= more_eight_ft) {
        errors.height = t?.validation.height_ft_max;
      } else if (Number(b.num) < min_ft) {
        errors.height = t?.validation.height_ft_min;
      } else if (b.num && b.sub && b.num >= more_eight_ft) {
        errors.height = t?.validation.height_ft_inch_max;
      } else if (
        b.num &&
        b.num >= min_ft &&
        b.num <= less_eight_ft &&
        b.sub >= more_twenty_inches
      ) {
        errors.height = t?.validation.height_inch_max;
      } else if (Number(b.num) >= max_ft && b.sub && b.sub >= three_decimal) {
        errors.height = t?.validation.height_ft_inch_max;
      } else if (RegExrComma.test(values.height)) {
        errors.height = t?.validation.validation_on_come;
      } else if (b.sub && b.sub.length >= three_decimal && b.sub.match(/\D/gi)) {
        errors.height = t?.validation.validation_format;
      } else if (b.sub && b.sub.length >= three_decimal) {
        errors.height = t?.validation.validation_on_two_decimal;
      }
    }
  }
  return errors;
};

export const validationForTheConverterProfileByAdmin = (values: any, t: any): any => {
  const errors: any = {};

  if (RegExrLetter.test(values.weight)) {
    errors.weight = t?.validation.validation_format;
  }
  if (RegExrLetter.test(values.height)) {
    errors.height = t?.validation.validation_format;
  }
  if (RegExrSpecialCharacter.test(values.weight)) {
    errors.weight = t?.validation.validation_format;
  }
  if (RegExrSpecialCharacter.test(values.height)) {
    errors.height = t?.validation.validation_format;
  }
  if (values.weight.length > 0 && !isValid(values.weight)) {
    errors.weight = t?.validation.validation_format;
  }
  if (values.height.length > 0 && !isValid(values.height)) {
    errors.height = t?.validation.validation_format;
  }

  const a = splitString(values && values.weight, ',');
  const b = splitString(values && values.height, ',');

  // Weight
  if (values.weight.length > 0) {
    if (values.weightDim === 'kg') {
      if (Number(values.weight) < min_kg) {
        errors.weight = t?.validation.weight_kg_min;
      } else if (Number(values.weight) >= max_kg) {
        errors.weight = t?.validation.weight_kg_max;
      } else if (Number(a.num) < min_kg) {
        errors.weight = t?.validation.weight_kg_min;
      } else if (RegExrComma.test(values.weight)) {
        errors.weight = t?.validation.validation_on_come;
      } else if (a.sub && a.sub.length >= two_decimal && a.sub.match(/\D/gi)) {
        errors.weight = t?.validation.validation_format;
      } else if (a.sub && a.sub.length >= two_decimal) {
        errors.weight = t?.validation.validation_on_format;
      }
    }

    if (values.weightDim === 'stone/lbs') {
      if (Number(values.weight) < min_stone) {
        errors.weight = t?.validation.weight_stone_lbs_min;
      } else if (a.num && !a.sub && a.num >= more_max_stone) {
        errors.weight = t?.validation.weight_stone_max;
      } else if (Number(a.num) < min_stone) {
        errors.weight = t?.validation.weight_stone_lbs_min;
      } else if (a.num && a.sub && a.num >= more_max_stone) {
        errors.weight = t?.validation.weight_stone_lbs_max;
      } else if (a.num && a.num >= min_stone && a.num <= less_max_stone && a.sub >= max_stone_lbs) {
        errors.weight = t?.validation.weight_only_lbs_max;
      } else if (Number(a.num) >= max_stone && a.sub && a.sub >= max_stone_lbs) {
        errors.weight = t?.validation.weight_stone_lbs_max;
      } else if (RegExrComma.test(values.weight)) {
        errors.weight = t?.validation.validation_on_come;
      } else if (a.sub && a.sub.length >= three_decimal && a.sub.match(/\D/gi)) {
        errors.weight = t?.validation.validation_format;
      } else if (a.sub && a.sub.length >= three_decimal) {
        errors.weight = t?.validation.validation_on_two_decimal;
      }
    }

    if (values.weightDim === 'lbs') {
      if (Number(values.weight) < min_lbs) {
        errors.weight = t?.validation.weight_lbs_min;
      } else if (Number(values.weight) > max_lbs) {
        errors.weight = t?.validation.weight_lbs_max;
      } else if (Number(a.num) < min_lbs) {
        errors.weight = t?.validation.weight_lbs_min;
      } else if (RegExrComma.test(values.weight)) {
        errors.weight = t?.validation.validation_on_come;
      } else if (a.sub && a.sub.length > zero && a.sub.match(/\D/gi)) {
        errors.weight = t?.validation.validation_format;
      } else if (a.sub && a.sub.length > zero) {
        errors.weight = t?.validation.whole_number;
      }
    }
  }
  // height
  if (values.height.length > 0) {
    if (values.heightDim === 'cm') {
      if (Number(values.height) < min_cm) {
        errors.height = t?.validation.height_cm_min;
      } else if (Number(values.height) > max_cm) {
        errors.height = t?.validation.height_cm_max;
      } else if (Number(b.num) < min_cm) {
        errors.height = t?.validation.height_cm_min;
      } else if (RegExrComma.test(values.height)) {
        errors.height = t?.validation.validation_on_come;
      } else if (b.sub && b.sub.length > zero && b.sub.match(/\D/gi)) {
        errors.height = t?.validation.validation_format;
      } else if (b.sub && b.sub.length > zero) {
        errors.height = t?.validation.whole_number;
      }
    }
    if (values.heightDim === 'ft/inch') {
      if (Number(values.height) < min_ft) {
        errors.height = t?.validation.height_ft_min;
      } else if (b.num && !b.sub && b.num >= more_eight_ft) {
        errors.height = t?.validation.height_ft_max;
      } else if (Number(b.num) < min_ft) {
        errors.height = t?.validation.height_ft_min;
      } else if (b.num && b.sub && b.num >= more_eight_ft) {
        errors.height = t?.validation.height_ft_inch_max;
      } else if (
        b.num &&
        b.num >= min_ft &&
        b.num <= less_eight_ft &&
        b.sub >= more_twenty_inches
      ) {
        errors.height = t?.validation.height_inch_max;
      } else if (Number(b.num) >= max_ft && b.sub && b.sub >= three_decimal) {
        errors.height = t?.validation.height_ft_inch_max;
      } else if (RegExrComma.test(values.height)) {
        errors.height = t?.validation.validation_on_come;
      } else if (b.sub && b.sub.length >= three_decimal && b.sub.match(/\D/gi)) {
        errors.height = t?.validation.validation_format;
      } else if (b.sub && b.sub.length >= three_decimal) {
        errors.height = t?.validation.validation_on_two_decimal;
      }
    }
  }
  return errors;
};

export const convertFromCm = (value: number, unit: string | undefined): any => {
  let result;
  switch (unit) {
    case 'cm':
      if (value === null) return value;
      result = value;
      break;
    case 'ft/inch': {
      if (value === null) return [value, value];
      const totalInch = value / 2.54;
      const ft = Math.floor(totalInch / 12);
      const inch = Math.round(totalInch % 12) || 0;
      result = [ft, inch];
      break;
    }
    default:
      result = null;
  }
  return result;
};

export const convertInCm = (value: string, unit: string): string | number => {
  let result;
  switch (unit) {
    case 'cm': {
      const [whole] = value.split(',');
      result = whole;
      break;
    }
    case 'ft/inch': {
      const spread = value.split(',');
      const ft = Math.floor(Number(spread[0]) * 12);
      const inch = Math.round(Number(spread[1])) || 0;
      result = Math.round((+ft + +inch) * 2.54);
      break;
    }
    default:
      result = 0;
  }
  return result;
};

export const convertFromGram = (value: number, unit: string | undefined): any => {
  let result;
  switch (unit) {
    case 'kg':
      if (value === null) return value;
      if (Number.isInteger(value / 1000)) {
        result = value / 1000;
        return result;
      }
      result = (value / 1000).toFixed(1).split('.').join(',');
      break;
    case 'stone/lbs': {
      if (value === null) return [value, value];
      const totalLbs = Math.round(value / 453.59237);
      const st = Math.floor(totalLbs / 14);
      const lbs = Math.floor(totalLbs % 14) || 0;
      result = [st, lbs];
      break;
    }
    case 'lbs':
      if (value === null) return value;
      result = (value / 453.59237).toFixed(0);
      break;
    default:
      result = null;
  }
  return result;
};

export const convertInGram = (value: string, unit: string): string | number => {
  let result;
  switch (unit) {
    case 'kg': {
      const [int, dec] = value.split(',');
      result = !!dec && Number(dec) !== 0 ? `${int}${Number(dec) * 100}` : `${Number(int) * 1000}`;
      break;
    }
    case 'stone/lbs': {
      const [integer, decimal] = value.split(',');
      const st = Number(integer) * 14;
      const lbs = decimal || 0;
      result = (+st + +lbs) * 453.59237;
      break;
    }
    case 'lbs': {
      const [whole] = value.split(',');
      result = Number(whole) * 453.59237;
      break;
    }
    default:
      result = '';
  }
  return result && Number(result).toFixed();
};

export const weightConverted = (patient: TPatient | null): string | number => {
  let weightConvert = '';
  if (patient?.weight) {
    weightConvert =
      patient.weightDim === Weight.Stone
        ? convertFromGram(patient.weight, patient.weightDim).join(',')
        : convertFromGram(patient.weight, patient.weightDim).toString();
  }
  return weightConvert;
};

export const heightConverted = (patient: TPatient | null): string | number => {
  let heightConvert = '';
  if (patient?.height) {
    heightConvert =
      patient.heightDim === HEIGHT.ftinches
        ? convertFromCm(patient.height, patient.heightDim).join(',')
        : convertFromCm(patient.height, patient.heightDim).toString();
  }
  return heightConvert;
};

// Patient profile
export const validationProfilePatient = (
  t: any,
  patientCountry: string,
): Yup.ObjectSchema<ValidationProfilePatientProps | undefined> =>
  Yup.object({
    email: Yup.string()
      .email(t?.validation.valid_email)
      // .matches(validationEmailDomain, t?.validation.valid_email)
      .required(t?.validation.required_email),
    firstName: Yup.string()
      .max(50, t?.validation.max_name)
      .min(2, t?.validation.min_name)
      .required(t?.validation.profile_patient_firstName || 'Please enter first name.')
      .matches(/^([A-Za-z])([A-Za-z'’`. -]+)$/g, t?.validation.name_as_letters),
    lastName: Yup.string()
      .required(t?.validation.profile_patient_lastName || 'Please enter last name.')
      .max(50, t?.validation.max_lastName)
      .min(2, t?.validation.min_lastName)
      .matches(/^([A-Za-z])([A-Za-z'’`. -]+)$/g, t?.validation.lastname_as_letters),
    phone: Yup.string()
      .required(t?.validation.phone)
      .matches(getPhoneRegex(patientCountry), t?.validation.phone),
    procedureTypeId: Yup.string().required(t?.validation.procedureTypeId),
    nhsNumber:
      patientCountry === UserCountryEnum.US || patientCountry === UserCountryEnum.DE
        ? Yup.string().length(11, t?.validation.min_9digits).nullable(true)
        : Yup.string().length(12, t?.validation.min_nhsNumber).nullable(true),
    insuredIdNumber: Yup.string()
      .max(29, t?.validation.valid_insurance_id_number)
      .min(29, t?.validation.valid_insurance_id_number)
      .nullable(true)
      .matches(alphanumeric, t?.validation.valid_insurance_id_number),
    policyGroup: Yup.string()
      .max(9, t?.validation.valid_fecaNumber)
      .min(9, t?.validation.valid_fecaNumber)
      .nullable(true)
      .matches(alphanumeric, t?.validation.valid_fecaNumber),
    insurancePlanName: Yup.string().max(28, t?.validation.valid_insurance_plan_name).nullable(true),
  });

export const validationFocusCheckboxes = (values: any, t: any): TValuesFocus | {} => {
  const errors: any = {};
  const err: any = {};
  for (let i = 1; i <= 4; i += 1) {
    if (values[`focus${i}`]) err[`focus${i}`] = String(values[`focus${i}`]);
  }
  if (!Object.values(err).includes('true')) {
    errors.focus1 = t?.validation.validation_focus_of_the_exercise;
  }
  if (!values?.name) {
    errors.name = t?.validation.required;
  }
  if (values?.name?.length > 30) {
    errors.name = t?.validation.max_exerciseName;
  }
  if (values?.isRecordVideo && values?.fileDuration < 5000) {
    errors.fileDuration = t?.validation.min_record_video_duration;
  }
  return errors;
};

// Connect Patient
export const validationConnectPatient = (t: any): any =>
  Yup.object({
    email: Yup.string()
      .email(t?.validation.valid_email)
      // .matches(validationEmailDomain, t?.validation.valid_email)
      .required(t?.validation.required_email),
    term: Yup.bool().oneOf([true], ' '), // t?.validation.privacy_checkbox),
  });

// Clinician-led sign up Patient
export const validationSignUpPatient = (t: any, patientCountry: string): any =>
  Yup.object({
    email: Yup.string().email(t?.validation.valid_email).required(t?.validation.required_email),
    term: Yup.bool().oneOf([true], ' '), // t?.validation.privacy_checkbox),
    dob: Yup.string().nullable(true).required(t?.validation.select_dob),
    firstName: Yup.string()
      .max(50, t?.validation.max_name)
      .min(2, t?.validation.min_name)
      .required(t?.validation.profile_patient_firstName || 'Please enter first name.')
      .matches(/^([A-Za-z])([A-Za-z'’`. -]+)$/g, t?.validation.name_as_letters),
    lastName: Yup.string()
      .required(t?.validation.profile_patient_lastName || 'Please enter last name.')
      .max(50, t?.validation.max_lastName)
      .min(2, t?.validation.min_lastName)
      .matches(/^([A-Za-z])([A-Za-z'’`. -]+)$/g, t?.validation.lastname_as_letters),
    phone: Yup.string()
      .matches(getPhoneRegex(patientCountry), t?.validation.phone)
      .required(t?.validation.enter_phone),
    procedure: Yup.string().nullable(true).required(t?.validation.procedureTypeId),
    nhsNumber:
      patientCountry === UserCountryEnum.US || patientCountry === UserCountryEnum.DE
        ? Yup.string().length(11, t?.validation.min_9digits).nullable(true)
        : Yup.string().length(12, t?.validation.min_nhsNumber).nullable(true),
    insuredIdNumber: Yup.string()
      .max(29, t?.validation.valid_insurance_id_number)
      .min(29, t?.validation.valid_insurance_id_number)
      .nullable(true)
      .matches(alphanumeric, t?.validation.valid_insurance_id_number),
    policyGroup: Yup.string()
      .max(9, t?.validation.valid_fecaNumber)
      .min(9, t?.validation.valid_fecaNumber)
      .nullable(true)
      .matches(alphanumeric, t?.validation.valid_fecaNumber),
    insurancePlanName: Yup.string().max(28, t?.validation.valid_insurance_plan_name).nullable(true),
  });

export const validationUploadVideo = (t: any): any =>
  Yup.object({
    exerciseName: Yup.string()
      .max(30, t?.validation.max_exerciseName)
      .required(t?.validation.required),
  });

export const validationRecordVideo = (t: any): any =>
  Yup.object({
    exerciseName: Yup.string()
      .max(30, t?.validation.max_exerciseName)
      .required(t?.validation.required),
    file: Yup.string().required(t?.validation.min_record_video_duration),
    fileDuration: Yup.number().min(5000, t?.validation.min_record_video_duration),
  });

export const validationTakeNewPhoto = (t: any): any =>
  Yup.object({
    exerciseName: Yup.string()
      .max(30, t?.validation.max_exerciseName)
      .required(t?.validation.required),
  });

export const validationRecordVideoForPatient = (t: any): any =>
  Yup.object({
    fileDuration: Yup.number().min(5000, t?.validation.min_record_video_duration),
  });

export const validationAddOrganisation = (t: any): any =>
  Yup.object({
    organisation: Yup.string().required(t?.validation.required),
  });

export const validationAddProfession = (t: any, initialValues: any): any => {
  const fieldNames = Object.keys(initialValues);
  const rulesObj: any = {};
  if (fieldNames?.length) {
    fieldNames.map((field: string) => {
      if (field === 'UK') {
        rulesObj[field] = Yup.string()
          .required(t?.validation.required)
          .max(99, t?.validation.max_100_characters)
          .min(3, t?.validation.min_3_characters);
      } else {
        rulesObj[field] = Yup.string()
          .max(99, t?.validation.max_100_characters)
          .min(3, t?.validation.min_3_characters);
      }
      return null;
    });
  }

  return Yup.object({
    ...rulesObj,
  });
};

export const validationFeedbackForm = (t: any): any => {
  const enter_less_characters =
    t?.validation.enter_less_characters || 'Please enter less than or up to <number> characters';
  const less_200 = enter_less_characters?.replace('<number>', '200');

  return Yup.object({
    firstName: Yup.string()
      .max(50, t?.validation.max_name)
      .min(2, t?.validation.min_name)
      .required(t?.validation.required_firstName)
      .matches(/^([A-Za-z])([A-Za-z'’`. -]+)$/g, t?.validation.name_as_letters),
    lastName: Yup.string()
      .required(t?.validation.required_lastName)
      .max(50, t?.validation.max_lastName)
      .min(2, t?.validation.min_lastName)
      .matches(/^([A-Za-z])([A-Za-z'’`. -]+)$/g, t?.validation.lastname_as_letters),
    email: Yup.string().email(t?.validation.valid_email).required(t?.validation.required_email),
    subject: Yup.string().required(t?.help.subject_placeholder),
    description: Yup.string().required(t?.validation.feedback_description).max(200, less_200),
  });
};

export const validationExercise = (t: any): any =>
  Yup.object({
    reps: Yup.string().matches(onlyDigits, t?.validation.onlyDigits).nullable(true),
    sets: Yup.string().matches(onlyDigits, t?.validation.onlyDigits).nullable(true),
    timesDay: Yup.string()
      .matches(onlyDigits, t?.validation.onlyDigits)
      .matches(/^([1-9])([0-9]+)?$/g, t?.validation.required) // not 0
      .required(t?.validation.required),
    restDaysWeek: Yup.string()
      .matches(onlyDigits, t?.validation.onlyDigits)
      .matches(maxValueIsSix, 'Max value is 6')
      .nullable(true),
  });

// Resend code to Patient
export const validationResendCode = (t: any): any =>
  Yup.object({
    email: Yup.string().email(t?.validation.valid_email).required(t?.validation.required_email),
  });

// Add (Edit) Exercises to folder (Team videobank and video bank)
export const validationAddExercToNewFolder = (t: any): any =>
  Yup.object({
    name: Yup.string().required(t?.validation.required_folder_name),
  });

// Add Exercises to folder
export const validationAddExercToExistFolder = (t: any): any =>
  Yup.object({
    existFolder: Yup.string().required(t?.validation.required_folder_name).nullable(),
  });

// Add Exercises to folder
export const validationOrganisationRewards = (t: any): any => {
  const { enter_less_characters } = t?.validation;
  const less_100 = enter_less_characters?.replace('<number>', '100');
  const less_25 = enter_less_characters?.replace('<number>', '25');

  return Yup.object({
    name: Yup.string().required(t?.validation.must_be_completed).max(25, less_25),
    description: Yup.string().required(t?.validation.must_be_completed).max(100, less_100),
    preview: Yup.string().required(t?.validation.must_be_completed),
    brandApproval: Yup.bool().oneOf([true], ' '),
  });
};

export const validationEmptyFields = (): any => Yup.object({});

export const validationManadgeNotifTypeOneSubtypeOne = (t: any): any =>
  Yup.object({
    percent: Yup.string()
      .required(t?.validation.required)
      .matches(onlyDigits, t?.validation.onlyDigits)
      .matches(
        valueFrom0To100,
        `${
          t?.dashboard.hcp.profile_patient.video_bank.from
        } 0 ${t?.dashboard.hcp.table.to.toLowerCase()} ${100}`,
      ),
    days: Yup.string()
      .required(t?.validation.required)
      .matches(onlyDigits, t?.validation.onlyDigits)
      .matches(notZero, t?.validation.onlyDigits),
  });

export const validationManadgeNotifTypeOneSubtypeTwo = (t: any): any =>
  Yup.object({
    percent_subType: Yup.string()
      .required(t?.validation.required)
      .matches(onlyDigits, t?.validation.onlyDigits)
      .matches(
        valueFrom0To100,
        `${
          t?.dashboard.hcp.profile_patient.video_bank.from
        } 0 ${t?.dashboard.hcp.table.to.toLowerCase()} ${100}`,
      ),
    days_subType: Yup.string()
      .required(t?.validation.required)
      .matches(onlyDigits, t?.validation.onlyDigits)
      .matches(notZero, t?.validation.onlyDigits),
  });

export const validationManadgeNotifTypeOneBothField = (t: any): any =>
  Yup.object({
    percent: Yup.string()
      .required(t?.validation.required)
      .matches(onlyDigits, t?.validation.onlyDigits)
      .matches(
        valueFrom0To100,
        `${
          t?.dashboard.hcp.profile_patient.video_bank.from
        } 0 ${t?.dashboard.hcp.table.to.toLowerCase()} ${100}`,
      ),
    days: Yup.string()
      .required(t?.validation.required)
      .matches(onlyDigits, t?.validation.onlyDigits)
      .matches(notZero, t?.validation.onlyDigits),
    percent_subType: Yup.string()
      .required(t?.validation.required)
      .matches(onlyDigits, t?.validation.onlyDigits)
      .matches(
        valueFrom0To100,
        `${
          t?.dashboard.hcp.profile_patient.video_bank.from
        } 0 ${t?.dashboard.hcp.table.to.toLowerCase()} ${100}`,
      ),
    days_subType: Yup.string()
      .required(t?.validation.required)
      .matches(onlyDigits, t?.validation.onlyDigits)
      .matches(notZero, t?.validation.onlyDigits),
  });

export const validationManadgeNotifTypeThreeSubtypeTwo = (t: any): any =>
  Yup.object({
    value_subType: Yup.string()
      .required(t?.validation.required)
      .matches(onlyDigits, t?.validation.onlyDigits)
      .matches(notZero, t?.validation.onlyDigits),
    days_subType: Yup.string()
      .required(t?.validation.required)
      .matches(onlyDigits, t?.validation.onlyDigits)
      .matches(notZero, t?.validation.onlyDigits),
  });

export const validationManadgeNotifTypeThreeBothSubtype = (t: any): any =>
  Yup.object({
    percent: Yup.string()
      .required(t?.validation.required)
      .matches(onlyDigits, t?.validation.onlyDigits)
      .matches(
        valueFrom0To100,
        `${
          t?.dashboard.hcp.profile_patient.video_bank.from
        } 0 ${t?.dashboard.hcp.table.to.toLowerCase()} ${100}`,
      ),
    days: Yup.string()
      .required(t?.validation.required)
      .matches(onlyDigits, t?.validation.onlyDigits)
      .matches(notZero, t?.validation.onlyDigits),
    value_subType: Yup.string()
      .required(t?.validation.required)
      .matches(onlyDigits, t?.validation.onlyDigits)
      .matches(notZero, t?.validation.onlyDigits),
    days_subType: Yup.string()
      .required(t?.validation.required)
      .matches(onlyDigits, t?.validation.onlyDigits)
      .matches(notZero, t?.validation.onlyDigits),
  });

export const validationManadgeNotifTypeFiveSubtypeOne = (t: any): any =>
  Yup.object({
    value: Yup.string().required(t?.validation.required),
    days: Yup.string()
      .required(t?.validation.required)
      .matches(onlyDigits, t?.validation.onlyDigits)
      .matches(notZero, t?.validation.onlyDigits),
  });

export const validationManadgeNotifTypeFiveSubtypeTwo = (t: any): any =>
  Yup.object({
    value_subType: Yup.string().required(t?.validation.required),
    days_subType: Yup.string()
      .required(t?.validation.required)
      .matches(onlyDigits, t?.validation.onlyDigits)
      .matches(notZero, t?.validation.onlyDigits),
  });

export const validationManadgeNotifTypeFiveBothSubtype = (t: any): any =>
  Yup.object({
    value: Yup.string().required(t?.validation.required),
    days: Yup.string()
      .required(t?.validation.required)
      .matches(onlyDigits, t?.validation.onlyDigits)
      .matches(notZero, t?.validation.onlyDigits),
    value_subType: Yup.string().required(t?.validation.required),
    days_subType: Yup.string()
      .required(t?.validation.required)
      .matches(onlyDigits, t?.validation.onlyDigits)
      .matches(notZero, t?.validation.onlyDigits),
  });

// Upload Patient Information from Manage PI page
export const uploadVideoForPatientInfoByHcp = (t: any, isAdmin: boolean, isEdit: boolean): any => {
  const { enter_less_characters } = t?.validation;
  const less_100 = enter_less_characters?.replace('<number>', '100');
  const less_30 = enter_less_characters?.replace('<number>', '30');

  const addValidationRule: any =
    isAdmin && !isEdit
      ? {
          organisationName: Yup.string().required(t?.validation.required),
        }
      : {};
  if (!isEdit) {
    addValidationRule.file = Yup.string().required(t?.validation.required);
  }

  return Yup.object({
    name: Yup.string().required(t?.validation.required).max(30, less_30),
    description: Yup.string().nullable().max(100, less_100),
    ...addValidationRule,
  });
};

// Upload Patient Information from Manage PI page (checkboxes for procedure)
export const validationProcedure = (
  values: any,
  focusType1Arr: string[],
  t: any,
): TValuesFocus | {} => {
  const errors: any = {};
  let isValidProcedureSection = false;
  focusType1Arr.map((procedureKey: string) => {
    if (values[procedureKey]) {
      isValidProcedureSection = true;
    }
    return null;
  });
  if (!isValidProcedureSection) {
    errors[focusType1Arr[0]] = t?.validation.validation_focus_of_the_exercise;
  }
  return errors;
};

// Manage notification - notification to my patient
export const validationNotifToMyPatients = (t: any): any =>
  Yup.object({
    daysPostSurgery: Yup.string().required(t?.validation.required),
  });

// Manage notification - notifications to me
export const validationRemoveUnreadNotif = (t: any): any =>
  Yup.object({
    amount: Yup.string().required(t?.validation.required).nullable(),
    timePeriod: Yup.string().required(t?.validation.required).nullable(),
  });

// Validate Email checkboxes
export const validateManageNotif = (
  values: { isEmailMe: boolean; isEmailMyColleague: boolean },
  t: any,
): {} => {
  const errors: any = {};
  if (values.isEmailMe || values.isEmailMyColleague) {
    return errors;
  }
  errors.isEmailMe = t?.validation.validation_focus_of_the_exercise;
  errors.emails = t?.validation.validation_focus_of_the_exercise;
  return errors;
};

// Create folder (validation number/unit fields)
export const validationScheduleFolder = (values: any, t: any) => {
  const errors: any = {};
  const { amount, timePeriod } = values;

  if (amount && !timePeriod) {
    errors.timePeriod = t?.validation.select_unit;
    return errors;
  }
  if (!amount && timePeriod) {
    errors.amount = t?.validation.select_number;
    return errors;
  }
  return null;
};

// Manage notification - notification to my patient
export const saveHospitalTeamValidation = (t: any): any =>
  Yup.object({
    name: Yup.string().required(t?.validation.required),
    description: Yup.string().required(t?.validation.required),
    // teamMembers: Yup.array()
    //   .min(1,
    // t?.validation.select_at_least_one_member || "Select at least one team member"), key not exist
  });
