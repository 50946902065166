/* eslint-disable indent */
import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import {
  getCurrenLang,
  getPatientProfile,
  getCurrentFormatDate,
  getUserLang,
  GetState,
  getHcpName,
} from '../../redux/selector';
import {
  HEIGHT,
  LIST_TYPE,
  PERMISSIONS,
  REWARDS_OPTIONS,
  UserCountryEnum,
  Weight,
} from '../../utils/enums';
import styles from './styles.module.css';
import useCheckRole from '../../hooks/useCheckRole';
import { InputFloatLabel, RadioConverter } from '../../common/Input';
import useCheckPermissions from '../../hooks/useCheckPermissions';
import { Button, SelectFloatLabelWithSearch } from '../../common';
import {
  dataPickerOfBirthday,
  getInsurancePolicyLabel,
  maxDate,
  minDate,
  minYear,
  nhsValueHandler,
  otherClaimIdHandler,
} from '../../utils/helper';
import { InputPhoneNumberFloatLabel } from '../../common/Input/PhoneNumber';
import { DatePickerFloatLabel } from '../../common/DatePicker';
import { SelectFloatLabel } from '../../common/Input/Select';
import { RewardsSwitch } from '../../common/Switch/Switch';
import { ShowErrorNotification } from './ErrorMessage';
import UserStatus from '../Table/components/UserStatus';
import BmiTip from '../../common/Elements/BmiTip';

const PatientProfileForm = ({
  formik,
  genderOptions,
  claimOptions,
  weight,
  height,
  calcBMI,
  rewardsSwitchHandler,
  errorMessage,
  refreshStatus,
  isProfileUpdated,
  loading,
}: any): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const patient: any = useSelector<any>((state) => getPatientProfile(state));
  const status_t = t?.dashboard.hcp.table.status;
  const save = t?.common.save;
  const organisation_name = t?.common.organisation_name;
  const first_name = t?.dashboard.admin.profile_patient.first_name;
  const last_name = t?.dashboard.admin.profile_patient.last_name;
  const number_phone = t?.profile_hcp.number_phone;
  const gender = t?.dashboard.admin.profile_patient.gender;
  const label_dob = t?.dashboard.admin.profile_patient.label_dob;
  const bmi_index = t?.dashboard.admin.profile_patient.bmi_index;
  const bmi_index_placeholder = t?.dashboard.admin.profile_patient.bmi_index_placeholder;
  const kg = t?.dashboard.admin.profile_patient.kg;
  const stone = t?.dashboard.admin.profile_patient.stone;
  const lbs = t?.dashboard.admin.profile_patient.lbs;
  const cm = t?.dashboard.admin.profile_patient.cm;
  const ft_inches = t?.dashboard.admin.profile_patient.ft_inches;
  const type_of_claim = t?.dashboard.admin.profile_patient.type_of_claim;
  const insured_ID_number = t?.dashboard.admin.profile_patient.insured_ID_number;
  const policy_group = t?.dashboard.admin.profile_patient.policy_group;
  const other_claim_id = t?.dashboard.admin.profile_patient.other_claim_id;
  const insurance_plan_name = t?.dashboard.admin.profile_patient.insurance_plan_name;
  const dos = t?.dashboard.hcp.profile_patient.completion_and_steps.date_of_surgery;
  const weight_label = t?.invite_patient.weight;
  const tip_kg = t?.validation.tip_kg;
  const tip_stone = t?.validation.tip_stone;
  const tip_lbs = t?.validation.tip_lbs;
  const height_label = t?.invite_patient.height;
  const tip_cm = t?.validation.tip_cm;
  const tip_ft = t?.validation.tip_ft;
  const no_options = t?.invite_patient.please_enter_min_3_characters;
  const rewards = t?.dashboard.admin.table.patient.rewards;
  const focus_t = t?.dashboard.hcp.table.procedure;
  const patients_email = t?.connect_patient.patients_email;

  const { isHcp, isAdmin } = useCheckRole();
  const patientCountry = patient.country;
  const isPermissionEditProfile = useCheckPermissions(PERMISSIONS.EDIT_PATIENT_PROFILE_INFO_OTM);
  const formatDate = useSelector((state: GetState) => getCurrentFormatDate(state));
  const hcpLang: any = useSelector<GetState>((state) => getUserLang(state));
  const resultBMI = calcBMI();
  const currentUser: any = useSelector((state: GetState) => getHcpName(state));
  const currentRewardsOption: number = currentUser.rewardsType;

  const { values, errors, touched, isValid } = formik;

  const nhsChangeHandler = (e: any): void => {
    const value = nhsValueHandler(e.target.value, patientCountry);
    formik.setFieldValue('nhsNumber', value);
  };

  const otherClaimIdChangeHandler = (e: any): void => {
    const value = otherClaimIdHandler(e.target.value);
    formik.setFieldValue('otherClaimId', value);
  };

  const onRadioChange = (dimProp: string, dimValue: string, prop: string): void => {
    formik.setFieldValue(dimProp, dimValue);
    formik.setFieldValue(prop, '');
  };

  return (
    <div className={styles['profile__form-container']}>
      <form onSubmit={formik.handleSubmit}>
        <div className={styles.row}>
          <div className={styles['cell-50']}>
            <InputFloatLabel
              inputId="profile_firstName"
              inputName="firstName"
              placeholder={first_name}
              inputType="text"
              inputValue={values.firstName}
              hasErrors={errors.firstName}
              isTouched={touched.firstName}
              onChangeMethod={formik.handleChange}
              disabled={!isPermissionEditProfile}
              isRedStar
            />
          </div>
          <div className={styles['cell-50']}>
            <InputFloatLabel
              inputId="profile_lastName"
              inputName="lastName"
              placeholder={last_name}
              inputType="text"
              inputValue={values.lastName}
              hasErrors={errors.lastName}
              isTouched={touched.lastName}
              onChangeMethod={formik.handleChange}
              disabled={!isPermissionEditProfile}
              isRedStar
            />
          </div>
        </div>
        <SelectFloatLabelWithSearch
          inputId="organisationName"
          name="hospitalName"
          placeholder={organisation_name}
          onChange={formik.setFieldValue}
          options={[]}
          // options={HospitalNameOptions} TODO if need change hospital
          hasErrors={errors.hospitalName}
          isTouched={touched.hospitalName}
          selected={values.hospitalName}
          inputValue={values.hospitalName}
          noOptionsMessage={no_options}
          type="user profile"
          disabledField
        />

        {/* User status */}
        {isHcp && (
          <div className={styles['profile__status-wrapper']}>
            <UserStatus
              mode={LIST_TYPE.PATIENT}
              record={formik.values}
              refresh={refreshStatus}
              isResponsive
              disabledProp={!isPermissionEditProfile}
              t={t}
            />
            <div className={styles['floating-label']}>
              {status_t}
              <span className={styles['red-dot']}>*</span>
            </div>
          </div>
        )}

        <SelectFloatLabelWithSearch
          inputId="procedure"
          name="procedureTypeId"
          placeholder={focus_t}
          onChange={formik.setFieldValue}
          options={[]}
          hasErrors={errors.procedureTypeId}
          isTouched={touched.procedureTypeId}
          selected={values.procedureTypeId}
          inputValue={values.procedureTypeId}
          noOptionsMessage={no_options}
          type="user profile"
          disabledField
          isRedStar
        />
        <InputFloatLabel
          inputId="email"
          inputName="email"
          placeholder={patients_email}
          inputType="text"
          inputValue={values.email}
          hasErrors={errors.email}
          isTouched={touched.email}
          onChangeMethod={formik.handleChange}
          disabled={!isPermissionEditProfile}
          isRedStar
        />
        <InputPhoneNumberFloatLabel
          inputId="phoneNumber"
          isValid={isValid}
          hasErrors={errors.phone}
          value={values.phone}
          isTouched={touched.phone}
          onChange={(data: any): void => {
            formik.setFieldValue('phone', `+${data}`);
          }}
          userCountry={patientCountry}
          placeholder={number_phone}
          isRedStar
          disabled={!isPermissionEditProfile}
        />
        <DatePickerFloatLabel
          autocomplete="off"
          name="dob"
          labelName={label_dob}
          placeholderText=" "
          carrentValue={values.dob}
          selected={values.dob}
          onChange={formik.setFieldValue}
          onBlur={(val: React.ChangeEvent<HTMLInputElement>): void => {
            dataPickerOfBirthday(val, t, formik, formatDate);
          }}
          minDate={new Date(new Date(minYear).getFullYear(), 0, 1)}
          maxDate={new Date()}
          currentLang={hcpLang}
          disabled={!isPermissionEditProfile}
          hasErrors={errors.dob}
          isTouched={touched.dob}
        />
        <SelectFloatLabel
          inputId="gender"
          name="gender"
          placeholder={gender}
          options={genderOptions}
          onChange={formik.setFieldValue}
          inputValue={values.gender}
          hasErrors={errors.gender}
          isTouched={touched.gender}
          type="user profile"
          disabledField={!isPermissionEditProfile}
          isRedStar
        />
        <div className={styles.row}>
          <div className={styles['cell-50']}>
            <InputFloatLabel
              inputId="profile_nhsNumber"
              inputName="nhsNumber"
              placeholder={getInsurancePolicyLabel(t, patientCountry)}
              inputType="text"
              inputValue={values.nhsNumber}
              hasErrors={errors.nhsNumber}
              isTouched={touched.nhsNumber}
              onChangeMethod={nhsChangeHandler}
              disabled={!isPermissionEditProfile}
            />
          </div>
          <div className={styles['cell-50']}>
            <DatePickerFloatLabel
              autocomplete="off"
              name="surgeryDate"
              labelName={dos}
              placeholderText=" "
              carrentValue={values.surgeryDate}
              selected={values.surgeryDate}
              onChange={formik.setFieldValue}
              onBlur={(val: React.ChangeEvent<HTMLInputElement>): void => {
                dataPickerOfBirthday(val, t, formik, formatDate);
              }}
              isNextYear
              minDate={new Date(minDate)}
              maxDate={new Date(maxDate)}
              hasErrors={errors.dos}
              isTouched={touched.dos}
              currentLang={hcpLang}
              disabled={!isPermissionEditProfile}
            />
          </div>
        </div>

        {/* Policy accurance US */}
        {patientCountry === UserCountryEnum.US && (
          <>
            <SelectFloatLabel
              inputId="typeOfClaim"
              name="typeOfClaim"
              placeholder={type_of_claim}
              options={claimOptions}
              onChange={formik.setFieldValue}
              inputValue={values.typeOfClaim}
              hasErrors={errors.typeOfClaim}
              isTouched={touched.typeOfClaim}
              type="user profile"
              disabledField={!isPermissionEditProfile}
              isClearable
            />
            <InputFloatLabel
              inputId="insuredIdNumber"
              inputName="insuredIdNumber"
              placeholder={insured_ID_number}
              inputType="text"
              inputValue={values.insuredIdNumber}
              hasErrors={errors.insuredIdNumber}
              isTouched={touched.insuredIdNumber}
              onChangeMethod={formik.handleChange}
              disabled={!isPermissionEditProfile}
            />
            <InputFloatLabel
              inputId="patientProfile_policyGroup"
              inputName="policyGroup"
              placeholder={policy_group}
              hasErrors={errors.policyGroup}
              isTouched={touched.policyGroup}
              onChangeMethod={formik.handleChange}
              inputValue={values.policyGroup}
              inputType="text"
              disabled={!isPermissionEditProfile}
            />
            <InputFloatLabel
              inputId="patientProfile_otherClaimId"
              inputName="otherClaimId"
              placeholder={other_claim_id}
              hasErrors={errors.otherClaimId}
              isTouched={touched.otherClaimId}
              onChangeMethod={otherClaimIdChangeHandler}
              inputValue={values.otherClaimId}
              inputType="text"
              disabled={!isPermissionEditProfile}
            />
            <InputFloatLabel
              inputId="patientProfile_insurancePlanName"
              inputName="insurancePlanName"
              placeholder={insurance_plan_name}
              hasErrors={errors.insurancePlanName}
              isTouched={touched.insurancePlanName}
              onChangeMethod={formik.handleChange}
              inputValue={values.insurancePlanName}
              inputType="text"
              disabled={!isPermissionEditProfile}
            />
          </>
        )}

        <div className={styles.row}>
          <div className={styles['cell-50']}>
            {/* HEIGHT */}
            <div className={styles.container_radio}>
              <div>
                <div className={styles.converter_container}>
                  <RadioConverter
                    id="cm"
                    tip={tip_cm}
                    value={values.heightDim}
                    inputName={values.heightDim}
                    handleChange={(): void => onRadioChange('heightDim', HEIGHT.cm, 'height')}
                    labelName={cm}
                    checkedRadio={values.heightDim === HEIGHT.cm}
                    bigSize
                  />
                  <RadioConverter
                    id="ft_inches"
                    tip={tip_ft}
                    value={values.heightDim}
                    inputName={values.heightDim}
                    handleChange={(): void => onRadioChange('heightDim', HEIGHT.ftinches, 'height')}
                    labelName={ft_inches}
                    checkedRadio={values.heightDim === HEIGHT.ftinches}
                    bigSize
                  />
                </div>

                <InputFloatLabel
                  inputId="heightId"
                  inputName="height"
                  inputValue={values.height}
                  hasErrors={errors.height}
                  isTouched={touched.height}
                  onChangeMethod={formik.handleChange}
                  disabled={!(isPermissionEditProfile && values.heightDim)}
                  placeholder={height_label}
                  inputType="text"
                />
              </div>
            </div>
          </div>
          <div className={styles['cell-50']}>
            {/* WEIGHT */}
            <div className={styles.container_radio}>
              <div className={styles.form_radio}>
                <div>
                  <RadioConverter
                    id="kg"
                    value={values.weightDim}
                    inputName={values.weightDim}
                    handleChange={(): void => onRadioChange('weightDim', Weight.Kg, 'weight')}
                    labelName={kg}
                    tip={tip_kg}
                    checkedRadio={values.weightDim === Weight.Kg}
                    bigSize
                  />
                  <RadioConverter
                    id="stone"
                    value={values.weightDim}
                    inputName={values.weightDim}
                    handleChange={(): void => onRadioChange('weightDim', Weight.Stone, 'weight')}
                    labelName={stone}
                    tip={tip_stone}
                    checkedRadio={values.weightDim === Weight.Stone}
                    bigSize
                  />

                  <RadioConverter
                    id="lbs"
                    value={values.weightDim}
                    inputName={values.weightDim}
                    handleChange={(): void => onRadioChange('weightDim', Weight.Lbs, 'weight')}
                    labelName={lbs}
                    tip={tip_lbs}
                    checkedRadio={values.weightDim === Weight.Lbs}
                    bigSize
                  />
                </div>

                <InputFloatLabel
                  inputId="weightId"
                  inputName="weight"
                  inputValue={values.weight}
                  hasErrors={errors.weight}
                  isTouched={touched.weight}
                  onChangeMethod={formik.handleChange}
                  disabled={!(isPermissionEditProfile && values.weightDim)}
                  inputType="text"
                  placeholder={weight_label}
                />
              </div>
            </div>
          </div>
        </div>

        {/* BMI INDEX */}
        <div className={styles.bmi__container}>
          <div className={styles.inputFloat}>
            {(values.weight && values.height) ||
            (!weight && weight >= 1 && height >= 1 && !height) ? (
              resultBMI
            ) : (
              <div className={styles.bmi__placeholder}>{bmi_index_placeholder}</div>
            )}
          </div>
          <div className={styles['floating-label']}>
            <span>{bmi_index}</span>
          </div>
          <div className={styles.bmi__popup}>
            <BmiTip resultBMI={resultBMI} t={t} />
          </div>
        </div>

        {/* REWARDS */}
        {(isAdmin || currentRewardsOption === REWARDS_OPTIONS.OPTION_2) && (
          <div className={styles.rewards__container}>
            <div className={styles['rewards__label-container']}>
              <div className={styles.rewards__label}>{rewards}</div>
              <div className={styles['rewards__switch-container']}>
                <RewardsSwitch
                  onChange={rewardsSwitchHandler}
                  isRewardsEnabled={formik.values.isRewardsEnabled}
                  size="small"
                  disabled={!isPermissionEditProfile}
                />
              </div>
            </div>
            <div className={styles['rewards__calendar-container']}>
              <div className={styles['rewards__calendar-date']}>
                {formik.values.rewardsStartDate
                  ? moment(formik.values.rewardsStartDate).format('ddd MMM D, YYYY')
                  : ''}
              </div>
              -
              <div className={styles['rewards__calendar-date']}>
                {formik.values.rewardsEndDate
                  ? moment(formik.values.rewardsEndDate).format('ddd MMM D, YYYY')
                  : ''}
              </div>
            </div>
          </div>
        )}

        {/* ERROR CONTAINER */}
        {errorMessage && (
          <div className={styles.error__wrapper}>
            <ShowErrorNotification errorMessage={errorMessage} />
          </div>
        )}

        <Button
          buttonType="submit"
          buttonName={save}
          buttonClass={styles['btn__submit--right-align']}
          disabledButton={!formik.dirty || !isPermissionEditProfile || loading || isProfileUpdated}
        />
      </form>
    </div>
  );
};

export default PatientProfileForm;
