import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import styles from '../../../styles.module.css';
import { getCurrenLang } from '../../../../redux/selector';
import { BreadcrumbAnt } from '../../../../utils/routers/Breadcrumb';
import { MainTitle } from '../../../../common';
import { OrgVideoBankIcon } from '../../../../theme/icons';
import OrganisationVideoBank from '../../../../components/Video/OrganisationVideoBank';

const OrganisationVideoBankPage = (): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const org_video_bank = t?.title.org_video_bank;

  // Breadcrumb path
  const routes = [
    {
      path: org_video_bank,
      breadcrumbName: org_video_bank,
    },
  ];

  return (
    <div className={styles.main__container}>
      <BreadcrumbAnt currentRoutes={routes} />
      <div className={cx([styles.main__wrapper, styles['main__wrapper--without-pd']])}>
        <div className={styles['main__wrapper--pd']}>
          <MainTitle title={org_video_bank} icon={<OrgVideoBankIcon />} />
        </div>

        <OrganisationVideoBank />
      </div>
    </div>
  );
};

export default OrganisationVideoBankPage;
