import { UserCountryEnum } from '../../utils/enums';
import { otherClaimIdHandler } from '../../utils/helper';
import { TPatient } from '../../utils/model';

export const initValuesUSProfile = (patientCountry: string, patient: TPatient): object => {
  if (patientCountry === UserCountryEnum.US) {
    return {
      typeOfClaim: patient?.patientInsurance?.claim
        ? {
          value: patient?.patientInsurance.claim.id,
          label: patient?.patientInsurance.claim.name,
        }
        : '',
      insuredIdNumber: patient?.patientInsurance?.insuredIdNumber || '',
      policyGroup: patient?.patientInsurance?.policyGroup || '',
      otherClaimId: otherClaimIdHandler(patient?.patientInsurance?.otherClaimId) || '',
      insurancePlanName: patient?.patientInsurance?.insurancePlanName || '',
    };
  }
  return {};
};

export const initUploadFile = [
  { file: null, name: '', type: '', uuid: '', thumbnail: '', description: '', isError: false },
];
export const initUrl = [{ url: '', description: '', isDone: false, isError: false }];
export const initErr = {
  error: false,
  format: false,
  heavy: false,
};

// Maximum file size for patient information uploads
export const filesSizeLimit = 52428800;
