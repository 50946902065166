import { loader } from 'graphql.macro';
import { useLazyQuery } from '@apollo/react-hooks';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

import {
  setAllQuestionnaires,
  setLoadingAllQuestionnaires,
  setLoadingPatientAvailableQuestionnaires,
  setLoadingPics,
  setLoadingQuestionnaires,
  setPainDetectPics,
  setPatientAllQuestionnaires,
  setPatientAvailableQuestionnaires,
} from '../../redux/questionnaires';
import { UseGetPatientQuestByProcType } from '../types';

// Query
const queryGetPatientAllQuestionnaires = loader('./gql/querygetPatientAllQuestionnaire.graphql');
const queryGetPatientAllPainDetectPics = loader('./gql/getPatientAllPainDetectPics.graphql');
const queryGetAllQuestionnaires = loader('./gql/queryGetAllQuestionnaires.graphql');
const queryGetPatientCompletedQuestionnaires = loader('./gql/getPatientCompletedQuestionnaires.graphql');
const gqlGetPatientQuestionnairesByProcedureType = loader('./gql/getPatientQuestionnairesByProcedureType.graphql');

// Get all patient questionnaires
export const useGetPatientAllQuestionnaires = () => {
  const [_getPatientAllQuestionnaires, { loading, error, data }] = useLazyQuery(
    queryGetPatientAllQuestionnaires,
  );
  const dispatch = useDispatch();

  const response = data?.getPatientAllQuestionnaires;

  useEffect(() => {
    if (data) {
      dispatch(setPatientAllQuestionnaires(response));
    }
  }, [response]);

  useEffect(() => {
    dispatch(setLoadingQuestionnaires(loading));
  }, [loading]);

  return {
    _getPatientAllQuestionnaires,
    loading,
    error,
    data: response,
  };
};

// Pain detect img for PROMS
export const useGetPatientAllPainDetectPics = () => {
  const [_getPatientAllPainDetectPics, { loading, error, data }] = useLazyQuery(
    queryGetPatientAllPainDetectPics,
  );
  const dispatch = useDispatch();
  const response = data && data.getPatientAllPainDetectPics;

  useEffect(() => {
    dispatch(setLoadingPics(loading));
  }, [loading]);

  useEffect(() => {
    if (response) {
      dispatch(setPainDetectPics(response));
    }
  }, [response]);

  return {
    _getPatientAllPainDetectPics,
    loadingPainDetectPics: loading,
    errorPainDetectPics: error,
    dataPainDetectPics: response,
  };
};

// List of all questionnaires
export const useGetAllQuestionnaires = () => {
  const [_getAllQuestionnaires, { data, loading }] = useLazyQuery(
    queryGetAllQuestionnaires,
  );
  const dispatch = useDispatch();

  const response = data && data.getAllQuestionnaires;

  useEffect(() => {
    if (response) {
      dispatch(setAllQuestionnaires(response));
    }
  }, [response]);

  useEffect(() => {
    dispatch(setLoadingAllQuestionnaires(loading));
  }, [loading]);

  return {
    _getAllQuestionnaires,
    questionnairesList: response,
  };
};

// All completed questionnaires by patient (for check raw data)
export const useGetPatientCompletedQuestionnaires = () => {
  const [_getPatientCompletedQuestionnaires, { loading, error, data }] = useLazyQuery(
    queryGetPatientCompletedQuestionnaires, {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  );
  const response = data && data.getPatientCompletedQuestionnaires;
  return {
    _getPatientCompletedQuestionnaires,
    loadingQuestionnaires: loading,
    errorQuestionnaires: error,
    completedQuestionnaires: response,
  };
};

// All available questionnaires for current patient
export const useGetPatientQuestionnairesByProcedureType = (): UseGetPatientQuestByProcType => {
  const [_getPatientAvailableQuestionnaires, { loading, error, data }] = useLazyQuery(
    gqlGetPatientQuestionnairesByProcedureType, {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  );
  const dispatch = useDispatch();

  const response = data && data.getPatientQuestionnairesByProcedureType;

  useEffect(() => {
    if (response) {
      dispatch(setPatientAvailableQuestionnaires(response));
    }
  }, [response]);

  useEffect(() => {
    dispatch(setLoadingPatientAvailableQuestionnaires(loading));
  }, [loading]);

  return {
    _getPatientAvailableQuestionnaires,
    loadingPatientAvailableQuestionnaires: loading,
    errorPatientAvailableQuestionnaires: error,
    patientAvailableQuestionnaires: response,
  };
};
