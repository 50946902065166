import { PERIOD_FOR_CHART } from '../../utils/enums';

// Type
const FILL_MOTIVATION = 'FILL_MOTIVATION';
const LOADING_PATIENT_MOTIVATIONS = 'LOADING_PATIENT_MOTIVATIONS';
const SET_MOTIVATION_ERROR = 'SET_MOTIVATION_ERROR';
const FILL_PAINE_LEVEL = 'FILL_PAINE_LEVEL';
const SET_PAIN_LEVEL_LOADING = 'SET_PAIN_LEVEL_LOADING';
const SET_PAIN_LEVEL_ERROR = 'SET_PAIN_LEVEL_ERROR';

// OLD
const FILL_MOTIVATIONS_OVERVIEW_TAB = 'FILL_MOTIVATIONS_OVERVIEW_TAB';

// TS
export interface MotivationChart {
  type: typeof FILL_MOTIVATION;
  payload: ChartsDataType[];
}
export interface ErrorType {
  type: typeof SET_MOTIVATION_ERROR | typeof SET_PAIN_LEVEL_ERROR;
  payload: any;
}

interface MotivationType {
  type: typeof FILL_PAINE_LEVEL | typeof FILL_MOTIVATIONS_OVERVIEW_TAB;
  payload: MotivationDataType;
}

interface LoadingType {
  type: typeof LOADING_PATIENT_MOTIVATIONS | typeof SET_PAIN_LEVEL_LOADING;
  payload: boolean;
}

interface DataType {
  data: object;
}

interface MotivationDataType {
  data: DataType[];
}

export interface ChartValuesType {
  name: string;
  value: number;
}
export interface ChartsDataType {
  graph: PERIOD_FOR_CHART;
  title: string;
  description: string;
  values: ChartValuesType[] | [];
  axis: string;
}

export interface MotivationState {
  motivation: ChartsDataType[];
  loading: boolean;
  error: any;
  painLevel: any;
  painLevelLoading: boolean;
  painLevelError: any;
}
type ActionTypes = MotivationType | LoadingType | ErrorType | MotivationChart;

// Action
export const setPatientMotivationData = (payload: ChartsDataType[] | []): MotivationChart => ({
  type: FILL_MOTIVATION,
  payload,
});
export const loadingMotivation = (data: boolean): LoadingType => ({
  type: LOADING_PATIENT_MOTIVATIONS,
  payload: data,
});
export const setPatientMotivationError = (payload: any): ErrorType => ({
  type: SET_MOTIVATION_ERROR,
  payload,
});
export const setPainLevel = (data: MotivationDataType): MotivationType => ({
  type: FILL_PAINE_LEVEL,
  payload: data,
});
export const setPainLevelLoading = (data: boolean): LoadingType => ({
  type: SET_PAIN_LEVEL_LOADING,
  payload: data,
});
export const setPainLevelError = (data: any): ErrorType => ({
  type: SET_PAIN_LEVEL_ERROR,
  payload: data,
});

const init: MotivationState = {
  loading: false,
  error: '',
  motivation: [],
  painLevel: [],
  painLevelLoading: false,
  painLevelError: '',
};

// Reducer
export const motivationReduser = (
  state = init,
  { type, payload }: ActionTypes,
): MotivationState => {
  switch (type) {
    case FILL_MOTIVATION:
      return {
        ...state,
        motivation: payload,
      };
    case LOADING_PATIENT_MOTIVATIONS:
      return {
        ...state,
        loading: payload,
      };
    case SET_MOTIVATION_ERROR:
      return {
        ...state,
        error: payload,
      };
    case FILL_PAINE_LEVEL: {
      return {
        ...state,
        painLevel: payload,
      };
    }
    case SET_PAIN_LEVEL_LOADING:
      return {
        ...state,
        painLevelLoading: payload,
      };
    case SET_PAIN_LEVEL_ERROR: {
      return {
        ...state,
        painLevelError: payload,
      };
    }
    default:
      return state;
  }
};
