import React, { ReactElement, useEffect, useState } from 'react';
import { Table } from 'antd';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import styles from './styles.module.css';
import 'antd/dist/antd.css';
import './style.css';
import { getColumns } from './components/Columns';
import {
  GetState,
  // getCurrenLang,
  getCurrentFormatDate,
  getHcpName,
  getUserCountry,
} from '../../redux/selector';
import {
  ExpandedRowRecord,
  GetNestedColumns,
  NestedTableData,
  Records,
  RewardsModalState,
  // TableHCPAndPatientProps,
  UserProps,
} from './types';
import { Modal, ConfirmatioModal, WarningNotification } from '../../common';
import { getNestedColumns } from './components/NestedColumns';
import { useHcpListByPatient } from '../../graphql/dashboard';
import { HcpListByPatient } from '../HcpList/list';
import { concatData } from '../../utils/helper';
import { Popup } from '../../common/Popup';
import useCheckPermissions from '../../hooks/useCheckPermissions';
import { PERMISSIONS } from '../../utils/enums';
import {
  useCreatePatientRewards,
  useCreatePatientRewardsByHcp,
  useShutdownPatientRewards,
  useShutdownPatientRewardsByHcp,
} from '../../graphql/rewards';
import { pageSizeOptions } from '../../utils/variables';
import { ModalResend, ModalResendCode, ModalUnlockAccount } from './components/ResendInvite';
import { CloseBtn } from '../../common/Button/Button';
import { RewardsModal } from './components/RewardsModal';

const TableView = ({
  dataSrc,
  currentPage,
  pageSize,
  totalItems,
  mode,
  deactivateUser,
  deactiveteUserLoading,
  deactiveteUserError,
  seccessDeletedUser,
  sortedInfo,
  filteredInfo,
  categoryFromQuery,
  setResend,
  onChangePagination,
  _deactiveteAccount,
  getColumnSearchProps,
  setDeactivateUser,
  setDeleteUser,
  _onAccept,
  _showProfile,
  setSortedInfo,
  refreshTable,
  setLoading,
  isAdmin,
  t,
  _redirectToMyTeam,
}: // }: TableHCPAndPatientProps): ReactElement => {
any): ReactElement => {
  const page_label = t?.dashboard.hcp.table.page;
  const deactivate = t?.notifications.deactivate;
  const are_you_sure = t?.notifications.are_you_sure;
  const successfully_deactivated = t?.notifications.successfully_deactivated;
  const deactivate_btn = t?.common.deactivate;
  const ok = t?.common.ok;
  const hcp_invited_by_hcp = t?.dashboard.hcp.table.hcp_invited_by_hcp;
  const patients_shared_progress_to_him = t?.dashboard.hcp.table.patients_shared_progress_to_him;
  const hcp_invited_by_hcp_and_patients_shared_progress_to_him =
    t?.dashboard.hcp.table.hcp_invited_by_hcp_and_patients_shared_progress_to_him;
  const hcp_who_invited_me = t?.dashboard.hcp.table.hcp_who_invited_me;

  const initState = {
    isOpen: false,
    candidatId: null,
    firstName: '',
    lastName: '',
    hospitalName: '',
  };

  const [isModalOpen, setModal] = useState(false);
  const [isResendCodeModal, setResendCodeModal] = useState(false);
  const [isUnlockAccount, setUnlockAccount] = useState(false);
  const [user, setUser] = useState<any>(null);
  const [isDeactivete, setModalDeactivete] = useState(false);
  const [isHcpListByPatientModal, setIsHcpListByPatientModal] = useState(false);
  const [turnOnRewardsModal, setTurnOnRewardsModal] = useState<RewardsModalState>(initState);
  const [isConfirmRewards, setIsConfirmRewards] = useState(false);
  const [isShowPopup, setShowPopup] = useState({ isShow: false, content: '' });
  const [isWarningDeactivedRewards, setIsWarningDeactivedRewards] = useState<RewardsModalState>(
    initState,
  );

  const formatsDate = useSelector((state: GetState) => getCurrentFormatDate(state));
  const userCountry: any = useSelector<GetState>((state) => getUserCountry(state));
  const windowClientWidth = useSelector((state: GetState) => state.common.screen.innerWidth);
  const currentUser: any = useSelector((state: GetState) => getHcpName(state));
  const currentRewardsOption: number = currentUser.rewardsType;

  const {
    _getHcpListByPatient,
    hcpListByPatient,
    hcpListError,
    hcpListLoading,
  } = useHcpListByPatient();

  const handleModal = (): void => {
    setModal(() => !isModalOpen);
  };
  const _onInviteResendInvite = (userData: UserProps): void => {
    handleModal();
    setUser(userData);
  };

  // Resend SignIn(SignUp) code to patient
  const _resendCode = (userData: UserProps): void => {
    setUser(userData);
    setResendCodeModal(() => true);
  };

  const _onDeactivate = (userForDeactivate: UserProps): void => {
    setModalDeactivete(() => !isDeactivete);
    const name = userForDeactivate && userForDeactivate.firstName;
    setDeactivateUser(name);
    setDeleteUser(userForDeactivate);
  };

  // Unlock patient account
  const _onLockPatientAccount = (userData: UserProps): void => {
    setUser(userData);
    setUnlockAccount(() => true);
  };

  const _refreshDashbord = (): void => {
    setModalDeactivete(() => false);
    window.location.assign(`/admin?category=${categoryFromQuery}`);
  };

  const {
    _onCreatePatientRewards,
    createRewardsLoading,
    createRewardsError,
    saccessCreatePatientRewards,
  } = useCreatePatientRewards();
  const {
    _onShutdownPatientRewards,
    shutdownRewardsLoading,
    shutdownRewardsError,
    saccessShutdownPatientRewards,
  } = useShutdownPatientRewards();

  const {
    _onCreatePatientRewardsByHcp,
    createRewardsByHcpLoading,
    createRewardsByHcpError,
    saccessCreatePatientRewardsByHcp,
  } = useCreatePatientRewardsByHcp();
  const {
    _onShutdownPatientRewardsByHcp,
    shutdownRewardsByHcpLoading,
    shutdownRewardsByHcpError,
    saccessShutdownPatientRewardsByHcp,
  } = useShutdownPatientRewardsByHcp();

  useEffect(() => {
    if (saccessCreatePatientRewards || saccessCreatePatientRewardsByHcp) {
      setIsConfirmRewards(() => true);
    }
  }, [saccessCreatePatientRewards, saccessCreatePatientRewardsByHcp]);

  useEffect(() => {
    const currentLoading = isAdmin ? createRewardsLoading : createRewardsByHcpLoading;
    setLoading(() => currentLoading);
  }, [createRewardsLoading, createRewardsByHcpLoading]);

  useEffect(() => {
    const currentLoading = isAdmin ? shutdownRewardsLoading : shutdownRewardsByHcpLoading;
    setLoading(() => currentLoading);
  }, [shutdownRewardsLoading, shutdownRewardsByHcpLoading]);

  useEffect(() => {
    if (saccessShutdownPatientRewards || saccessShutdownPatientRewardsByHcp) {
      setIsWarningDeactivedRewards(initState);
      refreshTable();
    }
  }, [saccessShutdownPatientRewards || saccessShutdownPatientRewardsByHcp]);

  useEffect(() => {
    if (createRewardsError || createRewardsByHcpError) {
      toast.error(createRewardsError || createRewardsByHcpError);
    }
    if (shutdownRewardsError || shutdownRewardsByHcpError) {
      toast.error(shutdownRewardsError || shutdownRewardsByHcpError);
    }
  }, [
    createRewardsError,
    shutdownRewardsError,
    createRewardsByHcpError,
    shutdownRewardsByHcpError,
  ]);

  const onConfermDeactiveUserJSX = !seccessDeletedUser && !deactiveteUserError && (
    <WarningNotification
      title={`${deactivate} ${deactivateUser || 'user'}?`}
      contant={are_you_sure}
      buttonName={deactivate_btn}
      onCancel={(): void => setModalDeactivete(() => !isDeactivete)}
      onSubmit={_deactiveteAccount}
    />
  );

  const seccessDeactivedJsx = seccessDeletedUser &&
    !deactiveteUserError &&
    !deactiveteUserLoading && (
      <ConfirmatioModal
        contant={successfully_deactivated}
        buttonTitle={ok}
        buttonMethod={_refreshDashbord}
      />
    );

  const errorDeactivedJsx = !deactiveteUserLoading && deactiveteUserError && (
    <>
      <WarningNotification
        title=""
        contant={deactiveteUserError}
        buttonName={ok}
        onCancel={(): void => setModalDeactivete(() => !isDeactivete)}
        onSubmit={_refreshDashbord}
      />
    </>
  );

  const expandedRowRender = (record: ExpandedRowRecord, index: number): JSX.Element => {
    const invitedByHcpData = record.invitedByHcp;
    const invitedByPatientData = record.invitedByPatient;
    const listOfHcpsInvitedByThisHcp = record.hcpWhoInvitedMe;
    let title = '';
    if (invitedByHcpData?.length && !invitedByPatientData?.length) {
      title = hcp_invited_by_hcp;
    } else if (!invitedByHcpData?.length && invitedByPatientData?.length) {
      title = patients_shared_progress_to_him;
    } else if (invitedByHcpData?.length && invitedByPatientData?.length) {
      title = hcp_invited_by_hcp_and_patients_shared_progress_to_him;
    }
    const nestedColumns: GetNestedColumns[] = getNestedColumns(t, title, windowClientWidth);
    const nestedColumns2: GetNestedColumns[] = getNestedColumns(
      t,
      hcp_who_invited_me,
      windowClientWidth,
    );
    const source: NestedTableData[] = concatData(
      title,
      invitedByHcpData,
      invitedByPatientData,
      index,
    );
    const source2: NestedTableData[] = concatData(
      hcp_who_invited_me,
      listOfHcpsInvitedByThisHcp,
      [],
      index, // Index +100
    );
    return (
      <>
        {source2?.length ? (
          <Table columns={nestedColumns2} dataSource={source2} pagination={false} />
        ) : (
          <></>
        )}
        {source?.length ? (
          <Table columns={nestedColumns} dataSource={source} pagination={false} />
        ) : (
          <></>
        )}
      </>
    );
  };

  const openListHcp = (record: Records): void => {
    _getHcpListByPatient({
      variables: {
        patientId: record.id,
      },
    });
    setIsHcpListByPatientModal(() => true);
  };

  const onclose = (): void => {
    setIsHcpListByPatientModal(() => false);
  };

  const isPermissionEditProfile = useCheckPermissions(PERMISSIONS.EDIT_PATIENT_PROFILE_INFO_OTM);

  const columns = getColumns(
    t,
    getColumnSearchProps,
    _onInviteResendInvite,
    _resendCode,
    _onAccept,
    _showProfile,
    _onDeactivate,
    categoryFromQuery,
    mode,
    sortedInfo,
    filteredInfo,
    windowClientWidth,
    refreshTable,
    openListHcp,
    setTurnOnRewardsModal,
    setIsWarningDeactivedRewards,
    isAdmin,
    setShowPopup,
    formatsDate,
    userCountry,
    isPermissionEditProfile,
    currentRewardsOption,
    _redirectToMyTeam,
    _onLockPatientAccount,
  );

  return (
    <>
      <div className={styles.table_container} id="container-fluid">
        <Table
          columns={columns}
          dataSource={dataSrc}
          size="small"
          onChange={onChangePagination}
          pagination={{
            current: currentPage,
            pageSize,
            locale: { items_per_page: `/${page_label}` },
            total: totalItems,
            pageSizeOptions,
            showSizeChanger: true,
          }}
          expandable={
            categoryFromQuery === 'hcp' && windowClientWidth > 320 ? { expandedRowRender } : {}
          }
        />
      </div>

      {isModalOpen && (
        <ModalResend
          onModalClose={handleModal}
          user={user}
          updateResend={setResend}
          setSortedInfo={setSortedInfo}
          setUser={setUser}
        />
      )}
      {isResendCodeModal && (
        <ModalResendCode
          onModalClose={setResendCodeModal}
          user={user}
          updateResend={setResend}
          setSortedInfo={setSortedInfo}
          setUser={setUser}
        />
      )}
      {isUnlockAccount && (
        <ModalUnlockAccount
          onModalClose={setUnlockAccount}
          user={user}
          refreshTable={refreshTable}
          setUser={setUser}
          t={t}
        />
      )}
      {isDeactivete && (
        <Modal onClose={(): void => setModalDeactivete(() => !isDeactivete)}>
          <>
            {onConfermDeactiveUserJSX}
            {seccessDeactivedJsx}
            {errorDeactivedJsx}
          </>
        </Modal>
      )}
      {isHcpListByPatientModal && (
        <Modal onClose={onclose} style={styles['modal__hcp-list']}>
          <CloseBtn close={onclose} />
          <HcpListByPatient
            hcpListByPatient={hcpListByPatient}
            hcpListLoading={hcpListLoading}
            hcpListError={hcpListError}
          />
        </Modal>
      )}
      <RewardsModal
        _onCreatePatientRewards={isAdmin ? _onCreatePatientRewards : _onCreatePatientRewardsByHcp}
        _onShutdownPatientRewards={
          isAdmin ? _onShutdownPatientRewards : _onShutdownPatientRewardsByHcp
        }
        refreshTable={refreshTable}
        turnOnRewardsModal={turnOnRewardsModal}
        setTurnOnRewardsModal={setTurnOnRewardsModal}
        isConfirmRewards={isConfirmRewards}
        setIsConfirmRewards={setIsConfirmRewards}
        isWarningDeactivedRewards={isWarningDeactivedRewards}
        setIsWarningDeactivedRewards={setIsWarningDeactivedRewards}
        setRewardsDate={(): null => null}
      />

      {isShowPopup.isShow && (
        <Popup
          title={isShowPopup.content}
          content=""
          buttonName={ok}
          onClosePopup={(): void => setShowPopup({ isShow: false, content: '' })}
        />
      )}
    </>
  );
};

export default TableView;
