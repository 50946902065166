import React, { ReactElement } from 'react';
import styles from '../styles.module.css';
import { GetNestedColumns } from '../types';
import { mobileFormat } from '../../../utils/variables';

export const getNestedColumns = (
  t: any,
  title: string,
  windowclientWidth: number,
): GetNestedColumns[] => {
  const tablePadding = 91;
  const nestedColumnsConfig = [
    {
      title: (
        <div
          style={{
            textAlign: 'start',
            width: windowclientWidth > mobileFormat ? 'unset' : windowclientWidth - tablePadding,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {title}
        </div>
      ),
      dataIndex: 'title',
      key: 'title',
      minHeight: '61px',
      children: [
        {
          title: <></>,
          dataIndex: 'firstLastNameEmail',
          key: 'firstLastNameEmail',
          className: 'custom-row',
          minHeight: '61px',
          width: '200px',
          render: (text: any, record: any): ReactElement => (
            <div className={styles['table__sm-row']} role="presentation">
              <div className={styles['table__first-last-name']}>
                {`${record?.firstName || ''} ${record?.lastName || ''}`}
              </div>
              <div className={styles['table__sm-email']}>{record?.email || ''}</div>
            </div>
          ),
        },
        {
          title: (
            <span className={styles.header_table}>
              {t?.dashboard.hcp.profile_patient.information.first_name}
            </span>
          ),
          dataIndex: 'firstName',
          key: 'firstName',
          width: windowclientWidth > mobileFormat ? '200px' : '80px',
          minHeight: '61px',
          className: 'custom-row',
        },
        {
          title: (
            <span className={styles.header_table}>
              {t?.dashboard.hcp.profile_patient.information.last_name}
            </span>
          ),
          dataIndex: 'lastName',
          key: 'lastName',
          width: windowclientWidth > mobileFormat ? '230px' : '90px',
          minHeight: '61px',
          className: 'custom-row',
        },
        {
          title: (
            <span className={styles.header_table}>
              {t && t.dashboard.admin.table.hcp.profession}
            </span>
          ),
          dataIndex: 'profession',
          key: 'profession',
          width: '192px',
          minHeight: '61px',
          className: 'custom-row',
        },
        {
          title: <span className={styles.header_table}>{t && t.dashboard.hcp.table.email}</span>,
          dataIndex: 'email',
          key: 'email',
          width: '300px',
          minHeight: '61px',
          className: 'custom-row',
        },
        {
          title: (
            <span className={styles.header_table}>
              {t?.dashboard.hcp.table.phone_number || 'Phone Number'}
            </span>
          ),
          dataIndex: 'phone',
          key: 'phone',
          width: '150px',
          minHeight: '61px',
          className: 'custom-row',
        },
      ],
    },
  ];
  if (windowclientWidth <= mobileFormat) {
    nestedColumnsConfig[0].children = [
      ...nestedColumnsConfig[0].children.filter((column) =>
        ['firstLastNameEmail'].includes(column.key),
      ),
    ];
  } else {
    nestedColumnsConfig[0].children = [
      ...nestedColumnsConfig[0].children.filter((column) => column.key !== 'firstLastNameEmail'),
    ];
  }
  return nestedColumnsConfig;
};
