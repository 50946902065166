import { gql, loader } from 'graphql.macro';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { UseQueryLang, UseQueryLangWithValue } from '../types';

// Queries
const queryLang = loader('./gql/queryLanguage.graphql');
const queryLanguageWithValue = loader('./gql/queryLanguageWithValue.graphql');

export const useQueryLang = (): UseQueryLang => {
  const { loading, error, data } = useQuery(queryLang);

  const lang = data ? data.getLanguages : null;

  return { lang, loading, error };
};

export const useQueryLangWithValue = (): UseQueryLangWithValue => {
  const [_getLang, { loading, error, data }] = useLazyQuery(queryLanguageWithValue);

  const lang = data ? data.getLanguagesWithValue : null;

  return {
    _getLang,
    lang,
    loading,
    error,
  };
};

export const GET_LANG_OPTIONS = gql`
  query getLanguagesWithValue($langCode: String!) {
    getLanguagesWithValue(langCode: $langCode) {
      id
      code
      description
    }
  }
`;
