import moment from 'moment';
import {
  Options,
  PeriodDropDownOptions,
  PeriodListOption,
  ReminderPeriodOptionsType,
} from '../model';
import { TBar } from '../../components/Charts/StepCount/types';
import { PERIOD_FOR_CHART, PERIOD_TYPE, REMINDER_PERIOD } from '../enums';

export const daysStringFormat = 'YYYY-MM-DDTHH:mm:ss.sssZ';

export const chartItemsCount: { [key: string]: number } = {
  '2Y': 24,
  Y: 12,
  '6M': 24,
  '3M': 12,
  '6W': 6,
  M: 4,
  '2W': 14,
  W: 7,
};

export const listOptionInit = {
  value: '7',
  label: 'Last 7 days',
  periodType: 'days',
  periodFrom: moment().add(-7, 'days').format(daysStringFormat),
  dateTo: moment().format(daysStringFormat),
};
export const overviewListOptionInit = {
  value: PERIOD_FOR_CHART.W,
  label: 'Last 7 days',
  periodFrom: moment().add(-6, 'days').format(daysStringFormat),
};
export const overviewChartsPeriods = (optionLabels: any): PeriodDropDownOptions[] => [
  {
    value: PERIOD_FOR_CHART.W,
    label: optionLabels?.last_7_days,
    periodFrom: moment().add(-6, 'days').format(daysStringFormat),
    periodType: 'day',
  },
  {
    value: PERIOD_FOR_CHART.W2,
    label: optionLabels?.last_14_days,
    periodFrom: moment().add(-13, 'days').format(daysStringFormat),
    periodType: 'day',
  },
  {
    value: PERIOD_FOR_CHART.M,
    label: optionLabels?.last_30_days,
    periodFrom: moment().add(-4, 'weeks').format(daysStringFormat),
    periodType: 'week',
  },
  {
    value: PERIOD_FOR_CHART.W6,
    label: optionLabels?.last_6_weeks,
    periodFrom: moment().add(-6, 'weeks').startOf('weeks').format(daysStringFormat),
    periodType: 'week',
  },
  {
    value: PERIOD_FOR_CHART.M3,
    label: optionLabels?.last_3_months,
    periodFrom: moment().add(-12, 'weeks').format(daysStringFormat),
    periodType: 'week',
  },
  {
    value: PERIOD_FOR_CHART.M6,
    label: optionLabels?.last_6_months,
    periodFrom: moment().add(-24, 'weeks').startOf('week').format(daysStringFormat),
    periodType: 'week',
  },
  {
    value: PERIOD_FOR_CHART.Y,
    label: optionLabels?.last_12_months,
    periodFrom: moment().add(-12, 'month').startOf('month').format(daysStringFormat),
    periodType: 'month',
  },
  {
    value: PERIOD_FOR_CHART.Y2,
    label: optionLabels?.last_24_months,
    periodFrom: moment().add(-24, 'month').startOf('month').format(daysStringFormat),
    periodType: 'month',
  },
];

export const periodListOption = (t: any): PeriodListOption => ({
  '7Days': {
    value: '7',
    label: t && t.dashboard.hcp.profile_patient.completion_and_steps.last_7_days,
    periodType: 'days',
    periodFrom: moment().add(-7, 'days').format(daysStringFormat),
    dateTo: moment().format(daysStringFormat),
  },
  '14Days': {
    value: '14',
    label: t && t.dashboard.hcp.profile_patient.completion_and_steps.last_14_days,
    periodType: 'days',
    periodFrom: moment().add(-13, 'days').format(daysStringFormat),
    dateTo: moment().format(daysStringFormat),
  },
  '30Days': {
    value: '30',
    label: t && t.dashboard.hcp.profile_patient.completion_and_steps.last_30_days,
    periodType: 'days',
    periodFrom: moment().add(-29, 'days').format(daysStringFormat),
    dateTo: moment().format(daysStringFormat),
  },
});

export const completionList = (t: any): PeriodListOption => ({
  '7Days': {
    value: '7',
    label: t && t.dashboard.hcp.profile_patient.completion_and_steps.last_7_days,
    periodType: 'days',
    periodFrom: moment().add(-7, 'days').format(daysStringFormat),
    dateTo: moment().format(daysStringFormat),
  },
  '14Days': {
    value: '14',
    label: t && t.dashboard.hcp.profile_patient.completion_and_steps.last_14_days,
    periodType: 'days',
    periodFrom: moment().add(-13, 'days').format(daysStringFormat),
    dateTo: moment().format(daysStringFormat),
  },
  '30Days': {
    value: '30',
    label: t && t.dashboard.hcp.profile_patient.completion_and_steps.last_30_days,
    periodType: 'days',
    periodFrom: moment().add(-29, 'days').format(daysStringFormat),
    dateTo: moment().format(daysStringFormat),
  },
  '6Weeks': {
    value: '6',
    label: t && t.dashboard.hcp.profile_patient.completion_and_steps.last_6_weeks,
    periodType: 'weeks',
    periodFrom: moment().add(-6, 'weeks').startOf('weeks').format(daysStringFormat),
    dateTo: moment().add(-1, 'weeks').endOf('weeks').format(daysStringFormat),
  },
  '3Months': {
    value: '13',
    label: t && t.dashboard.hcp.profile_patient.completion_and_steps.last_3_months,
    periodType: 'weeks',
    periodFrom: moment().add(-13, 'weeks').startOf('week').format(daysStringFormat),
    // periodFrom: moment().add(-3, 'month').startOf('week').format(daysStringFormat),
    dateTo: moment().add(-1, 'weeks').endOf('weeks').format(daysStringFormat),
  },
  '6Months': {
    value: '26',
    label: t && t.dashboard.hcp.profile_patient.completion_and_steps.last_6_months,
    periodType: 'weeks',
    periodFrom: moment().add(-26, 'weeks').startOf('week').format(daysStringFormat),
    // periodFrom: moment().add(-6, 'month').startOf('week').format(daysStringFormat),
    dateTo: moment().add(-1, 'weeks').endOf('weeks').format(daysStringFormat),
  },
  '12Months': {
    value: '12',
    label: t && t.dashboard.hcp.profile_patient.completion_and_steps.last_12_months,
    periodType: 'months',
    periodFrom: moment().add(-12, 'month').startOf('month').format(daysStringFormat),
    dateTo: moment().add(-1, 'month').endOf('month').format(daysStringFormat),
  },
  '24Months': {
    value: '24',
    label: t?.dashboard.hcp.profile_patient.completion_and_steps.last_24_months,
    periodType: 'months',
    periodFrom: moment().add(-24, 'month').startOf('month').format(daysStringFormat),
    dateTo: moment().add(-1, 'month').endOf('month').format(daysStringFormat),
  },
});

export const periodsForChart = (optionLabels: any): PeriodListOption => ({
  '7Days': {
    value: '7',
    label: optionLabels?.last_7_days,
    periodType: 'days',
    periodFrom: moment().add(-7, 'days').format(daysStringFormat),
    dateTo: moment().format(daysStringFormat),
  },
  '14Days': {
    value: '14',
    label: optionLabels?.last_14_days,
    periodType: 'days',
    periodFrom: moment().add(-13, 'days').format(daysStringFormat),
    dateTo: moment().format(daysStringFormat),
  },
  '30Days': {
    value: '30',
    label: optionLabels?.last_30_days,
    periodType: 'days',
    periodFrom: moment().add(-29, 'days').format(daysStringFormat),
    dateTo: moment().format(daysStringFormat),
  },
  '6Weeks': {
    value: '6',
    label: optionLabels?.last_6_weeks,
    periodType: 'weeks',
    periodFrom: moment().add(-6, 'weeks').startOf('weeks').format(daysStringFormat),
    dateTo: moment().add(-1, 'weeks').endOf('weeks').format(daysStringFormat),
  },
  '3Months': {
    value: '13',
    label: optionLabels?.last_3_months,
    periodType: 'weeks',
    periodFrom: moment().add(-13, 'weeks').startOf('week').format(daysStringFormat),
    dateTo: moment().add(-1, 'weeks').endOf('weeks').format(daysStringFormat),
  },
  '6Months': {
    value: '26',
    label: optionLabels?.last_6_months,
    periodType: 'weeks',
    periodFrom: moment().add(-26, 'weeks').startOf('week').format(daysStringFormat),
    dateTo: moment().add(-1, 'weeks').endOf('weeks').format(daysStringFormat),
  },
  '12Months': {
    value: '12',
    label: optionLabels?.last_12_months,
    periodType: 'months',
    periodFrom: moment().add(-12, 'month').startOf('month').format(daysStringFormat),
    dateTo: moment().add(-1, 'month').endOf('month').format(daysStringFormat),
  },
});

export const skipStopChartConfig = (t: any): TBar[] => {
  const number_of_exercises_skipped =
    t?.dashboard.hcp.profile_patient.completion_and_steps.number_of_exercises_skipped;
  const number_of_exercises_stopped =
    t?.dashboard.hcp.profile_patient.completion_and_steps.number_of_exercises_stopped;
  const number_of_reps = t?.dashboard.hcp.profile_patient.completion_and_steps.number_of_reps;

  return [
    {
      name: number_of_exercises_skipped || 'skip 1',
      color: '#F1A83C',
    },
    {
      name: number_of_exercises_stopped || 'skip 2',
      color: '#F15B07',
    },
    {
      name: number_of_reps || 'skip 3',
      color: '#1C9CE4',
    },
  ];
};

export const getMailingFrequencyOptions = (t: any): Options[] => [
  {
    value: 1,
    label: t?.hcp.manage.daily,
  },
  {
    value: 2,
    label: t?.hcp.manage.weekly,
  },
  {
    value: 3,
    label: t?.hcp.manage.monthly,
  },
];

export const getReminderPeriodOptions = (t: any): ReminderPeriodOptionsType[] => {
  const week = t?.dashboard.hcp.profile_patient.completion_and_steps.week;
  const weeks = t?.dashboard.hcp.profile_patient.completion_and_steps.weeks;
  const months = t?.dashboard.hcp.profile_patient.completion_and_steps.months;

  const configWeek = [
    {
      value: REMINDER_PERIOD.WEEK_1,
      label: `${1} ${week}`,
      timePeriod: PERIOD_TYPE.WEEK,
      amount: 1,
    },
    {
      value: REMINDER_PERIOD.WEEK_2,
      label: `${2} ${weeks}`,
      timePeriod: PERIOD_TYPE.WEEK,
      amount: 2,
    },
    {
      value: REMINDER_PERIOD.WEEK_3,
      label: `${3} ${weeks}`,
      timePeriod: PERIOD_TYPE.WEEK,
      amount: 3,
    },
    {
      value: REMINDER_PERIOD.WEEK_4,
      label: `${4} ${weeks}`,
      timePeriod: PERIOD_TYPE.WEEK,
      amount: 4,
    },
    {
      value: REMINDER_PERIOD.WEEK_5,
      label: `${5} ${weeks}`,
      timePeriod: PERIOD_TYPE.WEEK,
      amount: 5,
    },
    {
      value: REMINDER_PERIOD.WEEK_6,
      label: `${6} ${weeks}`,
      timePeriod: PERIOD_TYPE.WEEK,
      amount: 6,
    },
    {
      value: REMINDER_PERIOD.WEEK_7,
      label: `${7} ${weeks}`,
      timePeriod: PERIOD_TYPE.WEEK,
      amount: 7,
    },
    {
      value: REMINDER_PERIOD.WEEK_8,
      label: `${8} ${weeks}`,
      timePeriod: PERIOD_TYPE.WEEK,
      amount: 8,
    },
    {
      value: REMINDER_PERIOD.WEEK_9,
      label: `${9} ${weeks}`,
      timePeriod: PERIOD_TYPE.WEEK,
      amount: 9,
    },
    {
      value: REMINDER_PERIOD.WEEK_10,
      label: `${10} ${weeks}`,
      timePeriod: PERIOD_TYPE.WEEK,
      amount: 10,
    },
    {
      value: REMINDER_PERIOD.WEEK_11,
      label: `${11} ${weeks}`,
      timePeriod: PERIOD_TYPE.WEEK,
      amount: 11,
    },
    {
      value: REMINDER_PERIOD.WEEK_12,
      label: `${12} ${weeks}`,
      timePeriod: PERIOD_TYPE.WEEK,
      amount: 12,
    },
  ];
  const configMonth = [
    {
      value: REMINDER_PERIOD.MONTH_4,
      label: `${4} ${months}`,
      timePeriod: PERIOD_TYPE.MONTH,
      amount: 4,
    },
    {
      value: REMINDER_PERIOD.MONTH_5,
      label: `${5} ${months}`,
      timePeriod: PERIOD_TYPE.MONTH,
      amount: 5,
    },
    {
      value: REMINDER_PERIOD.MONTH_6,
      label: `${6} ${months}`,
      timePeriod: PERIOD_TYPE.MONTH,
      amount: 6,
    },
    {
      value: REMINDER_PERIOD.MONTH_7,
      label: `${7} ${months}`,
      timePeriod: PERIOD_TYPE.MONTH,
      amount: 7,
    },
    {
      value: REMINDER_PERIOD.MONTH_8,
      label: `${8} ${months}`,
      timePeriod: PERIOD_TYPE.MONTH,
      amount: 8,
    },
    {
      value: REMINDER_PERIOD.MONTH_9,
      label: `${9} ${months}`,
      timePeriod: PERIOD_TYPE.MONTH,
      amount: 9,
    },
    {
      value: REMINDER_PERIOD.MONTH_10,
      label: `${10} ${months}`,
      timePeriod: PERIOD_TYPE.MONTH,
      amount: 10,
    },
    {
      value: REMINDER_PERIOD.MONTH_11,
      label: `${11} ${months}`,
      timePeriod: PERIOD_TYPE.MONTH,
      amount: 11,
    },
    {
      value: REMINDER_PERIOD.MONTH_12,
      label: `${12} ${months}`,
      timePeriod: PERIOD_TYPE.MONTH,
      amount: 12,
    },
  ];

  return [...configWeek, ...configMonth];
};

export const getScheduleNumberOptions = (): Options[] => {
  const options = [];
  for (let i = 1; i <= 15; i += 1) {
    options.push({
      value: i,
      label: String(i),
    });
  }
  return options;
};

export const getUnitOptions = (t_compl_steps: {
  days: string;
  weeks: string;
  months: string;
}): Options[] => {
  const days_t = t_compl_steps?.days;
  const weeks = t_compl_steps?.weeks;
  const months = t_compl_steps?.months;
  return [
    { value: 1, label: days_t },
    { value: 2, label: weeks },
    { value: 3, label: months },
  ];
};

export const timerPeriod = {
  1: 'Days',
  2: 'Weeks',
  3: 'Months',
};
