import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';

import styles from './styles.module.css';
import { Button, SelectFloatLabelWithSearch } from '../../common';
import { getCurrenLang } from '../../redux/selector';
import { ShowErrorNotification } from './ErrorMessage';
import { ProfileFormProps } from './types';
import { InputFloatLabel } from '../../common/Input';
import { SelectFloatLabel } from '../../common/Input/Select';
import { InputPhoneNumberFloatLabel } from '../../common/Input/PhoneNumber';
import { validationFieldProfile } from '../../utils';
import { LANG, ProfileUserSecondaryPages } from '../../utils/enums';

export const ProfileForm = ({
  hcp,
  errorMessage,
  disabledField,
  options,
  hospitalNameOptions,
  professionOptions,
  departmentOptions,
  openPage,
  onSend,
  setProfileUpdated,
  isProfileUpdated,
  isHcp,
}: ProfileFormProps): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const first_name = t?.profile_hcp.first_name;
  const last_name = t?.profile_hcp.last_name;
  const profession = t?.profile_hcp.profession;
  const email = t?.profile_hcp.email;
  const number_phone = t?.profile_hcp.number_phone;
  const lang = t?.profile_hcp.lang;
  const save = t?.common.save;
  const change_password = t?.common.change_password;
  const organisation_name = t?.common.organisation_name;
  const no_options = t?.invite_patient.please_enter_min_3_characters;
  const password = t?.auth.password;
  const department_t = t?.dashboard.admin.table.hcp.department;

  const [currentHcpCountry, setCurrentHcpCountry] = useState<string>(LANG.EN_GB);

  const initialValuesProfile = useMemo(() => {
    setCurrentHcpCountry(hcp.country);
    return {
      firstName: hcp.firstName,
      lastName: hcp.lastName,
      professionId: hcp.profession
        ? { value: hcp.profession.id, label: hcp.profession.type }
        : null,
      email: hcp.email,
      phone: hcp.phone,
      langId: { value: hcp.lang.id, label: hcp.lang.description, code: hcp.lang.code },
      organisationName: hcp.hospital ? { value: hcp.hospital.id, label: hcp.hospital.name } : '',
      department: hcp.department ? { value: hcp.department.id, label: hcp.department.type } : '',
    };
  }, [hcp]);

  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: initialValuesProfile,
    validationSchema: (): any => validationFieldProfile(t, currentHcpCountry),
    onSubmit: (values) => onSend(values),
  });
  const { values, errors, dirty, touched, isValid } = formik;

  // Remove Save icon
  useEffect(() => {
    if (isProfileUpdated) {
      setProfileUpdated(() => false);
    }
  }, [values]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={styles.form_wrapper}>
        <div className={styles.row}>
          <div className={styles['cell-50']}>
            <InputFloatLabel
              inputId="firstName"
              inputName="firstName"
              placeholder={first_name}
              inputType="text"
              hasErrors={errors.firstName}
              inputValue={values.firstName}
              isTouched={touched.firstName}
              onChangeMethod={formik.handleChange}
              isRedStar
              disabled={disabledField}
            />
          </div>
          <div className={styles['cell-50']}>
            <InputFloatLabel
              inputId="lastName"
              inputName="lastName"
              placeholder={last_name}
              inputType="text"
              hasErrors={errors.lastName}
              inputValue={values.lastName}
              isTouched={touched.lastName}
              onChangeMethod={formik.handleChange}
              isRedStar
              disabled={disabledField}
            />
          </div>
        </div>

        <SelectFloatLabel
          name="professionId"
          inputId="professionId"
          onChange={formik.setFieldValue}
          hasErrors={errors.professionId}
          inputValue={values.professionId}
          isTouched={touched.professionId}
          options={professionOptions}
          disabledField={disabledField}
          selected={values.professionId}
          type="user profile"
          placeholder={profession}
          isRedStar
        />
        <SelectFloatLabelWithSearch
          inputId="organisationName"
          name="organisationName"
          onChange={formik.setFieldValue}
          options={hospitalNameOptions}
          hasErrors={errors.organisationName}
          isTouched={touched.organisationName}
          selected={values.organisationName}
          inputValue={values.organisationName}
          placeholder={organisation_name}
          noOptionsMessage={no_options}
          disabledField={disabledField}
          type="user profile"
          isRedStar
        />
        <SelectFloatLabel
          name="department"
          inputId="departmentId"
          onChange={formik.setFieldValue}
          hasErrors={errors.department}
          inputValue={values.department}
          isTouched={touched.department}
          options={departmentOptions}
          disabledField={disabledField}
          selected={values.department}
          placeholder={department_t}
          type="user profile"
        />
        <InputFloatLabel
          inputId="email"
          inputName="email"
          inputType="text"
          placeholder={email}
          hasErrors={errors.email}
          inputValue={values.email}
          isTouched={touched.email}
          onChangeMethod={formik.handleChange}
          isRedStar
          disabled={disabledField}
        />
        {isHcp && (
          <div className={styles.row}>
            <div className={styles['form__password-container']}>
              <div>{password}</div>
              <div className={styles['form__password-value']}>******</div>
            </div>
            <Button
              buttonType="button"
              buttonName={change_password}
              buttonClass={styles['btn__change-password']}
              buttonMethod={(): void => openPage(ProfileUserSecondaryPages.CHANGE_PASSWORD)}
              colorStyle="black"
              disabledButton={disabledField}
            />
          </div>
        )}
        <InputPhoneNumberFloatLabel
          inputId="phoneNumber"
          isValid={isValid}
          hasErrors={errors.phone}
          value={values.phone}
          isTouched={touched.phone}
          onChange={(data: any): void => {
            formik.setFieldValue('phone', `+${data}`);
          }}
          placeholder={number_phone}
          isRedStar
          disabled={disabledField}
        />
        <SelectFloatLabel
          name="langId"
          inputId="langId"
          onChange={formik.setFieldValue}
          options={options}
          selected={values.langId}
          placeholder={lang}
          type="user profile"
          disabledField={disabledField}
        />

        {errorMessage && (
          <div className={styles.errorMessages}>
            <ShowErrorNotification errorMessage={errorMessage} />
          </div>
        )}
        <Button
          buttonType="submit"
          buttonName={save}
          buttonClass={styles['btn__submit--right-align']}
          disabledButton={!dirty || disabledField}
        />
      </div>
    </form>
  );
};
