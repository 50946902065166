import React, { useState, useEffect, ReactElement, Fragment, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import cx from 'classnames';

import { getCurrenLang } from '../../../redux/selector';
import { useQueryAllGoals, useQueryPatientGoals } from '../../../graphql/patients';
import style from './style.module.css';
import { Loading } from '../../../common/Loader';
import { AllGoal, PatientGoalsData, PatientGoalsViewProps } from '../types';
import {
  EightWeekGoalIcon,
  OverallGoalIcon,
  SixMonthGoalIcon,
  TwoWeekIcon,
} from '../../../theme/icons';
import PatientAnswer from '../PatientAnswer';

const PatientRehabGoals = ({ notAllowed, userId, isOverview }: any): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  // const no_patient_goals = t?.dashboard.hcp.profile_patient.no_patient_goals;
  const overall_goal = t?.dashboard.hcp.profile_patient.overall_goal;
  const two_week_goal = t?.dashboard.hcp.profile_patient.two_week_goal;
  const eight_week_goal = t?.dashboard.hcp.profile_patient.eight_week_goal;
  const six_month_goal = t?.dashboard.hcp.profile_patient.six_month_goal;
  const goals_set_private = t?.dashboard.hcp.profile_patient.goals_set_private;

  // Endpoints
  const {
    _getAllGoals,
    loadingAllGoals,
    errorAllGoals,
    allGoals,
    allGoalsData,
  } = useQueryAllGoals();
  const {
    _getPatientsGoals,
    loadingGoals,
    errorGoals,
    goals,
    patientGoalsData,
  } = useQueryPatientGoals();

  const goalPeriodConfig = {
    1: {
      icon: <OverallGoalIcon />,
      title: overall_goal,
      order: 1,
      goalTypeId: null,
      patientsAnswers: [],
    },
    2: {
      icon: <TwoWeekIcon />,
      title: two_week_goal,
      order: 2,
      goalTypeId: null,
      patientsAnswers: [],
    },
    3: {
      icon: <EightWeekGoalIcon />,
      title: eight_week_goal,
      order: 3,
      goalTypeId: null,
      patientsAnswers: [],
    },
    4: {
      icon: <SixMonthGoalIcon />,
      title: six_month_goal,
      order: 4,
      goalTypeId: null,
      patientsAnswers: [],
    },
  };

  const [patientGoalsView, setPatientGoalsView] = useState<any>(null);

  // Send request to the database
  useEffect(() => {
    if (!notAllowed) {
      _getAllGoals();
      _getPatientsGoals({
        variables: {
          patientId: userId,
        },
      });
    }
  }, [notAllowed, userId]);

  useEffect(() => {
    if (goals?.length && allGoals?.length) {
      const goalPeriodConfigCopy: any = { ...goalPeriodConfig };
      allGoals.map((goal: AllGoal) => {
        goalPeriodConfigCopy[goal.order].goalTypeId = goal.id;
        return null;
      });
      const goalPeriodConfigArr = Object.values(goalPeriodConfigCopy);
      const result: any = [];
      goals.map((patientGoalFromQuery: PatientGoalsData) => {
        goalPeriodConfigArr.map((item: any) => {
          if (item.goalTypeId === patientGoalFromQuery.goalTypeId) {
            goalPeriodConfigCopy[item.order].patientsAnswers.push(patientGoalFromQuery);
          }
          return null;
        });
        return null;
      });
      Object.values(goalPeriodConfigCopy).map((item: any) => {
        if (item.patientsAnswers.length) {
          result.push(item);
        }
        return null;
      });
      setPatientGoalsView(result);
    }
  }, [patientGoalsData, allGoalsData]);

  useEffect(() => {
    if (errorGoals) {
      toast.error(errorGoals);
    }
    if (errorAllGoals) {
      toast.error(errorAllGoals);
    }
  }, [errorGoals, errorAllGoals]);

  // JSX
  const loadingJSX = (loadingAllGoals || loadingGoals) && <Loading />;
  const noGoalData = useMemo(() => {
    if (patientGoalsData && !patientGoalsView && !loadingJSX) {
      if (isOverview) {
        return <div className={style['profile-card__no-goals-data']}>{goals_set_private}</div>;
      }
      return <div className={style.noData}>{goals_set_private}</div>;
    }
    return <></>;
  }, [patientGoalsData, patientGoalsView, loadingJSX]);

  return (
    <div className={style.goals__container}>
      {loadingJSX}
      {patientGoalsView &&
        patientGoalsView.map((item: PatientGoalsViewProps) => (
          <Fragment key={item.title}>
            <div
              key={item.title}
              className={cx({
                [style['goals__icon-container']]: true,
                [style['goals__icon-container--overview']]: isOverview,
              })}
            >
              <div className={style.goals__icon}>{item.icon}</div>
              <div className={style['goals__icon-title']}>{item.title}</div>
            </div>
            {item.patientsAnswers.map((answer: PatientGoalsData, i: number) => (
              <PatientAnswer
                key={`${answer.goalName}${String(i)}`}
                itemTitle={item.title}
                answer={answer}
                i={i}
                isOverview={isOverview}
              />
            ))}
          </Fragment>
        ))}

      {noGoalData}
    </div>
  );
};

export default PatientRehabGoals;
