import React, { ReactElement } from 'react';
import style from '../style.module.css';
import { CalculationDescriptionProps } from '../types';

export const CalculationDescription = ({
  name,
  content,
  date,
  t,
}: CalculationDescriptionProps): ReactElement => {
  const completed_t = t?.dashboard.hcp.profile_patient.completion_and_steps.completed;
  const calculation_t = t?.dashboard.hcp.profile_patient.questionnaires.calculation;
  const patient_responses = t?.dashboard.hcp.profile_patient.questionnaires.patient_responses;

  return (
    <>
      <div className={style.questionnaire__name}>{name}</div>
      {content && (
        <div className={style.questionnaire__calc}>
          {calculation_t}
          :
        </div>
      )}
      {content && (<div className={style['questionnaire__calc-descr']}>{content}</div>)}
      <div className={style['questionnaire__completed-cont']}>
        <span>
          {completed_t}
          :
        </span>
        &nbsp;
        <span>{date}</span>
      </div>
      <div className={style.questionnaire__responses}>
        {patient_responses}
        :
      </div>
    </>
  );
};
