import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import { useMutationRequest } from '../../hooks/feature/useMutationRequest';
import { UseCreateHcpDocument, UseQueryGetPatientDocument } from '../types';
import { UseDeleteHcpDocument, UseQueryGetPatientInformation } from '../patients/types';
import { config } from '../../utils/configs';

// Query
const getCurrentHcpDocuments = loader('./gql/queryGetCurrentHcpDocuments.graphql');
const getCurrentPatientDocumentsForHcp = loader(
  './gql/queryGetCurrentPatientDocumentsForHcp.graphql',
);

// Mutation
const removeDocumentPic = loader('./gql/mutationRemoveDocPic.graphql');
const removeDocumentVideo = loader('./gql/mutationRemoveDocVideo.graphql');
const createHcpDocument = loader('./gql/mutationCreateHcpDocument.graphql');
const deleteHcpDocument = loader('./gql/mutationDeleteHcpDocument.graphql');

// Delete file from AWS
export const useRemoveFileFromAws = () => {
  const [_removeFile, { data, error, loading }] = useMutation(removeDocumentPic);
  return {
    _removeFile,
    deleteFromAwsData: data && data.removeDocumentPic,
    deleteFromAwsError: error,
    deleteFromAwsLoading: loading,
  };
};

// Delete video file from AWS
export const useRemoveVideoFileFromAws = () => {
  const [_removeVideo, { data, error, loading }] = useMutation(removeDocumentVideo);
  return {
    _removeVideo,
    deleteVideoFromAwsData: data && data.removeDocumentVideo,
    deleteVideoFromAwsError: error,
    deleteVideoFromAwsLoading: loading,
  };
};

// Create new Patient information by HCP
export const useCreateHcpDocument = (): UseCreateHcpDocument => {
  const [_createHcpDocument, { data, errorText, loading }] = useMutationRequest<{
    createHcpDocument: boolean;
  }>(createHcpDocument, {});

  return {
    _createHcpDocument,
    createdData: data && data.createHcpDocument,
    createError: errorText,
    createLoading: loading,
  };
};

// Delete item from patient information from database
export const useDeleteHcpDocument = (): UseDeleteHcpDocument => {
  const [_deleteDocument, { data, errorText, loading }] = useMutationRequest<{
    deleteHcpDocument: boolean;
  }>(deleteHcpDocument, {});

  return {
    _deleteDocument,
    deletedData: data && data.deleteHcpDocument,
    deleteError: errorText,
    deleteLoading: loading,
  };
};

// get patient information from DB (Attachment from HCP)
export const useQueryGetPatientInformation = (): UseQueryGetPatientInformation => {
  const [_getPatientInfo, { loading, error, data }] = useLazyQuery(getCurrentHcpDocuments, {
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  });
  const response = data && data.getCurrentHcpDocuments;

  return {
    _getPatientInfo,
    loadingInfo: loading,
    errorInfo: error?.graphQLErrors?.[0]?.message || error,
    patientInfo: response,
  };
};

// get patient information from DB (Attachment from HCP)
export const useQueryGetPatientDocument = (): UseQueryGetPatientDocument => {
  const [_getPatientDoc, { loading, error, data }] = useLazyQuery(
    getCurrentPatientDocumentsForHcp,
    {
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      pollInterval: config.pollInterval,
    },
  );
  const response = data && data.getCurrentPatientDocumentsForHcp;

  return {
    _getPatientDoc,
    loadingDoc: loading,
    errorDoc: error?.graphQLErrors?.[0]?.message || error,
    patientDocuments: response,
    totalPages: response?.totalPages,
    totalItems: response?.totalItems,
    data,
  };
};

// Get link from uuId
// getDocumentVideoLink
// getDocumentThumbnailLink
// getDocumentPicLink
