import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import styles from '../../../styles.module.css';
import style from './style.module.css';
import { GetState, getCurrenLang, getHcpNameLoading } from '../../../../redux/selector';
import { BreadcrumbAnt } from '../../../../utils/routers/Breadcrumb';
import { Button, MainTitle } from '../../../../common';
import { path } from '../../../../utils';
import useCheckPermissions from '../../../../hooks/useCheckPermissions';
import { MEDIA_TYPE, PERMISSIONS } from '../../../../utils/enums';
import { AddPhotoOrangeIcon, AddVideoOrangeIcon } from '../../../../theme/icons';
import { ButtonWithContent } from '../../../../common/Button/Button';

const AddMediaPage = (): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const add_photo = t?.common.add_photo_title;
  const add_video = t?.common.add_video_title;
  const cancel_t = t?.common.cancel;
  const you_dont_have_permission = t?.common.you_dont_have_permission;
  const org_video_bank = t?.title.org_video_bank;
  const add_media = t?.hcp.org_video_bank.add_media;
  const descr_one = t?.hcp.org_video_bank?.add_media_descr_one;
  const descr_two = t?.hcp.org_video_bank?.add_media_descr_two;

  const history = useHistory();

  const isPermissionsAddVideosToTeamVideoBank = useCheckPermissions(
    PERMISSIONS.ADD_VIDEOS_TO_TEAM_VIDEOBANK_OTM,
  );
  const loadingPermission = useSelector<GetState>((state) => getHcpNameLoading(state));
  const isNoPermissions = !loadingPermission && !isPermissionsAddVideosToTeamVideoBank;

  // Redirect to Add Media page
  const redirectToPage = (type = ''): void => {
    let pathname: string = path.dashboardList;
    switch (type) {
      case MEDIA_TYPE.VIDEO:
        pathname = path.addVideo;
        break;
      case MEDIA_TYPE.PHOTO:
        pathname = path.addPhoto;
        break;
      default:
        pathname = path.organisationVideoBank;
    }
    history.push({
      pathname,
    });
  };

  // Breadcrumb path
  const routes = [
    {
      path: path.organisationVideoBank,
      breadcrumbName: org_video_bank,
    },
    {
      path: add_media,
      breadcrumbName: add_media,
    },
  ];

  return (
    <div className={styles.main__container}>
      <BreadcrumbAnt currentRoutes={routes} />
      <div className={cx([styles.main__wrapper])}>
        <MainTitle title={add_media} />

        {!isNoPermissions && (
          <div className={styles['left-section']}>
            <div className={styles['section-description']}>{descr_one}</div>
            <div className={styles['section-description']}>{descr_two}</div>
            <div className={style['add-media__btn-container']}>
              <ButtonWithContent
                buttonClass={style['add-media__btn']}
                buttonType="button"
                buttonName={add_video}
                buttonMethod={(): void => redirectToPage(MEDIA_TYPE.VIDEO)}
                icon={<AddVideoOrangeIcon />}
              />
              <ButtonWithContent
                buttonClass={style['add-media__btn']}
                buttonType="button"
                buttonName={add_photo}
                buttonMethod={(): void => redirectToPage(MEDIA_TYPE.PHOTO)}
                icon={<AddPhotoOrangeIcon />}
              />
            </div>
            <div className={style['add-media__btn-container--cancel']}>
              <Button
                buttonClass={style['add-media__btn']}
                buttonType="button"
                buttonName={cancel_t}
                buttonMethod={(): void => redirectToPage()}
              />
            </div>
          </div>
        )}
        {isNoPermissions ? (
          <div className={styles.nopermissions}>{you_dont_have_permission}</div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default AddMediaPage;
