import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';

import chartStyles from '../styles.module.css';
import styles from './styles.module.css';
import { CHART_TABS } from '../../../utils/enums';
import ExerciseCompletionChart from './ExerciseCompletionChart';
import IndividualExerciseChart from './IndividualExerciseChart';
import ReasonsChart from './ReasonsChart ';
import SkipStopChart from './SkipStopChart ';
import {
  getExerciseCompletionData,
  getExercisesPerData,
  getReasonsForNotComplExercData,
  getSkipStopData,
} from '../../../redux/selector';

const ExerciseCompletionContainer = (): ReactElement => {
  const exercSummaryData: any = useSelector(getExercisesPerData);
  const exercComplData: any = useSelector(getExerciseCompletionData);
  const reasNotComplData: any = useSelector(getReasonsForNotComplExercData);
  const skipStopExercData: any = useSelector(getSkipStopData);

  return (
    <div className={chartStyles.chart__flex}>
      <div className={styles['exerc-compl__left-section']}>
        <ExerciseCompletionChart
          exercComplData={exercComplData}
          isOverview
          type={CHART_TABS.exercise_completion}
        />
        <ReasonsChart
          reasonsNotComletionData={reasNotComplData}
          isOverview
          type={CHART_TABS.reasons}
        />
        <SkipStopChart
          skipStopExercData={skipStopExercData}
          isOverview
          type={CHART_TABS.skipStop}
        />
      </div>
      <div className={styles['exerc-compl__right-section']}>
        <IndividualExerciseChart isOverview exercSummaryData={exercSummaryData} />
      </div>
    </div>
  );
};

export default ExerciseCompletionContainer;
