import React, { ReactElement } from 'react';
import cx from 'classnames';

import styles from './styles.module.css';
import { Plus } from '../../theme/icons';

const AddNewItem = ({
  label,
  actionMethod,
  disable,
}: {
  label: string;
  actionMethod: any;
  disable?: boolean;
}): ReactElement => (
  <div
    className={cx({
      [styles.edit_icon]: true,
      [styles['edit_icon--disable']]: disable,
    })}
    onClick={actionMethod}
    role="presentation"
  >
    <div className={styles['edit_icon-plus']}>
      <Plus />
    </div>
    <span
      className={cx({
        [styles.edit_title]: true,
        [styles['edit_title--disable']]: disable,
      })}
    >
      {label}
    </span>
  </div>
);

export default AddNewItem;
