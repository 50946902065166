import React, { ReactElement, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import { Switch } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';

import style from './styles.module.css';
import { useChangeRemoveUnreadNotif } from '../../graphql/hcp';
import { getScheduleNumberOptions, getUnitOptions } from '../../utils/share/options';

import { getUnitScheduleText } from '../../utils/helper';
import {
  GetState,
  getCurrenLang,
  getEmailNotifSettingsData,
  getNotifToMeData,
} from '../../redux/selector';
import { setOpenSettingsData } from '../../redux/emailNotifications';
import { EMAIL_NOTIF_TYPE } from '../../utils/enums';
import { RemoveUnreadNotificationsSettings } from './SettingsForm';

const RemoveUnreadNotifications = ({ updateData }: any): ReactElement => {
  const t: any = useSelector<any>((state: GetState) => getCurrenLang(state));
  const on = t?.common.on;
  const off = t?.common.off;
  const settings_t = t?.dashboard.hcp.profile_patient.video_bank.settings;
  const inactive_t = t?.dashboard.hcp.profile_patient.completion_and_steps.inactive;
  const notifications_t = t?.header.notifications_title;
  const remove_unread_notif = t?.hcp.manage.remove_unread_notif;
  const remove_unread_notif_older = t?.hcp.manage.remove_unread_notif_older;

  // Endpoints
  const {
    _changeRemoveUnreadNotif,
    changeRemoveUnreadNotifLoading,
    changeRemoveUnreadNotifError,
    changeRemoveUnreadNotifData,
  } = useChangeRemoveUnreadNotif();

  // Redux
  const notifToMeData: any = useSelector((state: GetState) => getNotifToMeData(state));
  const openSettingsData: any = useSelector((state: GetState) => getEmailNotifSettingsData(state));
  const data = notifToMeData?.hcpNotifTimer;
  const isActive = openSettingsData?.type === EMAIL_NOTIF_TYPE.NOTIFICATION;

  // Variables
  const id = data?.id;
  const isEdit = !!id;
  const dispatch = useDispatch();
  const remove_unread_notif_older_norm = useMemo(() => {
    if (t && data) {
      const numberOptions = getScheduleNumberOptions();
      const unitOptions = getUnitOptions(t.dashboard.hcp.profile_patient.completion_and_steps);
      const values = {
        amount: data.amount ? numberOptions[data.amount - 1] : null,
        timePeriod: data.timePeriod ? unitOptions[data.timePeriod - 1] : null,
      };
      return data.timePeriod && data.amount
        ? getUnitScheduleText(t, remove_unread_notif_older, values)
        : '';
    }
    return '';
  }, [t, data]);

  // If update status Hcp Email Notification success then update list
  useEffect(() => {
    if (changeRemoveUnreadNotifData) {
      updateData();
    }
  }, [changeRemoveUnreadNotifData]);
  // If update status Hcp Email Notification error then show message
  useEffect(() => {
    if (changeRemoveUnreadNotifError) {
      toast.error(changeRemoveUnreadNotifError);
    }
  }, [changeRemoveUnreadNotifError]);

  const changeEmailNotifTypeStatus = (checked: boolean): void => {
    _changeRemoveUnreadNotif({ solution: checked });
  };

  const openSettings = (): void => {
    if (isActive && data) {
      dispatch(setOpenSettingsData(undefined));
    } else {
      dispatch(
        setOpenSettingsData({
          ...data,
          type: EMAIL_NOTIF_TYPE.NOTIFICATION,
          title: notifications_t,
        }),
      );
    }
  };

  return (
    <div className={style.row__wrapper}>
      <div className={style.row}>
        <div className={style['email-notif__title-container']}>
          <div className={style['email-notif__title']}>{notifications_t}</div>
          <div className={style['email-notif__description']}>{remove_unread_notif}</div>
          {!isEdit ? <div className={style['email-notif__description']}>{inactive_t}</div> : <></>}
        </div>

        <div className={style['email-notif__switch-wrapper']}>
          <div
            className={cx({
              [style['email-notif__settings-btn']]: true,
              [style['email-notif__settings-btn--active']]: isActive,
              // [style['email-notif__settings-btn--disable']]: !isPermissionCreateTeam,
            })}
            onClick={openSettings}
            aria-hidden
          >
            {settings_t}
          </div>
        </div>
      </div>

      {isEdit ? (
        <div className={style.row}>
          <div className={style['email-notif__title-container']}>
            <div className={style['email-notif__description']}>
              {remove_unread_notif_older_norm}
            </div>
          </div>

          <div className={style['email-notif__switch-wrapper']}>
            {isEdit && (
              <div className={style['email-notif__switch']}>
                <Switch
                  checkedChildren={<span>{on}</span>}
                  unCheckedChildren={<span>{off}</span>}
                  loading={changeRemoveUnreadNotifLoading}
                  checked={data?.isRemoveUnreadNotif}
                  onChange={(checked: boolean): void => changeEmailNotifTypeStatus(checked)}
                />
              </div>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}

      <div className={style['email-notif__settings-wrapper--sm']}>
        {/* Settings sm screen */}
        {isActive && (
          <RemoveUnreadNotificationsSettings data={openSettingsData} updateData={updateData} />
        )}
      </div>
    </div>
  );
};

export default RemoveUnreadNotifications;
