import { Options, PeriodDropDownOptions } from '../../utils/model';

// Type
const SELECT_PERIOD_REMOTE_MONITOR = 'SELECT_PERIOD_REMOTE_MONITOR';
const SELECT_PERIOD_OVERVIEW = 'SELECT_PERIOD_OVERVIEW';

// export interface TPeriodsOptionsType {
//   dateTo: string;
//   label: string;
//   periodFrom: string;
//   periodType: string;
//   value: string;
// }

// TS
interface SelectedDropdownRemMonitProp {
  type: typeof SELECT_PERIOD_REMOTE_MONITOR;
  payload: PeriodDropDownOptions;
}

interface OverviewSelectorType {
  type: typeof SELECT_PERIOD_OVERVIEW;
  payload: Options;
}

type ActionTypes = SelectedDropdownRemMonitProp | OverviewSelectorType;

// Action
export const setSelectedPeriodOverviewTab = (
  data: PeriodDropDownOptions,
): OverviewSelectorType => ({
  type: SELECT_PERIOD_OVERVIEW,
  payload: data,
});

export const setSelectedPeriodRemMonit = (
  data: PeriodDropDownOptions,
): SelectedDropdownRemMonitProp => ({
  type: SELECT_PERIOD_REMOTE_MONITOR,
  payload: data,
});

export interface SelectedPeriodType {
  overview: PeriodDropDownOptions | null;
  remoteMonitoring: PeriodDropDownOptions | null;
}

const init: SelectedPeriodType = {
  overview: null,
  remoteMonitoring: null,
};

// Reducer
export const dropdownReduser = (state = init, { type, payload }: ActionTypes) => {
  switch (type) {
    case SELECT_PERIOD_OVERVIEW:
      return {
        ...state,
        overview: payload,
      };
    case SELECT_PERIOD_REMOTE_MONITOR:
      return {
        ...state,
        remoteMonitoring: payload,
      };
    default:
      return state;
  }
};
