/* eslint-disable react/no-danger */
import React, { Fragment, ReactElement } from 'react';
import cx from 'classnames';
import moment from 'moment';
import { sanitize } from 'dompurify';

import { getPatientAnswers } from '../../../../../utils/helper_charts';
import style from './style.module.css';
import { QuestionnaireContentProps, Question } from './types';
import { RemoveQuestionnaire } from './common/RemoveQuestionnaire';
import { CalculationDescription } from './common/Calculation';
import { PROMS_NAME } from '../../../../../utils/enums';

export const PrweReport = ({
  questionnairesList,
  completedQuestionnaire,
  index,
  onDeleteItem,
  formatDate,
  t,
}: QuestionnaireContentProps): ReactElement => {
  const remove_questionnaire = t?.dashboard.hcp.profile_patient.questionnaires.remove_questionnaire;
  const score = t?.dashboard.hcp.profile_patient.questionnaires.score;
  const prwe_title = t?.dashboard.hcp.profile_patient.questionnaires.prwe_title;
  const pain = t?.dashboard.hcp.profile_patient.questionnaires.pain;
  const specific_activities = t?.dashboard.hcp.profile_patient.questionnaires.specific_activities;
  const usual_activities = t?.dashboard.hcp.profile_patient.questionnaires.usual_activities;
  const pain_subscale_t = t?.dashboard.hcp.profile_patient.questionnaires.pain_subscale;
  const functional_subscale_t = t?.dashboard.hcp.profile_patient.questionnaires.functional_subscale;
  const prwe_footer = t?.dashboard.hcp.profile_patient.questionnaires.prwe_footer;
  const total_score = t?.dashboard.hcp.profile_patient.questionnaires.total_score;
  const prwe_calc_descr_1 = t?.dashboard.hcp.profile_patient.questionnaires.prwe_calc_descr_1;
  const prwe_calc_descr_2 = t?.dashboard.hcp.profile_patient.questionnaires.prwe_calc_descr_2;

  const { questions: completedQuestions, completedDate, totalScore } = completedQuestionnaire;
  const patientAnswers: any = getPatientAnswers(completedQuestions);
  const mepsList = questionnairesList.filter((item: any) => item.name === PROMS_NAME.PRWE)[0];
  const { questions } = mepsList;

  const getSectionName = (i: number): string => {
    switch (i) {
      case 0:
        return `${pain}:`;
      case 5:
        return `${specific_activities}:`;
      case 11:
        return `${usual_activities}:`;
      default:
        return '';
    }
  };
  const answerConfig = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  let pain_subscale = 0;
  let functional_subscale = 0;

  // JSX
  const calc_description = (
    <>
      <p>{prwe_calc_descr_1}</p>
      <p>{prwe_calc_descr_2}</p>
    </>
  );

  return (
    <>
      <RemoveQuestionnaire
        onMethod={(): void => onDeleteItem(index)}
        title={remove_questionnaire}
      />
      <div className="questionnaire-to-PDF">
        <div className={style.prwe__container}>
          <CalculationDescription
            name={prwe_title}
            content={calc_description}
            date={completedDate ? moment(completedDate).format(formatDate) : ''}
            t={t}
          />

          {/* PAGE */}
          <div className={style.prwe__wrapper}>
            {questions.map((question: Question, ind: number) => {
              const sectionName = getSectionName(ind);
              return (
                <Fragment key={`prweQuestion${String(ind)}`}>
                  {/* Section */}
                  {sectionName && <div className={style['prwe__section-name']}>{sectionName}</div>}
                  {/* Question */}
                  <div className={style['prwe_question-container']}>
                    <div className={style.prwe_question}>
                      <div className={style.prwe_dot} />
                      <span dangerouslySetInnerHTML={{ __html: sanitize(question.name) }} />
                    </div>

                    {/* Answers */}
                    <div className={style['prwe_answers-container']}>
                      {answerConfig.map((answ: number, inx: number) => {
                        const isPatientChose = patientAnswers[question.id] === inx;
                        pain_subscale += ind < 5 && isPatientChose ? inx : 0;
                        functional_subscale += ind >= 5 && isPatientChose ? inx : 0;
                        return (
                          <div
                            key={`prwe_answer${String(inx)}`}
                            className={cx({
                              [style.prwe__answer]: true,
                              [style['prwe__answer--active']]: isPatientChose,
                            })}
                          >
                            {answ}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </Fragment>
              );
            })}
            <div className={style['prwe__section-name']}>{`${score}:`}</div>

            {/* Pain subscale */}
            <div
              className={cx({
                [style['prwe_question-container']]: true,
                [style['prwe__score--title']]: true,
              })}
            >
              <div className={style.prwe_question}>{`${pain_subscale_t}:`}</div>
              <div
                className={cx({
                  [style['prwe_answers-container']]: true,
                  [style['prwe__score--result']]: true,
                })}
              >
                {`${pain_subscale} / 50`}
              </div>
            </div>

            {/* Functional subscale (total divided by 2) */}
            <div
              className={cx({
                [style['prwe_question-container']]: true,
                [style['prwe__score--title']]: true,
              })}
            >
              <div className={style.prwe_question}>{`${functional_subscale_t}:`}</div>
              <div
                className={cx({
                  [style['prwe_answers-container']]: true,
                  [style['prwe__score--result']]: true,
                })}
              >
                {`${functional_subscale / 2} / 50`}
              </div>
            </div>

            {/* Total score */}
            <div
              className={cx({
                [style['prwe_question-container']]: true,
                [style['prwe__score--title']]: true,
              })}
            >
              <div className={style.prwe_question}>{`${total_score}:`}</div>
              <div
                className={cx({
                  [style['prwe_answers-container']]: true,
                  [style['prwe__score--result']]: true,
                })}
              >
                {`${totalScore} / 100`}
              </div>
            </div>
          </div>

          {/* Footer */}
          <div className={style.prwe_footer}>{prwe_footer}</div>
        </div>
      </div>
    </>
  );
};
