import React, { ReactElement } from 'react';

import style from './styles.module.css';
import { Button, Title } from '../../../common';
import { SearchForm } from '../../HcpList/search';
import { spinner } from '../../../common/Loader';
import { ListActiveHcp } from '../../HcpList/list';
import { CloseIcon } from '../../../theme/icons';

const ListHcpModalContent = ({
  t,
  _onclose,
  filter,
  setFilter,
  hcpListLoading,
  hcpList,
  selectedHcp,
  filterQuery,
  setFilterQuery,
  checkedHcpId,
  updateCompleted,
  totalLength,
  pageSize,
  currentPage,
  onChangePagination,
  onSubmit,
  onInvite,
  isAddHcp = true,
  isTooltip = false,
}: any): ReactElement => {
  const searchPlaceholder = t?.modal.search_placeholder;
  const add_hcp_modal = t?.modal.add_hcp;
  const add_patient = t?.modal.add_patient;
  const list_title = t?.modal.list_title;
  const list_title_patient = t?.modal.list_title_patient;
  const not_found = t?.modal.not_found;
  const did_you_invite = t?.modal.did_you_invite;
  const invite_hcp_btn = t?.common.invite_hcp;
  const submit = t?.common.submit;

  const titleModal = isAddHcp ? add_hcp_modal : add_patient;
  const listTitle = isAddHcp ? list_title : list_title_patient;

  const changeFilter = ({ target: { value } }: any): void => {
    setFilter(value);
    if (value.length >= 3) {
      setFilterQuery(value);
    } else setFilterQuery('');
  };

  return (
    <div className={style.modal_container}>
      <div className={style.close} onClick={_onclose} role="presentation">
        <CloseIcon />
      </div>
      <Title title={titleModal} />
      <SearchForm value={filter} onChangeFilter={changeFilter} placeholder={searchPlaceholder} />
      <span className={style.subtitle_list}>{listTitle}</span>
      {hcpListLoading && spinner}
      <div className={style.find_container}>
        {filterQuery && hcpList.length === 0 && !hcpListLoading && (
          <div className={style.notfound}>
            <div>{not_found}</div>
            {isAddHcp && <div>{did_you_invite}</div>}
          </div>
        )}
        {(!filterQuery || hcpList.length > 0) && (
          <ListActiveHcp
            selectedHcpId={selectedHcp}
            checkedHcpId={checkedHcpId}
            list={filterQuery ? hcpList : []}
            onUpdate={updateCompleted}
            loading={hcpListLoading}
            current={currentPage}
            total={filterQuery ? totalLength : 0}
            pageSize={pageSize}
            _onChange={onChangePagination}
            isTooltip={isTooltip}
          />
        )}
      </div>
      <div className={style.container_btn}>
        {hcpList && hcpList.length > 0 && (
          <Button
            buttonMethod={onSubmit}
            buttonType="button"
            buttonName={submit}
            disabledButton={checkedHcpId.length === 0}
            buttonClass={style.modal__btn}
          />
        )}
        {isAddHcp && (
          <Button
            buttonMethod={onInvite}
            buttonType="button"
            buttonName={invite_hcp_btn}
            colorStyle="green"
            buttonClass={style.modal__btn}
          />
        )}
      </div>
    </div>
  );
};

export default ListHcpModalContent;
