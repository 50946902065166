import React, { ReactElement } from 'react';
import cx from 'classnames';

import styles from '../styles.module.css';
import { FilterIcon } from '../../../theme/icons';
import { FilterJsxProps } from '../types';

const FilterJsx = ({ filtered }: FilterJsxProps): ReactElement => (
  <div
    className={cx({
      [styles.table__filter]: true,
      [styles['table__filter--active']]: filtered,
    })}
  >
    <FilterIcon />
  </div>
);

export default FilterJsx;
