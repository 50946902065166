/* eslint-disable jsx-a11y/media-has-caption */
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import axios from 'axios';
import FFMPEG from 'react-ffmpeg';
import { RecordRTCPromisesHandler } from 'recordrtc';
import moment from 'moment';
import cx from 'classnames';

import styles from './styles.module.css';
import pageStyles from '../../../../../pages/styles.module.css';
import { Button, Loading, MainTitle, Modal } from '../../../../../common';
import { getCurrenLang } from '../../../../../redux/selector';
import { Popup } from '../../../../../common/Popup';
import { InputFloatLabel, InputTypeFileAsText, RoundCheckbox } from '../../../../../common/Input';
import {
  defaultValue,
  delay,
  getCamAndMics,
  getExercisesCategories,
} from '../../../../../utils/helper';
import { validationExercise, validationFocusCheckboxes } from '../../../../../utils/validators';
import { EXERCISE_TYPE } from '../../../../../utils/enums';
import { ButtonWithContent, CloseBtn } from '../../../../../common/Button/Button';
import {
  AddVideoGreenIcon,
  CheckIcon,
  FlipIcon,
  MicIcon,
  MicMuteIcon,
  MuteIcon,
  StartIcon,
  WarningIcon,
} from '../../../../../theme/icons';
import { path } from '../../../../../utils';
import { DataVideoExercises } from '../../../VideoExercise/types';
import useGetFocuses from '../../../../../hooks/useGetFocuses';
import AddMediaSettingsForm from '../AddMediaSettingsForm';
import useCheckRole from '../../../../../hooks/useCheckRole';
import { config } from '../../../../../utils/configs';
import { useSaveHcpExercise } from '../../../../../graphql/videoBank';
import { timerStatus, useInterval } from '../../../../../hooks/useInterval';

const AddVideo = (): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const cancel = t?.common.cancel;
  const save = t?.common.save;
  const ok = t?.common.ok;
  const yes = t?.common.yes;
  const no = t?.common.no;
  const add_video = t?.common.add_video;
  const add_video_title = t?.common.add_video_title;
  const exercise_name = t?.dashboard.hcp.profile_patient.video_bank.exercise_name;
  const attachment = t?.dashboard.hcp.profile_patient.attachment;
  const max_video_duration = t?.validation.max_video_duration;
  const min_video_duration = t?.validation.min_video_duration;
  const video_length = t?.validation.video_length;
  const video_not_supported = t?.validation.video_not_supported;
  const less_than_60Mb = t?.validation.less_than_60Mb;
  const record_new_video = t?.dashboard.hcp.profile_patient.record_new_video;
  const video_not_uploaded = t?.dashboard.hcp.profile_patient.video_not_uploaded;
  const error_occurred = t?.dashboard.hcp.profile_patient.error_occurred;
  const access_to_device = t?.validation.access_to_device;
  const mute_text = t?.dashboard.hcp.profile_patient.video_bank.mute?.toLowerCase();
  const flip = t?.dashboard.hcp.profile_patient.video_bank.flip?.toLowerCase();
  const start_text = t?.dashboard.hcp.profile_patient.video_bank.start?.toLowerCase();
  const end_text = t?.dashboard.hcp.profile_patient.video_bank.end?.toLowerCase();
  const reset = t?.common?.reset?.toLowerCase();
  const do_you_want_audio_to_be_recorded =
    t?.dashboard.hcp.profile_patient.video_bank.do_you_want_audio_to_be_recorded;
  const noise_suppression = t?.dashboard.hcp.profile_patient.video_bank.noise_suppression;
  const echo_cancellation = t?.dashboard.hcp.profile_patient.video_bank.echo_cancellation;
  const remove_sound = t?.dashboard.hcp.profile_patient.video_bank.remove_sound;
  const createExercise = t?.notifications.created_exercise;

  const descr_one = t?.hcp.org_video_bank?.add_media_descr_one;
  const descr_two = t?.hcp.org_video_bank?.add_media_descr_two;
  const please_select_file = t?.hcp.org_video_bank?.please_select_file;
  const video_still_uploading = t?.hcp.org_video_bank?.video_still_uploading;
  const please_visit_later = t?.hcp.org_video_bank?.please_visit_later;
  const cannot_play = t?.hcp.org_video_bank?.cannot_play;
  // Endpoints
  // Save exercise by HCP to Organisation Video Bank
  const {
    _saveHcpExercise,
    savedOrgVideoExercise,
    errorSaveExercise,
    loadingSaveExercise,
  } = useSaveHcpExercise();

  // Local state
  const [srcForView, setSrcForView] = useState('');
  const [file, setFile] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [isExeption, setIsExeption] = useState(false);
  const [isUploadingModalOpen, setIsUploadingModalOpen] = useState(false);
  const [isFileErrorModalOpen, setIsFileErrorModalOpen] = useState(false);
  const [fileInputKey, setFileInputKey] = useState<number>(Date.now());
  const [isSuccessSaved, setSuccessSaved] = useState(false);

  const [stream, setStream] = useState<any>(null);
  const [camList, setCamList] = useState([]);
  const [front, setFront] = useState(false);
  const [startRec, setstartRec] = useState(false);
  const [mute, setMute] = useState(true);
  const [noiseSuppression, setNoiseSuppression] = useState(true);
  const [echoCancellation, setEchoCancellation] = useState(true);
  const [duration, setDuration] = useState(0);
  const [status, setStatus] = useState(timerStatus.STOPPED);
  const [isDeviceDeny, setIsDeviceDeny] = useState<any>('');
  const [isMicAlert, setMicAlert] = useState(false);
  const [isAlowedAlert, setAlowedAlert] = useState(true);
  const refVideo: any = useRef(null);
  const recorderRef: any = useRef(null);

  const {
    focusType1Arr,
    focusType2Arr,
    exercisesCategories,
    exercisesProcedureFocus,
    loadingFocuses,
  } = useGetFocuses();
  const { token } = useCheckRole();
  const history = useHistory();
  const { uri, bucketName, videoServiceUrl } = config;
  const uriWithoutGraph = uri?.split('/api')[0];

  const redirectToVideoBank = (): void => {
    if (stream) {
      stream.getTracks().forEach((x: any) => x.stop());
    }
    // history.push({ pathname: redirectPath, search });
    history.push({ pathname: path.organisationVideoBank });
  };

  // Save video file to AWS
  const uploadVideo = async (exerciseId: number): Promise<void> => {
    const startTime = Date.now();
    setLoading(() => true);
    const formData = new FormData();
    formData.append('file', file);
    const urlSecondPart = `video/uploadFromHcp/${exerciseId}`;
    let videoServUrl = `${uriWithoutGraph}/video_api/${urlSecondPart}`;
    switch (bucketName) {
      case 'otm-stage':
      case 'onthemend-en':
        videoServUrl = `${videoServiceUrl}${urlSecondPart}`;
        break;
      default:
        break;
    }
    axios
      .post(videoServUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        setSuccessSaved(() => true);
      })
      .catch((e) => {
        const diff = Date.now() - startTime;
        const nginxTimeout = 60 * 1000; // in milliseconds
        if (diff >= nginxTimeout) {
          setIsUploadingModalOpen(() => true);
        } else {
          toast.error(video_not_uploaded);
        }
      })
      .finally(() => setLoading(() => false));
  };

  /* After the exercise data is saved to DB,
    start uploading video to AWS */
  useEffect(() => {
    if (savedOrgVideoExercise) {
      uploadVideo(Number(savedOrgVideoExercise));
    }
  }, [savedOrgVideoExercise]);

  const initialValues: any = {
    name: '',
    exerciseName: '',
    id: null,
    type: EXERCISE_TYPE.VIDEO,
    exerciseArchived: null,
    exerciseCategories: null,
    exerciseComment: null,
    exerciseId: null,
    exerciseReps: null,
    exerciseRestDays: null,
    exerciseSets: null,
    exerciseTime: null,
    exerciseTimesPerDay: null,
    exerciseVideoUploaded: true,
    exerciseThumbnailUploaded: true,
    status: 'Inactive',
    thumbnailName: '',
    videoName: null,
    dim: 'reps',
    reps: '',
    sets: '',
    timesDay: '',
    restDaysWeek: '',
    minutes: defaultValue('0'),
    seconds: defaultValue('0'),
    comments: '',
    videobankId: null,
    focus1: false,
    focus2: false,
    focus3: false,
    focus4: false,
    procedure1: false,
    procedure2: false,
    procedure3: false,
    procedure4: false,
    procedure5: false,
    procedure6: false,
    procedure7: false,
    file: '',
    fileDuration: '',
    isRecordVideo: false,
  };

  const formik = useFormik({
    initialValues,
    validate: (values) => validationFocusCheckboxes(values, t),
    validationSchema: () => validationExercise(t),
    onSubmit: (values) => {
      if (file) {
        const request: DataVideoExercises = {
          isVideoOwnerHcp: true,
          exerciseCategoriesId: getExercisesCategories(values, exercisesCategories, 'focus'),
          exerciseProcedureTypesId: getExercisesCategories(
            values,
            exercisesProcedureFocus,
            'procedure',
          ),
          exerciseData: {
            id: null,
            name: values.name,
            thumbnailName: values.thumbnailName,
            videoName: values.videoName,
            videobankId: values.videobankId,
            sets: Number(values.sets) || null,
            reps: values.dim === 'time' ? null : Number(values.reps) || null,
            time:
              values.dim === 'time'
                ? Number(values.seconds.value) + Number(values.minutes.value) * 60
                : null,
            timesPerDay: Number(values.timesDay),
            restDays: Number(values.restDaysWeek) || null,
            comment: values.comments,
            videoUploaded: values.exerciseVideoUploaded,
            thumbnailUploaded: values.exerciseThumbnailUploaded,
          },
        };

        if (!savedOrgVideoExercise) {
          request.isVideoOwnerHcp = true;
          _saveHcpExercise(request);
          return;
        }
        if (savedOrgVideoExercise) {
          uploadVideo(Number(savedOrgVideoExercise));
        }
      }
    },
  });

  const { values, touched, errors } = formik;

  // Record video logik
  const cancelRecord = (): void => {
    if (stream) {
      stream.getTracks().forEach((x: any) => x.stop());
    }
  };

  // Delete attached video
  const deleteVideo = (): void => {
    setSrcForView('');
    setIsExeption(() => false);
    setFile(null);
    formik.setErrors({});
    formik.setFieldValue('fileDuration', 0);
    formik.setFieldValue('fileNameOrigin', '');
    formik.setFieldValue('isRecordVideo', false);
    cancelRecord();
    setIsDeviceDeny(() => false);
  };

  // Calculating video recording duration
  useInterval(
    async () => {
      if (timerStatus.STARTED) {
        setDuration(duration + 1000);
      }
    },
    // passing null stops the interval
    status === timerStatus.STARTED ? 1000 : null,
  );

  useEffect(() => {
    if (refVideo) {
      if (!refVideo.current) {
        return;
      }
      refVideo.current.srcObject = stream;
    }
  }, [stream, refVideo]);

  useEffect(() => {
    if (!srcForView && values.isRecordVideo) {
      (async (): Promise<void> => {
        try {
          const mediaTrackConstraints: MediaTrackConstraints = {
            // By setting noiseSuppression to true,
            // the browser will attempt to reduce background noise from the captured audio.
            // Keep in mind that the effectiveness of this feature may vary depending on
            // the user's device and browser support.
            noiseSuppression,
            echoCancellation,
          };
          // get aprove use devices
          const audio = mute ? false : mediaTrackConstraints;
          // const strm = await navigator.mediaDevices.getUserMedia({ audio: !mute, video: true });
          const strm = await navigator.mediaDevices.getUserMedia({ audio, video: true });
          // stop all track if exist
          strm.getTracks().forEach((x: any) => x.stop());
          // get device list
          if (!camList) {
            await getCamAndMics(setCamList, setLoading);
          }
          const cameraStream = await navigator.mediaDevices.getUserMedia({
            audio,
            // audio: !mute,
            video: {
              facingMode: front ? 'user' : 'environment',
            },
          });
          setStream(cameraStream);
        } catch (error) {
          setIsDeviceDeny(error);
        }
      })();
    }
  }, [front, srcForView, mute, noiseSuppression, echoCancellation, values.isRecordVideo]);

  const startRecord = async (): Promise<void> => {
    formik.setErrors({});
    setSrcForView('');
    setstartRec(() => true);
    if (stream) {
      stream.getTracks().forEach((x: any) => x.stop());
    }
    await delay(0);
    const mediaTrackConstraints: MediaTrackConstraints = {
      // By setting noiseSuppression to true,
      // the browser will attempt to reduce background noise from the captured audio.
      // Keep in mind that the effectiveness of this feature may vary depending on
      // the user's device and browser support.
      noiseSuppression,
      echoCancellation,
    };
    const audio = mute ? false : mediaTrackConstraints;
    const cameraStream = await navigator.mediaDevices.getUserMedia({
      video: {
        facingMode: front ? 'user' : 'environment',
      },
      // audio: !mute,
      audio,
    });
    setStream(cameraStream);
    recorderRef.current = new RecordRTCPromisesHandler(cameraStream, {
      type: 'video',
      // mimeType: 'video/webm;codecs=h264',
      // mimeType: 'video/mp4',
      mimeType: 'video/webm;codecs=vp8',
      timeSlice: 1000,
      disableLogs: true,
    });
    setDuration(0);
    setStatus(timerStatus.STARTED);
    recorderRef.current.startRecording();
  };

  const start = (): void => {
    if (mute && isAlowedAlert) {
      setMicAlert(() => true);
    } else {
      startRecord();
    }
  };

  const stopRecord = async (): Promise<void> => {
    setLoading(() => true);
    setStatus(timerStatus.STOPPED);
    await recorderRef.current.stopRecording();
    const result: any = await recorderRef.current.getBlob();
    const url = URL.createObjectURL(result);
    const currentName = 'Record';
    let defaultType = 'video/webm';
    let defaultName = `${currentName}.webm`;

    if (result.type.includes('mp4') || result.type.includes('MP4')) {
      defaultType = 'video/mp4';
      defaultName = `${currentName}.mp4`;
    }
    if (result.type.includes('mov') || result.type.includes('MOV')) {
      defaultType = 'video/quicktime';
      defaultName = `${currentName}.mov`;
    }
    if (result.type.includes('hevc') || result.type.includes('HEVC')) {
      defaultType = 'video/quicktime';
      defaultName = `${currentName}.hevc`;
    }

    const blobToFile = new File([result], defaultName, { type: defaultType });
    setFile(blobToFile);
    setSrcForView(url);
    formik.setFieldValue('fileDuration', duration);
    formik.setFieldValue('fileNameOrigin', defaultName);
    setSrcForView(url);
    if (stream) {
      stream.getTracks().forEach((x: any) => x.stop());
    }
    await recorderRef.current.reset();
    setstartRec(() => false);
    if (duration < 5000) {
      formik.setTouched({ file: true });
    }
    recorderRef.current = null;
    setLoading(() => false);
  };

  // Remove sound from recorded video
  const removeSound = async (): Promise<void> => {
    if (srcForView) {
      setLoading(() => true);
      try {
        await FFMPEG.process(file, '-c:v copy -an', (e: any) => {
          const muteFile = e?.result;
          setFile(muteFile);
          setMute(() => true);
          const url = URL.createObjectURL(muteFile);
          setSrcForView(url);
          setLoading(false);
        });
      } catch (error) {
        setLoading(false);
        toast.error(error_occurred);
      }
    }
  };

  // Auto stop video recording
  useEffect(() => {
    if (duration >= 29000) {
      setDuration(() => 30000);
      stopRecord();
    }
  }, [duration]);

  const replaceCam = async (): Promise<void> => {
    setFront(!front);
  };

  const onMute = async (): Promise<void> => {
    setMute(!mute);
  };

  const resetRecordedVideo = async (): Promise<void> => {
    setDuration(0);
    formik.setErrors({});
    formik.setTouched({});
    setSrcForView('');
    formik.setFieldValue('fileNameOrigin', '');
  };

  const closeAlert = (): void => {
    setMicAlert(() => false);
    setAlowedAlert(() => false);
  };

  const turnOnMic = (): void => {
    onMute();
    setMicAlert(() => false);
  };

  // Noise reduction handler
  const onNoiseReduction = (e: any, noiseType: string): void => {
    const currentVal = e.target.checked;
    if (noiseType === 'noise-suppression') {
      setNoiseSuppression(currentVal);
    }
    if (noiseType === 'echo-cancellation') {
      setEchoCancellation(currentVal);
    }
  };
  // End Record video logik

  // Check the uploaded video file
  const onCheckIncomingFile = async (e: any): Promise<void> => {
    formik.setErrors({});
    setLoading(() => true);
    try {
      const f = e.target.files[0];
      if (f) {
        // const type = f.type || 'video/quicktime';
        const fileName = f.name.toLowerCase();
        const prew = window.URL.createObjectURL(f);
        const el = document.createElement('VIDEO') as HTMLVideoElement;
        el.src = prew;
        await delay(200);
        const incomingVideoDuration = el.duration;
        if (f.name.includes('hevc') || f.name.includes('HEVC') || f.name.includes('MOV')) {
          if (incomingVideoDuration) {
            if (incomingVideoDuration > 0 && incomingVideoDuration < 5) {
              formik.setErrors({ file: min_video_duration });
              window.URL.revokeObjectURL(prew);
            }
            if (incomingVideoDuration > 5 && incomingVideoDuration < 31) {
              setIsExeption(() => true);
              setFile(f);
              formik.setFieldValue('fileNameOrigin', fileName);
            }
            if (incomingVideoDuration > 30) {
              formik.setErrors({ file: max_video_duration });
              window.URL.revokeObjectURL(prew);
            }
          } else if (f.size > 60_000_000) {
            formik.setErrors({ file: less_than_60Mb });
            window.URL.revokeObjectURL(prew);
          } else {
            setFile(f);
            formik.setFieldValue('fileNameOrigin', fileName);
            setIsExeption(() => true);
          }
        } else {
          if (incomingVideoDuration > 0 && incomingVideoDuration < 5) {
            formik.setErrors({ file: min_video_duration });
            window.URL.revokeObjectURL(prew);
          }
          if (incomingVideoDuration > 5 && incomingVideoDuration < 31) {
            setSrcForView(prew);
            setFile(f);
            formik.setFieldValue('fileNameOrigin', fileName);
          }
          if (incomingVideoDuration > 30) {
            formik.setErrors({ file: max_video_duration });
            window.URL.revokeObjectURL(prew);
          }
          try {
            await el.play();
            el.pause();
          } catch (error) {
            setIsFileErrorModalOpen(() => true);
          }
        }
      }
      setLoading(() => false);
    } catch (error) {
      setLoading(() => false);
      setIsFileErrorModalOpen(() => true);
      console.error('Upload file error:', error);
    }
  };

  const onCloseUploadingModalOpen = (): void => {
    setIsUploadingModalOpen(() => false);
    setIsFileErrorModalOpen(() => false);
    setFileInputKey(Date.now());
  };

  const isDisableRemoveSound = !srcForView || loading || mute;

  // JSX
  const loadingJSX = (loading || loadingFocuses || loadingSaveExercise) && <Loading />;

  const btnsJsx = (
    <div className={styles['add-video__btns-container']}>
      <Button
        buttonType="button"
        buttonName={cancel}
        buttonClass={styles['add-video__btn']}
        buttonMethod={deleteVideo}
        disabledButton={!!loadingJSX}
      />
      <Button
        buttonClass={styles['add-video__btn']}
        buttonType="submit"
        buttonMethod={(): void => formik.handleSubmit()}
        buttonName={save}
        disabledButton={!!loadingJSX}
      />
    </div>
  );

  return (
    <>
      {loadingJSX}
      <div className={pageStyles['left-section']}>
        <MainTitle title={add_video_title} />
        <div className={pageStyles['section-description']}>{descr_one}</div>
        <div className={pageStyles['section-description']}>{descr_two}</div>

        {/* Form */}
        <form>
          <InputFloatLabel
            inputId="AddVideo_name"
            inputName="name"
            inputType="text"
            placeholder={exercise_name}
            hasErrors={errors.name}
            inputValue={values.name}
            isTouched={touched.name}
            onChangeMethod={formik.handleChange}
            disabled={!!loadingJSX}
            isRedStar
          />

          {!file && !values.isRecordVideo && (
            <>
              <InputTypeFileAsText
                id="upload-file"
                inputName="file"
                labelName={add_video}
                hasErrors={false}
                onChangeMethod={onCheckIncomingFile}
                disabled={!!loadingJSX}
                fileInputKey={fileInputKey}
                accept=".mp4, .mov, .hevc"
                isTouched
              />
              <div className={styles['add-video__video-length']}>{video_length}</div>
            </>
          )}

          {/* Settings */}
          {(file || values.isRecordVideo) && (
            <AddMediaSettingsForm
              formik={formik}
              focusType1Arr={focusType1Arr}
              focusType2Arr={focusType2Arr}
              exercisesCategories={exercisesCategories}
              exercisesProcedureFocus={exercisesProcedureFocus}
              errorSaveExercise={errorSaveExercise}
              loadingJSX={loadingJSX}
            />
          )}

          {errors?.file && <div className={styles.error}>{errors.file}</div>}

          <div className={styles['add-video__btns-container']}>
            <ButtonWithContent
              buttonClass={styles['add-video__btn']}
              buttonType="button"
              buttonName={record_new_video}
              buttonMethod={(): void => formik.setFieldValue('isRecordVideo', true)}
              icon={<AddVideoGreenIcon />}
              colorStyle="green"
              active={values.isRecordVideo}
              disabledButton={!!srcForView || !!loadingJSX}
            />
            <Button
              buttonClass={styles['add-video__btn']}
              buttonType="button"
              buttonName={cancel}
              buttonMethod={redirectToVideoBank}
              disabledButton={!!loadingJSX}
            />
          </div>
        </form>
      </div>

      {/* Attach file */}
      {!values.isRecordVideo && (srcForView || isExeption) && (
        <div className={pageStyles['right-section']}>
          <MainTitle title={attachment} />

          <div>
            {srcForView && (
              <div className={styles['add-video__video-player-wrapper']}>
                {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                <video controls className={styles['add-video__video-player']}>
                  <source src={srcForView} type="video/mov" />
                  <source src={srcForView} type="video/hevc" />
                  <source src={srcForView} type="video/mp4" />
                </video>
              </div>
            )}
            {isExeption && (
              <>
                <div className={styles['add-video__video-file-name']}>{values.fileNameOrigin}</div>
                <div className={styles['add-video__file-cannot-play']}>{cannot_play}</div>
              </>
            )}
            {!file && touched && <div className={styles.error}>{please_select_file}</div>}
            {btnsJsx}
          </div>
        </div>
      )}

      {/* Record video */}
      {values.isRecordVideo && (
        <div className={pageStyles['right-section']}>
          <MainTitle title={record_new_video} />
          <div className={styles['add-video__video-player-wrapper']}>
            <video
              ref={refVideo}
              // autoPlay={!blob}
              autoPlay={!srcForView}
              playsInline
              controls={!!srcForView}
              // controls={!!blob}
              muted
              className={cx({
                [styles.form__recorder]: true,
                [styles.hide]: srcForView,
                // [styles.hide]: blob,
              })}
            />
            <video
              src={srcForView}
              // src={blob}
              controls
              className={cx({
                [styles.form__recorder]: true,
                [styles.hide]: !srcForView,
              })}
            />
            <div
              className={cx({
                [styles['form__recorder-btn-cont']]: true,
                [styles['form__recorder-btn-cont--reset']]: !startRec && srcForView,
              })}
            >
              {!startRec && !srcForView && (
                <div className={styles['form__recorder-btn-item']}>
                  <div
                    className={styles['form__recorder-btn']}
                    onClick={onMute}
                    role="presentation"
                  >
                    {mute ? <img src={MicMuteIcon} alt="Mic" /> : <img src={MicIcon} alt="Mic" />}
                  </div>
                  <div className={styles['form__recorder-btn-name']}>{mute_text}</div>
                </div>
              )}

              {!startRec && !srcForView && (
                <div className={styles['form__recorder-btn-item']}>
                  <div
                    className={styles['form__recorder-btn']}
                    onClick={replaceCam}
                    role="presentation"
                  >
                    <img src={FlipIcon} alt="Flip" />
                  </div>
                  <div className={styles['form__recorder-btn-name']}>{flip}</div>
                </div>
              )}

              {!startRec && srcForView && (
                <div className={styles['form__recorder-btn-item']}>
                  <div
                    className={styles['form__recorder-btn']}
                    onClick={resetRecordedVideo}
                    role="presentation"
                  >
                    &#8634;
                  </div>
                  <div className={styles['form__recorder-btn-name']}>{reset}</div>
                </div>
              )}

              {!startRec && !srcForView && (
                <div
                  className={cx({
                    [styles['form__recorder-btn-item']]: true,
                    [styles.hide]: startRec,
                  })}
                >
                  <div className={styles['form__recorder-btn']} onClick={start} role="presentation">
                    <img src={StartIcon} className={styles['start-icon']} alt="Start" />
                  </div>
                  <div className={styles['form__recorder-btn-name']}>{start_text}</div>
                </div>
              )}
              <div
                className={cx({
                  [styles['form__recorder-btn-item']]: true,
                  [styles.hide]: !startRec,
                })}
              >
                <div
                  className={cx({
                    [styles['form__recorder-btn']]: true,
                    [styles['form__recorder-btn-stop']]: true,
                  })}
                  onClick={stopRecord}
                  role="presentation"
                >
                  X
                </div>
                <div className={styles['form__recorder-btn-name']}>{end_text}</div>
              </div>
            </div>
            <div className={styles['form__recorder-timer-cont']}>
              <div className={styles['form__recorder-timer-big-circle']}>
                <div className={styles['form__recorder-timer-sm-circle']} />
              </div>
              <div>{moment(duration).format('mm:ss')}</div>
            </div>
          </div>

          {/* Noise reduction */}
          <div className={styles['form__noise-reduction-container']}>
            <div className={styles['form__noise-reduction-item']}>
              <RoundCheckbox
                htmlId="noiseSuppression"
                name="noiseSuppression"
                checked={noiseSuppression}
                onChangeMethod={(e: any): void => onNoiseReduction(e, 'noise-suppression')}
                isTouched={false}
                hasErrors={false}
                disabled={mute}
                label={noise_suppression}
              />
              <div className={styles['form__noise-reduction--description']}>{}</div>
            </div>
            <div className={styles['form__noise-reduction-item']}>
              <RoundCheckbox
                htmlId="echoCancellation"
                name="echoCancellation"
                checked={echoCancellation}
                onChangeMethod={(e: any): void => onNoiseReduction(e, 'echo-cancellation')}
                isTouched={false}
                hasErrors={false}
                disabled={mute}
                label={echo_cancellation}
              />
            </div>
            <div
              onClick={isDisableRemoveSound ? (): null => null : removeSound}
              aria-hidden
              className={cx({
                [styles['form__noise-reduction-item']]: true,
                [styles['form__btn--remove-sound']]: true,
                [styles['form__btn--remove-sound-disable']]: isDisableRemoveSound,
              })}
            >
              <MuteIcon />
              <div className={styles['form__btn--remove-sound-label']}>{remove_sound}</div>
            </div>
          </div>

          {(errors?.file || errors?.fileDuration) && touched?.file && (
            <div className={styles['form__recorder-error-cont']}>
              {errors?.file || errors?.fileDuration}
            </div>
          )}

          {btnsJsx}
        </div>
      )}

      {isFileErrorModalOpen && (
        <Popup
          title={video_not_supported}
          content=""
          buttonName={ok}
          onClosePopup={onCloseUploadingModalOpen}
        />
      )}

      {isUploadingModalOpen && (
        <Popup
          title={video_still_uploading}
          content={please_visit_later}
          buttonName={ok}
          onClosePopup={redirectToVideoBank}
        />
      )}

      {/* Success save folder */}
      {isSuccessSaved && (
        <Modal onClose={redirectToVideoBank}>
          <CloseBtn close={redirectToVideoBank} />
          <div>
            <div className={styles.icon}>
              <CheckIcon />
            </div>
            <div className={styles.content}>{`${values.name} ${createExercise}`}</div>
            <Button
              buttonClass={styles['exercise-folder-settings__btn-ok']}
              buttonType="button"
              buttonName={ok}
              buttonMethod={redirectToVideoBank}
            />
          </div>
        </Modal>
      )}

      {isDeviceDeny && (
        <Modal onClose={deleteVideo}>
          <CloseBtn close={deleteVideo} />
          <img
            className={styles['device-deny-modal__warn-img']}
            src={WarningIcon}
            alt="Warning icon"
          />
          <div className={styles['device-deny-modal__content-wrapper']}>{access_to_device}</div>
          <Button
            buttonType="button"
            buttonName={cancel}
            buttonClass={styles.modal__btn}
            buttonMethod={deleteVideo}
            disabledButton={loading}
          />
        </Modal>
      )}

      {isMicAlert && (
        <Modal onClose={closeAlert}>
          <CloseBtn close={closeAlert} />
          <img
            src={WarningIcon}
            alt="Warning icon"
            className={styles['device-deny-modal__warn-img']}
          />
          <div className={styles['device-deny-modal__content-wrapper']}>
            {do_you_want_audio_to_be_recorded}
          </div>
          <div className={styles['alert-modal__btns-cont']}>
            <Button
              buttonType="button"
              buttonName={no}
              buttonClass={styles.modal__btn}
              buttonMethod={closeAlert}
            />
            <Button
              buttonType="button"
              buttonName={yes}
              buttonClass={styles.modal__btn}
              buttonMethod={turnOnMic}
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default AddVideo;
