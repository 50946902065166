import { DataRewardType } from '../../components/Rewards/types';
import { deepCopyObj } from '../../utils/helper';
import { minNumberOfRewardPeriod, rewardEmptyPeriod } from '../../utils/variables';
import { LoadingType } from '../types';

// Type
const FILL_REWARD_LIST = 'FILL_REWARD_LIST';
const SET_REWARD_LIST_LOADING = 'SET_REWARD_LIST_LOADING';
const SET_REWARD_LIST_ERROR = 'SET_REWARD_LIST_ERROR';
const FILL_REWARD_SETTING_DATA = 'FILL_REWARD_SETTING_DATA';

// TS
export interface RewardsStoreType {
  rewardList: DataRewardType[] | null;
  rewardListLoading: boolean;
  rewardListError: any;
  openRewardSettingData: DataRewardType | null;
}
interface ErrorType {
  type: string;
  payload: any;
}
interface RewardListType {
  type: string;
  payload: DataRewardType[] | null;
}
interface RewardSettingType {
  type: string;
  payload: DataRewardType | null;
}
type ActionTypes = ErrorType | LoadingType | RewardListType | RewardSettingType;

// Action
export const setRewardList = (payload: DataRewardType[] | null): RewardListType => ({
  type: FILL_REWARD_LIST,
  payload,
});
export const setRewardListLoading = (payload: boolean): LoadingType => ({
  type: SET_REWARD_LIST_LOADING,
  payload,
});
export const setRewardListError = (payload: any): ErrorType => ({
  type: SET_REWARD_LIST_ERROR,
  payload,
});
export const setOpenRewardSettingData = (payload: DataRewardType | null): RewardSettingType => ({
  type: FILL_REWARD_SETTING_DATA,
  payload,
});

// Initial comon STATE
const init: RewardsStoreType = {
  rewardList: null,
  rewardListLoading: false,
  rewardListError: undefined,
  openRewardSettingData: null,
};
// Reducer
export const rewardsReducer = (state = init, { type, payload }: ActionTypes): RewardsStoreType => {
  switch (type) {
    case FILL_REWARD_LIST: {
      if (payload) {
        const copyData = deepCopyObj(payload);
        // If the database stores fewer periods than the minimum, then add empty periods
        if (payload.length < minNumberOfRewardPeriod) {
          for (let i = payload.length; i < minNumberOfRewardPeriod; i += 1) {
            copyData.push(deepCopyObj(rewardEmptyPeriod));
          }
          return {
            ...state,
            rewardList: copyData,
          };
        }
        return {
          ...state,
          rewardList: copyData,
        };
      }
      return {
        ...state,
        rewardList: payload,
      };
    }

    case SET_REWARD_LIST_LOADING:
      return {
        ...state,
        rewardListLoading: payload,
      };
    case SET_REWARD_LIST_ERROR:
      return {
        ...state,
        rewardListError: payload,
      };
    case FILL_REWARD_SETTING_DATA:
      return {
        ...state,
        openRewardSettingData: payload,
      };
    default:
      return state;
  }
};
