import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Pagination, Tooltip } from 'antd';
import cx from 'classnames';
import { toast } from 'react-toastify';

import styles from './styles.module.css';
import { Button, Loading } from '../../common';
import { getCurrenLang } from '../../redux/selector';
import { initialPage } from '../../utils/helper';
import { PAGE_SIZE_OPTIONS_FOR_VIDEO_EXERCISE } from '../../utils/variables';
import { path } from '../../utils';
import OrgPatientInfoTableHeader from './components/TableHeader';
import { TSortdirectionProps } from '../../utils/model';
import MediaForPatient from './components/MediaForPatient';
import useCheckRole from '../../hooks/useCheckRole';
import {
  useGetAllPatientInformations,
  useGetAllPatientInformationsMatch,
} from '../../graphql/patients';
import useCheckPermissions from '../../hooks/useCheckPermissions';
import { PERMISSIONS } from '../../utils/enums';

const PatientInformation = ({ patientId }: any): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const add_patient_information = t?.dashboard.hcp.profile_patient.add_patient_information;
  const no_data = t?.common.no_data;
  const you_dont_have_permission = t?.common.you_dont_have_permission;
  const patient_info_descr = t?.dashboard.hcp.profile_patient.patient_info_descr;
  const history = useHistory();
  const { isAdmin } = useCheckRole();
  const isPermissionEditProfile = useCheckPermissions(PERMISSIONS.EDIT_PATIENT_PROFILE_INFO_OTM);

  const {
    _getAllPatientInfo,
    allPatientInfo,
    loadingAllPatientInfo,
    errorAllPatientInfo,
    allPatientInfoData,
    totalElements,
  } = useGetAllPatientInformations();

  // Get search result
  const {
    _getAllPatientInfoMatch,
    totalElementsMatch,
    loadingAllPatientInfoMatch,
  } = useGetAllPatientInformationsMatch();

  // Local state
  const [mediaList, setMediaList] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState<number>(initialPage);
  const [pageSize, setPageSize] = useState<number>(15);
  const [totalItems, setTotalItems] = useState<number>(0);

  const [sortBy, setSortby] = useState<string>('name');
  const [sortDirectionName, setSortDirectionName] = useState<string>('');
  const [isSearch, setIsSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [hideSearchMenu, setHideSearchMenu] = useState(false);

  const location = useLocation();

  // Get list of patient info
  useEffect(() => {
    setCurrentPage(initialPage);
    const listProps: TSortdirectionProps = {
      page: 1,
      itemsPerPage: pageSize,
    };
    if (sortDirectionName) {
      listProps.sortBy = sortBy;
      listProps.sortDirection = sortDirectionName;
    }
    _getAllPatientInfo({
      patientId,
      listProps,
      searchProps: {
        searchField: 'name',
        searchValue: searchQuery,
      },
    });
  }, []);

  // After receiving the list of Media from the database, display them on the UI
  useEffect(() => {
    if (!loadingAllPatientInfo) {
      setMediaList(allPatientInfo);
      setTotalItems(totalElements);
    }
  }, [allPatientInfoData]);

  // If error get all Patient media show message
  useEffect(() => {
    if (errorAllPatientInfo) {
      toast.error(errorAllPatientInfo);
    }
  }, [errorAllPatientInfo]);

  /* * search for matches in the database * */
  const [searchMatches, setSearchMatches] = useState(null);
  const [searchInputValue, setSearchInputValue] = useState('');

  // Find search matches in DB
  const findMatches = (searchVal: string): void => {
    if (!searchVal) {
      setSearchMatches(null);
      setSearchInputValue('');
      return;
    }
    setSearchInputValue(searchVal);
    _getAllPatientInfoMatch({
      patientId,
      listProps: {
        page: 1,
        itemsPerPage: pageSize,
      },
      searchProps: {
        searchField: 'name',
        searchValue: searchVal,
      },
    });
  };

  // Show the result from the database in the search menu
  useEffect(() => {
    if (!searchInputValue || !hideSearchMenu) return;
    if (!loadingAllPatientInfoMatch) {
      setSearchMatches(totalElementsMatch);
    }
  }, [totalElementsMatch]);

  // Get search exercise name
  const handleSearch = (val: string): void => {
    setSearchQuery(val);
    setSearchMatches(null);
    setHideSearchMenu(!hideSearchMenu);
    if (val) {
      setCurrentPage(1);
      const listProps: TSortdirectionProps = {
        page: 1,
        itemsPerPage: pageSize,
      };
      if (sortDirectionName) {
        listProps.sortBy = sortBy;
        listProps.sortDirection = sortDirectionName;
      }
      _getAllPatientInfo({
        patientId,
        listProps,
        searchProps: {
          searchField: 'name',
          searchValue: val,
        },
      });
      setIsSearch(() => true);
    }
  };

  // Reset search exercise name
  const handleReset = (): void => {
    setSearchQuery('');
    setSearchMatches(null);
    setHideSearchMenu(!hideSearchMenu);
    if (searchQuery) {
      setCurrentPage(1);
      const listProps: TSortdirectionProps = {
        page: 1,
        itemsPerPage: pageSize,
      };
      if (sortDirectionName) {
        listProps.sortBy = sortBy;
        listProps.sortDirection = sortDirectionName;
      }

      _getAllPatientInfo({
        patientId,
        listProps,
        searchProps: {
          searchField: 'name',
          searchValue: '',
        },
      });
      setIsSearch(() => false);
    }
  };

  // Sorting
  // Sort exercise list by Column Name
  const sortByColumnName = (name: string, sortdirection: string): void => {
    setSortby(name);
    setSortDirectionName(sortdirection);
    const listProps: TSortdirectionProps = {
      page: currentPage,
      itemsPerPage: pageSize,
    };
    if (sortdirection) {
      listProps.sortBy = name;
      listProps.sortDirection = sortdirection;
    }

    _getAllPatientInfo({
      patientId,
      listProps,
      searchProps: {
        searchField: 'name',
        searchValue: searchQuery,
      },
    });
  };

  // Exercise List Update
  const updateList = (): void => {
    const listProps: TSortdirectionProps = {
      page: currentPage,
      itemsPerPage: pageSize,
    };
    if (sortDirectionName) {
      listProps.sortBy = sortBy;
      listProps.sortDirection = sortDirectionName;
    }

    _getAllPatientInfo({
      patientId,
      listProps,
      searchProps: {
        searchField: 'name',
        searchValue: searchQuery,
      },
    });
  };

  // Go to the next page
  const onChangePagination = (page: any, itemsPerPage: any): void => {
    setPageSize(itemsPerPage);
    setCurrentPage(page);
    const listProps: TSortdirectionProps = {
      page,
      itemsPerPage,
    };
    if (sortDirectionName) {
      listProps.sortBy = sortBy;
      listProps.sortDirection = sortDirectionName;
    }
    _getAllPatientInfo({
      patientId,
      listProps,
      searchProps: {
        searchField: 'name',
        searchValue: searchQuery,
      },
    });
  };

  // Redirect to Upload media file page
  const openAddPatientInfoPage = (): void => {
    if (!isAdmin) {
      const pathname = path.patient_information_add_info;
      history.push({
        pathname,
        search: location.search,
      });
    }
  };

  // JSX
  const loadingJSX = (loadingAllPatientInfo || loadingAllPatientInfoMatch) && <Loading />;
  const noData = !mediaList?.length && !loadingJSX && (
    <div className={styles.noData}>{no_data}</div>
  );
  const addPIBtnJsx = (
    <Button
      buttonType="button"
      buttonName={add_patient_information}
      buttonMethod={openAddPatientInfoPage}
      buttonClass={styles['patient-info__btn']}
      disabledButton={!isPermissionEditProfile || isAdmin}
    />
  );

  return (
    <div className={styles['patient-info__container']}>
      {loadingJSX}
      {/* Description */}
      <div
        className={cx([
          styles['patient-info__description-container'],
          styles['patient-info__wrapper--pd'],
        ])}
      >
        <div className={styles['patient-info__description']}>{patient_info_descr}</div>
        <Tooltip title={isPermissionEditProfile ? '' : you_dont_have_permission}>
          <div className={styles['patient-info__btn--bg']}>{addPIBtnJsx}</div>
        </Tooltip>
      </div>

      {/* Header */}
      <OrgPatientInfoTableHeader
        sortByColumnName={sortByColumnName}
        sortBy={sortBy}
        handleSearch={handleSearch}
        handleReset={handleReset}
        searchMatches={searchMatches}
        findMatches={findMatches}
        hideSearchMenu={hideSearchMenu}
        isSearch={isSearch}
        setHideSearchMenu={setHideSearchMenu}
        setSearchMatches={setSearchMatches}
      />

      {/* Organisation video exercises */}
      {noData}
      {!!mediaList?.length &&
        mediaList.map((videoData: any) => {
          const keyObj = `media${videoData.id}`;
          return (
            <MediaForPatient
              key={keyObj}
              data={videoData}
              updateVideoList={updateList}
              isPermissionEditProfile={isPermissionEditProfile}
            />
          );
        })}

      {!noData && mediaList?.length > 0 && (
        <div className="wrapper_pagination">
          <Pagination
            size="small"
            current={currentPage}
            pageSize={pageSize}
            total={totalItems}
            onChange={onChangePagination}
            pageSizeOptions={PAGE_SIZE_OPTIONS_FOR_VIDEO_EXERCISE}
            showSizeChanger
          />
        </div>
      )}
      <Tooltip title={isPermissionEditProfile ? '' : you_dont_have_permission}>
        <div className={styles['patient-info__btn--sm']}>{addPIBtnJsx}</div>
      </Tooltip>
    </div>
  );
};

export default PatientInformation;
