import React, { ReactElement } from 'react';
import { useFormik } from 'formik';

import styles from './styles.module.css';
import { Button } from '../../common';
import { validationAddProfession } from '../../utils/validators';
import { Item } from '../../pages/Hcp/ProfileUser/types';
import { LANG } from '../../utils/enums';
import { InputFloatLabelWithDescription } from '../../common/Input';

export const AddNewProfessionForm = ({
  lang,
  onSave,
  createLoading,
  createError,
  onCloseModal,
  texts,
  t,
}: any): ReactElement => {
  const cancel = t?.common.cancel;
  const save = t?.common.save;

  const { subTitle, description, placeholder } = texts;

  // Get short name lang code
  const getLangCode = (langCode: string): string => {
    const startInd = langCode.indexOf('(');
    const endInd = langCode.indexOf(')');
    let code = '';
    if (startInd === -1 || endInd === -1) {
      code = langCode;
      return code;
    }
    code = langCode.slice(startInd + 1, endInd);
    return code;
  };

  const codeIdConfig: any = [];

  // UK language always first
  const normalizeLang = () => {
    if (lang[0].code === LANG.EN_GB) {
      return lang;
    }
    const firstElem: any = [];
    const secondPart: any = [];
    lang.map((item: Item, i: number) => {
      if (item.code !== LANG.EN_GB) {
        secondPart.push(lang[i]);
      } else {
        firstElem.push(lang[i]);
      }
      return null;
    });
    return [...firstElem, ...secondPart];
  };

  const langNormalize = normalizeLang();

  // Initial form state
  const getInit = (): any => {
    const init: any = {};
    langNormalize.map((item: Item) => {
      const fieldName = getLangCode(item.description);
      init[fieldName] = '';
      codeIdConfig.push({ shortCountryName: fieldName, id: item.id });
      return null;
    });
    return init;
  };

  const initialValues = getInit();

  const formik = useFormik({
    initialValues,
    validationSchema: () => validationAddProfession(t, initialValues),
    onSubmit: (values) => onSave(values, codeIdConfig),
  });

  return (
    <form className={styles['modal__manage-form']} onSubmit={formik.handleSubmit}>
      <div className={styles['modal__manage-org--label']}>{subTitle}</div>
      <div className={styles['modal__manage-org--description']}>{description}</div>
      {langNormalize.map((lngvg: Item) => {
        const shortLangCode = getLangCode(lngvg.description);
        return (
          <div key={lngvg.description} className={styles['modal__manage-org--input-cont']}>
            <InputFloatLabelWithDescription
              inputName={shortLangCode}
              inputType="text"
              hasErrors={formik.errors[shortLangCode]}
              inputValue={formik.values[shortLangCode]}
              isTouched={formik.touched[shortLangCode]}
              onChecngeMethod={formik.handleChange}
              placeholderText={placeholder}
              disabled={createLoading}
              description={shortLangCode}
              labelName=""
              fieldId={lngvg.description}
            />
          </div>
        );
      })}
      <div className={styles['modal__manage-org-err-cont']}>
        {createError && <>{createError}</>}
      </div>
      <div className={styles['modal__manage-org-btn-cont']}>
        <Button
          buttonClass={styles['modal__manage-org-btn']}
          buttonType="button"
          buttonName={cancel}
          buttonMethod={onCloseModal}
          disabledButton={createLoading}
        />
        <Button
          buttonClass={styles['modal__manage-org-btn']}
          buttonType="submit"
          buttonName={save}
          disabledButton={createLoading || !formik.dirty}
        />
      </div>
    </form>
  );
};
