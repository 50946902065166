import React, { ReactElement, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  Tooltip,
  LabelList,
} from 'recharts';
import moment from 'moment';

import {
  GetState,
  getAllPatientProms,
  getCurrenLang,
  getCurrentFormatDate,
  getHcpName,
} from '../../../redux/selector';
import style from '../styles.module.css';
import {
  MobilePortretScreenSize,
  chartTitleKeyQuestionnaries,
  getCompletedText,
  getDataForGraph,
  maxEq5d5l,
  maxVasHealth,
  minEq5d5l,
  minVasHealth,
} from '../../../utils/helper_charts';
import { TooltipRecharts } from '../../../common/TooltipRecharts';
import { PromsChartProps, TQuestionary } from '../types';
import { statusQuestionnaries } from '../../../utils/enums';
import { setEqLegendValue } from '../../../redux/questionnaires';

export const Eq5D5L = ({ nameGraph, chartId, loading }: PromsChartProps): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const not_completed = t?.dashboard.hcp.profile_patient.questionnaires.not_completed;
  const score = t?.dashboard.hcp.profile_patient.questionnaires.questionnaire_score;
  const health = t?.dashboard.hcp.profile_patient.questionnaires.health_Level;
  const date_completed = t?.dashboard.hcp.profile_patient.questionnaires.date_completed;
  const surgery_date = t?.dashboard.hcp.profile_patient.completion_and_steps.surgery_day;
  const no_data = t?.common.no_data;
  const contact_otm = t?.common.contact_otm;

  const dispatch = useDispatch();
  const patientPromsData: any = useSelector<any>((state) => getAllPatientProms(state));
  const windowClientWidth = useSelector((state: any) => state.common.screen.innerWidth);
  const formatsDate = useSelector((state: GetState) => getCurrentFormatDate(state));
  const allowedProms: any = useSelector((state: GetState) => getHcpName(state));
  const isAllowedProms = allowedProms.allowedPromsId.includes(chartId);
  const myContainer: any = useRef(null);
  const marginChart = {
    top: 20,
    // left: -15, //-35,
    left: -35, // windowClientWidth >= 1000 ? -15 : -35,
    right: -30,
    bottom: 100,
  };

  const eqLabels = [
    { color: '#44C1D8', yAxisId: 'left', name: score, value: 0 },
    { color: '#FF6464', yAxisId: 'right', name: health, value: 0 },
  ];

  const dataToEQ_5D_5L = useMemo(() => {
    const maxLengthDataToChart = 12;
    const data = getDataForGraph(patientPromsData, chartId);
    const dataLength = data?.length;
    if (dataLength > maxLengthDataToChart) {
      const result = data.slice(dataLength - maxLengthDataToChart);
      return result;
    }
    return data;
  }, [patientPromsData, chartId]);

  const isMobileLandscapeScreen = windowClientWidth > MobilePortretScreenSize;

  const initDate: any =
    dataToEQ_5D_5L &&
    dataToEQ_5D_5L.map((item: TQuestionary) => {
      const eqData: any = {
        chartTitle: item.chartTitle,
        [score]: '',
        [health]: '',
        display: item.completedDate
          ? moment(item.completedDate).format(formatsDate.momentFormat)
          : '',
        markerStart: item.joinDateAtChart,
        visibility: item.status,
      };

      if (item.chartTitleKey === chartTitleKeyQuestionnaries.surgeryDate) {
        eqData[score] = surgery_date;
        eqData[health] = surgery_date;
        return { ...eqData };
      }

      if (item.status === statusQuestionnaries.COMPLETED) {
        if (item.totalScore) {
          eqData[score] = item.totalScore;
        } else {
          eqData[score] = 0;
        }
        if (item.healthLevel) {
          eqData[health] = item.healthLevel;
        } else {
          eqData[health] = 0;
        }
        return { ...eqData };
      }
      if (item.status === statusQuestionnaries.SKIPPED) {
        eqData[score] = not_completed;
        eqData[health] = not_completed;
        return { ...eqData };
      }
      return { ...eqData };
    });

  const renderCustomizedLabel = (props: any): string | JSX.Element => {
    const { x, value } = props;
    // const height = type === PATIENT_PROFILE_TABS.PATIENT_OVERVIEW ? 200 : 290;
    const height =
      myContainer?.current?.state?.containerHeight - (marginChart.bottom + marginChart.top + 30);
    switch (value) {
      case surgery_date:
        return (
          <foreignObject x={x - 14} y={20} width={22} height={height}>
            {/* DASHED LINE ---- */}
            <div
              style={{
                writingMode: 'vertical-rl',
                color: '#E2007A',
                height: '100%',
                width: '50%',
                borderRight: '1px dashed #E2007A',
              }}
            />
          </foreignObject>
        );
      case not_completed:
        return (
          <foreignObject x={x - 12} y={20} width={22} height={height}>
            <div
              style={{
                writingMode: 'vertical-rl',
                transform: 'rotate(180deg)',
                fontSize: '14px',
                color: '#9BA1AE',
                paddingTop: '5px',
                height: '100%',
              }}
            >
              {not_completed}
            </div>
          </foreignObject>
        );

      default:
        return '';
    }
  };

  const renderCustomizedLabel2 = (props: any): ReactElement => {
    const { x, y, width, value, markerStart, display, visibility } = props;

    return (
      <foreignObject
        x={x - 26}
        // y={type === PATIENT_PROFILE_TABS.PATIENT_OVERVIEW ? 225 : 310}
        y={
          myContainer?.current?.state?.containerHeight - (marginChart.top + marginChart.bottom + 5)
        }
        width={47}
        height={100}
      >
        <div
          style={{
            writingMode: 'vertical-rl',
            transform: 'rotate(180deg)',
            fontSize: '14px',
            color: '#9BA1AE',
            height: '100%',
            lineHeight: '14px',
            margin: '0 auto',
          }}
        >
          {value}
          <br />
          {!markerStart &&
            isMobileLandscapeScreen &&
            getCompletedText(visibility, date_completed, not_completed)}
          {!markerStart && isMobileLandscapeScreen && <br />}
          {display}
        </div>
      </foreignObject>
    );
  };

  // Custom Tooltip
  const renderTooltip = (payload: any): JSX.Element => {
    const pay = payload.payload;
    if (windowClientWidth > 1000) {
      return <TooltipRecharts payload={pay} type="" t={t} />;
    }
    if (payload?.active) {
      dispatch(setEqLegendValue(pay));
    }
    return <></>;
  };

  // JSX
  const noData: any = !loading && initDate?.length === 0 && (
    <div className={style.proms__nodata}>{isAllowedProms ? no_data : contact_otm}</div>
  );

  return (
    <div className={style['proms-chart__container']}>
      {noData}

      <ResponsiveContainer
        width="100%"
        height="100%"
        // height={type === PATIENT_PROFILE_TABS.PATIENT_OVERVIEW ? 260 : 440}
        ref={myContainer}
      >
        <BarChart data={initDate} margin={marginChart}>
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey="name"
            axisLine={false}
            tickLine={false}
            // @ts-ignore
            style={{
              fontSize: '14px',
            }}
          />
          <YAxis
            yAxisId="left"
            orientation="left"
            stroke="#44C1D8"
            domain={[minEq5d5l, maxEq5d5l]}
            axisLine={false}
            tickLine={false}
            // @ts-ignore
            style={{
              fontSize: '14px',
            }}
          />
          <YAxis
            yAxisId="right"
            orientation="right"
            stroke="#FF6464"
            domain={[minVasHealth, maxVasHealth]}
            axisLine={false}
            tickLine={false}
            // @ts-ignore
            style={{
              fontSize: '14px',
            }}
          />
          {!noData && (
            <Tooltip
              allowEscapeViewBox={{ x: false, y: false }}
              offset={windowClientWidth > 1000 ? 0 : 500}
              content={renderTooltip}
            />
          )}
          {/* {!isOverView && windowClientWidth > 1000 && (
            <Legend
              verticalAlign="middle"
              content={<LegendRecharts type={''} t={t} procedureKey="" />}
              wrapperStyle={chartLegendStyles}
            />
          )} */}

          {eqLabels.map((label, index) => (
            <Bar
              key={String(index)}
              barSize={15}
              dataKey={label.name}
              fill={label.color}
              yAxisId={label.yAxisId}
              label={index % 2 ? renderCustomizedLabel : false}
              isAnimationActive={false}
            >
              {index % 2 && (
                <LabelList
                  dataKey="chartTitle"
                  position="bottom"
                  content={renderCustomizedLabel2}
                />
              )}
            </Bar>
          ))}
        </BarChart>
      </ResponsiveContainer>
      <div className={style['proms-chart__label--yAxis']} style={{ color: '#44C1D8' }}>
        {score}
      </div>
      <div className={style['proms-chart__label--yAxis-secondary']} style={{ color: '#FF6464' }}>
        {health}
      </div>
    </div>
  );
};
