import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import pageStyles from '../../styles.module.css';
import style from './style.module.css';
import { GetState, getCurrenLang, getUserLang } from '../../../redux/selector';
import { BreadcrumbAnt } from '../../../utils/routers/Breadcrumb';
import { Loading, MainTitle } from '../../../common';
import { AddNewProfessionForm } from '../../../components/ManageProfessions/AddNewProfessionForm';
import { executeScroll, initialPage, initialSizePage } from '../../../utils/helper';
import { CreateProfessionData } from '../../../graphql/types';
import { useQueryLangWithValue } from '../../../graphql/lang';
import {
  useCreateDepartment,
  useDeleteDepartment,
  useGetDepartmentsList,
} from '../../../graphql/departments';
import SearchClient from '../../../components/Search/SearchClient';
import useDebounce from '../../../hooks/useDebounce';
import AddNewItem from '../../../components/ManageProfessions/AddNewItemJsx';
import { WarnNotifModal } from '../../../common/NotificationModal';
import ListItems from '../../../components/ManageProfessions/ListItems';

const ManageDepartmentsPage = (): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const cancel_t = t?.common.cancel;
  const placeholder = t?.invite_patient.please_enter_min_3_characters;
  const delete_t = t?.common.delete;
  const no_data = t?.common.no_data;
  const this_action_cannot_be_undone = t?.notifications.action_cannot_be_undone;
  const are_you_sure_you_want_to_delete = t?.notifications.are_you_sure_you_want_to_delete;
  const manage_departments = t?.menu.manage_departments;
  const departments_list = t?.common.departments_list;
  const add_new_department = t?.title.add_new_department;
  const department_t = t?.dashboard.admin.table.hcp.department;
  const enter_department_name = t?.title.enter_department_name;
  const enter_department = t?.title.enter_department;

  const texts = {
    subTitle: department_t,
    description: enter_department_name,
    placeholder: enter_department,
  };

  const userLang: any = useSelector<GetState>((state) => getUserLang(state));
  const initCandidat = { id: 0, type: '' };
  const sectionRef: any = useRef(null);

  // Local state
  const [pageSize, setPageSize] = useState<number>(initialSizePage);
  const [currentPage, setCurrentPage] = useState<number>(initialPage);
  const [isModalOpen, setModal] = useState<boolean>(false);
  const [isDelete, setDelete] = useState<boolean>(false);
  const [departments, setDepartments] = useState([]);
  const [totalItemsState, setTotalItemsState] = useState(0);
  const [candidat, setCandidat] = useState(initCandidat);
  const [filter, setFilter] = useState<string>('');
  const [filterQuery, setFilterQuery] = useState<any>(null);

  // Queris and mutations
  const {
    _getDepartmentsList,
    data,
    deparmentsList,
    totalItems,
    error,
    loading,
  } = useGetDepartmentsList();

  const {
    _createDepartment,
    createdepartmentError,
    createdepartmentLoading,
    departmentCreated,
  } = useCreateDepartment();

  const {
    _deleteDepartment,
    departmentDeleted,
    deleteDepartmentError,
    deleteDepartmentLoading,
  } = useDeleteDepartment();

  const { _getLang, lang, error: errorLang, loading: loadingLang } = useQueryLangWithValue();

  // Create new profession
  const onSave = (departmentData: any, config: CreateProfessionData[]): void => {
    const request = config.map((item: any) => ({
      langId: item.id,
      name: departmentData[item.shortCountryName]
        ? departmentData[item.shortCountryName]
        : departmentData.UK,
    }));
    _createDepartment(request);
  };

  // Get professions list from DB
  const getList = (page: number, itemsPerPage: number): void => {
    _getDepartmentsList({
      variables: {
        listProps: {
          page,
          itemsPerPage,
        },
        searchProps: filterQuery || [
          {
            searchField: 'type',
            searchValue: '',
          },
        ],
      },
    });
  };

  // Scroll to elem
  useEffect(() => {
    if (isModalOpen) {
      executeScroll(sectionRef);
    }
  }, [isModalOpen]);

  useEffect(() => {
    getList(initialPage, pageSize);
    setCurrentPage(initialPage);
    _getLang({
      variables: {
        langCode: userLang,
      },
    });
  }, []);

  useEffect(() => {
    if (deparmentsList && !loading) {
      setDepartments(deparmentsList);
      setTotalItemsState(totalItems);
    }
  }, [data]);

  useEffect(() => {
    if (departmentCreated) {
      setModal(() => false);
      getList(currentPage, pageSize);
    }
  }, [departmentCreated]);

  // Get profession list after search
  useEffect(() => {
    getList(initialPage, pageSize);
    setCurrentPage(initialPage);
  }, [filterQuery]);

  const debounceValue = useDebounce(filter, 300);

  // Get search result from DB after delay
  useEffect(() => {
    if (debounceValue.length >= 2) {
      setFilterQuery([{ searchField: 'type', searchValue: debounceValue }]);
    } else {
      setFilterQuery(null);
    }
  }, [debounceValue]);

  // Search patient or HCP
  const changeFilter = ({ target: { value } }: any): void => {
    setFilter(value);
  };

  // Show error message from getDepartmentsList
  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  useEffect(() => {
    if (departmentDeleted) {
      getList(currentPage, pageSize);
    }
    setDelete(() => false);
    setCandidat(() => initCandidat);
  }, [departmentDeleted]);

  // Show error message from delete Profession
  useEffect(() => {
    if (deleteDepartmentError) {
      toast.error(deleteDepartmentError);
    }
  }, [deleteDepartmentError]);

  // Pagination
  const onChangePagination = (page: number, size: number | undefined): void => {
    let paginPageSize = pageSize;
    let paginPage = page;
    if (size && size !== pageSize) {
      setPageSize(size);
      paginPageSize = size;
      paginPage = initialPage;
    }
    getList(paginPage, paginPageSize);
    setCurrentPage(paginPage);
  };

  const onOpenModal = (): void => {
    setModal(() => true);
  };
  const onCloseModal = (): void => {
    setModal(() => false);
  };

  // Delete department from DB
  const deleteDepartment = (departmentId: number): void => {
    _deleteDepartment(departmentId);
  };

  const loadingJSX = (deleteDepartmentLoading || createdepartmentLoading || loading) && <Loading />;
  // Breadcrumb path
  const routes = [
    {
      path: manage_departments,
      breadcrumbName: manage_departments,
    },
  ];
  const deleteContentJsx = (
    <div>
      <div className={style['delete-content']}>
        {`${are_you_sure_you_want_to_delete} ${candidat.type}?`}
      </div>
      <div className={style['delete-content']}>{this_action_cannot_be_undone}</div>
    </div>
  );

  return (
    <div className={pageStyles.main__container}>
      {loadingJSX}
      <BreadcrumbAnt currentRoutes={routes} />
      <div className={pageStyles.main__wrapper}>
        <div className={pageStyles['flex-container']}>
          <div className={pageStyles['left-section']}>
            <MainTitle title={manage_departments} />
            {/* Search */}
            <div className={style['search-container']}>
              <SearchClient
                inputId="patientsSearch"
                name="filter"
                placeholder={placeholder}
                inputValue={filter}
                changeFilter={changeFilter}
              />
            </div>

            {/* Add new Item */}
            <AddNewItem
              label={add_new_department}
              actionMethod={onOpenModal}
              disable={isModalOpen}
            />

            {/* List */}
            <ListItems
              label={departments_list}
              srcList={departments}
              setCandidat={setCandidat}
              setDelete={setDelete}
              currentPage={currentPage}
              pageSize={pageSize}
              totalItemsState={totalItemsState}
              onChangePagination={onChangePagination}
              no_data={no_data}
            />
          </div>

          {/* Add new Departament */}
          {isModalOpen ? (
            <div className={pageStyles['right-section']} ref={sectionRef}>
              <MainTitle title={add_new_department} />
              {!errorLang && !loadingLang && (
                <AddNewProfessionForm
                  lang={lang}
                  onSave={onSave}
                  createLoading={createdepartmentLoading}
                  createError={createdepartmentError}
                  onCloseModal={onCloseModal}
                  texts={texts}
                  t={t}
                />
              )}
              {errorLang && <div className={style['modal__manage-dep-title']}>{errorLang}</div>}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      {isDelete && (
        <WarnNotifModal
          onClose={(): void => setDelete(() => false)}
          content={deleteContentJsx}
          cancelBtnName={cancel_t}
          actionBtnName={delete_t}
          actionMethod={(): void => deleteDepartment(candidat.id)}
        />
      )}
    </div>
  );
};

export default ManageDepartmentsPage;
