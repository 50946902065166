/* eslint-disable indent */
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Tooltip } from 'antd';
import { toast } from 'react-toastify';
import * as html2pdf from 'html2pdf.js';
import moment from 'moment';
import axios from 'axios';

import styles from './styles.module.css';
import { Loading, MainTitle } from '../../../common';
import {
  GetState,
  getCurrenLang,
  getCurrentFormatDate,
  getPatientProfile,
} from '../../../redux/selector';
import { EXPORT_PDF_TYPE, PERMISSIONS } from '../../../utils/enums';
import {
  useChangePatientAllowArchiveVideos,
  useEmailExercisePlanToPatient,
  useQueryActiveVideoExercises,
} from '../../../graphql/videoBank';
import { DownloadIcon, MailIcon, SelectDownIcon } from '../../../theme/icons';
import useCheckRole from '../../../hooks/useCheckRole';
import useCheckPermissions from '../../../hooks/useCheckPermissions';
import {
  SuccessNotifModal,
  WarnNotifModal,
  WarnNotifModalOneButton,
} from '../../../common/NotificationModal';
import { ShowArchiveWarnType } from './types';
import { useQueryPatientProfile } from '../../../graphql/patients';
import PrescribedExercises from './PrescribedExercises';
import PrescribeExercises from './PrescribeExercises';
import PdfExercisesPlanPreview from './PdfExercisesPlanPreview';
import { detectBrowser } from '../../../utils/helper';
import SendPdfWarnModal from './SendPdfWarnModal';
import { config } from '../../../utils/configs';

const PrescribedExerciseList = ({ patientId, isInvitee, patientStatus }: any): ReactElement => {
  const t: any = useSelector<any>((state: GetState) => getCurrenLang(state));
  const on = t?.common.on;
  const off = t?.common.off;
  const ok = t?.common.ok;
  const download = t?.common.download;
  const save = t?.common.save;
  const cancel = t?.common.cancel;
  const not_support = t?.common.not_support;
  const allow_patient_unarchive_exercise =
    t?.dashboard.admin.profile_patient.allow_patient_unarchive_exercise;
  const presc_exercise_list = t?.dashboard.hcp.profile_patient.video_bank.prescribed_exercise_list;
  const dragdrop_exer_cont = t?.dashboard.hcp.profile_patient.video_bank.dragdrop_exercise_content;
  const tap_save_send = t?.dashboard.hcp.profile_patient.video_bank.tap_save_send;
  const tapping_save = t?.dashboard.hcp.profile_patient.video_bank.tapping_save;
  const email_pdf_exerc_plan = t?.dashboard.hcp.profile_patient.video_bank.email_pdf_exerc_plan;
  const patient_be_able_archive =
    t?.dashboard.hcp.profile_patient.video_bank.patient_be_able_archive;
  const patient_not_be_able_archive =
    t?.dashboard.hcp.profile_patient.video_bank.patient_not_be_able_archive;
  const you_dont_have_permission = t?.common.you_dont_have_permission;
  const patient_havent_active_exercises = t?.dashboard.hcp.profile_patient.havent_active_exercises;
  const exercise_plan = t?.dashboard.hcp.profile_patient.exercise_plan;
  const success_emailed = t?.dashboard.hcp.profile_patient.video_bank.success_emailed;

  const { isHcp } = useCheckRole();
  const isPermissionEditProfile = useCheckPermissions(PERMISSIONS.EDIT_PATIENT_PROFILE_INFO_OTM);
  // const isPermissionEditVideoExerc = useCheckPermissions(
  //   PERMISSIONS.EDIT_OR_PRESCRIBE_EXERCISE_VIDEOS_OTM,
  // );
  const formatDate = useSelector((state: GetState) => getCurrentFormatDate(state));
  const showArchiveWarnInit = {
    msg: '',
    solution: null,
    isShow: false,
  };
  const isSafari = useMemo(() => {
    const browser = detectBrowser();
    const detect = browser === 'Apple Safari';
    return detect;
  }, []);
  const { token } = useCheckRole();
  const { uri, bucketName, videoServiceUrl } = config;
  const uriWithoutGraph = uri?.split('/api')[0];

  // Endpoint
  const { _getProfileById } = useQueryPatientProfile(patientStatus);
  const {
    _changePatientAllowArchiveVideos,
    changePatientAllowArchiveVideosData,
    changePatientAllowArchiveVideosError,
    changePatientAllowArchiveVideosLoading,
  } = useChangePatientAllowArchiveVideos();
  const {
    _getActiveVideoExercises,
    activeVideoExersises,
    loadingActiveExercises,
    errorActiveExercises,
  } = useQueryActiveVideoExercises(isInvitee);
  const {
    _sendPlanByEmailToPatient,
    emailSentToPatient,
    sendPlanToPatientError,
    sendPlanToPatientLoading,
  } = useEmailExercisePlanToPatient(isInvitee);

  // Redux
  const profileData: any = useSelector<any>((state: GetState) => getPatientProfile(state));

  // Local state
  const [isShowArchiveWarn, setShowArchiveWarn] = useState<ShowArchiveWarnType>({
    ...showArchiveWarnInit,
  });
  const [isPreviewModal, setPreviewModal] = useState(false);
  const [pdfFile, setPdfFile] = useState<any>(null);
  const [patientEmail, setPatientEmail] = useState(profileData?.email || '');
  const [counter, setCounter] = useState<number>(0);
  const [exercisesToPDF, setExercisesToPDF] = useState<any>();
  const [thumbnailLoading, setThumbnailLoading] = useState(false);
  const [errorToPDF, setErrorToPDF] = useState<any>();
  const [isCreateDPF, setCreateDPF] = useState(false);
  const [recipientType, setRecipientType] = useState('');
  const [isWarningModal, setWarningModal] = useState(false);
  const [stateLoading, setStateLoading] = useState(false);
  const [isSuccessModal, setSuccessModal] = useState(false);

  // Show error
  useEffect(() => {
    if (changePatientAllowArchiveVideosError) {
      toast.error(changePatientAllowArchiveVideosError);
    }
  }, [changePatientAllowArchiveVideosError]);

  // If success update patient data
  useEffect(() => {
    if (changePatientAllowArchiveVideosData) {
      _getProfileById({
        variables: {
          patientId,
        },
      });
      setShowArchiveWarn({ ...showArchiveWarnInit });
    }
  }, [changePatientAllowArchiveVideosData]);

  // Togler handler allow patient unarchive videos by HCP
  const switchHandler = (checked: boolean): void => {
    const text = checked ? patient_be_able_archive : patient_not_be_able_archive;
    setShowArchiveWarn({
      msg: text,
      solution: checked,
      isShow: true,
    });
  };

  //
  const changePatientAllow = (solution: boolean | null): void => {
    _changePatientAllowArchiveVideos({
      variables: { patientId, solution },
    });
  };

  // Open preview PDF prescribed exercise list
  const openPreviewModal = (type: EXPORT_PDF_TYPE): void => {
    if (isSafari) {
      setPreviewModal(() => true);
      return;
    }
    const emailPatient = profileData?.email;
    if (emailPatient) {
      setPatientEmail(emailPatient);
    }
    setRecipientType(type);
    _getActiveVideoExercises({
      variables: {
        patientId,
      },
    });
  };

  // Close preview window
  const closePreview = (): void => {
    setPreviewModal(() => false);
    setErrorToPDF(null);
    setRecipientType('');
  };

  // Options for PDF
  const opt = {
    margin: 5,
    filename: `On The Mend ${exercise_plan} ${moment().format('DD-MM-YYYY')}.pdf`,
    image: { type: 'jpeg', quality: 0.9 },
    enableLinks: true,
    jsPDF: {
      unit: 'mm',
      format: 'a4',
      // orientation: 'landscape',
      orientation: 'portrait',
      compress: true,
    },
    pagebreak: { mode: ['css', 'legacy'], avoid: ['section'] },
  };

  // Create PDF file
  const createPDF = async (): Promise<void> => {
    if (isCreateDPF) {
      const element = document.getElementById('video-exercises-to-pdf');
      try {
        await html2pdf()
          .from(element)
          .set(opt)
          .outputPdf(
            'blob',
            `OnTheMend_${exercise_plan}_${moment().format(formatDate.momentFormat)}.pdf`,
          )
          .then((res: any) => {
            setPdfFile(res);
            setWarningModal(() => true);
          });
      } catch (err) {
        toast.error('Export to PDF error.');
        console.error('From create PDF=', err);
      }
    }
  };

  // Create PDF image and download PDF file
  const uploadPDFHandler = async (): Promise<void> => {
    const element = document.getElementById('video-exercises-to-pdf');
    await html2pdf().from(element).set(opt).save();
    setPreviewModal(() => false);
    setCreateDPF(() => false);
    setRecipientType('');
  };

  // Start create PDF
  useEffect(() => {
    if (isCreateDPF) {
      if (recipientType === EXPORT_PDF_TYPE.SEND_TO_PATIENT) {
        createPDF();
      }
      if (recipientType === EXPORT_PDF_TYPE.UPLOAD) {
        uploadPDFHandler();
      }
    }
  }, [isCreateDPF]);

  // Fill data
  useEffect(() => {
    if (activeVideoExersises) {
      setExercisesToPDF(activeVideoExersises);
    }
  }, [activeVideoExersises]);

  // Show error
  useEffect(() => {
    if (errorActiveExercises) {
      setErrorToPDF(errorActiveExercises);
      setPreviewModal(() => true);
    }
  }, [errorActiveExercises]);

  // Checking uploaded thumbnails and romove loader
  useEffect(() => {
    if (exercisesToPDF && counter >= exercisesToPDF.length) {
      setThumbnailLoading(() => false);
    }
  }, [counter]);

  // Start create preview content for invitee patient
  useEffect(() => {
    if (exercisesToPDF) {
      setPreviewModal(() => true);
      if (exercisesToPDF?.length > 0) {
        setThumbnailLoading(() => true);
      }
    }
  }, [exercisesToPDF]);

  // After success emailing PDF plan to patient open success modal window
  useEffect(() => {
    if (emailSentToPatient) {
      setPreviewModal(() => false);
      setWarningModal(() => false);
      setPatientEmail(() => profileData.email);
      setSuccessModal(() => true);
      setCreateDPF(() => false);
      setRecipientType('');
    }
  }, [emailSentToPatient]);

  // Show toast if error sending exercise PDF plan to patient
  useEffect(() => {
    if (sendPlanToPatientError) {
      toast.error(sendPlanToPatientError);
    }
  }, [sendPlanToPatientError]);

  // Downloaded thumbnails counter
  const counting = (): void => {
    setCounter((count) => count + 1);
  };

  // Send exercises plan to patient by email
  const sendPDFToPatient = async (pdfUuid: string): Promise<void> => {
    _sendPlanByEmailToPatient({
      variables: {
        patientId,
        pdfUuid,
        email: patientEmail,
      },
    });
  };

  // Upload pdf file to AWS
  const uploadPdfFileToAWS = async (): Promise<void> => {
    setStateLoading(() => true);
    const formData = new FormData();
    formData.append('file', pdfFile);
    const urlSecondPart = 'pictures/upload-exercise-plan-pic';
    let videoServUrl = `${uriWithoutGraph}/video_api/${urlSecondPart}`;
    switch (bucketName) {
      case 'otm-stage':
      case 'onthemend-en':
        videoServUrl = `${videoServiceUrl}${urlSecondPart}`;
        break;
      default:
        break;
    }
    axios
      .post(videoServUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const response = res.data;
        if (response) {
          sendPDFToPatient(response);
        } else {
          toast.error('The email was not sent. Try again.');
          setPreviewModal(() => false);
        }
        setStateLoading(() => false);
      })
      .catch((e) => {
        console.error('res=', e);
        setStateLoading(() => false);
        setPreviewModal(() => false);
        toast.error('The email was not sent. Try again.');
      });
  };

  // Close send PDF to patient warning modal window
  const oncloseWarning = (): void => {
    setPreviewModal(() => false);
    setWarningModal(() => false);
    setPatientEmail(() => profileData?.email);
    setRecipientType('');
    setCreateDPF(() => false);
  };

  // Close success modal window
  const oncloseSuccessModal = (): void => {
    setSuccessModal(() => false);
    setPdfFile(() => null);
  };

  // JSX
  const loadingJSX = (changePatientAllowArchiveVideosLoading ||
    loadingActiveExercises ||
    thumbnailLoading ||
    stateLoading ||
    sendPlanToPatientLoading ||
    (isCreateDPF && !isWarningModal)) && <Loading />;
  const archiveWarnContent = (
    <div className={styles['prescrebed__archive-warn-content']}>{isShowArchiveWarn.msg}</div>
  );
  const getPdfWarnContent = (): string => {
    if (isSafari) {
      return not_support;
    }
    if (errorToPDF) {
      return errorToPDF;
    }
    if (!loadingActiveExercises && !errorToPDF && exercisesToPDF?.length === 0) {
      return patient_havent_active_exercises;
    }
    return '';
  };

  return (
    <div className={styles.prescrebed__container}>
      {loadingJSX}
      <MainTitle title={presc_exercise_list} />
      <div className={styles.prescrebed__description}>{dragdrop_exer_cont}</div>
      <div className={styles.prescrebed__description}>{tap_save_send}</div>
      <div className={styles.prescrebed__description}>{tapping_save}</div>

      {/* Upload PDF */}
      <div className={styles['prescrebed__download-pdf-container']}>
        <div
          className={styles['prescrebed__download-wrapper']}
          aria-hidden
          onClick={(): void => openPreviewModal(EXPORT_PDF_TYPE.UPLOAD)}
        >
          <DownloadIcon />
          <div className={styles.prescrebed__download}>{download}</div>
          <SelectDownIcon />
        </div>
        <div
          className={styles['prescrebed__download-wrapper']}
          aria-hidden
          onClick={(): void => openPreviewModal(EXPORT_PDF_TYPE.SEND_TO_PATIENT)}
        >
          <MailIcon />
          <div className={styles.prescrebed__download}>{email_pdf_exerc_plan}</div>
        </div>
      </div>

      {/* Set patient rights to archive/unarchive exercises */}
      {isHcp && !isInvitee && (
        <Tooltip title={!isPermissionEditProfile ? you_dont_have_permission : ''}>
          <div className={styles['prescrebed__archive-switch-wrapper']}>
            <Switch
              checkedChildren={<span>{on}</span>}
              unCheckedChildren={<span>{off}</span>}
              loading={changePatientAllowArchiveVideosLoading}
              checked={profileData?.isAllowArchiveVideos}
              onChange={switchHandler}
              disabled={!isPermissionEditProfile}
            />
            <div className={styles['prescrebed__archive-label']}>
              {allow_patient_unarchive_exercise}
            </div>
          </div>
        </Tooltip>
      )}

      {/* Drag n Drop */}
      <PrescribeExercises patientId={patientId} isInvitee={isInvitee} />

      {/* Prescribed exercises */}
      <PrescribedExercises patientId={patientId} isInvitee={isInvitee} />

      {/* Popups */}
      {isShowArchiveWarn.isShow && (
        <WarnNotifModal
          onClose={(): void => setShowArchiveWarn({ ...showArchiveWarnInit })}
          content={archiveWarnContent}
          cancelBtnName={cancel}
          actionBtnName={save}
          actionMethod={(): void => changePatientAllow(isShowArchiveWarn.solution)}
        />
      )}
      {isPreviewModal && exercisesToPDF?.length > 0 && (
        <PdfExercisesPlanPreview
          data={exercisesToPDF}
          closePreview={closePreview}
          isCreateDPF={isCreateDPF}
          counting={counting}
          setCreateDPF={setCreateDPF}
          loading={!!loadingJSX}
        />
      )}

      {/* Send to patiet warning */}
      {isWarningModal &&
        exercisesToPDF?.length > 0 &&
        !loadingActiveExercises &&
        !errorToPDF &&
        !thumbnailLoading && (
          <SendPdfWarnModal
            patientEmail={patientEmail}
            setPatientEmail={setPatientEmail}
            oncloseWarning={oncloseWarning}
            uploadPdfFileToAWS={uploadPdfFileToAWS}
            loading={!!loadingJSX}
          />
        )}

      {/* ERROR OR EMPTY ACTIVE EXERCISES */}
      {isPreviewModal &&
        (!exercisesToPDF?.length || errorToPDF || isSafari) &&
        !loadingActiveExercises && (
          <WarnNotifModalOneButton
            onClose={closePreview}
            btnName={ok}
            description={getPdfWarnContent()}
          />
        )}

      {/* SUCCESS EMAILED */}
      {isSuccessModal && (
        <SuccessNotifModal
          onClose={oncloseSuccessModal}
          btnName={ok}
          description={success_emailed}
        />
      )}
    </div>
  );
};

export default PrescribedExerciseList;
