import React, { ReactElement, useEffect, useState } from 'react';
import cx from 'classnames';
import moment from 'moment';
import { sanitize } from 'dompurify';
import axios from 'axios';

import style from './style.module.css';
import {
  PainDefaultImg,
  PainDetectArea,
  PDLogo,
  PainImg1,
  PainImg2,
  PainImg3,
  PainImg4,
} from '../../../../../theme/icons';
import { Answer, ImgConfig, PcsContentProps } from './types';
import { getPatientAnswers } from '../../../../../utils/helper_charts';
import { RemoveQuestionnaire } from './common/RemoveQuestionnaire';
import { CalculationDescription } from './common/Calculation';
import { PROMS_NAME } from '../../../../../utils/enums';

export const PainDetectContent = ({
  questionnairesList,
  completedQuestionnaire,
  patient,
  index,
  onDeleteItem,
  formatDate,
  t,
}: PcsContentProps): ReactElement => {
  const remove_questionnaire = t?.dashboard.hcp.profile_patient.questionnaires.remove_questionnaire;
  const date_t = t?.dashboard.hcp.profile_patient.questionnaires.date;
  const first_name = t?.dashboard.admin.profile_patient.first_name;
  const last_name = t?.dashboard.admin.profile_patient.last_name;
  const patient_t = t?.dashboard.hcp.profile_patient.questionnaires.patient;
  const pain_radiates = t?.dashboard.hcp.profile_patient.questionnaires.pain_radiates;
  const filled_out = t?.dashboard.hcp.profile_patient.questionnaires.filled_out_by_physician;
  const never = t?.dashboard.hcp.profile_patient.questionnaires.never;
  const hardly_noticed = t?.dashboard.hcp.profile_patient.questionnaires.hardly_noticed;
  const slightly = t?.dashboard.hcp.profile_patient.questionnaires.slightly;
  const moderately = t?.dashboard.hcp.profile_patient.questionnaires.moderately;
  const strongly = t?.dashboard.hcp.profile_patient.questionnaires.strongly;
  const very_strongly = t?.dashboard.hcp.profile_patient.questionnaires.very_strongly;
  const please_transfer = t?.dashboard.hcp.profile_patient.questionnaires.please_transfer;
  const total_score = t?.dashboard.hcp.profile_patient.questionnaires.total_score;
  const please_add_up = t?.dashboard.hcp.profile_patient.questionnaires.please_add_up;
  const radiating_pains = t?.dashboard.hcp.profile_patient.questionnaires.radiating_pains;
  const if_yes = t?.dashboard.hcp.profile_patient.questionnaires.if_yes;
  const final_score = t?.dashboard.hcp.profile_patient.questionnaires.final_score;
  const out_of = t?.dashboard.hcp.profile_patient.questionnaires.out_of;
  const if_marked_or = t?.dashboard.hcp.profile_patient.questionnaires.if_marked_or;
  const if_marked = t?.dashboard.hcp.profile_patient.questionnaires.if_marked;
  const first_three_likert = t?.dashboard.hcp.profile_patient.questionnaires.first_three_likert;
  const to_their_score = t?.dashboard.hcp.profile_patient.questionnaires.to_their_score;
  const persistent = t?.dashboard.hcp.profile_patient.questionnaires.persistent_pain;
  const without_pain = t?.dashboard.hcp.profile_patient.questionnaires.pain_attacks_without_pain;
  const with_pain = t?.dashboard.hcp.profile_patient.questionnaires.pain_attacks_with_pain;
  const please_mark_your = t?.dashboard.hcp.profile_patient.questionnaires.please_mark_your;
  const main_area = t?.dashboard.hcp.profile_patient.questionnaires.main_area_pain;
  const pain_questionnaire = t?.dashboard.hcp.profile_patient.questionnaires.pain_questionnaire;
  const sc_pain_quest = t?.dashboard.hcp.profile_patient.questionnaires.scoring_pain_questionnaire;
  const yes = t?.common.yes?.toLowerCase();
  const no = t?.common.no?.toLowerCase();
  const none = t?.common.none?.toLowerCase();
  const max = t?.common.max?.toLowerCase();

  const [finalScore, setFinalScore] = useState(0);
  const [radiatingPoins, setRadiatingPoins] = useState(0);
  const { completedDate, pictureUuid, questions: completedQuestions } = completedQuestionnaire;
  const patientAnswers = getPatientAnswers(completedQuestions);
  const formatedDate = completedDate ? moment(completedDate).format(formatDate) : '';
  const { firstName, lastName } = patient;
  const { questions } = questionnairesList.filter(
    (item: any) => item.name === PROMS_NAME.PainDetect,
  )[0];
  const imgConfig = [
    { img: PainImg1, score: 0 },
    { img: PainImg2, score: -1 },
    { img: PainImg3, score: 1 },
    { img: PainImg4, score: 1 },
  ];
  const colorScaleConfig = [
    '#FFFFFF',
    '#FCF2EE',
    '#FAECE6',
    '#F8E5DC',
    '#F6DDD2',
    '#F4D3C4',
    '#F2CDBC',
    '#F0C5B2',
    '#EEBBA4',
    '#ECB69E',
    '#EAAD92',
  ];
  const mainQuestionsCounter: any = {
    0: 0,
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
  };
  let mainTotal = 0;
  let patternPoints = 0;

  useEffect(() => {
    setFinalScore(mainTotal + radiatingPoins + patternPoints);
  }, [mainTotal, patternPoints, radiatingPoins]);
  useEffect(() => {
    if (patientAnswers && questions) {
      if (patientAnswers[questions[4].id] === 2) {
        setRadiatingPoins(2);
      }
    }
  }, [patientAnswers]);

  const getQuestionConfig = (start: number, end: number) => {
    const config: any = [];
    if (completedQuestions.length && questions.length) {
      const questionsList = questions.slice(start, end + 1);
      questionsList.map((item: any, ind: number) => {
        config.push({
          id: questionsList[ind].id,
          name: questionsList[ind].name,
          answers: questionsList[ind].answers,
          value:
            patientAnswers[questionsList[ind].id] === null
              ? 0
              : patientAnswers[questionsList[ind].id],
        });
        return null;
      });
    }
    return config;
  };
  const getAnswer = (indx: number): string => {
    switch (indx) {
      case 0:
        return never;
      case 2:
        return hardly_noticed;
      case 4:
        return slightly;
      case 6:
        return moderately;
      case 8:
        return strongly;
      case 10:
        return very_strongly;
      default:
        return '';
    }
  };

  const getValue = (
    sequenceNumber: number,
    ind: number,
    value: number,
    isMainTotalCount = true,
  ): number | string => {
    if (isMainTotalCount) {
      mainTotal += value;
    }
    if (ind < 2) {
      return value;
    }
    if (sequenceNumber === 1) {
      if (value < 10) {
        return 0;
      }
      return String(value)[0];
    }
    if (value > 0 && value < 10) {
      return String(value)[0];
    }
    if (value > 9) {
      return String(value)[1];
    }
    return 0;
  };
  const getDescr = (i: number): string => {
    switch (i) {
      case 0:
        return '';
      case 1:
      case 2:
        return if_marked_or;
      case 3:
        return if_marked;
      default:
        return '';
    }
  };
  const setPatternScore = (i: number): void => {
    switch (i) {
      case 1:
        patternPoints = -1;
        return;
      case 2:
      case 3:
        patternPoints = 1;
        return;
      default:
        patternPoints = 0;
    }
  };

  const calc_description = (
    <div className={style['questionnaire__calc-descr-pain']}>
      <p>{first_three_likert}</p>
      <div>
        <span>{to_their_score}</span>
        <span>{persistent}</span>
        <span>{without_pain}</span>
        <span>{with_pain}</span>
      </div>
    </div>
  );

  const header = (
    <div className={style['pain__client-info-container']}>
      <div className={style['pain__client-info-date-cont']}>
        <div className={style['pain__client-info-date-name']}>{date_t}:</div>
        <div className={style['pain__client-info-date-value']}>
          <div className={style['pain__client-info-date']}>{formatedDate}</div>
        </div>
      </div>

      <div className={style['pain__client-info-patient-cont']}>
        <div className={style['pain__client-info-date-name']}>{patient_t}:</div>
        <div className={style['pain__client-info-patient-value-cont']}>
          <div className={style['pain__client-info-patientName-cont']}>
            <div className={style['pain__client-info-patientName']}>{last_name}:</div>
            <div className={style['pain__client-info-patientName-value']}>
              <div className={style['pain__client-info-date']}>{lastName || ''}</div>
            </div>
          </div>
          <div className={style['pain__client-info-patientName-cont']}>
            <div className={style['pain__client-info-patientName']}>{first_name}:</div>
            <div className={style['pain__client-info-patientName-value']}>
              <div className={style['pain__client-info-date']}>{firstName || ''}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const footer = (
    <div className={style.pain__footer}>
      <div className={style['pain__footer-item']}>
        R. Freynhagen, R. Baron, U. Gockel,T.R. Tölle, CurrMed ResOpin Vol 22, 2006, 1911-1920
      </div>
      <div className={style['pain__footer-item']}>
        <strong>© 2005 Pfizer Pharma GmbH, Pfizerstr.1, 76139 Karlsruhe, Germany</strong>
      </div>
    </div>
  );

  /* ********************************************************** */
  const [src, setSrc] = useState('');

  // Get image from AWS
  useEffect(() => {
    if (pictureUuid) {
      const getFileFromBlobUrl = async (): Promise<void> => {
        axios
          .get(pictureUuid, { responseType: 'arraybuffer' })
          .then((res) => {
            const base64 = btoa(
              new Uint8Array(res.data).reduce((acc, byte) => acc + String.fromCharCode(byte), ''),
            );
            setSrc(base64);
          })
          .catch((e) => {
            console.error('Err_getThumbnail_from_AXIOS=', e);
          });
      };
      getFileFromBlobUrl();
    }
  }, [pictureUuid]);

  return (
    <>
      <RemoveQuestionnaire onMethod={(): any => onDeleteItem(index)} title={remove_questionnaire} />
      <div className="questionnaire-to-PDF">
        <div
          className={cx({
            [style.pain__container]: true,
            [style.container]: true,
            [style.description__container]: true,
          })}
        >
          <CalculationDescription
            name={pain_questionnaire}
            content={calc_description}
            date={completedDate ? moment(completedDate).format(formatDate) : ''}
            t={t}
          />
        </div>
      </div>
      <div className="questionnaire-to-PDF">
        <div
          className={cx({
            [style.pain__container]: true,
            [style.container]: true,
          })}
        >
          {/* LOGO */}
          {/* <img src={PainDetectLogo} alt="Logo" className={style.pain_logo} />  */}
          <div className={style['pain__logo-cont-out']}>
            <div className={style['pain__logo-cont']}>
              <img src={PDLogo} alt="Logo" />
              <div className={style['pain__logo-text']}>{pain_questionnaire}</div>
            </div>
          </div>

          <div className={style.pain__wrapper}>
            {/* HEADER */}
            {header}
            {/* IMG MARK */}
            <div className={style['pain__imgMark-container']}>
              <div className={style['pain__imgMark-left']}>
                {getQuestionConfig(0, 2).map((question: { name: string; value: number }) => (
                  <div key={question.name} className={style['pain__imgMark-left-row']}>
                    <div
                      dangerouslySetInnerHTML={{ __html: sanitize(question.name) }}
                      className={style['pain__imgMark-left-name']}
                    />
                    <div className={style['pain__imgMark-left-numbers-cont']}>
                      <div className={style['pain__imgMark-left-numbers-subRow']}>
                        {colorScaleConfig.map((color: string, ind: number) => (
                          <div
                            key={color}
                            className={style['pain__imgMark-left-number']}
                            style={ind === question.value ? { border: '1px solid #000000' } : {}}
                          >
                            {ind}
                          </div>
                        ))}
                      </div>
                      <div className={style['pain__imgMark-left-numbers-subRow']}>
                        {colorScaleConfig.map((color: string) => (
                          <div
                            key={color}
                            className={style['pain__imgMark-left-number']}
                            style={{ backgroundColor: color }}
                          />
                        ))}
                      </div>
                      <div className={style['pain__imgMark-left-numbers-afterRow']}>
                        <span>{none}</span>
                        <span>{max}</span>
                      </div>
                    </div>
                  </div>
                ))}
                <div className={style['pain__imgMark-left-bigArea-container']}>
                  <div
                    className={style['pain__imgMark-left-bigArea-title']}
                    dangerouslySetInnerHTML={{ __html: sanitize(questions[3].name) }}
                  />
                  {imgConfig.map((img: ImgConfig, i: number) => {
                    if (questions[3].answers[i].id === patientAnswers[questions[3].id]) {
                      setPatternScore(i);
                    }
                    return (
                      <div key={String(i)} className={style['pain__imgMark-left-bigArea-row']}>
                        <div className={style['pain__imgMark-left-bigArea-img']}>
                          <img src={img.img} alt="Logo" className={style.pain_logo} />
                        </div>
                        <div className={style['pain__imgMark-left-bigArea-text-cont']}>
                          <div
                            className={style['pain__imgMark-left-bigArea-text']}
                            dangerouslySetInnerHTML={{
                              __html: sanitize(questions[3].answers[i].name),
                            }}
                          />
                          <div className={style['pain__imgMark-left-bigArea-value-cont']}>
                            <div className={style['pain__imgMark-left-bigArea-value']}>
                              {questions[3].answers[i].id === patientAnswers[questions[3].id] && (
                                <>&#10004;</>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div
                key="pain__imgMark-right"
                className={cx({
                  [style['pain__imgMark-right']]: true,
                })}
                style={
                  pictureUuid
                    ? {
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                      }
                    : {}
                }
              >
                {src ? (
                  <img
                    crossOrigin="anonymous"
                    src={`data:image/jpeg;charset=utf-8;base64,${src}`}
                    alt="Logo"
                    key="pain__imgMark-right-IMG"
                    className={style.pain_imgMark}
                  />
                ) : (
                  <img
                    src={PainDefaultImg}
                    alt="Preview"
                    crossOrigin="anonymous"
                    key="pain__imgMark-right-IMG"
                    className={style.pain_imgMark}
                  />
                )}
                <div className={style['pain__imgMark-right-img-descr-top']}>
                  <div>{please_mark_your}</div>
                  <div className={style['pain__imgMark-img-txt']}>{main_area}</div>
                </div>
                <div className={style['pain__imgMark-right-qustion-cont']}>
                  <div className={style['pain__imgMark-right-qustion']}>
                    {questions[4].name}
                    <div className={style['pain__imgMark-right-answer-cont']}>
                      <div className={style['pain__imgMark-right-answer']}>
                        {yes}
                        <div className={style['pain__imgMark-right-answer-sqr']}>
                          {patientAnswers[questions[4].id] === 2 && <>&#10004;</>}
                        </div>
                      </div>
                      <div className={style['pain__imgMark-right-answer']}>
                        {no}
                        <div className={style['pain__imgMark-right-answer-sqr']}>
                          {patientAnswers[questions[4].id] === 0 && <>&#10004;</>}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={style['pain__imgMark-right-answer-info']}>{pain_radiates}</div>
                </div>
              </div>
            </div>
            {/* MAIN QUESTIONS */}
            <div className={style['pain__main-container']}>
              {getQuestionConfig(6, 12).map(
                (mainQuestion: { id: number; name: string; value: number; answers: Answer[] }) => (
                  <div key={mainQuestion.name}>
                    <div className={style['pain__main-row']}>
                      {colorScaleConfig.map((itm: string, ind: number) => {
                        const zIndex = ind === 0 ? 1 : 0;
                        if (ind % 2 === 0) {
                          return (
                            <div
                              key={itm}
                              className={style['pain__main-title']}
                              style={{ backgroundColor: itm, zIndex }}
                              dangerouslySetInnerHTML={{
                                __html: ind === 0 ? sanitize(mainQuestion.name) : '',
                              }}
                            />
                          );
                        }
                        return <React.Fragment key={String(itm)} />;
                      })}
                    </div>
                    <div className={style['pain__main-row']}>
                      {colorScaleConfig.map((itm: string, ind: number) => {
                        if (ind % 2 === 0) {
                          if (mainQuestion.answers[ind / 2].id === mainQuestion.value) {
                            mainQuestionsCounter[ind / 2] += 1;
                          }
                          return (
                            <div
                              key={itm}
                              className={style['pain__main-value-cont']}
                              style={{ backgroundColor: itm }}
                            >
                              <div className={style['pain__main-value-name']}>
                                {mainQuestion.answers[ind / 2].name}
                              </div>
                              <div className={style['pain__main-value']}>
                                {mainQuestion.answers[ind / 2].id === mainQuestion.value && (
                                  <>&#10004;</>
                                )}
                              </div>
                            </div>
                          );
                        }
                        return <React.Fragment key={String(itm)} />;
                      })}
                    </div>
                  </div>
                ),
              )}
              <div className={style['pain__main-row']}>
                {colorScaleConfig.map((itm: string, ind: number) => {
                  const zIndex = ind === 4 ? 1 : 0;
                  if (ind % 2 === 0) {
                    return (
                      <div key={itm} className={style['pain__main-white']} style={{ zIndex }}>
                        {ind === 4 && filled_out}
                      </div>
                    );
                  }
                  return <React.Fragment key={String(itm)} />;
                })}
              </div>
              {/* BLUE SECTION */}
              <div className={style['pain__main-row-gray-border']}>
                {colorScaleConfig.map((itm: string, ind: number) => {
                  if (ind % 2 === 0) {
                    return (
                      <div key={itm} className={style['pain__main-blue']}>
                        {getAnswer(ind)}
                      </div>
                    );
                  }
                  return <React.Fragment key={String(itm)} />;
                })}
              </div>
              <div className={style['pain__main-row-gray-border']}>
                {colorScaleConfig.map((itm: string, ind: number) => {
                  if (ind < 6) {
                    return (
                      <div key={itm} className={style['pain__main-blue-count']}>
                        <div className={style['pain__main-blue-sqr']}>
                          {mainQuestionsCounter[ind]}
                        </div>
                        x&nbsp;
                        {ind}
                        &nbsp;=
                        <div className={style['pain__main-blue-sqr-bold']}>
                          {getValue(1, ind, mainQuestionsCounter[ind] * ind)}
                        </div>
                        {ind > 1 && (
                          <div className={style['pain__main-blue-sqr-bold']}>
                            {getValue(2, ind, mainQuestionsCounter[ind] * ind, false)}
                          </div>
                        )}
                      </div>
                    );
                  }
                  return <React.Fragment key={String(itm)} />;
                })}
              </div>
              <div className={style['pain__main-row-total']}>
                <div className={style['pain__main-blue-empty']} />
                <div className={style['pain__main-blue-notemptyend']}>{total_score}</div>
                <div className={style['pain__main-total-res-cont']}>
                  <div className={style['pain__main-blue-sqr-bold_total']}>
                    {getValue(1, 3, mainTotal, false)}
                  </div>
                  <div className={style['pain__main-blue-sqr-bold_total']}>
                    {getValue(2, 3, mainTotal, false)}
                  </div>
                </div>

                <div className={style['pain__main-blue-notempty']}>{`${out_of} 35`}</div>
                <div className={style['pain__main-blue-empty']} />
              </div>
            </div>
          </div>
          {footer}
        </div>
      </div>

      <div className="questionnaire-to-PDF">
        {/* 2 page */}
        <div
          className={cx({
            [style.pain__container]: true,
            [style.container]: true,
          })}
        >
          <div className={style['pain__logo-cont-out']}>
            <div className={style['pain__logo-cont']}>
              <img src={PDLogo} alt="Logo" />
              <div className={style['pain__logo-text_scor']}>{sc_pain_quest}</div>
            </div>
          </div>
          <div className={style.pain__wrapper}>
            {/* HEADER */}
            {header}
            {/* TOTAL SCORE */}
            <div className={style['pain2__totalScore-container']}>
              <p className={style['pain2__totalScore-title']}>{please_transfer}</p>
              <div className={style['pain2__totalScore-value-cont']}>
                <div className={style['pain2__totalScore-name']}>{total_score}</div>
                <div className={style['pain2__totalScore-value']}>
                  <div className={style['pain2__totalScore-value-sqr']}>
                    {getValue(1, 3, mainTotal, false)}
                  </div>
                  <div className={style['pain2__totalScore-value-sqr']}>
                    {getValue(2, 3, mainTotal, false)}
                  </div>
                </div>
              </div>
            </div>
            <div className={style['pain2__mark-img-container']}>
              <div className={style['pain2__mark-img-descr']}>{please_add_up}</div>
              {imgConfig.map((img: ImgConfig, i: number) => (
                <div key={String(i)} className={style['pain2__mark-img-row']}>
                  <div className={style['pain2__mark-img-cont']}>
                    <img src={img.img} alt="Logo" className={style.pain_logo} />
                  </div>
                  <div
                    className={style['pain2__mark-img-question']}
                    dangerouslySetInnerHTML={{ __html: sanitize(questions[3].answers[i].name) }}
                  />
                  <div className={style['pain2__mark-img-answer']}>
                    {img.score > 0 ? `+${img.score}` : img.score}
                  </div>
                  <div className={style['pain2__mark-img-info']}>{getDescr(i)}</div>
                </div>
              ))}
              <div className={style['pain2__mark-img-row']}>
                <div className={style['pain2__mark-img-cont']}>
                  <img src={PainDetectArea} alt="Logo" className={style.pain_logo} />
                </div>
                <div className={style['pain2__mark-img-question']}>{radiating_pains}</div>
                <div className={style['pain2__mark-img-answer']}>+2</div>
                <div className={style['pain2__mark-img-info']}>{if_yes}</div>
              </div>
              <div className={style['pain2__mark-img-row']}>
                <div className={style['pain2__mark-img-final-score-name']}>{final_score}</div>
                <div className={style['pain2__mark-img-final-score-value-cont']}>
                  <div className={style['pain2__mark-img-final-score-value']}>
                    {getValue(1, 3, finalScore, false)}
                  </div>
                  <div className={style['pain2__mark-img-final-score-value']}>
                    {getValue(2, 3, finalScore, false)}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {footer}
        </div>
      </div>
    </>
  );
};
