/* eslint-disable default-case */
import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Loader from 'react-loaders';

import style from './styles.module.css';
import { GetState, getCurrenLang, getCurrentFormatDate } from '../../../../redux/selector';
import { Button, Loading, Modal } from '../../../../common';
import { CloseIcon } from '../../../../theme/icons';
import { PcsContent } from './Reports/pcs';
import { PainDetectContent } from './Reports/painDetect';
import { EqContent } from './Reports/eq';
import { OxfordContent } from './Reports/oxford';
import { QuickDashContent } from './Reports/quickDash';
import { AOFASContent } from './Reports/aofas';
import { MOXFQContent } from './Reports/moxfq';
import { SfReport } from './Reports/sfReport';
import { OdiReport } from './Reports/odiReport';
import { MepsReport } from './Reports/meps';
import { PrweReport } from './Reports/prwe';
import { PROMS_NAME } from '../../../../utils/enums';
import { HoosReport } from './Reports/hoos';
import { KoosReport } from './Reports/koos';

const PromsToPdf = ({
  isModalOpen,
  isMobile,
  setIsMobile,
  completedQuestionnaires,
  fileLoading,
  loadingQuestionnaires,
  downloadHandler,
  onclose,
  errorQuestionnaires,
  questionnairesList,
  patient,
  procedureKey,
}: any): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const dateFormat: any = useSelector<GetState>((state) => getCurrentFormatDate(state));
  const export_to_pdf = t?.common.export_to_pdf;
  const total_questionnaires = t?.dashboard.hcp.profile_patient.total_questionnaires;
  const havent_completed_proms = t?.dashboard.hcp.profile_patient.havent_completed_questionnaires;

  const [allCompletedQuestionnaires, setAllCompletedQuestionnaires] = useState<any>();

  useEffect(() => {
    setAllCompletedQuestionnaires(completedQuestionnaires);
  }, [completedQuestionnaires]);

  const onDeleteItem = (i: number) => {
    const temp = allCompletedQuestionnaires.slice();
    temp.splice(i, 1);
    setAllCompletedQuestionnaires(temp);
    if (allCompletedQuestionnaires?.length === 1) {
      onclose();
    }
  };

  const exportToPdfButton = (
    <Button
      buttonName={export_to_pdf}
      buttonMethod={(): void => {
        downloadHandler('questionnaire-to-PDF');
      }}
      disabledButton={fileLoading || loadingQuestionnaires || !allCompletedQuestionnaires?.length}
      buttonType="button"
      buttonClass={style['exportToPdf__export-btn']}
    />
  );

  return (
    <>
      {isMobile && allCompletedQuestionnaires?.length > 0 && (
        <div className={style.exportToPdf__container}>
          {exportToPdfButton}
          <Button
            buttonName="View questionnaires"
            buttonMethod={(): void => setIsMobile(false)}
            disabledButton={fileLoading || loadingQuestionnaires}
            buttonType="button"
          />
          <div className="btn-close">
            <CloseIcon onClick={onclose} />
          </div>
        </div>
      )}
      {isModalOpen && (
        <Modal
          onClose={onclose}
          style={
            allCompletedQuestionnaires?.length > 0
              ? style.questionnaries__modal
              : style['questionnaries__modal-empty']
          }
        >
          {!isMobile && (
            <div className="btn-close">
              <CloseIcon onClick={onclose} />
            </div>
          )}
          <div className={style.questionnaries__container}>
            {allCompletedQuestionnaires?.length > 0 && (
              <>
                {exportToPdfButton}
                <div className={style.questionnaries__total}>
                  {total_questionnaires}: &nbsp;
                  {allCompletedQuestionnaires?.length}
                </div>
              </>
            )}
            {loadingQuestionnaires && (
              <div className={style['loader-container']}>
                <Loader type="ball-spin-fade-loader" active />
              </div>
            )}
            {errorQuestionnaires && (
              <div className={style.questionnaries__empty}>{errorQuestionnaires}</div>
            )}
            {!loadingQuestionnaires &&
              !errorQuestionnaires &&
              allCompletedQuestionnaires?.length === 0 && (
                <div className={style.questionnaries__empty}>{havent_completed_proms}</div>
              )}
            {!errorQuestionnaires &&
              questionnairesList &&
              allCompletedQuestionnaires &&
              allCompletedQuestionnaires.length > 0 &&
              allCompletedQuestionnaires.map((completedQuestionnaire: any, indx: number) => {
                switch (completedQuestionnaire.questionnaireName) {
                  case 1:
                  case PROMS_NAME.PCS:
                    if (completedQuestionnaire?.questions?.length === 13) {
                      return (
                        <PcsContent
                          key={String(indx)}
                          questionnairesList={questionnairesList}
                          completedQuestionnaire={completedQuestionnaire}
                          patient={patient}
                          index={indx}
                          onDeleteItem={onDeleteItem}
                          formatDate={dateFormat.momentFormat}
                          t={t}
                        />
                      );
                    }
                    return <React.Fragment key={String(indx)} />;
                  // case 2:
                  case PROMS_NAME.PainDetect:
                    if (completedQuestionnaire?.questions?.length === 13) {
                      return (
                        <PainDetectContent
                          key={String(indx)}
                          questionnairesList={questionnairesList}
                          completedQuestionnaire={completedQuestionnaire}
                          patient={patient}
                          index={indx}
                          onDeleteItem={onDeleteItem}
                          formatDate={dateFormat.momentFormat}
                          t={t}
                        />
                      );
                    }
                    return <React.Fragment key={String(indx)} />;
                  // case 3:
                  case PROMS_NAME.EQ5D5L:
                    if (completedQuestionnaire?.questions?.length === 6) {
                      return (
                        <EqContent
                          key={String(indx)}
                          questionnairesList={questionnairesList}
                          completedQuestionnaire={completedQuestionnaire}
                          index={indx}
                          onDeleteItem={onDeleteItem}
                          formatDate={dateFormat.momentFormat}
                          t={t}
                        />
                      );
                    }
                    return <React.Fragment key={String(indx)} />;
                  // case 4:
                  // case 5:
                  // case 6:
                  case PROMS_NAME.OxfordHipScore:
                  case PROMS_NAME.OxfordKneeScore:
                  case PROMS_NAME.OxfordShoulderScore:
                    if (completedQuestionnaire?.questions?.length === 12) {
                      return (
                        <OxfordContent
                          key={String(indx)}
                          questionnairesList={questionnairesList}
                          completedQuestionnaire={completedQuestionnaire}
                          procedureKey={procedureKey}
                          patient={patient}
                          index={indx}
                          onDeleteItem={onDeleteItem}
                          formatDate={dateFormat.momentFormat}
                          t={t}
                        />
                      );
                    }
                    return <React.Fragment key={String(indx)} />;
                  // case 7:
                  case PROMS_NAME.QuickDash:
                    if (completedQuestionnaire?.questions?.length === 11) {
                      return (
                        <QuickDashContent
                          key={String(indx)}
                          questionnairesList={questionnairesList}
                          completedQuestionnaire={completedQuestionnaire}
                          index={indx}
                          onDeleteItem={onDeleteItem}
                          formatDate={dateFormat.momentFormat}
                          t={t}
                        />
                      );
                    }
                    return <React.Fragment key={String(indx)} />;
                  // case 8:
                  case PROMS_NAME.AOFAS:
                    if (completedQuestionnaire?.questions?.length === 9) {
                      return (
                        <AOFASContent
                          key={String(indx)}
                          questionnairesList={questionnairesList}
                          completedQuestionnaire={completedQuestionnaire}
                          patient={patient}
                          index={indx}
                          onDeleteItem={onDeleteItem}
                          formatDate={dateFormat.momentFormat}
                          t={t}
                        />
                      );
                    }
                    return <React.Fragment key={String(indx)} />;
                  // case 9:
                  case PROMS_NAME.MOXFQ:
                    if (completedQuestionnaire?.questions?.length === 16) {
                      return (
                        <MOXFQContent
                          key={String(indx)}
                          questionnairesList={questionnairesList}
                          completedQuestionnaire={completedQuestionnaire}
                          index={indx}
                          onDeleteItem={onDeleteItem}
                          formatDate={dateFormat.momentFormat}
                          t={t}
                        />
                      );
                    }
                    return <React.Fragment key={String(indx)} />;
                  // case 10:
                  case PROMS_NAME.SF36:
                    if (completedQuestionnaire?.questions?.length === 36) {
                      return (
                        <SfReport
                          key={String(indx)}
                          questionnairesList={questionnairesList}
                          completedQuestionnaire={completedQuestionnaire}
                          index={indx}
                          onDeleteItem={onDeleteItem}
                          formatDate={dateFormat.momentFormat}
                          t={t}
                        />
                      );
                    }
                    return <React.Fragment key={String(indx)} />;
                  // case 11:
                  case PROMS_NAME.ODI:
                    if (completedQuestionnaire?.questions?.length === 10) {
                      return (
                        <OdiReport
                          key={String(indx)}
                          questionnairesList={questionnairesList}
                          completedQuestionnaire={completedQuestionnaire}
                          index={indx}
                          onDeleteItem={onDeleteItem}
                          formatDate={dateFormat.momentFormat}
                          t={t}
                        />
                      );
                    }
                    return <React.Fragment key={String(indx)} />;
                  // case 15:
                  case PROMS_NAME.MEPS:
                    return (
                      <MepsReport
                        key={String(indx)}
                        questionnairesList={questionnairesList}
                        completedQuestionnaire={completedQuestionnaire}
                        index={indx}
                        onDeleteItem={onDeleteItem}
                        formatDate={dateFormat.momentFormat}
                        t={t}
                      />
                    );
                  // case 22:
                  case PROMS_NAME.PRWE:
                    return (
                      <PrweReport
                        key={String(indx)}
                        questionnairesList={questionnairesList}
                        completedQuestionnaire={completedQuestionnaire}
                        index={indx}
                        onDeleteItem={onDeleteItem}
                        formatDate={dateFormat.momentFormat}
                        t={t}
                      />
                    );
                  case PROMS_NAME.HOOS:
                    return (
                      <HoosReport
                        key={String(indx)}
                        questionnairesList={questionnairesList}
                        completedQuestionnaire={completedQuestionnaire}
                        index={indx}
                        onDeleteItem={onDeleteItem}
                        formatDate={dateFormat.momentFormat}
                        t={t}
                      />
                    );
                  case PROMS_NAME.KOOS:
                    return (
                      <KoosReport
                        key={String(indx)}
                        questionnairesList={questionnairesList}
                        completedQuestionnaire={completedQuestionnaire}
                        index={indx}
                        onDeleteItem={onDeleteItem}
                        formatDate={dateFormat.momentFormat}
                        t={t}
                      />
                    );
                  default:
                    return <React.Fragment key={String(indx)} />;
                }
              })}
          </div>

          {exportToPdfButton}
          {fileLoading && <Loading />}
        </Modal>
      )}
    </>
  );
};

export default PromsToPdf;
