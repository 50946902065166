import React, { ReactElement } from 'react';
import styles from './styles.module.css';
import { ShowErrorNotificationProps } from './types';

const JWT_TOKEN_EXPIRED = 'jwt token expired';

export const ShowErrorNotification = (
  { errorMessage }: ShowErrorNotificationProps,
): ReactElement => {
  const ErrorJSX = Array.isArray(errorMessage) ? (
    errorMessage.map(
      (error, i) => <div key={String(i)}>{JWT_TOKEN_EXPIRED !== error && error}</div>,
    )
  ) : (
    <div className={styles.error_list}>{JWT_TOKEN_EXPIRED !== errorMessage && errorMessage}</div>
  );
  return <>{ErrorJSX}</>;
};
