import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { getCurrenLang } from '../../../redux/selector';
import { BreadcrumbAnt } from '../../../utils/routers/Breadcrumb';
import styles from '../../styles.module.css';
import { ConnectPatient } from '../../../components/invite/ConnectPatient';
import { path } from '../../../utils';
import { MainTitle } from '../../../common';
import CreateTeam from '../../../components/RehabTeams/CreateTeam';
import { Options } from '../../../utils/model';
import { executeScroll } from '../../../utils/helper';

const ConnectPatientPage = (): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const connect_patient = t?.common.connect_patient;
  const patient_list = t?.title.patient_list;

  const [isViewCreateTeamPage, setViewCreateTeamPage] = useState(false);
  const [createdNewTeam, setCreatedNewTeam] = useState<Options | null>(null);
  const sectionRef: any = useRef(null);

  // Scroll to elem
  useEffect(() => {
    if (isViewCreateTeamPage) {
      executeScroll(sectionRef);
    }
  }, [isViewCreateTeamPage]);

  // Breadcrumb path
  const routes = [
    {
      path: path.dashboardList,
      breadcrumbName: patient_list,
    },
    {
      path: connect_patient,
      breadcrumbName: connect_patient,
    },
  ];

  return (
    <div className={styles.main__container}>
      <BreadcrumbAnt currentRoutes={routes} />
      <div className={styles['main__flex-wrapper']}>
        {/* Connect Patient */}
        <div className={styles['left-section']}>
          <MainTitle title={connect_patient} />
          <ConnectPatient
            setViewCreateTeamPage={setViewCreateTeamPage}
            createdNewTeam={createdNewTeam}
          />
        </div>

        {/* Create Team */}
        {isViewCreateTeamPage ? (
          <div className={styles['right-section']} ref={sectionRef}>
            <CreateTeam
              t={t}
              isConnectPatientPage
              closePage={setViewCreateTeamPage}
              setCreatedNewTeam={setCreatedNewTeam}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default ConnectPatientPage;
