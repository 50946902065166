import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';

import styles from './styles.module.css';
import { Button, Confirmation, Loading, Modal } from '../../../../../common';
import { getCurrenLang } from '../../../../../redux/selector';
import { validationScheduleFolder } from '../../../../../utils/validators';
import { useSaveFolderSettings } from '../../../../../graphql/videoBank';
import ScheduleFolder from '../ScheduleFolder';
import { CloseBtn } from '../../../../../common/Button/Button';
import { PopupWithTwoButtons } from '../../../../../common/Popup';

const ScheduleForm = ({
  folderId,
  dataFolder,
  unitOptions,
  numberOptions,
  setOpenScheduleForm,
  updateList,
}: any): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const cancel = t?.common.cancel;
  const warning_unsaved_changes = t?.common.warning_unsaved_changes;
  const exit = t?.common.exit;
  const ok = t?.common.ok;
  const success = t?.profile_hcp.success;
  const schedule_t = t?.hcp.org_video_bank?.schedule;
  const folder_scheduled = t?.hcp.org_video_bank?.folder_scheduled;

  // Endpoints
  const {
    _saveFolderSettings,
    errorSaveFolderSettings,
    loadingSaveFolderSettings,
    folderSettingsSaved,
  } = useSaveFolderSettings();

  const [successModal, setSuccessModal] = useState(false);
  const [unsaveWarning, setUnsaveWarning] = useState(false);

  const initialValues = useMemo(
    () => ({
      amount: dataFolder.hcpFolderTimer?.amount
        ? numberOptions[dataFolder.hcpFolderTimer.amount - 1]
        : null,
      timePeriod: dataFolder.hcpFolderTimer?.timePeriod
        ? unitOptions[dataFolder.hcpFolderTimer.timePeriod - 1]
        : null,
    }),
    [dataFolder],
  );

  const formik = useFormik({
    initialValues,
    validate: (values) => validationScheduleFolder(values, t),
    onSubmit: (values: any) => {
      _saveFolderSettings({
        variables: {
          hcpFolderSettings: {
            id: folderId,
            name: dataFolder.name,
            description: dataFolder.description,
            amount: values.amount?.value || null,
            timePeriod: values.timePeriod?.value || null,
          },
        },
      });
    },
  });

  const { dirty } = formik;

  // if an error occurs we show an error message
  useEffect(() => {
    if (folderSettingsSaved) {
      setSuccessModal(() => true);
    }
  }, [folderSettingsSaved]);

  // Check changes
  const closeForm = (): void => {
    if (dirty) {
      setUnsaveWarning(() => true);
      return;
    }
    setOpenScheduleForm(() => false);
  };

  // Close modal window
  const closeSuccessModal = (): void => {
    setOpenScheduleForm(() => false);
    updateList();
  };

  // JSX
  const loadingJSX = loadingSaveFolderSettings && <Loading />;

  return (
    <>
      {loadingJSX}
      <form onSubmit={formik.handleSubmit} className={styles['exercise-folder-settings__section']}>
        <ScheduleFolder
          t={t}
          formik={formik}
          unitOptions={unitOptions}
          numberOptions={numberOptions}
        />
        {errorSaveFolderSettings && (
          <div className={styles['exercise-folder-settings__error']}>{errorSaveFolderSettings}</div>
        )}
        <div className={styles['exercise-folder-settings__btn-container']}>
          <Button
            buttonClass={styles['exercise-folder-settings__btn']}
            buttonType="button"
            buttonName={cancel}
            buttonMethod={closeForm}
            disabledButton={loadingSaveFolderSettings}
          />
          <Button
            buttonClass={styles['exercise-folder-settings__btn']}
            buttonType="submit"
            buttonName={schedule_t}
            colorStyle="blue"
            disabledButton={!dirty || loadingSaveFolderSettings}
          />
        </div>

        {/* Popups */}
        {unsaveWarning && (
          <PopupWithTwoButtons
            title={warning_unsaved_changes}
            content=""
            confirmButtonName={exit}
            closeButtonName={cancel}
            onConfirm={(): void => setOpenScheduleForm(() => false)}
            onClosePopup={(): void => setUnsaveWarning(() => false)}
          />
        )}

        {/* Success modal */}
        {successModal && (
          <Modal onClose={closeSuccessModal}>
            <CloseBtn close={closeSuccessModal} />
            <div className={styles['change-password__conf-wrapper']}>
              <Confirmation
                icon="success"
                title={success}
                contant={folder_scheduled}
                buttonTitle={ok}
                buttonMethod={closeSuccessModal}
              />
            </div>
          </Modal>
        )}
      </form>
    </>
  );
};

export default ScheduleForm;
