/* eslint-disable consistent-return */
import { useEffect, useRef } from 'react';

export enum timerStatus {
  STARTED = 'Started',
  STOPPED = 'Stopped',
}

export const useInterval = (callback: any, delay: number | null): void => {
  const savedCallback: any = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    const tick = (): void => {
      savedCallback.current();
    };
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return (): void => clearInterval(id);
    }
  }, [delay]);
};
