import React, { ReactElement, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
// @ts-ignore
import { Document, Page } from 'react-pdf/dist/entry.webpack';
import { Tooltip } from 'antd';
import cx from 'classnames';

import styles from './styles.module.css';
import { getCurrenLang } from '../../../redux/selector';
import { PdfIcon, ArrowLeft, ArrowRight, CloseIcon } from '../../../theme/icons';
import { PdfItemProps } from './types';
import { capitalizeFirstLetter } from '../../../utils/helper';
import { Modal } from '../../../common';
import { downloadFile } from '../../../utils/helper_charts';
import { useGetLink } from '../../../graphql/videoBank';

const PdfItem = ({ pdfPlan, userName, email, isInvitee }: PdfItemProps): ReactElement => {
  const { exercisePlanUuid, exercisePlanName } = pdfPlan;

  const windowClientWidth = useSelector((state: any) => state.common.screen.innerWidth);
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const page_label = t && t.dashboard.hcp.table.page;
  const download = t?.common.download || 'Download';
  const of = t?.common.of || 'of';

  const [file, setFile] = useState<any>('');
  const [numberPages, setNumberPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [isModalOpen, setModalOpen] = useState(false);

  const { _getLink, data, loading } = useGetLink();

  const blobToBase64 = async (blob: any): Promise<void> => {
    const prew = await window.URL.createObjectURL(blob);
    setFile(prew);
  };

  // Get PDF file from AWS
  const getPDF = async (link: any): Promise<void> => {
    const headers = new Headers();
    // headers.append('Cache-control', 'no-cache');
    headers.append('Access-Control-Allow-Credentials', 'true');
    headers.append('Content-Type', 'application/pdf');
    try {
      await fetch(link, {
        // mode: 'cors',
        // credentials: 'omit',
        method: 'GET',
        headers,
      })
        .then((res) => res.blob())
        .then((res) => blobToBase64(res));
    } catch (error) {
      console.log(`Fetch PDF faild : ${error}`);
    }
  };

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
    setNumberPages(numPages);
  };

  // Get link to pdf file from AWS
  useEffect(() => {
    if (exercisePlanUuid) {
      _getLink({
        variables: {
          pdfkey: exercisePlanUuid,
        },
      });
    }
  }, [pdfPlan]);

  // Get file from AWS
  useEffect(() => {
    if (!loading && data) {
      getPDF(data);
    }
  }, [data]);

  // Get next PDF page number
  const toNextPage = (direction: string): void => {
    if (direction === 'left') {
      if (pageNumber === 1) {
        setPageNumber(numberPages);
        return;
      }
      setPageNumber(pageNumber - 1);
      return;
    }
    if (direction === 'right') {
      if (pageNumber === numberPages) {
        setPageNumber(1);
        return;
      }
      setPageNumber(pageNumber + 1);
    }
  };

  // Download PDF file
  const downloadPDFHandler = (): void => {
    const patientNameArr = userName.split(' ');
    const emailNameArr = email.split('@');
    const patientName = {
      firstName: isInvitee ? 'Patient' : patientNameArr[0],
      lastName: isInvitee ? emailNameArr[0] : patientNameArr[1],
    };
    if (file) {
      downloadFile(file, patientName, exercisePlanName, 'pdf');
    }
  };

  // Open modal window
  const openModalHandler = (): void => {
    setModalOpen(() => true);
  };

  // Close preview modal window
  const onclose = (): void => {
    setModalOpen(() => false);
  };

  // JSX
  const getPdfView = (scale: number, isModal: boolean): ReactElement => (
    <div
      className={cx({
        [styles['pdf-plans__item']]: true,
        [styles['pdf-plans__item--modal']]: isModal,
      })}
    >
      {!isModal && (
        <div className={styles['pdf-plans__icon']}>
          <Tooltip mouseEnterDelay={0.3} placement="top" title={download}>
            <div
              className={cx({
                [styles['icon-pdf']]: true,
                [styles.disabled]: false,
                'pdf-icon': true,
              })}
              onClick={downloadPDFHandler}
              role="presentation"
            >
              <img src={PdfIcon} alt="PDF" />
            </div>
          </Tooltip>
        </div>
      )}
      <div className={styles['pdf-plans__document']}>
        <Document
          file={file}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={(e: any): void => console.log('onLoad PDF Error', e)}
          // @ts-ignore
          onClick={openModalHandler}
        >
          {isModal ? (
            <Page
              renderAnnotationLayer={false}
              pageNumber={pageNumber}
              width={windowClientWidth < 700 ? windowClientWidth - 10 : 700}
              scale={scale}
            />
          ) : (
            <Page renderAnnotationLayer={false} pageNumber={pageNumber} scale={scale} width={235} />
          )}
        </Document>
      </div>
      <div className={styles['pdf-plans__item-name']}>{exercisePlanName}</div>
      <div className={styles['pdf-plans__arrow-container']}>
        <div
          className={styles['pdf-plans__arrow']}
          onClick={(): void => toNextPage('left')}
          role="presentation"
        >
          <ArrowLeft />
        </div>
        <span>
          {capitalizeFirstLetter(page_label)} {pageNumber} {of} {numberPages}
        </span>
        <div
          className={styles['pdf-plans__arrow']}
          onClick={(): void => toNextPage('right')}
          role="presentation"
        >
          <ArrowRight />
        </div>
      </div>
    </div>
  );

  return (
    <>
      {getPdfView(0.45, false)}

      {/* PDF PREVIEW */}
      {isModalOpen && (
        <Modal onClose={onclose} style={styles['pdf-plans__modal']}>
          <div className={styles['pdf-plans__btn-close']}>
            <CloseIcon onClick={onclose} />
          </div>
          {getPdfView(1, true)}
        </Modal>
      )}
    </>
  );
};

export default PdfItem;
