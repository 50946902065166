import React, { ReactElement, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Area,
  ComposedChart,
} from 'recharts';
import { toast } from 'react-toastify';
import cx from 'classnames';

import styles from '../styles.module.css';
import { getCurrenLang, getMotivationError, getMotivationLoading } from '../../../redux/selector';
import { TooltipRecharts } from '../../../common/TooltipRecharts';
import { spinner } from '../../../common/Loader';
import { EmojiYAxisTick } from '../../../utils/helper_charts';
import { PERIOD_FOR_CHART } from '../../../utils/enums';

export const MotivationsChart = ({ motivationData, type, isOverview }: any): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const no_data = t?.common.no_data;
  const motivation_label = t?.dashboard.hcp.profile_patient.tabs.motivation;

  const loading = useSelector<any>((state) => getMotivationLoading(state));
  const error: any = useSelector<any>((state) => getMotivationError(state));
  const windowClientWidth = useSelector((state: any) => state.common.screen.innerWidth);
  const chartData: any = motivationData?.values;
  const axisName = motivationData?.axis;
  const periodType = motivationData?.graph;

  const chartPeriod = motivationData?.title;
  const chartDescription = motivationData?.description;
  const isLabelAngle =
    periodType === PERIOD_FOR_CHART.W2 ||
    periodType === PERIOD_FOR_CHART.M6 ||
    periodType === PERIOD_FOR_CHART.M3;
  const isAllLabels = periodType !== PERIOD_FOR_CHART.M6 && windowClientWidth > 500;

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  // JSX

  // Custom Tooltip
  const renderTooltip = (payload: any): JSX.Element => {
    const pay = payload.payload;
    // if (windowClientWidth > 1000) {
    return <TooltipRecharts payload={pay} type={type} t={t} />;
    // }
    // if (payload?.active) {
    //   dispatch(setMotivationsLegendValue(pay));
    // }
    // return <></>;
  };

  const noData = !loading && !chartData?.length && <span className={styles.nodata}>{no_data}</span>;

  return (
    <div
      className={cx({
        [styles['chart-wrapper']]: true,
        'chart-to-PDF': true,
      })}
    >
      {!isOverview && (
        <div>
          <div className={styles['chart-title']}>{motivation_label}</div>
          <div className={styles['chart-desciption']}>{chartDescription}</div>
        </div>
      )}
      {isOverview && (
        <>
          <div className={styles['chart-period-overview']}>{chartPeriod}</div>
          <div className={styles['chart-desciption--overview']}>{chartDescription}</div>
        </>
      )}
      <div className={styles['chart-container']}>
        <div className={styles['chart-label']}>{axisName}</div>
        {loading && spinner}
        {noData}
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            data={chartData}
            margin={{
              top: 10,
              left: -20,
              right: isLabelAngle ? 7 : 0,
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="name"
              axisLine={false}
              tickLine={false}
              // @ts-ignore
              style={{
                fontSize: isLabelAngle ? '11px' : '14px',
                fill: '#23335B',
              }}
              angle={isLabelAngle ? -45 : 0}
              interval={isAllLabels ? 0 : 'preserveEnd'} // 0 - To show all ticks
            />
            <YAxis
              domain={[0, 10]}
              stroke="#9BA1AE"
              tickLine={{ stroke: '#F0F0F8' }}
              interval={0}
              tickCount={11}
              tick={<EmojiYAxisTick type={type} />}
              // @ts-ignore
              style={{
                fontSize: '14px',
                fill: '#23335B',
              }}
            />
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#44C1D8" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#44C1D8" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
              </linearGradient>
            </defs>
            <Area
              type="monotone"
              dataKey="value"
              fill="url(#colorUv)"
              fillOpacity={1}
              // isAnimationActive={false}
            />

            {!noData && (
              <Tooltip
                allowEscapeViewBox={{ x: false, y: false }}
                offset={windowClientWidth > 1000 ? 10 : 500}
                content={renderTooltip}
              />
            )}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};
