import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';

import styles from './styles.module.css';
import { Loading, SelectFloatLabelWithSearch } from '../../common';
import { getCurrenLang } from '../../redux/selector';
import { getSelectOptions } from '../../utils/helper';
import { useGetHospitalNames } from '../../graphql/hospitals';
import { HospitalCheckboxesForm } from './HospitalCheckboxesForm';
import {
  useQueryGetHospitalProgramRewards,
  useSetHospitalProgramRewards,
} from '../../graphql/rewards';
import { SuccessNotifModal } from '../../common/NotificationModal';

const RewardsAdmin = (): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const ok = t?.common.ok;
  const organisation_name = t?.common.organisation_name;
  const no_options = t?.invite_patient.please_enter_min_3_characters;
  const give_access_to_reward = t?.dashboard.admin.table.give_access_to_reward;
  const select_organisation = t?.dashboard.admin.table.select_organisation;
  const rewards_updated = t?.dashboard.admin.table.rewards_updated;

  // Local state
  const [isModalOpen, setModal] = useState(false);

  // Queris and mutations
  const { hospitalNames } = useGetHospitalNames();
  const {
    _getHospitalProgramRewards,
    hospitalProgramRewards,
    errorHospitalProgramRewards,
    loadingHospitalProgramRewards,
  } = useQueryGetHospitalProgramRewards();
  const {
    _setHospitalProgramRewards,
    setHospitalProgramRewardsData,
    setHospitalProgramRewardsError,
    setHospitalProgramRewardsLoading,
  } = useSetHospitalProgramRewards();

  const initialValues: any = {
    organisationName: '',
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (): any => null,
  });

  const { values, errors, touched } = formik;

  // Send option to database
  const _send = (value: any): void => {
    const req = {
      hospitalId: values.organisationName.value,
      programRewardId: 0,
    };
    const keys = Object.keys(value);
    keys.map((item: string) => {
      if (value[item]) {
        req.programRewardId = +item;
      }
      return null;
    });
    _setHospitalProgramRewards({
      variables: req,
    });
  };

  // When the hospital is changed, we request data from the DB
  useEffect(() => {
    if (values.organisationName) {
      _getHospitalProgramRewards({
        variables: {
          hospitalId: values.organisationName.value,
        },
      });
    }
  }, [values.organisationName]);

  // If didn`t find organisationName Error handler
  useEffect(() => {
    if (errorHospitalProgramRewards) {
      toast.error(errorHospitalProgramRewards);
      formik.setFieldValue('organisationName', '');
    }
  }, [errorHospitalProgramRewards]);

  // If set hospital questionnaires not success
  useEffect(() => {
    if (setHospitalProgramRewardsError) {
      toast.error(setHospitalProgramRewardsError);
    }
  }, [setHospitalProgramRewardsError]);

  // If the settings are saved successfully
  useEffect(() => {
    if (setHospitalProgramRewardsData) {
      setModal(setHospitalProgramRewardsData);
    }
  }, [setHospitalProgramRewardsData]);

  const hospitalNameOptions = useMemo(() => getSelectOptions(hospitalNames), [hospitalNames]);

  const onCloseModal = (): void => {
    // Update form
    _getHospitalProgramRewards({
      variables: {
        hospitalId: values.organisationName.value,
      },
    });
    setModal(() => false);
  };

  const loadingJSX = (loadingHospitalProgramRewards || setHospitalProgramRewardsLoading) && (
    <Loading />
  );

  return (
    <>
      {loadingJSX}
      <div className={styles.reward_admin__container}>
        <SelectFloatLabelWithSearch
          inputId="organisationNameId"
          name="organisationName"
          onChange={formik.setFieldValue}
          options={hospitalNameOptions}
          hasErrors={errors.organisationName}
          isTouched={touched.organisationName}
          selected={values.organisationName}
          placeholder={organisation_name}
          noOptionsMessage={no_options}
          inputValue={values.organisationName}
          type="rewards"
        />

        {/* Description */}
        <div className={styles.reward_admin__description}>{give_access_to_reward}:</div>

        {/* Checkboxes container */}
        <div>
          {!values.organisationName && (
            <div className={styles.reward_admin__noData}>{select_organisation}</div>
          )}
          {values.organisationName && hospitalProgramRewards && (
            <HospitalCheckboxesForm
              data={hospitalProgramRewards}
              onSave={_send}
              loading={!!loadingJSX}
            />
          )}
        </div>
      </div>

      {isModalOpen && (
        <SuccessNotifModal onClose={onCloseModal} btnName={ok} description={rewards_updated} />
      )}
    </>
  );
};

export default RewardsAdmin;
