import React from 'react';
import cx from 'classnames';

import styles from './styles.module.css';
import { ClockIcon, FolderIcon } from '../../../../theme/icons';

const FolderItem = ({ data }: any): JSX.Element => (
  <div className={styles['exercise-lib-item__row']}>
    {/* Thumbnail */}
    <div className={styles['exercise-lib-item__block']}>
      <div
        className={cx({
          [styles.preview]: true,
        })}
      >
        <FolderIcon />
        {data.hcpFolderTimer?.amount && (
          <div className={styles['exercise-lib-item__folder-clock']}>
            <ClockIcon />
          </div>
        )}
      </div>
    </div>
    {/* Name */}
    <div className={styles['exercise-lib-item__exercise-name-container']}>
      <div className={styles['exercise-lib-item__name']}>{data.name}</div>
      <div className={styles['exercise-lib-item__description']}>{data.description}</div>
    </div>
  </div>
);

export default FolderItem;
