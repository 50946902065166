import React, { ReactElement, useMemo, useState } from 'react';
import cx from 'classnames';
import { sanitize } from 'dompurify';

import styles from './styles.module.css';
import { CloseBtn } from '../Button/Button';
import { Modal } from '../Modal';
import { otmLink } from '../../utils/variables';
import { EXERCISE_STATUS_COLOR } from '../../utils/enums';

interface GuideTitleProps {
  text: string;
  content: JSX.Element;
}

export const GuideTitle = ({ text, content }: GuideTitleProps): ReactElement => {
  const [isGuideOpen, setGuideOpen] = useState(false);

  const patientStatusGuidJsx = useMemo(() => {
    if (text) {
      const parsString = text?.split('<link>');
      if (parsString?.length > 1) {
        const partOne = parsString[0];
        const partTwo = parsString[1];
        const partThree = parsString[2] || '';

        return (
          <div className={styles.guide__wrapper}>
            <span>{partOne}</span>
            <span
              className={styles.guide__link}
              onClick={(): void => setGuideOpen(true)}
              aria-hidden
            >
              {partTwo}
            </span>
            <span>{partThree}</span>
          </div>
        );
      }
    }
    return <></>;
  }, [text]);

  const closeModal = (): void => {
    setGuideOpen(() => false);
  };

  return (
    <>
      {patientStatusGuidJsx}
      {isGuideOpen && (
        <Modal onClose={closeModal} style={styles.modal__guide}>
          <CloseBtn close={closeModal} />
          {content}
        </Modal>
      )}
    </>
  );
};

export const RewardsGuide = ({ guideStatusesConfig, t }: any): JSX.Element => {
  const guide_setting_rewards = t?.hcp.rewards.guide_setting_rewards;
  const guide_description_one = t?.hcp.rewards.guide_description_one;
  const give_access = t?.hcp.rewards.give_access;
  const guide_list_one = t?.hcp.rewards.guide_list_one;
  const guide_list_two = t?.hcp.rewards.guide_list_two;
  const guide_list_three = t?.hcp.rewards.guide_list_three;
  const guide_offer_cash = t?.hcp.rewards.guide_offer_cash;
  const guide_offer_cash_email = guide_offer_cash?.replace('<email>', otmLink);

  return (
    <div className={styles.guide__container}>
      <div className={styles.guide__title}>{guide_setting_rewards}</div>
      <div className={styles.guide__description}>{guide_description_one}</div>
      <div className={styles.guide__description}>{give_access}</div>
      <ul className={styles.guide__list}>
        <li className={styles.guide__li}>{guide_list_one}</li>
        <li className={styles.guide__li}>{guide_list_two}</li>
        <li className={styles.guide__li}>{guide_list_three}</li>
      </ul>

      {/* Statuses */}
      {guideStatusesConfig.map((status: any) => (
        <div className={styles['guide__status-container']} key={status.description}>
          <div
            className={cx({
              [styles.guide__status]: true,
              [styles[status.color]]: true,
            })}
          >
            {status.status}
          </div>
          <div className={styles['guide__status-description']}>{status.description}</div>
        </div>
      ))}

      <div
        className={styles.guide__footer}
        dangerouslySetInnerHTML={{ __html: sanitize(guide_offer_cash_email) }}
      />
    </div>
  );
};

export const ExerciseStatusGuide = ({ t }: any): JSX.Element => {
  const status_active = t?.common.status_active;
  const status_inactive = t?.common.status_inactive;
  const status_archived = t?.common.status_archived;
  const set = t?.common.set;
  const not_set = t?.common.not_set;
  const status_guide = t?.dashboard.hcp.profile_patient.status_guide;

  const exercise_is_prescribed = t?.dashboard.hcp.profile_patient.exercise_is_prescribed;
  const folders_are_active = t?.dashboard.hcp.profile_patient.folders_are_active;
  const exercise_not_prescribed = t?.dashboard.hcp.profile_patient.exercise_not_prescribed;
  const inactive_folder_means = t?.dashboard.hcp.profile_patient.inactive_folder_means;
  const exercise_settings_saved = t?.dashboard.hcp.profile_patient.exercise_settings_saved;
  const exercise_settings_not_entered =
    t?.dashboard.hcp.profile_patient.exercise_settings_not_entered;
  const exercise_archived = t?.dashboard.hcp.profile_patient.exercise_archived;
  const config = [
    {
      status: status_active,
      color: EXERCISE_STATUS_COLOR.active,
      description: (
        <section>
          <div className={styles['guide__description-item']}>{exercise_is_prescribed}</div>
          <div className={styles['guide__description-item']}>{folders_are_active}</div>
        </section>
      ),
    },
    {
      status: status_inactive,
      color: EXERCISE_STATUS_COLOR.inactive,
      description: (
        <section>
          <div className={styles['guide__description-item']}>{exercise_not_prescribed}</div>
          <div className={styles['guide__description-item']}>{inactive_folder_means}</div>
        </section>
      ),
    },
    {
      status: set,
      color: EXERCISE_STATUS_COLOR.set,
      description: (
        <section>
          <div className={styles['guide__description-item']}>{exercise_settings_saved}</div>
        </section>
      ),
    },
    {
      status: not_set,
      color: EXERCISE_STATUS_COLOR.notSet,
      description: (
        <section>
          <div className={styles['guide__description-item']}>{exercise_settings_not_entered}</div>
        </section>
      ),
    },
    {
      status: status_archived,
      color: EXERCISE_STATUS_COLOR.archived,
      description: (
        <section>
          <div className={styles['guide__description-item']}>{exercise_archived}</div>
        </section>
      ),
    },
  ];

  return (
    <div className={styles.guide__container}>
      <div className={styles.guide__title}>{status_guide}</div>
      {/* Statuses */}
      {config.map((status: any) => (
        <div className={styles['guide__exercise-status-row']} key={status.description}>
          <div
            style={{ color: status.color }}
            className={cx({
              [styles['guide__exercise-status']]: true,
              // [styles[status.color]]: true,
            })}
          >
            {status.status}
          </div>
          {status.description}
        </div>
      ))}
    </div>
  );
};
