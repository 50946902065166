import { makeStyles, green, lightBlue, createMuiTheme } from '../theme/icons';

export const useStyles = makeStyles({
  root: {
    '&$checked': {
      color: '#1c9ce4',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  checked: {
    backgroundColor: 'transparent',
  },
});

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: lightBlue[500],
    },
    secondary: {
      main: green[500],
    },
  },
});
