import React, { ReactElement } from 'react';
import { sanitize } from 'dompurify';
import cx from 'classnames';
import moment from 'moment';

import { getPatientAnswers } from '../../../../../utils/helper_charts';
import style from './style.module.css';
import { Answer, Question, QuestionnaireContentProps } from './types';
import { RemoveQuestionnaire } from './common/RemoveQuestionnaire';
import { CalculationDescription } from './common/Calculation';
import { PROMS_NAME } from '../../../../../utils/enums';

export const QuickDashContent = ({
  questionnairesList,
  completedQuestionnaire,
  index,
  onDeleteItem,
  formatDate,
  t,
}: QuestionnaireContentProps): ReactElement => {
  const remove_questionnaire = t?.dashboard.hcp.profile_patient.questionnaires.remove_questionnaire;
  const qd_name = t?.dashboard.hcp.profile_patient.questionnaires.qd_name;
  const please_rate_severity = t?.dashboard.hcp.profile_patient.questionnaires.please_rate_severity;
  const circle_number = t?.dashboard.hcp.profile_patient.questionnaires.circle_number;
  const please_rate_your_ability =
    t?.dashboard.hcp.profile_patient.questionnaires.please_rate_your_ability;
  const disability_symptom_score =
    t?.dashboard.hcp.profile_patient.questionnaires.disability_symptom_score;
  const of_completed_responses =
    t?.dashboard.hcp.profile_patient.questionnaires.of_completed_responses;
  const sum_responses = t?.dashboard.hcp.profile_patient.questionnaires.sum_responses;
  const where_n_is = t?.dashboard.hcp.profile_patient.questionnaires.where_n_is;
  const not_be_calculated = t?.dashboard.hcp.profile_patient.questionnaires.not_be_calculated;

  const QDList = questionnairesList.filter((item: any) => item.name === PROMS_NAME.QuickDash)[0];
  const { questions } = QDList;
  const { questions: completedQuestions, completedDate } = completedQuestionnaire;
  const patientAnswers = getPatientAnswers(completedQuestions);

  const getTableJSX = (
    list: any,
    startNumbering: number,
    endNumbering: number,
    isLeftInfo = false,
  ): ReactElement => {
    let number = startNumbering;
    const tableList = list.slice(startNumbering - 1, endNumbering);
    return (
      <div className={style['quickDash__table-cont']}>
        <div className={style['quickDash__table-header-cont']}>
          {isLeftInfo && (
            <div className={style['quickDash__table-header-left']}>
              <strong>{please_rate_severity}</strong>
              <i>({circle_number})</i>
            </div>
          )}
          <div className={style['quickDash__table-header']}>
            {tableList[0].answers.map((answer: Answer) => (
              <div
                key={answer.name}
                className={style['quickDash__table-answer']}
                dangerouslySetInnerHTML={{ __html: sanitize(answer.name) }}
              />
            ))}
          </div>
        </div>
        <div className={style['quickDash__table-body']}>
          {tableList.map((question: Question) => {
            const backgroundColor = { backgroundColor: number % 2 === 0 ? '#DEDEDE' : '#FFFFFF' };
            number += 1;
            return (
              <div
                className={style['quickDash__table-row']}
                key={question.name}
                style={backgroundColor}
              >
                <div className={style['quickDash__table-question-name-cont']}>
                  <div className={style['quickDash__table-question-number']}>{number - 1}.</div>
                  <div
                    className={style['quickDash__table-question-name']}
                    dangerouslySetInnerHTML={{ __html: sanitize(question.name) }}
                  />
                </div>
                <div className={style['quickDash__table-question-answer-cont']}>
                  {question.answers.map((currentAnswer: Answer, i: number) => (
                    <div
                      key={currentAnswer.name}
                      className={style['quickDash__table-question-answer-box']}
                    >
                      <div
                        className={style['quickDash__table-question-answer']}
                        style={
                          patientAnswers[question.id] === currentAnswer.id
                            ? { border: '1px solid #000000' }
                            : {}
                        }
                      >
                        {i + 1}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  const quickDash = (
    <>
      <span className={style['quickDash__title-quick']}>
        <i>Quick</i>
      </span>
      <span className={style['quickDash__title-dash']}>dash</span>
    </>
  );

  return (
    <>
      <RemoveQuestionnaire onMethod={() => onDeleteItem(index)} title={remove_questionnaire} />
      <div className="questionnaire-to-PDF">
        <div
          className={cx({
            [style.quickDash__container]: true,
            [style.description__container]: true,
          })}
        >
          <CalculationDescription
            name={qd_name}
            content=""
            date={completedDate ? moment(completedDate).format(formatDate) : ''}
            t={t}
          />
        </div>
      </div>
      {/* PAGE 1 */}
      <div className="questionnaire-to-PDF">
        <div className={style.quickDash__container}>
          <div className={style.quickDash__title}>{quickDash}</div>
          <p className={style.quickDash__subTitle}>{please_rate_your_ability}</p>
          {getTableJSX(questions, 1, 6)}
          {getTableJSX(questions, 7, 7)}
          {getTableJSX(questions, 8, 8)}
          {getTableJSX(questions, 9, 10, true)}
          {getTableJSX(questions, 11, 11)}
          <div className={style['quickDash__table-body']} />
          <div className={style['quickDash__footer-container']}>
            <div>
              <p>
                {quickDash} <strong className={style.uppercase}>{disability_symptom_score}</strong>{' '}
                =
              </p>
              <p>{of_completed_responses}</p>
            </div>
            <div className={style['quickDash__footer-calc-container']}>
              <div className={style['quickDash__footer-parenthesis']}>(</div>

              <div className={style['quickDash__footer-formula-container']}>
                <div className={style['quickDash__footer-formula-sqr-cont']}>
                  <div className={style['quickDash__footer-formula-top']}>
                    <div>({sum_responses})</div>
                  </div>
                  <div className={style['quickDash__footer-formula-bottom']}>n</div>
                </div>
                <div>- 1</div>
              </div>

              <div className={style['quickDash__footer-parenthesis']}>)</div>
            </div>
            <div>{where_n_is}</div>
          </div>
          <p className={style['quickDash__footer-not-be-calc']}>{not_be_calculated}</p>
        </div>
      </div>
    </>
  );
};
