import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Switch } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';

import overviewStyles from '../styles.module.css';
import style from './style.module.css';
import { PatientsNotifIcon } from '../../../theme/icons';
import { NotificationCardProps } from '../types';
import { getCurrenLang } from '../../../redux/selector';
import { CardTitle, EndMessage, NotificationItem } from '../Elements';
import useCheckRole from '../../../hooks/useCheckRole';
import { NOTIF_STATUS } from '../../../utils/enums';
import NotificationAction from '../../../common/Elements/NotificationAction';
import {
  useChangeStateNotifications,
  useGetHcpNotificationList,
  useGetPatientNotificationsList,
} from '../../../graphql/notifications';
import {
  setItemsPerPageCumulativeForPatient,
  setPatientNotificationsStatus,
} from '../../../redux/notifications';
import { spinner } from '../../../common/Loader';
import { ITEMS_PER_PATIENT_PAGE } from '../../../utils/variables';
import useRedirectFromNotification from '../../../hooks/useRedirectFromNotification';
import { TNotification } from '../../SideBar/types';

const NotificationCard = ({
  patientId,
  patientStatus,
  loading,
}: NotificationCardProps): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const on = t?.common.on;
  const off = t?.common.off;
  const loading_t = t?.header.loading;
  const patients_notif = t?.menu.patients_notif;
  const allNotificationsRead = t?.header.all_notifications_read;
  const onlyShowUnread = t?.header.only_show_unread;
  const noMoreMessages = t?.header.no_more_messages;

  const redirectStateInit = {
    isRedirect: false,
    type: '',
    patientId: 0,
    patientStatus: '',
    notifId: 0,
    isUnread: true,
  };

  const { isAdmin } = useCheckRole();
  const { redirectFromNotification } = useRedirectFromNotification();

  // Endpoint
  const {
    _getCurrentPatientNotifications,
    loadingPatientNotifications,
  } = useGetPatientNotificationsList();

  const {
    _getHcpNotifications,
    loadingHcpNotifications,
    hcpNotificationsData,
  } = useGetHcpNotificationList();

  const {
    _changeHcpNotifStatus: _changeNotifsStatus,
    changeHcpNotifsStatusLoading: changeNotifsStatusLoading,
    changeHcNotifsStatusError: changeNotifsStatusError,
    changedHcpNotifsStatus: changedNotifsStatus,
  } = useChangeStateNotifications();

  // Local state
  const [checkedNotifications, setCheckedNotifications] = useState<any>([]);
  const [isRedirectState, setRedirectState] = useState({ ...redirectStateInit });

  // Redux
  const dispatch = useDispatch();

  const {
    itemsPerPageCumulative,
    totalItems,
    totalPages,
    notificationsStatus,
    notificationsList,
  }: any = useSelector<any>((state) => state.notifications.patientNotifications);
  const checkedSwitch = notificationsStatus === NOTIF_STATUS.UNREAD;

  const {
    currentPage: hcpCurrentPage,
    searchQuery,
    itemsPerPage: hcpItemsPerPage,
    notificationsStatus: hcpNotificationsStatus,
  }: any = useSelector<any>((state) => state.notifications);

  const endMessage = totalPages === 1;

  // Select all notifs
  const checkAllItems = (e: any): void => {
    const { checked } = e.target;
    const checkedIds: any = [];
    if (checked) {
      notificationsList.map((notification: TNotification) => {
        checkedIds.push(notification.hcpNotificationId);
        return null;
      });
    }
    setCheckedNotifications(checkedIds);
  };

  // Select one notif
  const checkItem = (e: any, notifId: number): void => {
    const { checked } = e.target;
    if (checked) {
      setCheckedNotifications([...checkedNotifications, notifId]);
      return;
    }
    const newCheckedNotifications = checkedNotifications.filter(
      (checkedNotifId: number) => notifId !== checkedNotifId,
    );
    setCheckedNotifications(newCheckedNotifications);
  };

  // Switch notifikation status (read/unread)
  const switchHandler = (checked: boolean): void => {
    const newStatus = checked ? NOTIF_STATUS.UNREAD : NOTIF_STATUS.READ;
    _getCurrentPatientNotifications(newStatus, itemsPerPageCumulative, patientId);
    dispatch(setPatientNotificationsStatus(newStatus));
    setCheckedNotifications([]);
  };

  // Update notification list
  const updateList = (): void => {
    if (isAdmin) {
      return;
    }
    if (patientId) {
      _getCurrentPatientNotifications(notificationsStatus, itemsPerPageCumulative, patientId);
      _getHcpNotifications(hcpNotificationsStatus, hcpCurrentPage, hcpItemsPerPage, searchQuery);
    }
  };

  // Get Patient notificatons
  useEffect(() => {
    updateList();
  }, [patientId]);

  // Get next page from DB
  const setNextPage = (): void => {
    const nextNumberItemsPerPage = itemsPerPageCumulative + ITEMS_PER_PATIENT_PAGE;

    _getCurrentPatientNotifications(notificationsStatus, nextNumberItemsPerPage, patientId);
    dispatch(setItemsPerPageCumulativeForPatient(nextNumberItemsPerPage));
  };

  // If change status error show message
  useEffect(() => {
    if (changeNotifsStatusError) {
      toast.error(changeNotifsStatusError);
    }
  }, [changeNotifsStatusError]);

  // If changed notifications status
  useEffect(() => {
    if (changedNotifsStatus) {
      setCheckedNotifications([]);
      updateList();
    }
  }, [changedNotifsStatus]);

  // Change notification status
  const setReadStatus = (): void => {
    if (checkedNotifications.length) {
      _changeNotifsStatus(checkedNotifications);
    }
  };

  // Redirect to correspond page after click icon
  const redirectTo = (
    type: string,
    currentPatientId: number,
    currentPatientStatus: string,
    notifId: number,
    isUnread: boolean,
  ): void => {
    setRedirectState({
      isRedirect: true,
      type,
      patientId: currentPatientId,
      patientStatus: currentPatientStatus,
      notifId,
      isUnread,
    });

    if (isUnread) {
      _changeNotifsStatus([notifId]);
      return;
    }
    redirectFromNotification(type, currentPatientId, currentPatientStatus);
  };
  useEffect(() => {
    if (hcpNotificationsData && !loadingHcpNotifications && isRedirectState.isRedirect) {
      const {
        type,
        patientId: currentPatientId,
        patientStatus: currentPatientStatus,
      } = isRedirectState;
      redirectFromNotification(type, currentPatientId, currentPatientStatus);
      setRedirectState({ ...redirectStateInit });
    }
  }, [hcpNotificationsData]);

  // JSX
  const loadingJSX =
    (loadingPatientNotifications ||
      changeNotifsStatusLoading ||
      loading ||
      loadingHcpNotifications) &&
    spinner;

  const noData = (isAdmin || (notificationsList?.length === 0 && !loadingPatientNotifications)) && (
    <div className={overviewStyles.nodata}>{allNotificationsRead}</div>
  );

  const endMsgJsx = (
    <EndMessage
      totalItems={totalItems}
      ITEMS_PER_PAGE={ITEMS_PER_PATIENT_PAGE}
      description={noMoreMessages}
    />
  );

  return (
    <div className={overviewStyles['overview__card-container']}>
      {loadingJSX}
      <CardTitle icon={<PatientsNotifIcon />} title={patients_notif} />

      <div className={style.notifications__container}>
        <div className={style['notifications__switch-container']}>
          <Switch
            checkedChildren={on}
            unCheckedChildren={off}
            checked={checkedSwitch}
            onChange={(checked: boolean): void => {
              switchHandler(checked);
            }}
            disabled={isAdmin}
          />
          <div className={style['notifications__switch-label']}>{onlyShowUnread}</div>
        </div>

        {/* Action */}
        <div className={style['notifications__actions-container']}>
          <NotificationAction
            t={t}
            checkAllItems={checkAllItems}
            isCheked={
              checkedNotifications.length === notificationsList.length && !noData && !loadingJSX
            }
            updateList={updateList}
            disabled={!checkedNotifications.length || isAdmin}
            setReadStatus={setReadStatus}
          />
        </div>

        {/* List */}
        {notificationsList && !noData ? (
          <InfiniteScroll
            dataLength={notificationsList.length}
            next={(): any => setNextPage()}
            hasMore={!endMessage}
            loader={<div className={style.notifications__loader}>{`${loading_t || ''}...`}</div>}
            scrollableTarget="scrollablePatientNotifDiv"
            endMessage={endMsgJsx}
          >
            {notificationsList.map((notification: TNotification, index: number) => (
              <NotificationItem
                key={JSON.stringify(notification)}
                patientId={patientId}
                patientStatus={patientStatus}
                data={notification}
                index={index}
                isHcpNotif={false}
                checkItem={checkItem}
                isCheked={
                  checkedNotifications.length &&
                  checkedNotifications.includes(notification.hcpNotificationId)
                }
                redirectTo={redirectTo}
              />
            ))}
          </InfiniteScroll>
        ) : (
          <></>
        )}
        {noData}
      </div>
    </div>
  );
};

export default NotificationCard;
