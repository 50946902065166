import React, { ReactElement } from 'react';
import { Switch } from 'antd';
import { useSelector } from 'react-redux';
import { getCurrenLang } from '../../redux/selector';

interface RewardsSwitchProps {
  onChange: any;
  isRewardsEnabled: boolean;
  size: any;
  disabled?: boolean;
  dataQa?: string;
}

export const RewardsSwitch = ({
  onChange,
  isRewardsEnabled,
  size,
  disabled = false,
  dataQa,
}: RewardsSwitchProps): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));

  return (
    <Switch
      checkedChildren={<span>{t && t.common.on}</span>}
      unCheckedChildren={<span>{t && t.common.off}</span>}
      checked={isRewardsEnabled}
      onChange={onChange}
      size={size}
      disabled={disabled}
      data-qa={dataQa}
    />
  );
};
