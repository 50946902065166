// Core
import { loader } from 'graphql.macro';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client';
import { useMutationRequest } from '../../hooks/feature/useMutationRequest';
import { UseSetHospitalQuestionnairesType } from '../types';
import { handleError } from '../../utils/helper';
import { config } from '../../utils/configs';
import useHandleGqlError from '../../hooks/useHandleGqlError';
// Query
const queryGetNames = loader('./gql/queryGetHospitalNames.graphql');
const gqlOrganisationList = loader('./gql/queryGetHospitalList.graphql');
const queryHospitalQuestionnaires = loader('./gql/queryGetHospitalQuestionnaires.graphql');
const queryAllHospitalVideos = loader('./gql/queryGetAllHospitalVideos.graphql');
const queryAllHospitalDocuments = loader('./gql/queryGetAllHospitalDocuments.graphql');
const queryAllHospitalDocMatches = loader('./gql/queryGetAllHospitalDocMatches.graphql');
const queryAllHospitalVideosMatches = loader('./gql/queryGetAllHospitalVideosMatches.graphql');
const queryDocumentThumbnailPic = loader('./gql/queryDocumentThumbnailPic.graphql');
const queryDocumentVideoLink = loader('./gql/queryDocumentVideoLink.graphql');

// Mutation
const createOrganisation = loader('./gql/mutationCreateHospitalByAdmin.graphql');
const deleteOrganisation = loader('./gql/mutationDeleteHospitalByAdmin.graphql');
const mutationHospitalQuestionnaires = loader('./gql/mutationSetHospitalQuestionnaires.graphql');
const mutationCreateHospitalVideo = loader('./gql/mutationCreateHospitalVideo.graphql');
const mutationCreateHospitalDocument = loader('./gql/mutationCreateHospitalDocument.graphql');
const mutationDeleteHospitalVideo = loader('./gql/mutationDeleteHospitalVideo.graphql');
const mutationSaveHospitalVideo = loader('./gql/mutationSaveHospitalVideo.graphql');

interface UseGetHospitalNames {
  hospitalNames: any;
  errorHospitalNames: ApolloError | undefined;
  loadingHospitalNames: boolean;
}

export const useGetHospitalNames = (skip = false): UseGetHospitalNames => {
  const { loading, error, data } = useQuery(queryGetNames, {
    skip,
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  });

  const hospitalNames = data ? data.getHospitalNames : null;

  return {
    hospitalNames,
    errorHospitalNames: error,
    loadingHospitalNames: loading,
  };
};

export const useOrgListByAdmin = (): any => {
  const [_getOrgListByAdmin, { loading, error, data }] = useLazyQuery(gqlOrganisationList, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  });
  let dataList = [];
  let total = null;
  let totalPages = null;
  let totalItems = null;

  if (data) {
    const response = data.getHospitalList;
    dataList = response.list;
    total = response.listLength;
    totalPages = response.totalPages;
    totalItems = response.totalItems;
  }

  return {
    _getOrgListByAdmin,
    organisationsList: dataList,
    total,
    totalPages,
    totalItems,
    listError: error,
    orgListLoading: loading,
  };
};

// Get Hospital Questionnaires for Manage PROMS by Admin
export const useGetHospitalQuestionnaires = () => {
  const [_getHospitalQuestionnaires, { loading, error, data }] = useLazyQuery(
    queryHospitalQuestionnaires,
    {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  );

  return {
    _getHospitalQuestionnaires,
    hospitalQuestionnaires: data ? data.getHospitalQuestionnaires : null,
    errorHospitalQuestionnaires: error,
    loadingHospitalQuestionnaires: loading,
  };
};

export const useCreateOrganisation = (): any => {
  const [_onCreateOrganisation, { data, errorText, loading }] = useMutationRequest<{
    createHospitalByAdmin: boolean;
  }>(createOrganisation, { fetchPolicy: 'no-cache' });
  const _createOrganisation = (organisation: string): void => {
    _onCreateOrganisation({
      variables: {
        hospitalData: { name: organisation },
      },
    });
  };

  return {
    _createOrganisation,
    organisationCreated: data && data.createHospitalByAdmin,
    createOrgError: errorText,
    createOrgLoading: loading,
  };
};

export const useDeleteOrganisation = (): any => {
  const [_onDeleteOrganisation, { data, errorText, loading }] = useMutationRequest<{
    deleteHospitalByAdmin: boolean;
  }>(deleteOrganisation, {});
  const _deleteOrganisation = (hospitalId: number): void => {
    _onDeleteOrganisation({
      variables: {
        hospitalId,
      },
    });
  };

  return {
    _deleteOrganisation,
    organisationDeleted: data && data.deleteHospitalByAdmin,
    deleteOrgError: errorText,
    deleteOrgLoading: loading,
  };
};

// Set settings for Hospital Questionnaires
export const useSetHospitalQuestionnaires = (): UseSetHospitalQuestionnairesType => {
  const [_setHospitalQuestionnaires, { data, errorText, loading }] = useMutationRequest(
    mutationHospitalQuestionnaires,
    {},
  );

  return {
    _setHospitalQuestionnaires,
    settingsUpdated: data && data.setHospitalQuestionnaires,
    errorSetHospitalQuestionnaires: errorText,
    loadingSetHospitalQuestionnaires: loading,
  };
};

// Create Hospital video in DB
export const useCreateHospitalVideo = () => {
  const [_createHospitalVideo, { data, errorText, loading }] = useMutationRequest(
    mutationCreateHospitalVideo,
    {},
  );

  return {
    _createHospitalVideo,
    hospitalVideoCreated: data && data.createHospitalVideo,
    errorCreateHospitalVideo: errorText,
    loadingCreateHospitalVideo: loading,
  };
};

// Create Hospital video in DB
export const useCreateHospitalDocument = () => {
  const [_createHospitalDocument, { data, errorText, loading }] = useMutationRequest(
    mutationCreateHospitalDocument,
    {},
  );

  return {
    _createHospitalDocument,
    hospitalDocumentCreated: data && data.createHospitalDocument,
    errorCreateHospitalDocument: errorText,
    loadingCreateHospitalDocument: loading,
  };
};

// Get all Organisation Videos for Organisation Patient Information page
export const useGetAllHospitalDocuments = () => {
  const [_getAllHospitalDoc, { loading, error, data }] = useLazyQuery(queryAllHospitalDocuments, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    pollInterval: config.pollInterval,
  });

  const _getAllHospitalDocuments = (request: any) => {
    _getAllHospitalDoc({
      variables: request,
    });
  };

  const textErr = useHandleGqlError(error);

  return {
    _getAllHospitalDocuments,
    allHospitalDocuments: data && data.getAllHospitalDocuments,
    errorHospitalDocuments: textErr,
    loadingHospitalDocuments: loading,
    allHospitalDocumentsData: data,
  };
};

// Get all Organisation Videos for Organisation Patient Information page for Search info
export const useGetAllHospitalDocumentsMatches = () => {
  const [_getAllHospitalDocMatches, { loading, error, data }] = useLazyQuery(
    queryAllHospitalDocMatches,
    {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  );

  return {
    _getAllHospitalDocMatches,
    totalDoc: data && data.getAllHospitalDocuments.totalItems,
    loadingHospitalDocMatches: loading,
  };
};

// Get ALl Hospital Videos for Manage Patient Information page (OLD query - only video)
export const useGetAllHospitalVideos = () => {
  const [_getAllHospitalVideos, { loading, error, data }] = useLazyQuery(queryAllHospitalVideos, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    pollInterval: config.pollInterval,
  });

  const textErr = useHandleGqlError(error);

  return {
    _getAllHospitalVideos,
    allHospitalVideos: data && data.getAllHospitalVideos,
    errorHospitalVideos: textErr,
    loadingHospitalVideos: loading,
    allHospitalVideosData: data,
  };
};

// Get ALl Hospital Videos for Manage Patient Information page for Search info (OLD query - only video)
export const useGetAllHospitalVideosMatches = () => {
  const [_getAllHospitalVideosMatches, { loading, error, data }] = useLazyQuery(
    queryAllHospitalVideosMatches,
    {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  );

  return {
    _getAllHospitalVideosMatches,
    totalVideos: data && data.getAllHospitalVideos.totalItems,
    loadingHospitalVideosMatches: loading,
  };
};

// Delete video for Manage Patient Information page
export const useDeleteHospitalVideo = (): any => {
  const [_onDeleteHospitalVideo, { data, errorText, loading }] = useMutationRequest<{
    deleteHospitalVideo: boolean;
  }>(mutationDeleteHospitalVideo, {});
  const _deleteHospitalVideo = (hospitalVideoIds: number[]): void => {
    _onDeleteHospitalVideo({
      variables: {
        hospitalVideoIds,
      },
    });
  };

  return {
    _deleteHospitalVideo,
    videoDeleted: data && data.deleteHospitalVideo,
    deleteVideoError: errorText,
    deleteVideoLoading: loading,
  };
};

// get video file link
export const useQueryDocumentVideo = () => {
  const [_getDocumentVideoByUuid, { loading, error, data }] = useLazyQuery(queryDocumentVideoLink, {
    fetchPolicy: 'no-cache',
  });
  return {
    _getDocumentVideoByUuid,
    loadingVideo: loading,
    errorVideo: error && handleError(error, ''),
    videoSrc: data && data.getDocumentVideoLink,
  };
};

// get ThumbNail pic (for Manage PI file in base64)
export const useQueryDocumentThumbnailPic = () => {
  const [_getDocumentThumbnailPic, { loading, error, data }] = useLazyQuery(
    queryDocumentThumbnailPic,
    {},
  );

  return {
    _getDocumentThumbnailPic,
    loadingThumbnailPic: loading,
    errorThumbnailPic: error && handleError(error, ''),
    thumbnailPic: data && data.getDocumentThumbnail,
  };
};

// Update hospital video for patients
export const useSaveHospitalVideo = () => {
  const [_onSaveHospitalVideo, { data, errorText, loading }] = useMutationRequest<{
    saveHospitalVideo: boolean;
  }>(mutationSaveHospitalVideo, {});

  const _saveHospitalVideo = (request: any): void => {
    const { videoData } = request;
    _onSaveHospitalVideo({
      variables: {
        videoProcedureTypesIds: request.videoProcedureTypesId,
        videoData: {
          id: videoData.id,
          name: videoData.name,
          description: videoData.description,
          isAllowDownloadVideos: videoData.isAllowDownloadVideos,
        },
      },
    });
  };

  return {
    _saveHospitalVideo,
    errorSaveHospitalVideo: errorText,
    loadingSaveHospitalVideo: loading,
    hospitalVideoSaved: data && data.saveHospitalVideo,
  };
};
