import DisablePainFree from '../theme/image/emoji/default/00_disable_Pain_Free.svg';
import DisableHardlyNoticeable from '../theme/image/emoji/default/01_disable_Hardly_Noticeable.svg';
import DisableNoticeable from '../theme/image/emoji/default/02_disable_Noticeable.svg';
import DisableUnfomfortable from '../theme/image/emoji/default/03_disable_Unfomfortable.svg';
import DisableModerate from '../theme/image/emoji/default/04_disable_Moderate.svg';
import DisableHardIgnore from '../theme/image/emoji/default/05_disable_Hard_to_ignore.svg';
import DisableFocusAttention from '../theme/image/emoji/default/06_disable_Focus_of_attention.svg';
import DisableSevereSeven from '../theme/image/emoji/default/07_seven_disable_Severe.svg';
import DisableSevere from '../theme/image/emoji/default/08_disable_Severe.svg';
import DisableSevereNine from '../theme/image/emoji/default/09_nine_disable_Severe.svg';
import DisableUnableMove from '../theme/image/emoji/default/10_disable_Unable_to_move.svg';

import PainFree from '../theme/image/emoji/selected/0_Pain Free.svg';
import HardlyNoticeable from '../theme/image/emoji/selected/1_Hardly_Noticeable.svg';
import Noticeable from '../theme/image/emoji/selected/2_Noticeable.svg';
import Unfomfortable from '../theme/image/emoji/selected/3_Unfomfortable.svg';
import Moderate from '../theme/image/emoji/selected/4_Moderate.svg';
import HardIgnore from '../theme/image/emoji/selected/5_Hard_to_ignore.svg';
import FocusAttention from '../theme/image/emoji/selected/6_Focus_of_attention.svg';
import SevereSeven from '../theme/image/emoji/selected/7_seven_Severe.svg';
import Severe from '../theme/image/emoji/selected/8_Severe.svg';
import SevereNine from '../theme/image/emoji/selected/9_nine_Severe.svg';
import UnableMove from '../theme/image/emoji/selected/10_Unable_to_move.svg';

export const emojies = [
  {
    id: '0',
    selectedEmoji: PainFree,
    defaultEmoji: DisablePainFree,
  },
  {
    id: '1',
    selectedEmoji: HardlyNoticeable,
    defaultEmoji: DisableHardlyNoticeable,
  },
  {
    id: '2',
    selectedEmoji: Noticeable,
    defaultEmoji: DisableNoticeable,
  },
  {
    id: '3',
    selectedEmoji: Unfomfortable,
    defaultEmoji: DisableUnfomfortable,
  },
  {
    id: '4',
    selectedEmoji: Moderate,
    defaultEmoji: DisableModerate,
  },
  {
    id: '5',
    selectedEmoji: HardIgnore,
    defaultEmoji: DisableHardIgnore,
  },
  {
    id: '6',
    selectedEmoji: FocusAttention,
    defaultEmoji: DisableFocusAttention,
  },
  {
    id: '7',
    selectedEmoji: SevereSeven,
    defaultEmoji: DisableSevereSeven,
  },
  {
    id: '8',
    selectedEmoji: Severe,
    defaultEmoji: DisableSevere,
  },
  {
    id: '9',
    selectedEmoji: SevereNine,
    defaultEmoji: DisableSevereNine,
  },
  {
    id: '10',
    selectedEmoji: UnableMove,
    defaultEmoji: DisableUnableMove,
  },
];
