import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import styles from '../../styles.module.css';
import {
  GetState,
  getCurrenLang,
  getPatientsInvitationData,
  getPatientsInvitationLoading,
  getPatientsInvitationTotal,
} from '../../../redux/selector';
import { usePatientsPendingInvitations } from '../../../graphql/patients';
import { BreadcrumbAnt } from '../../../utils/routers/Breadcrumb';
import PandingPatientInvitations from '../../../components/patientInvitations/patientInvitations';
import { MainTitle } from '../../../common';
import { PatientInvitationIcon } from '../../../theme/icons';

const PatientInvitationsPage = (): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const patient_invitations = t?.menu.patient_invitations;
  const description = t?.notifications.invitations_description;

  // Redux
  const patientsInvitations = useSelector((state: GetState) => getPatientsInvitationData(state));
  const total = useSelector((state: GetState) => getPatientsInvitationTotal(state));
  const listLoading = useSelector((state: GetState) => getPatientsInvitationLoading(state));

  const { _getPatientInvitations } = usePatientsPendingInvitations();

  // Breadcrumb path
  const routes = [
    {
      path: patient_invitations,
      breadcrumbName: patient_invitations,
    },
  ];

  return (
    <div className={styles.main__container}>
      <BreadcrumbAnt currentRoutes={routes} />
      <div className={cx([styles.main__wrapper, styles['main__wrapper--without-pd']])}>
        <div className={styles['main__wrapper--pd']}>
          <MainTitle title={patient_invitations} icon={<PatientInvitationIcon />} />
          <div className={styles['section-description']}>{description}</div>
        </div>

        <PandingPatientInvitations
          _getPatientInvitations={_getPatientInvitations}
          patientsInvitations={patientsInvitations}
          total={total}
          listLoading={listLoading}
        />
      </div>
    </div>
  );
};

export default PatientInvitationsPage;
