import { useLazyQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import { config } from '../../utils/configs';
import useHandleGqlError from '../../hooks/useHandleGqlError';
import { GetPatientExerciseHistoryProps } from '../../components/Video/ExercisePrescriptions/types';

// Query
const getPatientExerciseHistory = loader('./gql/queryGetPatientExerciseHistory.graphql');
const getInviteePatientExerciseHistory = loader(
  './gql/queryGetInviteePatientExerciseHistory.graphql',
);
const getPatientExerciseHistoryMatch = loader('./gql/queryGetPatientExerciseHistoryMatch.graphql');
const getInviteePatientExerciseHistoryMatch = loader(
  './gql/queryGetInviteePatientExerciseHistoryMatch.graphql',
);

// get list prescriptions to patient for Exercise prescription history report page from DB
export const useGetPatientExerciseHistory = (
  isInvitee: boolean,
): GetPatientExerciseHistoryProps => {
  const query = isInvitee ? getInviteePatientExerciseHistory : getPatientExerciseHistory;
  const [_getPatientExercisesHistory, { loading, error, data }] = useLazyQuery(query, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    pollInterval: config.pollInterval,
  });

  let dataList = [];
  let total = null;
  let totalPages = null;
  let totalElements = null;

  const errorText = useHandleGqlError(error);

  if (data) {
    const response = isInvitee
      ? data.getInviteePatientExerciseHistory
      : data.getPatientExerciseHistory;
    dataList = response.list;
    total = response.listLength;
    totalPages = response.totalPages;
    totalElements = response.totalItems;
  }

  return {
    _getPatientExercisesHistory,
    loading,
    error: errorText,
    patientExercisesHistory: dataList,
    total,
    totalPages,
    totalElements,
  };
};

// get list prescriptions to invitee patient for Exercise prescription history report page from DB
// export const useGetInviteePatientExerciseHistory = (): GetInviteePatientExerciseHistoryProps => {
//   const [_getInviteePatientExercisesHistory, { loading, error, data }] = useLazyQuery(
//     getInviteePatientExerciseHistory,
//     {
//       fetchPolicy: 'no-cache',
//       errorPolicy: 'all',
//       pollInterval: config.pollInterval,
//     },
//   );

//   let dataList = [];
//   let total = null;
//   let totalPages = null;
//   let totalItems = null;

//   if (data) {
//     const response = data.getInviteePatientExerciseHistory;
//     dataList = response.list;
//     total = response.listLength;
//     totalPages = response.totalPages;
//     totalItems = response.totalItems;
//   }

//   return {
//     _getInviteePatientExercisesHistory,
//     loadingInvitee: loading,
//     errorInvitee: error?.graphQLErrors?.[0]?.message || error,
//     patientInviteeHistory: dataList,
//     totalInvitee: total,
//     totalPagesInvitee: totalPages,
//     totalItemsInvitee: totalItems,
//   };
// };

// get matches number prescriptions to patient for
// "Exercise prescription history report" page search menu from DB
export const useFindMatchesPatientExerciseHistory = () => {
  const [_getPatientExercisesHistoryMatch, { loading, data }] = useLazyQuery(
    getPatientExerciseHistoryMatch,
    {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  );

  let totalItemsMatch = null;

  if (data) {
    const response = data.getPatientExerciseHistory;
    totalItemsMatch = response.totalItems;
  }

  return {
    _getPatientExercisesHistoryMatch,
    loadingMatch: loading,
    totalItemsMatch,
  };
};

// get matches number prescriptions to Invitee patient for
// "Exercise prescription history report" page search menu from DB
export const useFindMatchesInviteePatientExerciseHistory = () => {
  const [_getInviteePatientExercisesHistoryMatch, { loading, data }] = useLazyQuery(
    getInviteePatientExerciseHistoryMatch,
    {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  );

  let totalItemsInviteeMatch = null;

  if (data) {
    const response = data.getInviteePatientExerciseHistory;
    totalItemsInviteeMatch = response.totalItems;
  }

  return {
    _getInviteePatientExercisesHistoryMatch,
    loadingInviteeMatch: loading,
    totalItemsInviteeMatch,
  };
};
