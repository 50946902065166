import React, { ReactElement, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import cx from 'classnames';
import styles from './styles.module.css';
// import './styles.css';
import 'react-phone-input-2/lib/high-res.css';
import { InputPhoneNumberFloatLabelProps, InputPhoneNumberProps } from './types';
import { UserCountryEnum } from '../../utils/enums';

export const InputPhoneNumber = ({
  value,
  onChange,
  labelName,
  isTouched,
  hasErrors,
  disabled = false,
  userCountry,
  isRedStar = false,
  dataQa,
}: InputPhoneNumberProps): ReactElement => {
  const invalidStyles = cx({
    [styles.phoneContainerError]: hasErrors && isTouched,
  });

  const getCountry = (): string => {
    switch (userCountry) {
      case UserCountryEnum.US:
        return 'us';
      case UserCountryEnum.GB:
        return 'gb';
      case UserCountryEnum.DE:
        return 'de';
      default:
        return 'gb';
    }
  };

  return (
    <label className={styles.lable_field} htmlFor={labelName}>
      <div className={styles.subtitle}>
        {labelName}
        {isRedStar && <span className={styles.red_star}>*</span>}
        <PhoneInput
          country={getCountry()}
          // preferredCountries={['gb']}
          disableDropdown
          inputProps={{
            name: 'phone',
            id: 'PhoneInputId',
          }}
          // priority={{ gb: 0, us: 1 }}
          // onlyCountries={['gb', 'us']}
          // onlyCountries={[getCountry()]}
          enableAreaCodes
          masks={{
            gb: '.... ... ...',
            us: '... ... ....',
          }}
          value={value}
          containerClass={`${styles.phoneContainer} ${invalidStyles}`}
          inputClass={`${disabled ? styles.phoneInputDisabled : styles.phoneInput}`}
          buttonClass={`${styles.phoneButton}`}
          onChange={onChange}
          placeholder=" "
          disabled={disabled}
          data-qa={dataQa}
        />
        {isTouched && hasErrors ? <div className={styles.error}>{hasErrors}</div> : null}
      </div>
    </label>
  );
};

export const InputPhoneNumberNew = ({
  value,
  onChange,
  labelName,
  isTouched,
  hasErrors,
  disabled = false,
  userCountry,
  dataQa,
}: InputPhoneNumberProps): ReactElement => {
  const invalidStyles = cx({
    [styles.phoneContainerError]: hasErrors && isTouched,
  });

  const getCountry = (): string => {
    switch (userCountry) {
      case UserCountryEnum.US:
        return 'us';
      case UserCountryEnum.GB:
        return 'gb';
      case UserCountryEnum.DE:
        return 'de';
      default:
        return 'gb';
    }
  };

  return (
    <label className={styles.label} htmlFor="idNumber">
      <div className={styles.titleField}>{labelName}</div>
      <PhoneInput
        country={getCountry()}
        disableDropdown
        inputProps={{
          name: 'phone',
        }}
        enableAreaCodes
        masks={{
          gb: '.... ... ...',
          us: '... ... ....',
        }}
        value={value}
        containerClass={`${styles.phoneContainerNew} ${invalidStyles}`}
        inputClass={styles.phoneInputNew}
        buttonClass={`${styles.phoneButtonNew}`}
        onChange={onChange}
        placeholder=""
        disabled={disabled}
        data-qa={dataQa}
      />
      {isTouched && hasErrors ? <div className={styles.error}>{hasErrors}</div> : null}
    </label>
  );
};

export const InputPhoneNumberFloatLabel = ({
  inputId,
  value,
  onChange,
  isTouched,
  hasErrors,
  disabled = false,
  userCountry,
  isRedStar = false,
  placeholder = '',
  dataQa,
}: InputPhoneNumberFloatLabelProps): ReactElement => {
  const getCountry = (): string => {
    switch (userCountry) {
      case UserCountryEnum.US:
        return 'us';
      case UserCountryEnum.GB:
        return 'gb';
      case UserCountryEnum.DE:
        return 'de';
      default:
        return 'gb';
    }
  };
  const [focused, setFocused] = useState(false);
  // const [placeholderState, setPlaceholderState] = useState('');

  const handleFocus = (): void => {
    setFocused(true);
  };

  const handleBlur = (): void => {
    setFocused(false);
  };

  return (
    <label className={styles.label_wrapper} htmlFor={inputId}>
      <PhoneInput
        onFocus={handleFocus}
        onBlur={handleBlur}
        country={getCountry()}
        disableDropdown
        inputProps={{
          name: 'phone',
        }}
        enableAreaCodes
        masks={{
          gb: '.... ... ...',
          us: '... ... ....',
        }}
        value={value}
        containerClass={cx({
          [styles.phoneContainer]: true,
          [styles.phoneContainerError]: hasErrors && isTouched,
        })}
        inputClass={`${disabled ? styles.phoneInputDisabled : styles.phoneInput}`}
        buttonClass={`${styles.phoneButton}`}
        onChange={onChange}
        placeholder=" "
        disabled={disabled}
        data-qa={dataQa}
      />
      <div
        className={cx({
          [styles.phone__label]: true,
          [styles['phone__label--floating']]: focused || value,
        })}
      >
        <span>{placeholder || ''}</span>
        {isRedStar && <span className={styles.red_star}>*</span>}
      </div>
      {isTouched && hasErrors ? <div className={styles.error}>{hasErrors}</div> : null}
    </label>
  );
};
