import React, { ReactElement } from 'react';
import { RenderDotProps } from '../../types';

export const RenderDot = ({
  cx,
  cy,
  stroke,
  strokeOpacity,
  value,
}: RenderDotProps): ReactElement => {
  if (value === null) {
    return <></>;
  }
  return (
    <svg
      x={cx - 5}
      y={cy - 5}
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="5"
        cy="5"
        r="4"
        fill={stroke}
        opacity={strokeOpacity}
        stroke="white"
        strokeWidth="2"
      />
    </svg>
  );
};
