import { LoadingType } from '../types';

// CONSTANTS
export const FILL_PATIENT_PROFILE = 'FILL_PATIENT_PROFILE';
const LOADING_PATIENT_PROFILE = 'LOADING_PATIENT_PROFILE';
const SET_NOT_SAVED_PATIENT_PROFILE = 'SET_NOT_SAVED_PATIENT_PROFILE';
const SET_NOT_SAVED_PATIENT_INFO = 'SET_NOT_SAVED_PATIENT_INFO';
const ERROR_PATIENT_PROFILE = 'ERROR_PATIENT_PROFILE';
const FILL_EXERCISE_LIBRARY = 'FILL_EXERCISE_LIBRARY';
const SET_EXERCISE_LIBRARY_TOTAL_ITEMS = 'SET_EXERCISE_LIBRARY_TOTAL_ITEMS';
const FILL_DRAGGED_LIST = 'FILL_DRAGGED_LIST';
const UPDATE_EXERCISE_LIBRARY = 'UPDATE_EXERCISE_LIBRARY';
const START_UPDATE_EXERCISE_LIBRARY = 'START_UPDATE_EXERCISE_LIBRARY';

// TYPES
export interface DataType {
  data: any;
}
export interface PatientFillPeriondAction {
  type: typeof FILL_PATIENT_PROFILE;
  payload: DataType;
}
interface ActionType {
  type: string;
  payload: any;
}
export interface PatientState {
  profile: any;
  loading: boolean;
  isNotSavedProfileData: boolean;
  isNotSavedPatientInfo: boolean;
  error: undefined;
  exerciseLibrary: null | any;
  exerciseLibraryTotalItems: number;
  draggedList: any;
  isStartUpdate: boolean;
}

// ACTIONS
export const fillPatientProfile = (payload: any): PatientFillPeriondAction => ({
  type: FILL_PATIENT_PROFILE,
  payload,
});

export const loadingPatientProfile = (payload: boolean): LoadingType => ({
  type: LOADING_PATIENT_PROFILE,
  payload,
});

export const setErrorPatientProfile = (payload: any): any => ({
  type: ERROR_PATIENT_PROFILE,
  payload,
});

export const setNotSavedPatientProfile = (value: any): ActionType => ({
  type: SET_NOT_SAVED_PATIENT_PROFILE,
  payload: value,
});

export const setNotSavedPatientInfo = (value: any): ActionType => ({
  type: SET_NOT_SAVED_PATIENT_INFO,
  payload: value,
});

export const fillExerciseLibrary = (value: any): ActionType => ({
  type: FILL_EXERCISE_LIBRARY,
  payload: value,
});

export const updateExerciseLibrary = (value: any): ActionType => ({
  type: UPDATE_EXERCISE_LIBRARY,
  payload: value,
});
export const setExerciseLibraryTotalItems = (value: any): ActionType => ({
  type: SET_EXERCISE_LIBRARY_TOTAL_ITEMS,
  payload: value,
});

export const fillDraggedList = (value: any): ActionType => ({
  type: FILL_DRAGGED_LIST,
  payload: value,
});

export const startUpdateExerciseLib = (value: boolean): ActionType => ({
  type: START_UPDATE_EXERCISE_LIBRARY,
  payload: value,
});

const init: PatientState = {
  profile: null,
  loading: false,
  isNotSavedProfileData: false,
  isNotSavedPatientInfo: false,
  error: undefined,
  exerciseLibrary: null,
  exerciseLibraryTotalItems: 0,
  draggedList: [],
  isStartUpdate: false,
};

// Reducer
export const patientdReduser = (state = init, action: any): PatientState => {
  switch (action.type) {
    case FILL_PATIENT_PROFILE:
      return {
        ...state,
        profile: action.payload,
      };
    case LOADING_PATIENT_PROFILE:
      return {
        ...state,
        loading: action.payload,
      };
    case ERROR_PATIENT_PROFILE:
      return {
        ...state,
        error: action.payload,
      };
    case SET_NOT_SAVED_PATIENT_PROFILE:
      return {
        ...state,
        error: action.payload,
      };
    case SET_NOT_SAVED_PATIENT_INFO:
      return {
        ...state,
        isNotSavedPatientInfo: action.payload,
      };
    case FILL_EXERCISE_LIBRARY: {
      const actionPay = action.payload;
      // If there are dropped items, exclude them from the exercise library.
      if (state.draggedList.length) {
        const filtredPay = actionPay.filter((exercise: any) => {
          const payItemUnicId = `${exercise.type}_${exercise.currentId}`;
          let isExist = false;
          state.draggedList.map((dragedExercise: any) => {
            const dragedExerciseUnicId = `${dragedExercise.type}_${dragedExercise.currentId}`;
            if (payItemUnicId === dragedExerciseUnicId) {
              isExist = true;
            }
            return null;
          });
          if (!isExist) {
            return exercise;
          }
        });
        return {
          ...state,
          exerciseLibrary: filtredPay,
        };
      }

      return {
        ...state,
        exerciseLibrary: actionPay,
      };
    }
    case UPDATE_EXERCISE_LIBRARY:
      return {
        ...state,
        exerciseLibrary: action.payload,
      };
    case SET_EXERCISE_LIBRARY_TOTAL_ITEMS:
      return {
        ...state,
        exerciseLibraryTotalItems: action.payload,
      };
    case FILL_DRAGGED_LIST:
      return {
        ...state,
        draggedList: action.payload,
      };
    case START_UPDATE_EXERCISE_LIBRARY:
      return {
        ...state,
        isStartUpdate: action.payload,
      };
    default:
      return state;
  }
};
