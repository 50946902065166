import React from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';

import style from '../styles.module.css';
import { getCurrenLang, GetState } from '../../../../redux/selector';
import { Button } from '../../../../common';
import MediaItem from '../ExerciseLibraryItem/MediaItem';
import { EXERCISE_TYPE, PERMISSIONS } from '../../../../utils/enums';
import useCheckPermissions from '../../../../hooks/useCheckPermissions';
import { getExercisesCategories } from '../../../../utils/helper';
import { InitialValuesForm } from '../../VideoExercise/types';
import { initialValues } from '../../VideoExercise/initForm';
import { validationExercise, validationFocusCheckboxes } from '../../../../utils/validators';
import SettingsForm from '../../OrganisationVideoBank/components/SettingsForm';
import { InputFloatLabel } from '../../../../common/Input';

const DragMediaModalContent = ({
  data,
  cancelDrag,
  continueDrag,
  exercisesCategories,
  exercisesProcedureFocus,
  focusType1Arr,
  focusType2Arr,
}: any): JSX.Element => {
  const t: any = useSelector<any>((state: GetState) => getCurrenLang(state));
  const cancel = t?.common.cancel;
  const continue_t = t?.common.continue;
  const exercise_name = t?.dashboard.hcp.profile_patient.video_bank.exercise_name;

  const isPermissionEditVideoExerc = useCheckPermissions(
    PERMISSIONS.EDIT_OR_PRESCRIBE_EXERCISE_VIDEOS_OTM,
  );
  const isPhotosExist = !!data.exercisePhotos?.length;
  const exercisePhotos = isPhotosExist ? data.exercisePhotos : [];

  const initialValuesForm: InitialValuesForm = initialValues(
    data,
    exercisesCategories,
    exercisesProcedureFocus,
    t,
    focusType1Arr,
    focusType2Arr,
  );

  const formik = useFormik({
    initialValues: initialValuesForm,
    validate: (values) => validationFocusCheckboxes(values, t),
    validationSchema: () => validationExercise(t),
    onSubmit: async (values: InitialValuesForm) => {
      const request = {
        currentId: data.currentId,
        description: data.description,
        exerciseArchived: data.exerciseArchived,
        exerciseCategories: getExercisesCategories(values, exercisesCategories, 'focus'),
        exerciseComment: values.comments,
        exerciseId: data.exerciseId,
        exerciseName: values.name,
        exercisePhotos: data.exercisePhotos,
        exerciseProcedureTypes: getExercisesCategories(
          values,
          exercisesProcedureFocus,
          'procedure',
        ),
        exerciseReps: values.dim === 'time' ? null : Number(values.reps) || null,
        exerciseRestDays: Number(values.restDaysWeek) || null,
        exerciseSets: Number(values.sets) || null,
        exerciseThumbnailUploaded: data.exerciseThumbnailUploaded,
        exerciseTime:
          values.dim === 'time'
            ? Number(values.seconds.value) + Number(values.minutes.value) * 60
            : null,
        exerciseTimesPerDay: Number(values.timesDay),
        exerciseVideoUploaded: data.exerciseVideoUploaded,
        folderExercises: data.folderExercises,
        hcpFolderTimer: data.hcpFolderTimer,
        name: values.name,
        status: data.status,
        thumbnailName: data.thumbnailName,
        type: data.type,
        videoName: data.videoName,
      };
      continueDrag(request);
    },
  });

  const { values } = formik;
  const valuesData = {
    exercisePhotos: data.exercisePhotos,
    type: data.type,
    ...values,
  };
  const dataType = data.type;

  return (
    <div className={style['exerc-lib__form-wrapper']}>
      <form onSubmit={formik.handleSubmit} className={style['exerc-lib__container']}>
        {(dataType === EXERCISE_TYPE.PHOTO || dataType === EXERCISE_TYPE.VIDEO) && (
          <MediaItem data={valuesData} isModal />
        )}
        <div className={style['exerc-lib__name--input']}>
          <InputFloatLabel
            inputId="name"
            inputName="name"
            inputType="text"
            placeholder={exercise_name}
            hasErrors={formik.errors.name}
            inputValue={values.name}
            isTouched={formik.touched.name}
            onChangeMethod={formik.handleChange}
            disabled={!isPermissionEditVideoExerc}
          />
        </div>
        <SettingsForm
          formik={formik}
          index={1}
          isPhotosExist={isPhotosExist}
          exercisePhotos={exercisePhotos}
          closeSettings={(): null => null}
          setWarningModal={(): null => null}
          focusType2Arr={focusType2Arr}
          exercisesCategories={exercisesCategories}
          focusType1Arr={focusType1Arr}
          exercisesProcedureFocus={exercisesProcedureFocus}
          loading={false}
          errorSaveExercise=""
          errorSaveExerciseInFolder={false}
          deleteFromTeamError={false}
          isModal
        />

        <div className={style['exerc-lib__btns-container']}>
          <Button
            buttonClass={style['exerc-lib__btn']}
            buttonType="button"
            buttonName={cancel}
            buttonMethod={cancelDrag}
          />
          <Button
            buttonClass={style['exerc-lib__btn']}
            buttonType="submit"
            buttonName={continue_t}
            disabledButton={!isPermissionEditVideoExerc}
          />
        </div>
      </form>
    </div>
  );
};

export default DragMediaModalContent;
