import storage from '../utils/storage';
import { constants } from '../utils/routers/book';
import { UseCheckRole } from './types';

const useCheckRole = (): UseCheckRole => {
  const userFromLS = storage.get('user');
  const isAdmin = userFromLS && userFromLS.role === constants.admin;
  const isHcp = userFromLS && userFromLS.role === constants.hcp;
  const token = userFromLS && userFromLS.token;

  return { isAdmin, isHcp, token };
};

export default useCheckRole;
