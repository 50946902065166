import { createContext } from 'react';
import storage from '../utils/storage';
import { ContextProps } from './types';
import { config } from '../utils/configs';

const getAdminFromLS = storage.get('user');
const lang = storage.get('lang');
const langLS = lang || config.language;

const userFromLS = getAdminFromLS && getAdminFromLS.role;

export const userCtx = {
  userFromLS,
  lang: langLS,
};

export const UserContext = createContext<Partial<ContextProps>>(userFromLS);

export const Context = createContext({});
export const { Provider } = Context;
