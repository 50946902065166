import { useHistory } from 'react-router-dom';

interface UseRedirectToPathProps {
  redirectToPage: (redirectPath: string) => void;
}

const useRedirectToPath = (): UseRedirectToPathProps => {
  const history = useHistory();

  const redirectToPage = (redirectPath: string): void => {
    history.push(redirectPath);
  };

  return { redirectToPage };
};

export default useRedirectToPath;
