import React, { ReactElement } from 'react';
import { sanitize } from 'dompurify';
import moment from 'moment';
import cx from 'classnames';

import { getPatientAnswers } from '../../../../../utils/helper_charts';
import style from './style.module.css';
import { Answer, QuestionnaireContentProps, Question } from './types';
import { RemoveQuestionnaire } from './common/RemoveQuestionnaire';
import { CalculationDescription } from './common/Calculation';
import { PROMS_NAME } from '../../../../../utils/enums';

export const MOXFQContent = ({
  questionnairesList,
  completedQuestionnaire,
  index,
  onDeleteItem,
  formatDate,
  t,
}: QuestionnaireContentProps): ReactElement => {
  const remove_questionnaire = t?.dashboard.hcp.profile_patient.questionnaires.remove_questionnaire;
  const moxfq_name = t?.dashboard.hcp.profile_patient.questionnaires.moxfq_name;
  const during_the_past_4_weeks =
    t?.dashboard.hcp.profile_patient.questionnaires.during_the_past_4_weeks;
  const this_has_applied_to_me =
    t?.dashboard.hcp.profile_patient.questionnaires.this_has_applied_to_me;
  const circle_as_appropriate =
    t?.dashboard.hcp.profile_patient.questionnaires.circle_as_appropriate;
  const right = t?.dashboard.hcp.profile_patient.questionnaires.right;
  const left = t?.dashboard.hcp.profile_patient.questionnaires.left;
  const please_tick_box = t?.dashboard.hcp.profile_patient.questionnaires.please_tick_box;
  const please_turn_to_next_page =
    t?.dashboard.hcp.profile_patient.questionnaires.please_turn_to_next_page;
  const finally_please_check = t?.dashboard.hcp.profile_patient.questionnaires.finally_please_check;
  const every_question = t?.dashboard.hcp.profile_patient.questionnaires.every_question;
  const thank_you_very_much = t?.dashboard.hcp.profile_patient.questionnaires.thank_you_very_much;

  const { questions: completedQuestions, completedDate } = completedQuestionnaire;
  const patientAnswers = getPatientAnswers(completedQuestions);
  const MOXFQList = questionnairesList?.filter((item: any) => item.name === PROMS_NAME.MOXFQ)[0];
  const { questions } = MOXFQList;

  const getTableJSX = (
    list: any,
    startNumbering: number,
    endNumbering: number,
    page: number,
  ): ReactElement => {
    let number = startNumbering;
    const tableList = list.slice(startNumbering - 1, endNumbering);
    return (
      <div className={style.moxfq__table}>
        {/* TABLE HEADER */}
        <div className={style.moxfq__header}>
          <div className={style['moxfq__header-descr']}>
            {page === 1 && (
              <div className={style['moxfq__header-descr-circle']}>
                <i>{circle_as_appropriate}</i>
                <span className={style.uppercase}>
                  <strong>{`${right} / ${left}`}</strong>
                </span>
              </div>
            )}
            <div className={style['moxfq__header-descr-during']}>
              <i className={style.underline}>{during_the_past_4_weeks}</i>
              &nbsp;
              <i>{this_has_applied_to_me}</i>
            </div>
          </div>
          <div className={style['moxfq__header-answer-container']}>
            <div className={style['moxfq__header-tick']}>
              <i>{please_tick_box}</i>
            </div>
            <div className={style['moxfq__header-answer-cont']}>
              {tableList[0].answers.map((answer: Answer) => (
                <div
                  key={answer.name}
                  className={style['moxfq__header-answer']}
                  dangerouslySetInnerHTML={{ __html: sanitize(answer.name) }}
                />
              ))}
            </div>
          </div>
        </div>
        {/* TABLE BODY */}
        {tableList.map((question: Question, i: number) => {
          number += 1;
          return (
            <div key={question.name} className={style.moxfq__row}>
              <div className={style['moxfq__question-cont']}>
                <div className={style['moxfq__question-number']}>{number - 1}.</div>
                <div
                  className={style.moxfq__question}
                  dangerouslySetInnerHTML={{ __html: sanitize(question.name) }}
                />
              </div>
              <div className={style['moxfq__answers-cont']}>
                {question.answers.map((answ: Answer) => (
                  <div key={answ.name} className={style.moxfq__answer}>
                    <div className={style['eq__box-sqr']}>
                      {patientAnswers[question.id] === answ.id && <>&#10004;</>}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  const getCustomRows = (list: any, startNumbering: number, endNumbering: number): ReactElement => {
    let number = startNumbering;
    const tableList = list.slice(startNumbering - 1, endNumbering);

    return (
      <>
        {tableList.map((question: Question, i: number) => {
          number += 1;
          return (
            <div key={question.name} className={style['moxfq__custom-row']}>
              <div className={style['moxfq__custom-row-question-number']}>{number - 1}.</div>
              <div className={style['moxfq__custom-row-question-cont']}>
                <strong>{during_the_past_4_weeks}</strong>
                &nbsp;
                <span
                  className={style['moxfq__custom-row-question']}
                  dangerouslySetInnerHTML={{ __html: sanitize(question.name) }}
                />
                <div className={style['moxfq__custom-row-answer-container']}>
                  {question.answers.map((answer: Answer) => (
                    <div key={answer.name} className={style['moxfq__custom-row-answer-item']}>
                      <div>{answer.name}</div>
                      <div className={style['eq__box-sqr']}>
                        {patientAnswers[question.id] === answer.id && <>&#10004;</>}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <>
      <RemoveQuestionnaire onMethod={() => onDeleteItem(index)} title={remove_questionnaire} />
      <div className="questionnaire-to-PDF">
        <div
          className={cx({
            [style.quickDash__container]: true,
            [style.description__container]: true,
          })}
        >
          <CalculationDescription
            name={moxfq_name}
            content=""
            date={completedDate ? moment(completedDate).format(formatDate) : ''}
            t={t}
          />
        </div>
      </div>
      {/* PAGE 1 */}
      <div className="questionnaire-to-PDF">
        <div className={style.moxfq__container}>
          <div className={style.moxfq__title}>{moxfq_name}</div>
          {getTableJSX(questions, 1, 10, 1)}
          <div className={style['moxfq__please-turn']}>{please_turn_to_next_page}</div>
        </div>
      </div>
      {/* PAGE 2 */}
      <div className="questionnaire-to-PDF">
        <div className={style.moxfq__container}>
          <div className={style.moxfq__title}>{moxfq_name}</div>
          {getTableJSX(questions, 11, 14, 2)}
          {getCustomRows(questions, 15, 16)}
          <div className={style.moxfq__finally}>
            {finally_please_check} <span className={style.underline}>{every_question}</span>
          </div>
          <div className={style.moxfq__thank}>{thank_you_very_much}</div>
          <div className={style.moxfq__footer}>
            ©MOXFQ v2 University of Oxford, Department of Public Health (HSRU), Old Road Campus,
            Oxford OX37LF, UK
          </div>
        </div>
      </div>
    </>
  );
};
