// Core
import React, { ReactElement, useEffect } from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import ReactGa from 'react-ga';
// Pages
import { useDispatch } from 'react-redux';
import AppRouter from './utils/routers/AppRouter';
// Hooks
import { useTranslate } from './graphql/translation';
// Components
import { Loading } from './common';
import { AuthContext } from './context/withAuthContext';
// Instruments
import { config } from './utils/configs';
import { persistor } from './redux/store';
import storage from './utils/storage';

import 'antd/dist/antd.css';
import 'react-toastify/dist/ReactToastify.css';
import './theme/base.css';
import { setCurrentDateFormat, setCurrentLang, setWindowClientWidth } from './redux/common';
import { getFormats } from './utils/helper';

function App(): ReactElement {
  const lang = storage.get('lang');
  const langLS = lang || config.language;
  const { _getTranslation } = useTranslate();
  const dispatch = useDispatch();

  useEffect(() => {
    _getTranslation({
      variables: {
        translationData: {
          langCode: langLS,
        },
      },
    });
  }, []);

  useEffect(() => {
    ReactGa.initialize(String(process.env.GOOGLE_ANALITICS_MEASUREMENT_ID), {
      gaOptions: { cookieFlags: 'SameSite=None; Secure' },
    }); // TODO: google analytics code, need to add from an account
    ReactGa.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    // dispatch(setWindowClientWidth(window.innerWidth));
    dispatch(setWindowClientWidth(window.screen.width));
    dispatch(setCurrentDateFormat(getFormats(langLS)));
    dispatch(setCurrentLang(langLS));
  }, []);

  useEffect(() => {
    window.addEventListener('resize', () => {
      // dispatch(setWindowClientWidth(window.innerWidth));
      dispatch(setWindowClientWidth(window.screen.width));
    });

    //   window.addEventListener('orientationchange', () => {
    //     dispatch(setWindowClientWidth(window.innerHeight));
    //   });
  }, [window.innerWidth]);

  return (
    <PersistGate loading={<Loading />} persistor={persistor}>
      {/* <AuthContext> */}
      <AppRouter />
      {/* </AuthContext> */}
    </PersistGate>
  );
}

export default App;
