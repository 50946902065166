import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';

import pageStyles from '../../styles.module.css';
import styles from './styles.module.css';
import { getCurrenLang } from '../../../redux/selector';
import { BreadcrumbAnt } from '../../../utils/routers/Breadcrumb';
import { executeScroll, initialPage, initialSizePage } from '../../../utils/helper';
import {
  useCreateOrganisation,
  useDeleteOrganisation,
  useOrgListByAdmin,
} from '../../../graphql/hospitals';
import { validationAddOrganisation } from '../../../utils/validators';
import { Button, InputFormik, Loading, MainTitle } from '../../../common';
import SearchClient from '../../../components/Search/SearchClient';
import useDebounce from '../../../hooks/useDebounce';
import AddNewItem from '../../../components/ManageProfessions/AddNewItemJsx';
import ListItems from '../../../components/ManageProfessions/ListItems';
import { WarnNotifModal } from '../../../common/NotificationModal';
import { InputFloatLabel } from '../../../common/Input';

const ManageOrganisationsPage = (): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const cancel = t?.common.cancel;
  const save = t?.common.save;
  const delete_t = t?.common.delete;
  const cancel_t = t?.common.cancel;
  const organisation_name = t?.common.organisation_name;
  const organisations_list = t?.common.organisations_list;
  const no_data = t?.common.no_data;
  const manage_organisations = t?.menu.manage_organisations;
  const placeholder = t?.invite_patient.please_enter_min_3_characters;
  const add_new_org = t?.title.add_new_org;
  const enter_organisation_name = t?.title.enter_organisation_name;
  const are_you_sure = t?.notifications.are_you_sure_disconnect;
  const do_you_want_delete = t?.notifications.do_you_want_delete;

  const initCandidat = { id: 0, name: '' };

  const [pageSize, setPageSize] = useState<number>(initialSizePage);
  const [currentPage, setCurrentPage] = useState<number>(initialPage);
  const [isModalOpen, setModal] = useState<boolean>(false);
  const [isDelete, setDelete] = useState<boolean>(false);
  const [hospitals, setHospitals] = useState([]);
  const [totalItemsState, setTotalItemsState] = useState(0);
  const [candidat, setCandidat] = useState(initCandidat);
  const [filter, setFilter] = useState<string>('');
  const [filterQuery, setFilterQuery] = useState<any>(null);
  const sectionRef: any = useRef(null);

  const {
    _getOrgListByAdmin,
    organisationsList,
    totalItems,
    listError,
    orgListLoading,
  } = useOrgListByAdmin();

  const {
    _createOrganisation,
    organisationCreated,
    createOrgError,
    createOrgLoading,
  } = useCreateOrganisation();

  const {
    _deleteOrganisation,
    organisationDeleted,
    deleteOrgError,
    deleteOrgLoading,
  } = useDeleteOrganisation();

  const initialValues = {
    organisation: '',
  };

  const onSave = ({ organisation }: { organisation: string }): void => {
    _createOrganisation(organisation);
  };
  const getList = (page: number, itemsPerPage: number): void => {
    _getOrgListByAdmin({
      variables: {
        listProps: {
          page,
          itemsPerPage,
        },
        searchProps: filterQuery || [
          {
            searchField: 'hospital.name',
            searchValue: '',
          },
        ],
      },
    });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: () => validationAddOrganisation(t),
    onSubmit: (values) => onSave(values),
  });

  // Scroll to elem
  useEffect(() => {
    if (isModalOpen) {
      executeScroll(sectionRef);
    }
  }, [isModalOpen]);

  useEffect(() => {
    getList(initialPage, pageSize);
    setCurrentPage(initialPage);
  }, []);

  useEffect(() => {
    if (organisationsList && !orgListLoading) {
      setHospitals(organisationsList);
      setTotalItemsState(totalItems);
    }
  }, [organisationsList]);

  useEffect(() => {
    if (organisationCreated) {
      setModal(() => false);
      formik.resetForm();
      getList(currentPage, pageSize);
    }
  }, [organisationCreated]);

  // Get profession list after search
  useEffect(() => {
    getList(initialPage, pageSize);
    setCurrentPage(initialPage);
  }, [filterQuery]);

  const debounceValue = useDebounce(filter, 300);

  // Get search result from DB after delay
  useEffect(() => {
    if (debounceValue.length >= 2) {
      setFilterQuery([{ searchField: 'hospital.name', searchValue: debounceValue }]);
    } else {
      setFilterQuery(null);
    }
  }, [debounceValue]);

  // Search patient or HCP
  const changeFilter = ({ target: { value } }: any): void => {
    setFilter(value);
  };

  useEffect(() => {
    if (listError) {
      toast.error(listError);
    }
  }, [listError]);

  useEffect(() => {
    if (organisationDeleted) {
      getList(currentPage, pageSize);
    }
    setDelete(() => false);
    setCandidat(() => initCandidat);
  }, [organisationDeleted]);

  useEffect(() => {
    if (deleteOrgError) {
      toast.error(deleteOrgError);
    }
  }, [deleteOrgError]);

  const onChangePagination = (page: number, size: number | undefined): void => {
    let paginPageSize = pageSize;
    let paginPage = page;
    if (size && size !== pageSize) {
      setPageSize(size);
      paginPageSize = size;
      paginPage = initialPage;
    }
    getList(paginPage, paginPageSize);
    setCurrentPage(paginPage);
  };

  const onOpenModal = (): void => {
    setModal(() => true);
  };
  const onCloseModal = (): void => {
    setModal(() => false);
  };
  const removeOrganisation = (orgId: number): void => {
    _deleteOrganisation(orgId);
  };

  const loadingJSX = (createOrgLoading || orgListLoading || deleteOrgLoading) && <Loading />;

  // Breadcrumb path
  const routes = [
    {
      path: manage_organisations,
      breadcrumbName: manage_organisations,
    },
  ];
  const deleteContentJsx = (
    <div>
      <div className={styles['delete-content']}>{`${do_you_want_delete} ${candidat.name}?`}</div>
      <div className={styles['delete-content']}>{are_you_sure}</div>
    </div>
  );

  return (
    <div className={pageStyles.main__container}>
      <BreadcrumbAnt currentRoutes={routes} />
      <div className={pageStyles.main__wrapper}>
        <div className={pageStyles['flex-container']}>
          <div className={pageStyles['left-section']}>
            {loadingJSX}
            <MainTitle title={manage_organisations} />

            {/* Search */}
            <div className={styles['search-container']}>
              <SearchClient
                inputId="patientsSearch"
                name="filter"
                placeholder={placeholder}
                inputValue={filter}
                changeFilter={changeFilter}
              />
            </div>

            {/* Add new Item */}
            <AddNewItem label={add_new_org} actionMethod={onOpenModal} disable={isModalOpen} />

            {/* List */}
            <ListItems
              label={organisations_list}
              srcList={hospitals}
              setCandidat={setCandidat}
              setDelete={setDelete}
              currentPage={currentPage}
              pageSize={pageSize}
              totalItemsState={totalItemsState}
              onChangePagination={onChangePagination}
              no_data={no_data}
            />
          </div>

          {/* Add new Departament */}
          {isModalOpen ? (
            <div className={pageStyles['right-section']} ref={sectionRef}>
              <MainTitle title={add_new_org} />
              <form onSubmit={formik.handleSubmit}>
                <InputFloatLabel
                  inputId="organisationAdminId"
                  inputName="organisation"
                  inputType="text"
                  hasErrors={formik.errors.organisation}
                  inputValue={formik.values.organisation}
                  isTouched={formik.touched.organisation}
                  onChangeMethod={formik.handleChange}
                  placeholder={organisation_name}
                  disabled={createOrgLoading || orgListLoading}
                />
                <div className={styles['modal__manage-org-err-cont']}>
                  {createOrgError && <>{createOrgError}</>}
                </div>
                <div className={styles['modal__manage-org-btn-cont']}>
                  <Button
                    buttonClass={styles['modal__manage-org-btn']}
                    buttonType="button"
                    buttonName={cancel}
                    buttonMethod={onCloseModal}
                    disabledButton={createOrgLoading}
                  />
                  <Button
                    buttonClass={styles['modal__manage-org-btn']}
                    buttonType="submit"
                    buttonName={save}
                    disabledButton={createOrgLoading}
                  />
                </div>
              </form>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>

      {isDelete && (
        <WarnNotifModal
          onClose={(): void => setDelete(() => false)}
          content={deleteContentJsx}
          cancelBtnName={cancel_t}
          actionBtnName={delete_t}
          actionMethod={(): void => removeOrganisation(candidat.id)}
        />
      )}
    </div>
  );
};
export default ManageOrganisationsPage;
