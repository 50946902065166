import React, { ReactElement, useMemo } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { ResponsiveContainer, BarChart, CartesianGrid, XAxis, YAxis, Bar, Tooltip } from 'recharts';

import { getCurrenLang, getNotComplExercLoading } from '../../../redux/selector';

import styles from '../styles.module.css';
import { spinner } from '../../../common/Loader';
import { TooltipRecharts } from '../../../common/TooltipRecharts';
import { PERIOD_FOR_CHART } from '../../../utils/enums';
import { TReasonBar } from './types';
import { NotComplExercValuesType } from '../../../redux/completions/types';
import { LegendBarChart } from '../../../common/Legend';

const ReasonsChart = ({ reasonsNotComletionData, type, isOverview }: any): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const no_data = t?.common.no_data;
  const too_painful = t?.dashboard.hcp.profile_patient.reasons.too_painful;
  const lack_of_equipment = t?.dashboard.hcp.profile_patient.reasons.lack_of_equipment;
  const no_time = t?.dashboard.hcp.profile_patient.reasons.no_time;
  const didnt_understand = t?.dashboard.hcp.profile_patient.reasons.didnt_understand;
  const rest_day = t?.dashboard.hcp.profile_patient.reasons.rest_day;
  const fatigue_label = t?.dashboard.hcp.profile_patient.reasons.fatigue;
  const will_do_later = t?.dashboard.hcp.profile_patient.reasons.will_do_later;
  const already_done = t?.dashboard.hcp.profile_patient.reasons.already_done;
  const i_prefer_not_to_say = t?.dashboard.hcp.profile_patient.reasons.i_prefer_not_to_say;
  const reason_for_not_completing =
    t?.dashboard.hcp.profile_patient.completion_and_steps.reason_for_not_completing;

  const loading = useSelector<any>((state) => getNotComplExercLoading(state));
  const windowClientWidth = useSelector((state: any) => state.common.screen.innerWidth);
  const chartData: any = reasonsNotComletionData?.values;
  const chartDescription = reasonsNotComletionData?.description;
  const axisName = reasonsNotComletionData?.axis;
  const YAxisDomain = reasonsNotComletionData?.yAxisDomain;
  const periodType = reasonsNotComletionData?.graph;
  const barChartLabels = [
    { name: too_painful || '1', value: 0, color: '#8147E9' },
    { name: lack_of_equipment || '2', value: 0, color: '#E60084' },
    { name: no_time || '3', value: 0, color: '#1C9CE4' },
    { name: didnt_understand || '4', value: 0, color: '#44C1D8' },
    { name: rest_day || '5', value: 0, color: '#30CE0C' },
    { name: fatigue_label || '6', value: 0, color: '#1CBA66' },
    { name: will_do_later || '7', value: 0, color: '#F15B07' },
    { name: already_done || '8', value: 0, color: '#B4B783' },
    { name: i_prefer_not_to_say || '9', value: 0, color: '#ADADAD' },
  ];
  const legendConfig = [...barChartLabels].reverse();
  const isLabelAngle =
    periodType === PERIOD_FOR_CHART.W2 ||
    periodType === PERIOD_FOR_CHART.M6 ||
    periodType === PERIOD_FOR_CHART.M3;
  const isAllLabels = periodType !== PERIOD_FOR_CHART.M6 && windowClientWidth > 500;

  const dataTransformert = useMemo(() => {
    if (chartData) {
      const dataCalc = chartData.map((value: NotComplExercValuesType) => {
        const {
          tooPainful,
          lackOfEquipment,
          noTime,
          didntUnderstand,
          fatigue,
          willDoLater,
          alreadyDone,
          noReason,
          restDay,
        } = value.reasons;

        const data = {
          name: value.name,
          [too_painful]: tooPainful,
          [lack_of_equipment]: lackOfEquipment,
          [no_time]: noTime,
          [didnt_understand]: didntUnderstand,
          [rest_day]: restDay,
          [fatigue_label]: fatigue,
          [will_do_later]: willDoLater,
          [already_done]: alreadyDone,
          [i_prefer_not_to_say]: noReason,
        };
        return data;
      });
      return dataCalc;
    }
    return [];
  }, [chartData]);

  // Custom Tooltip
  const renderTooltip = (payload: any): JSX.Element => {
    const pay = payload.payload;
    return <TooltipRecharts payload={pay} type={type} t={t} />;
  };

  // JSX
  const noData = !loading && !dataTransformert?.length && (
    <span className={styles.nodata}>{no_data}</span>
  );

  return (
    <div
      className={cx({
        [styles['chart-wrapper']]: true,
        [styles['padding-bottom']]: true,
        'chart-to-PDF': true,
      })}
    >
      {!isOverview && (
        <div>
          <div className={styles['chart-title']}>{reason_for_not_completing}</div>
          <div className={styles['margin-bottom']} />
          <div className={styles['chart__exrc-compl-legend--wrapper']}>
            <LegendBarChart config={legendConfig} />
          </div>
        </div>
      )}
      {isOverview && <div className={styles['chart-desciption']}>{chartDescription}</div>}

      <div className={styles['chart-container']}>
        <div className={styles['chart-label']}>{axisName}</div>
        {loading && spinner}
        {noData}
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={dataTransformert}
            margin={{
              top: 10,
              left: -20,
              right: isLabelAngle ? 7 : 0,
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="name"
              axisLine={false}
              tickLine={false}
              angle={isLabelAngle ? -45 : 0}
              // @ts-ignore
              style={{
                fontSize: isLabelAngle ? '11px' : '14px',
                fill: '#23335B',
              }}
              interval={isAllLabels ? 0 : 'preserveEnd'} // 0 - To show all ticks
            />
            <YAxis
              // dataKey="value"
              domain={YAxisDomain || [0, 10]}
              interval={0} // To show all ticks
              axisLine={false}
              // @ts-ignore
              style={{
                fontSize: '14px',
                fill: '#23335B',
              }}
            />
            {!noData && (
              <Tooltip
                content={renderTooltip}
                allowEscapeViewBox={{ x: false, y: false }}
                offset={windowClientWidth > 1000 ? 10 : 500}
              />
            )}
            {barChartLabels.map((bar: TReasonBar) => (
              <Bar
                key={bar.name}
                dataKey={bar.name}
                strokeWidth={3}
                fill={bar.color}
                stackId="a"
                isAnimationActive
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default ReasonsChart;
