import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ArrowBackIosIcon } from '../../theme/icons';
import { getCurrenLang } from '../../redux/selector';
import styles from './styles.module.css';
import { ButtonGoBackProps } from './types';

export const ButtonGoBack = ({ redirectTo, onClick }: ButtonGoBackProps): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const back = t?.common.back;

  const onGoBackLinkJsx = redirectTo && (
    <div className={styles.wrapper}>
      <Link to={redirectTo}>
        <span className={styles.btn}>
          <ArrowBackIosIcon />
          {back}
        </span>
      </Link>
    </div>
  );

  const onGoBackClickJsx = onClick && (
    <div className={styles.wrapper} onClick={onClick} role="presentation">
      <div className={styles['go-back-link']}>
        <span className={styles.btn}>
          <ArrowBackIosIcon />
          {back}
        </span>
      </div>
    </div>
  );
  return (
    <>
      {onGoBackClickJsx}
      {onGoBackLinkJsx}
    </>
  );
};
