import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import cx from 'classnames';
import { Tooltip } from 'antd';
import { toast } from 'react-toastify';

import pageStyles from '../../styles.module.css';
import style from './style.module.css';
import patientStyles from '../style.module.css';
import {
  GetState,
  getCurrenLang,
  getErrorPatientProfile,
  getLoadingPatientProfile,
  getPatientProfile,
  getPatientTeamList,
  getPatientTeamsError,
  getPatientTeamsLoading,
  getPatientTeamsTotalItems,
  getSearchPatientTeamLoading,
  getTeamSettingsData,
  getTotalItemsSearchPatientTeam,
} from '../../../redux/selector';
import { Button, Loading, MainTitle } from '../../../common';
import { BreadcrumbAnt } from '../../../utils/routers/Breadcrumb';
import { getQueryParams, initialPage } from '../../../utils/helper';
import { path } from '../../../utils';
import useCheckRole from '../../../hooks/useCheckRole';
import { useQueryPatientProfile } from '../../../graphql/patients';
import { PERMISSIONS, StatusKeyNameEnum } from '../../../utils/enums';
import { PatientRehabTeamIcon } from '../../../theme/icons';
import useCheckPermissions from '../../../hooks/useCheckPermissions';
import HcpTeamTable from '../../../components/RehabTeams/HcpTeamTable';
import { useGetPatientRehabTeam, useGetPatientRehubTeamMatch } from '../../../graphql/hospitalTeam';
import { setOpenTeamSettingData, setSearchTeamItems } from '../../../redux/rehabTeams';
import { fillPatientProfile } from '../../../redux/patient';
import TeamSettings from '../../../components/RehabTeams/TeamSettings';
import { GuideTitle } from '../../../common/Giude';
import PatientStatusGuide from '../../../components/Table/components/PatientStatusGuide';
import OtherHcpTable from '../../../components/RehabTeams/OtherHcpTable';

const PatientRehabTeamPage = (): ReactElement => {
  const t: any = useSelector<any>((state: GetState) => getCurrenLang(state));
  const patient_list = t?.title.patient_list;
  const team_t = t?.common.team;
  const create_team = t?.hcp.manage.create_team;
  const other_hcp = t?.dashboard.hcp.profile_patient.other_hcp;
  const patient_rehab_team = t?.menu.patient_rehab_team;
  const you_can_see_list_team = t?.hcp.rehab_teams?.you_can_see_list_team;
  const you_dont_have_permission = t?.common.you_dont_have_permission;
  const patient_not_found = t?.common.patient_not_found;
  const no_my_patient_teams = t?.hcp.rehab_teams?.no_my_patient_teams;
  const other_hcp_guide = t?.hcp.rehab_teams?.hcp_status_guide;

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { userId, userStatus } = getQueryParams(location.search);
  const patientId = Number(userId);
  const patientStatus = String(userStatus);
  const { isAdmin, isHcp } = useCheckRole();
  const isPermissionCreateTeam = useCheckPermissions(PERMISSIONS.CREATE_TEAM_OTM);

  // Endpoints
  // Get current patient profile from DB
  const { _getProfileById } = useQueryPatientProfile(patientStatus);
  // Patient Teams
  const { _getPatientRehabTeam } = useGetPatientRehabTeam();
  const { _getPatientTeamMatch } = useGetPatientRehubTeamMatch();

  // Local state
  const [currentPage, setCurrentPage] = useState<number>(initialPage);
  const [pageSize, setPageSize] = useState<number>(15);
  const [sortBy, setSortby] = useState<string>('name');
  const [sortDirectionName, setSortDirectionName] = useState<string>('');
  const [isSearch, setIsSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [hideSearchMenu, setHideSearchMenu] = useState(false);

  // Redux
  const profileData: any = useSelector<any>((state: GetState) => getPatientProfile(state));
  const profileLoading = useSelector<any>((state: GetState) => getLoadingPatientProfile(state));
  const profileError: any = useSelector<any>((state: GetState) => getErrorPatientProfile(state));

  const dataTeam: any = useSelector<GetState>((state) => getPatientTeamList(state));
  const teamsLoading: any = useSelector<GetState>((state) => getPatientTeamsLoading(state));
  const teamListError: any = useSelector<GetState>((state) => getPatientTeamsError(state));
  const itemsTotal: any = useSelector<GetState>((state) => getPatientTeamsTotalItems(state));
  const teamsLoadingMatch = useSelector<GetState>((state) => getSearchPatientTeamLoading(state));
  const searchMatches = useSelector<GetState>((state) => getTotalItemsSearchPatientTeam(state));
  const isOpenSettings: any = useSelector<any>((state) => getTeamSettingsData(state));

  // No data available for this user
  const notAllowed = useMemo(() => {
    if (!userStatus || !patientId || profileError) {
      return true;
    }
    if (userStatus === StatusKeyNameEnum.invite_sent) {
      return true;
    }
    if (userStatus === StatusKeyNameEnum.pending) {
      return true;
    }
    return false;
  }, [patientId, userStatus, profileError]);

  // Send request to the database
  useEffect(() => {
    if (!notAllowed) {
      _getProfileById({
        variables: {
          patientId,
        },
      });
    }
  }, [notAllowed, patientId]);

  useEffect(() => {
    if (teamListError) {
      toast.error(teamListError);
    }
  }, [teamListError]);

  useEffect(() => {
    // get Hospital Teams list
    _getPatientRehabTeam({
      searchProps: {
        searchField: 'name',
        searchValue: searchQuery,
      },
      listProps: {
        itemsPerPage: pageSize,
        page: currentPage,
        sortBy: '',
        sortDirection: '',
      },
      patientId,
    });

    return (): void => {
      // Clear open settings state  when componentWillUnmount
      dispatch(setOpenTeamSettingData(null));
      // Clear state when componentWillUnmount
      dispatch(fillPatientProfile(null));
    };
  }, []);

  // Update Hospital Teams list
  const updateTeamList = (): void => {
    _getPatientRehabTeam({
      searchProps: {
        searchField: 'name',
        searchValue: searchQuery,
      },
      listProps: {
        itemsPerPage: pageSize,
        page: currentPage,
        sortBy,
        sortDirection: sortDirectionName,
      },
      patientId,
    });
  };

  // Go to the correspond page
  const onChangePagination = (pagePgin: number, itemsPerPage?: number | undefined): void => {
    setPageSize(itemsPerPage || 15);
    setCurrentPage(pagePgin);
    _getPatientRehabTeam({
      searchProps: {
        searchField: 'name',
        searchValue: searchQuery,
      },
      listProps: {
        itemsPerPage: itemsPerPage || 15,
        page: pagePgin,
        sortBy,
        sortDirection: sortDirectionName,
      },
      patientId,
    });
  };

  // Search and sort
  /* * search for matches in the database * */
  // Set to null search query
  const setSearchMatches = (): void => {
    dispatch(setSearchTeamItems(null));
  };

  // Find search matches in DB
  const findMatches = (searchVal: string): void => {
    if (!searchVal) {
      dispatch(setSearchTeamItems(null));
      return;
    }
    _getPatientTeamMatch({
      searchProps: {
        searchField: 'name',
        searchValue: searchVal,
      },
      listProps: {
        itemsPerPage: 15,
        page: initialPage,
        sortBy: '',
        sortDirection: '',
      },
      patientId,
    });
  };

  // Sort exercise list by name
  const sortByColumnName = (name: string, sortdirection: string): void => {
    setSortby(name);
    setSortDirectionName(sortdirection);
    _getPatientRehabTeam({
      searchProps: {
        searchField: name,
        searchValue: searchQuery,
      },
      listProps: {
        itemsPerPage: pageSize,
        page: currentPage,
        sortBy: name,
        sortDirection: sortdirection,
      },
      patientId,
    });
  };

  // Get search value by team name column
  const handleSearch = (val: string): void => {
    setSearchQuery(val);
    dispatch(setSearchTeamItems(null));
    setHideSearchMenu(!hideSearchMenu);
    if (val) {
      _getPatientRehabTeam({
        searchProps: {
          searchField: 'name',
          searchValue: val,
        },
        listProps: {
          itemsPerPage: pageSize,
          page: initialPage,
          sortBy,
          sortDirection: sortDirectionName,
        },
        patientId,
      });
      setIsSearch(() => true);
      setCurrentPage(initialPage);
    }
  };

  // Reset search exercise name
  const handleReset = (): void => {
    setSearchQuery('');
    dispatch(setSearchTeamItems(null));
    setHideSearchMenu(!hideSearchMenu);
    if (searchQuery) {
      _getPatientRehabTeam({
        searchProps: {
          searchField: 'name',
          searchValue: '',
        },
        listProps: {
          itemsPerPage: pageSize,
          page: initialPage,
          sortBy,
          sortDirection: sortDirectionName,
        },
        patientId,
      });
      setIsSearch(() => false);
      setCurrentPage(initialPage);
    }
  };

  // Redirect to Create Team page
  const openCreateTeam = (): void => {
    history.push({
      pathname: path.createTeam,
      search: location.search,
    });
  };

  // JSX
  const loadingJSX = (profileLoading || teamsLoading || teamsLoadingMatch) && <Loading />;
  const noData = dataTeam?.length === 0 && (
    <div className={style.noData}>{no_my_patient_teams}</div>
  );

  // Breadcrumb path
  const routes = useMemo(
    () => [
      {
        path: isAdmin ? path.adminDashboardList : path.dashboardList,
        breadcrumbName: patient_list,
      },
      {
        path: isAdmin
          ? `${path.patient_overview_admin}${location.search}`
          : `${path.patient_overview}${location.search}`,
        breadcrumbName: profileData ? `${profileData.firstName} ${profileData.lastName}` : '-',
      },
      {
        path: patient_rehab_team,
        breadcrumbName: patient_rehab_team,
      },
    ],
    [t, profileData],
  );
  const createTeamBtnJsx = (
    <Button
      buttonClass={style['rehab-teams__btn']}
      buttonType="button"
      buttonName={create_team}
      disabledButton={!isPermissionCreateTeam || isAdmin}
      buttonMethod={isPermissionCreateTeam || !isHcp ? openCreateTeam : (): null => null}
    />
  );

  return (
    <div className={pageStyles.main__container}>
      {loadingJSX}
      <BreadcrumbAnt currentRoutes={routes} />
      <div className={cx([pageStyles.main__wrapper])}>
        <MainTitle title={patient_rehab_team} icon={<PatientRehabTeamIcon />} />

        <div className={style['rehab-teams__description-container']}>
          <div className={pageStyles['left-section']}>
            <div className={style['rehab-teams__description']}>{you_can_see_list_team}</div>
          </div>

          <Tooltip title={!isPermissionCreateTeam ? you_dont_have_permission : ''}>
            <div
              className={cx({
                [pageStyles['right-section']]: true,
                [style['rehab-teams__btn--bg']]: true,
              })}
            >
              {createTeamBtnJsx}
            </div>
          </Tooltip>
        </div>

        {notAllowed ? (
          <div className={patientStyles['profile-user__notfound']}>{patient_not_found}</div>
        ) : (
          <div className={pageStyles['flex-container']}>
            <div className={pageStyles['left-section']}>
              {/* Team */}
              <MainTitle title={team_t} />
              <HcpTeamTable
                dataTeam={dataTeam}
                sortByColumnName={sortByColumnName}
                sortBy={sortBy}
                handleSearch={handleSearch}
                handleReset={handleReset}
                searchMatches={searchMatches}
                findMatches={findMatches}
                hideSearchMenu={hideSearchMenu}
                isSearch={isSearch}
                setHideSearchMenu={setHideSearchMenu}
                setSearchMatches={setSearchMatches}
                noData={noData}
                pageFromQuery={currentPage}
                pageSize={pageSize}
                itemsTotal={itemsTotal}
                onChangePagination={onChangePagination}
                isPermissionCreateTeam={isPermissionCreateTeam}
                withPagination={false}
              />

              {/* Other HCP */}
              <MainTitle title={other_hcp} />
              {/* Guide */}
              <GuideTitle text={other_hcp_guide} content={<PatientStatusGuide t={t} />} />
              <OtherHcpTable patientId={patientId} />
            </div>

            {/* Settings */}
            <div className={style['rehab-teams__settings--bg']}>
              {!!isOpenSettings && (
                <div className={pageStyles['right-section']}>
                  <TeamSettings
                    unicId="patient-teamSettings"
                    key={isOpenSettings.id}
                    isPermissionCreateTeam={isPermissionCreateTeam}
                    updateTeamList={updateTeamList}
                  />
                </div>
              )}
            </div>
            {/* Sm screen */}
            <Tooltip title={!isPermissionCreateTeam ? you_dont_have_permission : ''}>
              <div className={style['rehab-teams__btn--sm']}>{createTeamBtnJsx}</div>
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  );
};

export default PatientRehabTeamPage;
