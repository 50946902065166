import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import pageStyles from '../../styles.module.css';
import style from './style.module.css';
import { getCurrenLang } from '../../../redux/selector';
import { Loading, MainTitle } from '../../../common';
import { useGetProfessions } from '../../../graphql/professions';
import { useGetHospitalNames } from '../../../graphql/hospitals';
import { useQueryHcpPic, useQueryHcpProfile } from '../../../graphql/hcpProfile';
import { PersonGreyIcon, RedTickIcon } from '../../../theme/icons';
import { useGetDepartments } from '../../../graphql/departments';
import { BreadcrumbAnt } from '../../../utils/routers/Breadcrumb';
import useCheckRole from '../../../hooks/useCheckRole';
import AnotherHcpProfile from '../../../components/Form/AnotherHcpProfile';

const AnotherHcpProfilePage = (): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const saved = t?.common.saved;
  const hcp_not_found = t?.dashboard.admin.profile_hcp.hcp_not_found;
  const hcp_profile = t?.profile_hcp.hcp;

  // Common queris
  const { _getHcpById, hcp, loading, error } = useQueryHcpProfile();
  const { professions, loadingProfessions } = useGetProfessions();
  const { hospitalNames, loadingHospitalNames } = useGetHospitalNames();
  const { departments } = useGetDepartments();

  // Profile queries
  const { _getHcpPic, hcpPic } = useQueryHcpPic();

  const [isProfileUpdated, setProfileUpdated] = useState(false);

  const { isAdmin } = useCheckRole();
  const { params } = useRouteMatch();
  const { hcpId }: any = params;
  const hcpIdFromQuery = String(hcpId);

  useEffect(() => {
    if (hcpIdFromQuery) {
      _getHcpById({
        variables: {
          hcpId: Number(hcpIdFromQuery),
        },
      });
    }
  }, [hcpIdFromQuery]);

  // No data available for this user
  const notAllowed = useMemo(() => {
    if (!hcpIdFromQuery) {
      return true;
    }
    if (!loading && error) {
      return true;
    }
    return false;
  }, [hcpIdFromQuery, loading, error]);

  // Get HCP pic
  useEffect(() => {
    if (hcp?.photo) {
      _getHcpPic(hcp?.photo);
    }
  }, [hcp?.photo]);

  // JSX
  const loadingJSX = (loadingHospitalNames || loading || loadingProfessions) && <Loading />;

  const avatarJsx = (
    <div className={style['profile-user__avatar-container']}>
      {hcpPic ? (
        <img
          src={`data:image/jpeg;charset=utf-8;base64,${hcpPic}`}
          alt="Avatar"
          crossOrigin="anonymous"
        />
      ) : (
        <PersonGreyIcon />
      )}
      {/* <div
        onClick={(): void => openPage(ProfileUserSecondaryPages.CHANGE_PHOTO)}
        aria-hidden
        className={style['profile-user__avatar-backdrop']}
      >
        {hcp?.photo ? edit_photo : add_photo}
      </div> */}
    </div>
  );

  const hcpName = hcp ? `${hcp.firstName} ${hcp.lastName}` : hcp_profile;

  // Breadcrumb path
  const routes = [
    {
      path: hcpName,
      breadcrumbName: hcpName,
    },
  ];

  return (
    <div className={pageStyles.main__container}>
      {loadingJSX}
      <BreadcrumbAnt currentRoutes={routes} />
      <div className={pageStyles.main__wrapper}>
        {/* Profile form */}
        <div className={pageStyles['left-section']}>
          <div className={pageStyles['profile-user__title-container']}>
            <MainTitle title={hcp_profile} icon={avatarJsx} />
            {isProfileUpdated && (
              <div className={pageStyles['profile-user__saved-container']}>
                <RedTickIcon />
                <div className={pageStyles['profile-user__saved']}>{saved}</div>
              </div>
            )}
          </div>
          {notAllowed ? (
            <div className={style['profile-user__notfound']}>{hcp_not_found}</div>
          ) : (
            <></>
          )}
          {!notAllowed && hcp && (
            <AnotherHcpProfile
              hcp={hcp}
              isAdmin={isAdmin}
              hospitalNames={hospitalNames}
              departments={departments}
              professions={professions}
              setProfileUpdated={setProfileUpdated}
              isProfileUpdated={isProfileUpdated}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AnotherHcpProfilePage;
