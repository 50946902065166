import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { toast } from 'react-toastify';

import styles from './styles.module.css';
import { useQueryPhotoPic, useQueryThumbnail, useQueryVideo } from '../../../../graphql/videoBank';
import { EXERCISE_TYPE } from '../../../../utils/enums';
import { ShowMediaIcon, ThumbnailJsx } from '../../../../common/ShowMediaIcon';
import { VideoModal } from '../../../../common';
import { PhotoSliderModal } from '../../../../common/VideoModal';
import { ExerciseFileType } from '../../../../utils/model';

const MediaItem = ({ data, isModal = false }: any): JSX.Element => {
  // get image from AWS by Uuid
  const { _getThumbnailByName, thumbnail } = useQueryThumbnail();

  // get photo link from AWS by Uuid
  // const { _getPhotoLink, photoLink } = useQueryPhotoLink();

  // get photo picture from AWS by Uuid
  const { _getPhotoPic, photoPic } = useQueryPhotoPic();

  // get video from AWS by uuid
  const { _getVideoByName, videoSrc, errorVideo } = useQueryVideo();
  // const [isUploadingToAWS, setIsUploadingToAWS] = useState(false);

  // Local state
  const [isVideoModalOpen, setVideoModal] = useState(false);
  const [isPhotoModalOpen, setPhotoModal] = useState(false);
  const [videoSrcState, setVideoSrcState] = useState<any>('');

  const exerciseFileType: ExerciseFileType = data.type;
  const isPhotosExist = !!data.exercisePhotos?.length;
  const exercisePhotos = isPhotosExist ? data.exercisePhotos : [];

  // Get thumbnail
  useEffect(() => {
    if (data.thumbnailName) {
      _getThumbnailByName({
        variables: {
          thumbnailKey: data.thumbnailName,
        },
      });
      return;
    }
    if (isPhotosExist) {
      _getPhotoPic({
        variables: {
          pictureUuid: exercisePhotos[0].fileUuid,
        },
      });
      // return;
    }
    // if (!data.thumbnailName && !data.videoName && !data.exerciseVideoUploaded && !isPhotosExist) {
    //   setIsUploadingToAWS(() => true);
    // } else {
    //   setIsUploadingToAWS(() => false);
    // }
  }, [data.thumbnailName, data.exercisePhotos]);

  // Open video modal window
  useEffect(() => {
    if (videoSrc) {
      setVideoSrcState(videoSrc);
      setVideoModal(() => true);
    }
  }, [videoSrc]);

  useEffect(() => {
    if (errorVideo) {
      toast.error(errorVideo);
    }
  }, [errorVideo]);

  // Video modal window handler
  const showMedia = (): void => {
    switch (exerciseFileType) {
      case EXERCISE_TYPE.VIDEO:
        if (data.videoName) {
          _getVideoByName({
            variables: {
              videoKey: data.videoName,
            },
          });
        }
        return;
      case EXERCISE_TYPE.PHOTO:
        if (photoPic) {
          setPhotoModal(() => !isPhotoModalOpen);
        }
        return;
      default:
        setPhotoModal(() => false);
    }
  };

  // Close open modal windows
  const onCloseModal = (): void => {
    setVideoModal(() => false);
    setPhotoModal(() => false);
  };

  const isMediaExist = thumbnail || data.videoName || isPhotosExist;

  return (
    <div className={styles['exercise-lib-item__row']}>
      {/* Thumbnail */}
      <div
        className={cx({
          [styles['exercise-lib-item__block']]: true,
          [styles['exercise-lib-item__block--modal']]: isModal,
        })}
      >
        <div
          className={cx({
            [styles.preview]: true,
            [styles['preview-not-allowed']]: !isMediaExist,
          })}
          role="presentation"
          onClick={isMediaExist ? showMedia : (): null => null}
        >
          <ThumbnailJsx thumbLink={thumbnail} photoPic={photoPic} />
          <ShowMediaIcon thumbLink={thumbnail} photoPic={photoPic} />
        </div>
      </div>
      {/* Name */}
      <div className={styles['exercise-lib-item__exercise-name-container']}>
        <div
          className={cx({
            [styles['exercise-lib-item__name']]: true,
            [styles['exercise-lib-item__name--modal']]: isModal,
          })}
        >
          {data.name}
        </div>
      </div>

      {/* Popups */}
      {/* Video content */}
      {isVideoModalOpen && <VideoModal videoSrc={videoSrcState} onclose={onCloseModal} />}
      {/* Image content */}
      {isPhotoModalOpen && (
        <PhotoSliderModal
          exercisePhotos={isPhotosExist ? exercisePhotos : []}
          photoPreviews={data.photoPreviews ? data.photoPreviews : []}
          onclose={onCloseModal}
        />
      )}
    </div>
  );
};

export default MediaItem;
