import React, { Fragment, ReactElement } from 'react';
import { useSelector } from 'react-redux';

import styles from './styles.module.css';
import { CHART_TABS } from '../../utils/enums';
import { getEqValueForLegend } from '../../redux/selector';
import { capitalizeFirstLetter } from '../../utils/helper';

export interface TooltipRechartsHint {
  dataKey: string;
  name: string;
  fill: string;
  value: number;
  stroke?: string;
}
export interface TooltipRechartsMob {
  color: string;
  name: string;
  value: string;
}

export const TooltipRecharts = ({ payload, type, t }: any): ReactElement => {
  const times = t?.dashboard.hcp.profile_patient.completion_and_steps.times;
  const time = t?.dashboard.hcp.profile_patient.completion_and_steps.time;
  const steps_t = t?.dashboard.hcp.profile_patient.completion_and_steps.steps;
  const motivation_level =
    t?.dashboard.hcp.profile_patient.completion_and_steps.daily_motivation_level;
  const exercise_completion_t = t?.hcp.manage.exercise_completion;

  const textKey: any = {
    valueCompleted: t?.dashboard.hcp.profile_patient.completion_and_steps.average_pain,
    valueSkippedStopped: t?.dashboard.hcp.profile_patient.completion_and_steps.skip_stop_value,
    valueBackCompleted:
      t?.dashboard.hcp.profile_patient.completion_and_steps.av_back_pain_score_completed,
    valueBackSkippedStopped:
      t?.dashboard.hcp.profile_patient.completion_and_steps.av_back_pain_score_skip_stop,
    valueLegCompleted:
      t?.dashboard.hcp.profile_patient.completion_and_steps.av_leg_pain_score_completed,
    valueLegSkippedStopped:
      t?.dashboard.hcp.profile_patient.completion_and_steps.av_leg_pain_score_skip_stop,
    maxHeartRate: t?.dashboard.hcp.profile_patient.completion_and_steps.max_heart_rate,
    minHeartRate: t?.dashboard.hcp.profile_patient.completion_and_steps.min_heart_rate,
    averageHeartRate: t?.dashboard.hcp.profile_patient.completion_and_steps.av_heart_rate,
    restingHeartRate: t?.dashboard.hcp.profile_patient.completion_and_steps.rest_heart_rate,
  };

  const result = payload && type === CHART_TABS.reasons ? payload.reverse() : payload;

  const getPainHint = (hint: TooltipRechartsHint) => {
    return `${textKey[hint.name]}: ${hint.value}`;
  };

  const getUnit = (hint: TooltipRechartsHint): any => {
    switch (type) {
      case CHART_TABS.exercise_completion:
        return `${exercise_completion_t}: ${hint.value}%`;
      case CHART_TABS.pain:
      case CHART_TABS.heart_rate:
        return getPainHint(hint);
      case CHART_TABS.step_count:
        return `${hint.value} ${steps_t}`;
      case CHART_TABS.motivation:
        return `${motivation_level}: ${hint.value}`;
      case CHART_TABS.skipStop:
        if (hint.value === 1) {
          return `${hint.name}: ${hint.value} ${time}`;
        }
        return `${hint.name}: ${hint.value} ${times}`;

      case CHART_TABS.promsQD: {
        let hintValue = '';
        if (typeof hint.value === 'number') {
          hintValue = String(100 - hint.value);
        } else {
          hintValue = hint.value;
        }

        return `${hint.name}: ${hintValue}`;
      }

      default:
        return `${hint.name}: ${hint.value}`;
    }
  };
  return (
    <div className={styles.tooltip__container}>
      {payload &&
        result.map(
          (hint: TooltipRechartsHint, i: number): JSX.Element => {
            let isShowZeroValue = true;
            if (type === CHART_TABS.motivation || type === CHART_TABS.pain) {
              isShowZeroValue = true;
            } else if (hint.value === 0) {
              isShowZeroValue = false;
            }
            if (isShowZeroValue) {
              return (
                <div
                  className={styles.tooltip__valueBlock}
                  style={{ color: hint.stroke ? hint.stroke : hint.fill }}
                  key={`${hint.dataKey}_${String(i)}`}
                >
                  {getUnit(hint)}
                </div>
              );
            }
            return <></>;
          },
        )}
    </div>
  );
};

// PROMS EQ chart
export const TooltipEqMob = (): ReactElement => {
  const tooltipValue: any = useSelector<any>((state) => getEqValueForLegend(state));
  return (
    <div className={styles['tooltip__container--mobile']}>
      {tooltipValue?.length > 0 ? (
        tooltipValue.map(
          (label: TooltipRechartsMob): JSX.Element => (
            <div className={styles['tooltip__row--mobile']} key={label.name}>
              <div className={styles['tooltip__name-cont--mobile']}>
                <div
                  className={styles['tooltip__marker--mobile']}
                  style={{ backgroundColor: label.color }}
                />
                <div className={styles['tooltip__name--mobile']} style={{ whiteSpace: 'nowrap' }}>
                  {label.name}
                </div>
              </div>
              <div>{label.value}</div>
            </div>
          ),
        )
      ) : (
        <></>
      )}
    </div>
  );
};

export const TooltipSleep = ({ payload, t }: any): ReactElement => {
  const start_t = t?.dashboard.hcp.profile_patient.video_bank.start;
  const end_t = t?.dashboard.hcp.profile_patient.video_bank.end;
  const start_label = capitalizeFirstLetter(start_t);
  const end_label = capitalizeFirstLetter(end_t);

  const getUnit = (hint: any): any => {
    const convertDateToMinutes = (timeInMinutes: number): string => {
      let hours: number | string = Math.trunc(timeInMinutes / 60);
      let minutes: number | string = timeInMinutes - hours * 60;

      if (hours < 10) {
        hours = `0${hours}`;
      }
      if (minutes < 10) {
        minutes = `0${minutes}`;
      }
      return `${hours}:${minutes}`;
    };

    const startDate = convertDateToMinutes(hint.payload.startTimeInMinutes);
    const endDate = convertDateToMinutes(hint.payload.endTimeInMinutes);
    return (
      <>
        <div
          className={styles.tooltip__valueBlock}
          style={{ color: hint.stroke ? hint.stroke : hint.fill }}
        >
          {`${end_label}: ${endDate}`}
        </div>
        <div
          className={styles.tooltip__valueBlock}
          style={{ color: hint.stroke ? hint.stroke : hint.fill }}
        >
          {`${start_label}: ${startDate}`}
        </div>
      </>
    );
  };

  return (
    <div className={styles.tooltip__container}>
      {payload &&
        payload.map(
          (hint: any, i: number): JSX.Element => {
            if (hint.value && hint.value[0] && hint.value[1]) {
              return (
                <div
                  className={styles.tooltip__valueBlock}
                  style={{ color: hint.stroke ? hint.stroke : hint.fill }}
                  key={`${hint.payload.name}${String(i)}`}
                >
                  {getUnit(hint)}
                </div>
              );
            }
            return <Fragment key={`hint${String(i)}`} />;
          },
        )}
    </div>
  );
};
