import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, CloseBtn } from '../Button/Button';
import { CloseIcon, WarningIcon, SuccessIcon } from '../../theme/icons';
import { getCurrenLang } from '../../redux/selector';
import styles from './styles.module.css';
import {
  NotificationModalProps,
  NotificationSucceessProps,
  WarningNotificationProps,
  WarnNotifModalProps,
  SuccessNotifModalProps,
} from './types';
import { Modal } from '../Modal';

export const NotificationModal = ({
  title,
  contant,
  onResend,
  buttonName,
  hasButton,
  redirect,
  redirectTitle,
}: NotificationModalProps): ReactElement => (
  <div className={styles.container}>
    <div className={styles.icon}>
      <CloseIcon style={{ height: '46px' }} />
    </div>

    {title && <div className={styles.message}>{title}</div>}
    {contant && !redirect && <div className={styles.contant}>{contant}</div>}
    {contant && redirect && (
      <div className={styles.redirect}>
        <div className={styles.contant_senter}>
          {contant}{' '}
          <Link to={redirect} className={styles.redirect_link}>
            {redirectTitle}
          </Link>
        </div>
      </div>
    )}
    {hasButton && (
      <div className={styles.wrapper}>
        <Button
          buttonType="submit"
          buttonName={buttonName}
          buttonMethod={onResend}
          buttonClass={styles.btn__submit}
        />
      </div>
    )}
  </div>
);

export const WarningNotification = ({
  title,
  contant,
  onCancel,
  onSubmit,
  buttonName,
  foreignStyle,
  foreignBtnStyle,
  cancelButtonName,
  disabledBtn = false,
  contentStyle,
}: WarningNotificationProps): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const cancel = t?.common.cancel;
  return (
    <div className={foreignStyle || styles.container}>
      {title && <div className={styles.message}>{title}</div>}
      <div className={styles.warn_icon}>
        <img alt="User avatar" height="55" src={WarningIcon} width="55" />
      </div>

      {contant && <div className={contentStyle || styles.warn_contant}>{contant}</div>}
      <div className={styles.warn_wrapper}>
        <Button
          disabledButton={disabledBtn}
          buttonType="button"
          buttonName={cancelButtonName || cancel}
          buttonMethod={onCancel}
          buttonClass={foreignBtnStyle || styles.warn__btn}
        />
        <Button
          disabledButton={disabledBtn}
          buttonType="submit"
          buttonName={buttonName}
          buttonMethod={onSubmit}
          buttonClass={foreignBtnStyle || styles.warn__btn}
        />
      </div>
    </div>
  );
};

export const NotificationSucceess = ({
  title,
  contant,
}: NotificationSucceessProps): ReactElement => (
  <div className={styles.success__container}>
    <div className={styles.success__icon}>
      <SuccessIcon style={{ width: '54px', height: '54px' }} />
    </div>
    {title && <div className={styles.message}>{title}</div>}
    {contant && <div className={styles.success_contant}>{contant}</div>}
  </div>
);

export const WarnNotifModal = ({
  onClose,
  content,
  cancelBtnName,
  actionBtnName,
  actionMethod,
  disableAction = false,
}: WarnNotifModalProps): ReactElement => (
  <Modal onClose={(): void => onClose(false)} style={styles['warn-modal']}>
    <CloseBtn close={onClose} />
    <div className={styles['warn-modal__icon']}>
      <img alt="Warning" width="44" height="44" src={WarningIcon} />
    </div>
    {content}
    <div className={styles['warn-modal__btns-container']}>
      <Button
        buttonName={actionBtnName}
        buttonType="button"
        buttonClass={styles['warn-modal__btn']}
        buttonMethod={actionMethod}
        disabledButton={disableAction}
      />
      <Button
        buttonName={cancelBtnName}
        buttonType="button"
        buttonClass={styles['warn-modal__btn']}
        buttonMethod={onClose}
      />
    </div>
  </Modal>
);

export const WarnNotifModalOneButton = ({
  onClose,
  content,
  btnName,
  description,
}: SuccessNotifModalProps): ReactElement => (
  <Modal onClose={(): void => onClose(false)} style={styles['warn-modal']}>
    <CloseBtn close={onClose} />
    <div className={styles['warn-modal__icon']}>
      <img alt="Warning" width="44" height="44" src={WarningIcon} />
    </div>
    {content || ''}
    {description ? <div className={styles['warn-modal__content']}>{description}</div> : ''}
    <div className={styles['warn-modal__btns-container']}>
      <Button
        buttonName={btnName}
        buttonType="button"
        buttonClass={styles['warn-modal__btn']}
        buttonMethod={onClose}
      />
    </div>
  </Modal>
);

export const SuccessNotifModal = ({
  onClose,
  content,
  btnName,
  description,
}: SuccessNotifModalProps): ReactElement => (
  <Modal onClose={(): void => onClose(false)} style={styles['warn-modal']}>
    <CloseBtn close={onClose} />
    <div className={styles['warn-modal__icon']}>
      <SuccessIcon style={{ width: '44px', height: '44px' }} />
    </div>
    {content || ''}
    {description ? <div className={styles['warn-modal__content']}>{description}</div> : ''}
    <div className={styles['warn-modal__btns-container']}>
      <Button
        buttonName={btnName}
        buttonType="button"
        buttonClass={styles['warn-modal__btn']}
        buttonMethod={onClose}
      />
    </div>
  </Modal>
);
