import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';

import { getCurrenLang, GetState } from '../../redux/selector';
import { validationEmail } from '../../utils/validators';
import { EditEmailProps } from './types';
import { InputFloatLabelWithButton } from '../../common/Input';

export const EditEmail = ({
  email,
  setPatientEmail,
  setEditMode,
}: EditEmailProps): ReactElement => {
  const t: any = useSelector<GetState>((state) => getCurrenLang(state));
  const email_t = t?.auth.email;

  const formik = useFormik({
    initialValues: { email },
    validationSchema: () => validationEmail(t),
    onSubmit: (values: any) => {
      setPatientEmail(values.email);
      setEditMode(false);
    },
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
      style={{
        display: 'flex',
      }}
    >
      <InputFloatLabelWithButton
        inputName="email"
        labelName=""
        placeholder={email_t}
        hasErrors={formik.errors.email}
        inputValue={formik.values.email}
        isTouched={formik.touched.email}
        onChecngeMethod={formik.handleChange}
        inputId="Email"
        isButton
        buttonName="ok"
      />
    </form>
  );
};
