// Type
const FILL_PATIENT_INVITATION_DATA = 'FILL_PATIENT_INVITATION_DATA';
const SET_PATIENT_INVITATION_LOADING = 'SET_PATIENT_INVITATION_LOADING';
const SET_PATIENT_INVITATION_ERROR = 'SET_PATIENT_INVITATION_ERROR';

// TS
export interface PatientsInvitationStoreType {
  patientsInvitations: any;
  patientsInvitationsLoading: false;
  patientsInvitationsError: undefined | any;
  total: number;
}
interface LoadingType {
  type: string;
  payload: boolean;
}
interface ErrorType {
  type: string;
  payload: any;
}
interface PatientsInvitationDataPayType {
  type: string;
  payload: any;
}
type ActionTypes = ErrorType | LoadingType | PatientsInvitationDataPayType;

// Action
export const setPatientsInvitationData = (payload: any): PatientsInvitationDataPayType => ({
  type: FILL_PATIENT_INVITATION_DATA,
  payload,
});
export const setPatientsInvitationLoading = (payload: boolean): LoadingType => ({
  type: SET_PATIENT_INVITATION_LOADING,
  payload,
});
export const setPatientsInvitationError = (payload: any): ErrorType => ({
  type: SET_PATIENT_INVITATION_ERROR,
  payload,
});

// Initial comon STATE
const init: PatientsInvitationStoreType = {
  patientsInvitations: undefined,
  patientsInvitationsLoading: false,
  patientsInvitationsError: undefined,
  total: 0,
};

// Reducer
export const patientsInvitationReducer = (
  state = init,
  { type, payload }: ActionTypes,
): PatientsInvitationStoreType => {
  switch (type) {
    case FILL_PATIENT_INVITATION_DATA:
      return {
        ...state,
        patientsInvitations: payload.list,
        total: payload.totalItems,
      };
    case SET_PATIENT_INVITATION_LOADING:
      return {
        ...state,
        patientsInvitationsLoading: payload,
      };
    case SET_PATIENT_INVITATION_ERROR:
      return {
        ...state,
        patientsInvitationsError: payload,
      };
    default:
      return state;
  }
};
