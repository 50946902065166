import React, { ReactElement } from 'react';
import { sanitize } from 'dompurify';
import moment from 'moment';
import cx from 'classnames';

import { AofasLogo } from '../../../../../theme/icons';
import { getPatientAnswers } from '../../../../../utils/helper_charts';
import style from './style.module.css';
import { Answer, PcsContentProps, Question } from './types';
import { RemoveQuestionnaire } from './common/RemoveQuestionnaire';
import { CalculationDescription } from './common/Calculation';
import { PROMS_NAME } from '../../../../../utils/enums';

export const AOFASContent = ({
  questionnairesList,
  completedQuestionnaire,
  patient,
  index,
  onDeleteItem,
  formatDate,
  t,
}: PcsContentProps): ReactElement => {
  const remove_questionnaire = t?.dashboard.hcp.profile_patient.questionnaires.remove_questionnaire;
  const ankle_hindfoot_scale = t?.dashboard.hcp.profile_patient.questionnaires.ankle_hindfoot_scale;
  const aofas_name = t?.dashboard.hcp.profile_patient.questionnaires.aofas_name;
  const patient_name = t?.dashboard.hcp.profile_patient.questionnaires.patient_name;
  const patient_MRN = t?.dashboard.hcp.profile_patient.questionnaires.patient_MRN;
  const date = t?.dashboard.hcp.profile_patient.questionnaires.date;
  const pain_points = t?.dashboard.hcp.profile_patient.questionnaires.pain_points;
  const function_points = t?.dashboard.hcp.profile_patient.questionnaires.function_points;
  const alignment_points = t?.dashboard.hcp.profile_patient.questionnaires.alignment_points;
  const total_points = t?.dashboard.hcp.profile_patient.questionnaires.total_points;
  const aofas_one_pain = t?.dashboard.hcp.profile_patient.questionnaires.aofas_one_pain;
  const aofas_two_function = t?.dashboard.hcp.profile_patient.questionnaires.aofas_two_function;
  const aofas_three_alignment =
    t?.dashboard.hcp.profile_patient.questionnaires.aofas_three_alignment;
  const aofas_four_total_core =
    t?.dashboard.hcp.profile_patient.questionnaires.aofas_four_total_core;
  const aofas_footer = t?.dashboard.hcp.profile_patient.questionnaires.aofas_footer;

  const { questions: completedQuestions, completedDate } = completedQuestionnaire;
  const patientAnswers = getPatientAnswers(completedQuestions);
  const patientInfoConfig = [
    { name: patient_name, value: (patient && `${patient.firstName} ${patient.lastName}`) || '' },
    { name: patient_MRN, value: '' },
    { name: date, value: (completedDate && moment(completedDate).format(formatDate)) || '' },
  ];
  const totalScoreConfig = [
    { name: pain_points, value: 0 },
    { name: function_points, value: 0 },
    { name: alignment_points, value: 0 },
    { name: '', value: 0 },
    { name: total_points, value: 0 },
  ];
  const pointsConfig = [
    [40, 30, 20, 0],
    [10, 7, 4, 0],
    [5, 4, 2, 0],
    [5, 3, 0],
    [8, 4, 0],
    [8, 4, 0],
    [6, 3, 0],
    [8, 0],
    [10, 5, 0],
  ];

  const AOFASList =
    questionnairesList &&
    questionnairesList.filter((item: any) => item.name === PROMS_NAME.AOFAS)[0];
  const { questions } = AOFASList;
  const getSectionTitle = (i: number): string => {
    switch (i) {
      case 0:
        return aofas_one_pain;
      case 1:
        return aofas_two_function;
      case 8:
        return aofas_three_alignment;
      case 9:
        return aofas_four_total_core;
      default:
        return '';
    }
  };

  return (
    <>
      <RemoveQuestionnaire onMethod={(): any => onDeleteItem(index)} title={remove_questionnaire} />
      <div className="questionnaire-to-PDF">
        <div
          className={cx({
            [style.quickDash__container]: true,
            [style.description__container]: true,
          })}
        >
          <CalculationDescription
            name={aofas_name}
            content=""
            date={completedDate ? moment(completedDate).format(formatDate) : ''}
            t={t}
          />
        </div>
      </div>
      <div className="questionnaire-to-PDF">
        <div className={style.aofas__container}>
          <div className={style.aofas__header}>
            <div className={style['aofas__header-title-container']}>
              <div className={style['aofas__header-title']}>{ankle_hindfoot_scale}</div>
              {patientInfoConfig.map((info: { name: string; value: string }) => (
                <div key={info.name} className={style['aofas__header-patient-info-cont']}>
                  <div className={style['aofas__header-patient-info-name']}>{info.name}:</div>
                  <div className={style['aofas__header-patient-info-value']}>{info.value}</div>
                </div>
              ))}
            </div>
            <div className={style['aofas__header-logo-container']}>
              <img src={AofasLogo} alt="logo" />
            </div>
          </div>
          {/* BODY */}
          <div className={style['aofas__body-container']}>
            {questions.map((question: Question, i: number) => (
              <div key={`aofas${question.name}${String(i)}`} className={style['aofas__body-item']}>
                <div className={style['aofas__body-item-section-title']}>{getSectionTitle(i)}</div>
                <div
                  className={style.aofas__question}
                  dangerouslySetInnerHTML={{ __html: sanitize(question.name) }}
                />
                {question.answers.map((answ: Answer, ind: number) => {
                  if (patientAnswers[question.id] === answ.id) {
                    if (i === 0) {
                      totalScoreConfig[0].value += pointsConfig[i][ind];
                    }
                    if (i > 0 && i < 8) {
                      totalScoreConfig[1].value += pointsConfig[i][ind];
                    }
                    if (i === 8) {
                      totalScoreConfig[2].value += pointsConfig[i][ind];
                    }
                    totalScoreConfig[4].value =
                      totalScoreConfig[0].value +
                      totalScoreConfig[1].value +
                      totalScoreConfig[2].value;
                  }
                  return (
                    <div key={answ.name} className={style['aofas__answer-row']}>
                      <div className={style['aofas__answer-sqr']}>
                        {patientAnswers[question.id] === answ.id && <>&#10004;</>}
                      </div>
                      <div
                        className={style.aofas__answer}
                        dangerouslySetInnerHTML={{ __html: sanitize(answ.name) }}
                      />
                      <div className={style['aofas__answer-point']}>+{pointsConfig[i][ind]}</div>
                    </div>
                  );
                })}
              </div>
            ))}
            <div className={style['aofas__body-item']}>
              <div className={style['aofas__body-item-section-title']}>{getSectionTitle(9)}</div>
              {totalScoreConfig.map((point: { name: string; value: number }, i: number) => {
                if (i === 3) {
                  return <div key={point.name} className={style['aofas__body-line']} />;
                }
                return (
                  <div key={point.name} className={style['aofas__body-total-score-cont']}>
                    <div className={style['aofas__body-total-score-value']}>{point.value}</div>
                    <div className={style['aofas__body-total-score-name']}>{point.name}</div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className={style.aofas__footer}>{aofas_footer}</div>
        </div>
      </div>
    </>
  );
};
