import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import pageStyles from '../../styles.module.css';
import styles from './styles.module.css';
import { getCurrenLang, GetState, getUserLang } from '../../../redux/selector';
import { BreadcrumbAnt } from '../../../utils/routers/Breadcrumb';
import { Loading, MainTitle } from '../../../common';
import SearchClient from '../../../components/Search/SearchClient';
import AddNewItem from '../../../components/ManageProfessions/AddNewItemJsx';
import { AddNewProfessionForm } from '../../../components/ManageProfessions/AddNewProfessionForm';
import { executeScroll, initialPage, initialSizePage } from '../../../utils/helper';
import useDebounce from '../../../hooks/useDebounce';
import { useQueryLangWithValue } from '../../../graphql/lang';
import {
  useCreateProfession,
  useDeleteProfession,
  useProfessionsListByAdmin,
} from '../../../graphql/professions';
import { CreateProfessionData } from '../../../graphql/types';
import { WarnNotifModal } from '../../../common/NotificationModal';
import ListItems from '../../../components/ManageProfessions/ListItems';

const ManageProfessionsPage = (): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const cancel_t = t?.common.cancel;
  const manage_professions = t?.menu.manage_professions;
  const placeholder = t?.invite_patient.please_enter_min_3_characters;
  const delete_t = t?.common.delete;
  const professions_list = t?.common.professions_list;
  const add_new_prof = t?.title.add_new_prof;
  const no_data = t?.common.no_data;
  const this_action_cannot_be_undone = t?.notifications.action_cannot_be_undone;
  const are_you_sure_you_want_to_delete = t?.notifications.are_you_sure_you_want_to_delete;
  const profession_t = t?.dashboard.admin.table.hcp.profession;
  const enter_profession = t?.title.enter_profession;
  const description_t = t?.title.description_profession;
  const texts = {
    subTitle: profession_t,
    description: description_t,
    placeholder: enter_profession,
  };

  const userLang: any = useSelector<GetState>((state) => getUserLang(state));
  const initCandidat = { id: 0, type: '' };
  const sectionRef: any = useRef(null);

  // Local state
  const [pageSize, setPageSize] = useState<number>(initialSizePage);
  const [currentPage, setCurrentPage] = useState<number>(initialPage);
  const [isModalOpen, setModal] = useState<boolean>(false);
  const [isDelete, setDelete] = useState<boolean>(false);
  const [professions, setProfessions] = useState([]);
  const [totalItemsState, setTotalItemsState] = useState(0);
  const [candidat, setCandidat] = useState(initCandidat);
  const [filter, setFilter] = useState<string>('');
  const [filterQuery, setFilterQuery] = useState<any>(null);

  // Queris and mutations
  const {
    _getProfessionsListByAdmin,
    professionsList,
    totalItems,
    error,
    loading,
  } = useProfessionsListByAdmin();

  const {
    _createProfession,
    createProfError,
    createProfLoading,
    professionCreated,
  } = useCreateProfession();

  const {
    _deleteProfession,
    professionDeleted,
    deleteProfError,
    deleteProfLoading,
  } = useDeleteProfession();

  const { _getLang, lang, error: errorLang, loading: loadingLang } = useQueryLangWithValue();

  // Create new profession
  const onSave = (professionData: any, config: CreateProfessionData[]): void => {
    const request = config.map((item: any) => ({
      langId: item.id,
      name: professionData[item.shortCountryName]
        ? professionData[item.shortCountryName]
        : professionData.UK,
    }));
    _createProfession(request);
  };

  // Get professions list from DB
  const getList = (page: number, itemsPerPage: number): void => {
    _getProfessionsListByAdmin({
      variables: {
        listProps: {
          page,
          itemsPerPage,
        },
        searchProps: filterQuery || [
          {
            searchField: 'type',
            searchValue: '',
          },
        ],
      },
    });
  };

  useEffect(() => {
    getList(initialPage, pageSize);
    setCurrentPage(initialPage);
    _getLang({
      variables: {
        langCode: userLang,
      },
    });
  }, []);

  // Scroll to elem
  useEffect(() => {
    if (isModalOpen) {
      executeScroll(sectionRef);
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (professionsList && !loading) {
      setProfessions(professionsList);
      setTotalItemsState(totalItems);
    }
  }, [professionsList]);

  useEffect(() => {
    if (professionCreated) {
      setModal(() => false);
      getList(currentPage, pageSize);
    }
  }, [professionCreated]);

  // Get profession list after search
  useEffect(() => {
    getList(initialPage, pageSize);
    setCurrentPage(initialPage);
  }, [filterQuery]);

  const debounceValue = useDebounce(filter, 300);

  // Get search result from DB after delay
  useEffect(() => {
    if (debounceValue.length >= 2) {
      setFilterQuery([{ searchField: 'type', searchValue: debounceValue }]);
    } else {
      setFilterQuery(null);
    }
  }, [debounceValue]);

  // Search patient or HCP
  const changeFilter = ({ target: { value } }: any): void => {
    setFilter(value);
  };

  // Show error message from getProfessionsList
  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  useEffect(() => {
    if (professionDeleted) {
      getList(currentPage, pageSize);
    }
    setDelete(() => false);
    setCandidat(() => initCandidat);
  }, [professionDeleted]);

  // Show error message from delete Profession
  useEffect(() => {
    if (deleteProfError) {
      toast.error(deleteProfError);
    }
  }, [deleteProfError]);

  // Pagination
  const onChangePagination = (page: number, size: number | undefined): void => {
    let paginPageSize = pageSize;
    let paginPage = page;
    if (size && size !== pageSize) {
      setPageSize(size);
      paginPageSize = size;
      paginPage = initialPage;
    }
    getList(paginPage, paginPageSize);
    setCurrentPage(paginPage);
  };

  const onOpenModal = (): void => {
    setModal(() => true);
  };
  const onCloseModal = (): void => {
    setModal(() => false);
  };
  const removeProfession = (profId: number): void => {
    _deleteProfession(profId);
  };

  // Breadcrumb path
  const routes = [
    {
      path: manage_professions,
      breadcrumbName: manage_professions,
    },
  ];
  const loadingJSX = (createProfLoading || loading || deleteProfLoading) && <Loading />;
  const deleteContentJsx = (
    <div>
      <div className={styles['delete-content']}>
        {`${are_you_sure_you_want_to_delete} ${candidat.type}?`}
      </div>
      <div className={styles['delete-content']}>{this_action_cannot_be_undone}</div>
    </div>
  );

  return (
    <div className={pageStyles.main__container}>
      <BreadcrumbAnt currentRoutes={routes} />
      <div className={pageStyles.main__wrapper}>
        <div className={pageStyles['flex-container']}>
          <div className={pageStyles['left-section']}>
            {loadingJSX}
            <MainTitle title={manage_professions} />

            {/* SEARCH */}
            <div className={styles['search-container']}>
              <SearchClient
                inputId="professionsSearch"
                name="search"
                placeholder={placeholder}
                inputValue={filter}
                changeFilter={changeFilter}
              />
            </div>

            {/* Add new Item */}
            <AddNewItem label={add_new_prof} actionMethod={onOpenModal} disable={isModalOpen} />

            <ListItems
              label={professions_list}
              srcList={professions}
              setCandidat={setCandidat}
              setDelete={setDelete}
              currentPage={currentPage}
              pageSize={pageSize}
              totalItemsState={totalItemsState}
              onChangePagination={onChangePagination}
              no_data={no_data}
            />
          </div>
          {/* Add new Profession */}
          {isModalOpen && (
            <div className={pageStyles['right-section']} ref={sectionRef}>
              <MainTitle title={add_new_prof} />
              {!errorLang && !loadingLang && (
                <AddNewProfessionForm
                  lang={lang}
                  onSave={onSave}
                  createLoading={createProfLoading}
                  createError={createProfError}
                  onCloseModal={onCloseModal}
                  texts={texts}
                  t={t}
                />
              )}
              {errorLang && <div className={styles['modal__manage-org-title']}>{errorLang}</div>}
            </div>
          )}
        </div>
      </div>

      {isDelete && (
        <WarnNotifModal
          onClose={(): void => setDelete(() => false)}
          content={deleteContentJsx}
          cancelBtnName={cancel_t}
          actionBtnName={delete_t}
          actionMethod={(): void => removeProfession(candidat.id)}
        />
      )}
    </div>
  );
};

export default ManageProfessionsPage;
