import React, { ReactElement } from 'react';

import styles from '../styles.module.css';
import { SelectIcon } from '../../../theme/icons';
import { userStatusColor } from '../../../utils/enums';
import { StatusItemtype } from '../types';

const PatientStatusGuide = ({ t }: any): ReactElement => {
  const connected = t?.common.status_connected;
  const invite_sent = t?.common.status_invite_sent;
  const pending = t?.common.status_pending;
  const disconnected = t?.common.status_disconnected;
  const treatment_completed = t?.common.status_treatment_completed;
  const declined = t?.common.status_declined;
  const guide_title = t?.dashboard.hcp.table.guide_title;
  const guide_connected = t?.dashboard.hcp.table.guide_connected;
  const guide_invite_sent = t?.dashboard.hcp.table.guide_invite_sent;
  const guide_pending = t?.dashboard.hcp.table.guide_pending;
  const guide_disconnected = t?.dashboard.hcp.table.guide_disconnected;
  const guide_treatment_completed = t?.dashboard.hcp.table.guide_treatment_completed;
  const guide_declined = t?.dashboard.hcp.table.guide_declined;

  const config = [
    { description: guide_connected, label: connected, color: userStatusColor.connected },
    { description: guide_invite_sent, label: invite_sent, color: userStatusColor.invite_sent },
    { description: guide_pending, label: pending, color: userStatusColor.pending },
    { description: guide_disconnected, label: disconnected, color: userStatusColor.disconnected },
    {
      description: guide_treatment_completed,
      label: treatment_completed,
      color: userStatusColor.treatment_completed,
    },
    { description: guide_declined, label: declined, color: userStatusColor.declined },
  ];

  return (
    <div className={styles['modal__guide-container']}>
      <div className={styles['modal__guide-title']}>{guide_title}</div>
      {config.map((statusItem: StatusItemtype) => (
        <div className={styles['modal__guide-item-container']} key={statusItem.description}>
          <div className={styles['modal__guide-item-status-container']}>
            <div
              className={styles['modal__guide-item-status-color']}
              style={{ backgroundColor: statusItem.color }}
            />
            <div className={styles['modal__guide-item-status-label']}>{statusItem.label}</div>
            <SelectIcon />
          </div>
          <div className={styles['modal__guide-item-status-description']}>
            {statusItem.description}
          </div>
        </div>
      ))}
    </div>
  );
};

export default PatientStatusGuide;
