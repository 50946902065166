/* eslint-disable @typescript-eslint/ban-ts-ignore */
import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  ResponsiveContainer,
  ComposedChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Line,
} from 'recharts';
import cx from 'classnames';

import styles from '../styles.module.css';
import { getCurrenLang, getPainLevelLoading } from '../../../redux/selector';
import { EmojiYAxisTick } from '../../../utils/helper_charts';
import { TBar } from './types';
import { TooltipRecharts } from '../../../common/TooltipRecharts';
import { spinner } from '../../../common/Loader';
import { PERIOD_FOR_CHART, proceduresEnum } from '../../../utils/enums';
import { LegendLineChart } from '../../../common/Legend';

export const PainChart = ({ painLevelData, procedureKey, type, isOverview }: any): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const no_data = t?.common.no_data;
  const pain = t?.dashboard.hcp.profile_patient.questionnaires.pain;

  const [description, setDescription] = useState<string[]>([]);

  const loading = useSelector<any>((state) => getPainLevelLoading(state));
  const windowClientWidth = useSelector((state: any) => state.common.screen.innerWidth);
  const chartData: any = painLevelData?.values;
  const periodType = painLevelData?.graph;
  const axisName = painLevelData?.axis;
  const isLabelAngle =
    periodType === PERIOD_FOR_CHART.W2 ||
    periodType === PERIOD_FOR_CHART.M6 ||
    periodType === PERIOD_FOR_CHART.M3;
  const isAllLabels = periodType !== PERIOD_FOR_CHART.M6 && windowClientWidth > 500;

  const chartTitle = painLevelData?.title;
  useEffect(() => {
    if (painLevelData && procedureKey) {
      const { averageValues } = painLevelData;
      const avValuesResult: string[] = [];
      if (procedureKey === proceduresEnum.SPINAL) {
        avValuesResult.push(averageValues.completedBack);
        avValuesResult.push(averageValues.skippedStoppedBack);
        avValuesResult.push(averageValues.completedLeg);
        avValuesResult.push(averageValues.skippedStoppedLeg);
      } else {
        avValuesResult.push(averageValues.completed);
        avValuesResult.push(averageValues.skippedStopped);
      }
      setDescription(avValuesResult);
    }
  }, [painLevelData?.averageValues, procedureKey, t]);

  const proceduresBarConfig = [
    {
      name: 'valueCompleted',
      label: t?.dashboard.hcp.profile_patient.completion_and_steps.average_pain,
      color: '#F16465',
      lineType: '',
    },
    {
      name: 'valueSkippedStopped',
      label: t?.dashboard.hcp.profile_patient.completion_and_steps.skip_stop_value,
      color: '#993c3c',
      lineType: '',
    },
  ];

  const spinalBarsConfig = [
    {
      name: 'valueBackCompleted',
      label: t?.dashboard.hcp.profile_patient.completion_and_steps.av_back_pain_score_completed,
      color: '#F16465',
      lineType: '',
    },
    {
      name: 'valueBackSkippedStopped',
      label: t?.dashboard.hcp.profile_patient.completion_and_steps.av_back_pain_score_skip_stop,
      color: '#F16465',
      lineType: '5 5',
    },
    {
      name: 'valueLegCompleted',
      label: t?.dashboard.hcp.profile_patient.completion_and_steps.av_leg_pain_score_completed,
      color: '#1CBA66',
      lineType: '',
    },
    {
      name: 'valueLegSkippedStopped',
      label: t?.dashboard.hcp.profile_patient.completion_and_steps.av_leg_pain_score_skip_stop,
      color: '#1CBA66',
      lineType: '5 5',
    },
  ];
  const barsConfig: TBar[] = [];
  switch (procedureKey) {
    case proceduresEnum.SPINAL:
      barsConfig.push(...spinalBarsConfig);
      break;
    default:
      barsConfig.push(...proceduresBarConfig);
  }

  // Custom Tooltip
  const renderTooltip = (payload: any): JSX.Element => {
    const pay = payload.payload;
    return <TooltipRecharts payload={pay} type={type} t={t} />;
  };

  // JSX
  const noData = !loading && !chartData?.length && <span className={styles.nodata}>{no_data}</span>;

  return (
    <div
      className={cx({
        [styles['chart-wrapper']]: true,
        'chart-to-PDF': true,
      })}
    >
      {!isOverview && (
        <div>
          <div className={styles['chart-title']}>{pain}</div>{' '}
          <div className={styles['chart-desciption-wrapper']}>
            {description.map((descr: string) => (
              <div key={descr} className={styles['chart-desciption--pain']}>
                {descr}
              </div>
            ))}
          </div>
          <div className={styles['chart__exrc-compl-legend--wrapper']}>
            <LegendLineChart config={barsConfig} />
          </div>
        </div>
      )}

      {isOverview && (
        <div className={styles.chart__flex}>
          <div className={styles['left-section']}>
            <div className={styles['chart-period']}>{chartTitle}</div>
            <div className={styles['chart-desciption-wrapper']}>
              {description.map((descr: string) => (
                <div key={descr} className={styles['chart-desciption--pain']}>
                  {descr}
                </div>
              ))}
            </div>
          </div>
          <div className={styles['right-section']}>
            <div className={styles['chart__legend--wrapper']}>
              <LegendLineChart config={barsConfig} />
            </div>
          </div>
        </div>
      )}

      <div className={styles['chart-container']}>
        <div className={styles['chart-label']}>{axisName}</div>
        {loading && spinner}
        {noData}
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            data={chartData}
            margin={{
              top: 10,
              left: -20,
              right: isLabelAngle ? 7 : 0,
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="name"
              axisLine={false}
              tickLine={false}
              // @ts-ignore
              style={{
                fontSize: isLabelAngle ? '11px' : '14px',
                fill: '#23335B',
              }}
              angle={isLabelAngle ? -45 : 0}
              interval={isAllLabels ? 0 : 'preserveEnd'} // 0 - To show all ticks
            />
            <YAxis
              domain={[0, 10]}
              stroke="#9BA1AE"
              tickLine={{ stroke: '#F0F0F8' }}
              interval={0}
              tickCount={11}
              tick={<EmojiYAxisTick type={type} />}
              // @ts-ignore
              style={{
                fontSize: '14px',
                fill: '#23335B',
              }}
            />
            {barsConfig.map((typeChart: TBar) => (
              <Line
                key={typeChart.name}
                type="monotone"
                dataKey={typeChart.name}
                stroke={typeChart.color}
                strokeDasharray={typeChart.lineType}
                isAnimationActive
              />
            ))}
            {!noData && (
              <Tooltip
                allowEscapeViewBox={{ x: false, y: false }}
                // offset={windowClientWidth > 1000 ? 0 : 500}
                content={renderTooltip}
              />
            )}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};
