// Core
import { loader } from 'graphql.macro';
import { useLazyQuery } from '@apollo/react-hooks';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

import {
  UseQueryPatientProfile,
  UseQueryPatientProfileByEmail,
  UseQueryPendingPatientProfile,
} from './types';
import {
  fillPatientProfile,
  loadingPatientProfile,
  setErrorPatientProfile,
} from '../../redux/patient';
import { config } from '../../utils/configs';
import { useMutationRequest } from '../../hooks/feature/useMutationRequest';
import {
  setPatientsInvitationData,
  setPatientsInvitationError,
  setPatientsInvitationLoading,
} from '../../redux/patientInvitation';
import useHandleGqlError from '../../hooks/useHandleGqlError';
import {
  AllGoal,
  ClaimOptions,
  UpdatePatientProfileReqDto,
  UseQueryAllGoals,
  UseQueryPatientGoals,
} from '../../components/PatientProfile/types';
import { DocumentDataType } from '../../components/PatientInformation/types';
import { StatusKeyNameEnum } from '../../utils/enums';

// Query
const queryProfile = loader('./gql/queryGetPatientById.graphql');
const queryProfileInviteePatient = loader('./gql/queryGetInviteePatientById.graphql');
const queryProfilePendingPatient = loader('./gql/queryGetPendingPatientById.graphql');
const queryGetPatientsListByHcpId = loader('./gql/getPatientsListByHcpId.graphql');
const queryProfileByEmail = loader('./gql/queryGetPatientByEmail.graphql');
const gqlPatient = loader('./gql/queryGetPatientsList.graphql');
const getPatientInvitations = loader('./gql/queryGetPatientsInvitations.graphql');
const getPatientGoalsWithAnswers = loader('./gql/queryGetPatientGoalsWithAnswers.graphql');
const queryAllGoals = loader('./gql/queryGetAllGoalType.graphql');
const queryClaimOptions = loader('./gql/queryGetClaims.graphql');
const queryAllPatientInformations = loader('./gql/queryGetAllPatientInformations.graphql');
const queryAllPatientInformationsMatch = loader(
  './gql/queryGetAllPatientInformationsMatch.graphql',
);

//  Mutation
const mutationUnlockPatientAccountByAdmin = loader(
  './gql/mutationUnlockPatientAccountByAdmin.graphql',
);
const updateProfileByHcp = loader('./gql/mutationPatientUpdateProfileByHcp.graphql');
const updateProfileByAdmin = loader('./gql/mutationPatientUpdateProfileByAdmin.graphql');
const mutationUpdateHcpDocument = loader('./gql/mutationUpdateHcpDocument.graphql');

// get patient profile from DB
export const useQueryPatientProfile = (patientStatus: string): UseQueryPatientProfile => {
  let query = queryProfile;
  switch (patientStatus) {
    case StatusKeyNameEnum.invite_sent:
      query = queryProfileInviteePatient;
      break;
    case StatusKeyNameEnum.pending:
      query = queryProfilePendingPatient;
      break;
    default:
      break;
  }
  const [_getProfileById, { loading, error, data }] = useLazyQuery(query, {
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  });

  const dispatch = useDispatch();
  let response: any;
  if (data) {
    switch (patientStatus) {
      case StatusKeyNameEnum.invite_sent:
        response = data.getInviteePatientById;
        break;
      case StatusKeyNameEnum.pending:
        response = data.getPendingPatientById;
        break;
      default:
        response = data.getPatientById;
    }
  }

  const errorText = useHandleGqlError(error);

  useEffect(() => {
    if (response) {
      dispatch(fillPatientProfile(response));
    }
  }, [data]);

  useEffect(() => {
    dispatch(loadingPatientProfile(loading));
  }, [loading]);

  useEffect(() => {
    dispatch(setErrorPatientProfile(errorText));
  }, [error]);

  return {
    _getProfileById,
    loading,
    error,
    patient: response,
  };
};

// get invitee patient profile from DB
// export const useQueryInviteePatientProfile = (): UseQueryInviteePatientProfile => {
//   const [_getInviteeProfileById, { loading, error, data }] = useLazyQuery(
//     queryProfileInviteePatient,
//     {
//       errorPolicy: 'all',
//       fetchPolicy: 'no-cache',
//     },
//   );

//   const dispatch = useDispatch();
//   const response = data && data.getInviteePatientById;

//   useEffect(() => {
//     if (response) {
//       dispatch(fillPatientProfile(response));
//     }
//   }, [response]);

//   useEffect(() => {
//     dispatch(loadingPatientProfile(loading));
//   }, [loading]);

//   return {
//     _getInviteeProfileById,
//     inviteeLoading: loading,
//     inviteeError: error,
//     inviteePatient: data && data.getInviteePatientById,
//   };
// };

// get pending patient profile from DB
// export const useQueryPendingPatientProfile = (): UseQueryPendingPatientProfile => {
//   const [_getPendingPatientProfileById, { loading, error, data }] = useLazyQuery(
//     queryProfilePendingPatient,
//     {
//       errorPolicy: 'all',
//       fetchPolicy: 'no-cache',
//     },
//   );

//   const dispatch = useDispatch();
//   const response = data && data.getPendingPatientById;

//   useEffect(() => {
//     if (response) {
//       dispatch(fillPatientProfile(response));
//     }
//   }, [response]);

//   useEffect(() => {
//     dispatch(loadingPatientProfile(loading));
//   }, [loading]);

//   return {
//     _getPendingPatientProfileById,
//     pendingLoading: loading,
//     pendingError: error,
//     pendingPatient: data && data.getPendingPatientById,
//   };
// };

// Get patient list for HCP profile
export const useQueryGetPatientsListByHcpId = (): any => {
  const [_getPatientById, { loading, error, data }] = useLazyQuery(queryGetPatientsListByHcpId, {
    errorPolicy: 'all',
  });

  const response = data && !error && !loading && data.getPatientsListByHcpId;

  if (response) {
    const { listLength, totalPages, list, totalItems } = response;
    return {
      _getPatientById,
      loading,
      data,
      error,
      patients: list,
      listLength,
      totalPages,
      totalItems,
    };
  }
  return {
    _getPatientById,
    loading,
    data,
    error,
    patients: [],
    listLength: 0,
    totalPages: 0,
    totalItems: 0,
  };
};

// get patient profile from DB
export const useQueryPatientProfileByEmail = (): UseQueryPatientProfileByEmail => {
  const [_getProfileByEmail, { loading, error, data }] = useLazyQuery(queryProfileByEmail, {
    // errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  });
  const response = data && data.getPatientByEmail;

  return {
    _getProfileByEmail,
    loading,
    error: error?.graphQLErrors?.[0]?.message || error,
    patient: response,
  };
};

// Get all patients
export const usePatientsList = () => {
  const [_getListPatient, { loading, error, data }] = useLazyQuery(gqlPatient, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    // pollInterval: config.pollInterval,
  });
  const dataList = data && data.getPatientsList;
  if (dataList) {
    const { list, listLength, totalPages, totalItems } = dataList;
    return {
      dataPatients: data,
      patients: list,
      total: totalItems,
      pages: totalPages,
      pageSize: listLength,
      _getListPatient,
      listError: error,
      listLoading: loading,
    };
  }
  return {
    dataPatients: data,
    patients: [],
    total: 0,
    pageSize: 0,
    _getListPatient,
    listError: error,
    listLoading: loading,
  };
};

// Get all patients
export const usePatientsListForExportToCSV = () => {
  const [_getListPatientForExportToCSV, { loading, error, data }] = useLazyQuery(gqlPatient, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  });
  const dataList = data && data.getPatientsList;
  if (dataList) {
    const { list, listLength, totalPages, totalItems } = dataList;
    return {
      dataPatientsForExportToCSV: data,
      patientsForExportToCSV: list,
      totalForExportToCSV: totalItems,
      pagesForExportToCSV: totalPages,
      pageSizeForExportToCSV: listLength,
      _getListPatientForExportToCSV,
      listErrorForExportToCSV: error,
      listLoadingForExportToCSV: loading,
    };
  }
  return {
    dataPatientsForExportToCSV: data,
    patientsForExportToCSV: [],
    totalForExportToCSV: 0,
    pageSizeForExportToCSV: 0,
    _getListPatientForExportToCSV,
    listErrorForExportToCSV: error,
    listLoadingForExportToCSV: loading,
  };
};

// Get pending patients Invitations
export const usePatientsPendingInvitations = () => {
  const [_getPatientInvitations, { loading, error, data }] = useLazyQuery(getPatientInvitations, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    pollInterval: config.pollInterval,
  });
  const dispatch = useDispatch();
  const dataList = data && data.getPatientsList;

  useEffect(() => {
    dispatch(setPatientsInvitationLoading(loading));
  }, [loading]);

  useEffect(() => {
    if (dataList && !loading) {
      dispatch(setPatientsInvitationData(dataList));
    }
  }, [data]);

  useEffect(() => {
    dispatch(setPatientsInvitationError(error));
  }, [error]);

  if (dataList) {
    const { list, listLength, totalPages, totalItems } = dataList;
    return {
      patientsInvitations: list,
      total: totalItems,
      pages: totalPages,
      pageSize: listLength,
      _getPatientInvitations,
      listError: error,
      listLoading: loading,
    };
  }
  return {
    patientsInvitations: [],
    total: 0,
    pageSize: 0,
    _getPatientInvitations,
    listError: error,
    listLoading: loading,
    data,
  };
};

// Unlock patients account by Admin
export const useUnlockPatientAccountByAdmin = () => {
  const [_onUnlockPatientAccountByAdmin, { data, errorText, loading }] = useMutationRequest<{
    unlockPatientAccountByAdmin: boolean;
  }>(mutationUnlockPatientAccountByAdmin, { fetchPolicy: 'no-cache' });
  const _unlockPatientAccountByAdmin = (patientId: number): void => {
    _onUnlockPatientAccountByAdmin({
      variables: { patientId },
    });
  };

  return {
    _unlockPatientAccountByAdmin,
    unlockPatientAccountData: data && data.unlockPatientAccountByAdmin,
    unlockPatientAccountError: errorText,
    unlockPatientAccountLoading: loading,
  };
};

// Update profile
export const useUpdatePatientProfile = (isAdmin: boolean) => {
  const [_update, { data, errorText, loading }] = useMutationRequest<{
    patientUpdateProfileByAdmin?: boolean;
    patientUpdateProfileByHcp?: boolean;
  }>(isAdmin ? updateProfileByAdmin : updateProfileByHcp, {});

  const _updateProfile = (form: UpdatePatientProfileReqDto, hcp: number[]): void => {
    _update({
      variables: {
        patientData: form,
        hcpIds: hcp,
      },
    });
  };
  const response = isAdmin
    ? data && data.patientUpdateProfileByAdmin
    : data && data.patientUpdateProfileByHcp;

  return {
    updating: loading,
    _updateProfile,
    errorMessage: errorText,
    updatedProfileByAdmin: response,
  };
};

// get patient goals
export const useQueryPatientGoals = (): UseQueryPatientGoals => {
  const [_getPatientsGoals, { loading, error, data }] = useLazyQuery(getPatientGoalsWithAnswers, {
    errorPolicy: 'all',
    pollInterval: 60000,
    fetchPolicy: 'no-cache',
  });
  return {
    _getPatientsGoals,
    loadingGoals: loading,
    errorGoals: error,
    goals: data && data.getPatientGoalsWithAnswers,
    patientGoalsData: data,
  };
};

// get all goals
export const useQueryAllGoals = (): UseQueryAllGoals => {
  const [_getAllGoals, { loading, error, data }] = useLazyQuery(queryAllGoals);
  let response;
  if (data) {
    if (data.getAllGoalType.length > 0) {
      response = data.getAllGoalType.sort((a: AllGoal, b: AllGoal) => {
        if (a.order > b.order) {
          return 1;
        }
        if (a.order < b.order) {
          return -1;
        }
        return 0;
      });
    }
  }
  return {
    _getAllGoals,
    loadingAllGoals: loading,
    errorAllGoals: error,
    allGoals: response,
    allGoalsData: data,
  };
};

// get options for type of claim
export const useQueryClaimOptions = (): ClaimOptions => {
  const [_getClaimOptions, { loading, error, data }] = useLazyQuery(queryClaimOptions);
  return {
    _getClaimOptions,
    loadingClaims: loading,
    errorClaims: error,
    claimOptions: data?.getClaims || [],
  };
};

// Get ALl Patient Informations
export const useGetAllPatientInformations = () => {
  const [_getAllPatientInformations, { loading, error, data }] = useLazyQuery(
    queryAllPatientInformations,
    {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      pollInterval: config.pollInterval,
    },
  );

  const _getAllPatientInfo = (request: any) => {
    _getAllPatientInformations({
      variables: request,
    });
  };

  const textErr = useHandleGqlError(error);
  const response = data && data.getAllPatientInformations;

  return {
    _getAllPatientInfo,
    allPatientInfo: response?.list,
    errorAllPatientInfo: textErr,
    loadingAllPatientInfo: loading,
    allPatientInfoData: data,
    totalElements: response?.totalItems,
    totalPages: response?.totalPages,
  };
};

// Get ALl Patient Informations
export const useGetAllPatientInformationsMatch = () => {
  const [_getAllPatientInformationsMatch, { loading, data }] = useLazyQuery(
    queryAllPatientInformationsMatch,
    {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      pollInterval: config.pollInterval,
    },
  );

  const _getAllPatientInfoMatch = (request: any) => {
    _getAllPatientInformationsMatch({
      variables: request,
    });
  };

  return {
    _getAllPatientInfoMatch,
    loadingAllPatientInfoMatch: loading,
    totalElementsMatch: data && data.getAllPatientInformations.totalItems,
  };
};

// Update Patient Information from Organisation level
export const useUpdateHcpDocument = () => {
  const [_updateDoc, { data, errorText, loading }] = useMutationRequest<{
    updateHcpDocument: boolean;
  }>(mutationUpdateHcpDocument, {});

  const _updateHcpDocument = (documentData: DocumentDataType): void => {
    _updateDoc({
      variables: { documentData },
    });
  };

  const response = data && data.updateHcpDocument;

  return {
    _updateHcpDocument,
    loadingUpdateHcpDocument: loading,
    errorUpdateHcpDocument: errorText,
    documentUpdated: response,
  };
};
