import React from 'react';

import styles from './styles.module.css';
import { NoImage, NotAllowedIcon, PlayVideoIcon, ShowImgIcon } from '../../theme/icons';

interface SyzeType {
  width: number;
  height: number;
}
interface ThumbnailJsxProps {
  thumbLink: string;
  photoPic: string;
}
interface ShowMediaIconProps extends ThumbnailJsxProps {
  size?: { playVideo: SyzeType; showImg: SyzeType };
}

export const ShowMediaIcon = ({
  thumbLink,
  photoPic,
  size = { playVideo: { width: 12, height: 16 }, showImg: { width: 25, height: 21 } },
}: ShowMediaIconProps): JSX.Element => {
  const playVideoWidth = size.playVideo.width;
  const playVideoHeight = size.playVideo.height;

  if (photoPic) {
    return <ShowImgIcon className={styles['btn--slider']} />;
  }
  if (thumbLink) {
    return (
      <PlayVideoIcon
        width={playVideoWidth}
        height={playVideoHeight}
        className={styles['start--btn']}
      />
    );
  }
  return <img src={NotAllowedIcon} alt="No video" className={styles['btn--not-allowed']} />;
};

export const ThumbnailJsx = ({ thumbLink, photoPic }: ThumbnailJsxProps): JSX.Element => {
  if (thumbLink) {
    return <img src={thumbLink} alt="Preview" /* crossOrigin="anonymous" */ />;
  }
  if (photoPic) {
    return <img src={`data:image/jpeg;charset=utf-8;base64,${photoPic}`} alt="Preview" />;
  }
  return <img src={NoImage} alt="Preview" />;
};
