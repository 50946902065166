/* eslint-disable no-nested-ternary */
import React, { ReactElement, useMemo } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import moment from 'moment';

import style from './style.module.css';
import { Female, Male, PatientProfileIcon } from '../../../theme/icons';
import { ProfilePatientCardProps } from '../types';
import { getCurrenLang, GetState, getUserCountry } from '../../../redux/selector';
import { getInsurancePolicyLabel, otherClaimIdHandler } from '../../../utils/helper';
import StatusWithMarker from '../../../common/Elements/StatusWithMarker';
import { HEIGHT, UserCountryEnum, Weight } from '../../../utils/enums';
import {
  convertFromCm,
  convertFromGram,
  convertInCm,
  convertInGram,
} from '../../../utils/validators';
import BmiTip from '../../../common/Elements/BmiTip';
import PatientRehabGoals from '../../PatientProfile/RehabGoals';

const ProfilePatientCard = ({
  patient,
  notAllowed,
  patientId,
}: ProfilePatientCardProps): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const label_dob = t?.dashboard.admin.profile_patient.label_dob;
  const bmi_index = t?.dashboard.admin.profile_patient.bmi_index;
  const type_of_claim = t?.dashboard.admin.profile_patient.type_of_claim;
  const insured_ID_number = t?.dashboard.admin.profile_patient.insured_ID_number;
  const policy_group = t?.dashboard.admin.profile_patient.policy_group;
  const other_claim_id = t?.dashboard.admin.profile_patient.other_claim_id;
  const insurance_plan_name = t?.dashboard.admin.profile_patient.insurance_plan_name;
  const dos = t?.dashboard.hcp.profile_patient.completion_and_steps.date_of_surgery;
  const weight_label = t?.invite_patient.weight;
  const height_label = t?.invite_patient.height;
  const gender_t = t?.dashboard.admin.profile_patient.gender;
  const rehab_goals = t?.dashboard.hcp.profile_patient.patient_goals;
  let firstName;
  let lastName;
  let status = '';
  let procedure;
  let email;
  let phone;
  let dob;
  let nhsNumber;
  let gender;
  let country;
  let height: any;
  let heightDim: any;
  let weight: any;
  let weightDim: any;
  if (patient) {
    firstName = patient.firstName;
    lastName = patient.lastName;
    status = patient.status;
    procedure = patient.procedure;
    email = patient.email;
    phone = patient.phone;
    dob = patient.dob;
    nhsNumber = patient.nhsNumber;
    gender = patient.gender;
    country = patient.country;
    height = patient.height;
    heightDim = patient.heightDim;
    weight = patient.weight;
    weightDim = patient.weightDim;
  }
  const patientName = firstName || lastName ? `${firstName} ${lastName}` : '-';
  const userCountry: any = useSelector<GetState>((state) => getUserCountry(state));
  const dosDate = procedure?.date;
  const weightConverted = weight
    ? weightDim === Weight.Stone
      ? convertFromGram(weight, weightDim).join(',')
      : convertFromGram(weight, weightDim).toString()
    : null;
  const heightConverted = height
    ? heightDim === HEIGHT.ftinches
      ? convertFromCm(height, heightDim).join(',')
      : convertFromCm(height, heightDim).toString()
    : null;

  const calcBMI = useMemo(() => {
    if (weight && height) {
      const weightRes: any = convertInGram(String(weightConverted), weightDim);
      const heightRes: any = convertInCm(String(heightConverted), heightDim);
      // eslint-disable-next-line no-restricted-properties
      const result = weightRes / 1000 / Math.pow(heightRes / 100, 2);
      return parseFloat(result.toString()).toFixed(1);
    }
    return '-';
  }, [patient]);

  // JSX
  // Get gender icon
  const getSign = (patientGender: number | undefined): JSX.Element => {
    if (patientGender) {
      let genderIcon = '';
      if (patientGender === 1) {
        genderIcon = Female;
      }
      if (patientGender === 2) {
        genderIcon = Male;
      }
      return <img src={genderIcon} alt="Icon" />;
    }
    return <>N/A</>;
  };

  return (
    <div className={style['profile-card__container']}>
      <PatientProfileIcon width={23} height={23} />
      <div
        className={cx({
          [style['profile-card__patient-name']]: true,
          [style.row]: true,
        })}
      >
        {patientName}
      </div>
      {status && (
        <div className={style.row}>
          <StatusWithMarker currentStatus={status} t={t} />
        </div>
      )}
      <div
        className={cx({
          [style['profile-card__procedure']]: true,
          [style.row]: true,
        })}
      >
        {procedure?.type?.name || '-'}
      </div>
      {procedure?.hospital?.name && (
        <div
          className={cx({
            [style['profile-card__hospital']]: true,
            [style.row]: true,
          })}
        >
          {procedure.hospital.name || '-'}
        </div>
      )}

      <div className={style['profile-card__email']}>{email || '-'}</div>

      {/* Phone */}
      {phone && (
        <div
          className={cx({
            [style['profile-card__phone']]: true,
            [style.row]: true,
          })}
        >
          {phone}
        </div>
      )}

      {/* DOB */}
      <div className={style['profile-card__label']}>{label_dob}</div>
      <div
        className={cx({
          [style['profile-card__phone']]: true,
          [style.row]: true,
        })}
      >
        {dob ? moment(dob).format('DD MMM YYYY') : '-'}
      </div>

      <div
        className={cx({
          [style.row__secondary]: true,
        })}
      >
        <div className={style['profile-card__gender-wrapper']}>
          <div className={style['profile-card__label']}>{gender_t}</div>
          <div className={style['profile-card__gender']}>{getSign(gender)}</div>
        </div>

        <div className={style['profile-card__nhs-wrapper']}>
          <div className={style['profile-card__label']}>
            {getInsurancePolicyLabel(t, userCountry)}
          </div>
          <div className={style['profile-card__nhs']}>{nhsNumber || '-'}</div>
        </div>

        <div className={style['profile-card__dos-wrapper']}>
          <div className={style['profile-card__label']}>{dos}</div>
          <div className={style['profile-card__nhs']}>
            {dosDate ? moment(dosDate).format('DD MMM YYYY') : '-'}
          </div>
        </div>
      </div>

      {/* BMI */}
      <div
        className={cx({
          [style.row__secondary]: true,
        })}
      >
        <div className={style['profile-card__height-wrapper']}>
          <div className={style['profile-card__label']}>{height_label}</div>
          <div className={style['profile-card__nhs']}>
            {heightConverted ? `${heightConverted}${heightDim}` : '-'}
          </div>
        </div>

        <div className={style['profile-card__height-wrapper']}>
          <div className={style['profile-card__label']}>{weight_label}</div>
          <div className={style['profile-card__nhs']}>
            {weightConverted ? `${weightConverted}${weightDim}` : '-'}
          </div>
        </div>

        <div className={style['profile-card__height-wrapper']}>
          <div className={style['profile-card__label']}>{bmi_index}</div>
          <div
            className={cx({
              [style['profile-card__nhs']]: true,
              [style['profile-card__gender']]: true,
            })}
          >
            {calcBMI}
            <div className={style['profile-card__bmi-popup']}>
              <BmiTip resultBMI={calcBMI} t={t} />
            </div>
          </div>
        </div>
      </div>

      {/* for US */}
      {country === UserCountryEnum.US && (
        <div className={style['profile-card__wrapper']}>
          <div className={style['profile-card__label']}>{type_of_claim}</div>
          <div className={style['profile-card__us-insurance']}>
            Group Health Plan
            {/* {patient.patientInsurance?.claim?.name || '-'} */}
          </div>

          <div className={style['profile-card__label']}>{insured_ID_number}</div>
          <div className={style['profile-card__us-insurance']}>
            {patient?.patientInsurance?.insuredIdNumber || '-'}
          </div>

          <div className={style['profile-card__label']}>{policy_group}</div>
          <div className={style['profile-card__us-insurance']}>
            {patient?.patientInsurance?.policyGroup || '-'}
          </div>

          <div className={style['profile-card__label']}>{other_claim_id}</div>
          <div className={style['profile-card__us-insurance']}>
            {otherClaimIdHandler(patient?.patientInsurance?.otherClaimId) || '-'}
          </div>

          <div className={style['profile-card__label']}>{insurance_plan_name}</div>
          <div className={style['profile-card__us-insurance']}>
            {patient?.patientInsurance?.insurancePlanName || '-'}
          </div>
        </div>
      )}

      {/* Rehab Goals */}
      <div className={style['profile-card__goals-container']}>
        <div className={style['profile-card__goals-title']}>{rehab_goals}</div>
        <PatientRehabGoals notAllowed={notAllowed} userId={patientId} isOverview />
      </div>
    </div>
  );
};

export default ProfilePatientCard;
