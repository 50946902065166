import React, { ReactElement, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sanitize } from 'dompurify';
import { toast } from 'react-toastify';

import pageStyles from '../../styles.module.css';
import styles from './styles.module.css';
import {
  GetState,
  getCurrenLang,
  getHcpName,
  getRewardList,
  getRewardListError,
  getRewardListLoading,
  getRewardSettingsData,
} from '../../../redux/selector';
import { Loading, MainTitle } from '../../../common';
import { BreadcrumbAnt } from '../../../utils/routers/Breadcrumb';
import { RewardsIcon } from '../../../theme/icons';
import { GuideTitle, RewardsGuide } from '../../../common/Giude';
import { otmLink, rewardEmptyPeriod } from '../../../utils/variables';
import { REWARDS_OPTIONS } from '../../../utils/enums';
import { useQueryGetHcpHospitalRewards } from '../../../graphql/rewards';
import { DataRewardType } from '../../../components/Rewards/types';
import { Reward } from '../../../components/Rewards/Reward';
import { PlusNewItem } from '../../../common/Button/Button';
import { setOpenRewardSettingData, setRewardList } from '../../../redux/rewards';
import RewardSetting from '../../../components/Rewards/RewardSetting';
import { executeScroll } from '../../../utils/helper';

const RewardsPage = (): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const rewards_t = t?.common.rewards;
  const set_reward = t?.hcp.rewards.set_reward;
  const edit_reward = t?.hcp.rewards.edit_reward;
  const set_rewards = t?.hcp.rewards.setRewards;
  const edit_rewards = t?.hcp.rewards.edit_rewards;
  const add_new_week = t?.hcp.rewards.add_new_week;
  const custom_rewards_guide = t?.dashboard.hcp.table.custom_rewards_guide;
  const ready_to_set = t?.hcp.rewards.ready_to_set;
  const not_ready_to_set = t?.hcp.rewards.not_ready_to_set;
  const can_edit = t?.hcp.rewards.can_edit;
  const reward_desc_otm_1 = t?.hcp.rewards.reward_description_otm_1;
  const reward_desc_otm_2 = t?.hcp.rewards.reward_description_otm_2;
  const reward_desc_otm_3 = t?.hcp.rewards.reward_description_otm_3;
  const reward_desc_otm_3_email = reward_desc_otm_3?.replace('<email>', otmLink);

  // Get hospital custom rewards
  const { _getHcpHospitalRewards } = useQueryGetHcpHospitalRewards();

  // Redax
  const rewardList = useSelector((state: GetState) => getRewardList(state));
  const rewardListLoading = useSelector((state: GetState) => getRewardListLoading(state));
  const rewardListError = useSelector((state: GetState) => getRewardListError(state));
  const isOpenSettigsData = useSelector((state: GetState) => getRewardSettingsData(state));
  const currentUser: any = useSelector((state: GetState) => getHcpName(state));
  const currentRewardsOption: number = currentUser.rewardsType;

  const dispatch = useDispatch();
  const sectionRef: any = useRef(null);

  // Scroll to elem
  useEffect(() => {
    if (isOpenSettigsData) {
      executeScroll(sectionRef);
    }
  }, [isOpenSettigsData]);

  // Get all rewards (periods)
  useEffect(() => {
    _getHcpHospitalRewards();

    // Delete current open settings data if page close
    return (): void => {
      dispatch(setOpenRewardSettingData(null));
    };
  }, []);

  // Update form data
  const updateForm = (): void => {
    _getHcpHospitalRewards();
  };

  // Get all rewards (periods)
  useEffect(() => {
    if (rewardListError) {
      toast.error(rewardListError);
    }
  }, [rewardListError]);

  // Add new empty week
  const addNewWeek = (): void => {
    if (rewardList) {
      dispatch(setRewardList([...rewardList, { ...rewardEmptyPeriod }]));
    }
  };

  // JSX
  const guideStatusesConfig = [
    {
      status: set_reward,
      description: ready_to_set,
      color: 'green',
    },
    {
      status: set_reward,
      description: not_ready_to_set,
      color: 'grey',
    },
    {
      status: edit_reward,
      description: can_edit,
      color: 'blue',
    },
  ];

  const loadingJSX = rewardListLoading && <Loading />;

  // Breadcrumb path
  const routes = [
    {
      path: rewards_t,
      breadcrumbName: rewards_t,
    },
  ];

  return (
    <div className={pageStyles.main__container}>
      {loadingJSX}
      <BreadcrumbAnt currentRoutes={routes} />
      <div className={pageStyles.main__wrapper}>
        <MainTitle title={rewards_t} icon={<RewardsIcon />} />

        {/* Option 1 (OTM) */}
        {currentRewardsOption === REWARDS_OPTIONS.OPTION_1 && (
          <>
            <div className={pageStyles['section-description']}>
              <div>{reward_desc_otm_1}</div>
              <div dangerouslySetInnerHTML={{ __html: sanitize(reward_desc_otm_3_email) }} />
            </div>
            <div className={pageStyles['section-description']}>{reward_desc_otm_2}</div>
          </>
        )}

        {/* Option 2 (Organisation Rewards) */}
        {currentRewardsOption === REWARDS_OPTIONS.OPTION_2 && (
          <>
            {/* Rewards guide */}
            <GuideTitle
              text={custom_rewards_guide}
              content={<RewardsGuide guideStatusesConfig={guideStatusesConfig} t={t} />}
            />

            <div className={pageStyles['flex-container']}>
              {/* Rewards List */}
              <div className={pageStyles['left-section']}>
                {!rewardListError &&
                  rewardList &&
                  rewardList.map((reward: DataRewardType, i: number) => (
                    <>
                      <Reward
                        key={`RewardKey${String(i)}${String(reward.id)}`}
                        index={i}
                        data={reward}
                        isDisable={i > 0 ? !rewardList[i - 1].name : false}
                      />
                      <div className={styles['rewards__settings--sm']}>
                        {isOpenSettigsData?.id === reward.id ? (
                          <div className={pageStyles['right-section']} ref={sectionRef}>
                            <RewardSetting updateForm={updateForm} key={isOpenSettigsData.id} />
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </>
                  ))}
                {!rewardListError && (
                  <PlusNewItem
                    description={add_new_week}
                    buttonMethod={addNewWeek}
                    disable={
                      !(
                        rewardList &&
                        rewardList.length > 0 &&
                        rewardList[rewardList.length - 1].name
                      )
                    }
                  />
                )}
              </div>

              {/* Settings big screen */}
              <div className={styles['rewards__settings--bg']}>
                {isOpenSettigsData ? (
                  <div className={pageStyles['right-section']} ref={sectionRef}>
                    <MainTitle title={isOpenSettigsData.name ? edit_rewards : set_rewards} />
                    <RewardSetting updateForm={updateForm} key={isOpenSettigsData.id} />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default RewardsPage;
