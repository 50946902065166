import React, { ReactElement } from 'react';

import styles from './styles.module.css';
import { MainTitle } from '../../../common';
import { InviteHcp } from '../../invite/InviteHcp';
import { CloseIcon } from '../../../theme/icons';

const InviteHcpModal = ({ invite_hcp_title, onCloseInviteModal }: any): ReactElement => (
  <div className={styles.modal_container}>
    <div className={styles.close} onClick={onCloseInviteModal} role="presentation">
      <CloseIcon />
    </div>
    <MainTitle title={invite_hcp_title} />
    <InviteHcp inModal methodModal={onCloseInviteModal} />
  </div>
);

export default InviteHcpModal;
