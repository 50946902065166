import React, { ReactElement } from 'react';
import { Pagination } from 'antd';

import styles from './styles.module.css';
import { DeleteIcon } from '../../theme/icons';

interface ItemType {
  name?: string;
  type?: string;
  id: number;
}
interface ListItemsProps {
  label: string;
  srcList: ItemType[];
  setCandidat: any;
  setDelete: any;
  currentPage: number;
  pageSize: number;
  totalItemsState: number;
  onChangePagination: any;
  no_data: string;
}

const ListItems = ({
  label,
  srcList,
  setCandidat,
  setDelete,
  currentPage,
  pageSize,
  totalItemsState,
  onChangePagination,
  no_data,
}: ListItemsProps): ReactElement => (
  <>
    <div className={styles.listTitle}>{label}</div>
    <div className={styles.list}>
      {srcList.length > 0 ? (
        srcList.map((item: ItemType) => (
          <div key={item.type || item.name} className={styles.row}>
            <div className={styles['name-cont']}>
              <div className={styles.dash}>-</div>
              <div className={styles.name}>{item.type || item.name}</div>
            </div>
            <div
              className={styles['del-icon']}
              onClick={(): any => {
                setCandidat({
                  id: item.id,
                  name: item.name,
                  type: item.type,
                });
                setDelete(true);
              }}
              role="presentation"
            >
              <DeleteIcon />
            </div>
          </div>
        ))
      ) : (
        <div className={styles['no-data']}>{no_data}</div>
      )}
    </div>
    <div className="wrapper_pagination">
      <Pagination
        size="small"
        current={currentPage}
        pageSize={pageSize}
        total={totalItemsState}
        onChange={onChangePagination}
      />
    </div>
  </>
);

export default ListItems;
