import React, { ReactElement } from 'react';
import { sanitize } from 'dompurify';
import moment from 'moment';

import { getPatientAnswers } from '../../../../../utils/helper_charts';
import { CalculationDescription } from './common/Calculation';
import { RemoveQuestionnaire } from './common/RemoveQuestionnaire';
import style from './style.module.css';
import { Answer, Question, QuestionnaireContentProps } from './types';
import { PROMS_NAME } from '../../../../../utils/enums';

export const HoosReport = ({
  questionnairesList,
  completedQuestionnaire,
  index,
  onDeleteItem,
  formatDate,
  t,
}: QuestionnaireContentProps): ReactElement => {
  const remove_questionnaire = t?.dashboard.hcp.profile_patient.questionnaires.remove_questionnaire;
  const hoos_calc_descr_header =
    t?.dashboard.hcp.profile_patient.questionnaires.hoos_calc_descr_header;
  const hoos_scoring_instruction =
    t?.dashboard.hcp.profile_patient.questionnaires.hoos_scoring_instruction;
  const hoos_calc_descr_1 = t?.dashboard.hcp.profile_patient.questionnaires.hoos_calc_descr_1;
  const hoos_calc_descr_2 = t?.dashboard.hcp.profile_patient.questionnaires.hoos_calc_descr_2;
  const hoos_calc_descr_3 = t?.dashboard.hcp.profile_patient.questionnaires.hoos_calc_descr_3;
  const hoos_calc_table_title1 =
    t?.dashboard.hcp.profile_patient.questionnaires.hoos_calc_table_title1;
  const hoos_calc_table_title2 =
    t?.dashboard.hcp.profile_patient.questionnaires.hoos_calc_table_title2;
  const hoos_calc_table_footer =
    t?.dashboard.hcp.profile_patient.questionnaires.hoos_calc_table_footer;
  const hoos_header_title = t?.dashboard.hcp.profile_patient.questionnaires.hoos_header_title;
  const hoos_header_version = t?.dashboard.hcp.profile_patient.questionnaires.hoos_header_version;
  const hoos_instructions = t?.dashboard.hcp.profile_patient.questionnaires.instructions;
  const hoos_instructions_descr1 =
    t?.dashboard.hcp.profile_patient.questionnaires.hoos_instructions_descr1;
  const hoos_instructions_descr2 =
    t?.dashboard.hcp.profile_patient.questionnaires.hoos_instructions_descr2;
  const hoos_pain = t?.dashboard.hcp.profile_patient.questionnaires.pain;
  const hoos_subTitle_descr = t?.dashboard.hcp.profile_patient.questionnaires.hoos_subTitle_descr;
  const hoos_subTitle2 = t?.dashboard.hcp.profile_patient.questionnaires.hoos_subTitle2;
  const hoos_subTitle2_descr = t?.dashboard.hcp.profile_patient.questionnaires.hoos_subTitle2_descr;
  const hoos_footer = t?.dashboard.hcp.profile_patient.questionnaires.hoos_footer;

  const calcTableConfig = [
    '100.000',
    '92.340',
    '85.257',
    '80.550',
    '76.776',
    '73.472',
    '70.426',
    '67.516',
    '64.664',
    '61.815',
    '58.930',
    '55.985',
    '52.965',
    '49.858',
    '46.652',
    '43.335',
    '39.902',
    '36.363',
    '32.735',
    '29.009',
    '25.103',
    '20.805',
    '15.633',
    '8.104',
    '0.000',
  ];
  const { questions: completedQuestions, completedDate } = completedQuestionnaire;
  const patientAnswers: any = getPatientAnswers(completedQuestions);
  const hoosList = questionnairesList.filter((item: any) => item.name === PROMS_NAME.HOOS)[0];
  const { questions } = hoosList;

  // JSX
  const calc_description = (
    <>
      <div className={style['hoos__calc-header']}>{hoos_calc_descr_header}</div>
      <p>{hoos_calc_descr_1}</p>
      <p>{hoos_calc_descr_2}</p>
      <p className={style['hoos__calc-descr3']}>{hoos_calc_descr_3}</p>
      <div className={style['hoos__calc-table']}>
        {/* Header */}
        <div className={style['hoos__calc-tr']}>
          <div
            className={style['hoos__calc-th']}
            dangerouslySetInnerHTML={{ __html: sanitize(hoos_calc_table_title1) }}
          />
          <div
            className={style['hoos__calc-th']}
            dangerouslySetInnerHTML={{ __html: sanitize(hoos_calc_table_title2) }}
          />
        </div>

        {/* Body */}
        {calcTableConfig.map((score: string, i: number) => (
          <div key={score} className={style['hoos__calc-tr']}>
            <div className={style['hoos__calc-td']}>{i}</div>
            <div className={style['hoos__calc-td']}>{score}</div>
          </div>
        ))}
        {/* Footer */}
        <div className={style['hoos__calc-footer']}>{hoos_calc_table_footer}</div>
      </div>
    </>
  );

  const firstQuestionsSection: any = [];
  const secondQuestionsSection: any = [];
  const jsx = questions?.map((question: Question, ind: number) => {
    const questionJsx = (
      <div className={style['hoos__question-row']} key={question.name}>
        <div className={style['hoos__question--number']}>{ind + 1}.</div>
        <div className={style['hoos__question-answ-container']}>
          <div className={style['hoos__question-sub-row']}>{question.name}</div>
          <div className={style['hoos__question-sub-row']}>
            {question.answers?.map((answ: Answer) => (
              <div className={style['hoos__answer-item']} key={`${answ.name}${ind}`}>
                <div>{answ.name}</div>
                <div className={style['hoos__answer-checkbox']}>
                  {patientAnswers[question.id] === answ.id && <>&#10004;</>}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
    if (ind < 2) {
      firstQuestionsSection.push(questionJsx);
    } else {
      secondQuestionsSection.push(questionJsx);
    }
    return null;
  });

  return (
    <>
      <RemoveQuestionnaire
        onMethod={(): void => onDeleteItem(index)}
        title={remove_questionnaire}
      />
      <div className="questionnaire-to-PDF">
        <div className={style.hoos__container}>
          <CalculationDescription
            name={hoos_scoring_instruction}
            content={calc_description}
            date={completedDate ? moment(completedDate).format(formatDate) : ''}
            t={t}
          />
        </div>
      </div>
      {/* PAGE 1 */}
      <div className="questionnaire-to-PDF">
        <div className={style.hoos__container}>
          {/* Header */}
          <div className={style.hoos__header}>
            <div className={style['hoos__header--logo']}>HSS</div>
            <div className={style['hoos__header--title']}>
              {hoos_header_title}
              <div className={style['hoos__header--vers']}>{hoos_header_version}</div>
            </div>
          </div>

          {/* Body */}
          <div className={style.hoos__title}>{hoos_instructions}</div>
          <p>{hoos_instructions_descr1}</p>
          <p>{hoos_instructions_descr2}</p>

          <div className={style['hoos__sub-title']}>{hoos_pain}</div>
          <p>{hoos_subTitle_descr}</p>
          {firstQuestionsSection}
          <div className={style['hoos__sub-title']}>{hoos_subTitle2}</div>
          <p>{hoos_subTitle2_descr}</p>
          {secondQuestionsSection}

          {/* Footer */}
          <div className={style.hoos__footer}>{hoos_footer}</div>
        </div>
      </div>
    </>
  );
};
