import { CSSProperties } from 'react';
import { hexToRgbA } from '../../utils/helper';

const disableDropdownIndicator = {
  dropdownIndicator: (style: CSSProperties): CSSProperties => ({
    ...style,
    display: 'none',
  }),
  singleValue: (style: CSSProperties): CSSProperties => ({
    ...style,
    color: '#9ba1ae',
  }),
};

const disableDropdownIndicatorFloatLabel = {
  dropdownIndicator: (style: CSSProperties): CSSProperties => ({
    ...style,
    display: 'none',
  }),
  singleValue: (style: CSSProperties): CSSProperties => ({
    ...style,
    color: '#9ba1ae',
    paddingTop: '14px',
  }),
};

export const selectStyles = {
  container: (style: CSSProperties) => ({
    ...style,
    minWidth: '100%',
    height: '36px',
    // marginTop: '7px',
    color: '#23335B',
  }),
  control: (style: CSSProperties) => ({
    ...style,
    borderColor: '#F0F0F8',
    borderRadius: '4px',
    minHeight: '36px',
    height: '36px',
    boxShadow: 'none',
    backgroundColor: 'hsl(0,0%,100%)',
    cursor: 'pointer',
    ':hover': { borderColor: '#1C9CE4' },
    ':active': { borderColor: '#1C9CE4' },
  }),
  input: (style: CSSProperties) => ({
    ...style,
    div: { input: { ':hover': { border: '0 !important' } } },
  }),
  singleValue: (style: CSSProperties) => ({
    ...style,
    color: '#23335B',
  }),
  valueContainer: (style: CSSProperties) => ({
    ...style,
    color: '#23335B',
    fontSize: '12px',
  }),
  option: (style: CSSProperties, state: any) => ({
    ...style,
    fontSize: '12px',
    lineHeight: '18px',
    paddingTop: '11px',
    paddingBottom: '11px',
    paddingLeft: '15px',
    color: '#23335B',
    ':hover': {
      color: '#1C9CE4',
      backgroundColor: '#1C9CE404',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
    },
    backgroundColor: state.isSelected && '#EFEFFF',
  }),
  indicatorSeparator: (style: CSSProperties) => ({
    ...style,
    display: 'none',
  }),
  placeholder: (style: CSSProperties) => ({
    ...style,
    color: '#9ba1ae',
  }),
};

export const selectNewStyles = {
  container: (style: CSSProperties): CSSProperties => ({
    ...style,
    width: '100%',
    height: '22px',
  }),
  control: (style: CSSProperties): any => ({
    ...style,
    borderColor: 'transparent',
    borderRadius: '4px',
    minHeight: '22px',
    height: '22px',
    boxShadow: 'none',
    backgroundColor: '#F8F9FD',
    cursor: 'pointer',
    ':hover': { borderColor: 'transparent' },
    ':active': { borderColor: 'transparent' },
  }),
  singleValue: (style: CSSProperties): CSSProperties => ({
    ...style,
    fontSize: 14,
    fontWeight: 400,
    margin: 0,
    color: 'rgba(0,0,0,0.65)',
  }),
  valueContainer: (style: CSSProperties): CSSProperties => ({
    ...style,
    padding: 0,
    margin: 0,
    height: '22px',
    color: '#F8F9FD',
  }),
  indicatorsContainer: (style: CSSProperties): {} => ({
    ...style,
    height: 22,
    alignSelf: 'center',
  }),
  input: (style: CSSProperties): any => ({
    ...style,
    div: { input: { ':hover': { border: '0 !important' } } },
  }),
  option: (base: CSSProperties, state: any): any => ({
    ...base,
    fontSize: 14,
    color: 'rgba(0, 0, 0, 0.65)',
    ':hover': {
      color: '#23335B',
      backgroundColor: '#1C9CE404',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
    },
    backgroundColor: state.isSelected && '#EFEFFF',
  }),
  indicatorSeparator: (style: CSSProperties): CSSProperties => ({
    ...style,
    display: 'none',
  }),
  placeholder: (style: CSSProperties): CSSProperties => ({
    ...style,
    color: '#9ba1ae',
  }),
};
export const dropdownIndicatorStyles = (disabledField?: boolean): any =>
  disabledField
    ? {
        dropdownIndicator: (style: CSSProperties): CSSProperties => ({
          ...style,
          display: 'none',
        }),
        singleValue: (style: CSSProperties): CSSProperties => ({
          ...style,
          color: '#9ba1ae',
        }),
      }
    : {};

export const selectStatusFieldStyle = (statusColor: any, disabledField?: boolean): any => ({
  container: (style: CSSProperties): CSSProperties => ({
    ...style,
    height: 29,
    width: 'fit-content',
  }),
  control: (style: CSSProperties): CSSProperties => ({
    // ...style,
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    fontWeight: 700,
    // maxWidth: 130,
    height: 29,
    cursor: 'pointer',
  }),
  valueContainer: (style: CSSProperties): CSSProperties => ({
    ...style,
    padding: 0,
    margin: 'auto 0',
  }),
  singleValue: (style: CSSProperties): CSSProperties => ({
    ...style,
    position: 'relative',
    maxWidth: '100%',
    overflow: 'hidden',
    transform: 'translateY(0);',
    WebkitTransform: 'translateY(0)',
    alignSelf: 'center',
    color: statusColor || '#23335B',
  }),
  indicatorsContainer: (style: CSSProperties): CSSProperties => ({
    ...style,
    alignSelf: 'center',
    color: statusColor || '#23335B',
  }),
  indicatorSeparator: (style: CSSProperties): {} => ({
    ...style,
    display: 'none',
  }),
  dropdownIndicator: (style: CSSProperties): CSSProperties => ({
    ...style,
    color: statusColor,
    display: disabledField ? 'none' : 'block',
  }),
  option: (style: any, { data }: any): CSSProperties => ({
    ...style,
    fontSize: 14,
    color: data.color,
    // textAlign: 'center',
  }),
  menu: (style: CSSProperties): {} => ({
    ...style,
    minWidth: 130,
  }),
});

export const selectTableStatusStyle = (statusColor: any): any => ({
  container: (style: CSSProperties): {} => ({
    ...style,
    height: 29,
  }),
  control: (): {} => ({
    width: 'fit-content',
    maxWidth: 200,
    display: 'flex',
    alignItems: 'center',
    fontSize: 15,
    fontWeight: 500,
    height: 29,
    cursor: 'pointer',
  }),
  valueContainer: (style: CSSProperties): {} => ({
    ...style,
    padding: 0,
    margin: 'auto 0',
  }),
  singleValue: (style: CSSProperties): {} => ({
    ...style,
    position: 'relative',
    maxWidth: '100%',
    overflow: 'hidden',
    transform: 'translateY(0);',
    WebkitTransform: 'translateY(0)',
    alignSelf: 'center',
    color: '#23335B',
    // color: statusColor || '#23335B',
  }),
  input: (style: CSSProperties): {} => ({
    ...style,
    // position: 'absolute',
  }),
  indicatorsContainer: (style: CSSProperties): {} => ({
    ...style,
    alignSelf: 'center',
    color: '#23335B',
    // color: statusColor || '#23335B',
  }),
  indicatorSeparator: (style: CSSProperties): {} => ({
    ...style,
    display: 'none',
  }),
  dropdownIndicator: (style: CSSProperties): {} => ({ ...style, color: '#23335B' }),
  option: (style: any, state: any): {} => ({
    ...style,
    fontSize: 15,
    // color: data.color,
    color: '#23335B',
    ':hover': {
      color: '#1C9CE4',
      backgroundColor: '#1C9CE404',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
    },
    backgroundColor: state.isSelected && '#F8F9FD',
  }),
  menuPortal: (style: CSSProperties): {} => ({
    ...style,
    width: 190,
  }),
});

export const dropdownIndicatorNone = (disabledField?: boolean) =>
  disabledField && {
    dropdownIndicator: (style: CSSProperties) => ({
      ...style,
      display: 'none',
    }),
  };

export const connectPatientSelectStyles = (disabledField: boolean) => {
  const disDropdownIndicator = disabledField ? disableDropdownIndicator : {};

  return {
    container: (style: CSSProperties) => ({
      ...style,
      minWidth: '100%',
      height: '50px',
      // color: '#23335B',
      color: 'rgba(0, 0, 0, 0.65)',
    }),
    control: (style: CSSProperties) => ({
      ...style,
      borderColor: '#F0F0F8',
      borderRadius: '4px',
      minHeight: '48px',
      height: '48px',
      boxShadow: 'none',
      backgroundColor: disabledField ? 'transparent' : 'hsl(0,0%,100%)',
      cursor: 'pointer',
      ':hover': { borderColor: '#1C9CE4' },
      ':active': { borderColor: '#1C9CE4' },
    }),
    input: (style: CSSProperties) => ({
      ...style,
      div: { input: { ':hover': { border: '0 !important' } } },
    }),
    singleValue: (style: CSSProperties) => ({
      ...style,
      color: disabledField ? '#9ba1ae' : 'rgba(0, 0, 0, 0.65)',
    }),
    valueContainer: (style: CSSProperties) => ({
      ...style,
      // color: '#23335B',
      color: 'rgba(0, 0, 0, 0.65)',
      fontSize: '17px',
    }),
    option: (style: CSSProperties, state: any) => ({
      ...style,
      fontSize: '17px',
      lineHeight: '18px',
      paddingTop: '11px',
      paddingBottom: '11px',
      paddingLeft: '15px',
      color: 'rgba(0, 0, 0, 0.65)',
      // color: '#23335B',
      ':hover': {
        color: '#1C9CE4',
        backgroundColor: '#1C9CE404',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
      },
      backgroundColor: state.isSelected && '#EFEFFF',
    }),
    indicatorSeparator: (style: CSSProperties) => ({
      ...style,
      display: 'none',
    }),
    placeholder: (style: CSSProperties) => ({
      ...style,
      color: '#9ba1ae',
    }),
    ...disDropdownIndicator,
  };
};

export const selectFloatLabelStyles = (disabledField: boolean) => {
  const disDropdownIndicator = disabledField ? disableDropdownIndicatorFloatLabel : {};

  return {
    container: (style: CSSProperties) => ({
      ...style,
      minWidth: '100%',
      height: '50px',
      color: '#23335B',
    }),
    control: (style: CSSProperties) => ({
      ...style,
      borderColor: '#FFFFFF',
      borderRadius: '4px',
      minHeight: '48px',
      height: '48px',
      boxShadow: 'none',
      backgroundColor: '#F8F9FD',
      cursor: 'pointer',
      ':hover': { borderColor: '#1C9CE4' },
      ':active': { borderColor: '#1C9CE4' },
    }),
    input: (style: CSSProperties) => ({
      ...style,
      div: { input: { ':hover': { border: '0 !important' } } },
      paddingTop: '14px',
    }),
    singleValue: (style: CSSProperties) => ({
      ...style,
      color: disabledField ? '#9ba1ae' : '#23335B',
      paddingTop: '14px',
    }),
    valueContainer: (style: CSSProperties) => ({
      ...style,
      color: '#23335B',
      fontSize: '17px',
      overflow: 'visible',
      paddingLeft: 13,
    }),
    option: (style: CSSProperties, state: any) => ({
      ...style,
      fontSize: '17px',
      lineHeight: '18px',
      paddingTop: '11px',
      paddingBottom: '11px',
      paddingLeft: '15px',
      color: '#23335B',
      ':hover': {
        color: '#1C9CE4',
        backgroundColor: '#1C9CE404',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
      },
      backgroundColor: state.isSelected && '#F8F9FD',
    }),
    indicatorSeparator: (style: CSSProperties) => ({
      ...style,
      display: 'none',
    }),
    placeholder: (style: CSSProperties, state: any) => ({
      ...style,
      color: '#8A93A6',
      position: 'absolute',
      top: state.hasValue || state.selectProps.inputValue ? 10 : '50%',
      left: 15,
      transition: 'top 0.1s, font-size 0.1s',
      fontSize: state.hasValue || state.selectProps.inputValue ? 12 : 18,
      fontWeight: state.hasValue || state.selectProps.inputValue ? 500 : 400,
    }),
    dropdownIndicator: (style: CSSProperties): CSSProperties => ({
      ...style,
      color: '#23335B',
    }),
    ...disDropdownIndicator,
  };
};

export const selectStyleProfileStatus = (): any => ({
  container: (style: CSSProperties): {} => ({
    ...style,
    height: 29,
    width: '100%',
  }),
  control: (): {} => ({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    fontSize: 15,
    fontWeight: 500,
    height: 29,
    cursor: 'pointer',
  }),
  valueContainer: (style: CSSProperties): {} => ({
    ...style,
    padding: 0,
    margin: 'auto 0',
  }),
  singleValue: (style: CSSProperties): {} => ({
    ...style,
    position: 'relative',
    width: '100%',
    overflow: 'hidden',
    transform: 'translateY(0);',
    WebkitTransform: 'translateY(0)',
    alignSelf: 'center',
    color: '#23335B',
  }),
  input: (style: CSSProperties): {} => ({
    ...style,
  }),
  indicatorsContainer: (style: CSSProperties): {} => ({
    ...style,
    alignSelf: 'center',
    color: '#23335B',
  }),
  indicatorSeparator: (style: CSSProperties): {} => ({
    ...style,
    display: 'none',
  }),
  dropdownIndicator: (style: CSSProperties): {} => ({
    ...style,
    color: '#23335B',
  }),
  option: (style: any, state: any): {} => ({
    ...style,
    fontSize: 15,
    color: '#23335B',
    ':hover': {
      color: '#1C9CE4',
      backgroundColor: '#1C9CE404',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
    },
    backgroundColor: state.isSelected && '#F8F9FD',
  }),
  // menuPortal: (style: CSSProperties): {} => ({
  //   ...style,
  //   width: '190px',
  // }),
});

export const selectPersonalInfoStatusStyle = (statusColor: any): any => ({
  container: (style: CSSProperties): {} => ({
    ...style,
    display: 'flex',
    alignItems: 'center',
    minWidth: 190,
    width: '100%',
    height: 35,
    borderRadius: 50,
    backgroundColor: hexToRgbA(statusColor, 0.1), // 'rgba(28, 186, 102, 0.1)',
  }),
  control: (): {} => ({
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    fontWeight: 500,
    width: '100%',
    height: 35,
    cursor: 'pointer',
  }),
  singleValue: (style: CSSProperties): {} => ({
    ...style,
    width: '100%',
    alignSelf: 'center',
    color: statusColor || '#23335B',
  }),
  valueContainer: (style: CSSProperties): {} => ({
    ...style,
    paddingRight: 0,
    textAlign: 'center',
  }),
  input: (style: CSSProperties): {} => ({
    ...style,
  }),
  // menu: (style: CSSProperties): {} => ({
  //   ...style,
  // }),
  indicatorsContainer: (style: CSSProperties): {} => ({
    ...style,
    alignSelf: 'center',
    color: statusColor || '#23335B',
  }),
  indicatorSeparator: (style: CSSProperties): {} => ({
    ...style,
    display: 'none',
  }),
  dropdownIndicator: (style: CSSProperties): {} => ({ ...style, color: statusColor }),
  option: (style: any, { data }: any): {} => ({
    ...style,
    fontSize: 14,
    color: data.color,
  }),
  menuPortal: (style: CSSProperties): {} => ({
    ...style,
    minWidth: 190,
  }),
});
