import React, { ReactElement } from 'react';
import cx from 'classnames';

import styles from './styles.module.css';
import { maxPcsValue, minPcsValue } from '../../../utils/helper_charts';

const PcsChart = ({ nameChart, pcsScore, pcsSkip }: any): ReactElement => (
  <div
    className={cx({
      [styles.proms__pcs]: true,
    })}
  >
    <div
      className={cx({
        [styles['proms__pcs-name']]: true,
      })}
    >
      {nameChart}
    </div>
    <div
      className={cx({
        [styles.total]: true,
        [styles['proms__fontSize-value--proms-tab']]: true,
      })}
    >
      <span className={styles.max_value}>{maxPcsValue}</span>
      <div
        className={styles.pcs_graph}
        style={{
          display: !pcsScore ? 'none' : 'block',
          height: pcsScore ? `${(100 * pcsScore) / maxPcsValue}%` : 0,
        }}
      >
        {pcsScore && <span className={styles.pcs_value}>{pcsScore}</span>}
      </div>
      {pcsSkip && <span className={styles.skip}>{pcsSkip}</span>}
      <span className={styles.min_value}>{minPcsValue}</span>
    </div>
  </div>
);

export default PcsChart;
