import React from 'react';
import { Menu } from 'antd';
import cx from 'classnames';

import style from './styles.module.css';
import {
  SortSearchFilterItem,
  SortSearchItem,
  SortSearchPickerItem,
} from '../../OrganisationVideoBank/components/HeaderItems';
import { RoundCheckbox } from '../../../../common/Input';
import { getHistoryMenuConfig } from '../../../../utils/variables';
import { CrossSmIcon } from '../../../../theme/icons';

const ExercisePrescriptionsTableHeader = ({
  t,
  sortBy,
  sortByColumnName,
  isInvitee,
  handleResetLastActiondate,
  handleSearchByLastActionDate,
  hideDatePicker,
  setHideDatePicker,
  queryPicker,
  setQueryPicker,
  isDatePickerSearch,
  hideMenuByHistoryStatus,
  setHideMenuByHistoryStatus,
  isFilterStatus,
  setFilteringHistoryStatus,
  filteringHistoryStatus,
  getFilteredData,
  resetFilter,
  dataQa,
}: any): JSX.Element => {
  const exercise_name = t?.dashboard.hcp.profile_patient.video_bank.exercise_name;
  const last_action_date = t?.dashboard.hcp.profile_patient.last_action_date;
  const status = t?.dashboard.hcp.profile_patient.video_bank.status;
  const ok = t?.common.ok;

  // Change status filter
  const onCheckFilter = (e: any, value: string): void => {
    const { checked } = e.target;
    if (!checked) {
      setFilteringHistoryStatus('');
      return;
    }
    setFilteringHistoryStatus(value);
  };

  const menu = (
    <Menu>
      {getHistoryMenuConfig(t).map((menuItem: any, indx: number) => (
        <Menu.Item key={String(indx + 1)}>
          <RoundCheckbox
            htmlId={`Filter${menuItem.value}`}
            name="statusFilter"
            label={menuItem.label}
            checked={filteringHistoryStatus === menuItem.value}
            onChangeMethod={(e: any): void => onCheckFilter(e, menuItem.value)}
            isValid
            isTouched
            hasErrors={false}
          />
        </Menu.Item>
      ))}
      <Menu.Item key="7">
        <div className={style['btn-container']}>
          <button
            type="button"
            className={cx({
              [style['btn-x']]: true,
              [style['btn-disable']]: !filteringHistoryStatus,
            })}
            disabled={!filteringHistoryStatus}
            onClick={resetFilter}
            data-qa={dataQa}
          >
            <CrossSmIcon />
          </button>
          <button
            data-qa={dataQa}
            type="button"
            className={style['btn-ok']}
            onClick={getFilteredData}
          >
            {ok}
          </button>
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={style.history__header}>
      <div className={style['history__exercise-name-container']}>
        <SortSearchItem
          fieldName={exercise_name}
          t={t}
          sortName={isInvitee ? 'invitee_exercise.name' : 'exercise.name'}
          sortByColumnName={sortByColumnName}
          sortBy={sortBy}
        />
      </div>
      <div className={style['history__date-container']}>
        <SortSearchPickerItem
          fieldName={last_action_date}
          t={t}
          sortName={
            isInvitee ? 'invitee_exercise_history.updateDate' : 'exercise_history.updateDate'
          }
          sortByColumnName={sortByColumnName}
          sortBy={sortBy}
          withSearch
          isDatePickerSearch={isDatePickerSearch}
          handleSearchByLastActionDate={handleSearchByLastActionDate}
          handleResetLastActiondate={handleResetLastActiondate}
          hideDatePicker={hideDatePicker}
          setHideDatePicker={setHideDatePicker}
          setQueryPicker={setQueryPicker}
          queryPicker={queryPicker}
        />
      </div>
      <div className={style['history__status-container']}>
        <SortSearchFilterItem
          fieldName={status}
          t={t}
          sortName={
            isInvitee
              ? 'invitee_exercise_history.roleWhoChanged'
              : 'exercise_history.roleWhoChanged'
          }
          sortByColumnName={sortByColumnName}
          sortBy={sortBy}
          withSearch
          menu={menu}
          hideSearchMenu={hideMenuByHistoryStatus}
          setHideSearchMenu={setHideMenuByHistoryStatus}
          isFilterStatus={isFilterStatus}
        />
      </div>
    </div>
  );
};

export default ExercisePrescriptionsTableHeader;
