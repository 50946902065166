import React, { ReactElement } from 'react';
import { CustomLinkProps } from './types';
import styles from './styles.module.css';

export const CustomLink = ({
  buttonName,
  buttonMethod,
  disabledButton = false,
  dataQa,
}: CustomLinkProps): ReactElement => (
  <button
    className={styles.btn_link}
    type="button"
    disabled={disabledButton}
    onClick={buttonMethod}
    aria-hidden
    data-qa={dataQa}
  >
    {buttonName}
  </button>
);
