import React, { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';

import styles from './styles.module.css';
import { getCurrenLang } from '../../redux/selector';
import { CloseIcon, DeleteIcon } from '../../theme/icons';
import { validationFeedbackForm } from '../../utils/validators';
import { Button, InputFeild, InputFormik, Loading, SelectField } from '../../common';
import { capitalizeFirstLetter, getSelectOptions, uploadFiles } from '../../utils/helper';
import { InputTypeFile, TextareaFeild } from '../../common/Input';
import { useCreateHcpHelp, useGetSubjects } from '../../graphql/Feedback';
import { Popup, PopupWithTwoButtons } from '../../common/Popup';
import { storage } from '../../utils';
import { config } from '../../utils/configs';
import { normalizePhoneNumber } from '../../utils/regx';

const Feedback: FC = () => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const contact_otm = t?.help.contact_otm;
  const description_first = t?.help.description_first;
  const description_second = t?.help.description_second;
  const email_t = t && t.dashboard.admin.profile_patient.email;
  const call = t?.help.call;
  const want_to_call_us_instead = t?.help.want_to_call_us_instead;
  const first_name = t?.dashboard.admin.profile_patient.first_name;
  const last_name = t?.dashboard.admin.profile_patient.last_name;
  const description = t?.common.description;
  const placeholder = t?.help.description_placeholder;
  const subject = t?.help.subject;
  const subject_placeholder = t?.help.subject_placeholder;
  const attachments = t?.help.attachments_optional;
  const add_file = t?.dashboard.hcp.profile_patient.add_file;
  const delete_all = t?.help.delete_all;
  const confirm_delete_attachments = t?.help.confirm_delete_attachments;
  const yes = t && t.common.yes;
  const no = t && t.common.no;
  const ok = t && t.notifications.ok;
  const submit = t && t.common.submit;
  const large_file = t?.help.large_file;
  const large_file_10 = large_file?.replace('<size>', '10MB');
  const wrong_format = t?.help.wrong_format;
  const success_request = t?.help.success_request;
  const warning_message = t?.help.warning_message;
  const fill_mandatory_fields = t?.validation.fill_mandatory_fields;
  const file_not_loaded = t?.help.file_not_loaded;

  const initErrState = {
    error: false,
    format: false,
    heavy: false,
  };

  const [fullScreen, setFullScreen] = useState(false);
  const [fullScreenDilay, setFullScreenDilay] = useState(false);
  const [fileInputKey, setFileInputKey] = useState<number>(Date.now());
  const [loading, setLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [fileSizes, setFileSizes] = useState(0);
  const [errorMessage, setErrorMessage] = useState(JSON.parse(JSON.stringify(initErrState)));
  const [confirmDelAttModal, setConfirmDelAttModal] = useState(false);
  const [successPopup, setSuccessPopup] = useState(false);
  const [warningPopup, setWarningPopup] = useState(false);
  const [warningFromVideoservice, setWarningFromVideoservice] = useState(false);

  const { allHelpSubjects } = useGetSubjects();
  const { _createHcpHelp, createdEmail, emailerror, emailLoading } = useCreateHcpHelp();

  const subjectOptions = useMemo(() => getSelectOptions(allHelpSubjects), [allHelpSubjects]);

  // TO DO to add variable to .env file
  const helpPhoneNumberFromEnv = config.helpPhoneNumber || '+44-(0)-333-200-4502';
  const helpPhoneNumber = helpPhoneNumberFromEnv.replace(normalizePhoneNumber, ' ');
  const helpPhoneNumberLink = config.helpPhoneNumberLink || '+443332004502';

  const user = storage.get('user');
  const { token } = user;

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    subject: '',
    description: '',
    file: '',
  };

  const send = async (data: any): Promise<void> => {
    setLoading(() => true);
    const urlSecondPartPic = 'pictures/upload-hcp-help-pic';
    const urlSecondPartVideo = 'video/uploadHcpVideoHelp';
    const { attachmentVideo, attachmentPic, isLoadingErr } = await uploadFiles(
      selectedFiles,
      urlSecondPartPic,
      urlSecondPartVideo,
      token,
      false,
    );
    setLoading(() => false);
    if (isLoadingErr) {
      setWarningFromVideoservice(() => true);
      return;
    }
    const request: any = {
      variables: {
        hcpHelp: {
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          subjectId: +data.subject.value,
          descriptions: data.description,
        },
      },
    };

    if (attachmentVideo.length > 0) request.variables.attachmentVideo = attachmentVideo;
    if (attachmentPic.length > 0) request.variables.attachmentPic = attachmentPic;
    _createHcpHelp(request);
  };

  useEffect(() => {
    if (createdEmail) {
      setSuccessPopup(() => true);
    }
  }, [createdEmail]);

  useEffect(() => {
    if (emailerror) {
      toast.error(emailerror);
    }
  }, [emailerror]);

  const formik = useFormik({
    initialValues,
    validationSchema: () => validationFeedbackForm(t),
    onSubmit: (values) => {
      if (values) {
        send(values);
      }
    },
  });

  const inputValue = formik.values;
  const { isValid } = formik;
  const hasErrors = formik.errors;
  const isTouched = formik.touched;

  // Check the incoming file for the correct format and size
  const onCheckIncomingFiles = async (e: any): Promise<void> => {
    setFileInputKey(Date.now());
    setLoading(() => true);
    let size = fileSizes;
    let wrongFormat = false;
    try {
      const f = e.target.files;
      const normalizeFiles: any = [];
      const filesArray = Array.from(f);
      if (filesArray.length) {
        filesArray.forEach((file: any) => {
          size += file.size;
          const { type } = file;
          const fileName = file.name.toLowerCase();
          // const prew = window.URL.createObjectURL(file);
          const newFile = new File([file], fileName, { type });
          // const newFile = new File([file], file.name.toLowerCase());
          if (
            type !== 'image/jpeg' &&
            type !== 'image/jpg' &&
            type !== 'image/png' &&
            type !== 'video/mp4' &&
            type !== 'video/quicktime'
          ) {
            wrongFormat = true;
          }
          normalizeFiles.push(newFile);
        });
      }
      if (wrongFormat) {
        setErrorMessage({
          error: true,
          format: true,
          heavy: false,
        });
      } else if (size > 10485760) {
        setErrorMessage({
          error: true,
          format: false,
          heavy: true,
        });
      } else {
        setFileSizes(size);
        setSelectedFiles([...selectedFiles, ...normalizeFiles]);
      }
      setLoading(() => false);
    } catch (error) {
      setLoading(() => false);
      console.error('Upload file error:', error);
    }
  };

  // Open Feedback form
  const openFeedbackForm = (): void => {
    setFullScreen(() => true);
    setFullScreenDilay(() => true);
  };

  // Close Feedback form
  const closeFeedbackForm = (): void => {
    setFullScreen(() => false);
    setSelectedFiles([]);
    setFileSizes(0);
    formik.resetForm();
    setTimeout(() => {
      setFullScreenDilay(() => false);
    }, 1500);
  };

  // Before closure form check data
  const checkForm = (): void => {
    let isWarning = false;
    if (selectedFiles.length > 0) {
      isWarning = true;
    } else if (
      inputValue.description ||
      inputValue.email ||
      inputValue.firstName ||
      inputValue.lastName ||
      inputValue.subject
    ) {
      isWarning = true;
    }
    if (isWarning) {
      setWarningPopup(() => true);
    } else {
      closeFeedbackForm();
    }
  };

  // Delete elements from attachments
  const deleteAttachments = (type: string, index: number): void => {
    switch (type) {
      case 'single':
        {
          let newSizes = fileSizes;
          newSizes -= selectedFiles[index].size;
          if (newSizes < 0) {
            newSizes = 0;
          }
          setFileSizes(newSizes);
          const temp = [...selectedFiles];
          temp.splice(index, 1);
          setSelectedFiles(temp);
        }
        return;
      case 'all':
        setConfirmDelAttModal(() => true);
        break;
      default:
    }
  };

  // Delete all attached files
  const _onConfirm = (): void => {
    setConfirmDelAttModal(() => false);
    setSelectedFiles([]);
    setFileSizes(0);
  };

  // Close success popap and feedback form
  const closeSuccessPopup = (): void => {
    setSuccessPopup(() => false);
    closeFeedbackForm();
  };

  // We listen to the resize event
  window.addEventListener('resize', () => {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    const vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });

  // JSX
  const loadingJSX = loading || emailLoading ? <Loading /> : <></>;

  return (
    <>
      {loadingJSX}
      {fullScreenDilay ? (
        <div
          className={cx({
            [styles.feedback__container]: fullScreen,
            [styles['feedback__container-close']]: !fullScreen,
          })}
        >
          <div className={styles['feedback__title-cont']}>
            <div className={styles.feedback__title}>{contact_otm}</div>
            <div className={styles.feedback__close}>
              <CloseIcon onClick={checkForm} />
            </div>
          </div>
          <div className={styles.feedback__descr}>{description_first}</div>
          <div className={styles.feedback__descr}>{description_second}</div>
          <div className={styles.feedback__title}>{capitalizeFirstLetter(email_t)}</div>
          {/* FORM */}
          <form onSubmit={formik.handleSubmit}>
            <div className={styles.form_wrapper}>
              <div className={styles.row}>
                <div className={styles.cell}>
                  <InputFeild
                    fieldId="Feedback_firstName"
                    inputName="firstName"
                    labelName={first_name}
                    inputType="text"
                    inputValue={inputValue.firstName}
                    hasErrors={hasErrors.firstName}
                    isTouched={isTouched.firstName}
                    onChecngeMethod={formik.handleChange}
                  />
                </div>
                <div className={styles.cell}>
                  <InputFeild
                    fieldId="Feedback_lastName"
                    inputName="lastName"
                    labelName={last_name}
                    inputType="text"
                    inputValue={inputValue.lastName}
                    hasErrors={hasErrors.lastName}
                    isTouched={isTouched.lastName}
                    onChecngeMethod={formik.handleChange}
                  />
                </div>
              </div>
              <div className={styles.row}>
                <InputFormik
                  inputName="email"
                  inputType="text"
                  labelName={email_t}
                  hasErrors={formik.errors.email}
                  inputValue={inputValue.email}
                  isTouched={inputValue.email || formik.touched.email}
                  onChecngeMethod={formik.handleChange}
                />
              </div>
              <div className={styles.row}>
                <SelectField
                  name="subject"
                  onChange={formik.setFieldValue}
                  isValid={isValid}
                  hasErrors={hasErrors.subject}
                  isTouched={formik.touched.subject}
                  inputValue={formik.values.subject}
                  labelName={subject}
                  options={subjectOptions}
                  placeholder={subject_placeholder}
                  type="feedback form"
                />
              </div>
              <div className={styles.row}>
                <TextareaFeild
                  id="helpDescription"
                  inputName="description"
                  labelName={description}
                  placeholder={placeholder}
                  inputValue={inputValue.description}
                  hasErrors={hasErrors.description}
                  isTouched={isTouched.description}
                  onChecngeMethod={formik.handleChange}
                  rows={4}
                />
              </div>
              <div className={styles.row}>
                <InputTypeFile
                  id="Feedback"
                  inputName="file"
                  labelName={attachments}
                  hasErrors={formik.errors.file}
                  onChecngeMethod={onCheckIncomingFiles}
                  placeholder={add_file}
                  // disabled={loading}
                  fileInputKey={fileInputKey}
                  // accept="video/mp4, video/quicktime, image/png, image/jpeg, image/jpg"
                  accept=".mp4, .mov, .hevc, image/png, image/jpeg, image/jpg"
                  isMultiple
                  isTouched
                />
              </div>
              {selectedFiles.length > 0 ? (
                <div>
                  {selectedFiles.map((file: { name: string }, index: number) => (
                    <div key={`file${String(index)}`} className={styles['attachments-row']}>
                      <div className={styles['feedback__file-name']}>{file.name}</div>
                      <div
                        className={styles['feedback__delete-icon']}
                        onClick={(): void => deleteAttachments('single', index)}
                        role="presentation"
                      >
                        <DeleteIcon />
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <></>
              )}
              {selectedFiles.length > 1 ? (
                <div className={styles['feedback__delete-all-cont']}>
                  <div
                    className={styles['feedback__delete-all']}
                    onClick={(): void => deleteAttachments('all', 0)}
                    role="presentation"
                  >
                    {delete_all}
                  </div>
                </div>
              ) : (
                <></>
              )}
              {Object.keys(hasErrors).length && Object.keys(isTouched).length ? (
                <div className={styles.feedback__error}>{fill_mandatory_fields}</div>
              ) : (
                <></>
              )}
              <Button
                disabledButton={loading || emailLoading}
                buttonType="submit"
                buttonName={submit}
                buttonClass={styles.btn__submit}
              />
            </div>
          </form>

          <div className={styles.feedback__title}>{call}</div>
          <div className={styles['feedback__call-descr']}>{want_to_call_us_instead}</div>
          <div className={styles['feedback__call-descr']}>
            <a href={`tel:${helpPhoneNumberLink}`}>
              {call} {helpPhoneNumber}
            </a>
          </div>
        </div>
      ) : (
        <div className={styles.feedback__icon} onClick={openFeedbackForm} role="presentation">
          ?
        </div>
      )}

      {confirmDelAttModal && (
        <PopupWithTwoButtons
          content=""
          title={confirm_delete_attachments}
          confirmButtonName={yes}
          closeButtonName={no}
          onConfirm={_onConfirm}
          onClosePopup={(): void => setConfirmDelAttModal(() => false)}
        />
      )}
      {errorMessage.error && (
        <Popup
          content=""
          title={errorMessage.heavy ? large_file_10 : wrong_format}
          buttonName={ok}
          onClosePopup={(): void => setErrorMessage(JSON.parse(JSON.stringify(initErrState)))}
        />
      )}
      {successPopup && (
        <Popup
          content=""
          title={success_request}
          buttonName={ok}
          onClosePopup={closeSuccessPopup}
        />
      )}
      {warningPopup && (
        <PopupWithTwoButtons
          content=""
          title={warning_message}
          confirmButtonName={yes}
          closeButtonName={no}
          onConfirm={(): void => {
            closeFeedbackForm();
            setWarningPopup(() => false);
          }}
          onClosePopup={(): void => setWarningPopup(() => false)}
        />
      )}
      {warningFromVideoservice && (
        <Popup
          content=""
          title={file_not_loaded}
          buttonName={ok}
          onClosePopup={(): void => setWarningFromVideoservice(() => false)}
        />
      )}
    </>
  );
};

export default Feedback;
