import React, { ReactElement, useState } from 'react';
import cx from 'classnames';

import style from './style.module.css';
import { ClockSmIcon, FolderIcon } from '../../../theme/icons';
import PrescribedExerciseItem from './PrescribedExerciseItem';

const PrescribedFolder = ({ data }: any): ReactElement => {
  const [isActiveFolder, setActiveFolder] = useState(false);

  return (
    <>
      <div
        className={style['prescribed-item__row--folder']}
        onClick={(): void => setActiveFolder(() => !isActiveFolder)}
        role="presentation"
      >
        {/* Thumbnail */}
        <div className={style['prescribed-folder__block']}>
          <div
            className={cx({
              [style.preview__folder]: true,
            })}
          >
            <FolderIcon width="30px" height="30px" />
            {data.hcpFolderTimer?.amount && (
              <div className={style['prescribed-item__folder-clock']}>
                <ClockSmIcon />
              </div>
            )}
          </div>
        </div>
        {/* Name */}
        <div className={style['prescribed-item__name--folder']}>{data.name}</div>
      </div>

      {/* Nested Exercises */}
      {isActiveFolder ? (
        data?.folderExercises.length &&
        data?.folderExercises.map((videoExercise: any, i: number) => (
          <div
            className={style['folder__exercise-container']}
            key={`${videoExercise.name}${String(i)}`}
          >
            <PrescribedExerciseItem data={videoExercise} />
          </div>
        ))
      ) : (
        <></>
      )}
    </>
  );
};

export default PrescribedFolder;
