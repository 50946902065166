export const otmConfig = () => ({
  editPatientProfileInfo_otm: true,
  inviteOrAddHcp_otm: true,
  editOrPrescribeExerciseVideos_otm: true,
  addVideosToTeamVideoBank_otm: true,
  createTeam_otm: false,
});
export const hcpConfig = () => ({
  editPatientProfileInfo_hcp: true,
  inviteOrAddHcp_hcp: true,
  editOrPrescribeExerciseVideos_hcp: true,
  addVideosToTeamVideoBank_hcp: true,
});

export const additionalFieldsAdmin = () => ([
  { key: 'otm', otm: Object.keys(otmConfig()) },
  { key: 'hcp', hcp: Object.keys(hcpConfig()) },
]);
