import React, { useEffect } from 'react';
import cx from 'classnames';
import { useSelector } from 'react-redux';

import styles from './styles.module.css';
import { useQueryPhotoPic, useQueryThumbnailPic } from '../../../../graphql/videoBank';
import { PrescriptionProps } from '../types';
import { getCurrenLang } from '../../../../redux/selector';
import { ThumbnailJsx } from '../../../../common/ShowMediaIcon';
import { getOwnerAction } from '../../../../utils/helper';

const Prescription = ({ data }: PrescriptionProps): JSX.Element => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const last_action_date = t?.dashboard.hcp.profile_patient.last_action_date;
  const action_taken = t?.dashboard.hcp.profile_patient.action_taken;
  const change_by_hcp = t?.dashboard.hcp.profile_patient.video_bank.change_by_hcp;
  const change_by_patient = t?.dashboard.hcp.profile_patient.video_bank.change_by_patient;
  const owner_action = t?.dashboard.hcp.table.status;
  const loading = t?.header.loading;

  // get photo picture from AWS by Uuid
  const { _getThumbnailPic, thumbnailPic, loadingThumbnailPic } = useQueryThumbnailPic();

  // get photo picture from AWS by Uuid
  const { _getPhotoPic, photoPic, loadingPhotoPic } = useQueryPhotoPic();

  const isPhotosExist = data.photos?.length;
  const photos = isPhotosExist ? data.photos : [];

  // Get thumbnail source link
  useEffect(() => {
    if (data.thumbnailName) {
      _getThumbnailPic({
        variables: {
          thumbnailKey: data.thumbnailName,
        },
      });
      return;
    }
    if (isPhotosExist) {
      _getPhotoPic({
        variables: {
          pictureUuid: photos[0].fileUuid,
        },
      });
    }
  }, []);

  const loadingImg = loadingPhotoPic || loadingThumbnailPic;

  return (
    <div className={styles.prescription__row}>
      {/* Thumbnail */}
      <div>
        {!loadingImg && (
          <div className={styles.preview}>
            {!loadingImg && <ThumbnailJsx thumbLink="" photoPic={photoPic || thumbnailPic} />}
          </div>
        )}
        {loadingImg && <div className={styles.isUploading}>{loading}...</div>}
      </div>

      {/* Description */}
      <div
        className={cx({
          [styles['prescription__description-container']]: true,
        })}
      >
        <div className={styles.prescription__name}>{data.exerciseName}</div>
        <div className={styles.prescription__date}>
          {`${last_action_date}: ${data.dateOfLastAction || '-'}`}
        </div>
        <div className={styles.prescription__comment}>{`${action_taken}: ${data.actionTaken}`}</div>
        {data?.roleWhoChanged ? (
          <div className={styles.prescription__comment}>
            {`${owner_action}: ${getOwnerAction(
              data.roleWhoChanged,
              change_by_hcp,
              change_by_patient,
            )}`}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Prescription;
