import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { toast } from 'react-toastify';

import style from './style.module.css';
import { getCurrenLang } from '../../../redux/selector';
import { EXERCISE_TYPE } from '../../../utils/enums';
import { useQueryPhotoPic, useQueryThumbnail, useQueryVideo } from '../../../graphql/videoBank';
import { ExerciseFileType } from '../../../utils/model';
import { COLOR_STATUS_VIDEOBANK } from '../../../utils/variables';
import { PhotoSliderModal, VideoModal } from '../../../common/VideoModal';
import { ShowMediaIcon, ThumbnailJsx } from '../../../common/ShowMediaIcon';

const PrescribedExerciseItem = ({ data }: any): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  // get image from AWS by Uuid
  const { _getThumbnailByName, thumbnail } = useQueryThumbnail();

  // get photo picture from AWS by Uuid
  const { _getPhotoPic, photoPic } = useQueryPhotoPic();

  // get video from AWS by uuid
  const { _getVideoByName, videoSrc, errorVideo } = useQueryVideo();
  // const [isUploadingToAWS, setIsUploadingToAWS] = useState(false);

  // Local state
  const [isVideoModalOpen, setVideoModal] = useState(false);
  const [isPhotoModalOpen, setPhotoModal] = useState(false);
  const [videoSrcState, setVideoSrcState] = useState<any>('');

  const exerciseFileType: ExerciseFileType = data.type;
  const isPhotosExist = !!data.exercisePhotos?.length;
  const exercisePhotos = isPhotosExist ? data.exercisePhotos : [];
  const { status } = data;

  const statusOptions = status ? COLOR_STATUS_VIDEOBANK(t)[status] : 'Inactive';

  // Get thumbnail
  useEffect(() => {
    if (data.thumbnailName) {
      _getThumbnailByName({
        variables: {
          thumbnailKey: data.thumbnailName,
        },
      });
      return;
    }
    if (isPhotosExist) {
      _getPhotoPic({
        variables: {
          pictureUuid: exercisePhotos[0].fileUuid,
        },
      });
    }
  }, [data.thumbnailName, data.exercisePhotos]);

  // Open video modal window
  useEffect(() => {
    if (videoSrc) {
      setVideoSrcState(videoSrc);
      setVideoModal(() => true);
    }
  }, [videoSrc]);

  useEffect(() => {
    if (errorVideo) {
      toast.error(errorVideo);
    }
  }, [errorVideo]);

  // Video modal window handler
  const showMedia = (): void => {
    switch (exerciseFileType) {
      case EXERCISE_TYPE.VIDEO:
        if (data.videoName) {
          _getVideoByName({
            variables: {
              videoKey: data.videoName,
            },
          });
        }
        return;
      case EXERCISE_TYPE.PHOTO:
        if (photoPic) {
          setPhotoModal(() => !isPhotoModalOpen);
        }
        return;
      default:
        setPhotoModal(() => false);
    }
  };

  // Close open modal windows
  const onCloseModal = (): void => {
    setVideoModal(() => false);
    setPhotoModal(() => false);
  };

  const isMediaExist = thumbnail || data.videoName || isPhotosExist;

  return (
    <div className={style['prescribed-item__row']}>
      {/* Thumbnail */}
      <div className={style['prescribed-item__block']}>
        <div
          className={cx({
            [style.preview]: true,
            [style['preview-not-allowed']]: !isMediaExist,
          })}
          role="presentation"
          onClick={isMediaExist ? showMedia : (): null => null}
        >
          <ThumbnailJsx thumbLink={thumbnail} photoPic={photoPic} />
          <ShowMediaIcon thumbLink={thumbnail} photoPic={photoPic} />
        </div>
      </div>

      {/* Name */}
      <div className={style['prescribed-item__name']}>{data.name}</div>

      {/* Status */}
      <div style={{ color: statusOptions.color }} className={style['prescribed-item__status']}>
        {statusOptions.label}
      </div>

      {/* Popups */}
      {/* Video content */}
      {isVideoModalOpen && <VideoModal videoSrc={videoSrcState} onclose={onCloseModal} />}
      {/* Image content */}
      {isPhotoModalOpen && (
        <PhotoSliderModal
          exercisePhotos={isPhotosExist ? exercisePhotos : []}
          photoPreviews={data.photoPreviews ? data.photoPreviews : []}
          onclose={onCloseModal}
        />
      )}
    </div>
  );
};

export default PrescribedExerciseItem;
