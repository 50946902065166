import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';

import pageStyles from '../../styles.module.css';
import { getCurrenLang } from '../../../redux/selector';
import { BreadcrumbAnt } from '../../../utils/routers/Breadcrumb';
import ManageProms from '../../../components/ManageProms';

const ManagePromsPage = (): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const manage_proms = t?.menu.manage_proms; // Breadcrumb path
  const routes = [
    {
      path: manage_proms,
      breadcrumbName: manage_proms,
    },
  ];

  return (
    <div className={pageStyles.main__container}>
      <BreadcrumbAnt currentRoutes={routes} />
      <div className={pageStyles.main__wrapper}>
        <div className={pageStyles['left-section']}>
          <ManageProms />
        </div>
      </div>
    </div>
  );
};
export default ManagePromsPage;
