/* eslint-disable @typescript-eslint/ban-ts-ignore */
import React, { ReactElement } from 'react';
import { Pagination, Spin, Tooltip } from 'antd';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';

import { Checkbox } from '../../common/Input';
import styles from './styles.module.css';
import { getTooltipText, showTheStatus } from '../../utils/helper';
import storage from '../../utils/storage';
import {
  HcpAvctiveViewProps,
  HcpList,
  HcpListByPatientProps,
  HcpViewProps,
  ListActiveHcpProps,
  ListHcpByPatient,
  ListHcpProps,
} from './types';
import { getCurrenLang } from '../../redux/selector';
import { StatusKeyNameEnum } from '../../utils/enums';

const user = storage.get('user');

const HcpView = ({
  onUpdateCheckbox,
  name,
  invite,
  email,
  lastname,
  status,
  hcpId,
}: HcpViewProps): ReactElement => {
  const item = status !== StatusKeyNameEnum.active ? styles.list_invited : null;
  return (
    <>
      {status !== StatusKeyNameEnum.active ? (
        <li className={`${styles.list}`}>
          <div className={styles.list_content}>
            <Checkbox onChengeMehtod={onUpdateCheckbox} checked={invite} />
            <span className={styles.fullName}>
              <span className={`${styles.name} ${item}`}>{`${name}, ${lastname}`}</span>
              <span className={styles.email}>{email}</span>
            </span>
          </div>
        </li>
      ) : (
        <li className={`${styles.list_invited}`}>
          <div className={styles.list_content}>
            <Checkbox
              onChengeMehtod={onUpdateCheckbox}
              checked={invite}
              disabled={user.role !== 'admin' && hcpId === user.id}
            />
            <span className={styles.fullName}>
              <span className={`${styles.name} ${item}`}>{`${name}, ${lastname}`}</span>
              <span className={styles.email}>{email}</span>
            </span>
          </div>
        </li>
      )}
    </>
  );
};

const HcpAvctiveView = ({
  onUpdateCheckbox,
  name,
  invite,
  email,
  lastname,
  status,
  selected,
  hospitalName,
  tooltip,
  dataQa,
}: HcpAvctiveViewProps): ReactElement => {
  return (
    // @ts-ignore
    <Tooltip title={tooltip} zIndex={5000}>
      <div key={email}>
        {status === StatusKeyNameEnum.active ||
        status === StatusKeyNameEnum.connected ||
        status === StatusKeyNameEnum.treatment_completed ||
        status === StatusKeyNameEnum.pending ? (
          <li className={`${styles.listHcp}`}>
            <div className={styles.listHcp_content}>
              <input
                checked={invite}
                type="checkbox"
                onChange={onUpdateCheckbox}
                disabled={selected}
                className={styles.listHcp_checkbox}
                data-qa={dataQa}
              />
              <div className={styles.fullName__cont}>
                <div
                  className={cx({
                    [styles.hcpName]: true,
                    [styles.list_invited]: selected,
                  })}
                >
                  {`${name}, ${lastname}`}
                </div>
                <div className={styles.hcpEmail}>{email}</div>
              </div>
            </div>
            {hospitalName && <div className={styles.hcpHospitalName}>{hospitalName}</div>}
          </li>
        ) : (
          <></>
        )}
      </div>
    </Tooltip>
  );
};

const ListActiveHcp = ({
  list,
  onUpdate,
  loading,
  current,
  _onChange,
  pageSize,
  total,
  selectedHcpId,
  checkedHcpId,
  isTooltip,
}: ListActiveHcpProps): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));

  return (
    <>
      <ul className={styles.list_container}>
        {!loading &&
          list &&
          list.map((item: HcpList) => {
            const tooltipText = getTooltipText(item, isTooltip, t);
            return (
              <HcpAvctiveView
                key={item.id}
                name={item.firstName}
                lastname={item.lastName}
                email={item.email}
                selected={isTooltip ? !!tooltipText : selectedHcpId?.includes(item.id)}
                invite={checkedHcpId.includes(item.id)}
                status={item.status.toLowerCase()}
                onUpdateCheckbox={(): void => onUpdate(item.id, item)}
                hospitalName={item.hospital || item.hospitalName || ''}
                tooltip={tooltipText}
              />
            );
          })}
      </ul>
      <div className={styles.wrapper_pagination}>
        <Pagination
          className={styles.pagination}
          size="small"
          current={current}
          pageSize={pageSize}
          total={total}
          onChange={_onChange}
        />
      </div>
    </>
  );
};

const ListHcp = ({
  list,
  onUpdate,
  loading,
  current,
  _onChange,
  pageSize,
  total,
  checkedHcpId,
}: ListHcpProps): ReactElement => {
  const currentUser = storage.get('user');
  return (
    <>
      <ul className={styles.list_container}>
        {!loading &&
          list &&
          list.map((item: HcpList) => (
            <HcpView
              key={item.id}
              name={item.firstName}
              lastname={item.lastName}
              email={item.email}
              invite={
                checkedHcpId.includes(item.id) ||
                (currentUser.role !== 'admin' && item.id === currentUser.id)
              }
              hcpId={item.id}
              status={item.status.toLowerCase()}
              onUpdateCheckbox={(): void => onUpdate(item.id, item)}
            />
          ))}
      </ul>
      <div className={styles.wrapper_pagination}>
        <Pagination
          className={styles.pagination}
          size="small"
          current={current}
          pageSize={pageSize}
          total={total}
          onChange={_onChange}
        />
      </div>
    </>
  );
};

const HcpListByPatient = ({
  hcpListByPatient,
  hcpListLoading,
  hcpListError,
}: HcpListByPatientProps): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const patient_status = t && t.dashboard.hcp.table.patient_status;
  const first_name = t && t.dashboard.hcp.table.first_name;
  const last_name = t && t.dashboard.hcp.table.last_name;
  const profession = t && t.dashboard.admin.table.hcp.profession;
  const list_hcp_related_with_patient =
    t && t.dashboard.admin.table.patient.list_hcp_related_with_patient;
  const no_data = t && t.common.no_data;

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <div className={styles['modal__hcp-list-container']}>
      <div className={styles['modal__hcp-list-title']}>{list_hcp_related_with_patient}</div>
      <div className={styles['modal__hcp-list-th']}>
        <span className={cx({ [styles['width-21']]: true, [styles.center]: false })}>
          {patient_status}
        </span>
        <span className={cx({ [styles['width-27']]: true, [styles.center]: false })}>
          {first_name}
        </span>
        <span className={cx({ [styles['width-33']]: true, [styles.center]: false })}>
          {last_name}
        </span>
        <span className={cx({ [styles['width-15']]: true, [styles.center]: false })}>
          {profession}
        </span>
      </div>
      <div className={styles['row-container']}>
        {hcpListLoading && (
          <span className={styles['no-data']}>
            <Spin indicator={antIcon} />
          </span>
        )}
        {!hcpListLoading && !hcpListError && hcpListByPatient && hcpListByPatient.length > 0
          ? hcpListByPatient.map((hcp: ListHcpByPatient) => {
              const [title, hex] = showTheStatus(hcp.status, t);
              return (
                <div className={styles.row}>
                  <span className={styles['width-21']} style={{ color: hex }}>
                    {title}
                    {/* {getStatusOptions(title, 'table', t).label} */}
                  </span>
                  <span className={cx({ [styles['width-27']]: true })}>{hcp.firstName}</span>
                  <span className={cx({ [styles['width-33']]: true })}>{hcp.lastName}</span>
                  <span className={cx({ [styles['width-15']]: true })}>{hcp.profession}</span>
                </div>
              );
            })
          : !hcpListLoading && <span className={styles['no-data']}>{no_data}</span>}
      </div>
    </div>
  );
};

export { ListHcp, ListActiveHcp, HcpListByPatient };
