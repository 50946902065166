import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ProcedureKey } from '../utils/model';
import { proceduresEnum } from '../utils/enums';
import { FilterConfigType } from '../components/Video/VideoExercise/types';
import {
  TExerciseCategories,
  TFocusesProcedure,
} from '../components/Video/OrganisationVideoBank/types';
import {
  GetState,
  getExerciseCategories,
  getLoadingExerciseCategories,
  getLoadingProcedureKeys,
  getLoadingProcedures,
  getProcedureKeys,
  getProcedures,
} from '../redux/selector';

const useGetFocuses = () => {
  // Queris
  const listProcedureKeys: any = useSelector<GetState>((state) => getProcedureKeys(state));
  const loadingProcKeys: any = useSelector<GetState>((state) => getLoadingProcedureKeys(state));
  const procedures: any = useSelector<GetState>((state) => getProcedures(state));
  const loadingProcedures: any = useSelector<GetState>((state) => getLoadingProcedures(state));
  const exerciseCategoriesList: any = useSelector<any>((state) => getExerciseCategories(state));
  const loadingExercCategories = useSelector<any>((state) => getLoadingExerciseCategories(state));

  const [procedureFilterConfig, setProcedureFilterConfig] = useState<FilterConfigType[]>([]);
  const [focusType1Arr, setFocusType1Arr] = useState<string[]>([]);
  const [focusType2Arr, setFocusType2Arr] = useState<string[]>([]);
  const [exercisesProcedureFocus, setExercisesProcedureFocus] = useState<TFocusesProcedure>();
  const [exercisesCategories, setExercisesCategories] = useState<any>();

  // After receiving the list of procedures from the database, display them on the UI
  useEffect(() => {
    let procedureIndx = 1;
    if (procedures && listProcedureKeys) {
      const accprocedureFocus: any = {};
      let otherPhisicalRehabId: number | null = null;
      listProcedureKeys.map((key: ProcedureKey) => {
        if (key.key === proceduresEnum.OTHER_PHYSICAL_REHAB) {
          otherPhisicalRehabId = key.id;
        }
        return null;
      });
      const filterConfig: FilterConfigType[] = [];
      procedures.map((procedure: TExerciseCategories) => {
        if (procedure.id !== otherPhisicalRehabId) {
          accprocedureFocus[`procedure${procedureIndx}`] = procedure;
          procedureIndx += 1;
          const item = {
            id: String(procedure.id),
            name: procedure.name,
          };
          return filterConfig.push(item);
        }
        return null;
      });
      setProcedureFilterConfig(filterConfig);
      setExercisesProcedureFocus(accprocedureFocus);
      setFocusType1Arr(Object.keys(accprocedureFocus));
    }
  }, [procedures, listProcedureKeys]);

  // After receiving the list of focus categories from the database, display them on the UI
  useEffect(() => {
    let categoryIndex = 1;
    if (exerciseCategoriesList) {
      const accCategories: any = {};
      exerciseCategoriesList.map((focusList: TExerciseCategories) => {
        accCategories[`focus${categoryIndex}`] = focusList;
        categoryIndex += 1;
        return null;
      });
      setExercisesCategories(accCategories);
      setFocusType2Arr(Object.keys(accCategories));
    }
  }, [exerciseCategoriesList]);

  const loading = loadingProcedures || loadingProcKeys || loadingExercCategories;

  return {
    focusType1Arr,
    focusType2Arr,
    exercisesCategories,
    exercisesProcedureFocus,
    procedureFilterConfig,
    loadingFocuses: loading,
  };
};

export default useGetFocuses;
