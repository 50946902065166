import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import cx from 'classnames';
import Select from 'react-select';
import { Tooltip } from 'antd';

import pageStyles from '../../styles.module.css';
import styles from './style.module.css';
import patientStyles from '../style.module.css';
import {
  GetState,
  getCurrenLang,
  getErrorPatientProfile,
  getLoadingPatientProfile,
  getPatientProfile,
  getProcedureKeys,
  getRemoteMonitoringData,
  getSelectedPerRemoteMonit,
} from '../../../redux/selector';
import { Loading, MainTitle } from '../../../common';
import { BreadcrumbAnt } from '../../../utils/routers/Breadcrumb';
import { capitalizeFirstLetter, getQueryParams } from '../../../utils/helper';
import { path } from '../../../utils';
import useCheckRole from '../../../hooks/useCheckRole';
import { useQueryPatientProfile } from '../../../graphql/patients';
import { CHART_TABS, proceduresEnum, StatusKeyNameEnum } from '../../../utils/enums';
import { ActivitiesIcon, CsvIcon, PdfIcon } from '../../../theme/icons';
import { fillPatientProfile } from '../../../redux/patient';
import { overviewChartsPeriods } from '../../../utils/share/options';
import { dropdownIndicatorStyles, selectStyles } from '../../../common/Input/styles';
import { setSelectedPeriodRemMonit } from '../../../redux/dropdown';
import {
  useGetExercisesCompletion,
  useGetHeartRate,
  useGetMotivationLevel,
  useGetPainLevel,
  useGetPatientSteps,
  useGetSleepData,
} from '../../../graphql/charts';
import {
  useGetPatientCompletedExercisesByPeriod,
  useGetPatientNotCompletedExercisesForGraphs,
} from '../../../graphql/exercises';
import { PeriodDropDownOptions } from '../../../utils/model';
import IndividualExerciseChart from '../../../components/Charts/ExerciseCompletion/IndividualExerciseChart';
import ExerciseCompletionChart from '../../../components/Charts/ExerciseCompletion/ExerciseCompletionChart';
import ReasonsChart from '../../../components/Charts/ExerciseCompletion/ReasonsChart ';
import SkipStopChart from '../../../components/Charts/ExerciseCompletion/SkipStopChart ';
import { PainChart } from '../../../components/Charts/PainChart';
import { downloadFile, exportToPdf, getCurrentKeyOfProcedure } from '../../../utils/helper_charts';
import { MotivationsChart } from '../../../components/Charts/MotivationsChart';
import { Barchart } from '../../../components/Charts/StepCount';
import { SleepChart } from '../../../components/Charts/SleepChart';
import { HeartRateChart } from '../../../components/Charts/HeartRateChart';
import { NotComplExercValuesType } from '../../../redux/completions/types';
import { HeartRateChartValuesType, SleepChartValuesType } from '../../../redux/bodyData';

const RemoteMonitoringPage = (): ReactElement => {
  const t: any = useSelector<any>((state: GetState) => getCurrenLang(state));
  const patient_list = t?.title.patient_list;
  const remote_monitoring = t?.menu.remote_monitoring;
  const patient_not_found = t?.common.patient_not_found;
  const optionLabels = t?.dashboard.hcp.profile_patient.completion_and_steps;
  const export_to_pdf = t?.common.export_to_pdf;
  const export_to_csv = t?.common.export_to_csv;
  const day = t?.dashboard.hcp.profile_patient.completion_and_steps.day;
  const week = t?.dashboard.hcp.profile_patient.completion_and_steps.week;
  const month = t?.dashboard.hcp.profile_patient.completion_and_steps.month;
  const exercise_completion =
    t?.dashboard.hcp.profile_patient.completion_and_steps.exercise_completion;
  const reason_not_compl =
    t?.dashboard.hcp.profile_patient.completion_and_steps.reason_for_not_completing;
  const step_count = t?.dashboard.hcp.profile_patient.completion_and_steps.step_count;
  const skipped_or_stopped =
    t?.dashboard.hcp.profile_patient.completion_and_steps.skipped_or_stopped;
  const number_of_exercises_skipped =
    t?.dashboard.hcp.profile_patient.completion_and_steps.number_of_exercises_skipped;
  const number_of_exercises_stopped =
    t?.dashboard.hcp.profile_patient.completion_and_steps.number_of_exercises_stopped;
  const number_of_reps = t?.dashboard.hcp.profile_patient.completion_and_steps.number_of_reps;
  const too_painful = t?.dashboard.hcp.profile_patient.reasons.too_painful;
  const lack_of_equipment = t?.dashboard.hcp.profile_patient.reasons.lack_of_equipment;
  const no_time = t?.dashboard.hcp.profile_patient.reasons.no_time;
  const didnt_understand = t?.dashboard.hcp.profile_patient.reasons.didnt_understand;
  const rest_day = t?.dashboard.hcp.profile_patient.reasons.rest_day;
  const fatigue_label = t?.dashboard.hcp.profile_patient.reasons.fatigue;
  const will_do_later = t?.dashboard.hcp.profile_patient.reasons.will_do_later;
  const already_done = t?.dashboard.hcp.profile_patient.reasons.already_done;
  const i_prefer_not_to_say = t?.dashboard.hcp.profile_patient.reasons.i_prefer_not_to_say;
  const pain = t?.dashboard.hcp.profile_patient.questionnaires.pain;
  const valueBackCompleted_t =
    t?.dashboard.hcp.profile_patient.completion_and_steps.av_back_pain_score_completed;
  const valueBackSkippedStopped_t =
    t?.dashboard.hcp.profile_patient.completion_and_steps.av_back_pain_score_skip_stop;
  const valueLegCompleted_t =
    t?.dashboard.hcp.profile_patient.completion_and_steps.av_leg_pain_score_completed;
  const valueLegSkippedStoppedd_t =
    t?.dashboard.hcp.profile_patient.completion_and_steps.av_leg_pain_score_skip_stop;
  const valueCompleted_t = t?.dashboard.hcp.profile_patient.completion_and_steps.average_pain;
  const valueSkippedStopped_t =
    t?.dashboard.hcp.profile_patient.completion_and_steps.skip_stop_value;
  const sleep_quality = t?.dashboard.hcp.profile_patient.completion_and_steps.sleep_quality;
  const start_t = t?.dashboard.hcp.profile_patient.video_bank.start;
  const end_t = t?.dashboard.hcp.profile_patient.video_bank.end;
  const start_label = capitalizeFirstLetter(start_t);
  const end_label = capitalizeFirstLetter(end_t);
  const heart_rate = t?.dashboard.hcp.profile_patient.tabs.heart_rate;
  const max_heart_rate = t?.dashboard.hcp.profile_patient.completion_and_steps.max_heart_rate;
  const min_heart_rate = t?.dashboard.hcp.profile_patient.completion_and_steps.min_heart_rate;
  const av_heart_rate = t?.dashboard.hcp.profile_patient.completion_and_steps.av_heart_rate;
  const rest_heart_rate = t?.dashboard.hcp.profile_patient.completion_and_steps.rest_heart_rate;
  const exercise_summary = t?.dashboard.hcp.profile_patient.completion_and_steps.exercise_summary;

  const location = useLocation();
  const dispatch = useDispatch();
  const { userId, userStatus } = getQueryParams(location.search);
  const patientId = Number(userId);
  const patientStatus = String(userStatus);
  const { isAdmin } = useCheckRole();

  // Endpoints
  // Get current patient profile from DB
  const { _getProfileById } = useQueryPatientProfile(patientStatus);
  // Charts
  const { _getExercCompletion } = useGetExercisesCompletion();
  const { _getPatientCompletedExercisesByPeriod } = useGetPatientCompletedExercisesByPeriod();
  const { _getNotCompletedExercises } = useGetPatientNotCompletedExercisesForGraphs();
  const { _getMotivationLevel } = useGetMotivationLevel();
  const { _getSteps } = useGetPatientSteps();
  const { _getPainLevel } = useGetPainLevel();
  const { _getSleepData } = useGetSleepData();
  const { _getHeartRate } = useGetHeartRate();

  // Local state
  const [fileLoading, setFileLoading] = useState(false);

  // Redux
  const keyProceduresList = useSelector((state: GetState) => getProcedureKeys(state));
  const profileData: any = useSelector((state: GetState) => getPatientProfile(state));
  const profileLoading = useSelector((state: GetState) => getLoadingPatientProfile(state));
  const profileError: any = useSelector((state: GetState) => getErrorPatientProfile(state));
  const selectedPeriod: any = useSelector((state: GetState) => getSelectedPerRemoteMonit(state));
  const {
    exerciseSummary,
    exerciseCompletionData,
    reasonsNotComletionData,
    skipStopExercData,
    painLevelData,
    motivationData,
    stepsData,
    sleepData,
    heartRateData,
  }: any = useSelector(getRemoteMonitoringData);
  const selectedChartPeriod = exerciseCompletionData?.title;

  const periodListOptions = overviewChartsPeriods(optionLabels);
  const procedureId = profileData?.procedure?.type.id || 0;
  const procedureKey = useMemo(() => {
    if (keyProceduresList) {
      return getCurrentKeyOfProcedure(procedureId, keyProceduresList);
    }
    return '';
  }, [keyProceduresList, procedureId]);

  // No data available for this user
  const notAllowed = useMemo(() => {
    if (!userStatus || !patientId || profileError) {
      return true;
    }
    if (userStatus === StatusKeyNameEnum.invite_sent) {
      return true;
    }
    if (userStatus === StatusKeyNameEnum.pending) {
      return true;
    }
    return false;
  }, [patientId, userStatus, profileError]);

  // Send request to the database
  useEffect(() => {
    if (!notAllowed) {
      _getProfileById({
        variables: {
          patientId,
        },
      });
    }
  }, [notAllowed, patientId]);

  // Set Select period value and get Individual Exercise Summary data
  useEffect(() => {
    if (t) {
      const period = periodListOptions[0];
      dispatch(setSelectedPeriodRemMonit(period));
      _getPatientCompletedExercisesByPeriod({ patientId, selectedPeriod: period });
    }
  }, [t]);

  // Get data
  useEffect(() => {
    _getExercCompletion(patientId);
    _getNotCompletedExercises(patientId);
    _getPainLevel(patientId);
    _getMotivationLevel(patientId);
    _getSteps(patientId);
    _getSleepData(patientId);
    _getHeartRate(patientId);
    // Clear state after componentWillUnmount
    return (): void => {
      dispatch(fillPatientProfile(null));
    };
  }, []);

  // Select periods and get Individual Exercise Summary data
  const handleChange = (tabValue: PeriodDropDownOptions): void => {
    dispatch(setSelectedPeriodRemMonit(tabValue));
    _getPatientCompletedExercisesByPeriod({ patientId, selectedPeriod: tabValue });
  };

  // Download PDF file
  const downloadHandler = async (typeClass: string): Promise<void> => {
    if (!fileLoading && !profileLoading) {
      setFileLoading(true);
      await exportToPdf(profileData, typeClass);
      setFileLoading(false);
    }
  };

  // Convert chart data to CSV format
  const convertChartDataToCSV = (): void => {
    const last_name = t?.dashboard.hcp.table.last_name;
    const first_name = t?.dashboard.hcp.table.first_name;
    const nhs_number = t?.dashboard.hcp.table.nhs_number;
    const motiv_t = t?.dashboard.hcp.profile_patient.completion_and_steps.daily_motivation_level;
    let periodName = day;
    if (selectedPeriod.periodType === 'week') {
      periodName = week;
    } else if (selectedPeriod.periodType === 'month') {
      periodName = month;
    }
    // Motivation
    // Title
    let csvContent = `data:text/csv;charset=utf-8,${first_name},${
      profileData.firstName
    },\r\n${last_name},${profileData.lastName},\r\n${nhs_number},${
      profileData.nhsNumber ? String(profileData.nhsNumber) : ''
    },\r\n`;
    let exerciseSummaryCsvContent = csvContent;

    const addContent = (name: string, values: { name: string; value: number }[]): void => {
      let content = '';
      csvContent += `,${periodName}`;
      values.map((periodLabel: { name: string; value: number }) => {
        csvContent += `,${periodLabel.name}`;
        content += `${periodLabel.value},`;
        return null;
      });
      csvContent += ',\r\n';
      csvContent += `,${name},${content},\r\n`;
      csvContent += ',\r\n';
    };

    // Exercise completion
    if (exerciseCompletionData?.values?.length > 0) {
      addContent(exercise_completion, exerciseCompletionData.values);
    }

    // Reason for not completion
    if (reasonsNotComletionData?.values?.length > 0) {
      let numberOfSkippedExercises = `${number_of_exercises_skipped},`;
      let numberOfStoppedExercises = `${number_of_exercises_stopped},`;
      let averageNumberOfReps = `${number_of_reps},`;

      let alreadyDone = `${already_done},`;
      let didntUnderstand = `${didnt_understand},`;
      let fatigue = `${fatigue_label},`;
      let lackOfEquipment = `${lack_of_equipment},`;
      let noReason = `${i_prefer_not_to_say},`;
      let noTime = `${no_time},`;
      let restDay = `${rest_day},`;
      let tooPainful = `${too_painful},`;
      let willDoLater = `${will_do_later},`;

      csvContent += `,${periodName}`;
      reasonsNotComletionData.values.map((periodLabel: NotComplExercValuesType) => {
        const { reasons } = periodLabel;
        csvContent += `,${periodLabel.name}`;
        numberOfSkippedExercises += `${periodLabel.numberOfSkippedExercises},`;
        numberOfStoppedExercises += `${periodLabel.numberOfStoppedExercises},`;
        averageNumberOfReps += `${periodLabel.averageNumberOfReps},`;

        alreadyDone += `${reasons.alreadyDone},`;
        didntUnderstand += `${reasons.didntUnderstand},`;
        fatigue += `${reasons.fatigue},`;
        lackOfEquipment += `${reasons.lackOfEquipment},`;
        noReason += `${reasons.noReason},`;
        noTime += `${reasons.noTime},`;
        restDay += `${reasons.restDay},`;
        tooPainful += `${reasons.tooPainful},`;
        willDoLater += `${reasons.willDoLater},`;
        return null;
      });
      csvContent += ',\r\n';
      csvContent += `,${reason_not_compl},\r\n`;
      csvContent += `,${alreadyDone},\r\n`;
      csvContent += `,${didntUnderstand},\r\n`;
      csvContent += `,${fatigue},\r\n`;
      csvContent += `,${lackOfEquipment},\r\n`;
      csvContent += `,${noReason},\r\n`;
      csvContent += `,${noTime},\r\n`;
      csvContent += `,${restDay},\r\n`;
      csvContent += `,${tooPainful},\r\n`;
      csvContent += `,${willDoLater},\r\n`;
      csvContent += ',\r\n';
      csvContent += `,${skipped_or_stopped},\r\n`;
      csvContent += `,${numberOfSkippedExercises},\r\n`;
      csvContent += `,${numberOfStoppedExercises},\r\n`;
      csvContent += `,${averageNumberOfReps},\r\n`;
      csvContent += ',\r\n';
    }

    // Pain
    if (painLevelData?.values?.length > 0) {
      csvContent += `,${periodName}`;
      if (procedureKey === proceduresEnum.SPINAL) {
        let valueBackCompleted = `${valueBackCompleted_t},`;
        let valueBackSkippedStopped = `${valueBackSkippedStopped_t},`;
        let valueLegCompleted = `${valueLegCompleted_t},`;
        let valueLegSkippedStopped = `${valueLegSkippedStoppedd_t},`;
        painLevelData.values.map((periodLabel: any) => {
          csvContent += `,${periodLabel.name}`;
          valueBackCompleted += `${periodLabel.valueBackCompleted},`;
          valueBackSkippedStopped += `${periodLabel.valueBackSkippedStopped},`;
          valueLegCompleted += `${periodLabel.valueLegCompleted},`;
          valueLegSkippedStopped += `${periodLabel.valueLegSkippedStopped},`;
          return null;
        });
        csvContent += ',\r\n';
        csvContent += `,${pain},\r\n`;
        csvContent += `,${valueBackCompleted},\r\n`;
        csvContent += `,${valueBackSkippedStopped},\r\n`;
        csvContent += `,${valueLegCompleted},\r\n`;
        csvContent += `,${valueLegSkippedStopped},\r\n`;
        csvContent += ',\r\n';
      } else {
        let valueCompleted = `${valueCompleted_t},`;
        let valueSkippedStopped = `${valueSkippedStopped_t},`;
        painLevelData.values.map((periodLabel: any) => {
          csvContent += `,${periodLabel.name}`;
          valueCompleted += `${periodLabel.valueCompleted},`;
          valueSkippedStopped += `${periodLabel.valueSkippedStopped},`;
          return null;
        });
        csvContent += ',\r\n';
        csvContent += `,${pain},\r\n`;
        csvContent += `,${valueCompleted},\r\n`;
        csvContent += `,${valueSkippedStopped},\r\n`;
        csvContent += ',\r\n';
      }
    }

    // Motivation
    if (motivationData?.values?.length > 0) {
      addContent(motiv_t, motivationData.values);
    }

    // Step count
    if (stepsData?.values?.length > 0) {
      addContent(step_count, stepsData.values);
    }

    // Heart Rate
    if (heartRateData?.values?.length > 0) {
      csvContent += `,${periodName}`;
      let maxHeartRate = `${max_heart_rate},`;
      let minHeartRate = `${min_heart_rate},`;
      let averageHeartRate = `${av_heart_rate},`;
      let restingHeartRate = `${rest_heart_rate},`;

      heartRateData.values.map((periodLabel: HeartRateChartValuesType) => {
        csvContent += `,${periodLabel.name}`;
        maxHeartRate += `${periodLabel.maxHeartRate},`;
        minHeartRate += `${periodLabel.minHeartRate},`;
        averageHeartRate += `${periodLabel.averageHeartRate},`;
        restingHeartRate += `${periodLabel.restingHeartRate},`;
        return null;
      });
      csvContent += ',\r\n';
      csvContent += `,${heart_rate},\r\n`;
      csvContent += `,${maxHeartRate},\r\n`;
      csvContent += `,${minHeartRate},\r\n`;
      csvContent += `,${averageHeartRate},\r\n`;
      csvContent += `,${restingHeartRate},\r\n`;
      csvContent += ',\r\n';
    }

    // Sleep
    if (sleepData?.values?.length > 0) {
      csvContent += `,${periodName}`;
      let startTimeInMinutes = `${start_label},`;
      let endTimeInMinutes = `${end_label},`;

      const convertMinutes = (valueInMinutes: number) => {
        let hours: number | string = Math.trunc(valueInMinutes / 60);
        let minutes: number | string = valueInMinutes - hours * 60;
        const convertTimeToString = (time: number) => {
          let newTime = String(time);
          if (time < 10) {
            newTime = `0${time}`;
          }
          return newTime;
        };
        hours = convertTimeToString(hours);
        minutes = convertTimeToString(minutes);

        return `${hours}:${minutes}`;
      };

      sleepData.values.map((periodLabel: SleepChartValuesType) => {
        csvContent += `,${periodLabel.name}`;
        startTimeInMinutes += `${convertMinutes(periodLabel.startTimeInMinutes)},`;
        endTimeInMinutes += `${convertMinutes(periodLabel.endTimeInMinutes)},`;
        return null;
      });
      csvContent += ',\r\n';
      csvContent += `,${sleep_quality},\r\n`;
      csvContent += `,${startTimeInMinutes},\r\n`;
      csvContent += `,${endTimeInMinutes},\r\n`;
      csvContent += ',\r\n';
    }
    const completed_t = t?.dashboard.hcp.profile_patient.completion_and_steps.completed;
    const partly_done_t = t?.dashboard.hcp.profile_patient.completion_and_steps.partly_done;
    const skipped_t = t?.dashboard.hcp.profile_patient.completion_and_steps.skipped;

    // Individual Exercise Summary
    if (exerciseSummary?.chartLabels?.length > 0 && exerciseSummary?.exercises?.length > 0) {
      exerciseSummaryCsvContent += `,${periodName}`;

      exerciseSummary.chartLabels.reverse().map((periodLabel: string) => {
        exerciseSummaryCsvContent += `,${periodLabel}`;
        return null;
      });
      exerciseSummaryCsvContent += ',\r\n';
      exerciseSummaryCsvContent += `,${exercise_summary},\r\n`;

      exerciseSummary.exercises.map((exerc: any) => {
        exerciseSummaryCsvContent += `,${exerc.name},\r\n`;
        if (exerc.completed?.length > 0) {
          exerciseSummaryCsvContent += `,${completed_t},`;
          exerc.completed.reverse().map((compl: number) => {
            exerciseSummaryCsvContent += `${compl},`;
            return null;
          });
          exerciseSummaryCsvContent += ',\r\n';
        }
        if (exerc.stopped?.length > 0) {
          exerciseSummaryCsvContent += `,${partly_done_t},`;
          exerc.stopped.reverse().map((stoped: number) => {
            exerciseSummaryCsvContent += `${stoped},`;
            return null;
          });
          exerciseSummaryCsvContent += ',\r\n';
        }
        if (exerc.skipped?.length > 0) {
          exerciseSummaryCsvContent += `,${skipped_t},`;
          exerc.skipped.reverse().map((skip: number) => {
            exerciseSummaryCsvContent += `${skip},`;
            return null;
          });
          exerciseSummaryCsvContent += ',\r\n';
        }
      });
    }

    const downloadFiles = [
      { name: remote_monitoring, value: csvContent },
      { name: exercise_summary, value: exerciseSummaryCsvContent },
    ];
    for (let i = 0; i < downloadFiles.length; i += 1) {
      setTimeout(
        // eslint-disable-next-line no-shadow
        (i: any) => {
          downloadFile(downloadFiles[i].value, profileData, downloadFiles[i].name, 'csv');
        },
        i * 1000,
        i,
      );
    }
  };

  // Download CSV file
  const downloadCSVHandler = (): void => {
    // if (chartData.length === 0) return;
    if (!fileLoading && !profileLoading) {
      setFileLoading(true);
      convertChartDataToCSV();
      setFileLoading(false);
    }
  };

  // JSX
  const loadingJSX = (profileLoading || fileLoading) && <Loading />;

  // Breadcrumb path
  const routes = useMemo(
    () => [
      {
        path: isAdmin ? path.adminDashboardList : path.dashboardList,
        breadcrumbName: patient_list,
      },
      {
        path: isAdmin
          ? `${path.patient_overview_admin}${location.search}`
          : `${path.patient_overview}${location.search}`,
        breadcrumbName: profileData ? `${profileData.firstName} ${profileData.lastName}` : '-',
      },
      {
        path: remote_monitoring,
        breadcrumbName: remote_monitoring,
      },
    ],
    [t, profileData],
  );

  return (
    <div className={pageStyles.main__container}>
      {loadingJSX}
      <BreadcrumbAnt currentRoutes={routes} />
      <div className={cx([pageStyles.main__wrapper, pageStyles.main__wrapper])}>
        <MainTitle title={remote_monitoring} icon={<ActivitiesIcon />} />

        {notAllowed ? (
          <div className={patientStyles['profile-user__notfound']}>{patient_not_found}</div>
        ) : (
          <>
            <div className={styles['remote-monitoring__flex-container']}>
              <div className={styles['remote-monitoring__select']}>
                <Select
                  options={periodListOptions}
                  styles={{ ...selectStyles, ...dropdownIndicatorStyles() }}
                  value={selectedPeriod}
                  onChange={handleChange}
                  defaultValue={selectedPeriod}
                  isSearchable={false}
                />
              </div>

              {/* Export data */}
              <div className={styles['proms__export-icons--container']}>
                <Tooltip mouseEnterDelay={0.3} placement="top" title={export_to_pdf}>
                  <div
                    className={cx({
                      [styles['icon-pdf']]: true,
                      [styles['disable-icon']]: fileLoading || profileLoading,
                    })}
                    onClick={(): Promise<void> => downloadHandler('chart-to-PDF')}
                    role="presentation"
                  >
                    <img src={PdfIcon} alt="PDF" />
                  </div>
                </Tooltip>
                <Tooltip mouseEnterDelay={0.3} placement="bottom" title={export_to_csv}>
                  <div
                    className={cx({
                      [styles['icon-pdf']]: true,
                      [styles['disable-icon']]: fileLoading || profileLoading,
                    })}
                    onClick={downloadCSVHandler}
                    role="presentation"
                  >
                    <img src={CsvIcon} alt="CSV" />
                  </div>
                </Tooltip>
              </div>
            </div>
            <div className={styles['remote-monitoring__flex-container']}>
              {/* Left */}
              <div className={pageStyles['left-section']}>
                <div className={styles['chart-period']}>{selectedChartPeriod}</div>
                <ExerciseCompletionChart
                  exercComplData={exerciseCompletionData}
                  type={CHART_TABS.exercise_completion}
                />
                <ReasonsChart
                  reasonsNotComletionData={reasonsNotComletionData}
                  type={CHART_TABS.reasons}
                />
                <SkipStopChart skipStopExercData={skipStopExercData} type={CHART_TABS.skipStop} />
                <PainChart
                  painLevelData={painLevelData}
                  procedureKey={procedureKey}
                  type={CHART_TABS.pain}
                />
                <MotivationsChart motivationData={motivationData} type={CHART_TABS.motivation} />
                <Barchart stepsData={stepsData} type={CHART_TABS.step_count} />
                <SleepChart sleepData={sleepData} type={CHART_TABS.sleep} />
                <HeartRateChart heartRateData={heartRateData} type={CHART_TABS.heart_rate} />
              </div>

              {/* Right */}
              <div className={pageStyles['right-section']}>
                <IndividualExerciseChart isOverview={false} exercSummaryData={exerciseSummary} />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default RemoteMonitoringPage;
