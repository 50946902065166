/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { ReactElement, useState } from 'react';
import { Dropdown, Menu, Switch } from 'antd';
import { useSelector } from 'react-redux';

import styles from './styles.module.css';
import { getCurrenLang } from '../../redux/selector';
import { ChevronDown, ChevronUp, SelectDownIcon, SelectUpIcon } from '../../theme/icons';
import { ExerciseFilterProps, FilterByExerciseProps } from './types';
import { CurrentFocus, TFocusesProcedure } from '../Video/OrganisationVideoBank/types';
import { MEDIA } from '../../utils/enums';
import { RoundCheckbox } from '../../common/Input';
import { Info } from '../../common/Hint';

const ExerciseFilter = ({
  focusProcedureTypeArr,
  focusFilter = [],
  exercisesProcedureFocus,
  setFocusFilter,
  folderFilter,
  setFolderFilter,
  isFolder = false,
}: ExerciseFilterProps): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const on = t?.common.on;
  const off = t?.common.off;
  const all = t?.common.all;
  const folder_t = t?.common.folder;
  const focus = t?.dashboard.hcp.table.procedure;
  const filter_title = t?.dashboard.hcp.profile_patient.video_bank.filter_by_exercise_focus;

  const [isOpenDropDown, setOpenDropDown] = useState(false);

  const focusFilterHandler = (isCheked: boolean, currentFocus: CurrentFocus): void => {
    if (currentFocus.name === 'all') {
      setFocusFilter([]);
      return;
    }
    if (isCheked) {
      const copyState = [...focusFilter];
      copyState.push(currentFocus.id);
      if (focusProcedureTypeArr?.length === copyState.length) {
        setFocusFilter([]);
        return;
      }
      setFocusFilter(copyState);
      return;
    }
    if (!isCheked) {
      const copyState = [...focusFilter];
      const index = copyState.indexOf(currentFocus.id);
      if (index < 0) {
        setFocusFilter([]);
        return;
      }
      copyState.splice(index, 1);
      setFocusFilter(copyState);
    }
  };

  const folderFilterHandler = (isCheked: boolean): void => {
    setFolderFilter(isCheked);
  };

  const handleVisibleChangeFilterFocus = (flag: boolean): void => {
    setOpenDropDown(flag);
  };

  // JSX
  const getFilterSwitchItem = (focusId: number, currentFocus: CurrentFocus): JSX.Element => (
    <label className={styles['focus-filter__item']}>
      <div className={styles.switch}>
        <Switch
          checkedChildren={<span>{on}</span>}
          unCheckedChildren={<span>{off}</span>}
          checked={focusFilter.includes(focusId)}
          onChange={(checked: boolean): void => {
            focusFilterHandler(checked, currentFocus);
          }}
        />
      </div>
      <div className={styles['focus-filter__item-label']}>{currentFocus.name}</div>
    </label>
  );

  const filterSwitchAllJSX = (
    <label className={styles['focus-filter__item']}>
      <div className={styles.switch}>
        <Switch
          checkedChildren={<span>{on}</span>}
          unCheckedChildren={<span>{off}</span>}
          checked={focusFilter.length === 0}
          onChange={(checked: boolean): void => {
            focusFilterHandler(checked, { id: 0, name: 'all' });
          }}
        />
      </div>
      <div className={styles['focus-filter__item-label']}>{all}</div>
    </label>
  );

  const filterSwitchFolderJSX = (
    <label className={styles['focus-filter__item']}>
      <div className={styles.switch}>
        <Switch
          checkedChildren={<span>{on}</span>}
          unCheckedChildren={<span>{off}</span>}
          checked={folderFilter}
          onChange={(checked: boolean): void => {
            folderFilterHandler(checked);
          }}
        />
      </div>
      <div className={styles['focus-filter__item-label']}>{folder_t}</div>
    </label>
  );

  const getConfig = (): JSX.Element[] => {
    const config = [<Menu.Item key="1">{filterSwitchAllJSX}</Menu.Item>];
    if (isFolder) {
      config.push(<Menu.Item key="2">{filterSwitchFolderJSX}</Menu.Item>);
    }
    return config;
  };

  const configJSX = getConfig();

  const focusFiltermenu = (
    <Menu>
      {configJSX.map((item: JSX.Element) => item)}
      {focusProcedureTypeArr.map((_: any, i: number) => {
        const currentFocusNumber = `procedure${i + 1}` as keyof TFocusesProcedure;
        const currentFocus: CurrentFocus = exercisesProcedureFocus
          ? exercisesProcedureFocus[currentFocusNumber]
          : { name: '', id: 0 };
        const focusId: number = currentFocus.id;
        return (
          <Menu.Item key={`${i + configJSX.length + 1}`}>
            {getFilterSwitchItem(focusId, currentFocus)}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <div className={styles['focus-filter__container']}>
      <div className={styles['focus-filter__title']}>{filter_title}</div>
      <div className={styles['focus-filter__full-screen']}>
        {filterSwitchAllJSX}
        {isFolder ? filterSwitchFolderJSX : <></>}
        {focusProcedureTypeArr.map((exerciseFocus: string, i: number) => {
          const currentFocusNumber = `procedure${i + 1}` as keyof TFocusesProcedure;
          const currentFocus: CurrentFocus = exercisesProcedureFocus
            ? exercisesProcedureFocus[currentFocusNumber]
            : { name: '', id: 0 };
          const focusId: number = currentFocus.id;
          return (
            <React.Fragment key={exerciseFocus}>
              {getFilterSwitchItem(focusId, currentFocus)}
            </React.Fragment>
          );
        })}
      </div>
      <div className={styles['focus-filter__small-screen']}>
        <Dropdown
          overlay={focusFiltermenu}
          trigger={['click']}
          visible={isOpenDropDown}
          onVisibleChange={handleVisibleChangeFilterFocus}
          placement="bottomLeft"
          overlayClassName="focusFilterDropDown"
        >
          <div
            className={styles['filter-container']}
            onClick={(): void => setOpenDropDown(!isOpenDropDown)}
            role="presentation"
          >
            <div className={styles['focus-filter__small-screen-focus']}>{focus}</div>
            <img src={isOpenDropDown ? ChevronUp : ChevronDown} alt="Open" />
          </div>
        </Dropdown>
      </div>
    </div>
  );
};

interface MediaTypes {
  value: string;
  label: string;
}
const MediaFilter = ({ mediaFilter = [], setMediaFilter }: any): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const on = t?.common.on;
  const off = t?.common.off;
  const all = t?.common.all;
  const photos_t = t?.common.photos;
  const videos_t = t?.common.videos;
  const focus = t?.dashboard.hcp.table.procedure;
  const filter_by_media = t?.dashboard.hcp.profile_patient.video_bank.filter_by_media;

  const [isOpenDropDown, setOpenDropDown] = useState(false);

  const mediaConfig = [
    { value: MEDIA.FILE, label: photos_t },
    { value: MEDIA.VIDEO, label: videos_t },
  ];

  const focusFilterHandler = (isCheked: boolean, checkboxValue: string): void => {
    if (checkboxValue === 'all') {
      setMediaFilter([]);
      return;
    }
    if (isCheked) {
      setMediaFilter(checkboxValue);
    }
  };

  const handleVisibleChangeFilterFocus = (flag: boolean): void => {
    setOpenDropDown(flag);
  };

  // JSX
  const getFilterSwitchItem = (checkbox: MediaTypes): JSX.Element => (
    <label className={styles['focus-filter__item']}>
      <div className={styles.switch}>
        <Switch
          checkedChildren={<span>{on}</span>}
          unCheckedChildren={<span>{off}</span>}
          checked={mediaFilter.includes(checkbox.value)}
          onChange={(checked: boolean): void => {
            focusFilterHandler(checked, checkbox.value);
          }}
        />
      </div>
      <div className={styles['focus-filter__item-label']}>{checkbox.label}</div>
    </label>
  );

  const filterSwitchAllJSX = (
    <label className={styles['focus-filter__item']}>
      <div className={styles.switch}>
        <Switch
          checkedChildren={<span>{on}</span>}
          unCheckedChildren={<span>{off}</span>}
          checked={mediaFilter.length === 0}
          onChange={(checked: boolean): void => {
            focusFilterHandler(checked, 'all');
          }}
        />
      </div>
      <div className={styles['focus-filter__item-label']}>{all}</div>
    </label>
  );

  const focusFiltermenu = (
    <Menu>
      <Menu.Item key="all">{filterSwitchAllJSX}</Menu.Item>
      {mediaConfig.map((filterMedia: MediaTypes) => (
        <Menu.Item key={filterMedia.label}>{getFilterSwitchItem(filterMedia)}</Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div className={styles['focus-filter__container']}>
      <div className={styles['focus-filter__title']}>{filter_by_media}</div>
      <div className={styles['focus-filter__full-screen']}>
        {filterSwitchAllJSX}
        {mediaConfig.map((filterMedia: MediaTypes) => (
          <React.Fragment key={filterMedia.label}>
            {getFilterSwitchItem(filterMedia)}
          </React.Fragment>
        ))}
      </div>

      {/* Small screens */}
      <div className={styles['focus-filter__small-screen']}>
        <Dropdown
          overlay={focusFiltermenu}
          trigger={['click']}
          visible={isOpenDropDown}
          onVisibleChange={handleVisibleChangeFilterFocus}
          placement="bottomLeft"
          overlayClassName="focusFilterDropDown"
        >
          <div
            className={styles['filter-container']}
            onClick={(): void => setOpenDropDown(!isOpenDropDown)}
            role="presentation"
          >
            <div className={styles['focus-filter__small-screen-focus']}>{focus}</div>
            <img src={isOpenDropDown ? ChevronUp : ChevronDown} alt="Open" />
          </div>
        </Dropdown>
      </div>
    </div>
  );
};

const FilterByExercise = ({
  filterConfig,
  focusFilter = [],
  setFocusFilter,
  folderFilter,
  setFolderFilter,
  isFolder = false,
}: FilterByExerciseProps): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const all = t?.common.all;
  const folder_t = t?.common.folder;
  const filter_title = t?.dashboard.hcp.profile_patient.video_bank.filter_by_exercise_focus;
  const folder_info_one = t?.hcp.org_video_bank?.folder_info_one;
  const folder_info_two = t?.hcp.org_video_bank?.folder_info_two;
  const folder_info_three = t?.hcp.org_video_bank?.folder_info_three;
  const note = t?.common.note;
  const folder_info_foure = t?.hcp.org_video_bank?.folder_info_foure;
  const focus = t?.dashboard.hcp.table.procedure;

  const [isOpenDropDown, setOpenDropDown] = useState(false);

  const handleVisibleChangeFilterFocus = (flag: boolean): void => {
    setOpenDropDown(flag);
  };

  // Select the "All" option
  const onCheckAll = (): void => {
    if (focusFilter.length !== 0) {
      setFocusFilter([]);
    }
  };

  // Select the "Folder" option
  const onCheckFolder = (e: any): void => {
    const { checked } = e.target;
    setFolderFilter(checked);
  };

  // Change filter
  const onChange = (id: string): void => {
    const idNum = +id;
    if (focusFilter.includes(idNum)) {
      const newFocusFilter = focusFilter.filter((i: number) => idNum !== i);
      setFocusFilter(newFocusFilter);
    } else {
      setFocusFilter([idNum, ...focusFilter]);
    }
  };
  const inner = (
    <div className={styles['procedure-filter__inner']}>
      <div className={styles['procedure-filter__tip']}>{folder_info_one}</div>{' '}
      <div className={styles['procedure-filter__tip']}>{folder_info_two}</div>
      <div className={styles['procedure-filter__tip']}>{folder_info_three}</div>
      <div className={styles['procedure-filter__tip']}>
        <span>{note}:</span>
        {folder_info_foure}
      </div>
    </div>
  );
  const filterAllJSX = (
    <RoundCheckbox
      htmlId="all"
      name={all}
      label={all}
      checked={focusFilter.length === 0}
      onChangeMethod={onCheckAll}
      isValid
      isTouched
      hasErrors={false}
    />
  );
  const filterFolderJSX = (
    <RoundCheckbox
      htmlId="folder"
      name={folder_t}
      label={folder_t}
      checked={folderFilter}
      onChangeMethod={(e: any): void => onCheckFolder(e)}
      isValid
      isTouched
      hasErrors={false}
    />
  );

  const focusFiltermenu = (
    <Menu>
      <Menu.Item key="all">{filterAllJSX}</Menu.Item>
      {isFolder && <Menu.Item key="folder">{filterFolderJSX}</Menu.Item>}
      {filterConfig.map((item: { id: string; name: string }) => (
        <Menu.Item key={item.name}>
          <RoundCheckbox
            key={`RoundCheckbox${item.id}`}
            htmlId={item.id}
            name={item.name}
            label={item.name}
            checked={focusFilter.includes(+item.id)}
            onChangeMethod={(): void => onChange(item.id)}
            isValid
            isTouched
            hasErrors={false}
          />
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div>
      <div className={styles['procedure-filter__title']}>{filter_title}</div>
      {/* Big screens */}
      <div className={styles['procedure-filter__items-container']}>
        {/* All */}
        {filterAllJSX}
        {/* Folder */}
        {isFolder && (
          <div className={styles['procedure-filter__item-folder-container']}>
            {filterFolderJSX}
            <div className={styles['procedure-filter__item-folder-info']}>
              <Info tip={inner} />
            </div>
          </div>
        )}
        {filterConfig.map((item: { id: string; name: string }) => (
          <RoundCheckbox
            key={`RoundCheckbox${item.id}`}
            htmlId={item.id}
            name={item.name}
            label={item.name}
            checked={focusFilter.includes(+item.id)}
            onChangeMethod={(): void => onChange(item.id)}
            isValid
            isTouched
            hasErrors={false}
          />
        ))}
      </div>

      {/* Small screens */}
      <div className={styles['focus-filter__small-screen']}>
        <Dropdown
          overlay={focusFiltermenu}
          trigger={['click']}
          visible={isOpenDropDown}
          onVisibleChange={handleVisibleChangeFilterFocus}
          placement="bottomLeft"
          overlayClassName="focusFilterDropDown"
        >
          <div
            className={styles['procedure-filter__filter-container']}
            onClick={(): void => setOpenDropDown(!isOpenDropDown)}
            role="presentation"
          >
            <div className={styles['procedure-filter__small-screen-focus']}>{focus}</div>
            <img src={isOpenDropDown ? ChevronUp : ChevronDown} alt="Open" />
          </div>
        </Dropdown>
      </div>
    </div>
  );
};

const FilterByFocus = ({
  focusProcedureTypeArr,
  focusFilter = [],
  exercisesProcedureFocus,
  setFocusFilter,
  folderFilter,
  setFolderFilter,
  isFolder = false,
}: ExerciseFilterProps): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const all = t?.common.all;
  const folder_t = t?.common.folder;
  const focus = t?.dashboard.hcp.table.procedure;

  const [isOpenDropDown, setOpenDropDown] = useState(false);

  const handleVisibleChangeFilterFocus = (flag: boolean): void => {
    setOpenDropDown(flag);
  };

  // Select the "All" option
  const onCheckAll = (): void => {
    if (focusFilter.length !== 0) {
      setFocusFilter([]);
    }
  };

  // Select the "Folder" option
  const onCheckFolder = (e: any): void => {
    const { checked } = e.target;
    setFolderFilter(checked);
  };

  // Change filter
  const onChange = (id: string): void => {
    const idNum = +id;
    if (focusFilter.includes(idNum)) {
      const newFocusFilter = focusFilter.filter((i: number) => idNum !== i);
      setFocusFilter(newFocusFilter);
    } else {
      setFocusFilter([idNum, ...focusFilter]);
    }
  };

  // JSX
  const getFilterSwitchItem = (currentFocus: CurrentFocus): JSX.Element => (
    <RoundCheckbox
      key={`RoundCheckbox${currentFocus.id}`}
      htmlId={String(currentFocus.id)}
      name={currentFocus.name}
      label={currentFocus.name}
      checked={focusFilter.includes(currentFocus.id)}
      onChangeMethod={(): void => onChange(String(currentFocus.id))}
      isValid
      isTouched
      hasErrors={false}
    />
  );

  const filterSwitchAllJSX = (
    <RoundCheckbox
      htmlId="all"
      name={all}
      label={all}
      checked={focusFilter.length === 0}
      onChangeMethod={onCheckAll}
      isValid
      isTouched
      hasErrors={false}
    />
  );

  const filterSwitchFolderJSX = (
    <RoundCheckbox
      htmlId="folder"
      name={folder_t}
      label={folder_t}
      checked={folderFilter}
      onChangeMethod={(e: any): void => onCheckFolder(e)}
      isValid
      isTouched
      hasErrors={false}
    />
  );

  const getConfig = (): JSX.Element[] => {
    const config = [<Menu.Item key="1">{filterSwitchAllJSX}</Menu.Item>];
    if (isFolder) {
      config.push(<Menu.Item key="2">{filterSwitchFolderJSX}</Menu.Item>);
    }
    return config;
  };

  const configJSX = getConfig();

  const focusFiltermenu = (
    <Menu>
      {configJSX.map((item: JSX.Element) => item)}
      {focusProcedureTypeArr.map((_: any, i: number) => {
        const currentFocusNumber = `procedure${i + 1}` as keyof TFocusesProcedure;
        const currentFocus: CurrentFocus = exercisesProcedureFocus
          ? exercisesProcedureFocus[currentFocusNumber]
          : { name: '', id: 0 };
        return (
          <Menu.Item key={`${i + configJSX.length + 1}`}>
            {getFilterSwitchItem(currentFocus)}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <div className={styles['filter-by-focus__container']}>
      <Dropdown
        overlay={focusFiltermenu}
        trigger={['click']}
        visible={isOpenDropDown}
        onVisibleChange={handleVisibleChangeFilterFocus}
        placement="bottomRight"
        // overlayClassName="focusFilterDropDown"
      >
        <div
          className={styles['filter-by-focus__filter-container']}
          onClick={(): void => setOpenDropDown(!isOpenDropDown)}
          role="presentation"
        >
          <div className={styles['filter-by-focus__focus-label']}>{focus}</div>
          {isOpenDropDown ? <SelectUpIcon /> : <SelectDownIcon />}
        </div>
      </Dropdown>
    </div>
  );
};

export { ExerciseFilter, MediaFilter, FilterByExercise, FilterByFocus };
