import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import {
  Draggable,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from 'react-beautiful-dnd';
import { toast } from 'react-toastify';

import styles from '../styles.module.css';
import { Button, Loading } from '../../../../common';
import { GetState, getCurrenLang, getDraggedList } from '../../../../redux/selector';
import { collectUuids, getItemStyle, getListStyle } from '../../../../utils/helper';
import { EXERCISE_TYPE, PERMISSIONS } from '../../../../utils/enums';
import MediaItem from '../../PatientExerciseLibrary/ExerciseLibraryItem/MediaItem';
import FolderItem from '../../PatientExerciseLibrary/ExerciseLibraryItem/FolderItem';
import useCheckPermissions from '../../../../hooks/useCheckPermissions';
import { useSetExercisesToPatient } from '../../../../graphql/videoBank';
import { fillDraggedList, startUpdateExerciseLib } from '../../../../redux/patient';
import { SuccessNotifModal } from '../../../../common/NotificationModal';

const PrescribeExercises = ({ patientId, isInvitee }: any): ReactElement => {
  const t: any = useSelector<any>((state: GetState) => getCurrenLang(state));
  const ok = t?.common.ok;
  const save_and_send = t?.dashboard.hcp.profile_patient.video_bank.save_and_send;
  const cancel = t && t.common.cancel;
  const dragdrop_placeholder = t?.dashboard.hcp.profile_patient.video_bank.dragdrop_placeholder;
  const exercises_successfully_sent =
    t?.dashboard.hcp.profile_patient.video_bank.exercises_successfully_sent;

  // Endpoints
  const {
    _setExercisesToPatient,
    loadingExercises,
    errorMessage,
    savedVideoExercises,
  } = useSetExercisesToPatient(isInvitee);

  // Local state
  const [isSuccessSave, setSuccessSave] = useState(false);

  // Redux
  const dispatch = useDispatch();
  const draggedList: any = useSelector<any>((state: GetState) => getDraggedList(state));
  const isPermissionEditVideoExerc = useCheckPermissions(
    PERMISSIONS.EDIT_OR_PRESCRIBE_EXERCISE_VIDEOS_OTM,
  );
  const isDraggedList = !!draggedList?.length;

  // Show error
  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage);
    }
  }, [errorMessage]);

  // Show success message
  useEffect(() => {
    if (savedVideoExercises) {
      setSuccessSave(true);
    }
  }, [savedVideoExercises]);

  // Prescribe exercises to patient
  const sendDraggedExerc = (): void => {
    if (draggedList.length === 0) return;

    const exerciseData: any = [];
    const folderData: any = [];
    draggedList.map((exercise: any) => {
      if (exercise.type === EXERCISE_TYPE.PHOTO || exercise.type === EXERCISE_TYPE.VIDEO) {
        const result = {
          id: exercise.exerciseId,
          name: exercise.name,
          thumbnailName: exercise.thumbnailName,
          videoName: exercise.videoName,
          comment: exercise.exerciseComment,
          sets: exercise.exerciseSets,
          reps: exercise.exerciseReps,
          time: exercise.exerciseTime,
          // archived: false,
          timesPerDay: exercise.exerciseTimesPerDay,
          restDays: exercise.exerciseRestDays,
          videobankId: exercise.currentId,
          videoUploaded: exercise.exerciseVideoUploaded,
          thumbnailUploaded: exercise.exerciseThumbnailUploaded,
          categoriesIds: exercise.exerciseCategories,
          procedureTypesIds: exercise.exerciseProcedureTypes,
          photos: exercise.exercisePhotos ? collectUuids(exercise.exercisePhotos) : null,
        };
        exerciseData.push(result);
        return null;
      }
      if (exercise.type === EXERCISE_TYPE.FOLDER) {
        const result = {
          id: exercise.currentId,
          name: exercise.name.trim(),
          description: exercise.description.trim(),
          amount: exercise.hcpFolderTimer ? exercise.hcpFolderTimer.amount : null,
          timePeriod: exercise.hcpFolderTimer ? exercise.hcpFolderTimer.timePeriod : null,
        };
        folderData.push(result);
      }
      return null;
    });

    const request: any = {
      patientId,
    };
    if (exerciseData.length) {
      request.exerciseData = exerciseData;
    }
    if (folderData.length) {
      request.folderData = folderData;
    }

    _setExercisesToPatient(request);
  };

  // Cancel dragged exercises and update exercise Librarery
  const cancelDraggedExercises = (): void => {
    dispatch(fillDraggedList([]));
    dispatch(startUpdateExerciseLib(true));
    setSuccessSave(false);
  };

  // JSX
  const loadingJSX = loadingExercises && <Loading />;

  return (
    <div>
      {loadingJSX}
      <Droppable droppableId="draggedList">
        {(provided: DroppableProvided, snapshot: DroppableStateSnapshot): JSX.Element => (
          <div
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
            {...provided.droppableProps}
            className={styles['prescrebed__dragdrop-area']}
          >
            {/* Placeholder */}
            <div
              className={cx({
                [styles['prescrebed__dragdrop-placeholder']]: true,
                [styles['prescrebed__dragdrop-placeholder--hide']]: isDraggedList,
              })}
            >
              {dragdrop_placeholder}
            </div>

            {/* List */}
            {isDraggedList &&
              draggedList.map((exercise: any, i: number) => (
                <Draggable
                  key={`${exercise.type}_${exercise.currentId}${String(i)}`}
                  draggableId={`${exercise.type}_${exercise.currentId}`}
                  index={i}
                >
                  {(providedSecondary: any, snapshotSecondary: any): JSX.Element => (
                    <div
                      ref={providedSecondary.innerRef}
                      {...providedSecondary.draggableProps}
                      {...providedSecondary.dragHandleProps}
                      style={getItemStyle(
                        snapshotSecondary.isDragging,
                        providedSecondary.draggableProps.style,
                      )}
                    >
                      {(exercise.type === EXERCISE_TYPE.PHOTO ||
                        exercise.type === EXERCISE_TYPE.VIDEO) && <MediaItem data={exercise} />}
                      {exercise.type === 'folder' && <FolderItem data={exercise} />}
                    </div>
                  )}
                </Draggable>
              ))}
          </div>
        )}
      </Droppable>

      <div className={styles['prescrebed__btns-container']}>
        <Button
          buttonClass={styles.prescrebed__btn}
          buttonType="button"
          buttonName={cancel}
          disabledButton={!isDraggedList}
          buttonMethod={cancelDraggedExercises}
        />
        <Button
          buttonClass={styles.prescrebed__btn}
          buttonType="button"
          buttonName={save_and_send}
          disabledButton={!isPermissionEditVideoExerc || !isDraggedList}
          buttonMethod={sendDraggedExerc}
        />
      </div>

      {/* Popups */}
      {isSuccessSave && (
        <SuccessNotifModal
          onClose={cancelDraggedExercises}
          btnName={ok}
          description={exercises_successfully_sent}
        />
      )}
    </div>
  );
};

export default PrescribeExercises;
