import React, { ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import cx from 'classnames';

import styles from '../styles.module.css';
import { Modal, WarningNotification, Button } from '../../../common';
import { DatePickerRewards } from '../../../common/DatePicker';
import {
  GetState,
  getCurrenLang,
  getCurrentFormatDate,
  getUserLang,
} from '../../../redux/selector';
import { durationCounter, getAmountOfDaysUntilMonday } from '../../../utils/helper';
import { dayInMilliseconds } from '../../../utils/variables';
import { Popup } from '../../../common/Popup';
import { CloseBtn } from '../../../common/Button/Button';

export const RewardsModal = ({
  _onCreatePatientRewards,
  _onShutdownPatientRewards,
  refreshTable,
  turnOnRewardsModal,
  setTurnOnRewardsModal,
  isConfirmRewards,
  setIsConfirmRewards,
  isWarningDeactivedRewards,
  setIsWarningDeactivedRewards,
  setRewardsDate,
}: any): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const userLang: any = useSelector<GetState>((state) => getUserLang(state));
  const formatsDate = useSelector((state: GetState) => getCurrentFormatDate(state));
  const cancel = t?.common.cancel;
  const ok = t?.common.ok;
  const deactivate_btn = t?.common.deactivate;
  const apply = t?.common.apply;
  const today_t = t?.dashboard.hcp.table.today;
  const will_have_access_to_rewards = t?.dashboard.admin.table.patient.will_have_access_to_rewards;
  const rewards_activated = t?.dashboard.admin.table.patient.rewards_activated;
  const selected_weeks = t?.dashboard.admin.table.patient.selected_weeks;
  const number_of_weeks =
    'Select multiple of 4 weeks as the reward scheme are run either over 4 or 8 weeks.'; // t?.dashboard.admin.table.patient.number_of_weeks;
  const reward_start = t?.hcp.rewards.reward_start;
  const set_rewards = t?.hcp.rewards.set_rewards;
  const set_rewards_descr_one = t?.hcp.rewards.set_rewards_descr_one;
  const set_rewards_descr_two = t?.hcp.rewards.set_rewards_descr_two;
  const set_rewards_descr_three = t?.hcp.rewards.set_rewards_descr_three;
  const deactivate_rewards_warning = t?.notifications.deactivate_rewards_warning;
  const cannot_reversed = t?.notifications.cannot_reversed;
  const will_have_access_to_rewards_with_hospital = will_have_access_to_rewards?.replace(
    'the On The Mend',
    turnOnRewardsModal.hospitalName,
  );

  const initState = {
    isOpen: false,
    candidatId: null,
    firstName: '',
    lastName: '',
    hospitalName: '',
  };
  const today = new Date();
  const todayUTC = new Date(today.getTime() + today.getTimezoneOffset() * 60000);
  const dayOfWeek = today.getUTCDay();
  const amountOfDaysUntilMonday = getAmountOfDaysUntilMonday(today, dayOfWeek);
  const nearestMon = new Date(todayUTC.valueOf() + amountOfDaysUntilMonday * dayInMilliseconds);

  const [endDate, setEndDate] = useState<Date | null>(null);
  const [selectedWeeks, setSelectedWeeks] = useState<number>(0);
  const [isAttentionModal, setIsAttentionModal] = useState<boolean>(false);

  const selectPeriodForRewards = (dates: any): void => {
    const [start, end] = dates;
    const { duration } = durationCounter(nearestMon, endDate ? start : end);
    let mathCeilDuration = Math.ceil(duration);

    // The selected period must be a multiple of 4 weeks
    if (mathCeilDuration % 4 !== 0) {
      while (mathCeilDuration % 4 !== 0) {
        mathCeilDuration += 1;
      }
    }
    const jumpToSunday = moment()
      .add(mathCeilDuration + 1, 'weeks')
      .isoWeekday(0)
      .format('YYYY-MM-DD');
    setSelectedWeeks(mathCeilDuration);
    setEndDate(new Date(jumpToSunday));
    setIsAttentionModal(() => false);
  };

  const onCloseRewardsModal = (): void => {
    setTurnOnRewardsModal(() => initState);
    setEndDate(() => null);
    setIsConfirmRewards(() => false);
    setSelectedWeeks(() => 0);
    if (isConfirmRewards && refreshTable) {
      refreshTable();
    }
  };

  const turnOnPatientRewards = (): void => {
    const { duration, newStartDate, newEndDate } = durationCounter(nearestMon, endDate);
    const id = turnOnRewardsModal.candidatId;
    if (id && duration !== 0 && duration % 4 === 0) {
      const startDateToRequest = moment(newStartDate).format('YYYY-MM-DD');
      const endDateToRequest = moment(newEndDate).format('YYYY-MM-DD');
      setRewardsDate({ startDate: startDateToRequest, endDate: endDateToRequest });
      _onCreatePatientRewards(id, startDateToRequest, endDateToRequest);
    } else {
      setIsAttentionModal(() => true);
    }
  };
  const onCloseConfirmRewardsModal = (): void => {
    onCloseRewardsModal();
  };
  const deactivateRewards = (): void => {
    if (isWarningDeactivedRewards.candidatId) {
      _onShutdownPatientRewards(
        isWarningDeactivedRewards.candidatId,
        moment(todayUTC).format('YYYY-MM-DD'),
      );
    }
  };

  return (
    <>
      {turnOnRewardsModal.isOpen && (
        <Modal onClose={onCloseRewardsModal} style={styles.modal__rewards}>
          <CloseBtn close={onCloseRewardsModal} />

          <div className={styles['modal__rewards-title']}>{set_rewards}</div>
          <div className={styles['modal__rewards-description']}>{set_rewards_descr_one}</div>
          <div className={styles['modal__rewards-description']}>{set_rewards_descr_two}</div>
          <div className={styles['modal__rewards-description']}>{set_rewards_descr_three}</div>
          <div className={styles.spacer} />
          <div className={styles['modal__rewards-picker-cont']}>
            <DatePickerRewards
              name="Rewards"
              onChange={selectPeriodForRewards}
              startDate={nearestMon}
              // selected={today}
              selected={nearestMon}
              minDate={nearestMon}
              endDate={endDate}
              inline
              currentLang={userLang}
            />
          </div>
          <div className={styles['modal__rewards-legend-container']}>
            <div className={styles['modal__rewards-legend-item']}>
              <div
                className={cx({
                  [styles['modal__rewards-legend']]: true,
                  [styles.blue]: true,
                })}
              />
              <div className={styles['modal__rewards-legend-label']}>{today_t}</div>
            </div>
            <div className={styles['modal__rewards-legend-item']}>
              <div
                className={cx({
                  [styles['modal__rewards-legend']]: true,
                  [styles.orange]: true,
                })}
              />
              <div className={styles['modal__rewards-legend-label']}>{reward_start}</div>
            </div>
          </div>
          <div className={styles['modal__rewards-selected-weeks']}>
            {`${selected_weeks}: ${selectedWeeks}`}
          </div>
          {isAttentionModal && (
            <div className={styles['modal__rewards-error']}>{number_of_weeks}</div>
          )}
          <div className={styles['modal__rewards-btn-cont']}>
            <Button
              buttonClass={styles['modal__rewards-btn']}
              buttonType="button"
              buttonName={cancel}
              buttonMethod={onCloseRewardsModal}
            />
            <Button
              buttonClass={styles['modal__rewards-btn']}
              buttonType="submit"
              buttonName={apply}
              buttonMethod={turnOnPatientRewards}
            />
          </div>
        </Modal>
      )}
      {isConfirmRewards && (
        <Popup
          title={rewards_activated}
          content={`${
            turnOnRewardsModal.firstName
          } ${will_have_access_to_rewards_with_hospital} ${moment(nearestMon).format(
            formatsDate.momentFormat,
          )}.`}
          buttonName={ok}
          onClosePopup={onCloseConfirmRewardsModal}
        />
      )}
      {/* {isAttentionModal && (
        <Popup
          title={number_of_weeks}
          content=""
          buttonName={ok}
          onClosePopup={onCloseAttentionModal}
        />
      )} */}
      {isWarningDeactivedRewards.isOpen && (
        <Modal
          style={styles.modal}
          onClose={(): void => setIsWarningDeactivedRewards(() => initState)}
        >
          <WarningNotification
            title={`${deactivate_rewards_warning} ${isWarningDeactivedRewards.firstName} ${isWarningDeactivedRewards.lastName}?`}
            contant={cannot_reversed}
            buttonName={deactivate_btn}
            onCancel={(): void => setIsWarningDeactivedRewards(() => initState)}
            onSubmit={deactivateRewards}
          />
        </Modal>
      )}
    </>
  );
};
