// @ts-nocheck
import React, { ReactElement } from 'react';
import { Tooltip } from 'antd';
import { DeleteIcon } from '../../../../../../theme/icons';
import style from '../style.module.css';
import { RemoveQuestionnaireProps } from '../types';

export const RemoveQuestionnaire = ({
  onMethod,
  title,
}: RemoveQuestionnaireProps): ReactElement => (
  <div className={style.delete__container}>
    <div className={style.delete__line} />
    <div className={style.delete__btn} onClick={onMethod} role="presentation">
      <Tooltip zIndex={4002} title={title}>
        <DeleteIcon />
      </Tooltip>
    </div>
  </div>
);
