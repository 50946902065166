import React, { ReactElement, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Pagination } from 'antd';
import { toast } from 'react-toastify';

import styles from './styles.module.css';
import style from '../../../pages/Video/Exercise_video_bank/styles.module.css';
import '../../../theme/pagination.css';
import { Loading, MainTitle } from '../../../common';
import { getCurrenLang } from '../../../redux/selector';
import { PatientExercisePlanType, PdfPlansProps } from './types';
import PdfItem from './pdfItem';
import { initialPage } from '../../../utils/helper';
import { PAGE_SIZE_OPTIONS_FOR_PDF_PLANS } from '../../../utils/variables';
import { useGetPatientExercisePlans } from '../../../graphql/videoBank';

const PdfPlans = ({ email, userName, isInvitee, patientId }: PdfPlansProps): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const no_data = t?.common.no_data;
  const pdf_plans = t?.dashboard.hcp.profile_patient.pdf_plans;

  const [pdfPlans, setPdfPlans] = useState<PatientExercisePlanType[]>([]);
  const [totalElements, setTotalElements] = useState(0);
  const [currentPage, setCurrentPage] = useState<number>(initialPage);
  const [pageSize, setPageSize] = useState<number>(8);

  const {
    _getPatientExercisePlans,
    patientPlans,
    loading,
    error,
    totalItems,
    data,
  } = useGetPatientExercisePlans(isInvitee);

  // Get PDF plans from DB
  useEffect(() => {
    _getPatientExercisePlans({
      variables: {
        patientId,
        listProps: {
          page: initialPage,
          itemsPerPage: pageSize,
        },
      },
    });
  }, []);

  // Fill exercise prescription history report for connected patient
  useEffect(() => {
    if (!loading && !error) {
      setPdfPlans(patientPlans);
    }
  }, [data]);

  // Fill totalElements for Pagination for connected patient
  useEffect(() => {
    if (!loading) {
      setTotalElements(totalItems);
    }
  }, [data]);

  // If an error occurred while loading the pdf plan, we display a message
  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  // Go to the corresponding page
  const onChangePagination = (page: any, itemsPerPage: any): void => {
    setPageSize(itemsPerPage);
    setCurrentPage(page);
    _getPatientExercisePlans({
      variables: {
        patientId,
        listProps: {
          page,
          itemsPerPage,
        },
      },
    });
  };

  // JSX
  const loadingJSX = loading && <Loading />;
  const noData = pdfPlans?.length === 0 && !loading && (
    <div className={style.noData}>{no_data}</div>
  );

  return (
    <div className={styles['pdf-plans__wrapper']}>
      <MainTitle title={pdf_plans} />
      <div className={styles['pdf-plans__container']}>
        {loadingJSX}
        {noData}
        {pdfPlans?.length > 0 ? (
          pdfPlans.map((pdfPlan: PatientExercisePlanType, i: number) => (
            <PdfItem
              key={`pdfPlan${pdfPlan.exercisePlanUuid}${String(i)}`}
              pdfPlan={pdfPlan}
              email={email}
              userName={userName}
              isInvitee={isInvitee}
            />
          ))
        ) : (
          <></>
        )}
      </div>
      {!noData && (
        <div className="wrapper_pagination">
          <Pagination
            size="small"
            current={currentPage}
            pageSize={pageSize}
            total={totalElements}
            onChange={onChangePagination}
            pageSizeOptions={PAGE_SIZE_OPTIONS_FOR_PDF_PLANS}
            showSizeChanger
          />
        </div>
      )}
    </div>
  );
};

export default PdfPlans;
