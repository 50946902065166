import React, { ReactElement } from 'react';
import cx from 'classnames';

import styles from './styles.module.css';
import { BellEmptyIcon, BellFillIcon } from '../../../theme/icons';
import { BellIconProps } from '../types';

const BellIcon = (props: BellIconProps): ReactElement => {
  const { showNotifications, countUnread, notificationsList } = props;

  return (
    <div
      role="presentation"
      onClick={showNotifications}
      className={styles['notification-icon-container']}
    >
      {notificationsList && countUnread ? (
        <BellFillIcon color="primary" />
      ) : (
        <BellEmptyIcon color="primary" />
      )}
      <div
        className={cx({
          [styles['notification-counter']]: true,
          [styles.visible]: notificationsList?.length && countUnread,
        })}
      >
        {countUnread > 9 ? '9+' : countUnread}
      </div>
    </div>
  );
};

export default BellIcon;
