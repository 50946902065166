import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Pagination } from 'antd';
import { toast } from 'react-toastify';

import styles from '../styles.module.css';
import { Loading } from '../../../../common';
import { GetState, getCurrenLang, getStartUpd } from '../../../../redux/selector';
import { EXERCISE_TYPE } from '../../../../utils/enums';
import { initialPage } from '../../../../utils/helper';
import { useGetCurrentPatientExerciseList } from '../../../../graphql/videoBank';
import { PAGE_SIZE_OPTIONS_FOR_VIDEO_EXERCISE } from '../../../../utils/variables';
import useGetFocuses from '../../../../hooks/useGetFocuses';
import MediaExercise from '../MediaExercise';
import FolderExercises from '../FolderExercises';
import { startUpdateExerciseLib } from '../../../../redux/patient';

const PrescribedExercises = ({ patientId, isInvitee }: any): ReactElement => {
  const t: any = useSelector<any>((state: GetState) => getCurrenLang(state));
  const prescribed = t?.dashboard.hcp.profile_patient.video_bank.exercise_prescribed_this_patient;

  const dispatch = useDispatch();
  const isStartUpdate: any = useSelector<any>((state: GetState) => getStartUpd(state));

  // Endpoint
  // get exercise list
  const {
    _getCurrentPatientExerciseList,
    exerciseList,
    exerciseListData,
    loadingList,
    errorList,
  } = useGetCurrentPatientExerciseList(isInvitee);

  // Local state
  const [exerciseListState, setExersiseListState] = useState<any>();
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [pageSize, setPageSize] = useState(15);
  const [totalItems, setTotalItems] = useState(0);

  const [sortBy, setSortby] = useState<string>('name');
  const [sortDirectionName, setSortDirectionName] = useState<string>('');

  const {
    focusType1Arr,
    focusType2Arr,
    exercisesCategories,
    exercisesProcedureFocus,
  } = useGetFocuses();

  // Get List
  useEffect(() => {
    if (patientId) {
      _getCurrentPatientExerciseList(
        currentPage,
        pageSize,
        sortBy,
        sortDirectionName,
        patientId,
        false,
        '',
      );
    }
  }, [patientId]);

  // Update list
  useEffect(() => {
    if (patientId && isStartUpdate) {
      _getCurrentPatientExerciseList(
        currentPage,
        pageSize,
        sortBy,
        sortDirectionName,
        patientId,
        false,
        '',
      );
    }
  }, [isStartUpdate]);

  // Fill List
  useEffect(() => {
    if (exerciseList && !loadingList) {
      setExersiseListState(exerciseList.list);
      setTotalItems(exerciseList.totalItems);
      dispatch(startUpdateExerciseLib(false));
    }
  }, [exerciseListData]);

  // Show error
  useEffect(() => {
    if (errorList) {
      toast.error(errorList);
    }
  }, [errorList]);

  const updateList = (): void => {
    _getCurrentPatientExerciseList(
      currentPage,
      pageSize,
      sortBy,
      sortDirectionName,
      patientId,
      false,
      '',
    );
  };

  // Go to the next page
  const onChangePagination = (page: any, itemsPerPage: any): void => {
    setCurrentPage(page);
    setPageSize(itemsPerPage);
    _getCurrentPatientExerciseList(
      page,
      itemsPerPage,
      sortBy,
      sortDirectionName,
      patientId,
      false,
      '',
    );
  };

  // JSX
  const loadingJSX = loadingList && <Loading />;
  const noData = exerciseListState && exerciseListState.length === 0 && (
    <div className={styles.prescrebed__nodata}>{prescribed}</div>
  );

  return (
    <div className={styles['exerc-list__container']}>
      {loadingJSX}

      {!!exerciseListState?.length &&
        t &&
        exerciseListState.map((exercise: any, i: number) => (
          <React.Fragment key={`fragment${exercise.currentId}${String(i)}`}>
            {(exercise.type === EXERCISE_TYPE.PHOTO || exercise.type === EXERCISE_TYPE.VIDEO) && (
              <MediaExercise
                data={exercise}
                exercisesCategories={exercisesCategories}
                exercisesProcedureFocus={exercisesProcedureFocus}
                focusType1Arr={focusType1Arr}
                focusType2Arr={focusType2Arr}
                updateList={updateList}
                isInvitee={isInvitee}
                patientId={patientId}
              />
            )}
            {exercise.type === 'folder' && (
              <FolderExercises
                data={exercise}
                exercisesCategories={exercisesCategories}
                exercisesProcedureFocus={exercisesProcedureFocus}
                focusType1Arr={focusType1Arr}
                focusType2Arr={focusType2Arr}
                updateList={updateList}
                isInvitee={isInvitee}
                patientId={patientId}
                index={i}
              />
            )}
          </React.Fragment>
        ))}
      {noData}

      <div className="wrapper_pagination">
        <Pagination
          size="small"
          current={currentPage}
          pageSize={pageSize}
          total={totalItems}
          onChange={onChangePagination}
          pageSizeOptions={PAGE_SIZE_OPTIONS_FOR_VIDEO_EXERCISE}
          showSizeChanger
        />
      </div>
    </div>
  );
};

export default PrescribedExercises;
