import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import style from './styles.module.css';
import { Button, Loading, MainTitle } from '../../common';
import { Options, ReminderPeriodOptionsType } from '../../utils/model';
import { useSetHcpNotificationToPatient } from '../../graphql/hcp';
import { getReminderPeriodOptions } from '../../utils/share/options';
import { validationNotifToMyPatients } from '../../utils/validators';
import { NotifDataType } from './types';
import { GetState, getCurrenLang } from '../../redux/selector';
import { setOpenSettingsData } from '../../redux/emailNotifications';
import { SelectFloatLabel } from '../../common/Input/Select';
import { SuccessNotifModal } from '../../common/NotificationModal';
import { PopupWithTwoButtons } from '../../common/Popup';

export const NotifToPatientsSettingsForm = ({
  openSettingsData,
  updateData,
}: any): ReactElement => {
  const t: any = useSelector<any>((state: GetState) => getCurrenLang(state));
  const ok = t?.common.ok;
  const exit = t?.common.exit;
  const send_reminder_after_surgery = t?.hcp.manage.send_reminder_after_surgery;
  const warning_unsaved_changes = t?.common.warning_unsaved_changes;
  const cancel = t?.common.cancel;
  const save = t?.common.save;
  const settings_updated = t?.notifications.settings_updated;

  // Endpoints
  const {
    _setHcpNotifToPatient,
    setHcpNotifToPatientLoading,
    setHcpNotifToPatientError,
    setHcpNotifToPatientData,
  } = useSetHcpNotificationToPatient();

  // Variables
  const dispatch = useDispatch();
  const reminderPeriodOptions = useMemo(() => getReminderPeriodOptions(t), [t]);
  const getRemindersPeriodValue = (
    timePeriod: number | undefined | null,
    amount: number | undefined | null,
  ): ReminderPeriodOptionsType | any => {
    if (timePeriod && amount) {
      const result = reminderPeriodOptions.filter(
        (item: ReminderPeriodOptionsType) =>
          // eslint-disable-next-line implicit-arrow-linebreak
          item.timePeriod === timePeriod && item.amount === amount,
      );
      return result?.[0] || '';
    }
    return '';
  };
  const remindersPeriodOption = getRemindersPeriodValue(
    openSettingsData?.timePeriod,
    openSettingsData?.amount,
  );

  // Local state
  const [isUpdatedSuccess, setUpdatedSuccess] = useState(false);
  const [isExitWarn, setExitWarn] = useState(false);

  // If set Hcp Email Notification success show popup
  useEffect(() => {
    if (setHcpNotifToPatientData) {
      setUpdatedSuccess(() => true);
    }
  }, [setHcpNotifToPatientData]);

  // If set Hcp Email Notification error show message
  useEffect(() => {
    if (setHcpNotifToPatientError) {
      toast.error(setHcpNotifToPatientError);
    }
  }, [setHcpNotifToPatientError]);

  const initValues = useMemo(
    () => ({
      id: openSettingsData?.id || null,
      isSendNotification: openSettingsData?.isSendNotification || false,
      daysPostSurgery: remindersPeriodOption,
    }),
    [t, openSettingsData],
  );

  const formik = useFormik({
    // enableReinitialize: true,
    initialValues: initValues,
    validationSchema: () => validationNotifToMyPatients(t),
    onSubmit: (val: any) => {
      const request: NotifDataType = {
        notifData: {
          id: val.id,
          isSendNotification: true,
          timePeriod: val.daysPostSurgery.timePeriod,
          amount: val.daysPostSurgery.amount,
        },
      };
      _setHcpNotifToPatient(request);
    },
  });
  const { values, errors, touched } = formik;

  // Select handler
  const selectRemainderPeriod = (name: string, value: Options): void => {
    formik.setFieldValue(name, value);
  };

  const closeSettings = (): void => {
    dispatch(setOpenSettingsData(undefined));
  };

  // Cancel changes and clouse settings
  const onCancel = (): void => {
    if (formik.dirty) {
      setExitWarn(() => true);
      return;
    }
    closeSettings();
    // formik.resetForm();
  };

  // Clouse success modal window
  const onClouseSuccessModal = (): void => {
    updateData();
    setUpdatedSuccess(() => false);
    closeSettings();
  };

  // Confirm exit from settings
  const onConfirmExit = async (): Promise<void> => {
    setExitWarn(() => false);
    // formik.resetForm();
    closeSettings();
  };

  // Close Exit warning popup
  const onCloseExitWarn = (): void => {
    setExitWarn(() => false);
  };

  // JSX
  const loadingJSX = setHcpNotifToPatientLoading && <Loading />;

  return (
    <>
      {loadingJSX}
      <div className={style['email-notif__title-wrapper--bg']}>
        <MainTitle title={openSettingsData.title} />
      </div>

      <div className={style['email-notif__subtitle']}>{openSettingsData.description}</div>
      <form onSubmit={formik.handleSubmit}>
        <div className={style['email-notif__field--wound']}>
          <SelectFloatLabel
            inputId="daysPostSurgeryId"
            name="daysPostSurgery"
            placeholder={send_reminder_after_surgery}
            onChange={selectRemainderPeriod}
            options={reminderPeriodOptions}
            hasErrors={errors.daysPostSurgery}
            isTouched={touched.daysPostSurgery}
            selected={values.daysPostSurgery}
            inputValue={values.daysPostSurgery}
            type="rewards"
          />
        </div>

        <div className={style['email-notif__btns-container']}>
          <Button
            buttonName={cancel}
            buttonType="button"
            buttonClass={style['email-notif__btn']}
            buttonMethod={onCancel}
          />
          <Button
            buttonName={save}
            buttonType="submit"
            buttonClass={style['email-notif__btn']}
            disabledButton={!formik.dirty}
          />
        </div>
      </form>

      {/* Popups */}
      {isUpdatedSuccess && (
        <SuccessNotifModal
          onClose={onClouseSuccessModal}
          btnName={ok}
          description={settings_updated}
        />
      )}

      {/* Warning popup if has not saved changes */}
      {isExitWarn && (
        <PopupWithTwoButtons
          title={warning_unsaved_changes}
          content=""
          confirmButtonName={exit}
          closeButtonName={cancel}
          onConfirm={onConfirmExit}
          onClosePopup={onCloseExitWarn}
        />
      )}
    </>
  );
};
