import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Breadcrumbs, Typography } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Breadcrumb as BreadcrumbAntd } from 'antd';
import { Link } from 'react-router-dom';

import { getCurrenLang } from '../../redux/selector';
import { constants, path } from './book';
import {
  BreadcrumbAntProps,
  BreadcrumbProps,
  BreadcrumbRoutes,
  BreadcrumbsMapLinksProp,
  LinksConfig,
} from './types';
import useRedirectToPath from '../../hooks/useRedirectToPath';
import { userCtx } from '../../hooks/useContext';
import { Separator } from '../../theme/icons';

export const Breadcrumb = ({ title }: BreadcrumbProps): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const dashboard = t?.title.dashboard;
  const userRole = userCtx.userFromLS;
  const isAdmin = userRole === constants.admin;
  const pathHome = isAdmin ? path.adminDashboardList : path.dashboardList;

  const { redirectToPage } = useRedirectToPath();

  return (
    <div>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        <div
          className="breadCrumbs-item"
          onClick={(): void => redirectToPage(pathHome)}
          aria-hidden="true"
        >
          {dashboard}
        </div>
        <Typography color="textPrimary">{title}</Typography>
      </Breadcrumbs>
    </div>
  );
};

export const BreadcrumbNested = ({ title, links }: BreadcrumbsMapLinksProp): ReactElement => {
  const { redirectToPage } = useRedirectToPath();

  return (
    <div>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        {links.map((link: LinksConfig) => (
          <div
            key={link.path}
            className="breadCrumbs-item"
            onClick={(): void => redirectToPage(link.path)}
            aria-hidden="true"
          >
            {link.name}
          </div>
        ))}
        <Typography noWrap style={{ maxWidth: '250px' }} color="textPrimary">
          {title}
        </Typography>
      </Breadcrumbs>
    </div>
  );
};

export const BreadcrumbAnt = ({ currentRoutes }: BreadcrumbAntProps): ReactElement => {
  const itemRender = (
    route: any,
    params: any,
    routes: BreadcrumbRoutes[],
    paths: any,
  ): JSX.Element => {
    const last = routes.indexOf(route) === routes.length - 1;
    return last ? (
      <span key={route.path}>{route.breadcrumbName}</span>
    ) : (
      <Link key={route.path} to={route.path}>
        {route.breadcrumbName}
      </Link>
    );
  };
  const separator = (
    <span style={{ verticalAlign: 'middle' }}>
      <Separator />
    </span>
  );

  return (
    <div className="breadcrumbAntd-item">
      <BreadcrumbAntd separator={separator} itemRender={itemRender} routes={currentRoutes} />
    </div>
  );
};
