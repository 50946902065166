// Core
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { ProcedureKey } from '../../utils/model';
import {
  setLoadingProcedureKeys,
  setLoadingProcedures,
  setProcedureKeys,
  setProcedures,
} from '../../redux/common';
import { UseGetProcedures } from '../types';

// Query
const queryGetKeysOfProcedures = loader('./gql/queryGetKeysOfProcedures.graphql');
const queryGetProcedures = loader('./gql/queryGetProcedures.graphql');

interface UseGetKeysOfProcedures {
  listProcedureKeys: ProcedureKey[];
  errorKeysOfProcedures: ApolloError | undefined;
  loadingKeysOfProcedures: boolean;
}

// All procedures (patient focuses)
export const useGetKeysOfProcedures = (): UseGetKeysOfProcedures => {
  const { loading, error, data } = useQuery(queryGetKeysOfProcedures);
  const dispatch = useDispatch();
  const response = data && data.getKeysOfProcedures;

  useEffect(() => {
    if (data) {
      dispatch(setProcedureKeys(response));
    }
  }, [response]);

  useEffect(() => {
    dispatch(setLoadingProcedureKeys(loading));
  }, [loading]);

  return {
    listProcedureKeys: response,
    errorKeysOfProcedures: error,
    loadingKeysOfProcedures: loading,
  };
};

// Get procedures list
export const useGetProcedures = (): UseGetProcedures => {
  const { loading, error, data } = useQuery(queryGetProcedures);
  const dispatch = useDispatch();
  const procedures = data ? data.getProcedures : null;

  useEffect(() => {
    if (data) {
      dispatch(setProcedures(procedures));
    }
  }, [data]);

  useEffect(() => {
    dispatch(setLoadingProcedures(loading));
  }, [loading]);

  return {
    procedures,
    errorProcedures: error,
    loadingProcedures: loading,
  };
};
