import { TExerciseCategories } from '../../components/Video/OrganisationVideoBank/types';
import { storage } from '../../utils';
import { config } from '../../utils/configs';
import { UserCountryEnum } from '../../utils/enums';
import { CurrentFormatDates, ProcedureKey } from '../../utils/model';
import { dotFormatDate, formatDateEU, sideBarKey } from '../../utils/variables';

// Type
const SET_CURRENT_INNERWIDTH = 'SET_CURRENT_INNERWIDTH';
const FILL_CURRENT_HCP_NAME = 'FILL_CURRENT_HCP_NAME';
const FILL_CURRENT_HCP_NAME_LOADING = 'FILL_CURRENT_HCP_NAME_LOADING';
const SET_CURRENT_DATE_FORMAT = 'SET_CURRENT_DATE_FORMAT';
const SET_CURRENT_USER_LANGUAGE = 'SET_CURRENT_USER_LANGUAGE';
const SET_CURRENT_USER_COUNTRY = 'SET_CURRENT_USER_COUNTRY';
const SET_PROCEDURE_KEYS = 'SET_PROCEDURE_KEYS';
const SET_LOADING_PROCEDURE_KEYS = 'SET_LOADING_PROCEDURE_KEYS';
const FILL_CURRENT_HCP_AVATAR_PIC = 'FILL_CURRENT_HCP_AVATAR_PIC';
const FILL_CURRENT_HCP_AVATAR_UUID = 'FILL_CURRENT_HCP_AVATAR_UUID';
const SET_PROCEDURES = 'SET_PROCEDURES';
const SET_LOADING_PROCEDURES = 'SET_LOADING_PROCEDURES';
const SET_EXERCISE_CATEGORIES = 'SET_EXERCISE_CATEGORIES';
const SET_EXERCISE_CATEGORIES_LOADING = 'SET_EXERCISE_CATEGORIES_LOADING';
const SET_COLLAPSED = 'SET_COLLAPSED';
const TOGGLE_MENU = 'TOGGLE_MENU';

// TS
export interface HcpName {
  firstName: string;
  lastName: string;
  allowedPromsId: object;
  country: string;
  id: number | null;
  email: string;
  permissions: any[];
  isAutomaticallyAccept: boolean;
  rewardsType: number;
  recipients: any[];
  hospitalId: number | null;
  profession: string;
  photoUuid: string;
  photoPic: string;
}
export interface CommonType {
  screen: {
    innerWidth: number;
  };
  hcp: HcpName;
  hcpLoading: boolean;
  formatDates: CurrentFormatDates;
  userLang: string;
  userCountry: string;
  listProcedureKeys: ProcedureKey[] | null;
  loadingKeysOfProcedures: boolean;
  procedures: TExerciseCategories[] | null;
  loadingProcedures: boolean;
  exerciseCategoriesList: TExerciseCategories[] | null;
  loadingExerciseCategoriesList: boolean;
  collapsed: number | boolean;
  isShowMenu: boolean;
}
interface WindowScreenType {
  type: string;
  payload: number;
}
interface HcpNameType {
  type: string;
  payload: HcpName;
}
interface LoadingType {
  type: string;
  payload: boolean;
}
interface CurrentDateFormatType {
  type: string;
  payload: CurrentFormatDates;
}
interface PayLoadStringType {
  type: string;
  payload: string;
}
interface AllProceduresKeyType {
  type: string;
  payload: ProcedureKey[];
}
interface AllProceduresType {
  type: string;
  payload: TExerciseCategories[];
}
type ActionTypes =
  | WindowScreenType
  | HcpNameType
  | LoadingType
  | CurrentDateFormatType
  | PayLoadStringType;

// Action
export const setWindowClientWidth = (value: number): WindowScreenType => ({
  type: SET_CURRENT_INNERWIDTH,
  payload: value,
});
export const setHcpName = (payload: HcpName): HcpNameType => ({
  type: FILL_CURRENT_HCP_NAME,
  payload,
});
export const setHcpAvatarPic = (payload: string): PayLoadStringType => ({
  type: FILL_CURRENT_HCP_AVATAR_PIC,
  payload,
});
export const setHcpAvatarUuid = (payload: string): PayLoadStringType => ({
  type: FILL_CURRENT_HCP_AVATAR_UUID,
  payload,
});
export const setHcpNameLoading = (payload: boolean): LoadingType => ({
  type: FILL_CURRENT_HCP_NAME_LOADING,
  payload,
});
export const setCurrentDateFormat = (payload: CurrentFormatDates): CurrentDateFormatType => ({
  type: SET_CURRENT_DATE_FORMAT,
  payload,
});
export const setCurrentLang = (payload: string): PayLoadStringType => ({
  type: SET_CURRENT_USER_LANGUAGE,
  payload,
});
export const setCurrentCountry = (payload: string): PayLoadStringType => ({
  type: SET_CURRENT_USER_COUNTRY,
  payload,
});
export const setProcedureKeys = (payload: ProcedureKey[]): AllProceduresKeyType => ({
  type: SET_PROCEDURE_KEYS,
  payload,
});
export const setLoadingProcedureKeys = (payload: boolean): LoadingType => ({
  type: SET_LOADING_PROCEDURE_KEYS,
  payload,
});
export const setProcedures = (payload: TExerciseCategories[]): AllProceduresType => ({
  type: SET_PROCEDURES,
  payload,
});
export const setLoadingProcedures = (payload: boolean): LoadingType => ({
  type: SET_LOADING_PROCEDURES,
  payload,
});
export const setExerciseCategories = (payload: TExerciseCategories[]): AllProceduresType => ({
  type: SET_EXERCISE_CATEGORIES,
  payload,
});
export const setExerciseCategoriesLoading = (payload: boolean): LoadingType => ({
  type: SET_EXERCISE_CATEGORIES_LOADING,
  payload,
});
export const setCollapsed = (payload: number | boolean): any => ({
  type: SET_COLLAPSED,
  payload,
});
export const toggleMenu = (payload: boolean): any => ({
  type: TOGGLE_MENU,
  payload,
});

// Initial comon STATE
const init: CommonType = {
  screen: { innerWidth: 0 },
  hcp: {
    firstName: '',
    lastName: '',
    allowedPromsId: [],
    country: UserCountryEnum.GB,
    id: null,
    email: '',
    permissions: [],
    isAutomaticallyAccept: false,
    rewardsType: 1,
    recipients: [],
    hospitalId: null,
    profession: '',
    photoUuid: '',
    photoPic: '',
  },
  hcpLoading: false,
  formatDates: { momentFormat: formatDateEU, reactDatePickerFormat: dotFormatDate },
  userLang: config.language || 'en-gb',
  userCountry: UserCountryEnum.GB,
  listProcedureKeys: null,
  loadingKeysOfProcedures: false,
  procedures: null,
  loadingProcedures: false,
  exerciseCategoriesList: null,
  loadingExerciseCategoriesList: false,
  collapsed: !!Number(storage.get(sideBarKey)), // 0,
  isShowMenu: false,
};

// Reducer
export const commonReducer = (state = init, { type, payload }: ActionTypes): any => {
  switch (type) {
    case SET_CURRENT_INNERWIDTH:
      return {
        ...state,
        screen: { innerWidth: payload },
      };
    case FILL_CURRENT_HCP_NAME:
      return {
        ...state,
        hcp: payload,
      };
    case FILL_CURRENT_HCP_NAME_LOADING:
      return {
        ...state,
        hcpLoading: payload,
      };
    case FILL_CURRENT_HCP_AVATAR_PIC:
      return {
        ...state,
        hcp: {
          ...state.hcp,
          photoPic: payload,
        },
      };
    case FILL_CURRENT_HCP_AVATAR_UUID:
      return {
        ...state,
        hcp: {
          ...state.hcp,
          photoUuid: payload,
        },
      };
    case SET_CURRENT_DATE_FORMAT:
      return {
        ...state,
        formatDates: payload,
      };
    case SET_CURRENT_USER_LANGUAGE:
      return {
        ...state,
        userLang: payload,
      };
    case SET_CURRENT_USER_COUNTRY:
      return {
        ...state,
        userCountry: payload,
      };
    case SET_PROCEDURE_KEYS:
      return {
        ...state,
        listProcedureKeys: payload,
      };
    case SET_LOADING_PROCEDURE_KEYS:
      return {
        ...state,
        loadingProcedures: payload,
      };
    case SET_PROCEDURES:
      return {
        ...state,
        procedures: payload,
      };
    case SET_LOADING_PROCEDURES:
      return {
        ...state,
        loadingProcedures: payload,
      };
    case SET_EXERCISE_CATEGORIES:
      return {
        ...state,
        exerciseCategoriesList: payload,
      };
    case SET_EXERCISE_CATEGORIES_LOADING:
      return {
        ...state,
        loadingExerciseCategoriesList: payload,
      };
    case SET_COLLAPSED:
      return {
        ...state,
        collapsed: payload,
      };
    case TOGGLE_MENU:
      return {
        ...state,
        isShowMenu: payload,
      };
    default:
      return state;
  }
};
