import { useHistory } from 'react-router-dom';
import { path } from '../utils';
import { initialPage, setQueryParams } from '../utils/helper';
import { LIST_TYPE, StatusKeyNameEnum } from '../utils/enums';

const useRedirectFromNotification = () => {
  const history = useHistory();
  const redirectFromNotification = (
    currentType: string,
    currentPatientId: number,
    currentPatientStatus: string,
  ): void => {
    let redirectType = currentType;
    // Cannot see patient profile
    if (
      currentPatientStatus === StatusKeyNameEnum.declined ||
      currentPatientStatus === StatusKeyNameEnum.disable ||
      currentPatientStatus === StatusKeyNameEnum.disconnected ||
      currentPatientStatus === StatusKeyNameEnum.expired ||
      currentPatientStatus === StatusKeyNameEnum.treatment_completed
    ) {
      redirectType = '';
    }
    // Only can see videobank
    if (
      currentPatientStatus === StatusKeyNameEnum.invite_sent ||
      currentPatientStatus === StatusKeyNameEnum.pending
    ) {
      redirectType = 'VIDEOBANK';
    }
    if (!currentPatientId || !currentPatientStatus) {
      redirectType = '';
    }
    switch (redirectType) {
      case 'VIDEOBANK':
      case 'EMAIL_NOTIFICATION':
        history.push({
          pathname: path.patient_exercise_library,
          search: setQueryParams({
            userId: currentPatientId,
            userStatus: currentPatientStatus,
          }),
        });
        return;
      case 'PERSONAL_INFORMATION':
      case 'GOALS':
        history.push({
          pathname: path.patient_profile,
          search: setQueryParams({
            userId: currentPatientId,
            userStatus: currentPatientStatus,
          }),
        });
        return;
      case 'DOCUMENT':
        history.push({
          pathname: path.patient_wound_images,
          search: setQueryParams({
            userId: currentPatientId,
            userStatus: currentPatientStatus,
          }),
        });
        return;
      case 'TEAMS':
        history.push({
          pathname: path.patient_rehab_team,
          search: setQueryParams({
            userId: currentPatientId,
            userStatus: currentPatientStatus,
          }),
        });
        return;
      default:
        history.push({
          pathname: path.dashboardList,
          search: setQueryParams({
            category: LIST_TYPE.PATIENT,
            page: initialPage,
          }),
        });
    }
  };

  return { redirectFromNotification };
};

export default useRedirectFromNotification;
