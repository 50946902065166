import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getCurrenLang } from '../../../../redux/selector';
import styles from '../style.module.css';
import { Confirmation, FormFormik, Loading, Modal } from '../../../../common';
import { ChangePasswordForm } from '../../../../components/Form/ReserPassword';
import { useHcpChangePassword } from '../../../../graphql/hcpProfile';
import { validateChangePassword, validationChangePassword } from '../../../../utils/validators';
import { CloseBtn } from '../../../../common/Button/Button';

const ChangePassword = ({
  setOpenChangePassPage,
  setHasChanges,
  checkChanges,
}: any): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const ok = t?.common.ok;
  const success = t?.profile_hcp.success;
  const your_password_was_updated = t?.profile_hcp.your_password_was_updated;

  const [successModal, setSuccessModal] = useState(false);

  const initialChangePassword = {
    currentPassword: '',
    newPassword: '',
    repeatNewPassword: '',
  };

  const {
    _changeHcpPassword,
    loadingChangePass,
    errorChangePass,
    isPasswordChanged,
  } = useHcpChangePassword();

  // Show modal if password success changed
  useEffect(() => {
    if (isPasswordChanged) {
      setSuccessModal(() => isPasswordChanged);
    }
  }, [isPasswordChanged]);

  // Send data to DB
  const _send = (values: any): void => {
    _changeHcpPassword(values);
  };

  // Cancel change password
  const cancelChangePassword = (): void => {
    checkChanges(() => setOpenChangePassPage(() => false));
  };

  const closeSuccessModal = (): void => {
    setOpenChangePassPage(() => false);
    setHasChanges(() => false);
  };

  return (
    <>
      {/* Loader */}
      {loadingChangePass && <Loading />}

      {/* Page content */}
      <FormFormik
        initialValues={initialChangePassword}
        validate={(values: any): any => validateChangePassword(values, t)}
        schema={(): any => validationChangePassword(t)}
        onSend={_send}
        errorMessage={errorChangePass}
        onCancel={cancelChangePassword}
        component={ChangePasswordForm}
        setHasChanges={setHasChanges}
      />

      {/* Success modal */}
      {successModal && (
        <Modal style={styles['change-password__modal']} onClose={closeSuccessModal}>
          <CloseBtn close={closeSuccessModal} />
          <div className={styles['change-password__conf-wrapper']}>
            <Confirmation
              icon="success"
              title={success}
              contant={your_password_was_updated}
              buttonTitle={ok}
              buttonMethod={closeSuccessModal}
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default ChangePassword;
