import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import { getCurrenLang } from '../../redux/selector';
import { NotificationModal } from '../../common';
import { path } from '../../utils';
import styles from '../styles.module.css';
import storage from '../../utils/storage';

interface NotFoundProps {
  title?: string;
  content?: string;
  withoutHomePage?: boolean;
}

const NotFound = ({ title, content, withoutHomePage }: NotFoundProps): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const not_found_page_title = t?.notifications.not_found_page_title;
  const not_found_page_sub_title = t?.notifications.not_found_page_sub_title;
  const home_page = t?.notifications.home_page;

  const user = storage.get('user');
  const isAdmin = user && user.role === 'admin';
  const redirectPath = isAdmin ? path.adminDashboardList : path.dashboardList;

  return (
    <div className={styles.main__container}>
      <div
        className={cx({
          [styles.main__wrapper]: true,
          [styles['not-found__wrapper']]: true,
        })}
      >
        <NotificationModal
          title={title || not_found_page_title}
          contant={content || not_found_page_sub_title}
          redirect={redirectPath}
          redirectTitle={!withoutHomePage ? home_page : ''}
        />
      </div>
    </div>
  );
};
export default NotFound;
