export const initUploadFile = [
  { file: null, name: '', type: '', uuid: '', thumbnail: '', description: '', isError: false },
];
export const initUrl = [{ url: '', description: '', isDone: false, isError: false }];
export const initErr = {
  error: false,
  format: false,
  heavy: false,
};
export const allowedPicTypes = [
  'application/pdf',
  'image/png',
  'image/jpeg',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];
export const allowedFileLabel = 'PDF, JPEG, JPG, PNG, Word Doc, .mov, .mp4, .avi, .wmv';
export const allowedFileTypes =
  '.pdf,.jpeg,.jpg, .png,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.mov,.mp4,.avi,.wmv, .webm';

// Maximum file size for patient information uploads
export const filesSizeLimit = 52428800;
