import React from 'react';
import {
  EmailNotificationIcon,
  LogoutIcon,
  OrgVideoBankIcon,
  OrgPatientInfoIcon,
  PatientInvitationIcon,
  RehabTeamsIcon,
  RewardsIcon,
  OverviewIcon,
  PatientProfileIcon,
  PatientExerciseLibraryIcon,
  ActivitiesIcon,
  PatientRehabTeamIcon,
  PatientInformationIcon,
  WoundImagesIcon,
  PatientRelatedMessagesIcon,
  HistoryIcon,
  MailIconanageOrganisationIcon,
  ManageProfessionIcon,
  ManagePromsIcon,
} from '../../theme/icons';
import { path } from '../../utils';
import {
  EMAIL_NOTIF_CATEGORY,
  SIDEBAR_LABELS,
  StatusKeyNameEnum,
  TEAM_CATEGORY,
} from '../../utils/enums';
import { initialPage } from '../../utils/helper';

export const hcpSideBar = (t: any) => [
  {
    label: SIDEBAR_LABELS.SECTION_LABEL,
    title: t?.menu.organisation_settings,
  },
  {
    label: 'organisation_video_bank',
    title: t?.title.org_video_bank,
    icon: <OrgVideoBankIcon />,
    path: path.organisationVideoBank,
    exact: false,
  },
  {
    label: 'rehab_teams',
    title: t?.menu.rehab_teams,
    icon: <RehabTeamsIcon />,
    path: `${path.rehabTeams}?category=${TEAM_CATEGORY.MY_TEAM}&page=${initialPage}`,
    exact: true,
  },
  {
    label: 'organisation_patient_information',
    title: t?.hcp.org_patient_info?.org_patient_info,
    icon: <OrgPatientInfoIcon />,
    path: path.organisationPatientInformation,
    exact: false,
  },
  {
    label: 'Rewards',
    title: t?.common.rewards,
    icon: <RewardsIcon />,
    path: path.rewards,
    exact: true,
  },

  { label: SIDEBAR_LABELS.SECTION_END, title: '' },
  { label: SIDEBAR_LABELS.SECTION_LABEL, title: t?.menu.your_settings },
  {
    label: 'patient_invitations',
    title: t?.menu.patient_invitations,
    icon: <PatientInvitationIcon />,
    path: path.patientInvitations,
    exact: true,
  },
  {
    label: 'email_notifications',
    title: t?.menu.email_notifications,
    icon: <EmailNotificationIcon />,
    path: `${path.emailNotifications}?category=${EMAIL_NOTIF_CATEGORY.NOTIF_TO_ME}`,
    exact: true,
  },
  { label: SIDEBAR_LABELS.SECTION_END, title: '' },
];

export const adminSideBar = (t: any) => [
  {
    label: 'organisation_patient_information',
    title: t?.hcp.org_patient_info?.org_patient_info,
    icon: <OrgPatientInfoIcon />,
    path: path.adminOrganisationPatientInformation,
    exact: true,
  },
  {
    label: 'Rewards',
    title: t?.common.rewards,
    icon: <RewardsIcon />,
    path: path.rewardsAdmin,
    exact: true,
  },
  {
    label: 'Departments',
    title: t?.menu.manage_departments,
    icon: <RehabTeamsIcon />,
    path: path.manage_departments,
    exact: true,
  },
  {
    label: 'Proms',
    title: t?.menu.manage_proms,
    icon: <ManagePromsIcon width="20px" height="20px" />,
    path: path.manage_proms,
    exact: true,
  },
  {
    label: 'Profession',
    title: t?.menu.manage_professions,
    icon: <ManageProfessionIcon width="20px" height="20px" />,
    path: path.manage_professions,
    exact: true,
  },
  {
    label: 'Organisation',
    title: t?.menu.manage_organisations,
    icon: <MailIconanageOrganisationIcon width="20px" height="20px" />,
    path: path.manage_organisations,
    exact: true,
  },
];

export const patientSideBar = (t: any, userStatus: any) => {
  const overview = t?.dashboard.hcp.profile_patient.tabs.overview;
  const patient_profile = t?.dashboard.hcp.profile_patient.patient_profile;
  const patient_exercise_library = t?.menu.patient_exercise_library;
  const remote_monitoring = t?.menu.remote_monitoring;
  const patient_rehab_team = t?.menu.patient_rehab_team;
  const patient_information = t?.dashboard.hcp.profile_patient.patient_information;
  const wound_images = t?.menu.wound_images;
  const patient_related_messages = t?.menu.patient_related_messages;
  const prescription_history = t?.menu.prescription_history;

  const patientExerciseLibrary = {
    label: patient_exercise_library,
    title: patient_exercise_library,
    icon: <PatientExerciseLibraryIcon />,
    path: path.patient_exercise_library,
    exact: true,
  };

  if (userStatus === StatusKeyNameEnum.invite_sent || userStatus === StatusKeyNameEnum.pending) {
    return [patientExerciseLibrary];
  }

  return [
    {
      label: SIDEBAR_LABELS.SECTION_LABEL,
      title: t?.menu.patient_area || 'Patient Area',
    },
    {
      label: overview,
      title: overview,
      icon: <OverviewIcon />,
      path: path.patient_overview,
      exact: true,
    },
    {
      label: patient_profile,
      title: patient_profile,
      icon: <PatientProfileIcon />,
      path: path.patient_profile,
      exact: true,
    },
    patientExerciseLibrary,
    {
      label: remote_monitoring,
      title: remote_monitoring,
      icon: <ActivitiesIcon />,
      path: path.patient_remote_monitoring,
      exact: true,
    },
    {
      label: patient_rehab_team,
      title: patient_rehab_team,
      icon: <PatientRehabTeamIcon />,
      path: path.patient_rehab_team,
      exact: true,
    },
    {
      label: patient_information,
      title: patient_information,
      icon: <PatientInformationIcon />,
      path: path.patient_information,
      exact: false,
    },
    {
      label: wound_images,
      title: wound_images,
      icon: <WoundImagesIcon />,
      path: path.patient_wound_images,
      exact: true,
    },
    {
      label: patient_related_messages,
      title: patient_related_messages,
      icon: <PatientRelatedMessagesIcon />,
      path: '/dashboard/patient/7', // TODO
      exact: true,
    },
    {
      label: prescription_history,
      title: prescription_history,
      icon: <HistoryIcon />,
      path: path.patient_prescription_history,
      exact: true,
    },
    { label: SIDEBAR_LABELS.SECTION_END, title: '' },
  ];
};

export const patientSideBarAdmin = (t: any, userStatus: any) => {
  const overview = t?.dashboard.hcp.profile_patient.tabs.overview;
  const patient_profile = 'Patient Profile'; // t?.dashboard.hcp.profile_patient.patient_profile;
  const remote_monitoring = t?.menu.remote_monitoring;
  const patient_information = t?.dashboard.hcp.profile_patient.patient_information;
  const wound_images = t?.menu.wound_images;

  if (userStatus === StatusKeyNameEnum.invite_sent || userStatus === StatusKeyNameEnum.pending) {
    return [];
  }

  return [
    {
      label: SIDEBAR_LABELS.SECTION_LABEL,
      title: t?.menu.patient_area || 'Patient Area',
    },
    {
      label: overview,
      title: overview,
      icon: <OverviewIcon />,
      path: path.patient_overview_admin,
      exact: true,
    },
    {
      label: patient_profile,
      title: patient_profile,
      icon: <PatientProfileIcon />,
      path: path.patient_profile_admin,
      exact: true,
    },
    {
      label: remote_monitoring,
      title: remote_monitoring,
      icon: <ActivitiesIcon />,
      path: path.patient_remote_monitoring_admin,
      exact: true,
    },
    {
      label: patient_information,
      title: patient_information,
      icon: <PatientInformationIcon />,
      path: path.patient_information_admin,
      exact: false,
    },
    {
      label: wound_images,
      title: wound_images,
      icon: <WoundImagesIcon />,
      path: path.patient_wound_images_admin,
      exact: true,
    },
    { label: SIDEBAR_LABELS.SECTION_END, title: '' },
  ];
};

export const logoutSideBar = (t: any) => ({
  label: SIDEBAR_LABELS.LOGOUT,
  title: t?.menu.logout,
  icon: <LogoutIcon />,
});
