import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { Tooltip } from 'antd';
import cx from 'classnames';
import { toast } from 'react-toastify';

import styles from '../styles.module.css';
import { getCurrenLang, getCurrentFormatDate, GetState } from '../../../../redux/selector';
import { initialValues } from '../../VideoExercise/initForm';
import { InitialValuesForm } from '../../VideoExercise/types';
import { validationExercise, validationFocusCheckboxes } from '../../../../utils/validators';
import { collectUuids, getExercisesCategories } from '../../../../utils/helper';
import { PhotoSliderModal, VideoModal } from '../../../../common/VideoModal';
import {
  useQueryPhotoPic,
  useQueryThumbnail,
  useQueryVideo,
  useSaveHcpExerciseInFolder,
} from '../../../../graphql/videoBank';
import useCheckPermissions from '../../../../hooks/useCheckPermissions';
import { EXERCISE_STATUS_COLOR, EXERCISE_TYPE, PERMISSIONS } from '../../../../utils/enums';
import { Loading } from '../../../../common';
import { ShowMediaIcon, ThumbnailJsx } from '../../../../common/ShowMediaIcon';
import { ExerciseFileType } from '../../../../utils/model';
import SettingsForm from '../../OrganisationVideoBank/components/SettingsForm';

const NestedExercise = ({
  data,
  exercisesCategories,
  exercisesProcedureFocus,
  focusType1Arr,
  focusType2Arr,
  isInvitee,
  folderId,
  exerciseStatuses,
  setExerciseStatuses,
  index,
  updateList,
}: any): JSX.Element => {
  const t: any = useSelector<any>((state: GetState) => getCurrenLang(state));
  const uploading = t?.common.uploading;
  const settings_t = t?.dashboard.hcp.profile_patient.video_bank.settings;
  const createExercise = t?.notifications.created_exercise;
  const updateExercise = t?.notifications.updated_exercise;
  const you_dont_have_permission = t?.common.you_dont_have_permission;
  const loadingErrorMsg = t?.notifications.loading_error;
  const set = t?.common.set || 'Set';
  const not_set = t?.common.not_set || 'Not set';

  // Endpoints
  // Get image from AWS by Uuid
  const { _getThumbnailByName, thumbnail } = useQueryThumbnail();

  // get photo picture from AWS by Uuid
  const { _getPhotoPic, photoPic } = useQueryPhotoPic();

  // get video from AWS by uuid
  const { _getVideoByName, videoSrc, errorVideo } = useQueryVideo();

  // save exercise by HCP from folder to Team video bank
  const {
    _saveHcpExerciseInFolderTeam,
    savedExerciseForTeamInFolder,
    loadingSaveExerciseInFolder,
    errorSaveExerciseInFolder,
  } = useSaveHcpExerciseInFolder();

  // Local state
  const [isUploadingToAWS, setIsUploadingToAWS] = useState(false);
  const [isSettingsOpen, setOpenSettings] = useState(false);
  const [isVideoModalOpen, setVideoModal] = useState(false);
  const [isPhotoModalOpen, setPhotoModal] = useState(false);
  const [videoSrcState, setVideoSrcState] = useState<any>('');

  const formatsDate = useSelector((state: GetState) => getCurrentFormatDate(state));
  const exerciseFileType: ExerciseFileType = data.type;
  const { updatedByHcp } = data;
  const isPhotosExist = !!data.exercisePhotos?.length;
  const exercisePhotos = isPhotosExist ? data.exercisePhotos : [];
  const isPermissionEditVideoExerc = useCheckPermissions(
    PERMISSIONS.EDIT_OR_PRESCRIBE_EXERCISE_VIDEOS_OTM,
  );
  // currentId can be null
  const unicId = `${data.currentId}_${index}`;
  const initialValuesForm: InitialValuesForm = initialValues(
    data,
    exercisesCategories,
    exercisesProcedureFocus,
    t,
    focusType1Arr,
    focusType2Arr,
  );

  const formik = useFormik({
    initialValues: initialValuesForm,
    validate: (values) => validationFocusCheckboxes(values, t),
    validationSchema: () => validationExercise(t),
    onSubmit: async (values: InitialValuesForm) => {
      const request: any = {
        isVideoOwnerHcp: false,
        exerciseCategoriesId: getExercisesCategories(values, exercisesCategories, 'focus'),
        exerciseProcedureTypesId: getExercisesCategories(
          values,
          exercisesProcedureFocus,
          'procedure',
        ),
        folderId,
        photos: isPhotosExist ? collectUuids(exercisePhotos) : null,
        exerciseData: {
          id: values.exerciseId,
          name: values.name,
          thumbnailName: values.thumbnailName,
          videoName: values.videoName,
          videobankId: values.videobankId,
          sets: Number(values.sets) || null,
          reps: values.dim === 'time' ? null : Number(values.reps) || null,
          time:
            values.dim === 'time'
              ? Number(values.seconds.value) + Number(values.minutes.value) * 60
              : null,
          timesPerDay: Number(values.timesDay),
          restDays: Number(values.restDaysWeek) || null,
          comment: values.comments,
          videoUploaded: values.exerciseVideoUploaded,
          thumbnailUploaded: values.exerciseThumbnailUploaded,
        },
      };
      _saveHcpExerciseInFolderTeam(request);
    },
  });

  const { errors, values, dirty } = formik;
  const isNotValidMediaForExercise = (!thumbnail || !data.videoName) && !isPhotosExist;
  const errorsObj = !!Object.keys(errors).length;
  const isValidExercise = data.calcStatus === set;
  // const exerciseStatus = exerciseStatuses[unicId];

  const updateExerciseStatus = (): void => {
    // const newExercStatuses = { ...exerciseStatuses };
    // newExercStatuses[unicId] = errorsObj ? not_set : set;
    // setExerciseStatuses({ ...newExercStatuses });
    updateList(unicId);
  };

  // Update current exercise status
  // useEffect(() => {
  //   if (!dirty) {
  //     updateExerciseStatus();
  //   }
  // }, [errorsObj]);

  /* After the exercise video is saved to Organisation video bank IN FOLDER,
    corresponding message is displayed and close settigs options */
  useEffect(() => {
    if (!String(savedExerciseForTeamInFolder).includes('true') && savedExerciseForTeamInFolder) {
      toast.info(`${values.name} ${createExercise}`);
      updateExerciseStatus();
    }
    if (String(savedExerciseForTeamInFolder).includes('true') && savedExerciseForTeamInFolder) {
      toast.info(`${values.name} ${updateExercise}`);
      updateExerciseStatus();
    }
    if (!errorSaveExerciseInFolder && savedExerciseForTeamInFolder) {
      setOpenSettings(() => false);
    }
  }, [savedExerciseForTeamInFolder]);

  // Loading Thumbnail and validate form
  useEffect(() => {
    formik.validateForm();
    formik.setTouched({ ...formik.touched, focus1: true });
    if (data.thumbnailName) {
      _getThumbnailByName({
        variables: {
          thumbnailKey: data.thumbnailName,
        },
      });
      return;
    }
    if (isPhotosExist) {
      _getPhotoPic({
        variables: {
          pictureUuid: exercisePhotos[0].fileUuid,
        },
      });
      return;
    }
    if (!data.thumbnailName && !data.videoName && !data.exerciseVideoUploaded && !isPhotosExist) {
      setIsUploadingToAWS(() => true);
    } else {
      setIsUploadingToAWS(() => false);
    }
  }, [data]);

  // Open video modal window
  useEffect(() => {
    if (videoSrc) {
      setVideoSrcState(videoSrc);
      setVideoModal(() => true);
    }
  }, [videoSrc]);

  useEffect(() => {
    if (errorVideo) {
      toast.error(errorVideo);
    }
  }, [errorVideo]);

  // Video modal window handler
  const showMedia = (): void => {
    switch (exerciseFileType) {
      case EXERCISE_TYPE.VIDEO:
        if (data.videoName) {
          _getVideoByName({
            variables: {
              videoKey: data.videoName,
            },
          });
          // setModal(() => !isVideoModalOpen);
        }
        return;
      case EXERCISE_TYPE.PHOTO:
        if (photoPic) {
          setPhotoModal(() => !isPhotoModalOpen);
        }
        return;
      default:
        setPhotoModal(() => false);
    }
  };

  const onCancel = (): void => {
    formik.resetForm();
    setOpenSettings(() => false);
  };

  // Open settings or recover exercise
  const actionBtnHandler = () => {
    if (!isPermissionEditVideoExerc) {
      return null;
    }
    return setOpenSettings(() => !isSettingsOpen);
  };

  // Close opened modal windows
  const onCloseModal = (): void => {
    setVideoModal(() => false);
    setPhotoModal(() => false);
  };

  // JSX
  const loadingJSX = loadingSaveExerciseInFolder && <Loading />;
  const actionBtnJsx = (
    <Tooltip title={!isPermissionEditVideoExerc ? you_dont_have_permission : ''}>
      <div
        className={cx({
          [styles['video-exercise__btn--settings']]: true,
          [styles['video-exercise__btn--settings-open']]: isSettingsOpen,
          [styles['video-exercise__btn--disabled']]: !isPermissionEditVideoExerc,
        })}
        aria-hidden
        onClick={actionBtnHandler}
      >
        {settings_t}
      </div>
    </Tooltip>
  );

  return (
    <Tooltip title={isNotValidMediaForExercise ? loadingErrorMsg : ''}>
      <div>
        <div
          className={cx({
            [styles['video-exercise__row']]: true,
            [styles['video-exercise__row-disable']]: isNotValidMediaForExercise,
          })}
        >
          {loadingJSX}

          {/* Thumbnail */}
          <div className={styles['video-exercise__block']}>
            {!isUploadingToAWS && (
              <div
                className={cx({
                  [styles.preview]: true,
                  [styles['preview-not-allowed']]:
                    (!thumbnail || !data.videoName) && !isPhotosExist,
                })}
                role="presentation"
                onClick={showMedia}
              >
                <ThumbnailJsx thumbLink={thumbnail} photoPic={photoPic} />
                <ShowMediaIcon thumbLink={thumbnail} photoPic={photoPic} />
              </div>
            )}
            {isUploadingToAWS && <div className={styles.isUploading}>{uploading}...</div>}
          </div>

          {/* Status */}
          <div
            className={styles['video-exercise__status']}
            style={{
              color: isValidExercise ? EXERCISE_STATUS_COLOR.set : EXERCISE_STATUS_COLOR.notSet,
            }}
          >
            {data.calcStatus}
          </div>

          {/* Sm screen */}
          <div className={styles['video-exercise__description--sm']}>
            <div
              className={styles['video-exercise__status--sm']}
              style={{
                color: isValidExercise ? EXERCISE_STATUS_COLOR.set : EXERCISE_STATUS_COLOR.notSet,
              }}
            >
              {data.calcStatus}
            </div>
            <div className={styles['video-exercise__name--sm']}>{values.name}</div>
            <div className={styles['video-exercise__action--sm']}>{actionBtnJsx}</div>
          </div>

          {/* Name */}
          <div className={styles['video-exercise__name']}>{values.name}</div>

          {/* Setting last changed */}
          <div className={styles['video-exercise__date-container']}>{updatedByHcp || '-'}</div>

          {/* Action */}
          <div className={styles['video-exercise__action']}>{actionBtnJsx}</div>
        </div>

        {/* Settings */}
        {isSettingsOpen && (
          <div className={styles['form-wrapper']}>
            <SettingsForm
              formik={formik}
              index={index}
              isPhotosExist={isPhotosExist}
              exercisePhotos={exercisePhotos}
              closeSettings={onCancel}
              setWarningModal={(): null => null}
              focusType2Arr={focusType2Arr}
              exercisesCategories={exercisesCategories}
              focusType1Arr={focusType1Arr}
              exercisesProcedureFocus={exercisesProcedureFocus}
              loading={!!loadingJSX}
              errorSaveExercise=""
              errorSaveExerciseInFolder={errorSaveExerciseInFolder}
              deleteFromTeamError={false}
              isModalFolder
            />
          </div>
        )}

        {/* Popups */}
        {isVideoModalOpen && <VideoModal videoSrc={videoSrcState} onclose={onCloseModal} />}
        {isPhotoModalOpen && (
          <PhotoSliderModal
            exercisePhotos={isPhotosExist ? exercisePhotos : []}
            photoPreviews={data.photoPreviews ? data.photoPreviews : []}
            onclose={onCloseModal}
          />
        )}
      </div>
    </Tooltip>
  );
};

export default NestedExercise;
