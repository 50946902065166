import {
  defaultValue,
  getChecked,
  getMinutes,
  getSeconds,
  getStatusOptions,
} from '../../../utils/helper';

export const initialValues = (
  data: any,
  exercisesCategories: any,
  exercisesProcedureFocus: any,
  t: any,
  focusType1Arr: any,
  focusType2Arr: any,
): any => {
  const procedures: any = {};
  const categores: any = {};
  focusType1Arr.map((procedure: any, i: number) => {
    procedures[`procedure${i + 1}`] = Boolean(
      data.exerciseProcedureTypes?.length &&
        getChecked(exercisesProcedureFocus[`procedure${i + 1}`].name, data.exerciseProcedureTypes),
    );
    return null;
  });
  focusType2Arr.map((focus: any, i: number) => {
    categores[`focus${i + 1}`] = Boolean(
      data.exerciseCategories?.length &&
        getChecked(exercisesCategories[`focus${i + 1}`].name, data.exerciseCategories),
    );
    return null;
  });

  return {
    exerciseId: data.exerciseId ? data.exerciseId : null,
    exerciseName: data.exerciseName ? data.exerciseName : data.name,
    videoName: data.videoName,
    dim: data.exerciseTime ? 'time' : 'reps',
    reps: data.exerciseReps || '',
    sets: data.exerciseSets || '',
    timesDay: data.exerciseTimesPerDay || '',
    restDaysWeek: data.exerciseRestDays || '',
    minutes: data.exerciseTime
      ? defaultValue(String(getMinutes(data.exerciseTime)))
      : defaultValue('0'),
    seconds: data.exerciseTime
      ? defaultValue(String(getSeconds(data.exerciseTime)))
      : defaultValue('0'),
    comments: data.exerciseComment || '',
    status:
      getStatusOptions(data.status, 'videobank', t) || getStatusOptions('Inactive', 'videobank', t),
    name: data.name,
    thumbnailName: data.thumbnailName,
    videobankId: data.currentId ? data.currentId : null,
    exerciseVideoUploaded: data.exerciseId ? data.exerciseVideoUploaded : true,
    exerciseThumbnailUploaded: data.exerciseId ? data.exerciseThumbnailUploaded : true,
    // isDraft: true,
    ...categores,
    ...procedures,
  };
};

export const notAllowedStatuses = [
  'Inactive',
  'Not uploaded',
  'Deleted by patient',
  'Deleted by hcp',
];
export const numberOfThumbnails = 3;
