import React, { Suspense } from 'react';
import { Switch, Route, Redirect, useLocation, useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { publicRouters, privateRoutes } from './routes';
import { Loading } from '../../common/Loader';
import { path } from './book';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import { Dashboard } from '../../components';
import useCheckRole from '../../hooks/useCheckRole';
import { LIST_TYPE } from '../enums';
import { initialPage } from '../helper';

const AppRouter = (): JSX.Element => {
  const { isAdmin, token } = useCheckRole();
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const isPathAdmin = pathname && !!pathname.match(/admin/);
  const successPath = isAdmin
    ? `${path.adminDashboardList}?category=${LIST_TYPE.HCP_ADMIN}&page=${initialPage}`
    : `${path.dashboardList}?category=${LIST_TYPE.PATIENT}&page=${initialPage}`;
  const dashboardPath = isAdmin ? path.adminDashboard : path.dashboard;

  if (token && location.pathname.includes('/signin')) {
    history.push(successPath);
  }

  return (
    <>
      <Suspense fallback={<Loading />}>
        <Switch>
          <PrivateRoute
            component={Dashboard}
            path={dashboardPath}
            routes={privateRoutes}
            successPath={successPath}
          />
          {publicRouters.map((route, ind) => (
            <PublicRoute
              key={`publicRoute${String(ind)}`}
              component={(): JSX.Element => (
                <route.component {...((route.componentProps as any) || {})} />
              )}
              path={route.path}
              exact={route.exact}
            />
          ))}
          <Route
            render={(): JSX.Element => (
              <Redirect to={isPathAdmin ? path.adminSignIn : path.signIn} />
            )}
          />
        </Switch>
      </Suspense>
      <ToastContainer />
    </>
  );
};
export default AppRouter;
