import React, { ReactElement } from 'react';
import { sanitize } from 'dompurify';
import moment from 'moment';

import { getPatientAnswers } from '../../../../../utils/helper_charts';
import { CalculationDescription } from './common/Calculation';
import { RemoveQuestionnaire } from './common/RemoveQuestionnaire';
import style from './style.module.css';
import { Answer, Question, QuestionnaireContentProps } from './types';
import { PROMS_NAME } from '../../../../../utils/enums';

export const KoosReport = ({
  questionnairesList,
  completedQuestionnaire,
  index,
  onDeleteItem,
  formatDate,
  t,
}: QuestionnaireContentProps): ReactElement => {
  const remove_questionnaire = t?.dashboard.hcp.profile_patient.questionnaires.remove_questionnaire;
  const koos_calc_descr_header =
    t?.dashboard.hcp.profile_patient.questionnaires.koos_calc_descr_header; // || "Knee Injury and Osteoarthritis Outcome Score for Joint Replacement (KOOS, JR.) Scoring Instructions, English version 1.0";
  const koos_scoring_instruction =
    t?.dashboard.hcp.profile_patient.questionnaires.koos_scoring_instruction; // || "KOOS, JR SCORING INSTRUCTIONS";
  const koos_calc_descr_1 = t?.dashboard.hcp.profile_patient.questionnaires.koos_calc_descr_1; // || "The KOOS, JR was developed from the original long version of the Knee injury and Osteoarthritis Outcome Score (KOOS) survey using Rasch analysis. The KOOS, JR contains 7 items from the original KOOS survey. Items are coded from 0 to 4, none to extreme respectively.";
  const koos_calc_descr_2 = t?.dashboard.hcp.profile_patient.questionnaires.koos_calc_descr_2; // || "KOOS, JR is scored by summing the raw response (range 0-28) and then converting it to an interval score using the table provided below. The interval score ranges from 0 to 100 where 0 represents total knee disability and 100 represents perfect knee health."
  const koos_calc_descr_3 = t?.dashboard.hcp.profile_patient.questionnaires.koos_calc_descr_3; // || "Table for converting raw summed scores to interval level scores from 0 (total knee disability) to 100 (perfect knee health)"
  const koos_calc_table_title1 =
    t?.dashboard.hcp.profile_patient.questionnaires.koos_calc_table_title1; // || "Raw summed score<br />(0-28)"
  const hoos_calc_table_title2 =
    t?.dashboard.hcp.profile_patient.questionnaires.hoos_calc_table_title2; // || "Interval score<br />(0 to 100 scale)"
  const hoos_calc_table_footer =
    t?.dashboard.hcp.profile_patient.questionnaires.hoos_calc_table_footer; // || "©2022 Hospital for Special Surgery"
  const koos_header_title = t?.dashboard.hcp.profile_patient.questionnaires.koos_header_title; // || "Knee injury and Osteoarthritis Outcome Score for Joint Replacement (KOOS, JR.)"
  const hoos_header_version = t?.dashboard.hcp.profile_patient.questionnaires.hoos_header_version; // || "English version 1.0"
  const hoos_instructions = t?.dashboard.hcp.profile_patient.questionnaires.instructions;
  const koos_instructions_descr1 =
    t?.dashboard.hcp.profile_patient.questionnaires.koos_instructions_descr1; // || "This survey asks for your view about your knee. This information will help us keep track of how you feel about your knee and how well you are able to do your usual activities. Answer every question by ticking the appropriate box, only one box for each question. If you are unsure about how to answer a question, please give the best answer you can."
  const koos_stiffness = t?.dashboard.hcp.profile_patient.questionnaires.koos_stiffness; // || "Stiffness";
  const koos__stiffness_descr =
    t?.dashboard.hcp.profile_patient.questionnaires.koos__stiffness_descr; // || "The following question concerns the amount of joint stiffness you have experienced during the last week in your knee. Stiffness is a sensation of restriction or slowness in the ease of which you move your knee joint."
  const koos_pain = t?.dashboard.hcp.profile_patient.questionnaires.pain;
  const koos_subTitle_descr = t?.dashboard.hcp.profile_patient.questionnaires.koos_subTitle_descr; // || "What amount of knee pain have you experienced in the last week during the following activities?";
  const hoos_subTitle2 = t?.dashboard.hcp.profile_patient.questionnaires.hoos_subTitle2; // || "Function, daily living"
  const koos_subTitle2_descr = t?.dashboard.hcp.profile_patient.questionnaires.koos_subTitle2_descr; // || "The following questions concern your physical function. By this we mean your ability to move around and to look after yourself. For each of the following activities please indicate the degree of difficulty you have experienced in the last week due to your knee."
  const hoos_footer = t?.dashboard.hcp.profile_patient.questionnaires.hoos_footer; // || "© 2020 Hospital for Special Surgery. 535 East 70th Street, New York, NY 10021. Hospital for Special Surgery, HSS and the HSS logo are trademarks or registered trademarks of Hospital for Special Surgery in the United States and other countries."
  const koos_continue_on_next_page =
    t?.dashboard.hcp.profile_patient.questionnaires.koos_continue_on_next_page; // || "(Continue on next page for <i>Function, daily living</i>)"

  const calcTableConfig = [
    '100.000',
    '91.975',
    '84.600',
    '79.914',
    '76.332',
    '73.342',
    '70.704',
    '68.284',
    '65.994',
    '63.776',
    '61.583',
    '59.381',
    '57.140',
    '54.840',
    '52.465',
    '50.012',
    '47.487',
    '44.905',
    '42.281',
    '39.625',
    '36.931',
    '34.174',
    '31.307',
    '28.251',
    '24.875',
    '20.941',
    '15.939',
    '8.291',
    '0.000',
  ];

  const { questions: completedQuestions, completedDate } = completedQuestionnaire;
  const patientAnswers: any = getPatientAnswers(completedQuestions);
  const koosList = questionnairesList.filter((item: any) => item.name === PROMS_NAME.KOOS)[0];
  const { questions } = koosList;

  // JSX
  const calc_description = (
    <>
      <div className={style['hoos__calc-header']}>{koos_calc_descr_header}</div>
      <p>{koos_calc_descr_1}</p>
      <p>{koos_calc_descr_2}</p>
      <p className={style['hoos__calc-descr3']}>{koos_calc_descr_3}</p>
      <div className={style['hoos__calc-table']}>
        {/* Header */}
        <div className={style['hoos__calc-tr']}>
          <div
            className={style['hoos__calc-th']}
            dangerouslySetInnerHTML={{ __html: sanitize(koos_calc_table_title1) }}
          />
          <div
            className={style['hoos__calc-th']}
            dangerouslySetInnerHTML={{ __html: sanitize(hoos_calc_table_title2) }}
          />
        </div>

        {/* Body */}
        {calcTableConfig.map((score: string, i: number) => (
          <div key={score} className={style['hoos__calc-tr']}>
            <div className={style['hoos__calc-td']}>{i}</div>
            <div className={style['hoos__calc-td']}>{score}</div>
          </div>
        ))}
        {/* Footer */}
        <div className={style['hoos__calc-footer']}>{hoos_calc_table_footer}</div>
      </div>
    </>
  );

  const firstQuestionsSection: any = [];
  const secondQuestionsSection: any = [];
  const therdQuestionsSection: any = [];
  const jsx = questions?.map((question: Question, ind: number) => {
    const questionJsx = (
      <div className={style['hoos__question-row']} key={question.name}>
        <div className={style['hoos__question--number']}>{ind + 1}.</div>
        <div className={style['hoos__question-answ-container']}>
          <div className={style['hoos__question-sub-row']}>{question.name}</div>
          <div className={style['hoos__question-sub-row']}>
            {question.answers?.map((answ: Answer) => (
              <div className={style['hoos__answer-item']} key={`${answ.name}${ind}`}>
                <div>{answ.name}</div>
                <div className={style['hoos__answer-checkbox']}>
                  {patientAnswers[question.id] === answ.id && <>&#10004;</>}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
    if (ind === 0) {
      firstQuestionsSection.push(questionJsx);
    } else if (ind < 5) {
      secondQuestionsSection.push(questionJsx);
    } else {
      therdQuestionsSection.push(questionJsx);
    }
    return null;
  });

  return (
    <>
      <RemoveQuestionnaire
        onMethod={(): void => onDeleteItem(index)}
        title={remove_questionnaire}
      />
      <div className="questionnaire-to-PDF">
        <div className={style.hoos__container}>
          <CalculationDescription
            name={koos_scoring_instruction}
            content={calc_description}
            date={completedDate ? moment(completedDate).format(formatDate) : ''}
            t={t}
          />
        </div>
      </div>
      {/* PAGE 1 */}
      <div className="questionnaire-to-PDF">
        <div className={style.hoos__container}>
          {/* Header */}
          <div className={style.hoos__header}>
            <div className={style['hoos__header--logo']}>HSS</div>
            <div className={style['hoos__header--title']}>
              {koos_header_title}
              <div className={style['hoos__header--vers']}>{hoos_header_version}</div>
            </div>
          </div>

          {/* Body */}
          <div className={style.hoos__title}>{hoos_instructions}</div>
          <p>{koos_instructions_descr1}</p>

          <div className={style['hoos__sub-title']}>{koos_stiffness}</div>
          <p>{koos__stiffness_descr}</p>
          {firstQuestionsSection}

          <div className={style['hoos__sub-title']}>{koos_pain}</div>
          <p>{koos_subTitle_descr}</p>
          {secondQuestionsSection}

          <div
            className={style.koos__continue}
            dangerouslySetInnerHTML={{ __html: sanitize(koos_continue_on_next_page) }}
          />

          {/* Footer */}
          <div className={style.hoos__footer}>{hoos_footer}</div>
        </div>
      </div>

      {/* PAGE 2 */}
      <div className="questionnaire-to-PDF">
        <div className={style.hoos__container}>
          <div className={style['hoos__sub-title']}>{hoos_subTitle2}</div>
          <p>{koos_subTitle2_descr}</p>
          {therdQuestionsSection}
        </div>
      </div>
    </>
  );
};
