import React, { ReactElement } from 'react';
import moment from 'moment';
import { Tooltip } from 'antd';

import style from './styles.module.css';
import { dayInMilliseconds } from '../../utils/variables';
import { arrayOfDays, arrayOfWeeks, arrayOfYears } from '../../utils/helper_charts';

export interface THint {
  completed: number;
  partlyDone: number;
  attempts: number;
  noAttempt: number;
}
export interface THintValue {
  value: THint;
}
export interface TOverviewHint extends THint {
  name: string;
  steps: number;
  date: string;
}
export interface TOverviewHintValue {
  value: TOverviewHint;
  completed_t: string;
  partly_done_t: string;
  attempts_t: string;
  no_attempt_t: string;
  step_count_t: string;
  day_t: string;
  week_t: string;
  month_t: string;
  today_t: string;
  period: string;
}
export interface THintValueData {
  tooltipStatus: THint;
  name: string;
  steps: number;
  date: string;
}

export const HintComponent = ({ value }: THintValue): ReactElement => {
  const { completed, partlyDone, attempts, noAttempt } = value;

  return (
    <div className={style.toolTypContainer}>
      <div className={style.statusBlock}>
        <span className={style.valueBlock}>{`${completed} %`}</span>
        <span>-</span>
        <div className={style.statusLegend} style={{ background: '#00B248' }} />
      </div>
      <div className={style.statusBlock}>
        <span className={style.valueBlock}>{`${partlyDone} %`}</span>
        <span>-</span>
        <div className={style.statusLegend} style={{ background: '#66FFA6' }} />
      </div>
      <div className={style.statusBlock}>
        <span className={style.valueBlock}>{`${attempts} %`}</span>
        <span>-</span>
        <div className={style.statusLegend} style={{ background: '#EEFF41' }} />
      </div>
      <div className={style.statusBlock}>
        <span className={style.valueBlock}>{`${noAttempt} %`}</span>
        <span>-</span>
        <div className={style.statusLegend} style={{ background: '#EEF1F8' }} />
      </div>
    </div>
  );
};

export const OverviewHintComponent = ({
  value,
  completed_t,
  partly_done_t,
  attempts_t,
  no_attempt_t,
  step_count_t,
  day_t,
  week_t,
  month_t,
  today_t,
  period,
}: TOverviewHintValue): ReactElement => {
  const { completed, partlyDone, attempts, noAttempt, name, steps, date } = value;
  let exerciseName = '';
  let v = 0;
  let periodName = '';
  switch (true) {
    case arrayOfDays.includes(period): {
      const now: Date = new Date(moment().format('YYYY-MM-DD'));
      v = (+now - +new Date(date)) / dayInMilliseconds;
      periodName = day_t;
      exerciseName = name;
      break;
    }
    case arrayOfWeeks.includes(period): {
      v = +period - +date + 1;
      periodName = week_t;
      if (period === '6') {
        exerciseName = name;
      }
      break;
    }
    case arrayOfYears.includes(period): {
      v = +period - +date + 1;
      periodName = month_t;
      exerciseName = name;
      break;
    }
    default:
  }
  const roundedSteps =
    steps > 1000 ? `${Number.parseFloat(String(steps / 1000)).toFixed(1)}k` : steps;

  return (
    <div className={style.overviewHint__container}>
      <div className={style.overviewHint__valueBlock} style={{ fontWeight: 700 }}>
        {`${v === 0 ? today_t : `${periodName} ${v}`}: ${exerciseName}`}
      </div>
      <div className={style.overviewHint__valueBlock} style={{ color: '#B2B9C8' }}>
        {`${no_attempt_t}: ${noAttempt}%`}
      </div>
      <div className={style.overviewHint__valueBlock} style={{ color: '#FFED00' }}>
        {`${attempts_t}: ${attempts}%`}
      </div>
      <div className={style.overviewHint__valueBlock} style={{ color: '#67FFA5' }}>
        {`${partly_done_t}: ${partlyDone}%`}
      </div>
      <div className={style.overviewHint__valueBlock} style={{ color: '#00BD5C' }}>
        {`${completed_t}: ${completed}%`}
      </div>
      <div className={style.overviewHint__valueBlock} style={{ color: '#F15B07' }}>
        {`${step_count_t}: ${roundedSteps}`}
      </div>
    </div>
  );
};

export const Info = ({ tip }: { tip: string | JSX.Element }): ReactElement => (
  <Tooltip title={tip}>
    <div className={style.tooltip__info}>i</div>
  </Tooltip>
);
