import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { getCurrenLang } from '../../redux/selector';
import { InputToolkit, InputPasswordFloatLabel, InputFloatLabel } from '../../common/Input';
import { Button } from '../../common/Button/Button';
import styles from './styles.module.css';
import { ShowErrorNotification } from './ErrorMessage';
import { ChangePasswordFormProps, ReserEmailFormProps, ReserPasswordFormProps } from './types';

export const ResetEmailForm = ({
  errorMessage,
  hasErrors,
  inputValue,
  isTouched,
  onChangeMethod,
}: ReserEmailFormProps): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const email_t = t?.auth.email;
  const continue_t = t?.common.continue;

  return (
    <div className={styles.form_wrapper}>
      <InputFloatLabel
        inputId="resetPassByEmail"
        placeholder={email_t}
        inputName="email"
        inputType="email"
        hasErrors={hasErrors.email}
        inputValue={inputValue.email}
        isTouched={isTouched.email}
        onChangeMethod={onChangeMethod}
      />

      {errorMessage && (
        <div className={styles.errorMessages}>
          <ShowErrorNotification errorMessage={errorMessage} />
        </div>
      )}

      <div className={styles.content}>
        <Button buttonType="submit" buttonName={continue_t} buttonClass={styles.btn__submit} />
      </div>
    </div>
  );
};

export const ResetPasswordForm = ({
  errorMessage,
  hasErrors,
  inputValue,
  isTouched,
  onChangeMethod,
}: ReserPasswordFormProps): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const repeat_pass = t?.auth.repeat_password;
  const change_pass_t = t?.common.change_password;
  const enter_new_pass = t?.auth.enter_new_password;

  return (
    <div className={styles.form_wrapper}>
      <InputToolkit
        inputId="newPassword"
        inputName="password"
        inputValue={inputValue.password}
        hasErrors={hasErrors.password}
        isTouched={isTouched.password}
        onChecngeMethod={onChangeMethod}
        placeholder={enter_new_pass}
      />
      <InputPasswordFloatLabel
        inputId="repeatResetPassword"
        inputName="repeatPassword"
        placeholder={repeat_pass}
        hasErrors={hasErrors.repeatPassword}
        inputValue={inputValue.repeatPassword}
        isTouched={isTouched.repeatPassword}
        onChangeMethod={onChangeMethod}
        disabled={false}
      />
      {errorMessage && (
        <div className={styles.errorMessages}>
          <ShowErrorNotification errorMessage={errorMessage} />
        </div>
      )}
      <div className={styles.content}>
        <Button buttonType="submit" buttonName={change_pass_t} buttonClass={styles.btn__submit} />
      </div>
    </div>
  );
};

// Change HCP (user) password
export const ChangePasswordForm = ({
  errorMessage,
  hasErrors,
  inputValue,
  isTouched,
  onChangeMethod,
  onCancel,
  isDirty,
}: ChangePasswordFormProps): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const cancel = t?.common.cancel;
  const confirm = t?.common.confirm;
  const current_pass = t?.auth.current_password;
  const new_password = t?.auth.new_password;
  const repeat_new_password = t?.auth.repeat_new_password;

  return (
    <div className={styles.form_wrapper}>
      <InputPasswordFloatLabel
        inputId="currentPassword"
        placeholder={current_pass}
        inputName="currentPassword"
        inputValue={inputValue.currentPassword}
        hasErrors={hasErrors.currentPassword}
        isTouched={isTouched.currentPassword}
        onChangeMethod={onChangeMethod}
      />
      <InputToolkit
        inputId="newPassword"
        placeholder={new_password}
        inputName="newPassword"
        inputValue={inputValue.newPassword}
        hasErrors={hasErrors.newPassword}
        isTouched={isTouched.newPassword}
        onChecngeMethod={onChangeMethod}
      />
      <InputPasswordFloatLabel
        inputId="repeatNewPassword"
        placeholder={repeat_new_password}
        inputName="repeatNewPassword"
        hasErrors={hasErrors.repeatNewPassword}
        inputValue={inputValue.repeatNewPassword}
        isTouched={isTouched.repeatNewPassword}
        onChangeMethod={onChangeMethod}
      />
      {errorMessage && (
        <div className={styles.errorMessages}>
          <ShowErrorNotification errorMessage={errorMessage} />
        </div>
      )}
      <div className={styles.btns_container}>
        <Button
          buttonType="button"
          buttonName={cancel}
          buttonClass={styles.btn}
          buttonMethod={onCancel}
        />
        <Button
          buttonType="submit"
          buttonName={confirm}
          buttonClass={styles.btn}
          disabledButton={!isDirty}
        />
      </div>
    </div>
  );
};
