import React from 'react';

import style from './styles.module.css';
import { SortSearchItem } from '../../OrganisationVideoBank/components/HeaderItems';

const ExerciseLibreryTableHeader = ({ t, sortBy, sortByColumnName }: any): JSX.Element => {
  const media_type = t?.hcp.org_video_bank.media_type;
  const exercise_name = t?.dashboard.hcp.profile_patient.video_bank.exercise_name;

  return (
    <div className={style['exercise-library__header']}>
      <div className={style['exercise-library__media-container']}>
        <SortSearchItem
          fieldName={media_type}
          t={t}
          sortName="type"
          sortByColumnName={sortByColumnName}
          sortBy={sortBy}
        />
      </div>
      <div className={style['exercise-library__exercise-name-container']}>
        <SortSearchItem
          fieldName={exercise_name}
          t={t}
          sortName="name"
          sortByColumnName={sortByColumnName}
          sortBy={sortBy}
        />
      </div>
    </div>
  );
};

export default ExerciseLibreryTableHeader;
