export const path = Object.freeze({
  // Public
  signIn: '/signin',
  adminSignIn: '/admin/signin',

  resetEmail: '/reset',
  resetPassword: '/reset/password/:token',

  resetAdminEmail: '/admin/reset',
  resetAdminPassword: '/admin/reset/password/:token',

  signUp: '/invitation/:token',

  // Private
  home: '/',
  admin: '/admin',
  dashboard: '/dashboard',
  adminDashboard: '/admin/dashboard',
  organisationVideoBank: '/dashboard/organisation-video-bank',
  rehabTeams: '/dashboard/rehab-teams',
  createTeam: '/dashboard/create-team',
  organisationPatientInformation: '/dashboard/organisation-patient-information',
  adminOrganisationPatientInformation: '/admin/dashboard/organisation-patient-information',
  upload_patient_information: '/dashboard/organisation-patient-information/upload',
  upload_patient_information_admin: '/admin/dashboard/organisation-patient-information/upload',
  rewards: '/dashboard/rewards',
  rewardsAdmin: '/admin/dashboard/rewards',
  patientInvitations: '/dashboard/patient-invitations',
  emailNotifications: '/dashboard/email-notifications',
  profile: '/dashboard/my-profile',
  orgVideoBankFolder: '/dashboard/organisation-video-bank/folder/:folder',
  addMedia: '/dashboard/organisation-video-bank/add-media',
  addVideo: '/dashboard/organisation-video-bank/add-video',
  addPhoto: '/dashboard/organisation-video-bank/add-photo',
  all_notification: '/dashboard/all-notification',

  dashboardList: '/dashboard/list',
  adminDashboardList: '/admin/dashboard/list',

  connectPatientAsHcp: '/dashboard/connect-patient',
  inviteHcpAsHcp: '/dashboard/invite/hcp',
  inviteHcpAsAdmin: '/admin/dashboard/invite/hcp',

  patient_overview: '/dashboard/patient/overview',
  patient_overview_admin: '/admin/dashboard/patient/overview',
  patient_profile: '/dashboard/patient/profile',
  patient_profile_admin: '/admin/dashboard/patient/profile',
  patient_remote_monitoring: '/dashboard/patient/remote-monitoring',
  patient_remote_monitoring_admin: '/admin/dashboard/patient/remote-monitoring',
  patient_wound_images: '/dashboard/patient/wound-images',
  patient_wound_images_admin: '/admin/dashboard/patient/wound-images',
  patient_information: '/dashboard/patient/information',
  patient_information_admin: '/admin/dashboard/patient/information',
  patient_information_add_info: '/dashboard/patient/information/add-information',
  patient_rehab_team: '/dashboard/patient/rehab-team',
  // patient_rehab_team_admin: '/admin/dashboard/patient/rehab-team',
  patient_exercise_library: '/dashboard/patient/exercise-library',
  patient_prescription_history: '/dashboard/patient/prescription-history',
  hcp_profile: '/dashboard/hcp/profile/:hcpId',
  hcp_profile_admin: '/admin/dashboard/hcp/profile/:hcpId',

  // Admin
  manage_departments: '/admin/dashboard/manage-departments',
  manage_proms: '/admin/dashboard/manage-proms',
  manage_organisations: '/admin/dashboard/manage-organisations',
  manage_professions: '/admin/dashboard/manage-professions',

  // mob link, to show notification
  signUpForMobile: '/patient/invitation/:token',
  questionnaireForMobile: '/patient/questionnaire',
  resetPasswordMobile: '/patient/reset/password/:token',
  sharingPatientResult: '/patient/sharing-result/:hcpToShareId',
  resetPinMobile: '/patient/reset/pincode/:pinCode',
  connectPatientWithCodeMobile: '/patient/connect-with-code/signup/:code',
  loginPatientWithCodeMobile: '/patient/connect-with-code/login/:code',
  redirectToQuestionnairesMobile: '/patient/questionnaires',
  redirectToAchievementsMobile: '/patient/achievements',
  redirectToExerciseLibraryMobile: '/patient/exerciseLibrary',
  redirectToRewardsMobile: '/patient/rewards',
  redirectToProcedureAndTeamMobile: '/patient/procedureAndTeam',
  patientVerifyEmail: '/patient/verify-email/:token',
  signinPageMobile: '/patient/signin',
  signupPageMobile: '/patient/signup',
  garminConnection: '/patient/garmin-connection/:patientToken',
  garminVerified: '/patient/garmin-verified/:patientToken/:oauthTokenSecret',

  notFoud: '/page-not-found',
  success_delete_hcp_account: '/success-delete-hcp-account',

  upload_video_for_patient: '/profile/patient/:user/upload-video-for-patient',
  record_video_for_patient: '/profile/patient/:user/record-video-for-patient',
  from_patient: '/profile/patient/:user/from-patient',
  manage_exercises: '/manage-exercises',
});

export const constants = {
  admin: 'admin',
  hcp: 'hcp',
  geolacation: process.env.REACT_APP_LOCATION,
  mobileSchema: (url: string) => `${process.env.REACT_APP_BUNDLE_ID || 'onthemend'}://${url}`,
};
