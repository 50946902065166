import { DataTeamType } from '../../components/RehabTeams/types';

// Type
const FILL_TEAM_LIST = 'FILL_TEAM_LIST';
const SET_TEAM_LIST_LOADING = 'SET_TEAM_LIST_LOADING';
const SET_LIST_ERROR = 'SET_LIST_ERROR';
const FILL_TOTAL_ITEMS = 'FILL_TOTAL_ITEMS';
const SET_SEARCH_TEAM_LOADING = 'SET_SEARCH_TEAM_LOADING';
const FILL_SEARCH_TOTAL_ITEMS = 'FILL_SEARCH_TOTAL_ITEMS';
const FILL_TEAM_SETTING_DATA = 'FILL_TEAM_SETTING_DATA';
const FILL_PATIENT_TEAM_LIST = 'FILL_PATIENT_TEAM_LIST';
const SET_PATIENT_TEAM_LIST_LOADING = 'SET_PATIENT_TEAM_LIST_LOADING';
const SET__PATIENT_TEAM_LIST_ERROR = 'SET__PATIENT_TEAM_LIST_ERROR';
const FILL__PATIENT_TEAM_TOTAL_ITEMS = 'FILL__PATIENT_TEAM_TOTAL_ITEMS';
const SET_SEARCH_PATIENT_TEAM_LOADING = 'SET_SEARCH_PATIENT_TEAM_LOADING';
const FILL_SEARCH_TOTAL_ITEMS_PATIENT_TEAM = 'FILL_SEARCH_TOTAL_ITEMS_PATIENT_TEAM';

// TS
export interface RehabTeamsType {
  teamList: DataTeamType[] | null;
  teamListLoading: boolean;
  teamListError: any;
  totalItems: number;
  searchTeamLoading: boolean;
  totalItemsSearchTeam: number | null;
  openTeamSettingData: DataTeamType | null;
  patientTeamList: DataTeamType[] | null;
  patientTeamListLoading: boolean;
  patientTeamListError: any;
  patientTeamTotalItems: number;
  searchPatientTeamLoading: boolean;
  totalItemsSearchPatientTeam: number | null;
}
interface LoadingType {
  type: string;
  payload: boolean;
}
interface ErrorType {
  type: string;
  payload: any;
}
interface TeamDataType {
  type: string;
  payload: any;
}
interface TeamListType {
  type: string;
  payload: DataTeamType[] | null;
}
interface TotalItemsType {
  type: string;
  payload: number;
}
interface TotalItemsSearchType {
  type: string;
  payload: number | null;
}
interface TeamSettingType {
  type: string;
  payload: DataTeamType | null;
}
type ActionTypes =
  | ErrorType
  | LoadingType
  | TeamDataType
  | TeamListType
  | TeamSettingType
  | TotalItemsType;

// Action
export const setTeamList = (payload: DataTeamType[] | null): TeamListType => ({
  type: FILL_TEAM_LIST,
  payload,
});
export const setTeamListLoading = (payload: boolean): LoadingType => ({
  type: SET_TEAM_LIST_LOADING,
  payload,
});
export const setTeamListError = (payload: any): ErrorType => ({
  type: SET_LIST_ERROR,
  payload,
});
export const setTotalItems = (payload: number): TotalItemsType => ({
  type: FILL_TOTAL_ITEMS,
  payload,
});
export const setSearchTeamItems = (payload: number | null): TotalItemsSearchType => ({
  type: FILL_SEARCH_TOTAL_ITEMS,
  payload,
});
export const setSearchTeamLoading = (payload: boolean): LoadingType => ({
  type: SET_SEARCH_TEAM_LOADING,
  payload,
});
export const setOpenTeamSettingData = (payload: DataTeamType | null): TeamSettingType => ({
  type: FILL_TEAM_SETTING_DATA,
  payload,
});
export const setPatientTeamList = (payload: DataTeamType[] | null): TeamListType => ({
  type: FILL_PATIENT_TEAM_LIST,
  payload,
});
export const setPatientTeamListLoading = (payload: boolean): LoadingType => ({
  type: SET_PATIENT_TEAM_LIST_LOADING,
  payload,
});
export const setPatientTeamListError = (payload: any): ErrorType => ({
  type: SET__PATIENT_TEAM_LIST_ERROR,
  payload,
});
export const setPatientTeamTotalItems = (payload: number): TotalItemsType => ({
  type: FILL__PATIENT_TEAM_TOTAL_ITEMS,
  payload,
});
export const setSearchPatientTeamLoading = (payload: boolean): LoadingType => ({
  type: SET_SEARCH_PATIENT_TEAM_LOADING,
  payload,
});
export const setSearchPatientTeamItems = (payload: number | null): TotalItemsSearchType => ({
  type: FILL_SEARCH_TOTAL_ITEMS_PATIENT_TEAM,
  payload,
});

// Initial comon STATE
const init: RehabTeamsType = {
  teamList: null,
  teamListLoading: false,
  teamListError: undefined,
  totalItems: 0,
  searchTeamLoading: false,
  totalItemsSearchTeam: null,
  openTeamSettingData: null,
  patientTeamList: null,
  patientTeamListLoading: false,
  patientTeamListError: undefined,
  patientTeamTotalItems: 0,
  searchPatientTeamLoading: false,
  totalItemsSearchPatientTeam: null,
};

// Reducer
export const rehabTeamsReducer = (state = init, { type, payload }: ActionTypes): RehabTeamsType => {
  switch (type) {
    case FILL_TEAM_LIST:
      return {
        ...state,
        teamList: payload,
      };
    case SET_TEAM_LIST_LOADING:
      return {
        ...state,
        teamListLoading: payload,
      };
    case SET_LIST_ERROR:
      return {
        ...state,
        teamListError: payload,
      };
    case FILL_TOTAL_ITEMS:
      return {
        ...state,
        totalItems: payload,
      };
    case SET_SEARCH_TEAM_LOADING:
      return {
        ...state,
        searchTeamLoading: payload,
      };
    case FILL_SEARCH_TOTAL_ITEMS:
      return {
        ...state,
        totalItemsSearchTeam: payload,
      };
    case FILL_TEAM_SETTING_DATA:
      return {
        ...state,
        openTeamSettingData: payload,
      };
    case FILL_PATIENT_TEAM_LIST:
      return {
        ...state,
        patientTeamList: payload,
      };
    case SET_PATIENT_TEAM_LIST_LOADING:
      return {
        ...state,
        patientTeamListLoading: payload,
      };
    case SET__PATIENT_TEAM_LIST_ERROR:
      return {
        ...state,
        patientTeamListError: payload,
      };
    case FILL__PATIENT_TEAM_TOTAL_ITEMS:
      return {
        ...state,
        patientTeamTotalItems: payload,
      };
    case SET_SEARCH_PATIENT_TEAM_LOADING:
      return {
        ...state,
        searchPatientTeamLoading: payload,
      };
    case FILL_SEARCH_TOTAL_ITEMS_PATIENT_TEAM:
      return {
        ...state,
        totalItemsSearchPatientTeam: payload,
      };
    default:
      return state;
  }
};
