import React, { ReactElement } from 'react';
import cx from 'classnames';
import moment from 'moment';
import { sanitize } from 'dompurify';

import { getPatientAnswers } from '../../../../../utils/helper_charts';
import style from './style.module.css';
import { Answer, QuestionnaireContentProps, Question, DisabilityLevel } from './types';
import { RemoveQuestionnaire } from './common/RemoveQuestionnaire';
import { CalculationDescription } from './common/Calculation';
import { PROMS_NAME } from '../../../../../utils/enums';

export const OdiReport = ({
  questionnairesList,
  completedQuestionnaire,
  index,
  onDeleteItem,
  formatDate,
  t,
}: QuestionnaireContentProps): ReactElement => {
  const remove_questionnaire = t?.dashboard.hcp.profile_patient.questionnaires.remove_questionnaire;
  const odi_name = t?.dashboard.hcp.profile_patient.questionnaires.odi_name;
  const odi_content_part1 = t?.dashboard.hcp.profile_patient.questionnaires.odi_content_part1;
  const odi_content_part2 = t?.dashboard.hcp.profile_patient.questionnaires.odi_content_part2;
  const no_disability = t?.dashboard.hcp.profile_patient.questionnaires.no_disability;
  const mild_disability = t?.dashboard.hcp.profile_patient.questionnaires.mild_disability;
  const moderate_disability = t?.dashboard.hcp.profile_patient.questionnaires.moderate_disability;
  const severe_disability = t?.dashboard.hcp.profile_patient.questionnaires.severe_disability;
  const completely_disabled = t?.dashboard.hcp.profile_patient.questionnaires.completely_disabled;
  const disability_level_1 = t?.dashboard.hcp.profile_patient.questionnaires.disability_level_1;
  const disability_level_2 = t?.dashboard.hcp.profile_patient.questionnaires.disability_level_2;
  const disability_level_3 = t?.dashboard.hcp.profile_patient.questionnaires.disability_level_3;
  const disability_level_4 = t?.dashboard.hcp.profile_patient.questionnaires.disability_level_4;
  const disability_level_5 = t?.dashboard.hcp.profile_patient.questionnaires.disability_level_5;
  const odi_calc_descr_1 = t?.dashboard.hcp.profile_patient.questionnaires.odi_calc_descr_1;
  const odi_calc_descr_2 = t?.dashboard.hcp.profile_patient.questionnaires.odi_calc_descr_2;
  const instructions = t?.dashboard.hcp.profile_patient.questionnaires.instructions;
  const score = t?.dashboard.hcp.profile_patient.questionnaires.score;
  const disability_level = t?.dashboard.hcp.profile_patient.questionnaires.disability_level;
  const odi_calc_descr_3 = t?.dashboard.hcp.profile_patient.questionnaires.odi_calc_descr_3;
  const odi_calc_descr_4 = t?.dashboard.hcp.profile_patient.questionnaires.odi_calc_descr_4;
  const odi_calc_descr_5 = t?.dashboard.hcp.profile_patient.questionnaires.odi_calc_descr_5;

  const { questions: completedQuestions, completedDate } = completedQuestionnaire;
  const patientAnswers = getPatientAnswers(completedQuestions);
  const sfList = questionnairesList.filter((item: any) => item.name === PROMS_NAME.ODI)[0];
  const { questions } = sfList;

  const disabilityLevelConfig = [
    {
      title: no_disability,
      score: '0 - 4',
      content: disability_level_1,
    },
    {
      title: mild_disability,
      score: '5 - 14',
      content: disability_level_2,
    },
    {
      title: moderate_disability,
      score: '15 - 24',
      content: disability_level_3,
    },
    {
      title: severe_disability,
      score: '25 - 34',
      content: disability_level_4,
    },
    {
      title: completely_disabled,
      score: '35 - 50',
      content: disability_level_5,
    },
  ];

  // JSX
  const calc_description = (
    <>
      <p>{odi_calc_descr_1}</p>
      <div className={style['odi__instructions-cont']}>
        <div className={style['odi__instructions-left-item']}>
          <span className={style['odi__color-red']}>{instructions}</span>
          <div className={style['odi__instructions-left-item-descr']}>{odi_calc_descr_2}</div>
        </div>
        <div className={style['odi__instructions-right-item']}>
          <div className={style['odi__instructions-row']}>
            <div
              className={cx({
                [style['odi__instructions-cell']]: true,
                [style['odi__instructions-cell-30']]: true,
                [style['odi__color-red']]: true,
                [style.center]: true,
              })}
            >
              {score}
            </div>
            <div
              className={cx({
                [style['odi__instructions-cell']]: true,
                [style['odi__instructions-cell-70']]: true,
                [style['odi__color-red']]: true,
              })}
            >
              {disability_level}
            </div>
          </div>
          {disabilityLevelConfig.map((level: DisabilityLevel) => (
            <div key={level.title} className={style['odi__instructions-row']}>
              <div
                className={cx({
                  [style['odi__instructions-cell']]: true,
                  [style['odi__instructions-cell-30']]: true,
                  [style.center]: true,
                })}
              >
                {level.score}
              </div>
              <div
                className={cx({
                  [style['odi__instructions-cell']]: true,
                  [style['odi__instructions-cell-70']]: true,
                })}
              >
                {level.title}
              </div>
            </div>
          ))}
        </div>
      </div>
      {disabilityLevelConfig.map((level: DisabilityLevel) => (
        <div
          className={style['odi__disability-level-description-cont']}
          key={`disabilityLevel${level.title}`}
        >
          <span className={style['odi__disability-level-title']}>{level.title}</span>
          <p>{level.content}</p>
        </div>
      ))}

      <div className={style['odi__disability-level-description-cont']}>
        <span
          className={cx({
            [style['odi__disability-level-title']]: true,
            [style.uppercase]: true,
          })}
        >
          {odi_calc_descr_3}
        </span>
        <p>{odi_calc_descr_4}</p>
      </div>
      <p>{odi_calc_descr_5}</p>
    </>
  );

  return (
    <>
      <RemoveQuestionnaire onMethod={() => onDeleteItem(index)} title={remove_questionnaire} />
      <div className="questionnaire-to-PDF">
        <div
          className={cx({
            [style.odi__container]: true,
          })}
          style={{ paddingBottom: '0px' }}
        >
          <CalculationDescription
            name={odi_name}
            content={calc_description}
            date={completedDate ? moment(completedDate).format(formatDate) : ''}
            t={t}
          />
        </div>
      </div>
      {/* PAGE2 */}
      <div className="questionnaire-to-PDF">
        <div
          className={cx({
            [style.odi__container]: true,
          })}
          style={{ paddingBottom: '0px' }}
        >
          <div className={style.odi__title}>{odi_name}</div>
          <div className={style.odi__content}>
            {odi_content_part1}
            <span>{odi_content_part2}</span>
          </div>

          <div className={style['odi__questions-answers-cont']}>
            {questions?.length &&
              questions.map((question: Question, ind: number) => (
                <div key={question.name} className={style['odi__questions-answers-block']}>
                  <div className={style['odi__question-row']}>
                    <span>{`${ind + 1}. `}</span>
                    <span dangerouslySetInnerHTML={{ __html: sanitize(question.name) }} />
                  </div>
                  {question.answers.map((answ: Answer, inx: number) => (
                    <div key={`answers${String(inx)}`} className={style['odi__answer-row']}>
                      <div className={style['odi__answer-checkbox']}>
                        {patientAnswers[question.id] === answ.id && <>&#10004;</>}
                      </div>
                      <div className={style.odi__answer}>{answ.name}</div>
                    </div>
                  ))}
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};
