import React, { ReactElement, useEffect, useState } from 'react';

import styles from './styles.module.css';
import { Modal } from '../../../common';
import InviteHcpModal from '../HcpModalContent/InviteHcp';
import { PlusNewElem } from '../../../common/Button/Button';
import ListAddedHcp from '../AddedUsers';
import { initialPage } from '../../../utils/helper';
import { MEMBER_TYPE } from '../../../utils/enums';
import { TListHCP } from '../../PersonalInformation/types';
import { MemberListProps } from '../types';
import { CustomCheckbox } from '../../../common/Input';
import ListHcpModalContent from '../HcpModalContent/ListHcp';

const MemberList = ({
  t,
  title_t,
  add_member_t,
  listLoading,
  memberList,
  total,
  typeMember,
  isPermissionCreateTeam,
  getMemberListForSearch,
  addedMembers,
  setAddedMembers,
  setAddedMemberIds,
  selectedMembers,
  isAddPatientsAutomatically,
  setAddPatientsAutomatically,
  isTooltip,
  isCreateTeam,
}: MemberListProps): ReactElement => {
  const invite_hcp_title = t?.title.invite_hcp;
  const add_all_my_patients = t?.hcp.manage.add_all_my_patients;
  const list_patients_not_included = t?.hcp.manage.list_patients_not_included;

  // Local state
  const [isAddTeamMemberpModalOpen, setAddTeamMemberpModalOpen] = useState(false);
  const [inviteHCP, setIniteHCP] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState('');
  const [filterQuery, setFilterQuery] = useState('');
  const [checkedHcpId, setCheckedHcpId] = useState<any>([]);
  const [prevCheckedHcpId, setPrevCheckedHcpId] = useState<any>([]);
  const [currentListHcp, setCurrentListHcp] = useState<any>([]);
  const [cleanCurrentListHcp, setCleanCurrentListHcp] = useState(true);
  const [prevCurrentListHcp, setPrevCurrentListHcp] = useState<any>([]);

  const isAddHcp = typeMember === MEMBER_TYPE.HCPS;

  // Open Add team member modal window
  const onOpenModal = (): void => {
    setFilter('');
    setFilterQuery('');
    setAddTeamMemberpModalOpen(() => true);
  };

  // Close Add team member modal window
  const _onclose = (): void => {
    setCurrentListHcp(cleanCurrentListHcp ? [] : prevCurrentListHcp);
    setCheckedHcpId(cleanCurrentListHcp ? [] : prevCheckedHcpId);
    setCurrentPage(initialPage);
    setAddTeamMemberpModalOpen(() => false);
    setIniteHCP(false);
  };

  // Add new members to list
  const addNewMembers = (): void => {
    setAddTeamMemberpModalOpen(() => false);
    setCleanCurrentListHcp(false);
    setPrevCurrentListHcp(currentListHcp);
    setPrevCheckedHcpId(checkedHcpId);
    setCurrentPage(initialPage);

    const removeduplicates = currentListHcp.filter(
      (elem: any, pos: number) => currentListHcp.indexOf(elem) === pos,
    );
    setAddedMembers([...removeduplicates]);
    const listMemberId = removeduplicates.map((a: TListHCP) => a.id);
    setAddedMemberIds(() => [...listMemberId]);
  };

  // Open invite modal window
  const onInvite = (): void => {
    setIniteHCP(true);
  };

  // Handle checkbox
  const updateCompleted = (taskId: number, item: any): void => {
    setCurrentListHcp((currentHcp: any) => {
      const newItem = currentHcp.find((el: any) => el.id === item.id);
      return currentHcp.length === 0 || !newItem
        ? [...currentHcp, item]
        : currentHcp.filter((el: any) => el.id !== item.id);
    });
    setCheckedHcpId((prev: any) =>
      prev.length === 0 || !prev.includes(taskId)
        ? [...prev, taskId]
        : prev.filter((el: any) => el !== taskId),
    );
  };

  // Remove member from local state and from list
  const removeHcp = (taskId: number): void => {
    const a = addedMembers.filter(({ id }: any) => id !== taskId);
    setAddedMembers(a);
    setCurrentListHcp(a);
    const b = (prev: any): number[] => prev.filter((id: number) => id !== taskId);
    setCheckedHcpId(b);
    setAddedMemberIds(b);
  };

  // Update HCP list after change filter
  useEffect(() => {
    if (filterQuery) {
      getMemberListForSearch(initialPage, filterQuery);
    }
  }, [filterQuery]);

  // Move to corespond page
  const onChangePagination = (page: number): void => {
    setCurrentPage(page);
    getMemberListForSearch(page, filterQuery);
  };

  return (
    <div>
      {title_t && <div className={styles['create_team__members--title']}>{title_t}</div>}
      {/* hcp list */}
      {!isAddPatientsAutomatically && !!addedMembers.length && (
        <div className={styles.create_team__added_hcps}>
          {!isCreateTeam && !isAddHcp && (
            <div className={styles.create_team__added_title}>{list_patients_not_included}</div>
          )}
          <ListAddedHcp addedList={addedMembers} removeHcp={removeHcp} />
        </div>
      )}

      {isCreateTeam && !isAddHcp && (
        <div className={styles['create_team__members--checkbox']}>
          <CustomCheckbox
            htmlId={`memberList${typeMember}`}
            name={typeMember}
            checked={isAddPatientsAutomatically}
            onChangeMethod={(e: any): void => setAddPatientsAutomatically(e.target.checked)}
            isTouched={false}
            hasErrors={false}
            disabled={!isPermissionCreateTeam}
            bigSize
          />
          <div className={styles['create_team__members--checkbox-title']}>
            {add_all_my_patients}
          </div>
        </div>
      )}

      {/* Add new hcp icon */}
      {!isAddPatientsAutomatically && (
        <div className={styles['create_team__plus-new-elem-wrapper']}>
          <PlusNewElem
            description={add_member_t}
            buttonMethod={isPermissionCreateTeam ? onOpenModal : (): null => null}
            disable={!isPermissionCreateTeam}
          />
        </div>
      )}

      {/* Popups */}
      {isAddTeamMemberpModalOpen && (
        <Modal onClose={_onclose}>
          {inviteHCP ? (
            <InviteHcpModal invite_hcp_title={invite_hcp_title} onCloseInviteModal={_onclose} />
          ) : (
            <ListHcpModalContent
              t={t}
              _onclose={_onclose}
              filter={filter}
              setFilter={setFilter}
              hcpListLoading={listLoading}
              hcpList={memberList}
              selectedHcp={selectedMembers}
              filterQuery={filterQuery}
              setFilterQuery={setFilterQuery}
              checkedHcpId={checkedHcpId}
              updateCompleted={updateCompleted}
              totalLength={total}
              pageSize={pageSize}
              currentPage={currentPage}
              onChangePagination={onChangePagination}
              onSubmit={addNewMembers}
              onInvite={onInvite}
              isAddHcp={isAddHcp || false}
              isTooltip={isTooltip}
            />
          )}
        </Modal>
      )}
    </div>
  );
};

export default MemberList;
