import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import pageStyles from '../../styles.module.css';
import { getCurrenLang } from '../../../redux/selector';
import { BreadcrumbAnt } from '../../../utils/routers/Breadcrumb';
import { MainTitle } from '../../../common';
import { InfoIcon } from '../../../theme/icons';
import OrganisationPatientInformation from '../../../components/OrganisationPatientInformation';

const OrganisationPatientInformationPage = (): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const patient_information = t?.hcp.org_patient_info?.org_patient_info;
  const patient_information_tip_one = t?.hcp.org_patient_info?.patient_information_tip_one;
  const patient_information_tip_two = t?.hcp.org_patient_info?.patient_information_tip_two;
  const patient_information_tip_three = t?.hcp.org_patient_info?.patient_information_tip_three;

  const infoContentJsx = (
    <>
      <div className={pageStyles['main__title-tip']}>{patient_information_tip_one}</div>
      <div className={pageStyles['main__title-tip']}>{patient_information_tip_two}</div>
      <div className={pageStyles['main__title-tip']}>{patient_information_tip_three}</div>
    </>
  );
  // Breadcrumb path
  const routes = [
    {
      path: patient_information,
      breadcrumbName: patient_information,
    },
  ];

  return (
    <div className={pageStyles.main__container}>
      <BreadcrumbAnt currentRoutes={routes} />
      <div className={cx([pageStyles.main__wrapper, pageStyles['main__wrapper--without-pd']])}>
        <div className={pageStyles['main__wrapper--pd']}>
          <MainTitle title={patient_information} icon={<InfoIcon />} infoContent={infoContentJsx} />
        </div>
        <OrganisationPatientInformation />
      </div>
    </div>
  );
};

export default OrganisationPatientInformationPage;
