import { useSelector } from 'react-redux';
import moment from 'moment';

// @ts-ignore
import gbLocale from 'moment/locale/en-gb'; // (United Kingdom)
// @ts-ignore
import usLocale from 'moment/locale/es-us'; // (United States)
// @ts-ignore
import deLocale from 'moment/locale/de'; // German
// @ts-ignore
import esLocale from 'moment/locale/es'; // Spanish
import { GetState, getUserLang } from '../redux/selector';
import { LANG } from '../utils/enums';

const useCheckLocale = () => {
  const hcpLang: any = useSelector<GetState>((state) => getUserLang(state));

  switch (hcpLang) {
    case LANG.EN_US:
      // return moment.locale('en-us', [usLocale]);
      return { lng: 'en-us', lacaleArr: [usLocale] };
    case LANG.DE:
      return { lng: 'de', lacaleArr: [deLocale] };
      // return moment.locale('de', [deLocale]);
    default:
      return { lng: 'en-gb', lacaleArr: [gbLocale] };
      // return moment.locale('en-gb', [gbLocale]);
  }
};

export default useCheckLocale;
