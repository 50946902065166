/* eslint-disable react/jsx-no-target-blank */
import React, { ReactElement } from 'react';
import { Tooltip } from 'antd';

import styles from './styles.module.css';
import { BmiTipProps } from './types';

const BmiTip = ({ resultBMI, t }: BmiTipProps): ReactElement => {
  const link = t?.common.link;
  const your_patients_bmi = t?.dashboard.hcp.profile_patient.your_patients_bmi;
  const bmi_divided = t?.dashboard.hcp.profile_patient.bmi_divided;
  const reference = t?.dashboard.hcp.profile_patient.reference;
  const category_one = t?.dashboard.hcp.profile_patient.bmi_category_one;
  const category_two = t?.dashboard.hcp.profile_patient.bmi_category_two;
  const category_three = t?.dashboard.hcp.profile_patient.bmi_category_three;
  const category_four = t?.dashboard.hcp.profile_patient.bmi_category_four;
  const bmiEmail =
    'https://www.nhs.uk/health-assessment-tools/calculate-your-body-mass-index/calculate-bmi-for-adults';

  const bmiTipJsx = (
    <div className={styles.bmitip__container}>
      {resultBMI && (
        <div className={styles.bmitip__title}>{`${your_patients_bmi} ${resultBMI}`}</div>
      )}
      <div className={styles.bmitip__section}>{bmi_divided}</div>
      <ul className={styles.bmitip__section}>
        <li>{category_one}</li>
        <li>{category_two}</li>
        <li>{category_three}</li>
        <li>{category_four}</li>
      </ul>
      <div>
        {reference}:{' '}
        <a href={bmiEmail} target="_blank">
          {link}
        </a>
      </div>
    </div>
  );

  return (
    <Tooltip title={bmiTipJsx}>
      <div className={styles.bmitooltip__info}>i</div>
    </Tooltip>
  );
};

export default BmiTip;
