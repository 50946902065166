import React, { ReactElement } from 'react';
import cx from 'classnames';

import styles from './styles.module.css';
import { DeleteIcon } from '../../../theme/icons';

const AddedUsers = ({ addedList, removeHcp }: any): ReactElement => (
  <ul>
    {addedList?.map((user: any) => (
      <li
        key={user.id}
        className={cx({
          [styles.list_added]: true,
          // [styles.personalInfo__row]: true,
        })}
      >
        <div>
          {user.firstName} {user.lastName}
        </div>
        <div
          onClick={(): any => removeHcp(user.id)}
          role="presentation"
          className={styles.list_del}
        >
          <DeleteIcon />
        </div>
      </li>
    ))}
  </ul>
);

export default AddedUsers;
