import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import cx from 'classnames';
import { toast } from 'react-toastify';

import styles from './styles.module.css';
import { Button, Loading, Modal, WarningNotification } from '../../../../../common';
import { getCurrenLang } from '../../../../../redux/selector';
import { DeleteIcon } from '../../../../../theme/icons';
import { deepCopyObj, scrollPageTo, setSortDirectionTitle } from '../../../../../utils/helper';
import { validationAddExercToNewFolder } from '../../../../../utils/validators';
import {
  useDeleteFolder,
  useQueryCurrentFolder,
  useSaveFolder,
} from '../../../../../graphql/videoBank';
import { CloseBtn } from '../../../../../common/Button/Button';
import ScheduleForm from './ScheduleForm';
import { path } from '../../../../../utils';
import { PopupWithTwoButtons } from '../../../../../common/Popup';
import TableHeader from '../TableHeader';
import VideoExercise from '../VideoExercise';
import { VIDEO_BANK_TYPE } from '../../../../../utils/enums';
import FolderDetails from './FolderDetails';
import { SelectElem } from '../HeaderItems';

const ExerciseFolderSettings = ({
  folderId,
  dataFolder,
  _getFolderSettings,
  loadingFolder,
  videoExersisesList,
  setVideoExersisesList,
  unitOptions,
  numberOptions,
  focusType1Arr,
  focusType2Arr,
  exercisesCategories,
  exercisesProcedureFocus,
  setSuccessSaved,
  isSuccessSaved,
  recentlyDeleted,
  setRecentlyDeleted,
}: any): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const cancel = t?.common.cancel;
  const save = t?.common.save;
  const ok = t?.common.ok;
  const delete_folder = t?.common.delete_folder;
  const warning_unsaved_changes = t?.common.warning_unsaved_changes;
  const exit_t = t?.common.exit;
  const delete_t = t?.common.delete;
  const del_folder_warn_title = t?.dashboard.hcp.profile_patient.del_folder_warn_title;
  const del_folder_warn_description = t?.dashboard.hcp.profile_patient.del_folder_warn_description;
  const recently_deleted_videos = t?.dashboard.hcp.profile_patient.recently_deleted_videos;
  const remove_content_warn_title = t?.dashboard.hcp.profile_patient.remove_content_warn_title;
  const remove_content_warn_desc = t?.dashboard.hcp.profile_patient.remove_content_warn_description;

  const exercises_folder = t?.hcp.org_video_bank?.exercises_folder;
  const exercises_folder_description = t?.hcp.org_video_bank?.exercises_folder_description;

  // Endpoints
  const { _deleteFolder, errorDelFolder, loadingDelFolder, folderDeleted } = useDeleteFolder();
  const { _saveFolder, errorSaveFolder, loadingSaveFolder, savedFolder } = useSaveFolder();

  // Request to DB
  const getListVideoExercises = (
    sortName: string,
    sortdirection: string,
    searchValue: string,
  ): void => {
    const sortProps = { sortBy: 'name', sortDirection: '' };

    if (sortdirection) {
      sortProps.sortBy = sortName;
      sortProps.sortDirection = sortdirection;
    }

    _getFolderSettings({
      variables: {
        hcpFolderId: folderId,
        sortProps,
        searchProps: {
          searchField: 'name',
          searchValue,
        },
      },
    });
  };

  // Local state
  const [isOpenScheduleForm, setOpenScheduleForm] = useState(false);
  const [isDeleteFolder, setDeleteFolder] = useState(false);
  const [isExitWarn, setExitWarn] = useState(false);
  const [selectExercise, setSelectExercise] = useState<number[]>([]);
  const [isDeleteExerc, setDeleteExerc] = useState(false);
  const [hideSearchMenu, setHideSearchMenu] = useState(false);

  const [sortBy, setSortby] = useState<string>('name');
  const [sortDirectionName, setSortDirectionName] = useState<string>('');
  const [isSearch, setIsSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const history = useHistory();
  const initialValues = useMemo(
    () => ({
      name: dataFolder.name,
      description: dataFolder.description || '',
      videobankIds: dataFolder.videobankIds,
      // amount: dataFolder.hcpFolderTimer?.amount
      //   ? numberOptions[dataFolder.hcpFolderTimer.amount - 1]
      //   : null,
      // timePeriod: dataFolder.hcpFolderTimer?.timePeriod
      //   ? unitOptions[dataFolder.hcpFolderTimer.timePeriod - 1]
      //   : null,
    }),
    [dataFolder],
  );

  const formik = useFormik({
    // enableReinitialize: true,
    initialValues,
    validationSchema: () => validationAddExercToNewFolder(t),
    onSubmit: (values: any) => {
      const idsMutate = [...values.videobankIds];
      const hcpFolderData: any = {
        id: folderId,
        name: values.name.trim(),
        description: values.description.trim(),
        amount: dataFolder.hcpFolderTimer?.amount || null,
        timePeriod: dataFolder.hcpFolderTimer?.timePeriod || null,
      };
      if (recentlyDeleted.length > 0) {
        recentlyDeleted.map((deletedExerc: any) => {
          const pos = idsMutate.indexOf(deletedExerc.currentId);
          if (pos >= 0) {
            idsMutate.splice(pos, 1);
          }
          return null;
        });
      }
      _saveFolder({
        variables: {
          hcpFolderData,
          videobankIds: idsMutate,
        },
      });
    },
  });
  const { values, dirty } = formik;

  // Exercise List Update
  const updateList = (): void => {
    getListVideoExercises(sortBy, sortDirectionName, searchQuery);
  };

  // Delete current folder and back to TeamVideoBank
  const deleteFolder = (): void => {
    _deleteFolder({
      variables: {
        hcpFolderId: folderId,
      },
    });
  };

  // Close folder and back to Organisation Video Bank
  const exit = (confirmExit?: boolean): void => {
    if ((dirty || recentlyDeleted?.length) && !confirmExit && !isSuccessSaved) {
      setExitWarn(() => true);
      return;
    }
    history.push({
      pathname: `${path.organisationVideoBank}`,
    });
  };

  // Confirm exit from page
  const onConfirmExit = (): void => {
    setExitWarn(() => false);
    exit(true);
  };

  // If there is an error when deleting, we show the message
  useEffect(() => {
    if (folderDeleted && !errorDelFolder) {
      exit(true);
    }
  }, [folderDeleted]);

  // If there is an error when deleting, we show the message
  useEffect(() => {
    if (errorDelFolder) {
      toast.error(errorDelFolder);
    }
  }, [errorDelFolder]);

  // If there is an error when saving, we show the message
  useEffect(() => {
    if (errorSaveFolder) {
      toast.error(errorSaveFolder);
    }
  }, [errorSaveFolder]);

  // If success saving, we show popup
  useEffect(() => {
    if (savedFolder) {
      setSuccessSaved(() => true);
      scrollPageTo(0);
      setRecentlyDeleted([]);
    }
  }, [savedFolder]);

  // If there are new changes clear success save state
  useEffect(() => {
    if (isSuccessSaved) {
      setSuccessSaved(() => false);
    }
  }, [values, recentlyDeleted]);

  /* * search for matches in the database * */
  const [searchMatches, setSearchMatches] = useState(null);
  const [searchInputValue, setSearchInputValue] = useState('');

  // get match results for video exercises for Team video bank Folder page
  const {
    _getFolderSettings: _getFolderSettingsMatch,
    dataFolder: teamVideoExercisesMatch,
    loadingFolder: loadingMatch,
  } = useQueryCurrentFolder();

  // Find search matches in DB
  const findMatches = (searchVal: string): void => {
    if (!searchVal) {
      setSearchMatches(null);
      setSearchInputValue('');
      return;
    }
    setSearchInputValue(searchVal);
    _getFolderSettingsMatch({
      variables: {
        hcpFolderId: folderId,
        sortProps: { sortBy: 'name', sortDirection: '' },
        searchProps: {
          searchField: 'name',
          searchValue: searchVal,
        },
      },
    });
  };

  // Show the result from the database in the search menu
  useEffect(() => {
    if (!searchInputValue || !hideSearchMenu) return;
    if (!loadingMatch) {
      const current = teamVideoExercisesMatch?.folderExercises?.length || 0;
      setSearchMatches(current);
    }
  }, [teamVideoExercisesMatch]);

  // Get search exercise name
  const handleSearch = (val: string): void => {
    setSearchQuery(val);
    setSearchMatches(null);
    setHideSearchMenu(!hideSearchMenu);
    if (val) {
      getListVideoExercises(sortBy, sortDirectionName, val);
      setIsSearch(() => true);
    }
  };

  // Reset search exercise name
  const handleReset = (): void => {
    setSearchQuery('');
    setSearchMatches(null);
    setHideSearchMenu(!hideSearchMenu);
    if (searchQuery) {
      getListVideoExercises(sortBy, sortDirectionName, '');
      setIsSearch(() => false);
    }
  };

  // Move exercises to Recently deleted section
  const moveToRecentlyDeleted = (): void => {
    const newRecentlyDeleted = deepCopyObj(recentlyDeleted);
    const newVideoExercisesList: any = [];
    videoExersisesList.map((videoExercise: any) => {
      if (selectExercise.includes(videoExercise.currentId)) {
        newRecentlyDeleted.push(videoExercise);
      } else {
        newVideoExercisesList.push(videoExercise);
      }
      return null;
    });
    setRecentlyDeleted(newRecentlyDeleted);
    setVideoExersisesList(newVideoExercisesList);
    setSelectExercise([]);
    setDeleteExerc(() => false);
  };

  // Recover exercises from Recently deleted section
  const recoverExercise = (id: number): void => {
    const newRecentlyDeleted: any = [];
    const newVideoExercisesList: any = deepCopyObj(videoExersisesList);
    recentlyDeleted.map((deletedExercise: any) => {
      if (deletedExercise.currentId === id) {
        newVideoExercisesList.push(deletedExercise);
      } else {
        newRecentlyDeleted.push(deletedExercise);
      }
      return null;
    });
    setRecentlyDeleted(newRecentlyDeleted);
    setVideoExersisesList(newVideoExercisesList);
  };

  const tooltipTitleTextByName = setSortDirectionTitle(sortDirectionName, t);

  // Sort exercise list by name
  const sortByColumnName = (name: string, sortdirection: string): void => {
    setSortby(name);
    // const sortdirection = getSortDirectionName();
    setSortDirectionName(sortdirection);
    getListVideoExercises(name, sortdirection, searchQuery);
  };

  // JSX
  const loadingJSX = (loadingDelFolder || loadingSaveFolder) && <Loading />;

  return (
    <>
      {loadingJSX}
      <div className={styles['exercise-folder-settings__section-container']}>
        {/* Folder Details */}
        <FolderDetails
          dataFolder={dataFolder}
          unitOptions={unitOptions}
          numberOptions={numberOptions}
          formik={formik}
          loadingJSX={loadingJSX}
          isOpenScheduleForm={false}
          setOpenScheduleForm={setOpenScheduleForm}
        />

        {/* Schedule this folder */}
        {isOpenScheduleForm && (
          <ScheduleForm
            folderId={folderId}
            dataFolder={dataFolder}
            unitOptions={unitOptions}
            numberOptions={numberOptions}
            setOpenScheduleForm={setOpenScheduleForm}
            updateList={updateList}
          />
        )}
      </div>

      {/* Exercises in the folder */}
      <div
        className={cx({
          [styles['exercise-folder-settings__title']]: true,
          [styles['exercise-folder-settings--pd']]: true,
        })}
      >
        {exercises_folder}
      </div>
      <div className={styles['exercise-folder-settings__description']}>
        {exercises_folder_description}
      </div>

      {/* Select sm screen */}
      <div className={styles['org-video-bank__select-container']}>
        <SelectElem
          selectElem={selectExercise}
          setSelectElem={setSelectExercise}
          openMethod={(): void => setDeleteExerc(() => true)}
          disabled={false}
          submitBtnName={delete_t}
        />
      </div>

      {/* Header */}
      <TableHeader
        selectExercise={selectExercise}
        setSelectExercise={setSelectExercise}
        selectAction={(): void => setDeleteExerc(() => true)}
        tooltipTitleTextByName={tooltipTitleTextByName}
        sortByColumnName={sortByColumnName}
        sortBy={sortBy}
        handleSearch={handleSearch}
        handleReset={handleReset}
        searchMatches={searchMatches}
        findMatches={findMatches}
        hideSearchMenu={hideSearchMenu}
        isSearch={isSearch}
        setHideSearchMenu={setHideSearchMenu}
        setSearchMatches={setSearchMatches}
        actionBtnName={delete_t}
      />

      {/* Exercises List */}
      {!!videoExersisesList?.length &&
        Object.keys(exercisesCategories).length > 0 &&
        exercisesProcedureFocus &&
        videoExersisesList.map((videoExerciseItem: any, i: number) => {
          const keyObj = `${videoExerciseItem.currentId}${videoExerciseItem.type}${i}`;
          return (
            <VideoExercise
              data={videoExerciseItem}
              index={String(i)}
              exercisesCategories={exercisesCategories}
              exercisesProcedureFocus={exercisesProcedureFocus}
              focusType1Arr={focusType1Arr}
              focusType2Arr={focusType2Arr}
              key={keyObj}
              updateList={updateList}
              selectExercise={selectExercise}
              setSelectExercise={setSelectExercise}
              folderId={folderId}
              type={VIDEO_BANK_TYPE.TEAM_VIDEO_BANK_FOLDER}
            />
          );
        })}

      {/* RECENTLY DELETED EXERCISES */}
      {recentlyDeleted.length > 0 && (
        <>
          <div className={styles['exercise-folder-settings__recently-deleted-title']}>
            {recently_deleted_videos}
          </div>
          {recentlyDeleted.map((deletedItem: any, index: number) => {
            const keyObj = JSON.stringify(deletedItem);
            return (
              <VideoExercise
                data={deletedItem}
                index={String(`recently_deleted_${index}`)}
                exercisesCategories={exercisesCategories}
                exercisesProcedureFocus={exercisesProcedureFocus}
                focusType1Arr={focusType1Arr}
                focusType2Arr={focusType2Arr}
                key={keyObj}
                updateList={updateList}
                selectExercise={selectExercise}
                setSelectExercise={setSelectExercise}
                isRecentlyDeleted
                recoverExercise={recoverExercise}
                type={VIDEO_BANK_TYPE.TEAM_VIDEO_BANK_FOLDER}
              />
            );
          })}
        </>
      )}

      {/* Buttons */}
      <div className={styles['exercise-folder-settings__folder-btns-container']}>
        <div
          className={cx({
            [styles['exercise-folder-settings__btn-delete-wraper']]: true,
            // [styles.notAllowed]: !checkPermission(),
          })}
          role="presentation"
          onClick={(): void => setDeleteFolder(() => true)}
        >
          <DeleteIcon />
          <span className={styles['exercise-folder-settings__btn-delete']}>{delete_folder}</span>
        </div>
        <Button
          buttonClass={styles['exercise-folder-settings__btn-save']}
          buttonType="button"
          buttonName={cancel}
          buttonMethod={(): void => exit()}
          disabledButton={loadingFolder || !!loadingJSX}
        />
        <Button
          buttonClass={styles['exercise-folder-settings__btn-save']}
          buttonType="submit"
          buttonMethod={(): void => formik.handleSubmit()}
          buttonName={save}
          disabledButton={(!dirty && recentlyDeleted.length === 0) || loadingFolder || !!loadingJSX}
        />
      </div>

      {/* Popups */}
      {/* Delete folder warning */}
      {isDeleteFolder && (
        <Modal onClose={(): void => setDeleteFolder(() => false)}>
          <CloseBtn close={(): void => setDeleteFolder(() => false)} />
          <div className={styles.spacer} />
          <WarningNotification
            title={del_folder_warn_title}
            contant={del_folder_warn_description}
            buttonName={ok}
            onCancel={(): void => setDeleteFolder(() => false)}
            onSubmit={(): void => deleteFolder()}
          />
        </Modal>
      )}

      {/* Warning popup if has not saved changes */}
      {isExitWarn && (
        <PopupWithTwoButtons
          title={warning_unsaved_changes}
          content=""
          confirmButtonName={exit_t}
          closeButtonName={cancel}
          onConfirm={onConfirmExit}
          onClosePopup={(): void => setExitWarn(() => false)}
        />
      )}

      {/* Delete exercises from folder warning */}
      {isDeleteExerc && (
        <Modal onClose={(): void => setDeleteExerc(() => false)}>
          <CloseBtn close={(): void => setDeleteExerc(() => false)} />
          <div className={styles.spacer} />
          <WarningNotification
            title={remove_content_warn_title}
            contant={remove_content_warn_desc}
            buttonName={ok}
            onCancel={(): void => setDeleteExerc(() => false)}
            onSubmit={(): void => moveToRecentlyDeleted()}
          />
        </Modal>
      )}

      {/* Success save folder */}
      {/* {isSuccessSaved && (
        <Modal onClose={(): void => exit(true)}>
          <CloseBtn close={(): void => exit(true)} />
          <div>
            <div className={styles.icon}>
              <CheckIcon />
            </div>
            <div className={styles.content}>{exercise_folder_saved}</div>
            <Button
              buttonClass={styles['exercise-folder-settings__btn-ok']}
              buttonType="button"
              buttonName={ok}
              buttonMethod={(): void => exit(true)}
            />
          </div>
        </Modal>
      )} */}
    </>
  );
};

export default ExerciseFolderSettings;
