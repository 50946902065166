import React, { Fragment, ReactElement, useEffect, useMemo, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';

import pageStyles from '../../styles.module.css';
import styles from './styles.module.css';
import { BreadcrumbAnt } from '../../../utils/routers/Breadcrumb';
import { Loading, MainTitle } from '../../../common';
import { EmailNotificationIcon } from '../../../theme/icons';
import {
  GetState,
  getCurrenLang,
  getEmailNotifSettingsData,
  getNotifToMeData,
  getNotifToMeError,
  getNotifToMeLoading,
} from '../../../redux/selector';
import { executeScroll, getQueryParams } from '../../../utils/helper';
import { useCurrentHcpProfileForToggler } from '../../../graphql/hcpProfile';
import { useGetCurrentHcpNotificationToPatient } from '../../../graphql/hcp';
import { EMAIL_NOTIF_CATEGORY, EMAIL_NOTIF_TYPE } from '../../../utils/enums';
import { path } from '../../../utils';
import NotificationsToMeSwitch from '../../../components/EmailNotifications/NotificationsToMeSwitch';
import RemoveUnreadNotifications from '../../../components/EmailNotifications/RemoveUnreadNotif';
import { RemoveUnreadNotificationsSettings } from '../../../components/EmailNotifications/SettingsForm';
import { setOpenSettingsData } from '../../../redux/emailNotifications';
import HcpAllEmailNotification from '../../../components/EmailNotifications/HcpAllEmailNotif';
import { HcpAllEmailNotifType } from '../../../components/EmailNotifications/types';
import { NotificationsToMeSettings } from '../../../components/EmailNotifications/NotificationsToMeSettings';
import NotifToMyPatients from '../../../components/EmailNotifications/NotifToMyPatients';
import { NotifToPatientsSettingsForm } from '../../../components/EmailNotifications/NotifToPatientsSettingsForm';

const EmailNotificationsPage = (): ReactElement => {
  const t: any = useSelector<any>((state: GetState) => getCurrenLang(state));
  const notifications_to_me = t?.hcp.manage.notifications_to_me;
  const notifications_to_patients = t?.hcp.manage.notifications_to_patients;
  const email_notif = t?.menu.email_notifications;
  const my_notif = t?.hcp.email_notif?.notif_to_me;
  const notif_to_patients = t?.hcp.email_notif?.notif_to_patients;

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { category } = getQueryParams(location.search);
  const categoryFromQuery: any = String(category);

  // Redux
  const notifToMeData: any = useSelector((state: GetState) => getNotifToMeData(state));
  const notifToMeLoading: any = useSelector((state: GetState) => getNotifToMeLoading(state));
  const notifToMeError: any = useSelector((state: GetState) => getNotifToMeError(state));
  const openSettingsData: any = useSelector((state: GetState) => getEmailNotifSettingsData(state));
  const sectionRef: any = useRef(null);

  // Scroll to elem
  useEffect(() => {
    if (openSettingsData) {
      executeScroll(sectionRef);
    }
  }, [openSettingsData]);

  const hcpAllEmailNotif = useMemo(() => {
    if (notifToMeData?.hcpAllEmailNotif) {
      return notifToMeData.hcpAllEmailNotif;
    }
  }, [notifToMeData?.hcpAllEmailNotif]);

  // Endpoints
  // Notifications to me
  const { _getTogglerSetting } = useCurrentHcpProfileForToggler();

  // Notifications to my patients
  const { _getCurrentHcpNotifToPatient } = useGetCurrentHcpNotificationToPatient();

  const isSearchParamError = useMemo(() => {
    if (!categoryFromQuery) {
      return true;
    }
    if (
      categoryFromQuery !== EMAIL_NOTIF_CATEGORY.NOTIF_TO_ME &&
      categoryFromQuery !== EMAIL_NOTIF_CATEGORY.NOTIF_TO_MY_PATIENTS
    ) {
      return true;
    }
    return false;
  }, [categoryFromQuery]);

  // Query get data
  const _getData = useMemo(() => {
    if (categoryFromQuery === EMAIL_NOTIF_CATEGORY.NOTIF_TO_ME) {
      return _getTogglerSetting;
    }
    if (categoryFromQuery === EMAIL_NOTIF_CATEGORY.NOTIF_TO_MY_PATIENTS) {
      return _getCurrentHcpNotifToPatient;
    }
    return (): null => null;
  }, [categoryFromQuery]);

  const updateData = (): void => {
    _getData();
  };

  // If there is a Search Param error, use the default
  useEffect(() => {
    if (isSearchParamError) {
      history.push({
        pathname: path.emailNotifications,
        search: `category=${EMAIL_NOTIF_CATEGORY.NOTIF_TO_ME}`,
      });
    }
  }, [isSearchParamError]);

  // Switch between category
  const changeСategory = (arg: EMAIL_NOTIF_CATEGORY): void => {
    if (categoryFromQuery === arg) {
      return;
    }
    if (openSettingsData) {
      dispatch(setOpenSettingsData(undefined));
    }
    history.push({
      pathname: path.emailNotifications,
      search: `category=${arg}`,
    });
  };

  // Get default data from DB
  useEffect(() => {
    if (categoryFromQuery) {
      _getData();
    }
  }, []);

  // If changed tab get new data from DB
  useEffect(() => {
    if (categoryFromQuery) {
      _getData();
    }
  }, [categoryFromQuery]);

  // JSX
  const loadingJSX = notifToMeLoading && <Loading />;
  const getSettingsJSX = (settingsData: any): JSX.Element => {
    switch (settingsData.type) {
      case EMAIL_NOTIF_TYPE.NOTIFICATION:
        return (
          <RemoveUnreadNotificationsSettings data={openSettingsData} updateData={updateData} />
        );
      case EMAIL_NOTIF_TYPE.EXERCISE_COMPLETION:
      case EMAIL_NOTIF_TYPE.STEP_COUNT:
      case EMAIL_NOTIF_TYPE.PAIN:
        return (
          <NotificationsToMeSettings
            openSettingsData={openSettingsData}
            updateData={updateData}
            unicPrefix="setBgScreen"
          />
        );
      case EMAIL_NOTIF_TYPE.WOUND_IMAGES:
        return (
          <NotifToPatientsSettingsForm
            openSettingsData={openSettingsData}
            updateData={updateData}
          />
        );
      default:
        return <></>;
    }
  };

  // Breadcrumb path
  const routes = [
    {
      path: email_notif,
      breadcrumbName: email_notif,
    },
  ];

  return (
    <div className={pageStyles.main__container}>
      {loadingJSX}
      <BreadcrumbAnt currentRoutes={routes} />
      <div className={pageStyles.main__wrapper}>
        <MainTitle title={email_notif} icon={<EmailNotificationIcon />} />
        <div className={pageStyles['section-description']}>
          <div>{my_notif}</div>
          <div>{notif_to_patients}</div>
        </div>

        <div className={pageStyles['flex-container']}>
          {/* Main page */}
          <div className={pageStyles['left-section']}>
            {/* Tabs */}
            <div className={styles['rehab-teams__tabs-container']}>
              {/* Notifications to me */}
              <div
                className={cx({
                  [styles['rehab-teams__tab']]: true,
                  [styles['rehab-teams__tab--active']]:
                    categoryFromQuery === EMAIL_NOTIF_CATEGORY.NOTIF_TO_ME,
                })}
                onClick={(): void => changeСategory(EMAIL_NOTIF_CATEGORY.NOTIF_TO_ME)}
                role="tabpanel"
                aria-hidden
              >
                {notifications_to_me}
              </div>
              {/* Notifications to my patients */}
              <div
                className={cx({
                  [styles['rehab-teams__tab']]: true,
                  [styles['rehab-teams__tab--active']]:
                    categoryFromQuery === EMAIL_NOTIF_CATEGORY.NOTIF_TO_MY_PATIENTS,
                })}
                onClick={(): void => changeСategory(EMAIL_NOTIF_CATEGORY.NOTIF_TO_MY_PATIENTS)}
                role="tabpanel"
                aria-hidden
              >
                {notifications_to_patients}
              </div>
            </div>

            {categoryFromQuery === EMAIL_NOTIF_CATEGORY.NOTIF_TO_ME && (
              <>
                <NotificationsToMeSwitch notifToMeData={notifToMeData} updateData={updateData} />
                <RemoveUnreadNotifications updateData={updateData} />
                {hcpAllEmailNotif?.map((notif: HcpAllEmailNotifType, i: number) => {
                  if (
                    notif.emailNotifType === 2 ||
                    notif.emailNotifType === 4 ||
                    notif.emailNotifType === 6
                  ) {
                    return <Fragment key={`HcpAllEmailNotification_${i}`} />;
                  }
                  return (
                    <HcpAllEmailNotification
                      key={`HcpAllEmailNotification_${i}`}
                      data={notif}
                      subSectionData={hcpAllEmailNotif[notif.emailNotifType]}
                      updateData={updateData}
                    />
                  );
                })}
              </>
            )}

            {categoryFromQuery === EMAIL_NOTIF_CATEGORY.NOTIF_TO_MY_PATIENTS && (
              <NotifToMyPatients updateData={updateData} />
            )}
          </div>

          {/* Settings */}
          {openSettingsData && (
            <div
              ref={sectionRef}
              className={cx({
                [styles['email-notif__settings-wrapper--bg']]: true,
                [pageStyles['right-section']]: true,
              })}
            >
              {getSettingsJSX(openSettingsData)}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmailNotificationsPage;
