import React, { ReactElement, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Switch } from 'antd';
import { toast } from 'react-toastify';

import style from './styles.module.css';
import { GetState, getCurrenLang } from '../../redux/selector';
import { SetPatientRequestsConfig } from './types';
import { useChangeEmailReceiving } from '../../graphql/invite';
import { useChangeAcceptPatientInvitation } from '../../graphql/dashboard';

const NotificationsToMeSwitch = ({ notifToMeData, updateData }: any): ReactElement => {
  const t: any = useSelector((state: GetState) => getCurrenLang(state));
  const on = t?.common.on;
  const off = t?.common.off;
  const auto_accept_patient_inv = t?.dashboard.hcp.common.automatically_accept_patient_invitations;
  const email_me_when_i_have_pending_patient_invitations =
    t?.notifications.email_me_when_i_have_pending_patient_invitations;
  const email_me_when_new_patients_are_automatically_accepted =
    t?.notifications.email_me_when_new_patients_are_automatically_accepted;
  const pending_patient_invitations = t?.hcp.manage.pending_patient_invitations;
  const automatically_accept_new_patient = t?.hcp.manage.automatically_accept_new_patient;

  const {
    _changeAcceptPatientInvitation,
    acceptPatientInvitationSet,
    acceptPatientInvitationLoading,
    acceptPatientInvitationError,
  } = useChangeAcceptPatientInvitation();

  const {
    _changeEmailReceiving,
    changedEmailReceiving,
    emailReceivingLoading,
    emailReceivingError,
  } = useChangeEmailReceiving();

  const patientRequestsConfig = useMemo(
    () => [
      {
        title: auto_accept_patient_inv,
        description: '',
      },
      {
        title: pending_patient_invitations,
        description: email_me_when_i_have_pending_patient_invitations,
      },
      {
        title: automatically_accept_new_patient,
        description: email_me_when_new_patients_are_automatically_accepted,
      },
    ],
    [t],
  );

  const isAutomaticallyAccept = notifToMeData?.isAutomaticallyAccept;
  const isEmailReceiving = notifToMeData?.isEmailReceiving;

  useEffect(() => {
    if (acceptPatientInvitationError) {
      toast.error(acceptPatientInvitationError);
    }
  }, [acceptPatientInvitationError]);

  useEffect(() => {
    if (emailReceivingError) {
      toast.error(emailReceivingError);
    }
  }, [emailReceivingError]);

  useEffect(() => {
    if (acceptPatientInvitationSet) {
      updateData();
    }
  }, [acceptPatientInvitationSet]);

  useEffect(() => {
    if (changedEmailReceiving) {
      updateData();
    }
  }, [changedEmailReceiving]);

  const getSwitchParam = (index: number) => {
    switch (index) {
      case 0:
        return {
          isDisabled: false,
          isChecked: isAutomaticallyAccept,
          method: _changeAcceptPatientInvitation,
        };
      case 1:
        return {
          isDisabled: isAutomaticallyAccept,
          isChecked: !isAutomaticallyAccept && isEmailReceiving,
          method: _changeEmailReceiving,
        };
      case 2:
        return {
          isDisabled: !isAutomaticallyAccept,
          isChecked: isAutomaticallyAccept && isEmailReceiving,
          method: _changeEmailReceiving,
        };
      default:
        return { isDisabled: false, isChecked: false, method: (): null => null };
    }
  };

  return (
    <>
      {patientRequestsConfig.map((item: SetPatientRequestsConfig, i: number) => {
        const { isDisabled, isChecked, method } = getSwitchParam(i);
        return (
          <div className={style.row__wrapper} key={`ManagePatientRequests_${String(i)}`}>
            <div className={style.row}>
              <div className={style['email-notif__title-container']}>
                <div className={style['email-notif__title']}>{item.title}</div>
                <div className={style['email-notif__description']}>{item.description}</div>
              </div>
              <div className={style['email-notif__switch-wrapper']}>
                <Switch
                  checkedChildren={<span>{on}</span>}
                  unCheckedChildren={<span>{off}</span>}
                  loading={emailReceivingLoading || acceptPatientInvitationLoading}
                  checked={isChecked}
                  disabled={isDisabled}
                  onChange={(checked: boolean): void => method(checked)}
                />
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default NotificationsToMeSwitch;
