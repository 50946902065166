/* eslint-disable default-case */
import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import styles from './styles.module.css';
import { createBlocks, maxPainDetectValue, minPainDetectValue } from '../../../utils/helper_charts';
import { getCurrenLang } from '../../../redux/selector';

const PainDetect = ({ painScore, painSkip }: any): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const pain_detect = t?.dashboard.hcp.profile_patient.questionnaires.pain_detect;
  const positive = t?.dashboard.hcp.profile_patient.questionnaires.positive;
  const unclear = t?.dashboard.hcp.profile_patient.questionnaires.unclear;
  const negative = t?.dashboard.hcp.profile_patient.questionnaires.negative;

  const heightOneblock = 100 / maxPainDetectValue;
  const heightBottom = painScore * heightOneblock;

  return (
    <div
      className={cx({
        [styles.proms__pcs]: true,
      })}
    >
      <div
        className={cx({
          [styles['proms__pd-name']]: true,
        })}
      >
        {pain_detect}
      </div>
      <div
        className={cx({
          [styles.total]: true,
          [styles['proms__fontSize-value--proms-tab']]: true,
        })}
      >
        <span className={styles.pain_max_value}>{maxPainDetectValue}</span>

        {/* completed */}
        {!!painScore && (
          <>
            {/* {Pain Detect Scale */}
            {createBlocks(maxPainDetectValue).map((block: { color: string }, i: number) => (
              <div
                key={`${block.color}${String(i)}`}
                className={styles.blockScale}
                style={{
                  backgroundColor: block.color,
                  height: `${heightOneblock}%`,
                }}
              />
            ))}

            {/* Description */}
            <span className={styles.positive} style={{ bottom: `${heightOneblock * 28}%` }}>
              {positive}
            </span>
            <span className={styles.unclear} style={{ bottom: `${heightOneblock * 15}%` }}>
              {unclear}
            </span>
            <span className={styles.negative} style={{ bottom: `${heightOneblock * 6}%` }}>
              {negative}
            </span>

            {/* Current Value */}
            <div
              className={styles.currentPainDetect}
              style={{
                display: !painScore ? 'none' : 'block',
                bottom: `${heightBottom}%`,
              }}
            >
              <span className={styles.pcs_value}>{painScore}</span>
            </div>
          </>
        )}

        {/* not completed */}
        {painSkip && <span className={styles.skip}>{painSkip}</span>}
        <span className={styles.pain_min_value}>{minPainDetectValue}</span>
      </div>
    </div>
  );
};

export default PainDetect;
