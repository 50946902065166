import React, { ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import style from '../styles.module.css';
import { getCurrenLang, GetState } from '../../../redux/selector';
import { Button, MainTitle } from '../../../common';
import { InitErrType, UploadFileType } from '../../../pages/Patient/AddPatientInformation/types';
import { Loading, SpinnerWithContent } from '../../../common/Loader';
import { deepCopyObj, onChangeArea, uploadFiles } from '../../../utils/helper';
import {
  allowedFileLabel,
  allowedFileTypes,
  allowedPicTypes,
  filesSizeLimit,
  initErr,
  initUploadFile,
} from '../../../pages/Patient/AddPatientInformation/InitValues';
import { Attachment } from '../../../common/Attachments';
import { InputTypeFile, TextareaFeildFloatLabel } from '../../../common/Input';
import { Popup, PopupWithTwoButtons } from '../../../common/Popup';
import { useRemoveFileFromAws, useRemoveVideoFileFromAws } from '../../../graphql/attachments';
import useCheckRole from '../../../hooks/useCheckRole';
import { PlusNewItem } from '../../../common/Button/Button';
import { UploadFilePops } from '../types';

const UploadFile = ({
  uploadFile,
  setUploadFile,
  fileSizes,
  setFileSizes,
  setOpenForm,
  uploadFileRaw,
  setUploadFileRaw,
}: UploadFilePops): ReactElement => {
  const t: any = useSelector<any>((state: GetState) => getCurrenLang(state));
  const ok = t?.notifications.ok;
  const cancel = t?.common.cancel;
  const attachment = t?.dashboard.hcp.profile_patient.attachment;
  const upload_files = t?.dashboard.hcp.profile_patient.upload_files;
  const file_size = t?.dashboard.hcp.profile_patient.file_size;
  const file_size_norm = file_size?.replace('<size>', '50MB');
  const upload_your_attachment = t?.dashboard.hcp.profile_patient.upload_your_attachment;
  const add_file = t?.dashboard.hcp.profile_patient.add_file;
  const upload_file_descr_label = t?.dashboard.hcp.profile_patient.upload_file_descr_label;
  const large_file = t?.dashboard.hcp.profile_patient.large_file;
  const large_file_norm = large_file?.replace('<size>', '50MB');
  const uploading_file_error = t?.dashboard.hcp.profile_patient.uploading_file_error;
  const wrong_format = t?.help.wrong_format;
  const file_was_not_deleted = t?.dashboard.hcp.profile_patient.file_was_not_deleted;
  const add_more_attachments = t?.dashboard.hcp.profile_patient.add_more_files;
  const warning_unsaved_changes = t?.common.warning_unsaved_changes;
  const exit = t?.common.exit;

  const { token } = useCheckRole();

  // Endpoints
  const { _removeFile, deleteFromAwsLoading } = useRemoveFileFromAws();
  const { _removeVideo, deleteVideoFromAwsLoading } = useRemoveVideoFileFromAws();

  // Local state
  const [loading, setLoading] = useState(false);
  const [fileInputKey, setFileInputKey] = useState<number>(Date.now());
  const [errorMessage, setErrorMessage] = useState<InitErrType>(deepCopyObj(initErr));
  const [isExitWarn, setExitWarn] = useState(false);

  // Check the incoming file for the correct format, size and upload to AWS
  const onCheckIncomingFiles = async (e: any, ind: number): Promise<void> => {
    setFileInputKey(Date.now());
    setLoading(() => true);
    let size = fileSizes;
    let wrongFormat = false;
    let newFile = null;
    let fileNameOrigin = '';
    let fileTypeOrigin = '';
    try {
      const f = e.target.files;
      const filesArray = Array.from(f);
      if (filesArray.length) {
        const file: any = filesArray[0];
        size += file.size;
        const { type } = file;
        fileNameOrigin = file.name;
        const fileName = file.name.toLowerCase();
        newFile = new File([file], fileName, { type });

        if (!type) {
          wrongFormat = true;
        } else {
          fileTypeOrigin = type;
        }
      }
      if (wrongFormat) {
        setErrorMessage({
          error: true,
          format: true,
          heavy: false,
        });
      } else if (size > filesSizeLimit) {
        setErrorMessage({
          error: true,
          format: false,
          heavy: true,
        });
      } else {
        const isImage = allowedPicTypes.includes(fileTypeOrigin);
        const urlPic = 'pictures/upload-hcp-document-pic';
        const urlVideo = 'video/uploadHcpVideoDocument';
        const response: {
          attachmentPic: any[];
          attachmentVideo: any[];
          isLoadingErr: boolean;
        } = await uploadFiles([newFile], urlPic, urlVideo, token, allowedPicTypes);
        if (!response.isLoadingErr) {
          const newUploadFiles = [...uploadFileRaw];
          newUploadFiles[ind].file = newFile;
          newUploadFiles[ind].name = fileNameOrigin;
          newUploadFiles[ind].type = fileTypeOrigin;
          newUploadFiles[ind].isError = false;
          newUploadFiles[ind].uuid = isImage
            ? response.attachmentPic[0].picUuid
            : response.attachmentVideo[0].videoName;
          newUploadFiles[ind].thumbnail = isImage ? '' : response.attachmentVideo[0].thumbnailName;
          setFileSizes(size);
          setUploadFileRaw(newUploadFiles);
        } else {
          toast.error(uploading_file_error);
        }
      }
      setLoading(() => false);
    } catch (error) {
      setLoading(() => false);
      toast.error(uploading_file_error);
    }
  };

  // Delete files from AWS and local state
  const deleteAttachment = async (
    ind: number,
    uploadFileRawArg: UploadFileType[],
  ): Promise<boolean> => {
    const isImage = allowedPicTypes.includes(uploadFileRawArg[ind].type);
    const removeFromAws = isImage ? _removeFile : _removeVideo;
    const keyField = isImage ? 'pictureUuidKey' : 'videoKey';
    try {
      const currentUuid = uploadFileRawArg[ind].uuid;
      if (!currentUuid) return true;
      await removeFromAws({
        variables: {
          [keyField]: currentUuid,
        },
      });
      return true;
    } catch (error) {
      toast.error(file_was_not_deleted);
      return false;
    }
  };

  // Delete from AWS all attachments
  const deleteAllAttachments = async (uploadedItems: UploadFileType[]): Promise<void> => {
    const uuIds = uploadedItems.map((item: UploadFileType) => item.uuid);
    let newUpload = [...uploadedItems];

    const deleteFromAws = async (arr: string[]): Promise<void> => {
      async function* asyncGenerator(): AsyncGenerator<number, void, unknown> {
        let j = 0;
        while (j < arr.length) {
          yield (j += 1);
        }
      }
      // eslint-disable-next-line no-restricted-syntax
      for await (const j of asyncGenerator()) {
        const i = j - 1;
        const res = await deleteAttachment(i, uploadedItems);
        if (res) {
          const filtredArr = newUpload.filter((item: any) => arr[i] !== item.uuid);
          newUpload = [...filtredArr];
        }
      }
      if (newUpload.length === 0) {
        setUploadFileRaw([...deepCopyObj(initUploadFile)]);
        return;
      }
      setUploadFileRaw(newUpload);
    };
    await deleteFromAws(uuIds);
  };

  // Delete from AWS one attachment
  const deleteOneAttachment = async (i: number, uploadedItems: UploadFileType[]): Promise<void> => {
    const res = await deleteAttachment(i, uploadedItems);
    if (res) {
      const filtredArr = uploadedItems.filter((item: any) => uploadedItems[i].uuid !== item.uuid);
      if (filtredArr.length === 0) {
        setUploadFileRaw([...deepCopyObj(initUploadFile)]);
        return;
      }
      setUploadFileRaw(filtredArr);
    }
  };

  // Move selected and uploaded to AWS files to local state "Patient information component"
  const onSubmit = (): void => {
    const newUploadFile = [...uploadFile, ...uploadFileRaw];
    setUploadFile(newUploadFile);
    setUploadFileRaw([...deepCopyObj(initUploadFile)]);
    setOpenForm('');
  };

  // Cancel add files
  const onCancel = (): void => {
    if (uploadFileRaw[0].file || uploadFileRaw[0].description) {
      setExitWarn(() => true);
      return;
    }
    setOpenForm('');
  };

  // Add new upload empty section
  const addNewItem = (): void => {
    setUploadFileRaw([...uploadFileRaw, ...deepCopyObj(initUploadFile)]);
  };

  // Confirm exit from upload files
  const onConfirmExit = async (): Promise<void> => {
    setExitWarn(() => false);
    await deleteAllAttachments(uploadFileRaw);
    setOpenForm('');
  };

  // Clouse Exit warning popup
  const onCloseExitWarn = (): void => {
    setExitWarn(() => false);
  };
  const loadingJSX = (deleteFromAwsLoading || deleteVideoFromAwsLoading) && <Loading />;

  return (
    <>
      {loadingJSX}
      <MainTitle title={upload_files} />
      {uploadFileRaw.map((section: UploadFileType, ind: number) => (
        <div key={`uploadFile_section${String(ind)}`}>
          <div className={style['add-patient-info__loader-container']}>
            {/* { spinner} */}
            {!section.file && loading && <SpinnerWithContent content={file_size_norm} />}
          </div>
          {section.file && (
            <Attachment
              id={`Att_uploadFile${ind}`}
              title={section.name}
              index={ind}
              deleteAttachments={(): Promise<void> => deleteOneAttachment(ind, uploadFileRaw)}
            />
          )}
          {!section.file && !loading && (
            <InputTypeFile
              id={`inputFile${String(ind)}`}
              inputName="file"
              labelName={`${attachment} (${allowedFileLabel})`}
              hasErrors={section.isError && upload_your_attachment}
              onChecngeMethod={(e: any): Promise<void> => onCheckIncomingFiles(e, ind)}
              placeholder={add_file}
              fileInputKey={fileInputKey}
              accept={allowedFileTypes}
              isTouched
            />
          )}

          <TextareaFeildFloatLabel
            id={`uploadFileDescr${ind}`}
            inputName={`description${ind}`}
            placeholder={upload_file_descr_label}
            inputValue={section.description}
            hasErrors={false}
            isTouched={false}
            onChecngeMethod={(e: any): void => {
              onChangeArea(e, ind, uploadFileRaw, setUploadFileRaw);
            }}
            rows={3}
          />
        </div>
      ))}
      {/* ADD NEW ITEM ICON */}
      {uploadFileRaw[uploadFileRaw.length - 1].file && (
        <div className={style['margin-bottom']}>
          <PlusNewItem description={add_more_attachments} buttonMethod={addNewItem} />
        </div>
      )}
      {/* Buttons */}
      <div className={style['add-patient-info__btn-cont']}>
        <Button
          buttonClass={style['add-patient-info__btn']}
          buttonType="button"
          buttonName={cancel}
          buttonMethod={onCancel}
          disabledButton={loading || !!loadingJSX}
        />
        <Button
          buttonClass={style['add-patient-info__btn']}
          buttonType="button"
          buttonName={ok}
          buttonMethod={onSubmit}
          colorStyle="blue"
          disabledButton={!uploadFileRaw[0].uuid || loading || !!loadingJSX}
        />
      </div>
      {/* Pop up */}
      {errorMessage.error && (
        <Popup
          content=""
          title={errorMessage.heavy ? large_file_norm : wrong_format}
          buttonName={ok}
          onClosePopup={(): void => setErrorMessage(deepCopyObj(initErr))}
        />
      )}

      {/* Warning popup if has not saved changes */}
      {isExitWarn && (
        <PopupWithTwoButtons
          title={warning_unsaved_changes}
          content=""
          confirmButtonName={exit}
          closeButtonName={cancel}
          onConfirm={onConfirmExit}
          onClosePopup={onCloseExitWarn}
        />
      )}
    </>
  );
};

export default UploadFile;
