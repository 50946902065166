import { NotifToMeDataType } from '../../components/EmailNotifications/types';

// Type
const FILL_NOTIF_TO_ME_DATA = 'FILL_NOTIF_TO_ME_DATA';
const SET_NOTIF_TO_ME_LOADING = 'SET_NOTIF_TO_ME_LOADING';
const SET_NOTIF_TO_ME_ERROR = 'SET_NOTIF_TO_ME_ERROR';
const FILL_SETTING_DATA = 'FILL_SETTING_DATA';

// TS
export interface EmailNotifStoreType {
  notifToMeData: undefined | any; // NotifToMeDataType;
  notifToMeLoading: false;
  notifToMeError: undefined | any;
  notifToMyPatientdData: any;
  notifToMyPatientLoading: false;
  notifToMyPatientError: undefined | any;
  openSettingData: any;
}
interface LoadingType {
  type: string;
  payload: boolean;
}
interface ErrorType {
  type: string;
  payload: any;
}
interface NotifToMeDataPayType {
  type: string;
  payload: any; // NotifToMeDataType | undefined;
}
interface SettingType {
  type: string;
  payload: any | undefined;
}
type ActionTypes = ErrorType | LoadingType | NotifToMeDataPayType | SettingType;

// Action
export const setNotifToMeData = (payload: any): NotifToMeDataPayType => ({
  type: FILL_NOTIF_TO_ME_DATA,
  payload,
});
export const setNotifToMeLoading = (payload: boolean): LoadingType => ({
  type: SET_NOTIF_TO_ME_LOADING,
  payload,
});
export const setNotifToMeError = (payload: any): ErrorType => ({
  type: SET_NOTIF_TO_ME_ERROR,
  payload,
});
export const setOpenSettingsData = (payload: any | null): SettingType => ({
  type: FILL_SETTING_DATA,
  payload,
});

// Initial comon STATE
const init: EmailNotifStoreType = {
  notifToMeData: undefined,
  notifToMeLoading: false,
  notifToMeError: undefined,
  notifToMyPatientdData: null,
  notifToMyPatientLoading: false,
  notifToMyPatientError: undefined,
  openSettingData: undefined,
};

// Reducer
export const emailNotifReducer = (
  state = init,
  { type, payload }: ActionTypes,
): EmailNotifStoreType => {
  switch (type) {
    case FILL_NOTIF_TO_ME_DATA:
      return {
        ...state,
        notifToMeData: payload,
      };
    case SET_NOTIF_TO_ME_LOADING:
      return {
        ...state,
        notifToMeLoading: payload,
      };
    case SET_NOTIF_TO_ME_ERROR:
      return {
        ...state,
        notifToMeError: payload,
      };
    case FILL_SETTING_DATA:
      return {
        ...state,
        openSettingData: payload,
      };
    default:
      return state;
  }
};
