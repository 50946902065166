import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import cx from 'classnames';
import { toast } from 'react-toastify';
import { Tooltip } from 'antd';
import moment from 'moment';

import styles from './styles.module.css';
import { GetState, getCurrenLang, getCurrentFormatDate } from '../../../../../redux/selector';
import { Modal, WarningNotification } from '../../../../../common/index';
import {
  getExercisesCategories,
  checkFocusBodyChanges,
  collectUuids,
} from '../../../../../utils/helper';
import { DataVideoExercises, InitialValuesForm } from '../../../VideoExercise/types';
import {
  validationExercise,
  validationFocusCheckboxes,
} from '../../../../../utils/validators/index';
import {
  useDeleteExerciseFromTeamVideobank,
  useQueryThumbnail,
  useQueryVideo,
  useSaveHcpExercise,
  useQueryPhotoPic,
  useSaveHcpExerciseInFolder,
} from '../../../../../graphql/videoBank';
import { Loading } from '../../../../../common/Loader';
import { EXERCISE_TYPE, PERMISSIONS, VIDEO_BANK_TYPE } from '../../../../../utils/enums';
import { initialValues } from '../../../VideoExercise/initForm';
import { ExerciseFileType } from '../../../../../utils/model';
import useCheckPermissions from '../../../../../hooks/useCheckPermissions';
import { CustomCheckbox, InputFloatLabel } from '../../../../../common/Input';
import { PhotoSliderModal, VideoModal } from '../../../../../common/VideoModal';
import { CloseBtn } from '../../../../../common/Button/Button';
import SettingsForm from '../SettingsForm';
import { OrgVideoExerciseProp } from '../../types';
import { ShowMediaIcon, ThumbnailJsx } from '../../../../../common/ShowMediaIcon';

const VideoExercise = ({
  data,
  selectExercise = [],
  setSelectExercise,
  index,
  exercisesCategories,
  exercisesProcedureFocus,
  focusType1Arr,
  focusType2Arr,
  updateList,
  isRecentlyDeleted,
  recoverExercise,
  folderId,
  type,
}: OrgVideoExerciseProp): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const yes = t?.common.yes;
  const no = t?.common.no;
  const cancel = t?.common.cancel;
  const uploading = t?.common.uploading;
  const delete_text = t?.common.delete;
  const recover_t = t?.common.recover;
  const settings_t = t?.dashboard.hcp.profile_patient.video_bank.settings;
  const createExercise = t?.notifications.created_exercise;
  const updateExercise = t?.notifications.updated_exercise;
  const deletedExercise = t?.notifications.deleted_exercise;
  const cont_confirm_modal_team = t?.notifications.popup_warning_team_video_bank_settings;
  const exercise_delete_warning = t?.notifications.exercise_delete_warning;
  const you_dont_have_permission = t?.common.you_dont_have_permission;
  const loadingErrorMsg = t?.notifications.loading_error;

  // Endpoints
  // Get image from AWS by Uuid
  const { _getThumbnailByName, thumbnail } = useQueryThumbnail();

  // get photo picture from AWS by Uuid
  const { _getPhotoPic, photoPic } = useQueryPhotoPic();

  // get video from AWS by uuid
  const { _getVideoByName, videoSrc, errorVideo } = useQueryVideo();

  // save exercise by HCP to Team video bank
  const {
    _saveHcpExercise,
    savedOrgVideoExercise,
    errorSaveExercise,
    loadingSaveExercise,
  } = useSaveHcpExercise();

  // save exercise by HCP from folder to Team video bank
  const {
    _saveHcpExerciseInFolderTeam,
    savedExerciseForTeamInFolder,
    loadingSaveExerciseInFolder,
    errorSaveExerciseInFolder,
  } = useSaveHcpExerciseInFolder();

  // Delete exercise from Organisation Videobank
  const {
    _deleteExerciseFromTeamVideoBank,
    deleteFromTeamData,
    deleteFromTeamError,
    deleteFromTeamLoading,
  } = useDeleteExerciseFromTeamVideobank();

  // Local state
  const [isUploadingToAWS, setIsUploadingToAWS] = useState(false);
  const [isSettingsOpen, setOpenSettings] = useState(false);
  const [isVideoModalOpen, setVideoModal] = useState(false);
  const [isPhotoModalOpen, setPhotoModal] = useState(false);
  const [videoSrcState, setVideoSrcState] = useState<any>('');
  const [isWarningModalOpen, setWarningModal] = useState(false);
  const [isConfirmModalOpen, setConfirmModal] = useState(false);
  const [currentRequest, setRequest] = useState<any>(null);

  const formatsDate = useSelector((state: GetState) => getCurrentFormatDate(state));
  const exerciseFileType: ExerciseFileType = data.type;
  const { updatedByHcp, exerciseUpdatedAt } = data;
  const isPhotosExist = !!data.exercisePhotos?.length;
  const exercisePhotos = isPhotosExist ? data.exercisePhotos : [];
  const isPermissionEditVideoExerc = useCheckPermissions(
    PERMISSIONS.EDIT_OR_PRESCRIBE_EXERCISE_VIDEOS_OTM,
  );
  const initialValuesForm: InitialValuesForm = initialValues(
    data,
    exercisesCategories,
    exercisesProcedureFocus,
    t,
    focusType1Arr,
    focusType2Arr,
  );

  // Send new OrganisationVideoBank data to DB
  const onSend = (req: DataVideoExercises): void => {
    _saveHcpExercise(req);
    setRequest(null);
    setConfirmModal(() => false);
  };

  const formik = useFormik({
    initialValues: initialValuesForm,
    validate: (values) => validationFocusCheckboxes(values, t),
    validationSchema: () => validationExercise(t),
    onSubmit: async (values: InitialValuesForm) => {
      const request: DataVideoExercises = {
        isVideoOwnerHcp: false,
        exerciseCategoriesId: getExercisesCategories(values, exercisesCategories, 'focus'),
        exerciseProcedureTypesId: getExercisesCategories(
          values,
          exercisesProcedureFocus,
          'procedure',
        ),
        exerciseData: {
          id: values.exerciseId,
          name: values.name,
          thumbnailName: values.thumbnailName,
          videoName: values.videoName,
          videobankId: values.videobankId,
          sets: Number(values.sets) || null,
          reps: values.dim === 'time' ? null : Number(values.reps) || null,
          time:
            values.dim === 'time'
              ? Number(values.seconds.value) + Number(values.minutes.value) * 60
              : null,
          timesPerDay: Number(values.timesDay),
          restDays: Number(values.restDaysWeek) || null,
          comment: values.comments,
          videoUploaded: values.exerciseVideoUploaded,
          thumbnailUploaded: values.exerciseThumbnailUploaded,
        },
      };

      // Organisation video bank
      if (type === VIDEO_BANK_TYPE.TEAM_VIDEO_BANK) {
        if (!checkFocusBodyChanges(request, data.exerciseProcedureTypes)) {
          setConfirmModal(() => true);
          setRequest(request);
          return;
        }
        onSend(request);
      }

      // Organisation video bank exercise from folder
      if (type === VIDEO_BANK_TYPE.TEAM_VIDEO_BANK_FOLDER) {
        request.folderId = folderId;
        request.photos = isPhotosExist ? collectUuids(exercisePhotos) : null;
        _saveHcpExerciseInFolderTeam(request);
      }
    },
  });

  const { values } = formik;

  useEffect(() => {
    if (data.exerciseId) {
      formik.setFieldValue('exerciseId', data.exerciseId);
    }
  }, [data.exerciseId]);

  /* After the exercise video is saved to team video bank,
    corresponding message is displayed and clouse settigs options */
  useEffect(() => {
    if (!String(savedOrgVideoExercise).includes('true') && savedOrgVideoExercise) {
      toast.info(`${values.name} ${createExercise}`);
      updateList();
    }
    if (String(savedOrgVideoExercise).includes('true') && savedOrgVideoExercise) {
      toast.info(`${values.name} ${updateExercise}`);
      updateList();
    }
    if (!errorSaveExercise && savedOrgVideoExercise) {
      setOpenSettings(() => false);
    }
  }, [savedOrgVideoExercise]);

  /* After the exercise video is saved to team video bank IN FOLDER,
    corresponding message is displayed and close settigs options */
  useEffect(() => {
    if (!String(savedExerciseForTeamInFolder).includes('true') && savedExerciseForTeamInFolder) {
      toast.info(`${values.name} ${createExercise}`);
      // values.exerciseId = Number(savedExerciseForTeamInFolder);
      updateList();
    }
    if (String(savedExerciseForTeamInFolder).includes('true') && savedExerciseForTeamInFolder) {
      toast.info(`${values.name} ${updateExercise}`);
      updateList();
    }
    if (!errorSaveExerciseInFolder && savedExerciseForTeamInFolder) {
      setOpenSettings(() => false);
    }
  }, [savedExerciseForTeamInFolder]);

  // Loading Thumbnail
  useEffect(() => {
    if (data.thumbnailName) {
      _getThumbnailByName({
        variables: {
          thumbnailKey: data.thumbnailName,
        },
      });
      return;
    }
    if (isPhotosExist) {
      _getPhotoPic({
        variables: {
          pictureUuid: exercisePhotos[0].fileUuid,
        },
      });
      return;
    }
    if (!data.thumbnailName && !data.videoName && !data.exerciseVideoUploaded && !isPhotosExist) {
      setIsUploadingToAWS(() => true);
    } else {
      setIsUploadingToAWS(() => false);
    }
  }, [data]);

  // Open video modal window
  useEffect(() => {
    if (videoSrc) {
      setVideoSrcState(videoSrc);
      setVideoModal(() => true);
    }
  }, [videoSrc]);

  useEffect(() => {
    if (errorVideo) {
      toast.error(errorVideo);
    }
  }, [errorVideo]);

  /* After success deleted exercise corresponding message is displayed */
  useEffect(() => {
    if (deleteFromTeamData) {
      toast.info(`${values.name} ${deletedExercise}`);
      updateList();
    }
  }, [deleteFromTeamData]);

  // Video modal window handler
  const showMedia = (): void => {
    switch (exerciseFileType) {
      case EXERCISE_TYPE.VIDEO:
        if (data.videoName) {
          _getVideoByName({
            variables: {
              videoKey: data.videoName,
            },
          });
          // setModal(() => !isVideoModalOpen);
        }
        return;
      case EXERCISE_TYPE.PHOTO:
        if (photoPic) {
          setPhotoModal(() => !isPhotoModalOpen);
        }
        return;
      default:
        setPhotoModal(() => false);
    }
  };

  // Select current exercise for Team videobank
  const checkboxHandler = (e: any, id: number): void => {
    const { checked } = e.target;
    const newVal = [...selectExercise];
    if (checked) {
      newVal.push(id);
      setSelectExercise([id, ...selectExercise]);
    } else {
      const filtred = selectExercise.filter((itemId: number) => itemId !== id);
      setSelectExercise([...filtred]);
    }
  };

  // Close opened modal windows
  const onCloseModal = (): void => {
    setVideoModal(() => false);
    setPhotoModal(() => false);
    setWarningModal(() => false);
    setConfirmModal(() => false);
    setRequest(null);
  };

  // Delete exercises
  const deleteHandler = (): void => {
    _deleteExerciseFromTeamVideoBank({
      variables: {
        videobankId: values.videobankId,
      },
    });
  };

  const isNotValidExercise = (!thumbnail || !data.videoName) && !isPhotosExist;

  // Open settings or recover exercise
  const actionBtnHandler = () => {
    if (!isPermissionEditVideoExerc) {
      return null;
    }
    if (isRecentlyDeleted) {
      recoverExercise(values.videobankId);
    }
    return setOpenSettings(() => !isSettingsOpen);
  };

  // JSX
  const loadingJSX = (loadingSaveExercise ||
    deleteFromTeamLoading ||
    loadingSaveExerciseInFolder) && <Loading />;

  return (
    <Tooltip title={isNotValidExercise ? loadingErrorMsg : ''} color="#F95428">
      <div>
        <div
          className={cx({
            [styles['video-exercise__row']]: true,
            [styles['video-exercise__row-disable']]: isNotValidExercise || isRecentlyDeleted,
          })}
        >
          {loadingJSX}
          <div className={styles['video-exercise__checkbox-container']}>
            <div className={styles['video-exercise__checkbox']}>
              <CustomCheckbox
                htmlId={`CustomCheckbox${String(values.videobankId)}`}
                name={values.name}
                checked={selectExercise.includes(values.videobankId)}
                onChangeMethod={(e: any): void => checkboxHandler(e, values.videobankId)}
                isTouched={false}
                hasErrors={false}
                disabled={isRecentlyDeleted}
                bigSize
              />
            </div>
          </div>

          {/* Thumbnail */}
          <div className={styles['video-exercise__block']}>
            {!isUploadingToAWS && (
              <div
                className={cx({
                  [styles.preview]: true,
                  [styles['preview-not-allowed']]:
                    (!thumbnail || !data.videoName) && !isPhotosExist,
                })}
                role="presentation"
                onClick={showMedia}
              >
                <ThumbnailJsx thumbLink={thumbnail} photoPic={photoPic} />
                <ShowMediaIcon thumbLink={thumbnail} photoPic={photoPic} />
              </div>
            )}
            {isUploadingToAWS && <div className={styles.isUploading}>{uploading}...</div>}
          </div>

          {/* Name */}
          <div className={styles['video-exercise__name-container']}>
            {/* Full screen */}
            <div className={styles['video-exercise__name']}>{values.name}</div>
            {/* Sm screen */}
            <div className={styles['video-exercise__action--sm']}>
              <Tooltip title={!isPermissionEditVideoExerc ? you_dont_have_permission : ''}>
                <div
                  className={cx({
                    [styles['video-exercise__btn--settings']]: true,
                    [styles['video-exercise__btn--disabled']]: !isPermissionEditVideoExerc,
                  })}
                  onClick={actionBtnHandler}
                  aria-hidden
                >
                  {isRecentlyDeleted ? recover_t : settings_t}
                </div>
              </Tooltip>
            </div>
          </div>

          {/* Setting last changed */}
          <div className={styles['video-exercise__date-container']}>
            {updatedByHcp && (
              <>
                <div className={styles['video-exercise__date']}>
                  {moment(exerciseUpdatedAt).format(formatsDate.momentFormat)}
                </div>
                <div className={styles['video-exercise__date-author']}>{updatedByHcp}</div>
              </>
            )}
          </div>

          {/* Action */}
          <div className={styles['video-exercise__action']}>
            <Tooltip title={!isPermissionEditVideoExerc ? you_dont_have_permission : ''}>
              <div
                className={cx({
                  [styles['video-exercise__btn--settings']]: true,
                  [styles['video-exercise__btn--settings-open']]: isSettingsOpen,
                  [styles['video-exercise__btn--disabled']]: !isPermissionEditVideoExerc,
                  [styles['video-exercise__btn--deleted']]: isRecentlyDeleted,
                })}
                aria-hidden
                onClick={actionBtnHandler}
              >
                {isRecentlyDeleted ? recover_t : settings_t}
              </div>
            </Tooltip>
          </div>
        </div>

        {/* Settings */}
        {isSettingsOpen && (
          <div className={styles['form-wrapper']}>
            <SettingsForm
              formik={formik}
              index={index}
              isPhotosExist={isPhotosExist}
              exercisePhotos={exercisePhotos}
              closeSettings={setOpenSettings}
              setWarningModal={setWarningModal}
              focusType2Arr={focusType2Arr}
              exercisesCategories={exercisesCategories}
              focusType1Arr={focusType1Arr}
              exercisesProcedureFocus={exercisesProcedureFocus}
              loading={!!loadingJSX}
              errorSaveExercise={errorSaveExercise}
              errorSaveExerciseInFolder={errorSaveExerciseInFolder}
              deleteFromTeamError={deleteFromTeamError}
            />
          </div>
        )}

        {/* Popups */}
        {isVideoModalOpen && <VideoModal videoSrc={videoSrcState} onclose={onCloseModal} />}
        {isPhotoModalOpen && (
          <PhotoSliderModal
            exercisePhotos={isPhotosExist ? exercisePhotos : []}
            photoPreviews={data.photoPreviews ? data.photoPreviews : []}
            onclose={onCloseModal}
          />
        )}

        {/* Delete exercise from organisation videobank */}
        {isWarningModalOpen && (
          <Modal onClose={onCloseModal}>
            <CloseBtn close={onCloseModal} />
            <WarningNotification
              title=""
              contant={exercise_delete_warning}
              onCancel={onCloseModal}
              onSubmit={deleteHandler}
              buttonName={delete_text}
              cancelButtonName={cancel}
            />
          </Modal>
        )}

        {isConfirmModalOpen && (
          <Modal onClose={onCloseModal}>
            <CloseBtn close={onCloseModal} />
            <WarningNotification
              title=""
              contant={cont_confirm_modal_team}
              onCancel={onCloseModal}
              onSubmit={(): void => onSend(currentRequest)}
              buttonName={yes}
              cancelButtonName={no}
            />
          </Modal>
        )}
      </div>
    </Tooltip>
  );
};

export default VideoExercise;
