import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';

import pageStyles from '../../../styles.module.css';
import { getCurrenLang } from '../../../../redux/selector';
import { BreadcrumbAnt } from '../../../../utils/routers/Breadcrumb';
import { MainTitle } from '../../../../common';
import { InfoIcon } from '../../../../theme/icons';
import { path } from '../../../../utils';
import useCheckRole from '../../../../hooks/useCheckRole';
import UploadPatientInformation from '../../../../components/OrganisationPatientInformation/components/UploadPatientInformation';

const UploadPatientInformationPage = (): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const patient_information = t?.hcp.org_patient_info?.org_patient_info;
  const upload_patient_information = t?.hcp.manage_patient_information?.upload_patient_information;
  const patient_information_tip_one = t?.hcp.org_patient_info?.patient_information_tip_one;
  const patient_information_tip_two = t?.hcp.org_patient_info?.patient_information_tip_two;
  const patient_information_tip_three = t?.hcp.org_patient_info?.patient_information_tip_three;
  const { isAdmin } = useCheckRole();
  const infoContentJsx = (
    <>
      <div className={pageStyles['main__title-tip']}>{patient_information_tip_one}</div>
      <div className={pageStyles['main__title-tip']}>{patient_information_tip_two}</div>
      <div className={pageStyles['main__title-tip']}>{patient_information_tip_three}</div>
    </>
  );

  // Breadcrumb path
  const routes = [
    {
      path: isAdmin
        ? path.adminOrganisationPatientInformation
        : path.organisationPatientInformation,
      breadcrumbName: patient_information,
    },
    {
      path: upload_patient_information,
      breadcrumbName: upload_patient_information,
    },
  ];

  return (
    <div className={pageStyles.main__container}>
      <BreadcrumbAnt currentRoutes={routes} />
      <div className={pageStyles.main__wrapper}>
        <MainTitle
          title={upload_patient_information}
          icon={<InfoIcon />}
          infoContent={infoContentJsx}
        />
        <UploadPatientInformation />
      </div>
    </div>
  );
};

export default UploadPatientInformationPage;
