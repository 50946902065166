import { useLazyQuery } from '@apollo/react-hooks';
import { ExecutionResult } from 'graphql';
import { loader } from 'graphql.macro';
import { useMutationRequest } from '../../hooks/feature/useMutationRequest';
import { UseAcceptJoin, UseDeactivetePatien } from '../types';

// Query
const gqlHcpList = loader('./gql/queryGetHcpConnectionsForPatient.graphql');
const gqlqueryGetListHcp = loader('./gql/queryGetListHcp.graphql');
const gqlSearchingResultsPatient = loader('./gql/queryGetSearchingResultPatient.graphql');
const gqlSearchingResultsHcp = loader('./gql/queryGetSearchingResultHcp.graphql');

// Mutation
const gqlAcceptJoinToShare = loader('./gql/mutationAcceptJoinToShare.graphql');
const mutationDeletePatients = loader('./gql/mutationDeletePatients.graphql');
const changeAcceptPatientInvitation = loader('./gql/mutationChangeAcceptPatientInvitations.graphql');
const changeConnectionByHcp = loader('./gql/mutationChangeConnectionByHcp.graphql');

// Patients
export const useAcceptJoin = (): UseAcceptJoin => {
  const [_onAcceptJoin, { loading, errorText, data }] = useMutationRequest<{
    acceptJoinToShare: boolean;
  }>(gqlAcceptJoinToShare, {});

  return {
    _onAcceptJoin,
    loadingAcceptJoin: loading,
    errorAcceptJoin: errorText,
    responseAcceptJoin: data,
  };
};

export const useDeactivetePatien = (): UseDeactivetePatien => {
  const [_onDeactivete, { loading, errorText, data }] = useMutationRequest<{
    deletePatient: boolean;
  }>(mutationDeletePatients, {});

  const _onDeactivetePatient = (params: any): Promise<ExecutionResult<{
    deletePatient: boolean;
}>> => _onDeactivete({
    variables: {
      patientId: params,
    },
  });

  return {
    _onDeactivetePatient,
    deactivetePatientLoading: loading,
    deactivetePatientError: errorText,
    seccessDeletedPatient: data && data.deletePatient,
  };
};

// QERY
export const useHcpListByPatient = (): any => {
  const [_getHcpListByPatient, { loading, error, data }] = useLazyQuery(gqlHcpList, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  });
  const dataList = data && data.getHcpConnectionsForPatient;
  if (dataList) {
    return {
      hcpListByPatient: dataList,
      _getHcpListByPatient,
      listError: error,
      hcpListLoading: loading,
    };
  }
  return {
    hcpListByPatient: [],
    _getHcpListByPatient,
    hcpListError: error,
    hcpListLoading: loading,
  };
};

// HCPs
export const useHcpList = () => {
  const [_getList, { loading, error, data }] = useLazyQuery(gqlqueryGetListHcp, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    // pollInterval: config.pollInterval,
  });
  const hcps = data && data.getHcpList;

  const hcpList = hcps ? hcps.list : [];
  const total = hcps && hcps.listLength;
  const totalPages = hcps && hcps.totalPages;
  const totalLength = hcps && hcps.totalItems;

  return {
    dataHcp: data,
    hcpList,
    total,
    totalPages,
    totalLength,
    _getList,
    hcpListError: error,
    hcpListLoading: loading,
  };
};

// Change Accept Patient Invitation
export const useChangeAcceptPatientInvitation = (): any => {
  const [
    _onChangeAcceptPatientInvitation,
    { data, errorText, loading },
  ] = useMutationRequest<{changeAcceptPatientInvitations: boolean}>(
    changeAcceptPatientInvitation, { fetchPolicy: 'no-cache' },
  );
  const _changeAcceptPatientInvitation = (checked: boolean): void => {
    _onChangeAcceptPatientInvitation({
      variables: {
        solution: checked,
      },
    });
  };

  return {
    _changeAcceptPatientInvitation,
    acceptPatientInvitationSet: data && data.changeAcceptPatientInvitations,
    acceptPatientInvitationError: errorText,
    acceptPatientInvitationLoading: loading,
  };
};

// Connect - disconnect - treatment completed patient from HCP
export const useChangePatientConnectByHcp = (): any => {
  const [
    _onChangePatientConnectByHcp,
    { data, errorText, loading },
  ] = useMutationRequest<{changeConnectionByHcp: boolean}>(
    changeConnectionByHcp, { fetchPolicy: 'no-cache' },
  );
  const _changePatientConnectByHcp = (
    newStatus: string,
    patientId: number,
    teamIds: number[] | null,
  ): void => {
    const teamIdsReq = teamIds?.length ? { teamIds } : {};
    _onChangePatientConnectByHcp({
      variables: {
        solution: newStatus,
        patientId,
        ...teamIdsReq,
      },
    });
  };

  return {
    _changePatientConnectByHcp,
    patientConnectChanged: data && data.changeConnectionByHcp,
    changePatientConnectError: errorText,
    changePatientConnectLoading: loading,
  };
};

// Number of patients in the database matching the search
export const useFindMatchesInPatientList = () => {
  const [
    _getMatchesPatientInDB,
    { loading, error, data }] = useLazyQuery(gqlSearchingResultsPatient,
    {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    });
  const dataList = data && data.getPatientsList;
  if (dataList) {
    const { totalItems } = dataList;
    return {
      totalPatients: totalItems,
      _getMatchesPatientInDB,
      findPatientError: error,
      findPatientLoading: loading,
    };
  }
  return {
    totalPatients: null,
    _getMatchesPatientInDB,
    findPatientError: error,
    findPatientLoading: loading,
  };
};

// Number of hcp in the database matching the search
export const useFindMatchesInHcpList = (): any => {
  const [_getMatchesHcpInDB, { loading, error, data }] = useLazyQuery(gqlSearchingResultsHcp, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  });
  const dataList = data && data.getHcpList;
  if (dataList) {
    const { totalItems } = dataList;
    return {
      totalHcps: totalItems,
      _getMatchesHcpInDB,
      findHcpError: error,
      findHcpLoading: loading,
    };
  }
  return {
    totalHcps: null,
    _getMatchesHcpInDB,
    findHcpError: error,
    findHcpLoading: loading,
  };
};
