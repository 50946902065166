import React, { ReactElement } from 'react';
import { useFormik } from 'formik';
import { HospitalName } from '../../components/Form/types';
import { Options } from '../../utils/model';

interface PropTypes {
  initialValues: object;
  schema?: object;
  onSend: (values: any, action: any) => void;
  component?: any;
  errorMessage: string;
  validate?: any;
  options?: Options[];
  hospitalNameOptions?: HospitalName[];
  professionOptions?: Options[];
  departmentOptions?: Options[];
  disabledField?: boolean;
  onCancel?: any;
  setHasChanges?: any;
}

export const FormFormik = ({
  initialValues,
  schema,
  onSend,
  validate,
  component: Component,
  errorMessage,
  setHasChanges,
  ...rest
}: PropTypes): ReactElement => {
  const formik: any = useFormik({
    initialValues,
    validationSchema: schema,
    validate,
    onSubmit: (values, action) => onSend(values, action),
  });
  const { values, errors, touched, isValid, dirty } = formik;

  if (setHasChanges) {
    setHasChanges(() => dirty);
  }
  return (
    <form onSubmit={formik.handleSubmit}>
      <Component
        errorMessage={errorMessage}
        onChangeMethod={formik.handleChange}
        setFieldValue={formik.setFieldValue}
        setValues={formik.setValues}
        getFieldProps={formik.getFieldProps}
        hasErrors={errors}
        inputValue={values}
        isValid={isValid}
        isTouched={touched}
        isDirty={dirty}
        {...rest}
      />
    </form>
  );
};
