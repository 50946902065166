import { PERIOD_FOR_CHART } from '../../utils/enums';

// Type
const FILL_SLEEP_DATA = 'FILL_SLEEP_DATA';
const SET_SLEEP_DATA_LOADING = 'SET_SLEEP_DATA_LOADING';
const SET_SLEEP_ERROR = 'SET_SLEEP_ERROR';
const FILL_HEART_RATE_DATA = 'FILL_HEART_RATE_DATA';
const SET_HEART_REATE_DATA_LOADING = 'SET_HEART_REATE_DATA_LOADING';
const SET_HEAT_RATE_ERROR = 'SET_HEAT_RATE_ERROR';

// TS
interface SleepDataActionType {
  type: typeof FILL_SLEEP_DATA;
  payload: SleepChartDataType[];
}
interface HeartRateDataActionType {
  type: typeof FILL_HEART_RATE_DATA;
  payload: HeartRateChartDataType[];
}
interface ErrorType {
  type: typeof SET_SLEEP_ERROR | typeof SET_HEAT_RATE_ERROR;
  payload: any;
}
interface LoadingType {
  type: typeof SET_SLEEP_DATA_LOADING | typeof SET_HEART_REATE_DATA_LOADING;
  payload: boolean;
}

type ActionTypes = LoadingType | ErrorType | SleepDataActionType | HeartRateDataActionType;

export interface HeartRateChartValuesType {
  name: string;
  maxHeartRate: number;
  minHeartRate: number;
  averageHeartRate: number;
  restingHeartRate: number;
}
export interface HeartRateChartDataType {
  graph: PERIOD_FOR_CHART;
  title: string;
  averageHeartRate: number;
  minHeartRate: number;
  maxHeartRate: number;
  restingHeartRate: number;
  values: HeartRateChartValuesType[] | [];
}

export interface SleepChartValuesType {
  name: string;
  sleepValue: number;
  sleepScore: number;
  sleepDurationInSeconds: number;
  startTimeInMinutes: number;
  endTimeInMinutes: number;
}
export interface SleepChartDataType {
  graph: PERIOD_FOR_CHART;
  title: string;
  description: string;
  values: SleepChartValuesType[] | [];
}

export interface BodyDataState {
  sleep: SleepChartDataType[] | [];
  sleepLoading: boolean;
  sleepError: any;

  heartRate: HeartRateChartDataType[] | [];
  heartRateLoading: boolean;
  heartRateError: any;
}

// Action
export const setPatientSleepData = (payload: SleepChartDataType[] | []): SleepDataActionType => ({
  type: FILL_SLEEP_DATA,
  payload,
});
export const loadingSleepData = (data: boolean): LoadingType => ({
  type: SET_SLEEP_DATA_LOADING,
  payload: data,
});
export const setSleepError = (payload: any): ErrorType => ({
  type: SET_SLEEP_ERROR,
  payload,
});

export const setHeartRateData = (data: HeartRateChartDataType[] | []): HeartRateDataActionType => ({
  type: FILL_HEART_RATE_DATA,
  payload: data,
});
export const setHeartRateLoading = (data: boolean): LoadingType => ({
  type: SET_HEART_REATE_DATA_LOADING,
  payload: data,
});
export const setHeartRateError = (data: any): ErrorType => ({
  type: SET_HEAT_RATE_ERROR,
  payload: data,
});

const init: BodyDataState = {
  sleep: [],
  sleepLoading: false,
  sleepError: '',

  heartRate: [],
  heartRateLoading: false,
  heartRateError: '',
};

// Reducer
export const bodyDataReduser = (state = init, { type, payload }: ActionTypes): BodyDataState => {
  switch (type) {
    case FILL_SLEEP_DATA:
      return {
        ...state,
        sleep: payload,
      };
    case SET_SLEEP_DATA_LOADING:
      return {
        ...state,
        sleepLoading: payload,
      };
    case SET_SLEEP_ERROR:
      return {
        ...state,
        sleepError: payload,
      };
    case FILL_HEART_RATE_DATA: {
      return {
        ...state,
        heartRate: payload,
      };
    }
    case SET_HEART_REATE_DATA_LOADING:
      return {
        ...state,
        heartRateLoading: payload,
      };
    case SET_HEAT_RATE_ERROR: {
      return {
        ...state,
        heartRateError: payload,
      };
    }
    default:
      return { ...state };
  }
};
