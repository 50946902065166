import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { Switch } from 'antd';

import styles from './styles.module.css';
import { GetState, getCurrenLang } from '../../../../redux/selector';
import { MediaSettingsProps } from '../../types';
import { InputFloatLabel, TextareaFeildFloatLabel } from '../../../../common/Input';
import { Button } from '../../../../common';
import { DeleteIcon } from '../../../../theme/icons';

const MediaSettings = ({
  formik,
  onCancel,
  loadingJSX,
  setWarningDeleteMediaModal,
}: MediaSettingsProps): ReactElement => {
  const t: any = useSelector<GetState>((state) => getCurrenLang(state));
  const on = t?.common.on;
  const off = t?.common.off;
  const cancel = t && t.common.cancel;
  const save = t && t.common.save;
  const delete_text = t?.common.delete;
  const patient_can_download_video = t?.hcp.manage_patient_information?.patient_can_download_video;
  const name = t?.hcp.manage_patient_information?.name;
  const comments = t?.dashboard.hcp.profile_patient.video_bank.comments;

  const { values, errors, touched } = formik;

  // Changing the Patient can download video
  const switchHandler = (checked: boolean): void => {
    formik.setFieldValue('isAllowDownloadVideos', checked);
  };

  return (
    <form onSubmit={formik.handleSubmit} className={styles['patient-info__settings-form']}>
      <div className={styles['patient-info__settings-wrapper']}>
        {/* Left */}
        <div className={styles['patient-info__settings-left']}>
          <InputFloatLabel
            inputId="name"
            inputName="name"
            inputType="text"
            hasErrors={errors.name}
            isTouched={touched.name}
            inputValue={values.name}
            onChangeMethod={formik.handleChange}
            placeholder={name}
            disabled={!!loadingJSX}
          />
          <TextareaFeildFloatLabel
            id="Description"
            inputName="description"
            placeholder={comments}
            inputValue={values.description}
            hasErrors={errors.description}
            isTouched={touched.description}
            onChecngeMethod={formik.handleChange}
            rows={3}
            disabled={!!loadingJSX}
          />
        </div>

        {/* Right */}
        <div className={styles['patient-info__settings-right']}>
          {/* Patient can download video */}
          <div className={styles['patient-info__switch-container']}>
            <div className={styles['patient-info__switch-descr']}>{patient_can_download_video}</div>
            <Switch
              checkedChildren={<span>{on}</span>}
              unCheckedChildren={<span>{off}</span>}
              checked={values.isAllowDownloadVideos}
              onChange={switchHandler}
              disabled={!!loadingJSX}
            />
          </div>
        </div>
      </div>

      {/* Buttons */}
      <div className={styles['patient-info__btns-container']}>
        <div
          className={cx({
            [styles['settings__btn-delete-wraper']]: true,
            // [styles.notAllowed]: !checkPermission(),
          })}
          role="presentation"
          onClick={(): void => setWarningDeleteMediaModal(() => true)}
        >
          <DeleteIcon />
          <span className={styles['settings__btn-delete-text']}>{delete_text}</span>
        </div>
        <Button
          buttonName={cancel}
          buttonType="button"
          buttonClass={cx({
            [styles['org-patient-info__btn']]: true,
          })}
          buttonMethod={onCancel}
          disabledButton={!!loadingJSX}
        />
        <Button
          buttonName={save}
          buttonType="submit"
          buttonClass={styles['org-patient-info__btn']}
          disabledButton={!formik.dirty || !!loadingJSX}
        />
      </div>
    </form>
  );
};

export default MediaSettings;
