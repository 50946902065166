import React, { ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import style from '../styles.module.css';
import { getCurrenLang, GetState } from '../../../redux/selector';
import { Button, MainTitle } from '../../../common';
import { PopupWithTwoButtons } from '../../../common/Popup';
import { PlusNewItem } from '../../../common/Button/Button';
import { ExternalContentProps, UrlProps } from '../types';

const ExternalContent = ({
  editorState,
  setEditorState,
  setOpenForm,
  editorStateRaw,
  setEditorStateRaw,
}: ExternalContentProps): ReactElement => {
  const t: any = useSelector<any>((state: GetState) => getCurrenLang(state));
  const ok = t?.notifications.ok;
  const cancel = t?.common.cancel;
  const warning_unsaved_changes = t?.common.warning_unsaved_changes;
  const exit = t?.common.exit;
  const include_link = t?.dashboard.hcp.profile_patient.include_link;
  const include_link_placeholder = t?.dashboard.hcp.profile_patient.include_link_placeholder;

  // Local state
  const [isExitWarn, setExitWarn] = useState(false);
  const [isEmptyEditor, setEmptyEditor] = useState(true);

  // Text editor handler
  const onEditorStateChange = (editorSt: any): void => {
    const hasText = editorSt.getCurrentContent().hasText();
    setEmptyEditor(!hasText);
    setEditorStateRaw(editorSt);
  };

  // Move created URL to local state "Patient information component"
  const onSubmit = (): void => {
    const newEditor = [...editorState, editorStateRaw];
    setEditorState(newEditor);
    setEditorStateRaw(EditorState.createEmpty());
    setOpenForm('');
  };

  // Cancel add files
  const onCancel = (): void => {
    if (!isEmptyEditor) {
      setExitWarn(() => true);
      return;
    }
    setOpenForm('');
  };

  // Confirm exit from upload files
  const onConfirmExit = async (): Promise<void> => {
    setExitWarn(() => false);
    setEditorStateRaw(EditorState.createEmpty());
    setOpenForm('');
  };

  // Clouse Exit warning popup
  const onCloseExitWarn = (): void => {
    setExitWarn(() => false);
  };

  return (
    <>
      <MainTitle title={include_link} />
      <div className={style['add-patient-info__editor']}>
        <Editor
          placeholder={include_link_placeholder}
          editorState={editorStateRaw}
          onEditorStateChange={onEditorStateChange}
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          toolbarClassName="toolbar-class"
        />
      </div>

      {/* Buttons */}
      <div className={style['add-patient-info__btn-cont']}>
        <Button
          buttonClass={style['add-patient-info__btn']}
          buttonType="button"
          buttonName={cancel}
          buttonMethod={onCancel}
        />
        <Button
          buttonClass={style['add-patient-info__btn']}
          buttonType="button"
          buttonName={ok}
          buttonMethod={onSubmit}
          colorStyle="blue"
          disabledButton={isEmptyEditor}
        />
      </div>

      {/* Warning popup if has not saved changes */}
      {isExitWarn && (
        <PopupWithTwoButtons
          title={warning_unsaved_changes}
          content=""
          confirmButtonName={exit}
          closeButtonName={cancel}
          onConfirm={onConfirmExit}
          onClosePopup={onCloseExitWarn}
        />
      )}
    </>
  );
};

export default ExternalContent;
