// @ts-nocheck
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import localLangReduser from './localization';
import { completedReducer } from './completions';
import { motivationReduser } from './motivations/index';
import { dropdownReduser } from './dropdown';
import { patientdReduser } from './patient';
import { globalNotificationsReduser } from './notifications';
import { commonReducer } from './common';
import { promsReduser } from './questionnaires';
import { rehabTeamsReducer } from './rehabTeams';
import { rewardsReducer } from './rewards';
import { emailNotifReducer } from './emailNotifications';
import { patientsInvitationReducer } from './patientInvitation';
import { bodyDataReduser } from './bodyData';

const middleware = [
  ...getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      serializableCheck: false,
    },
  }),
  logger,
];

const authPersistConfig = {
  key: 't',
  storage,
  whitelist: ['t'],
};

export const store = configureStore({
  reducer: {
    t: persistReducer(authPersistConfig, localLangReduser),
    common: commonReducer,
    completed: completedReducer,
    motivation: motivationReduser,
    selectedPeriod: dropdownReduser,
    patient: patientdReduser,
    notifications: globalNotificationsReduser,
    proms: promsReduser,
    rehabTeams: rehabTeamsReducer,
    rewards: rewardsReducer,
    emailNotification: emailNotifReducer,
    patientsInvitation: patientsInvitationReducer,
    bodyData: bodyDataReduser,
  },
  middleware,
  devTools: process.env.NODE_ENV === 'development',
});

export const persistor = persistStore(store);
