// Type
import {
  FILL_PATIENTS_STEPS,
  STEPS_LOADING,
  STEPS_ERROR,
  ActionTypes,
  StepsDataType,
  FILL_EXERCISES_PER_PERIOD,
  PatientErrorFetching,
  PatientFillStep,
  LoadingActionType,
  ExercisesType,
  FILL_EXERCISE_COMPLETION,
  EXERCISE_COMPLETION_LOADING,
  EXERCISE_COMPLETION_ERROR,
  ExercComplDataType,
  FillPatientExercCompl,
  EXERC_PER_PERIOD_LOADING,
  ExercPerPeriodType,
  SetPerPeriodDataAction,
  NOT_COMPLETION_EXERCISE_LOADING,
  NOT_COMPLETION_EXERCISE_ERROR,
  FILL_NOT_COMPLETION_EXERCISE,
  FillPatientNotComplExer,
  NotComplExercDataType,
} from './types';

export const fillSteps = (payload: ExercComplDataType[] | []): PatientFillStep => ({
  type: FILL_PATIENTS_STEPS,
  payload,
});

export const loadingSteps = (payload: boolean): LoadingActionType => ({
  type: STEPS_LOADING,
  payload,
});

export const setErrorSteps = (payload: any): PatientErrorFetching => ({
  type: STEPS_ERROR,
  payload,
});

export const fillExerciseCompletion = (payload: StepsDataType[] | []): FillPatientExercCompl => ({
  type: FILL_EXERCISE_COMPLETION,
  payload,
});

export const exerciseComplLoading = (payload: boolean): LoadingActionType => ({
  type: EXERCISE_COMPLETION_LOADING,
  payload,
});

export const setExerciseComplError = (payload: any): PatientErrorFetching => ({
  type: EXERCISE_COMPLETION_ERROR,
  payload,
});

export const fillExercisesPerPeriod = (payload: ExercPerPeriodType[]): SetPerPeriodDataAction => ({
  type: FILL_EXERCISES_PER_PERIOD,
  payload,
});

export const setExercisesPerPeriodLoading = (payload: boolean): LoadingActionType => ({
  type: EXERC_PER_PERIOD_LOADING,
  payload,
});

export const fillNotComplExerc = (payload: NotComplExercDataType[]): FillPatientNotComplExer => ({
  type: FILL_NOT_COMPLETION_EXERCISE,
  payload,
});

export const notComplExercLoading = (payload: boolean): LoadingActionType => ({
  type: NOT_COMPLETION_EXERCISE_LOADING,
  payload,
});

export const notComplExercError = (payload: any): PatientErrorFetching => ({
  type: NOT_COMPLETION_EXERCISE_ERROR,
  payload,
});

const init: ExercisesType = {
  steps: [],
  stepsLoading: false,
  stepsError: '',

  exerciseComletion: [],
  exerciseComletionLoading: false,
  exerciseComletionError: '',

  exercisesPerPeriod: [],
  exercisesPerPeriodLoading: false,

  notCompletedExercises: [],
  notCompletedExercisesLoading: false,
  notCompletedExercisesError: '',
};

// Reducer
export const completedReducer = (state = init, action: ActionTypes): ExercisesType => {
  switch (action.type) {
    case FILL_PATIENTS_STEPS:
      return {
        ...state,
        steps: action.payload,
      };
    case STEPS_LOADING:
      return {
        ...state,
        stepsLoading: action.payload,
      };
    case STEPS_ERROR:
      return {
        ...state,
        stepsError: action.payload,
      };
    case FILL_EXERCISE_COMPLETION:
      return {
        ...state,
        exerciseComletion: action.payload,
      };
    case EXERCISE_COMPLETION_LOADING:
      return {
        ...state,
        exerciseComletionLoading: action.payload,
      };
    case FILL_EXERCISES_PER_PERIOD:
      return {
        ...state,
        exercisesPerPeriod: action.payload,
      };
    case EXERC_PER_PERIOD_LOADING:
      return {
        ...state,
        exercisesPerPeriodLoading: action.payload,
      };
    case FILL_NOT_COMPLETION_EXERCISE:
      return {
        ...state,
        notCompletedExercises: action.payload,
      };
    case NOT_COMPLETION_EXERCISE_LOADING:
      return {
        ...state,
        notCompletedExercisesLoading: action.payload,
      };
    case NOT_COMPLETION_EXERCISE_ERROR:
      return {
        ...state,
        notCompletedExercisesError: action.payload,
      };

    default:
      return state;
  }
};
