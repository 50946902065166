/* eslint-disable default-case */
import React, { CSSProperties, ReactElement } from 'react';
import styles from './styles.module.css';
import { PATIENT_PROFILE_TABS, proceduresEnum } from '../../utils/enums';
import { TBar } from '../../components/Charts/PainChart/types';

export const LegendRecharts = ({ payload, type, t, procedureKey }: any): ReactElement => {
  const back = t?.dashboard.hcp.profile_patient.back?.toUpperCase() || 'Back'.toUpperCase();
  const leg = t?.dashboard.hcp.profile_patient.leg?.toUpperCase() || 'Leg'.toUpperCase();
  const legendPay =
    type === PATIENT_PROFILE_TABS.REASONS_FOR_NOT_COMPLETENG && payload
      ? payload.reverse()
      : payload;

  return (
    <div className={styles.legend__container}>
      {legendPay &&
        legendPay.map(
          (item: any, i: number): JSX.Element => {
            const markerStyle: CSSProperties = {};
            const legendNameStyle: CSSProperties = {};
            if (item?.value?.length < 21) legendNameStyle.whiteSpace = 'nowrap';
            let isLine = false;
            // const lineType = 'solid';
            const lineType =
              procedureKey === proceduresEnum.SPINAL && (i === 2 || i === 4) ? 'dotted' : 'solid';
            switch (type) {
              case PATIENT_PROFILE_TABS.MOTIVATION:
                if (i !== 0) {
                  isLine = true;
                  markerStyle.height = '3px';
                  markerStyle.marginTop = '6px';
                  markerStyle.marginTop = '6px';
                  markerStyle.width = '20px';
                  markerStyle.minWidth = '20px';
                  markerStyle.borderTop = `2px ${lineType} ${item.color}`;
                  markerStyle.height = 0;
                  markerStyle.backgroundColor = 'unset';
                }
                break;
              case PATIENT_PROFILE_TABS.REASONS_FOR_NOT_COMPLETENG:
                legendNameStyle.whiteSpace = 'nowrap';
                break;
              case PATIENT_PROFILE_TABS.PROMS:
                if (i !== 1 && i !== 2) {
                  legendNameStyle.whiteSpace = 'nowrap';
                }
                break;
              case PATIENT_PROFILE_TABS.BY_EXERCISES:
                // legendNameStyle.whiteSpace = 'nowrap';
                if (i === 5) {
                  markerStyle.border = `1px solid ${item.color}`;
                  markerStyle.backgroundColor = '#transparent';
                }
                break;
              case PATIENT_PROFILE_TABS.BY_PERIODS:
                // legendNameStyle.whiteSpace = 'nowrap';
                if (i === 4) {
                  markerStyle.border = `1px solid ${item.color}`;
                  markerStyle.backgroundColor = '#transparent';
                }
                if (i === 5) {
                  markerStyle.border = `1px solid ${item.color}`;
                  markerStyle.borderRadius = '50%';
                }
                break;
            }

            return (
              <div>
                {procedureKey === proceduresEnum.SPINAL && (
                  <div className={styles.legend__subtitle}>
                    {i === 1 && back}
                    {i === 3 && leg}
                  </div>
                )}
                <div className={styles.legend__valueBlock} key={`legendValue${item.value}`}>
                  <div
                    className={styles.legend__marker}
                    style={{ backgroundColor: item.color, ...markerStyle }}
                  >
                    {isLine && (
                      <div
                        className={styles['legend__marker--circle']}
                        style={{ border: `1px solid ${item.color}` }}
                      />
                    )}
                  </div>
                  <div className={styles.legend__name} style={legendNameStyle}>
                    {item.value}
                  </div>
                </div>
              </div>
            );
          },
        )}
    </div>
  );
};

export const LegendLineChart = ({ config }: { config: TBar[] }): any => {
  const legendJsx = config.map((typeChart: TBar, i: number) => {
    const lineType = typeChart.lineType ? 'dotted' : 'solid';
    return (
      <div key={`${typeChart.label}${String(i)}`} className={styles['line-chart__legend']}>
        <div
          className={styles['line-legend__marker']}
          style={{ borderTop: `2px ${lineType} ${typeChart.color}` }}
        >
          <div
            className={styles['line-legend__marker--circle']}
            style={{ border: `1px solid ${typeChart.color}` }}
          />
        </div>
        <div className={styles['line-legend__name']}>{typeChart.label}</div>
      </div>
    );
  });
  return legendJsx;
};

export const LegendBarChart = ({ config }: { config: { name: string; color: string }[] }): any => {
  return (
    <div className={styles['chart__exrc-compl-legend--wrapper']}>
      {config.map((legend: any, i: number) => (
        <div key={`${legend.label}_${String(i)}`} className={styles.chart__legend}>
          <div
            className={styles['legend__marker--sqr']}
            style={{ backgroundColor: legend.color }}
          />
          <div className={styles['bar-legend__name']}>{legend.name}</div>
        </div>
      ))}
    </div>
  );
};
