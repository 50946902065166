import React, { ReactElement, useMemo } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { ResponsiveContainer, BarChart, CartesianGrid, XAxis, YAxis, Bar, Tooltip } from 'recharts';

import { getCurrenLang, getNotComplExercLoading } from '../../../redux/selector';
import styles from '../styles.module.css';
import { spinner } from '../../../common/Loader';
import { TooltipRecharts } from '../../../common/TooltipRecharts';
import { PERIOD_FOR_CHART } from '../../../utils/enums';
import { TReasonBar } from './types';
import { NotComplExercValuesType } from '../../../redux/completions/types';
import { skipStopChartConfig } from '../../../utils/share/options';
import { LegendBarChart } from '../../../common/Legend';

const SkipStopChart = ({ skipStopExercData, type, isOverview }: any): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const no_data = t?.common.no_data;
  const number_of_exercises_skipped =
    t?.dashboard.hcp.profile_patient.completion_and_steps.number_of_exercises_skipped;
  const number_of_exercises_stopped =
    t?.dashboard.hcp.profile_patient.completion_and_steps.number_of_exercises_stopped;
  const number_of_reps = t?.dashboard.hcp.profile_patient.completion_and_steps.number_of_reps;
  const skipped_or_stopped =
    t?.dashboard.hcp.profile_patient.completion_and_steps.skipped_or_stopped;

  const loading = useSelector<any>((state) => getNotComplExercLoading(state));
  const windowClientWidth = useSelector((state: any) => state.common.screen.innerWidth);
  const chartData: any = skipStopExercData?.values || [];
  const chartDescription = skipStopExercData?.description;
  const axisName = skipStopExercData?.axis;
  const YAxisDomain = skipStopExercData?.yAxisDomain;
  const periodType = skipStopExercData?.graph;
  const barsConfig = skipStopChartConfig(t);
  const isLabelAngle =
    periodType === PERIOD_FOR_CHART.W2 ||
    periodType === PERIOD_FOR_CHART.M6 ||
    periodType === PERIOD_FOR_CHART.M3;
  const isAllLabels = periodType !== PERIOD_FOR_CHART.M6 && windowClientWidth > 500;

  const dataTransformert = useMemo(() => {
    if (chartData) {
      const dataCalc = chartData.map((value: NotComplExercValuesType) => {
        const { numberOfSkippedExercises, numberOfStoppedExercises, averageNumberOfReps } = value;

        const data = {
          name: value.name,
          [number_of_exercises_skipped]: numberOfSkippedExercises,
          [number_of_exercises_stopped]: numberOfStoppedExercises,
          [number_of_reps]: averageNumberOfReps,
        };
        return data;
      });
      return dataCalc;
    }
    return [];
  }, [chartData]);

  // Custom Tooltip
  const renderTooltip = (payload: any): JSX.Element => {
    const pay = payload.payload;
    return <TooltipRecharts payload={pay} type={type} t={t} />;
  };

  // JSX
  const noData = !loading && !dataTransformert.length && (
    <span className={styles.nodata}>{no_data}</span>
  );

  return (
    <div
      className={cx({
        [styles['chart-wrapper']]: true,
        [styles['padding-bottom']]: true,
        'chart-to-PDF': true,
      })}
    >
      {!isOverview && (
        <div>
          <div className={styles['chart-title']}>{skipped_or_stopped}</div>
          <div className={styles['margin-bottom']} />
          <div className={styles['chart__exrc-compl-legend--wrapper']}>
            <LegendBarChart config={barsConfig} />
          </div>
        </div>
      )}

      {isOverview && <div className={styles['chart-desciption']}>{chartDescription}</div>}

      <div className={styles['chart-container']}>
        <div className={styles['chart-label']}>{axisName}</div>
        {loading && spinner}
        {noData}
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={dataTransformert}
            margin={{
              top: 10,
              left: -20,
              right: isLabelAngle ? 7 : 0,
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="name"
              axisLine={false}
              tickLine={false}
              angle={isLabelAngle ? -45 : 0}
              // @ts-ignore
              style={{
                fontSize: isLabelAngle ? '11px' : '14px',
                fill: '#23335B',
              }}
              interval={isAllLabels ? 0 : 'preserveEnd'} // 0 - To show all ticks
            />
            <YAxis
              domain={YAxisDomain || [0, 10]}
              interval={0} // To show all ticks
              axisLine={false}
              // @ts-ignore
              style={{
                fontSize: '14px',
                fill: '#23335B',
              }}
            />
            {!noData && (
              <Tooltip
                content={renderTooltip}
                allowEscapeViewBox={{ x: false, y: false }}
                offset={windowClientWidth > 1000 ? 10 : 500}
              />
            )}
            {barsConfig.map((bar: TReasonBar) => (
              <Bar
                key={bar.name}
                strokeWidth={3}
                dataKey={bar.name}
                fill={bar.color}
                isAnimationActive
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default SkipStopChart;
