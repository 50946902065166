import React, { ReactElement, useEffect, useState } from 'react';
import { CaretDownFilled, CaretUpFilled, FilterFilled } from '@ant-design/icons';
import { Dropdown, Tooltip } from 'antd';
import { useSelector } from 'react-redux';

import styles from './styles.module.css';
import { sortStyles } from '../../../../../utils/variables';
import { GetState, getCurrenLang, getUserLang } from '../../../../../redux/selector';
import { Button } from '../../../../../common';
import { ActionItemType, SelectElemType } from '../../../types';
import { SearchMenuElem } from '../../../../../common/Input';
import { SORT_DIRECTION } from '../../../../../utils/enums';
import { setSortDirectionTitle } from '../../../../../utils/helper';
import { DatePickerMenuElem } from '../../../../../common/DatePicker';

export const SelectElem = ({
  selectElem,
  setSelectElem,
  openMethod,
  disabled,
  submitBtnName,
}: SelectElemType): ReactElement => {
  const t: any = useSelector<GetState>((state) => getCurrenLang(state));
  const clear = t?.common.clear;
  const select_t = t?.dashboard.hcp.profile_patient.select;
  const selected_t = t?.dashboard.hcp.profile_patient.selected;

  return (
    <div className={styles['select-elem__container']}>
      <div className={styles['select-elem__label']}>
        {selectElem.length ? `${selectElem.length} ${selected_t}` : select_t}
      </div>
      <div className={styles['select-elem__label--sm-screen']}>
        {selectElem.length ? `${selectElem.length} ${selected_t}` : ''}
      </div>
      <div className={styles['select-elem__count']}>
        {selectElem.length ? (
          <>
            <Button
              buttonType="button"
              buttonName={clear}
              buttonMethod={(): void => setSelectElem([])}
              buttonClass={styles['select-elem__btn--clear']}
              colorStyle="blue"
            />
            <Button
              buttonType="button"
              buttonName={submitBtnName}
              buttonMethod={openMethod}
              buttonClass={styles['select-elem__btn--add-to-folder']}
              disabledButton={disabled}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export const ActionItem = ({ name }: ActionItemType): ReactElement => (
  <div className={styles['action-item']}>{name}</div>
);

export const SortSearchItem = ({
  t,
  sortByColumnName,
  sortName,
  handleSearch,
  handleReset,
  searchMatches,
  findMatches,
  hideSearchMenu,
  isSearch,
  setHideSearchMenu,
  setSearchMatches,
  fieldName,
  withSearch = false,
  sortBy,
}: any): ReactElement => {
  const [activeSortColorASC, setActiveSortColorASC] = useState<string>('unset');
  const [activeSortColorDESC, setActiveSortColorDESC] = useState<string>('unset');
  const [sortDirectionName, setSortDirectionName] = useState<string>('');

  const tooltipTitleTextByName = setSortDirectionTitle(sortDirectionName, t);

  // Specifies the sort direction
  const getSortDirectionName = (): string => {
    let changeSortDirection = '';
    const asc = SORT_DIRECTION.ASC;
    const desc = SORT_DIRECTION.DESC;
    switch (sortDirectionName) {
      case '':
        changeSortDirection = asc;
        setSortDirectionName(asc);
        setActiveSortColorASC('#1890ff');
        break;
      case asc:
        changeSortDirection = desc;
        setSortDirectionName(desc);
        setActiveSortColorASC('unset');
        setActiveSortColorDESC('#1890ff');
        break;
      default:
        changeSortDirection = '';
        setSortDirectionName('');
        setActiveSortColorASC('unset');
        setActiveSortColorDESC('unset');
    }
    return changeSortDirection;
  };

  // show or hide the search menu
  const handleVisibleSearchMenu = (flag: boolean): void => {
    setHideSearchMenu(flag);
    if (!flag) {
      setSearchMatches(null);
    }
  };

  const SearchMenuJsx = (
    <SearchMenuElem
      name={fieldName}
      handleSearch={handleSearch}
      handleReset={handleReset}
      searchMatches={searchMatches}
      findMatches={findMatches}
      hideSearchMenu={hideSearchMenu}
    />
  );
  useEffect(() => {
    if (sortBy !== sortName) {
      setActiveSortColorASC('unset');
      setActiveSortColorDESC('unset');
      setSortDirectionName('');
    }
  }, [sortBy]);

  return (
    <div className={styles['exercise-name-elem']}>
      <div className={styles['sort-filter-container']}>
        <Tooltip title={tooltipTitleTextByName}>
          <div
            className={styles['title-container']}
            onClick={(): void => {
              sortByColumnName(sortName, getSortDirectionName());
            }}
            role="presentation"
          >
            <span>{fieldName}</span>
            <div className={styles['exercise-name-title-sort']}>
              <CaretUpFilled style={{ color: activeSortColorASC, ...sortStyles }} />
              <CaretDownFilled style={{ color: activeSortColorDESC, ...sortStyles }} />
            </div>
          </div>
        </Tooltip>
        {withSearch && (
          <Dropdown
            overlay={SearchMenuJsx}
            trigger={['click']}
            visible={hideSearchMenu}
            onVisibleChange={handleVisibleSearchMenu}
            placement="bottomRight"
            overlayClassName="filterDropDown"
          >
            <div className={styles['filter-container']}>
              {isSearch ? (
                <div className={styles['search-icon-active']} />
              ) : (
                <div className={styles['search-icon-not-active']} />
              )}
            </div>
          </Dropdown>
        )}
      </div>
    </div>
  );
};

export const SortSearchPickerItem = ({
  fieldName,
  t,
  sortName,
  sortByColumnName,
  sortBy,
  withSearch = false,
  isDatePickerSearch,
  handleSearchByLastActionDate,
  handleResetLastActiondate,
  hideDatePicker,
  setHideDatePicker,
  queryPicker,
  setQueryPicker,
}: any): ReactElement => {
  const userLang = useSelector<GetState>((state) => getUserLang(state));

  const [activeSortColorASC, setActiveSortColorASC] = useState<string>('unset');
  const [activeSortColorDESC, setActiveSortColorDESC] = useState<string>('unset');
  const [sortDirectionName, setSortDirectionName] = useState<string>('');

  const tooltipTitleTextByName = setSortDirectionTitle(sortDirectionName, t);

  // Specifies the sort direction
  const getSortDirectionName = (): string => {
    let changeSortDirection = '';
    const asc = SORT_DIRECTION.ASC;
    const desc = SORT_DIRECTION.DESC;
    switch (sortDirectionName) {
      case '':
        changeSortDirection = asc;
        setSortDirectionName(asc);
        setActiveSortColorASC('#1890ff');
        break;
      case asc:
        changeSortDirection = desc;
        setSortDirectionName(desc);
        setActiveSortColorASC('unset');
        setActiveSortColorDESC('#1890ff');
        break;
      default:
        changeSortDirection = '';
        setSortDirectionName('');
        setActiveSortColorASC('unset');
        setActiveSortColorDESC('unset');
    }
    return changeSortDirection;
  };

  // show or hide the search menu
  const handleVisibleDatePicker = (flag: boolean): void => {
    setHideDatePicker(flag);
  };

  useEffect(() => {
    if (sortBy !== sortName) {
      setActiveSortColorASC('unset');
      setActiveSortColorDESC('unset');
      setSortDirectionName('');
    }
  }, [sortBy]);

  return (
    <div className={styles['exercise-name-elem']}>
      <div className={styles['sort-filter-container']}>
        <Tooltip title={tooltipTitleTextByName}>
          <div
            className={styles['title-container']}
            onClick={(): void => {
              sortByColumnName(sortName, getSortDirectionName());
            }}
            role="presentation"
          >
            <span>{fieldName}</span>
            <div className={styles['exercise-name-title-sort']}>
              <CaretUpFilled style={{ color: activeSortColorASC, ...sortStyles }} />
              <CaretDownFilled style={{ color: activeSortColorDESC, ...sortStyles }} />
            </div>
          </div>
        </Tooltip>
        {withSearch && (
          <Dropdown
            overlay={
              hideDatePicker ? (
                <DatePickerMenuElem
                  name="SearchDate"
                  handleSearch={handleSearchByLastActionDate}
                  handleReset={handleResetLastActiondate}
                  queryPicker={queryPicker}
                  setQueryPicker={setQueryPicker}
                  currentLang={userLang}
                />
              ) : (
                <></>
              )
            }
            trigger={['click']}
            visible={hideDatePicker}
            onVisibleChange={handleVisibleDatePicker}
            placement="bottomRight"
            // overlayClassName="filterDropDown"
          >
            <div className={styles['filter-container']}>
              {isDatePickerSearch ? (
                <div className={styles['filter-date-picker-icon-active']} />
              ) : (
                <div className={styles['filter-date-picker-icon-not-active']} />
              )}
            </div>
          </Dropdown>
        )}
      </div>
    </div>
  );
};

export const SortSearchFilterItem = ({
  fieldName,
  t,
  sortName,
  sortByColumnName,
  sortBy,
  withSearch = false,
  menu,
  hideSearchMenu,
  isFilterStatus,
  setHideSearchMenu,
}: any): ReactElement => {
  const [activeSortColorASC, setActiveSortColorASC] = useState<string>('unset');
  const [activeSortColorDESC, setActiveSortColorDESC] = useState<string>('unset');
  const [sortDirectionName, setSortDirectionName] = useState<string>('');

  const tooltipTitleTextByName = setSortDirectionTitle(sortDirectionName, t);

  // Specifies the sort direction
  const getSortDirectionName = (): string => {
    let changeSortDirection = '';
    const asc = SORT_DIRECTION.ASC;
    const desc = SORT_DIRECTION.DESC;
    switch (sortDirectionName) {
      case '':
        changeSortDirection = asc;
        setSortDirectionName(asc);
        setActiveSortColorASC('#1890ff');
        break;
      case asc:
        changeSortDirection = desc;
        setSortDirectionName(desc);
        setActiveSortColorASC('unset');
        setActiveSortColorDESC('#1890ff');
        break;
      default:
        changeSortDirection = '';
        setSortDirectionName('');
        setActiveSortColorASC('unset');
        setActiveSortColorDESC('unset');
    }
    return changeSortDirection;
  };

  // show or hide the search menu
  const handleVisibleSearchMenu = (flag: boolean): void => {
    setHideSearchMenu(flag);
  };

  useEffect(() => {
    if (sortBy !== sortName) {
      setActiveSortColorASC('unset');
      setActiveSortColorDESC('unset');
      setSortDirectionName('');
    }
  }, [sortBy]);

  const filterBtnStyle: { color: string } = {
    color: isFilterStatus ? '#1890ff' : 'unset',
  };

  return (
    <div className={styles['exercise-name-elem']}>
      <div className={styles['sort-filter-container']}>
        <Tooltip title={tooltipTitleTextByName}>
          <div
            className={styles['title-container']}
            onClick={(): void => {
              sortByColumnName(sortName, getSortDirectionName());
            }}
            role="presentation"
          >
            <span>{fieldName}</span>
            <div className={styles['exercise-name-title-sort']}>
              <CaretUpFilled style={{ color: activeSortColorASC, ...sortStyles }} />
              <CaretDownFilled style={{ color: activeSortColorDESC, ...sortStyles }} />
            </div>
          </div>
        </Tooltip>
        {withSearch && (
          <Dropdown
            overlay={menu}
            trigger={['click']}
            visible={hideSearchMenu}
            onVisibleChange={handleVisibleSearchMenu}
            placement="bottomRight"
            overlayClassName="filterItemDropDown"
          >
            <div className={styles['filter-container']}>
              <FilterFilled style={{ fontSize: '16px', height: '16px', ...filterBtnStyle }} />
            </div>
          </Dropdown>
        )}
      </div>
    </div>
  );
};
