import { useLazyQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import { ExecutionResult } from 'graphql';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

import { useMutationRequest } from '../../hooks/feature/useMutationRequest';
import {
  UseCreatePatientRewards,
  UseCreatePatientRewardsByHcp,
  UseShutdownPatientRewards,
  UseShutdownPatientRewardsByHcp,
} from '../types';
import { config } from '../../utils/configs';
import useHandleGqlError from '../../hooks/useHandleGqlError';
import { setRewardList, setRewardListError, setRewardListLoading } from '../../redux/rewards';
import { DataRewardType } from '../../components/Rewards/types';

// Query
const getHospitalProgramRewards = loader('./gql/queryGetHospitalProgramRewards.graphql');
const queryGetHcpHospitalRewards = loader('./gql/queryGetHcpHospitalRewards.graphql');

// Mutation
const setHospitalProgramRewards = loader('./gql/mutationSetHospitalRewards.graphql');
const saveHcpHospitalWeekReward = loader('./gql/mutationSaveHcpHospitalWeekReward.graphql');
const removeCustomBrandPic = loader('./gql/mutationRemoveCustomBrandPic.graphql');
const mutationCreatePatientRewards = loader('./gql/mutationCreatePatientRewardsByAdmin.graphql');
const mutationShutdownPatientRewards = loader(
  './gql/mutationShutdownPatientRewardsByAdmin.graphql',
);
const mutationCreatePatientRewardsByHcp = loader('./gql/mutationCreatePatientRewardsByHcp.graphql');
const mutationShutdownPatientRewardsByHcp = loader(
  './gql/mutationShutdownPatientRewardsByHcp.graphql',
);

// get patient information from DB (Attachment from HCP)
export const useQueryGetHospitalProgramRewards = () => {
  const [_getHospitalProgramRewards, { loading, error, data }] = useLazyQuery(
    getHospitalProgramRewards,
    {
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    },
  );

  return {
    _getHospitalProgramRewards,
    loadingHospitalProgramRewards: loading,
    errorHospitalProgramRewards: error?.graphQLErrors?.[0]?.message || error,
    hospitalProgramRewards: data?.getHospitalProgramRewards,
  };
};

// get Hospital custom rewards by HCP
export const useQueryGetHcpHospitalRewards = () => {
  const [_getHcpHospitalRewards, { loading, error, data }] = useLazyQuery(
    queryGetHcpHospitalRewards,
    {
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      pollInterval: config.pollInterval,
    },
  );
  const dispatch = useDispatch();
  let rewardList: DataRewardType[] | null = null;
  const textErr = useHandleGqlError(error);

  useEffect(() => {
    if (data && !loading) {
      rewardList = data.getHcpHospitalRewards;
      dispatch(setRewardList(rewardList));
    }
  }, [data]);

  useEffect(() => {
    dispatch(setRewardListLoading(loading));
  }, [loading]);

  useEffect(() => {
    if (textErr) {
      dispatch(setRewardListError(textErr));
    }
    dispatch(setRewardListError(undefined));
  }, [textErr]);

  return {
    _getHcpHospitalRewards,
    loadingHcpHospitalRewards: loading,
    errorHHcpHospitalRewards: textErr,
    hcpHospitalRewards: rewardList,
  };
};

// Create new Patient information by HCP
export const useSetHospitalProgramRewards = () => {
  const [_setHospitalProgramRewards, { data, errorText, loading }] = useMutationRequest<{
    setHospitalProgramRewards: boolean;
  }>(setHospitalProgramRewards, {});

  return {
    _setHospitalProgramRewards,
    setHospitalProgramRewardsData: data?.setHospitalProgramRewards,
    setHospitalProgramRewardsError: errorText,
    setHospitalProgramRewardsLoading: loading,
  };
};

// Save / Edit week (period) Reward by HCP
export const useSaveHcpHospitalWeekReward = () => {
  const [_saveWeek, { data, errorText, loading }] = useMutationRequest<{
    saveHcpHospitalWeekReward: boolean;
  }>(saveHcpHospitalWeekReward, {});

  return {
    _saveWeek,
    weekSaved: data?.saveHcpHospitalWeekReward,
    saveWeekError: errorText,
    saveWeekLoading: loading,
  };
};

// Remove custom logo brand from AWS
export const useRemoveBrandPic = () => {
  const [_removeBrand, { data, errorText, loading }] = useMutationRequest<{
    removeCustomBrandPic: boolean;
  }>(removeCustomBrandPic, {});

  return {
    _removeBrand,
    brandRemoved: data?.removeCustomBrandPic,
    removeBrandError: errorText,
    removeBrandLoading: loading,
  };
};

// Turn on OTM rewards by admin
export const useCreatePatientRewards = (): UseCreatePatientRewards => {
  const [_onCreateRewards, { loading, errorText, data }] = useMutationRequest<{
    startPatientRewards: boolean;
  }>(mutationCreatePatientRewards, {});

  const _onCreatePatientRewards = (
    patientId: number,
    startDate: string,
    endDate: string,
  ): Promise<
    ExecutionResult<{
      startPatientRewards: boolean;
    }>
  > =>
    _onCreateRewards({
      variables: {
        rewardsData: {
          patientId,
          startDate,
          endDate,
        },
      },
    });

  return {
    _onCreatePatientRewards,
    createRewardsLoading: loading,
    createRewardsError: errorText,
    saccessCreatePatientRewards: data && data.startPatientRewards,
  };
};

// Turn off OTM rewards by admin
export const useShutdownPatientRewards = (): UseShutdownPatientRewards => {
  const [_onShutdownRewards, { loading, errorText, data }] = useMutationRequest<{
    shutdownPatientRewards: boolean;
  }>(mutationShutdownPatientRewards, {});

  const _onShutdownPatientRewards = (
    patientId: number,
    shutdownDate: string,
  ): Promise<
    ExecutionResult<{
      shutdownPatientRewards: boolean;
    }>
  > =>
    _onShutdownRewards({
      variables: {
        rewardsData: {
          patientId,
          shutdownDate,
        },
      },
    });
  return {
    _onShutdownPatientRewards,
    shutdownRewardsLoading: loading,
    shutdownRewardsError: errorText,
    saccessShutdownPatientRewards: data && data.shutdownPatientRewards,
  };
};

// Turn on Hospital custom rewards by HCP
export const useCreatePatientRewardsByHcp = (): UseCreatePatientRewardsByHcp => {
  const [_onCreateCustomRewards, { loading, errorText, data }] = useMutationRequest<{
    startPatientRewardsByHcp: boolean;
  }>(mutationCreatePatientRewardsByHcp, {});

  const _onCreatePatientRewardsByHcp = (
    patientId: number,
    startDate: string,
    endDate: string,
  ): Promise<
    ExecutionResult<{
      startPatientRewardsByHcp: boolean;
    }>
  > =>
    _onCreateCustomRewards({
      variables: {
        rewardsData: {
          patientId,
          startDate,
          endDate,
        },
      },
    });

  return {
    _onCreatePatientRewardsByHcp,
    createRewardsByHcpLoading: loading,
    createRewardsByHcpError: errorText,
    saccessCreatePatientRewardsByHcp: data?.startPatientRewardsByHcp,
  };
};

// Turn off Hospital custom rewards by HCP
export const useShutdownPatientRewardsByHcp = (): UseShutdownPatientRewardsByHcp => {
  const [_onShutdownRewardsByHcp, { loading, errorText, data }] = useMutationRequest<{
    shutdownPatientRewardsByHcp: boolean;
  }>(mutationShutdownPatientRewardsByHcp, {});

  const _onShutdownPatientRewardsByHcp = (
    patientId: number,
    shutdownDate: string,
  ): Promise<
    ExecutionResult<{
      shutdownPatientRewardsByHcp: boolean;
    }>
  > =>
    _onShutdownRewardsByHcp({
      variables: {
        rewardsData: {
          patientId,
          shutdownDate,
        },
      },
    });
  return {
    _onShutdownPatientRewardsByHcp,
    shutdownRewardsByHcpLoading: loading,
    shutdownRewardsByHcpError: errorText,
    saccessShutdownPatientRewardsByHcp: data?.shutdownPatientRewardsByHcp,
  };
};
