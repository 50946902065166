/* eslint-disable react/no-danger */
import React, { ReactElement } from 'react';
import cx from 'classnames';
import moment from 'moment';
import { sanitize } from 'dompurify';

import { getPatientAnswers } from '../../../../../utils/helper_charts';
import style from './style.module.css';
import { Answer, QuestionnaireContentProps, Question } from './types';
import { RemoveQuestionnaire } from './common/RemoveQuestionnaire';
import { CalculationDescription } from './common/Calculation';
import { PROMS_NAME } from '../../../../../utils/enums';

export const SfReport = ({
  questionnairesList,
  completedQuestionnaire,
  index,
  onDeleteItem,
  formatDate,
  t,
}: QuestionnaireContentProps): ReactElement => {
  const remove_questionnaire = t?.dashboard.hcp.profile_patient.questionnaires.remove_questionnaire;
  const sf_title = t?.dashboard.hcp.profile_patient.questionnaires.sf_title;
  const sf_sub_title_now_limit_you =
    t?.dashboard.hcp.profile_patient.questionnaires.sf_sub_title_now_limit_you;
  const sf_sub_title_physical_health =
    t?.dashboard.hcp.profile_patient.questionnaires.sf_sub_title_physical_health;
  const sf_sub_title_emotional_problems =
    t?.dashboard.hcp.profile_patient.questionnaires.sf_sub_title_emotional_problems;
  const sf_sub_title_feeling = t?.dashboard.hcp.profile_patient.questionnaires.sf_sub_title_feeling;
  const sf_sub_title_how_much =
    t?.dashboard.hcp.profile_patient.questionnaires.sf_sub_title_how_much;
  const sf_sub_title_true_false =
    t?.dashboard.hcp.profile_patient.questionnaires.sf_sub_title_true_false;
  const sf_name = t?.dashboard.hcp.profile_patient.questionnaires.sf_name;
  const sf_calc_descr_1 = t?.dashboard.hcp.profile_patient.questionnaires.sf_calc_descr_1;
  const sf_calc_descr_2 = t?.dashboard.hcp.profile_patient.questionnaires.sf_calc_descr_2;

  const { questions: completedQuestions, completedDate, healthScores } = completedQuestionnaire;
  const patientAnswers = getPatientAnswers(completedQuestions);
  const sfList = questionnairesList.filter((item: any) => item.name === PROMS_NAME.SF36)[0];
  const { questions } = sfList;

  // JSX
  const calc_description = (
    <>
      <p>{sf_calc_descr_1}</p>
      <p>{sf_calc_descr_2}</p>
    </>
  );

  // get Q&A pages
  const getJSXPages = (): JSX.Element[] => {
    let page: any = [];
    const pages: any = [];
    const getSubTitles = (pos: number): JSX.Element => {
      switch (pos) {
        case 0:
          return <div className={style.sf__title}>{sf_title}</div>;
        case 2:
          return (
            <div
              className={style['sf__sub-title']}
              dangerouslySetInnerHTML={{ __html: sanitize(sf_sub_title_now_limit_you) }}
            />
          );
        case 12:
          return (
            <div
              className={style['sf__sub-title']}
              dangerouslySetInnerHTML={{ __html: sanitize(sf_sub_title_physical_health) }}
            />
          );
        case 16:
          return (
            <div
              className={style['sf__sub-title']}
              dangerouslySetInnerHTML={{ __html: sanitize(sf_sub_title_emotional_problems) }}
            />
          );
        case 22:
          return (
            <>
              <div
                className={style['sf__sub-title']}
                dangerouslySetInnerHTML={{ __html: sanitize(sf_sub_title_feeling) }}
              />
              <div
                className={style['sf__sub-title']}
                dangerouslySetInnerHTML={{ __html: sanitize(sf_sub_title_how_much) }}
              />
            </>
          );
        case 32:
          return (
            <div
              className={style['sf__sub-title']}
              dangerouslySetInnerHTML={{ __html: sanitize(sf_sub_title_true_false) }}
            />
          );
        default:
          return <></>;
      }
    };
    if (questions?.length) {
      questions.map((question: Question, ind: number) => {
        const oneQuestionAnswerJSX = (
          <React.Fragment key={`questions${String(ind)}`}>
            {getSubTitles(ind)}
            <div className={style['sf__quest-answ-cont']}>
              <div className={style['sf__quest-cont']}>
                <span>{ind + 1}.</span>
                <div
                  className={style.sf__question}
                  dangerouslySetInnerHTML={{ __html: sanitize(question.name) }}
                />
              </div>
              <div className={style.sf__row}>
                {question.answers.map((answ: Answer, inx: number) => (
                  <div
                    className={cx({
                      [style.sf__cell]: true,
                      [style[`sf__cell-${question.answers.length}`]]: true,
                      [style['sf__checked-item']]: patientAnswers[question.id] === answ.id,
                    })}
                    key={`answers${String(inx)}`}
                  >
                    <div className={style.sf__box}>
                      <div className={style.sf__checkbox}>
                        <div className={style['sf__checkbox-inner']}>
                          {patientAnswers[question.id] === answ.id && <>&#10004;</>}
                        </div>
                      </div>
                      {`${answ.name} (${inx + 1})`}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </React.Fragment>
        );
        page.push(oneQuestionAnswerJSX);

        // number of items on the 1st page
        if (ind === 6 || ind === 18 || ind === 28 || ind === 35) {
          pages.push(page);
          page = [];
        }
        return null;
      });
    }
    return pages;
  };

  return (
    <>
      <RemoveQuestionnaire
        onMethod={(): void => onDeleteItem(index)}
        title={remove_questionnaire}
      />
      <div className="questionnaire-to-PDF">
        <div
          className={cx({
            [style.sf__container]: true,
          })}
          style={{ paddingBottom: '0px' }}
        >
          <CalculationDescription
            name={sf_name}
            content={calc_description}
            date={completedDate ? moment(completedDate).format(formatDate) : ''}
            t={t}
          />
        </div>
      </div>
      {/* PAGES */}
      {getJSXPages().map((page: any, indx: number) => (
        <div key={`pages${String(indx)}`} className="questionnaire-to-PDF">
          <div
            className={cx({
              [style.sf__container]: true,
            })}
          >
            {page.map((item: JSX.Element, ind: number) => (
              <React.Fragment key={`page${String(ind)}`}>{item}</React.Fragment>
            ))}
          </div>
        </div>
      ))}
      <div className="questionnaire-to-PDF">
        <div
          className={cx({
            [style.sf__container]: true,
          })}
        >
          <div className={style['sf__result-cont']}>
            {healthScores.map((healthScore: any) => (
              <div key={healthScore.domain} className={style['sf__result-item']}>
                <span className={style['sf__result-domain']}>{healthScore.domain}:</span>
                <span>
                  {healthScore.score}
                  {' %'}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
