import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';

import styles from '../../../styles.module.css';
import { GetState, getCurrenLang, getHcpNameLoading } from '../../../../redux/selector';
import { BreadcrumbAnt } from '../../../../utils/routers/Breadcrumb';
import { path } from '../../../../utils';
import useCheckPermissions from '../../../../hooks/useCheckPermissions';
import { PERMISSIONS } from '../../../../utils/enums';
import AddPhoto from '../../../../components/Video/OrganisationVideoBank/components/AddPhoto';

const AddPhotoPage = (): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const add_photo = t?.common.add_photo_title;
  const you_dont_have_permission = t?.common.you_dont_have_permission;
  const org_video_bank = t?.title.org_video_bank;
  const add_media = t?.hcp.org_video_bank.add_media;

  const isPermissionsAddVideosToTeamVideoBank = useCheckPermissions(
    PERMISSIONS.ADD_VIDEOS_TO_TEAM_VIDEOBANK_OTM,
  );
  const loadingPermission = useSelector<GetState>((state) => getHcpNameLoading(state));
  const isNoPermissions = !loadingPermission && !isPermissionsAddVideosToTeamVideoBank;

  // Breadcrumb path
  const routes = [
    {
      path: path.organisationVideoBank,
      breadcrumbName: org_video_bank,
    },
    {
      path: path.addMedia,
      breadcrumbName: add_media,
    },
    {
      path: add_photo,
      breadcrumbName: add_photo,
    },
  ];

  return (
    <div className={styles.main__container}>
      <BreadcrumbAnt currentRoutes={routes} />
      <div className={styles['main__flex-wrapper']}>
        {!isNoPermissions && <AddPhoto />}
        {isNoPermissions ? (
          <div className={styles.nopermissions}>{you_dont_have_permission}</div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default AddPhotoPage;
