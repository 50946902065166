import { useSelector } from 'react-redux';
import { GetState, getPermissions } from '../redux/selector';
import { constants } from '../utils/routers/book';
import { storage } from '../utils';

const useCheckPermissions = (type: string): boolean => {
  const permissions: any = useSelector<GetState>((state) => getPermissions(state));
  const userFromLS = storage.get('user');
  const isAdmin = userFromLS && userFromLS.role === constants.admin;

  let isPermission = !!isAdmin;

  if (permissions?.length) {
    isPermission = isAdmin ? true : permissions.includes(type);
  }

  return isPermission;
};

export default useCheckPermissions;
