import React, { useState } from 'react';
import { UseDeviceDetectProps } from './types';

export default function useDeviceDetect(): UseDeviceDetectProps {
  const [isMobile, setMobile] = useState(false);

  React.useEffect(() => {
    const userAgent = typeof window.navigator === 'undefined' ? '' : navigator.userAgent;

    const mobile = Boolean(
      userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i) ||
        (userAgent.includes('Mac') && 'ontouchend' in document),
    );
    setMobile(mobile);
  }, []);

  return { isMobile };
}
