import { loader } from 'graphql.macro';
import { useLazyQuery } from '@apollo/react-hooks';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import moment from 'moment';
import {
  fillExercisesPerPeriod,
  fillNotComplExerc,
  notComplExercError,
  notComplExercLoading,
  setExercisesPerPeriodLoading,
} from '../../redux/completions';
import { config } from '../../utils/configs';

// Query
const queryGetPatientCompletedExercisesByPeriod = loader(
  './gql/queryGetPatientCompletedExercisesByPeriod.graphql',
);
const queryGetPatientNotComplExercForGraphs = loader(
  './gql/queryGetPatientNotComplExercForGraphs.graphql',
);

// Completed Exercises
export const useGetPatientCompletedExercisesByPeriod = () => {
  const dispatch = useDispatch();
  const [_onGetPatientCompletedExercisesByPeriod, { loading, error, data }] = useLazyQuery(
    queryGetPatientCompletedExercisesByPeriod,
    {
      fetchPolicy: 'no-cache',
      pollInterval: config.pollInterval,
    },
  );
  const _getPatientCompletedExercisesByPeriod = (req: any): void => {
    const { patientId, selectedPeriod } = req;
    const daysAsParams = moment(selectedPeriod.periodFrom);

    _onGetPatientCompletedExercisesByPeriod({
      variables: {
        patientExerciseData: {
          patientId,
          dateFrom: daysAsParams,
        },
      },
    });
  };

  useEffect(() => {
    if (data) {
      const response = data && data.getPatientCompletedExercisesByPeriod;
      dispatch(fillExercisesPerPeriod(response));
    }
  }, [data]);

  useEffect(() => {
    dispatch(setExercisesPerPeriodLoading(loading));
  }, [loading]);

  return {
    _getPatientCompletedExercisesByPeriod,
    loading,
    error,
  };
};

// Reasons for not completed exercises and Number of Exercises Skipped or Stopped
export const useGetPatientNotCompletedExercisesForGraphs = () => {
  const dispatch = useDispatch();
  const [_onGetPatientNotCompletedExercisesForGraphs, { loading, error, data }] = useLazyQuery(
    queryGetPatientNotComplExercForGraphs,
    {
      fetchPolicy: 'no-cache',
      pollInterval: config.pollInterval,
    },
  );
  const _getNotCompletedExercises = (patientId: number): void => {
    _onGetPatientNotCompletedExercisesForGraphs({
      variables: {
        patientId,
      },
    });
  };

  useEffect(() => {
    if (data) {
      const response = data && data.getPatientNotCompletedExercisesForGraphs;
      dispatch(fillNotComplExerc(response));
      dispatch(notComplExercError(''));
    }
  }, [data]);

  useEffect(() => {
    dispatch(notComplExercLoading(loading));
  }, [loading]);

  useEffect(() => {
    if (error) {
      dispatch(notComplExercError(error));
    }
  }, [error]);

  return {
    _getNotCompletedExercises,
    loading,
    error,
  };
};
