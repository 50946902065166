import { useLazyQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

import useHandleGqlError from '../../hooks/useHandleGqlError';
import {
  loadingMotivation,
  setPainLevel,
  setPainLevelError,
  setPainLevelLoading,
  setPatientMotivationData,
  setPatientMotivationError,
} from '../../redux/motivations';
import { config } from '../../utils/configs';
import {
  exerciseComplLoading,
  fillExerciseCompletion,
  fillSteps,
  loadingSteps,
  setErrorSteps,
  setExerciseComplError,
} from '../../redux/completions';
import {
  loadingSleepData,
  setHeartRateData,
  setHeartRateError,
  setHeartRateLoading,
  setPatientSleepData,
  setSleepError,
} from '../../redux/bodyData';

// Queries
const queryGetPatientAllExercisesCompletionForGraphs = loader(
  './gql/queryGetPatientAllExercisesCompletionForGraphs.graphql',
);
const queryGetPatientStepCountForGraphs = loader('./gql/queryGetPatientStepCountForGraphs.graphql');
const queryGetPatientPainLevelForGraphs = loader('./gql/queryGetPatientPainLevelForGraphs.graphql');
const queryGetPatientHeartRateForGraphs = loader('./gql/queryGetPatientHeartRateForGraphs.graphql');
const queryGetPatientSleepForGraphs = loader('./gql/queryGetPatientSleepForGraphs.graphql');
const queryGetMotivationLevelForGraphs = loader(
  './gql/queryGetPatientMotivationLevelForGraphs.graphql',
);

// Get data for motivation chart
export const useGetMotivationLevel = () => {
  const [_getPatientMotivationLevel, { loading, error, data }] = useLazyQuery(
    queryGetMotivationLevelForGraphs,
    {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      pollInterval: config.pollInterval,
    },
  );
  const dispatch = useDispatch();
  let response;
  const errorText = useHandleGqlError(error);

  useEffect(() => {
    if (data) {
      response = data.getPatientMotivationLevelForGraphs;
      dispatch(setPatientMotivationData(response));
    }
  }, [data]);

  useEffect(() => {
    dispatch(loadingMotivation(loading));
  }, [loading]);

  useEffect(() => {
    dispatch(setPatientMotivationError(error));
  }, [error]);

  const _getMotivationLevel = (patientId: number): void => {
    _getPatientMotivationLevel({
      variables: { patientId },
    });
  };

  return {
    _getMotivationLevel,
    getMotivationLevelError: errorText,
    getMotivationLevelLoading: loading,
  };
};

// Get data for steps chart
export const useGetPatientSteps = () => {
  const [_getPatientStepsCount, { loading, error, data }] = useLazyQuery(
    queryGetPatientStepCountForGraphs,
    {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      pollInterval: config.pollInterval,
    },
  );
  const dispatch = useDispatch();
  let response;
  const errorText = useHandleGqlError(error);

  useEffect(() => {
    if (data) {
      response = data.getPatientStepCountForGraphs;
      dispatch(fillSteps(response));
    }
  }, [data]);

  useEffect(() => {
    dispatch(loadingSteps(loading));
  }, [loading]);

  useEffect(() => {
    dispatch(setErrorSteps(error));
  }, [error]);

  const _getSteps = (patientId: number): void => {
    _getPatientStepsCount({
      variables: { patientId },
    });
  };

  return {
    _getSteps,
    getStepsError: errorText,
    getStepLoading: loading,
  };
};

// Get data for pain chart
export const useGetPainLevel = () => {
  const [_getPainLevelData, { loading, error, data }] = useLazyQuery(
    queryGetPatientPainLevelForGraphs,
    {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      pollInterval: config.pollInterval,
    },
  );
  const dispatch = useDispatch();
  let response;
  const errorText = useHandleGqlError(error);

  useEffect(() => {
    if (data) {
      response = data.getPatientPainLevelForGraphs;
      dispatch(setPainLevel(response));
    }
  }, [data]);

  useEffect(() => {
    dispatch(setPainLevelLoading(loading));
  }, [loading]);

  useEffect(() => {
    dispatch(setPainLevelError(error));
  }, [error]);

  const _getPainLevel = (patientId: number): void => {
    _getPainLevelData({
      variables: { patientId },
    });
  };

  return {
    _getPainLevel,
    getPainLevelError: errorText,
    getPainLevelLoading: loading,
  };
};

// Get data for Patient Sleep chart
export const useGetSleepData = () => {
  const [_getPatientSleepData, { loading, error, data }] = useLazyQuery(
    queryGetPatientSleepForGraphs,
    {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      pollInterval: config.pollInterval,
    },
  );
  const dispatch = useDispatch();
  let response;
  const errorText = useHandleGqlError(error);

  useEffect(() => {
    if (data) {
      response = data.getPatientSleepForGraphs;
      dispatch(setPatientSleepData(response));
    }
  }, [data]);

  useEffect(() => {
    dispatch(loadingSleepData(loading));
  }, [loading]);

  useEffect(() => {
    dispatch(setSleepError(error));
  }, [error]);

  const _getSleepData = (patientId: number): void => {
    _getPatientSleepData({
      variables: { patientId },
    });
  };

  return {
    _getSleepData,
    getSleepError: errorText,
    getSleepLoading: loading,
  };
};

// Get data for Patient Heart Rate chart
export const useGetHeartRate = () => {
  const [_getPatientHeartRate, { loading, error, data }] = useLazyQuery(
    queryGetPatientHeartRateForGraphs,
    {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      pollInterval: config.pollInterval,
    },
  );
  const dispatch = useDispatch();
  let response;
  const errorText = useHandleGqlError(error);

  useEffect(() => {
    if (data) {
      response = data.getPatientHeartRateForGraphs;
      dispatch(setHeartRateData(response));
    }
  }, [data]);

  useEffect(() => {
    dispatch(setHeartRateLoading(loading));
  }, [loading]);

  useEffect(() => {
    dispatch(setHeartRateError(error));
  }, [error]);

  const _getHeartRate = (patientId: number): void => {
    _getPatientHeartRate({
      variables: { patientId },
    });
  };

  return {
    _getHeartRate,
    getHeartRateError: errorText,
    getHeartRateLoading: loading,
  };
};

// Get data for Patient Heart Rate chart
export const useGetExercisesCompletion = () => {
  const [_getPatientExercCompletion, { loading, error, data }] = useLazyQuery(
    queryGetPatientAllExercisesCompletionForGraphs,
    {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      pollInterval: config.pollInterval,
    },
  );
  const dispatch = useDispatch();
  let response;
  const errorText = useHandleGqlError(error);

  useEffect(() => {
    if (data) {
      response = data.getPatientAllExercisesCompletionForGraphs;
      dispatch(fillExerciseCompletion(response));
    }
  }, [data]);

  useEffect(() => {
    dispatch(exerciseComplLoading(loading));
  }, [loading]);

  useEffect(() => {
    dispatch(setExerciseComplError(error));
  }, [error]);

  const _getExercCompletion = (patientId: number): void => {
    _getPatientExercCompletion({
      variables: { patientId },
    });
  };

  return {
    _getExercCompletion,
    getExercCompletionError: errorText,
    getExercCompletionLoading: loading,
  };
};
