import { loader } from 'graphql.macro';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { useMutationRequest } from '../../hooks/feature/useMutationRequest';
import { CreateDepartmentData } from '../types';
import useHandleGqlError from '../../hooks/useHandleGqlError';

// Query
const queryGetDeparmentsList = loader('./gql/queryGetDepartmentsList.graphql');
const queryGetDepartments = loader('./gql/queryGetDepartments.graphql');

// Mutation
const mutationCreateDepartmentByAdmin = loader('./gql/mutationCreateDepartmentByAdmin.graphql');
const mutationDeleteDepartmentByAdmin = loader('./gql/mutationDeleteDepartmentByAdmin.graphql');

export const useGetDepartments = () => {
  const { loading, error, data } = useQuery(
    queryGetDepartments,
    {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  );

  return {
    departments: data ? data.getDepartments : null,
    errorDepartments: error,
    loadingDepartments: loading,
  };
};

// Get Departments list by Admin
export const useGetDepartmentsList = () => {
  const [_getDepartmentsList, { loading, error, data }] = useLazyQuery(
    queryGetDeparmentsList,
    {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  );
  let dataList = [];
  let total = null;
  let totalPages = null;
  let totalItems = null;

  if (data) {
    const response = data.getDepartmentsList;
    dataList = response.list;
    total = response.listLength;
    totalPages = response.totalPages;
    totalItems = response.totalItems;
  }
  const errorText = useHandleGqlError(error);

  return {
    _getDepartmentsList,
    data,
    deparmentsList: dataList,
    total,
    totalPages,
    totalItems,
    error: errorText,
    loading,
  };
};

// Create new department
export const useCreateDepartment = () => {
  const [_onCreateDepartment, { data, errorText, loading }] = useMutationRequest(
    mutationCreateDepartmentByAdmin, {},
  );
  const _createDepartment = (departmentData: CreateDepartmentData[]): void => {
    _onCreateDepartment({
      variables: {
        departmentData,
      },
    });
  };

  return {
    _createDepartment,
    departmentCreated: data && data.createDepartmentByAdmin,
    createdepartmentError: errorText,
    createdepartmentLoading: loading,
  };
};

// Delete department
export const useDeleteDepartment = () => {
  const [_onDeleteDepartment, { data, errorText, loading }] = useMutationRequest<{
    deleteDepartmentByAdmin: boolean;
  }>(mutationDeleteDepartmentByAdmin, {});
  const _deleteDepartment = (departmentId: number): void => {
    _onDeleteDepartment({
      variables: {
        departmentId,
      },
    });
  };

  return {
    _deleteDepartment,
    departmentDeleted: data && data.deleteDepartmentByAdmin,
    deleteDepartmentError: errorText,
    deleteDepartmentLoading: loading,
  };
};
