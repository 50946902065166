import React, { ReactElement } from 'react';

import styles from './styles.module.css';
import { SelectFloatLabel } from '../../../../../common/Input/Select';

const ScheduleFolder = ({ t, formik, unitOptions, numberOptions }: any): ReactElement => {
  const schedule_this_folder = t?.dashboard.hcp.profile_patient.schedule_this_folder;
  const post_surgery_date = t?.dashboard.hcp.profile_patient.post_surgery_date;
  const adjust_patient_settings = t?.dashboard.hcp.profile_patient.adjust_patient_settings;
  const number_t = t?.common.number;
  const unit_t = t?.common.unit;
  const { values, errors, touched } = formik;

  return (
    <>
      <div className={styles['team__modal--title']}>{schedule_this_folder}</div>
      <div className={styles['team__modal--schedule-container']}>
        <div className={styles['team__modal--number-field']}>
          <SelectFloatLabel
            inputId="amount"
            name="amount"
            placeholder={number_t}
            onChange={formik.setFieldValue}
            options={numberOptions}
            hasErrors={errors.amount}
            isTouched={touched.amount}
            selected={values.amount}
            inputValue={values.amount}
            isClearable
            isShowErrorText={false}
            type="exercise folder details"
          />
        </div>
        <div className={styles['team__modal--unit-field']}>
          <SelectFloatLabel
            inputId="timePeriod"
            name="timePeriod"
            placeholder={unit_t}
            onChange={formik.setFieldValue}
            options={unitOptions}
            hasErrors={errors.timePeriod}
            isTouched={touched.timePeriod}
            selected={values.timePeriod}
            inputValue={values.timePeriod}
            isClearable
            isShowErrorText={false}
            type="exercise folder details"
          />
        </div>
      </div>
      <div className={styles['team__modal--post-surgery']}>{post_surgery_date}</div>
      {errors.amount && touched.amount && (
        <div className={styles['team__modal--error']}>{errors.amount}</div>
      )}
      {errors.timePeriod && touched.timePeriod && (
        <div className={styles['team__modal--error']}>{errors.timePeriod}</div>
      )}
      <div className={styles['team__modal--comment']}>{adjust_patient_settings}</div>
    </>
  );
};

export default ScheduleFolder;
