import React, { ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import moment from 'moment';

import styles from './styles.module.css';
import { Modal, VideoModal } from '../../../common';
import { NoImage, NotAllowedIcon, PlayVideoIcon, ShowImgIcon } from '../../../theme/icons';
import { GetState, getCurrenLang, getCurrentFormatDate } from '../../../redux/selector';
import { WoundImagesItemProps } from '../types';
import { CloseBtn } from '../../../common/Button/Button';
import { PhotoModal } from '../../../common/VideoModal';

const WoundImagesItem = ({ data }: WoundImagesItemProps): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const no_patients_note = t?.dashboard.hcp.profile_patient.video_bank.no_patients_note;

  // Local state
  const [isModalOpen, setModal] = useState<boolean>(false);
  const [isShowComment, setShowComment] = useState<boolean>(false);

  const formatsDate = useSelector((state: GetState) => getCurrentFormatDate(state));

  const { thumbnailLink, videoLink, fileLink, documentDate, description } = data;
  const documentDateFormat = documentDate
    ? moment(documentDate).format(formatsDate.momentFormat)
    : '-';

  // Endpoints
  // Get Video link from AWS
  const showMedia = async (): Promise<void> => {
    if (fileLink || videoLink) {
      setModal(() => true);
    }
  };

  // Open modal widow with comment
  const showComment = (): void => {
    if (description) {
      setShowComment(() => true);
    }
  };

  // Clouse open modal windows
  const onclose = (): void => {
    setModal(() => false);
    setShowComment(() => false);
  };

  // JSX
  // Action icon
  const setPlayVideoIcon = (videolink: string, fotolink: string): JSX.Element => {
    if (fotolink) {
      return <ShowImgIcon className={styles['btn--slider']} />;
    }
    if (videoLink) {
      return <PlayVideoIcon className={styles['start--btn']} />;
    }
    return <img src={NotAllowedIcon} alt="No video" className={styles['btn--not-allowed']} />;
  };

  const setThumbnailJsx = (thumbLink: string): JSX.Element => {
    if (thumbLink) {
      return <img src={thumbLink} alt="Thumbnail" />;
    }
    return <img src={NoImage} alt="NoImage" />;
  };

  const isMediaExist = !!(data.videoLink || data.fileLink);
  const imgDateJsx = <div className={styles['wound-image__date']}>{documentDateFormat}</div>;

  return (
    <div className={styles['wound-image__row']}>
      {/* Thumbnail */}
      <div className={styles['wound-image__block']}>
        <div
          className={cx({
            [styles.preview]: true,
            [styles['preview-not-allowed']]: !isMediaExist,
          })}
          role="presentation"
          onClick={isMediaExist ? showMedia : (): null => null}
        >
          {setThumbnailJsx(thumbnailLink || fileLink)}
          {setPlayVideoIcon(thumbnailLink, fileLink)}
        </div>
      </div>
      {/* Comment */}
      <div className={styles['wound-image__comment-container']}>
        <div
          className={cx({
            [styles['wound-image__comment']]: true,
            [styles['wound-image__comment--no-description']]: !description,
          })}
          onClick={showComment}
          aria-hidden
        >
          {description || no_patients_note}
        </div>

        <div className={styles['wound-image__date-container--sm']}>{imgDateJsx}</div>
      </div>
      {/* Date sent */}
      <div className={styles['wound-image__date-container']}>{imgDateJsx}</div>
      {/* Popups */} {/* Video content */}
      {isModalOpen && videoLink && <VideoModal videoSrc={videoLink} onclose={onclose} />}
      {/* Image content */}
      {isModalOpen && fileLink && (
        <PhotoModal photoLink={thumbnailLink || fileLink} onclose={onclose} />
      )}
      {/* Comment */}
      {isShowComment && description && (
        <Modal onClose={onclose} style={styles['fromPatient__modal--comment']}>
          <CloseBtn close={onclose} />
          <div>{description}</div>
        </Modal>
      )}
    </div>
  );
};

export default WoundImagesItem;
