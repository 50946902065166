// Core
import { loader } from 'graphql.macro';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client';
import { useMutationRequest } from '../../hooks/feature/useMutationRequest';
import { CreateProfessionData, UseCreateProfession } from '../types';

// Query
const querygetProfessions = loader('./gql/querygetProfessions.graphql');
const queryProfessionsList = loader('./gql/queryGetProfessionsListByAdmin.graphql');

// Mutation
const createProfession = loader('./gql/mutationCreateProfessionByAdmin.graphql');
const deleteProfession = loader('./gql/mutationDeleteProfessionByAdmin.graphql');

interface UseGetProfessions {
  professions: any;
  errorProfessions: ApolloError | undefined;
  loadingProfessions: boolean;
}

export const useGetProfessions = (): UseGetProfessions => {
  const { loading, error, data } = useQuery(querygetProfessions);

  const professions = data ? data.getProfessions : null;

  return {
    professions,
    errorProfessions: error,
    loadingProfessions: loading,
  };
};

// Get Professions list by Admin
export const useProfessionsListByAdmin = (): any => {
  const [_getProfessionsListByAdmin, { loading, error, data }] = useLazyQuery(
    queryProfessionsList,
    {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  );
  let dataList = [];
  let total = null;
  let totalPages = null;
  let totalItems = null;

  if (data) {
    const response = data.getProfessionsList;
    dataList = response.list;
    total = response.listLength;
    totalPages = response.totalPages;
    totalItems = response.totalItems;
  }

  return {
    _getProfessionsListByAdmin,
    professionsList: dataList,
    total,
    totalPages,
    totalItems,
    error,
    loading,
  };
};

// Create new profession
export const useCreateProfession = (): UseCreateProfession => {
  const [
    _onCreateOrganisation,
    { data, errorText, loading },
  ] = useMutationRequest(createProfession, {});
  const _createProfession = (professionData: CreateProfessionData[]): void => {
    _onCreateOrganisation({
      variables: {
        professionData,
      },
    });
  };

  return {
    _createProfession,
    professionCreated: data && data.createProfessionByAdmin,
    createProfError: errorText,
    createProfLoading: loading,
  };
};

// Delete current profession
export const useDeleteProfession = (): any => {
  const [
    _onDeleteProfession,
    { data, errorText, loading },
  ] = useMutationRequest<{deleteProfessionByAdmin: boolean}>(
    deleteProfession, {},
  );
  const _deleteProfession = (professionId: number): void => {
    _onDeleteProfession({
      variables: {
        professionId,
      },
    });
  };

  return {
    _deleteProfession,
    professionDeleted: data && data.deleteProfessionByAdmin,
    deleteProfError: errorText,
    deleteProfLoading: loading,
  };
};
