import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Switch } from 'antd';
import cx from 'classnames';

import style from './styles.module.css';
import { Button, Loading, MainTitle } from '../../common';
import { EmojiesConfig, Options } from '../../utils/model';
import { useSetHcpEmailNotifications } from '../../graphql/hcp';
import { getMailingFrequencyOptions } from '../../utils/share/options';
import {
  validateManageNotif,
  validationEmptyFields,
  validationManadgeNotifTypeFiveBothSubtype,
  validationManadgeNotifTypeFiveSubtypeOne,
  validationManadgeNotifTypeFiveSubtypeTwo,
  validationManadgeNotifTypeOneBothField,
  validationManadgeNotifTypeOneSubtypeOne,
  validationManadgeNotifTypeOneSubtypeTwo,
  validationManadgeNotifTypeThreeBothSubtype,
  validationManadgeNotifTypeThreeSubtypeTwo,
} from '../../utils/validators';
import { ColleagueEmptyEmail } from './types';
import { GetState, getCurrenLang } from '../../redux/selector';
import { setOpenSettingsData } from '../../redux/emailNotifications';
import { SelectFloatLabel } from '../../common/Input/Select';
import { SuccessNotifModal } from '../../common/NotificationModal';
import { PopupWithTwoButtons } from '../../common/Popup';
import {
  CustomCheckbox,
  InputFloatLabel,
  InputFloatLabelWithDescription,
} from '../../common/Input';
import { DeleteIcon } from '../../theme/icons';
import { validEmail } from '../../utils/regx';
import { emojies } from '../../utils/emojies';
import { Emoji } from '../../common/Emoji';

export const NotificationsToMeSettings = ({
  openSettingsData,
  updateData,
  unicPrefix,
}: any): ReactElement => {
  const t: any = useSelector<any>((state: GetState) => getCurrenLang(state));
  const ok = t?.common.ok;
  const on = t?.common.on;
  const off = t?.common.off;
  const exit = t?.common.exit;
  const warning_unsaved_changes = t?.common.warning_unsaved_changes;
  const cancel = t?.common.cancel;
  const save = t?.common.save;
  const settings_updated = t?.notifications.settings_updated;
  const receive_email_notif_for = t?.hcp.manage.receive_email_notif_for;
  const receive_emails = t?.hcp.manage.receive_emails;
  const day_rolling_average = t?.hcp.manage.day_rolling_average;
  const days_t = t?.dashboard.hcp.profile_patient.completion_and_steps.days;
  const day_t = t?.dashboard.hcp.profile_patient.completion_and_steps.day;
  const email_t = t?.auth.email;
  const email_me = t?.hcp.manage.email_me;
  const email_my_colleague = t?.hcp.manage.email_my_colleague;
  const add_another_email = t?.hcp.manage.add_another_email;
  // const email_will_be_sent_to = t?.hcp.manage.email_will_be_sent_to;
  const valid_email = t?.validation.valid_email;

  // Endpoints
  const {
    _setHcpEmailNotif,
    setHcpEmailNotifLoading,
    setHcpEmailNotifError,
    isSetHcpEmailNotif,
  } = useSetHcpEmailNotifications();

  // Variables
  const dispatch = useDispatch();

  const {
    data,
    subSection,
    title,
    description,
    smSwitchName,
    smSwitchName_subType,
    smSwitchDescr,
    smSwitchDescr_subType,
  } = openSettingsData;
  const { emailNotifType, id } = data;
  const mailingFrequencyOptions = getMailingFrequencyOptions(t);
  const emptyEmailItem = { emailName: '', error: true };
  const emailsNormalaze = (emailsArr: string[]): ColleagueEmptyEmail[] =>
    // eslint-disable-next-line implicit-arrow-linebreak
    emailsArr.map((email: string) => ({ emailName: email, error: false }));

  const initValues = useMemo(
    () => ({
      isEmailNotifReceiving:
        data.isEmailNotifReceiving === null ? false : data.isEmailNotifReceiving,
      frequency: data.frequency
        ? getMailingFrequencyOptions(t)[data.frequency - 1]
        : mailingFrequencyOptions[0],
      percent: data.percent !== null ? String(data.percent) : '',
      value: data.value !== null ? String(data.value) : '',
      days: data.days ? String(data.days) : '',

      isEmailNotifReceiving_subType:
        subSection.isEmailNotifReceiving === null ? false : subSection.isEmailNotifReceiving,
      frequency_subType: subSection.frequency
        ? getMailingFrequencyOptions(t)[subSection.frequency - 1]
        : mailingFrequencyOptions[0],
      percent_subType: subSection.percent !== null ? String(subSection.percent) : '',
      value_subType: subSection.value !== null ? String(subSection.value) : '',
      days_subType: subSection.days ? String(subSection.days) : '',

      isEmailMe: data.isEmailMe,
      isEmailMyColleague: !!data.emails?.length,
      emails: data.emails?.length ? emailsNormalaze(data.emails) : [{ ...emptyEmailItem }],
    }),
    [data],
  );

  // Local state
  const [isUpdatedSuccess, setUpdatedSuccess] = useState(false);
  const [isOnSubTypeOne, setSubTypeOne] = useState(initValues.isEmailNotifReceiving);
  const [isOnSubTypeTwo, setSubTypeTwo] = useState(initValues.isEmailNotifReceiving_subType);
  const [isExitWarn, setExitWarn] = useState(false);

  // If set Hcp Email Notification success show popup
  useEffect(() => {
    if (isSetHcpEmailNotif) {
      setUpdatedSuccess(() => true);
    }
  }, [isSetHcpEmailNotif]);

  // If set Hcp Email Notification error show message
  useEffect(() => {
    if (setHcpEmailNotifError) {
      toast.error(setHcpEmailNotifError);
    }
  }, [setHcpEmailNotifError]);

  // Validation rules
  const getValidationSchema = (order: number): any => {
    // Turn on only subtype 1
    if (isOnSubTypeOne && !isOnSubTypeTwo) {
      if (order === 1 || order === 3) {
        return validationManadgeNotifTypeOneSubtypeOne(t);
      }
      if (order === 5) {
        return validationManadgeNotifTypeFiveSubtypeOne(t);
      }
    }
    // Turn on only subtype 2
    if (!isOnSubTypeOne && isOnSubTypeTwo) {
      if (order === 1) {
        return validationManadgeNotifTypeOneSubtypeTwo(t);
      }
      if (order === 3) {
        return validationManadgeNotifTypeThreeSubtypeTwo(t);
      }
      if (order === 5) {
        return validationManadgeNotifTypeFiveSubtypeTwo(t);
      }
    }
    // Turn on both subtypes
    if (isOnSubTypeOne && isOnSubTypeTwo) {
      if (order === 1) {
        return validationManadgeNotifTypeOneBothField(t);
      }
      if (order === 3) {
        return validationManadgeNotifTypeThreeBothSubtype(t);
      }
      if (order === 5) {
        return validationManadgeNotifTypeFiveBothSubtype(t);
      }
    }
    return validationEmptyFields();
  };

  const formik = useFormik({
    // enableReinitialize: true,
    initialValues: initValues,
    validate: (values) => validateManageNotif(values, t),
    validationSchema: () => getValidationSchema(emailNotifType),
    onSubmit: (val) => {
      const emails: string[] = [];
      if (val.isEmailMyColleague) {
        let colleagueEmailsError = false;
        val.emails.map((item: ColleagueEmptyEmail) => {
          if (item.error || !item.emailName) {
            colleagueEmailsError = true;
          }
          emails.push(item.emailName);
          return null;
        });
        if (colleagueEmailsError) return;
      }

      const request: any = [];
      // if small chekbox is true then save current changes
      if (val.isEmailNotifReceiving) {
        const requestSubTypeOne = {
          id,
          emailNotifType,
          isEmailNotifReceiving: val.isEmailNotifReceiving,
          frequency: val.frequency.value,
          value: val.value === null ? null : +val.value,
          percent: val.percent === null ? null : +val.percent,
          days: +val.days,
          isEmailMe: val.isEmailMe,
          emails,
        };
        request.push(requestSubTypeOne);
        // } else if (id) { // if small chekbox is false and notif exist.
      } else {
        // if small chekbox is false and notif exist.
        const requestSubTypeOne = {
          id,
          emailNotifType,
          isEmailNotifReceiving: val.isEmailNotifReceiving,
          frequency: id ? data.frequency : 0,
          value: data.value,
          percent: data.percent,
          days: data.days,
          isEmailMe: val.isEmailMe,
          emails,
        };
        request.push(requestSubTypeOne);
      }
      if (val.isEmailNotifReceiving_subType) {
        const requestsubTypeTwo = {
          id: subSection.id,
          emailNotifType: subSection.emailNotifType,
          isEmailNotifReceiving: val.isEmailNotifReceiving_subType,
          frequency: val.frequency_subType.value,
          value: val.value_subType === null ? null : +val.value_subType,
          percent: val.percent_subType === null ? null : +val.percent_subType,
          days: +val.days_subType,
          isEmailMe: val.isEmailMe,
          emails,
        };
        request.push(requestsubTypeTwo);
        // } else if (subSection.id) {
      } else {
        const requestsubType = {
          id: subSection.id,
          emailNotifType: subSection.emailNotifType,
          isEmailNotifReceiving: val.isEmailNotifReceiving_subType,
          frequency: subSection.id ? subSection.frequency : 0,
          value: subSection.value,
          percent: subSection.percent,
          days: subSection.days,
          isEmailMe: val.isEmailMe,
          emails,
        };
        request.push(requestsubType);
      }
      _setHcpEmailNotif(request);
    },
  });
  const { values, errors, touched } = formik;

  // Changing the Form Switch
  const switchHandler = (checked: boolean, type: string): void => {
    let fieldName = 'isEmailNotifReceiving';
    if (type === 'type-two') {
      fieldName = 'isEmailNotifReceiving_subType';
      setSubTypeTwo(checked);
    } else {
      setSubTypeOne(checked);
    }
    formik.setFieldValue(fieldName, checked);
  };

  // Select handler
  const selectFrecuencyMailing = (name: string, value: Options): void => {
    formik.setFieldValue(name, value);
  };

  // Get description for day input
  const getDayDescription = (): string => {
    if (emailNotifType === 3) {
      return day_rolling_average;
    }
    if (+values.days === 1) {
      return day_t;
    }
    return days_t;
  };

  // Choosing who to send an email to
  const emailCheckboxHandler = (e: any, fieldName: string): void => {
    const value = e.target.checked;
    formik.setFieldValue(fieldName, value);
  };

  // Update text for email input
  const changeEmailInput = (e: any, i: number): void => {
    const { value } = e.target;
    const newState = [...values.emails];
    newState[i].emailName = value;
    newState[i].error = !value.match(validEmail);
    formik.setFieldValue('emails', [...newState]);
  };

  // Delete email
  const deleteEmail = (i: number): void => {
    const newState = [...values.emails];
    if (newState.length === 1) {
      formik.setFieldValue('emails', [{ ...emptyEmailItem }]);
      return;
    }
    newState.splice(i, 1);
    formik.setFieldValue('emails', [...newState]);
  };

  // Add new email field
  const addNewEmailField = (): void => {
    if (values.emails[values.emails.length - 1].emailName) {
      const newState = [...values.emails];
      newState.push({ ...emptyEmailItem });
      formik.setFieldValue('emails', [...newState]);
    }
  };

  // Emoji selection handler
  const onSelectEmoji = (emojiId: string, fieldName: string): void => {
    formik.setFieldValue(fieldName, emojiId);
  };

  // Close Settings page
  const closeSettings = (): void => {
    dispatch(setOpenSettingsData(undefined));
  };

  // Cancel changes and clouse settings
  const onCancel = (): void => {
    if (formik.dirty) {
      setExitWarn(() => true);
      return;
    }
    closeSettings();
  };

  // Clouse success modal window
  const onClouseSuccessModal = (): void => {
    updateData();
    setUpdatedSuccess(() => false);
    closeSettings();
  };

  // Confirm exit from settings
  const onConfirmExit = async (): Promise<void> => {
    setExitWarn(() => false);
    // formik.resetForm();
    closeSettings();
  };

  // Close Exit warning popup
  const onCloseExitWarn = (): void => {
    setExitWarn(() => false);
  };

  // JSX
  const loadingJSX = setHcpEmailNotifLoading && <Loading />;
  const daysInputJsx = (
    <div
      className={cx({
        [style['email-notif__field--32']]: emailNotifType === 1,
        [style['email-notif__field--55']]: emailNotifType === 3,
        [style['email-notif__field--days']]: emailNotifType === 5,
      })}
    >
      <InputFloatLabelWithDescription
        fieldId={`ManageNotifValue_days_${emailNotifType}_${unicPrefix}`}
        inputName="days"
        inputType="text"
        labelName=""
        hasErrors={errors.days}
        isTouched={touched.days}
        inputValue={values.days}
        onChecngeMethod={formik.handleChange}
        placeholderText={openSettingsData.dayFieldName}
        description={getDayDescription()}
      />
    </div>
  );
  const daysInputJsx_sub = (
    <div
      className={cx({
        [style['email-notif__field--32']]: emailNotifType === 1,
        [style['email-notif__field--55']]: emailNotifType === 3,
        [style['email-notif__field--days']]: emailNotifType === 5,
      })}
    >
      <InputFloatLabelWithDescription
        fieldId={`ManageNotifValue_days_subType_${unicPrefix}`}
        inputName="days_subType"
        inputType="text"
        labelName=""
        hasErrors={errors.days_subType}
        isTouched={touched.days_subType}
        inputValue={values.days_subType}
        onChecngeMethod={formik.handleChange}
        placeholderText={openSettingsData.dayFieldName_subType}
        description={getDayDescription()}
      />
    </div>
  );

  return (
    <>
      {loadingJSX}
      <div className={style['email-notif__title-wrapper--bg']}>
        <MainTitle title={title} />
      </div>
      <div className={style['email-notif__subtitle']}>{description}</div>
      <div className={style['email-notif__section-description']}>{receive_email_notif_for}</div>
      <div className={style.spacer} />

      <form onSubmit={formik.handleSubmit}>
        <div className={style['email-notif__form-wrapper']}>
          {/* Subtype 1 */}
          <div
            className={cx({
              [style.row]: true,
              [style['padding-bottom']]: true,
            })}
          >
            <div className={style['email-notif__title-container']}>
              <div className={style['email-notif__title']}>{smSwitchName}</div>
              <div className={style['email-notif__description']}>{smSwitchDescr}</div>
            </div>

            <div className={style['email-notif__switch-wrapper']}>
              <div className={style['email-notif__switch']}>
                <Switch
                  checkedChildren={<span>{on}</span>}
                  unCheckedChildren={<span>{off}</span>}
                  checked={values.isEmailNotifReceiving}
                  onChange={(val: any): void => switchHandler(val, 'type-one')}
                />
              </div>
            </div>
          </div>
          {values.isEmailNotifReceiving && (
            <>
              <SelectFloatLabel
                inputId={`frequencySubtypeOneId_${unicPrefix}`}
                name="frequency"
                placeholder={receive_emails}
                onChange={selectFrecuencyMailing}
                options={mailingFrequencyOptions}
                hasErrors={false}
                isTouched={false}
                selected={values.frequency}
                inputValue={values.frequency}
                type="email-notif"
              />

              {emailNotifType === 5 ? (
                <div className={style['manage-notif__field--emoji-field']}>
                  <div className={style['manage-notif__label']}>
                    {openSettingsData.percentFieldName}
                  </div>

                  <div
                    className={cx({
                      [style['manage-notif__emojis-container']]: true,
                      [style['manage-notif__emojis-container--invalid']]:
                        touched.value && errors.value,
                    })}
                  >
                    {emojies.map((emoji: EmojiesConfig, inx: number) => (
                      <div key={`Emoji${emoji.id}`}>
                        <div className={style['manage-notif__emoji-index']}>{inx}</div>
                        <div className={style['manage-notif__emoji-wrapper']}>
                          <Emoji
                            id={emoji.id}
                            selectedEmoji={emoji.selectedEmoji}
                            defaultEmoji={emoji.defaultEmoji}
                            onSelect={(emojiId: string): void => onSelectEmoji(emojiId, 'value')}
                            selected={values.value === emoji.id}
                            anySelected={!!values.value}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                  {touched.value && !!errors.value ? (
                    <div
                      className={cx({
                        [style['manage-notif__emojis-error']]: true,
                        [style['margin-bottom']]: true,
                      })}
                    >
                      {errors.value}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <></>
              )}

              {emailNotifType === 5 ? (
                daysInputJsx
              ) : (
                <div className={style['email-notif__exerc-compl-fields-container']}>
                  <div
                    className={cx({
                      [style['email-notif__field--65']]: emailNotifType === 1,
                      [style['email-notif__field--42']]: emailNotifType === 3,
                    })}
                  >
                    <InputFloatLabelWithDescription
                      fieldId={`ManageNotifValue_percent_${emailNotifType}_${unicPrefix}`}
                      inputName="percent"
                      inputType="text"
                      hasErrors={errors.percent}
                      isTouched={touched.percent}
                      inputValue={values.percent}
                      onChecngeMethod={formik.handleChange}
                      placeholderText={openSettingsData.percentFieldName}
                      description="%"
                      labelName=""
                    />
                  </div>
                  {daysInputJsx}
                </div>
              )}
            </>
          )}
        </div>
        <div className={style.spacer} />

        {/* Subtype 2 */}
        <div className={style['email-notif__form-wrapper']}>
          <div
            className={cx({
              [style.row]: true,
              [style['padding-bottom']]: true,
            })}
          >
            <div className={style['email-notif__title-container']}>
              <div className={style['email-notif__title']}>{smSwitchName_subType}</div>
              <div className={style['email-notif__description']}>{smSwitchDescr_subType}</div>
            </div>

            <div className={style['email-notif__switch-wrapper']}>
              <div className={style['email-notif__switch']}>
                <Switch
                  checkedChildren={<span>{on}</span>}
                  unCheckedChildren={<span>{off}</span>}
                  checked={values.isEmailNotifReceiving_subType}
                  onChange={(val: any): void => switchHandler(val, 'type-two')}
                />
              </div>
            </div>
          </div>
          {values.isEmailNotifReceiving_subType && (
            <>
              <SelectFloatLabel
                inputId={`frequencySubtypeOne_subtypeId_${unicPrefix}`}
                name="frequency_subType"
                placeholder={receive_emails}
                onChange={selectFrecuencyMailing}
                options={mailingFrequencyOptions}
                hasErrors={false}
                isTouched={false}
                selected={values.frequency_subType}
                inputValue={values.frequency_subType}
                type="email-notif"
              />

              {emailNotifType === 5 ? (
                <div className={style['manage-notif__field--emoji-field']}>
                  <div className={style['manage-notif__label']}>
                    {openSettingsData.percentFieldName_subType}
                  </div>

                  <div
                    className={cx({
                      [style['manage-notif__emojis-container']]: true,
                      [style['manage-notif__emojis-container--invalid']]:
                        touched.value_subType && errors.value_subType,
                    })}
                  >
                    {emojies.map((emoji: EmojiesConfig, inx: number) => (
                      <div key={`Emoji_sub${emoji.id}`}>
                        <div className={style['manage-notif__emoji-index']}>{inx}</div>
                        <div className={style['manage-notif__emoji-wrapper']}>
                          <Emoji
                            id={emoji.id}
                            selectedEmoji={emoji.selectedEmoji}
                            defaultEmoji={emoji.defaultEmoji}
                            onSelect={(emojiId: string) => onSelectEmoji(emojiId, 'value_subType')}
                            selected={values.value_subType === emoji.id}
                            anySelected={!!values.value_subType}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                  {touched.value_subType && !!errors.value_subType ? (
                    <div
                      className={cx({
                        [style['manage-notif__emojis-error']]: true,
                        [style['margin-bottom']]: true,
                      })}
                    >
                      {errors.value_subType}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <></>
              )}

              {emailNotifType === 5 ? (
                daysInputJsx_sub
              ) : (
                <div className={style['email-notif__btns-container']}>
                  <div
                    className={cx({
                      [style['email-notif__field--65']]: emailNotifType === 1,
                      [style['email-notif__field--42']]: emailNotifType === 3,
                    })}
                  >
                    <InputFloatLabelWithDescription
                      fieldId={`ManageNotifValue_percent_sub_${emailNotifType}_${unicPrefix}`}
                      inputName={emailNotifType === 3 ? 'value_subType' : 'percent_subType'}
                      inputType="text"
                      placeholderText={openSettingsData.percentFieldName_subType}
                      hasErrors={
                        emailNotifType === 3 ? errors.value_subType : errors.percent_subType
                      }
                      isTouched={
                        emailNotifType === 3 ? touched.value_subType : touched.percent_subType
                      }
                      inputValue={
                        emailNotifType === 3 ? values.value_subType : values.percent_subType
                      }
                      onChecngeMethod={formik.handleChange}
                      labelName=""
                      description={emailNotifType === 3 ? '' : '%'}
                    />
                  </div>
                  {daysInputJsx_sub}
                </div>
              )}
            </>
          )}
        </div>

        {/* Manage Email section */}
        {(values.isEmailNotifReceiving || values.isEmailNotifReceiving_subType) && (
          <>
            <div className={style['email-notif__email_checkbox-row']}>
              <CustomCheckbox
                htmlId={`isEmailMe_${unicPrefix}`}
                name="isEmailMe"
                checked={values.isEmailMe}
                onChangeMethod={(e: any): void => emailCheckboxHandler(e, 'isEmailMe')}
                isTouched={touched.isEmailMe}
                hasErrors={errors.isEmailMe}
                bigSize
              />
              <div className={style['email-notif__email_checkbox-description']}>{email_me}</div>
            </div>
            <div
              className={cx({
                [style['email-notif__email_checkbox-row']]: true,
                [style['margin-bottom']]: true,
              })}
            >
              <CustomCheckbox
                htmlId={`isEmailMyColleague_${unicPrefix}`}
                name="isEmailMyColleague"
                checked={values.isEmailMyColleague}
                onChangeMethod={(e: any): void => emailCheckboxHandler(e, 'isEmailMyColleague')}
                isTouched={touched.isEmailMe}
                hasErrors={errors.isEmailMe}
                bigSize
              />
              <div className={style['email-notif__email_checkbox-description']}>
                {email_my_colleague}
              </div>
            </div>
            {touched.isEmailMe && errors.isEmailMe && (
              <div className={style['manage-notif__emojis-error']}>{errors.isEmailMe}</div>
            )}
            {values.isEmailMyColleague && (
              <>
                {values.emails.map((item: ColleagueEmptyEmail, i: number) => (
                  <div className={style['email-notif__email_field']} key={`emails${String(i)}`}>
                    <InputFloatLabel
                      inputId={`emails${i}_${unicPrefix}`}
                      inputName={`emails${i}`}
                      inputType="text"
                      placeholder={email_t}
                      isRedStar={i === 0}
                      isTouched={touched.emails}
                      hasErrors={item.error && valid_email}
                      inputValue={item.emailName}
                      onChangeMethod={(e: any): void => changeEmailInput(e, i)}
                    />
                    <div
                      className={cx({
                        [style['manage-notif__email_delete']]: true,
                        [style['manage-notif__email_delete--first-elem']]: i === 0,
                      })}
                      onClick={(): void => deleteEmail(i)}
                      role="presentation"
                    >
                      <DeleteIcon />
                    </div>
                  </div>
                ))}
                <div
                  className={cx({
                    [style['manage-notif__add-another-email']]: true,
                    [style['manage-notif__add-another-email--disable']]: !values.emails[
                      values.emails.length - 1
                    ].emailName,
                  })}
                  aria-hidden
                  onClick={addNewEmailField}
                >
                  +{add_another_email}
                </div>
              </>
            )}
          </>
        )}

        <div className={style['email-notif__btns-container']}>
          <Button
            buttonName={cancel}
            buttonType="button"
            buttonClass={style['email-notif__btn']}
            buttonMethod={onCancel}
          />
          <Button
            buttonName={save}
            buttonType="submit"
            buttonClass={style['email-notif__btn']}
            disabledButton={!formik.dirty}
          />
        </div>
      </form>

      {/* Popups */}
      {isUpdatedSuccess && (
        <SuccessNotifModal
          onClose={onClouseSuccessModal}
          btnName={ok}
          description={settings_updated}
        />
      )}

      {/* Warning popup if has not saved changes */}
      {isExitWarn && (
        <PopupWithTwoButtons
          title={warning_unsaved_changes}
          content=""
          confirmButtonName={exit}
          closeButtonName={cancel}
          onConfirm={onConfirmExit}
          onClosePopup={onCloseExitWarn}
        />
      )}
    </>
  );
};
