import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Tooltip } from 'antd';

import styles from '../VideoExercise/styles.module.css';
import { GetState, getCurrenLang, getCurrentFormatDate } from '../../../../../redux/selector';
import { FolderIcon, ClockIcon } from '../../../../../theme/icons';
import { PERMISSIONS } from '../../../../../utils/enums';
import useCheckPermissions from '../../../../../hooks/useCheckPermissions';
import { path } from '../../../../../utils';

const FolderRow = ({ data }: any): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const settings_t = t?.dashboard.hcp.profile_patient.video_bank.settings;
  const you_dont_have_permission = t?.common.you_dont_have_permission;

  const history = useHistory();
  const formatsDate = useSelector((state: GetState) => getCurrentFormatDate(state));
  const { updatedByHcp, folderUpdatedAt } = data;
  const isPermissionEditVideoExerc = useCheckPermissions(
    PERMISSIONS.EDIT_OR_PRESCRIBE_EXERCISE_VIDEOS_OTM,
  );

  // Redirect to inside folder
  const openFolder = (folderId: string): void => {
    if (!isPermissionEditVideoExerc) return;
    const pathname = path.orgVideoBankFolder.replace(':folder', `${folderId}`);
    history.push({
      // pathname: `/dashboard/organisation-video-bank/folder/${folderId}`,
      // pathname: `${path.orgVideoBankFolder}/${folderId}`,
      pathname,
    });
  };

  return (
    <div>
      <div
        className={cx({
          [styles['video-exercise__row']]: true,
          // [styles['video-exercise__row-disable']]: isNotValidExercise || isRecentlyDeleted,
        })}
      >
        <div className={styles['video-exercise__checkbox-container']} />

        {/* Thumbnail */}
        <div
          className={cx({
            [styles['video-exercise__block']]: true,
          })}
        >
          <div
            onClick={(): void => openFolder(data.currentId)}
            aria-hidden
            className={cx({
              [styles.preview]: true,
            })}
          >
            <FolderIcon />
            {data.hcpFolderTimer?.amount && (
              <div className={styles['video-folder__clock']}>
                <ClockIcon />
              </div>
            )}
          </div>
        </div>

        {/* Name and Description */}
        <div className={styles['video-exercise__name-container']}>
          <div className={styles['video-exercise__name']}>{data.name}</div>
          <div className={styles['video-exercise__description--folder']}>{data.description}</div>
          {/* Sm screen */}
          <div className={styles['video-exercise__action--sm']}>
            <Tooltip title={!isPermissionEditVideoExerc ? you_dont_have_permission : ''}>
              <div
                className={cx({
                  [styles['video-exercise__btn--settings']]: true,
                  [styles['video-exercise__btn--disabled']]: !isPermissionEditVideoExerc,
                })}
                onClick={(): void => openFolder(data.currentId)}
                aria-hidden
              >
                {settings_t}
              </div>
            </Tooltip>
          </div>
        </div>

        {/* Setting last changed */}
        <div className={styles['video-exercise__date-container']}>
          {updatedByHcp && (
            <>
              <div className={styles['video-exercise__date']}>
                {moment(folderUpdatedAt).format(formatsDate.momentFormat)}
              </div>
              <div className={styles['video-exercise__date-author']}>{updatedByHcp}</div>
            </>
          )}
        </div>

        {/* Action */}
        <div className={styles['video-exercise__action']}>
          <Tooltip title={!isPermissionEditVideoExerc ? you_dont_have_permission : ''}>
            <div
              className={cx({
                [styles['video-exercise__btn--settings']]: true,
                [styles['video-exercise__btn--disabled']]: !isPermissionEditVideoExerc,
              })}
              onClick={(): void => openFolder(data.currentId)}
              aria-hidden
            >
              {settings_t}
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default FolderRow;
