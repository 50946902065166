import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';
import cx from 'classnames';

import styles from '../../../styles.module.css';
import style from './style.module.css';
import { GetState, getCurrenLang, getHcpNameLoading } from '../../../../redux/selector';
import { BreadcrumbAnt } from '../../../../utils/routers/Breadcrumb';
import { Loading, MainTitle } from '../../../../common';
import { path } from '../../../../utils';
import ExerciseFolderSettings from '../../../../components/Video/OrganisationVideoBank/components/ExerciseFolderSettings';
import useCheckPermissions from '../../../../hooks/useCheckPermissions';
import { PERMISSIONS } from '../../../../utils/enums';
import { useQueryCurrentFolder } from '../../../../graphql/videoBank';
import { getScheduleNumberOptions, getUnitOptions } from '../../../../utils/share/options';
import useGetFocuses from '../../../../hooks/useGetFocuses';
import { RedTickIcon } from '../../../../theme/icons';

const ExerciseFolderSettingPage = (): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const you_dont_have_permission = t?.common.you_dont_have_permission;
  const org_video_bank = t?.title.org_video_bank;
  const exercise_folder_settings = t?.title.exercise_folder_settings;
  const saved = t?.common.saved;

  const isPermissionsAddVideosToTeamVideoBank = useCheckPermissions(
    PERMISSIONS.ADD_VIDEOS_TO_TEAM_VIDEOBANK_OTM,
  );
  const loadingPermission = useSelector<GetState>((state) => getHcpNameLoading(state));
  const isNoPermissions = !loadingPermission && !isPermissionsAddVideosToTeamVideoBank;

  // Local state
  const [videoExersisesList, setVideoExersisesList] = useState<any>();
  const [dataFolderState, setdataFolderState] = useState<any>(null);
  const [isSuccessSaved, setSuccessSaved] = useState(false);
  const [recentlyDeleted, setRecentlyDeleted] = useState([]);

  const { params } = useRouteMatch();
  const { folder }: any = params;
  const folderId = Number(folder);
  const unitOptions = t ? getUnitOptions(t.dashboard.hcp.profile_patient.completion_and_steps) : [];
  const numberOptions = getScheduleNumberOptions();

  // Endpoints
  const {
    _getFolderSettings,
    dataFolder,
    loadingFolder,
    errorFolder,
    data,
  } = useQueryCurrentFolder();

  const {
    focusType1Arr,
    focusType2Arr,
    exercisesCategories,
    exercisesProcedureFocus,
    // procedureFilterConfig,
    loadingFocuses,
  } = useGetFocuses();

  useEffect(() => {
    // Get a list of categories from the database
    // _getExerciseCategoriesList();

    // Get folder data
    _getFolderSettings({
      variables: {
        hcpFolderId: folderId,
        sortProps: { sortBy: 'name', sortDirection: '' },
        searchProps: {
          searchField: 'name',
          searchValue: '',
        },
      },
    });
  }, []);

  // If there is an error, we show the message
  useEffect(() => {
    if (errorFolder) {
      toast.error(errorFolder);
    }
  }, [errorFolder]);

  // After receiving the list of team exercises from the database, display them on the UI
  useEffect(() => {
    if (dataFolder && !loadingFolder) {
      const recentlyDeletedIds: any = [];
      if (recentlyDeleted.length > 0) {
        recentlyDeleted.map((exerc: { id: number }) => {
          recentlyDeletedIds.push(exerc.id);
        });
        if (dataFolder.folderExercises?.length) {
          const fitredList = dataFolder.folderExercises.filter(
            (exercise: { id: number }) => !recentlyDeletedIds.includes(exercise.id),
          );
          setVideoExersisesList(fitredList);
        } else {
          setVideoExersisesList(dataFolder.folderExercises);
        }
      } else {
        setVideoExersisesList(dataFolder.folderExercises);
      }
      setdataFolderState(dataFolder);
    } else if (!loadingFolder) {
      setVideoExersisesList(null);
      setdataFolderState(null);
    }
  }, [data]);

  // Breadcrumb path
  const routes = [
    {
      path: path.organisationVideoBank,
      breadcrumbName: org_video_bank,
    },
    {
      path: exercise_folder_settings,
      breadcrumbName: exercise_folder_settings,
    },
  ];

  // JSX
  const loadingJSX = (loadingFolder || loadingFocuses) && <Loading />;

  return (
    <div className={styles.main__container}>
      {loadingJSX}
      <BreadcrumbAnt currentRoutes={routes} />
      <div className={cx([styles.main__wrapper, styles['main__wrapper--without-pd']])}>
        <div
          className={cx({
            [style['folder-setting__title-container']]: true,
            [styles['left-section']]: true,
          })}
        >
          <div className={styles['main__wrapper--pd']}>
            <MainTitle title={exercise_folder_settings} />
          </div>
          {isSuccessSaved && (
            <div className={style['folder-setting__saved-container']}>
              <RedTickIcon />
              <div className={style['folder-setting__saved']}>{saved}</div>
            </div>
          )}
        </div>

        {!isNoPermissions && dataFolderState && t && (
          <ExerciseFolderSettings
            folderId={folderId}
            dataFolder={dataFolderState}
            _getFolderSettings={_getFolderSettings}
            loadingFolder={loadingFolder}
            videoExersisesList={videoExersisesList}
            setVideoExersisesList={setVideoExersisesList}
            unitOptions={unitOptions}
            numberOptions={numberOptions}
            focusType1Arr={focusType1Arr}
            focusType2Arr={focusType2Arr}
            exercisesCategories={exercisesCategories}
            exercisesProcedureFocus={exercisesProcedureFocus}
            setSuccessSaved={setSuccessSaved}
            isSuccessSaved={isSuccessSaved}
            recentlyDeleted={recentlyDeleted}
            setRecentlyDeleted={setRecentlyDeleted}
          />
        )}
        {isNoPermissions ? (
          <div className={styles.nopermissions}>{you_dont_have_permission}</div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default ExerciseFolderSettingPage;
