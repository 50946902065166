import React, { ReactElement } from 'react';

import styles from './styles.module.css';
import { MainTitleProps } from './types';
import { Info } from '../Hint';

const MainTitle = ({ icon, title, content, infoContent }: MainTitleProps): ReactElement => (
  <div className={styles['main-title__container']}>
    {icon ? <div className={styles['main-title__icon']}>{icon}</div> : <></>}
    <div className={styles['main-title__text']}>{title}</div>
    {content || <></>}
    {infoContent && (
      <div className={styles['main-title__info-wrapper']}>
        <Info tip={infoContent} />
      </div>
    )}
  </div>
);

export default MainTitle;
