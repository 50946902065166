import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { getCurrenLang } from '../../redux/selector';
import { InputFloatLabel } from '../../common/Input';
import { Button } from '../../common/Button/Button';
import styles from './styles.module.css';
import { ShowErrorNotification } from './ErrorMessage';
import { InputPhoneNumberFloatLabel } from '../../common/Input/PhoneNumber';
import { ReserInviteProps } from './types';

export const ReserInvite = ({
  errorMessage,
  hasErrors,
  inputValue,
  isTouched,
  onChangeMethod,
  setFieldValue,
}: ReserInviteProps): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const resend_invite = t?.dashboard.hcp.table.resend_invite;
  const email_t = t?.invite_patient.email;

  return (
    <div className={styles.form_wrapper}>
      <InputFloatLabel
        inputId="resendInviteEmail"
        placeholder={email_t}
        inputName="email"
        inputType="email"
        hasErrors={hasErrors.email}
        inputValue={inputValue.email}
        isTouched={isTouched.email}
        onChangeMethod={onChangeMethod}
      />
      <InputPhoneNumberFloatLabel
        inputId="resendInvitePhone"
        hasErrors={hasErrors.phone}
        value={inputValue.phone}
        isTouched={isTouched.phone}
        onChange={(date: string): void => setFieldValue('phone', date)}
        placeholder={`${t?.profile_hcp.number_phone}`}
      />
      {errorMessage && (
        <div className={styles.errorMessages}>
          <ShowErrorNotification errorMessage={errorMessage} />
        </div>
      )}

      <Button buttonType="submit" buttonName={resend_invite} buttonClass={styles.btn__submit} />
    </div>
  );
};

export const ReserCode = ({
  errorMessage,
  hasErrors,
  inputValue,
  isTouched,
  onChangeMethod,
  setFieldValue,
}: ReserInviteProps): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const resend_code = t?.dashboard.hcp.table.resend_code;
  const email_t = t?.invite_patient.email;

  return (
    <div className={styles.form_wrapper}>
      <InputFloatLabel
        inputId="resendEmail"
        placeholder={email_t}
        inputName="email"
        inputType="email"
        hasErrors={hasErrors.email}
        inputValue={inputValue.email}
        isTouched={isTouched.email}
        onChangeMethod={onChangeMethod}
      />
      {errorMessage && (
        <div className={styles.errorMessages}>
          <ShowErrorNotification errorMessage={errorMessage} />
        </div>
      )}

      <Button buttonType="submit" buttonName={resend_code} buttonClass={styles.btn__submit} />
    </div>
  );
};
