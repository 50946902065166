/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow */
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { toast } from 'react-toastify';

import style from './styles.module.css';
import { GetState, getCurrenLang } from '../../../redux/selector';
import { SortSearchItem } from '../../Video/OrganisationVideoBank/components/HeaderItems';
import AddedUsers from '../AddedUsers';
import { PrevState, TListHCP } from '../../../utils/model';
import { Button, PlusNewElem } from '../../../common/Button/Button';
import useCheckPermissions from '../../../hooks/useCheckPermissions';
import { PERMISSIONS } from '../../../utils/enums';
import { initialPage, showTheStatus } from '../../../utils/helper';
import { useHcpListForSearchHcp } from '../../../graphql/hcp';
import { Loading, Modal } from '../../../common';
import ListHcpModalContent from '../HcpModalContent/ListHcp';
import InviteHcpModal from '../HcpModalContent/InviteHcp';
import { CurrentHcpType } from '../types';
import {
  useAddHcpsToPatient,
  useGetHcpsForPatientMatch,
  useGetHcpsForPatientRehabTeam,
} from '../../../graphql/hospitalTeam';
import { Popup } from '../../../common/Popup';
import StatusWithMarker from '../../../common/Elements/StatusWithMarker';

const OtherHcpTable = ({ patientId }: any): ReactElement => {
  const t: any = useSelector<GetState>((state) => getCurrenLang(state));
  const healthcare_professionals = t?.dashboard.admin.tabs.healthcare_professionals;
  const profession = t?.dashboard.admin.table.hcp.profession;
  const status = t?.dashboard.admin.table.hcp.status;
  const add_hcp = t?.common.add_hcp;
  const invite_hcp_title = t?.title.invite_hcp;
  const save_t = t?.common.save;
  const no_data = t?.common.no_data;
  const hit_save_to_send = t?.modal.hit_save_to_send;
  const ok = t?.common.ok;

  const isPermissionAddAnotherHcp = useCheckPermissions(PERMISSIONS.INVITE_OR_ADD_HCP_OTM);
  const isPermissionEditProfile = useCheckPermissions(PERMISSIONS.EDIT_PATIENT_PROFILE_INFO_OTM);

  // Endpoints
  const {
    _getHcpListForSearchHcp,
    hcpListLoading,
    hcpList,
    totalLength,
  } = useHcpListForSearchHcp();
  const {
    _addHcpsToPatient,
    addHcpsToPatientData,
    addHcpsToPatientError,
    addHcpsToPatientLoading,
  } = useAddHcpsToPatient();
  const {
    _getHcpsForPatient,
    data,
    hcpsForPatient,
    hcpsForPatientError,
    hcpsForPatientLoading,
  } = useGetHcpsForPatientRehabTeam();
  const {
    _getHcpsForPatientMatch,
    hcpsForPatientMatch,
    hcpsForPatientLoadingMatch,
  } = useGetHcpsForPatientMatch();

  // Local state
  const [addedList, setAddedList] = useState<any>([]);
  const [isModalOpen, setModal] = useState<boolean>(false);
  const [isInviteAlertModalOpen, setInviteAlertModal] = useState<boolean>(false);
  const [list, setList] = useState([]);
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filter, setFilter] = useState<string>('');
  const [filterQuery, setFilterQuery] = useState<string>('');
  const [inviteHCP, setIniteHCP] = useState<boolean>(false);
  const [checkedHcpId, setCheckedHcpId] = useState<any>([]);
  const [currentListHcp, setCurrentListHcp] = useState<any>([]);
  const [prevCurrentListHcp, setPrevCurrentListHcp] = useState<any>([]);
  const [cleanCurrentListHcp, setCleanCurrentListHcp] = useState<boolean>(true);
  const [prevCheckedHcpId, setPrevCheckedHcpId] = useState<any>([]);

  const [sortBy, setSortby] = useState<string>('hcp.firstName');
  const [sortDirectionName, setSortDirectionName] = useState<string>('');
  const [isSearch, setIsSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [hideSearchMenu, setHideSearchMenu] = useState(false);
  const [hcpListForPatient, setHcpListForPatient] = useState<CurrentHcpType[]>();

  const addNewHcpsToPtient = () => {
    const hcpIds: number[] = addedList.map((a: TListHCP) => a.id);
    _addHcpsToPatient({ patientId, hcpIds });
  };

  useEffect(() => {
    if (addHcpsToPatientError) {
      toast.error(addHcpsToPatientError);
    }
  }, [addHcpsToPatientError]);

  // Get HCP list
  useEffect(() => {
    if (patientId) {
      _getHcpsForPatient(patientId, searchQuery, sortBy, sortDirectionName);
    }
  }, [patientId]);

  // Update HCP list and clear state
  useEffect(() => {
    if (addHcpsToPatientData && !addHcpsToPatientLoading) {
      _getHcpsForPatient(patientId, searchQuery, sortBy, sortDirectionName);
      setAddedList([]);
      setCurrentListHcp([]);
      setCheckedHcpId([]);
    }
  }, [addHcpsToPatientData]);

  // Fill data to state
  useEffect(() => {
    if (hcpsForPatient && !hcpsForPatientLoading) {
      setHcpListForPatient(hcpsForPatient);
    }
  }, [data]);

  // Check already selected HCPs
  const selectedHcp = useMemo((): number[] => {
    if (hcpListForPatient) {
      return hcpListForPatient.reduce(
        (accum: number[], item: CurrentHcpType) => [...accum, item.id],
        [],
      );
    }
    return [];
  }, [hcpListForPatient]);

  // Add new hcp modal window
  const onOpenModal = (): void => {
    setFilter('');
    setFilterQuery('');
    setList([]);
    setModal(() => !isModalOpen);
  };

  // Close modl window
  const _onclose = (): void => {
    setCurrentListHcp(cleanCurrentListHcp ? [] : prevCurrentListHcp);
    setCheckedHcpId(cleanCurrentListHcp ? [] : prevCheckedHcpId);
    setCurrentPage(initialPage);
    setModal(() => !isModalOpen);
    setIniteHCP(false);
  };

  const onSubmit = (): void => {
    setModal(() => !isModalOpen);
    setCleanCurrentListHcp(false);
    setPrevCurrentListHcp(currentListHcp);
    setPrevCheckedHcpId(checkedHcpId);
    setCurrentPage(initialPage);

    const array = currentListHcp.concat(list);
    const removeduplicates = array.filter((elem: any, pos: number) => array.indexOf(elem) === pos);
    setAddedList(() => [...removeduplicates]);
    if (removeduplicates && removeduplicates.length > 0) {
      setInviteAlertModal(() => true);
    }
  };

  // Remove HCP from local state
  const removeHcp = (taskId: number): void => {
    const a = (prevState: PrevState[]): PrevState[] => prevState.filter(({ id }) => id !== taskId);
    setAddedList(a);
    setCurrentListHcp(a);
    setCheckedHcpId((prev: any) => prev.filter((id: number) => id !== taskId));
  };

  const updateCompleted = (taskId: number, item: any): void => {
    setCurrentListHcp((currentHcp: any) => {
      const newItem = currentHcp.find((el: any) => el.id === item.id);
      return currentHcp.length === 0 || !newItem
        ? [...currentHcp, item]
        : currentHcp.filter((el: any) => el.id !== item.id);
    });
    setCheckedHcpId((prev: any) =>
      prev.length === 0 || !prev.includes(taskId)
        ? [...prev, taskId]
        : prev.filter((el: any) => el !== taskId),
    );
  };

  // Change current page number for HCP list in modal window
  const onChangePagination = (page: number): void => {
    setCurrentPage(page);
    _getHcpListForSearchHcp({
      variables: {
        listProps: {
          page,
          itemsPerPage: pageSize,
        },
        searchProps: [
          {
            searchField: 'hcp.firstName',
            searchValue: filter,
          },
          {
            searchField: 'hcp.lastName',
            searchValue: filter,
          },
          {
            searchField: 'hcp.email',
            searchValue: filter,
          },
        ],
      },
    });
  };

  useEffect(() => {
    if (filterQuery) {
      _getHcpListForSearchHcp({
        variables: {
          listProps: {
            page: 1,
            itemsPerPage: pageSize,
          },
          searchProps: [
            {
              searchField: 'hcp.firstName',
              searchValue: filter,
            },
            {
              searchField: 'hcp.lastName',
              searchValue: filter,
            },
            {
              searchField: 'hcp.email',
              searchValue: filter,
            },
          ],
        },
      });
    }
  }, [filterQuery]);

  const onInvite = (): void => {
    setIniteHCP(true);
  };
  // Search and sort
  /* * search for matches in the database * */
  const [searchMatches, setSearchMatches] = useState(null);

  // Find search matches in DB
  const findMatches = (searchVal: string): void => {
    if (!searchVal) {
      setSearchMatches(null);
      return;
    }
    _getHcpsForPatientMatch(patientId, searchVal);
  };

  // Show the result from the database in the search menu
  useEffect(() => {
    if (!hideSearchMenu) return;
    if (!hcpsForPatientLoadingMatch) {
      const current = hcpsForPatientMatch.length;
      setSearchMatches(current);
    }
  }, [hcpsForPatientMatch]);

  // Sort exercise list by name
  const sortByColumnName = (name: string, sortdirection: string): void => {
    setSortby(name);
    setSortDirectionName(sortdirection);
    _getHcpsForPatient(patientId, searchQuery, name, sortdirection);
  };

  // Get search value by HCP name column
  const handleSearch = (val: string): void => {
    setSearchQuery(val);
    setSearchMatches(null);
    setHideSearchMenu(!hideSearchMenu);
    if (val) {
      _getHcpsForPatient(patientId, val, sortBy, sortDirectionName);
      setIsSearch(() => true);
      setCurrentPage(initialPage);
    }
  };

  // Reset search exercise name
  const handleReset = (): void => {
    setSearchQuery('');
    setSearchQuery('');
    setSearchMatches(null);
    setHideSearchMenu(!hideSearchMenu);
    if (searchQuery) {
      _getHcpsForPatient(patientId, '', sortBy, sortDirectionName);
      setIsSearch(() => false);
      setCurrentPage(initialPage);
    }
  };

  const onCloseInviteAlertModal = (): void => {
    setInviteAlertModal(() => false);
  };

  // JSX
  const loadingJSX = (addHcpsToPatientLoading ||
    hcpsForPatientLoading ||
    hcpsForPatientLoadingMatch) && <Loading />;
  const noData = !hcpListForPatient && !hcpsForPatientError && (
    <div className={style.noData}>{no_data}</div>
  );
  const errorJsx = hcpsForPatientError && <div className={style.error}>{hcpsForPatientError}</div>;

  return (
    <div className={style['other-hcp__container']}>
      {loadingJSX}
      {/* Header */}
      <div className={style['other-hcp__header']}>
        <div className={style['other-hcp__header--team']}>
          <SortSearchItem
            fieldName={healthcare_professionals}
            t={t}
            sortName="hcp.firstName"
            sortByColumnName={sortByColumnName}
            sortBy={sortBy}
            withSearch
            handleSearch={handleSearch}
            handleReset={handleReset}
            searchMatches={searchMatches}
            findMatches={findMatches}
            hideSearchMenu={hideSearchMenu}
            isSearch={isSearch}
            setHideSearchMenu={setHideSearchMenu}
            setSearchMatches={setSearchMatches}
          />
        </div>
        <div className={style['other-hcp__header--profession']}>{profession}</div>
        <div className={style['other-hcp__header--status']}>{status}</div>
      </div>
      {hcpListForPatient &&
        hcpListForPatient.map((currentHcp: CurrentHcpType) => (
          <div className={style.row} key={`HCP${currentHcp.id}`}>
            {/* Name and Description */}
            <div
              className={cx({
                [style['hcp-team__name-descr-container']]: true,
              })}
            >
              <div className={style['hcp-team__name-descr-wrapper']}>
                <div className={style['hcp-team__name']}>
                  {currentHcp.firstName} {currentHcp.lastName}
                </div>
                <div className={style['hcp-team__descr']}>{currentHcp.hospital}</div>
              </div>
            </div>

            {/* Profession */}
            <div className={style['other-hcp__profession']}>{currentHcp.profession}</div>

            {/* Patients */}

            <div className={style['other-hcp__status']}>
              <StatusWithMarker currentStatus={currentHcp.status} t={t} />
            </div>
          </div>
        ))}

      {noData}
      {errorJsx}

      {/* Add HCP */}
      {!!addedList.length && (
        <div className={style['other-hcp__added-list-wrapper']}>
          <AddedUsers addedList={addedList} removeHcp={removeHcp} />
        </div>
      )}

      {/* ADD NEW HCP ICON */}
      <PlusNewElem
        description={add_hcp}
        buttonMethod={
          isPermissionAddAnotherHcp && isPermissionEditProfile ? onOpenModal : (): null => null
        }
        disable={!isPermissionAddAnotherHcp || !isPermissionEditProfile}
      />

      <Button
        buttonType="button"
        buttonName={save_t}
        buttonClass={style['other-hcp__btn--save']}
        buttonMethod={addNewHcpsToPtient}
        disabledButton={
          !isPermissionAddAnotherHcp ||
          !isPermissionEditProfile ||
          !!loadingJSX ||
          !addedList.length
        }
      />

      {isModalOpen && (
        <Modal onClose={_onclose}>
          {inviteHCP ? (
            <InviteHcpModal invite_hcp_title={invite_hcp_title} onCloseInviteModal={_onclose} />
          ) : (
            <ListHcpModalContent
              t={t}
              _onclose={_onclose}
              filter={filter}
              setFilter={setFilter}
              hcpListLoading={hcpListLoading}
              hcpList={hcpList}
              selectedHcp={selectedHcp}
              filterQuery={filterQuery}
              setFilterQuery={setFilterQuery}
              checkedHcpId={checkedHcpId}
              updateCompleted={updateCompleted}
              totalLength={totalLength}
              pageSize={pageSize}
              currentPage={currentPage}
              onChangePagination={onChangePagination}
              onSubmit={onSubmit}
              onInvite={onInvite}
            />
          )}
        </Modal>
      )}
      {/* Warning */}
      {isInviteAlertModalOpen && (
        <Popup
          title={hit_save_to_send}
          content=""
          buttonName={ok}
          onClosePopup={onCloseInviteAlertModal}
        />
      )}
    </div>
  );
};

export default OtherHcpTable;
