import React, { ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';

import style from '../styles.module.css';
import { getCurrenLang, GetState } from '../../../redux/selector';
import { Button, MainTitle } from '../../../common';
import { UrlType } from '../../../pages/Patient/AddPatientInformation/types';
import { deepCopyObj, onChangeArea, onChangeInput } from '../../../utils/helper';
import { initUrl } from '../../../pages/Patient/AddPatientInformation/InitValues';
import { Attachment } from '../../../common/Attachments';
import { InputFloatLabel, TextareaFeildFloatLabel } from '../../../common/Input';
import { PopupWithTwoButtons } from '../../../common/Popup';

import { PlusNewItem } from '../../../common/Button/Button';
import { UrlProps } from '../types';

const Url = ({ url, setUrl, setOpenForm, urlRaw, setUrlRaw }: UrlProps): ReactElement => {
  const t: any = useSelector<any>((state: GetState) => getCurrenLang(state));
  const ok = t?.notifications.ok;
  const cancel = t?.common.cancel;
  const apply = t?.common.apply;
  const url_t = t?.dashboard.hcp.profile_patient.url_t?.toUpperCase();
  const url_descr_label = t?.dashboard.hcp.profile_patient.url_descr_label;
  const add_another_url = t?.dashboard.hcp.profile_patient.add_another_url;
  const url_label = t?.dashboard.hcp.profile_patient.url_label;
  const please_enter_url = t?.dashboard.hcp.profile_patient.please_enter_url;
  const warning_unsaved_changes = t?.common.warning_unsaved_changes;
  const exit = t?.common.exit;

  // Local state
  const [isExitWarn, setExitWarn] = useState(false);

  // Delete attachment from local state
  const deleteAttachment = async (ind: number): Promise<void> => {
    const newUrl = [...urlRaw];
    newUrl.splice(ind, 1);
    if (newUrl.length === 0) {
      setUrlRaw([...deepCopyObj(initUrl)]);
      return;
    }
    setUrlRaw(newUrl);
  };

  // Move created URL to local state "Patient information component"
  const onSubmit = (): void => {
    const newUrl = [...url, ...urlRaw];
    setUrl(newUrl);
    setUrlRaw([...deepCopyObj(initUrl)]);
    setOpenForm('');
  };

  // Cancel add files
  const onCancel = (): void => {
    if (urlRaw[0].url || urlRaw[0].description) {
      setExitWarn(() => true);
      return;
    }
    setOpenForm('');
  };

  // Add new upload empty section
  const addNewItem = (): void => {
    setUrlRaw([...urlRaw, ...deepCopyObj(initUrl)]);
  };

  // Confirm exit from upload files
  const onConfirmExit = async (): Promise<void> => {
    setExitWarn(() => false);
    setUrlRaw([...deepCopyObj(initUrl)]);
    setOpenForm('');
  };

  // Clouse Exit warning popup
  const onCloseExitWarn = (): void => {
    setExitWarn(() => false);
  };

  // Applay/Edit button handler
  const setEditState = (state: boolean, ind: number): void => {
    const newUrl = [...urlRaw];
    newUrl[ind].isDone = state;
    setUrlRaw(newUrl);
  };

  return (
    <>
      <MainTitle title={url_t} />
      {urlRaw.map((urlItem: UrlType, ind: number) => (
        <div key={`urlKey${String(ind)}`}>
          {!urlItem.isDone && (
            <InputFloatLabel
              inputId={`url${ind}`}
              inputName="url"
              inputType="text"
              hasErrors={urlItem.isError && please_enter_url}
              isTouched={urlItem.isError}
              inputValue={urlItem.url}
              onChangeMethod={(e: any): void => onChangeInput(e, ind, urlRaw, setUrlRaw)}
              placeholder={url_label}
            />
          )}

          {urlItem.isDone && (
            <Attachment
              id={`Att_url${ind}`}
              title={urlItem.url}
              index={ind}
              deleteAttachments={(): Promise<void> => deleteAttachment(ind)}
              isEditIcon={urlItem.isDone}
              editMethod={(): void => setEditState(false, ind)}
            />
          )}
          {!urlItem.isDone && urlItem.url.length > 1 && (
            <Button
              buttonClass={style['add-patient-info__btn--apply']}
              buttonType="button"
              buttonName={apply}
              buttonMethod={(): void => setEditState(true, ind)}
              colorStyle="blue"
            />
          )}

          <TextareaFeildFloatLabel
            id={`urlDescr${ind}`}
            inputName={`urlDescription${ind}`}
            placeholder={url_descr_label}
            inputValue={urlItem.description}
            hasErrors={false}
            isTouched={false}
            onChecngeMethod={(e: any): void => {
              onChangeArea(e, ind, urlRaw, setUrlRaw, 350);
            }}
            rows={3}
          />
        </div>
      ))}

      {/* ADD NEW URL ITEM ICON */}
      {urlRaw[urlRaw.length - 1].isDone && (
        <div className={style['margin-bottom']}>
          <PlusNewItem description={add_another_url} buttonMethod={(): void => addNewItem()} />
        </div>
      )}

      {/* Buttons */}
      <div className={style['add-patient-info__btn-cont']}>
        <Button
          buttonClass={style['add-patient-info__btn']}
          buttonType="button"
          buttonName={cancel}
          buttonMethod={onCancel}
        />
        <Button
          buttonClass={style['add-patient-info__btn']}
          buttonType="button"
          buttonName={ok}
          buttonMethod={onSubmit}
          colorStyle="blue"
          disabledButton={!urlRaw[0].isDone}
        />
      </div>

      {/* Warning popup if has not saved changes */}
      {isExitWarn && (
        <PopupWithTwoButtons
          title={warning_unsaved_changes}
          content=""
          confirmButtonName={exit}
          closeButtonName={cancel}
          onConfirm={onConfirmExit}
          onClosePopup={onCloseExitWarn}
        />
      )}
    </>
  );
};

export default Url;
