import React, { ReactElement } from 'react';

import styles from './styles.module.css';
import { showTheStatus } from '../../utils/helper';
import { StatusWithMarkerProps } from './types';

const StatusWithMarker = ({ currentStatus, t }: StatusWithMarkerProps): ReactElement => {
  const [title, , hex] = showTheStatus(currentStatus, t);
  return (
    <div className={styles['other-hcp__status-wrapper']}>
      <div style={{ backgroundColor: hex }} className={styles['other-hcp__status-marker']} />
      <div className={styles['other-hcp__status-label']}>{title}</div>
    </div>
  );
};

export default StatusWithMarker;
