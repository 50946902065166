import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ObjectSchema } from 'yup';
import { toast } from 'react-toastify';
import {
  Modal,
  Loading,
  Title,
  FormFormik,
  ConfirmatioModal,
  WarningNotification,
} from '../../../common';
import {
  useResendCodeByEmailToPatient,
  useResendInviteTokenByHcp,
} from '../../../graphql/invite/index';
import { ReserCode, ReserInvite } from '../../Form/ReserInvite';
import { getCurrenLang } from '../../../redux/selector';
import styles from '../styles.module.css';
import { ModalUnlockAccountProps, TCustomer, TModalResend, UserProps } from '../types';
import { validationResendCode } from '../../../utils/validators';
import { useUnlockPatientAccountByAdmin } from '../../../graphql/patients';
import { CloseBtn } from '../../../common/Button/Button';

export const ModalResend = ({
  onModalClose,
  user,
  updateResend,
  setSortedInfo,
  setUser,
}: TModalResend): ReactElement => {
  const { _onInvite, hcp, error, loading, response } = useResendInviteTokenByHcp();

  // const {
  //   _onInvitePatient,
  //   patient,
  //   errorPatient,
  //   loadingPatient,
  // } = useResendInviteTokenByPatient();
  const [openConfirm, setOpenConfirm] = useState(false);
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const invite_send = t?.notifications.invite_send;
  // const patient_get_invite_email = t?.notifications.patient_get_invite_email;
  const hcp_get_invite_email = t?.notifications.hcp_get_invite_email;
  const ok = t?.notifications.ok;
  const invitation_mail_will_be_sent_to = t?.notifications.invitation_mail_will_be_sent_to;

  // const fromLS = storage.get('user');
  // const role = fromLS && fromLS.role;
  // const isAdmin = role === constants.admin;

  // const location = useLocation();
  // const { category } = getQueryParams(location.search);
  // const categoryFormQuery = String(category);

  useEffect(() => {
    if (hcp) {
      setOpenConfirm(true);
    }
  }, [hcp]);

  useEffect(() => {
    if (hcp) {
      updateResend(hcp);
    }
  }, [response, openConfirm]);

  // useEffect(() => {
  //   if (patient && isAdmin && patientArray.includes(categoryFormQuery)) {
  //     updateResend(patient);
  //   }
  // }, [categoryFormQuery, patient, openConfirm]);

  // useEffect(() => {
  //   if (patient && !isAdmin) {
  //     updateResend(patient);
  //   }
  // }, [patient, openConfirm]);

  const initValue = {
    phone: (user && user.phone) || '',
    email: (user && user.email) || '',
  };
  const _send = (customer: TCustomer): void => {
    if (customer) {
      const params = {
        id: user && user.id,
        email: customer.email,
        phone:
          customer.phone && customer.phone[0].includes('+') ? customer.phone : `+${customer.phone}`,
      };
      // if (hcpArray.includes(categoryFormQuery)) {
      _onInvite(params);
      // } else {
      //   _onInvitePatient(params);
      // }
      setSortedInfo(null);
    }
  };
  const close = (): void => {
    onModalClose();
  };

  const onUpdatedSuccessJSX = hcp && !error && (
    <ConfirmatioModal
      title={invite_send}
      contant={hcp_get_invite_email}
      buttonTitle={ok}
      buttonMethod={close}
    />
  );

  const loadingJSX = loading && <Loading />;
  const formJsx = (
    <FormFormik
      initialValues={initValue}
      component={ReserInvite}
      onSend={_send}
      errorMessage={error}
    />
  );

  return (
    <Modal onClose={close}>
      <div>
        {!openConfirm && !hcp && (
          <div className={styles.wrapper_modal}>
            <div>
              <div className={styles['modal__title-container']}>
                <CloseBtn close={close} />
                <Title title={invitation_mail_will_be_sent_to} />
              </div>
            </div>
            {formJsx}
          </div>
        )}

        {openConfirm && onUpdatedSuccessJSX}
        {loadingJSX}
      </div>
    </Modal>
  );
};

// Resend patients signin code
export const ModalResendCode = ({
  onModalClose,
  user,
  updateResend,
  setSortedInfo,
  setUser,
}: TModalResend): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const ok = t?.notifications.ok;
  const code_sent = t?.notifications.code_sent;
  const get_code_email = t?.notifications.get_code_email;
  const code_mail_will_be_sent_to = t?.notifications.code_mail_will_be_sent_to;

  // Mutation email code to patient
  const {
    _resendCode,
    codeResent,
    errorCodeResend,
    loadingResend,
  } = useResendCodeByEmailToPatient();

  const [openConfirm, setOpenConfirm] = useState(false);

  const initValue = {
    email: user?.email || '',
  };

  // If code sent successfully then show message
  useEffect(() => {
    if (codeResent) {
      setOpenConfirm(true);
    }
  }, [codeResent]);

  // Code email to patient
  const _send = (customer: TCustomer): void => {
    if (customer) {
      _resendCode({
        variables: {
          resendPatientCodeData: {
            id: user?.id,
            email: customer.email,
          },
        },
      }); // Update to new endPoint
    }
  };

  // Clouse modal windows and clear current user state
  const close = (): void => {
    onModalClose(() => false);
    setUser(null);
  };

  // JSX
  const onUpdatedSuccessJSX = codeResent && !errorCodeResend && (
    <ConfirmatioModal
      title={code_sent}
      contant={get_code_email}
      buttonTitle={ok}
      buttonMethod={close}
    />
  );

  const loadingJSX = loadingResend && <Loading />;
  const formJsx = (
    <FormFormik
      initialValues={initValue}
      component={ReserCode}
      onSend={_send}
      errorMessage={errorCodeResend}
      schema={(): ObjectSchema<any | undefined> => validationResendCode(t)}
    />
  );

  return (
    <Modal onClose={close}>
      <div>
        {!openConfirm && !codeResent && (
          <div className={styles.wrapper_modal}>
            <div>
              <CloseBtn close={close} />
              <div className={styles['modal__title-container']}>
                <Title title={code_mail_will_be_sent_to} />
              </div>
            </div>
            {formJsx}
          </div>
        )}

        {onUpdatedSuccessJSX}
        {loadingJSX}
      </div>
    </Modal>
  );
};

export const ModalUnlockAccount = ({
  onModalClose,
  user,
  refreshTable,
  setUser,
  t,
}: ModalUnlockAccountProps): ReactElement => {
  const firstName = user?.firstName || '';
  const lastName = user?.lastName || '';
  const userEmail = user?.email || '';
  const ok = t?.notifications.ok;
  const unlock_t = t?.common.unlock;
  const account_unlocked = t?.notifications.account_unlocked;
  const unlock_acc_are_you_sure = t?.notifications.unlock_account_are_you_sure;
  const unlock_acc_are_you_sure_norm = unlock_acc_are_you_sure
    ? unlock_acc_are_you_sure
        .replace('<email>', userEmail)
        .replace('<user>', `${firstName} ${lastName}`)
    : '';

  const {
    _unlockPatientAccountByAdmin,
    unlockPatientAccountData,
    unlockPatientAccountError,
    unlockPatientAccountLoading,
  } = useUnlockPatientAccountByAdmin();

  const [openSuccess, setOpenSuccess] = useState(false);

  // If unlock Patient Account successfully then show message
  useEffect(() => {
    if (unlockPatientAccountData) {
      refreshTable();
      setOpenSuccess(true);
    }
  }, [unlockPatientAccountData]);

  // If unlock Patient Account error then show message
  useEffect(() => {
    if (unlockPatientAccountError) {
      toast.error(unlockPatientAccountError);
    }
  }, [unlockPatientAccountError]);

  // Unlock patient account
  const _unlockAccount = (customer: UserProps): void => {
    if (customer) {
      _unlockPatientAccountByAdmin(customer.id);
    }
  };

  // Clouse modal windows and clear current user state
  const close = (): void => {
    onModalClose(() => false);
    setUser(null);
  };

  // JSX
  const loadingJSX = unlockPatientAccountLoading && <Loading />;

  return (
    <Modal onClose={close}>
      <div>
        {!unlockPatientAccountData && !openSuccess && (
          <div>
            <CloseBtn close={close} />
            <WarningNotification
              title=""
              contant={unlock_acc_are_you_sure_norm}
              buttonName={unlock_t}
              onCancel={close}
              onSubmit={(): void => _unlockAccount(user)}
            />
          </div>
        )}

        {/* Success msg */}
        {unlockPatientAccountData && !unlockPatientAccountError && openSuccess && (
          <ConfirmatioModal
            title=""
            contant={account_unlocked}
            buttonTitle={ok}
            buttonMethod={close}
          />
        )}
        {loadingJSX}
      </div>
    </Modal>
  );
};
