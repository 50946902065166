/* eslint-disable react/no-danger */
import React, { ReactElement } from 'react';
import cx from 'classnames';
import moment from 'moment';
import { sanitize } from 'dompurify';

import { getPatientAnswers } from '../../../../../utils/helper_charts';
import style from './style.module.css';
import { Answer, QuestionnaireContentProps, Question } from './types';
import { RemoveQuestionnaire } from './common/RemoveQuestionnaire';
import { CalculationDescription } from './common/Calculation';
import { PROMS_NAME } from '../../../../../utils/enums';

export const MepsReport = ({
  questionnairesList,
  completedQuestionnaire,
  index,
  onDeleteItem,
  formatDate,
  t,
}: QuestionnaireContentProps): ReactElement => {
  const remove_questionnaire = t?.dashboard.hcp.profile_patient.questionnaires.remove_questionnaire;
  const meps_title = t?.dashboard.hcp.profile_patient.questionnaires.meps_title;
  const meps_calc_descr_1 = t?.dashboard.hcp.profile_patient.questionnaires.meps_calc_descr_1;
  const meps_calc_descr_2 = t?.dashboard.hcp.profile_patient.questionnaires.meps_calc_descr_2;
  const meps_calc_descr_3 = t?.dashboard.hcp.profile_patient.questionnaires.meps_calc_descr_3;
  const meps_please_anser = t?.dashboard.hcp.profile_patient.questionnaires.meps_please_anser;
  const mayo_elbow_score = t?.dashboard.hcp.profile_patient.questionnaires.mayo_elbow_score;
  const section = t?.dashboard.hcp.profile_patient.questionnaires.section;

  const { questions: completedQuestions, completedDate, totalScore } = completedQuestionnaire;
  const patientAnswers: any = getPatientAnswers(completedQuestions);
  const mepsList = questionnairesList.filter((item: any) => item.name === PROMS_NAME.MEPS)[0];
  const { questions } = mepsList;

  // JSX
  const calc_description = (
    <>
      <p>{meps_calc_descr_1}</p>
      <p>{meps_calc_descr_2}</p>
      <p>{meps_calc_descr_3}</p>
    </>
  );

  const totalScoreJSX = (
    <div className={style.meps_score}>{`${mayo_elbow_score}: ${totalScore} / 100`}</div>
  );

  return (
    <>
      <RemoveQuestionnaire
        onMethod={(): void => onDeleteItem(index)}
        title={remove_questionnaire}
      />
      <div className="questionnaire-to-PDF">
        <div
          className={cx({
            [style.meps__container]: true,
          })}
        >
          <CalculationDescription
            name={meps_title}
            content={calc_description}
            date={completedDate ? moment(completedDate).format(formatDate) : ''}
            t={t}
          />

          {/* PAGE */}
          <div className={style['meps_please-answer']}>{meps_please_anser}</div>
          {totalScoreJSX}
          {questions.map((question: Question, ind: number) => (
            // Question
            <div key={`mepsQuestion${String(ind)}`}>
              <div className={style.meps_question}>
                <span>{`${section} ${ind + 1} - `}</span>
                <span dangerouslySetInnerHTML={{ __html: sanitize(question.name) }} />
              </div>

              {/* Answers */}
              {question.answers.map((answ: Answer, inx: number) => {
                const getAnswer = (patientAnswer: number | number[], answId: number): boolean => {
                  if (typeof patientAnswer === 'number') {
                    return patientAnswer === answId;
                  }
                  if (typeof patientAnswer === 'object') {
                    return patientAnswer.includes(answId);
                  }
                  return false;
                };
                const isAnswerByPatientTrue = getAnswer(patientAnswers[question.id], answ.id);

                return (
                  <div
                    className={cx({
                      [style['meps__answer-container']]: true,
                      [style['backround-color']]: isAnswerByPatientTrue,
                    })}
                    key={`mepsAnswer${String(inx)}`}
                  >
                    <div className={style.meps__box}>
                      <div className={style.meps__checkbox}>
                        <div className={style['meps__checkbox-inner']}>
                          {isAnswerByPatientTrue && <>&#10004;</>}
                        </div>
                      </div>
                      {answ.name}
                    </div>
                  </div>
                );
              })}
            </div>
          ))}
          {totalScoreJSX}
        </div>
      </div>
    </>
  );
};
