import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';

import styles from './styles.module.css';
import { Loading, MainTitle, SelectFloatLabelWithSearch } from '../../common';
import { getCurrenLang } from '../../redux/selector';
import { getSelectOptions } from '../../utils/helper';
import {
  useGetHospitalNames,
  useGetHospitalQuestionnaires,
  useSetHospitalQuestionnaires,
} from '../../graphql/hospitals';
import { HospitalCheckboxesForm } from './HospitalCheckboxesForm';
import { SuccessNotifModal } from '../../common/NotificationModal';

const ManageProms = (): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const manage_proms = t?.menu.manage_proms;
  const organisation_name = t?.common.organisation_name;
  const ok = t?.common.ok;
  const no_options = t?.invite_patient.please_enter_min_3_characters;
  const give_access_to_proms = t?.dashboard.admin.table.give_access_to_proms;
  const select_organisation = t?.dashboard.admin.table.select_organisation;
  const proms_updated = t?.dashboard.admin.table.proms_updated;

  // Local state
  const [isModalOpen, setModal] = useState(false);

  // Queris and mutations
  const { hospitalNames } = useGetHospitalNames();
  const {
    _getHospitalQuestionnaires,
    hospitalQuestionnaires,
    errorHospitalQuestionnaires,
    loadingHospitalQuestionnaires,
  } = useGetHospitalQuestionnaires();
  const {
    _setHospitalQuestionnaires,
    settingsUpdated,
    errorSetHospitalQuestionnaires,
    loadingSetHospitalQuestionnaires,
  } = useSetHospitalQuestionnaires();

  const initialValues: any = {
    organisationName: '',
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (): any => null,
  });

  const _send = (value: any): void => {
    const idList: any = [];
    const keys = Object.keys(value);
    keys.map((item: string) => {
      if (value[item]) {
        idList.push(+item);
      }
      return null;
    });
    const req = {
      hospitalId: formik.values.organisationName.value,
      questionnairesId: idList,
    };
    _setHospitalQuestionnaires({
      variables: req,
    });
  };

  // When the hospital is changed, we request data from the DB
  useEffect(() => {
    if (formik.values.organisationName) {
      _getHospitalQuestionnaires({
        variables: {
          hospitalId: formik.values.organisationName.value,
        },
      });
    }
  }, [formik.values.organisationName]);

  // If didn`t find organisationName Error handler
  useEffect(() => {
    if (errorHospitalQuestionnaires) {
      toast.error(errorHospitalQuestionnaires);
      formik.setFieldValue('organisationName', '');
    }
  }, [errorHospitalQuestionnaires]);

  // If set hospital questionnaires not success
  useEffect(() => {
    if (errorSetHospitalQuestionnaires) {
      toast.error(errorSetHospitalQuestionnaires);
    }
  }, [errorSetHospitalQuestionnaires]);

  // If the settings are saved successfully
  useEffect(() => {
    if (settingsUpdated) {
      setModal(settingsUpdated);
    }
  }, [settingsUpdated]);

  const hospitalNameOptions = useMemo(() => getSelectOptions(hospitalNames), [hospitalNames]);

  const onCloseModal = (): void => {
    // Update form
    _getHospitalQuestionnaires({
      variables: {
        hospitalId: formik.values.organisationName.value,
      },
    });
    setModal(() => false);
  };

  const loadingJSX = (loadingHospitalQuestionnaires || loadingSetHospitalQuestionnaires) && (
    <Loading />
  );

  return (
    <>
      {loadingJSX}
      <MainTitle title={manage_proms} />
      {/* Organisation dropdown */}
      <SelectFloatLabelWithSearch
        inputId="organisationNameId"
        name="organisationName"
        onChange={formik.setFieldValue}
        options={hospitalNameOptions}
        hasErrors={formik.errors.organisationName}
        isTouched={formik.touched.organisationName}
        selected={formik.values.organisationName}
        placeholder={organisation_name}
        noOptionsMessage={no_options}
        inputValue={formik.values.organisationName}
        type="rewards"
      />

      {/* Description */}
      <div className={styles.manage_proms__description}>{give_access_to_proms}</div>

      {/* Checkboxes container */}
      <div>
        {!formik.values.organisationName && (
          <div className={styles.manage_proms__noData}>{select_organisation}</div>
        )}
        {formik.values.organisationName && hospitalQuestionnaires && (
          <HospitalCheckboxesForm
            data={hospitalQuestionnaires}
            onSave={_send}
            loading={!!loadingJSX}
          />
        )}
      </div>

      {isModalOpen && (
        <SuccessNotifModal onClose={onCloseModal} btnName={ok} description={proms_updated} />
      )}
    </>
  );
};

export default ManageProms;
