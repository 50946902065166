import React, { ReactElement, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import { Switch } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';

import style from './styles.module.css';
import { useChangeHcpEmailNotification } from '../../graphql/hcp';

import { getSectionTexts } from '../../utils/helper';
import { GetState, getCurrenLang, getEmailNotifSettingsData } from '../../redux/selector';
import { setOpenSettingsData } from '../../redux/emailNotifications';
import { NotificationsToMeSettings } from './NotificationsToMeSettings';

const HcpAllEmailNotification = ({ data, subSectionData, updateData }: any): ReactElement => {
  const t: any = useSelector<any>((state: GetState) => getCurrenLang(state));
  const on = t?.common.on;
  const off = t?.common.off;
  const settings_t = t?.dashboard.hcp.profile_patient.video_bank.settings;
  const inactive_t = t?.dashboard.hcp.profile_patient.completion_and_steps.inactive;

  // Endpoints
  const {
    _changeHcpEmailNotifStatus,
    changeHcpEmailNotifStatusLoading,
    changeHcpEmailNotifStatusError,
    isStatusChanged,
  } = useChangeHcpEmailNotification();

  // Variables
  const { emailNotifType, id } = data;
  const isEdit = !!id || !!subSectionData.id;
  const dispatch = useDispatch();
  const {
    title,
    description,
    smSwitchName,
    smSwitchName_subType,
    smSwitchDescr,
    smSwitchDescr_subType,
    percentFieldName,
    percentFieldName_subType,
    dayFieldName,
    dayFieldName_subType,
    switchDescription,
    switchDescription_sub,
    sectionType,
  } = useMemo(() => getSectionTexts(emailNotifType, t, data, subSectionData), [
    emailNotifType,
    t,
    data,
    subSectionData,
  ]);

  // Redux
  const openSettingsData: any = useSelector((state: GetState) => getEmailNotifSettingsData(state));
  const isActive = openSettingsData?.type === sectionType;

  // If update status Hcp Email Notification success then update list
  useEffect(() => {
    if (isStatusChanged) {
      updateData();
    }
  }, [isStatusChanged]);

  // If update status Hcp Email Notification error then show message
  useEffect(() => {
    if (changeHcpEmailNotifStatusError) {
      toast.error(changeHcpEmailNotifStatusError);
    }
  }, [changeHcpEmailNotifStatusError]);

  // Turn on (off) notification
  const changeEmailNotifTypeStatus = (checked: boolean, sectionId: number): void => {
    _changeHcpEmailNotifStatus({
      id: sectionId,
      isEmailNotifReceiving: checked,
    });
  };

  // Open settings
  const openSettings = (): void => {
    if (isActive && data) {
      dispatch(setOpenSettingsData(undefined));
    } else {
      dispatch(
        setOpenSettingsData({
          data: { ...data },
          subSection: { ...subSectionData },
          type: sectionType,
          title,
          description,
          smSwitchName,
          smSwitchName_subType,
          smSwitchDescr,
          smSwitchDescr_subType,
          percentFieldName,
          percentFieldName_subType,
          dayFieldName,
          dayFieldName_subType,
        }),
      );
    }
  };

  return (
    <div className={style.row__wrapper}>
      <div className={style.row}>
        <div className={style['email-notif__title-container']}>
          <div className={style['email-notif__title']}>{title}</div>
          <div className={style['email-notif__description']}>{description}</div>
          {!isEdit ? <div className={style['email-notif__description']}>{inactive_t}</div> : <></>}
        </div>

        <div className={style['email-notif__switch-wrapper']}>
          <div
            className={cx({
              [style['email-notif__settings-btn']]: true,
              [style['email-notif__settings-btn--active']]: isActive,
            })}
            onClick={openSettings}
            aria-hidden
          >
            {settings_t}
          </div>
        </div>
      </div>

      {!!id && !!data.frequency && (
        <div className={style.row}>
          <div className={style['email-notif__title-container']}>
            <div className={style['email-notif__description']}>{switchDescription}</div>
          </div>

          <div className={style['email-notif__switch-wrapper']}>
            <div className={style['email-notif__switch']}>
              <Switch
                checkedChildren={<span>{on}</span>}
                unCheckedChildren={<span>{off}</span>}
                loading={changeHcpEmailNotifStatusLoading}
                checked={data.isEmailNotifReceiving}
                onChange={(checked: boolean): void => changeEmailNotifTypeStatus(checked, id)}
              />
            </div>
          </div>
        </div>
      )}

      {!!subSectionData.id && !!subSectionData.frequency && (
        <div className={style.row}>
          <div className={style['email-notif__title-container']}>
            <div className={style['email-notif__description']}>{switchDescription_sub}</div>
          </div>

          <div className={style['email-notif__switch-wrapper']}>
            <div className={style['email-notif__switch']}>
              <Switch
                checkedChildren={<span>{on}</span>}
                unCheckedChildren={<span>{off}</span>}
                loading={changeHcpEmailNotifStatusLoading}
                checked={subSectionData.isEmailNotifReceiving}
                onChange={
                  (checked: boolean): void =>
                    // eslint-disable-next-line implicit-arrow-linebreak
                    changeEmailNotifTypeStatus(checked, subSectionData.id)
                  // eslint-disable-next-line react/jsx-curly-newline
                }
              />
            </div>
          </div>
        </div>
      )}

      <div className={style['email-notif__settings-wrapper--sm']}>
        {/* Settings sm screen */}
        {openSettingsData?.type === sectionType && (
          <NotificationsToMeSettings
            openSettingsData={openSettingsData}
            updateData={updateData}
            unicPrefix="setSmScreen"
          />
        )}
      </div>
    </div>
  );
};

export default HcpAllEmailNotification;
