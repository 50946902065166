import React, { ReactElement, useState, useEffect } from 'react';
// @ts-ignore
import { Document, Page } from 'react-pdf/dist/entry.webpack';

import styles from './style.module.css';
import './style.css';

const PdfView = ({ pdfLink }: any): ReactElement => {
  const [file, setFile] = useState<any>('');
  const [numberPages, setNumberPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const blobToBase64 = async (blob: any): Promise<void> => {
    const prew = await window.URL.createObjectURL(blob);
    setFile(prew);
  };

  // Get PDF file from AWS
  const getPDF = async (link: any): Promise<void> => {
    const headers = new Headers();
    headers.append('Access-Control-Allow-Credentials', 'true');
    headers.append('Content-Type', 'application/pdf');
    try {
      await fetch(link, {
        method: 'GET',
        headers,
      })
        .then((res) => res.blob())
        .then((res) => blobToBase64(res));
    } catch (error) {
      console.log(`Fetch PDF faild: ${error}`);
    }
  };

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
    setNumberPages(numPages);
  };

  // Get file from AWS
  useEffect(() => {
    if (pdfLink) {
      getPDF(pdfLink);
    }
  }, [pdfLink]);

  return (
    <div className={styles['patient-info__img-preview']}>
      <Document
        file={file}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={(e: any): void => console.log('onLoad PDF Error:', e)}
      >
        <Page
          renderAnnotationLayer={false}
          pageNumber={pageNumber}
          // width={60}
          height={60}
          // scale={0.9}
          className="pageClass"
        />
      </Document>
    </div>
  );
};

export default PdfView;
