import React, { ReactElement } from 'react';
import { Route } from 'react-router-dom';

import { PublicRouteProps } from './types';

const PublicRoute = ({
  component: Component,
  path: routePath,
  exact,
  ...rest
}: PublicRouteProps): ReactElement => (
  <Route
    {...rest}
    exact={exact}
    path={routePath}
    component={(props: any): JSX.Element => <Component {...props} />}
  />
);

export default PublicRoute;
