/* eslint-disable indent */
/* eslint-disable no-restricted-properties */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useMemo, ReactElement } from 'react';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { getCurrenLang } from '../../../redux/selector';
import {
  nhsValueHandler,
  getSelectOptions,
  getPatientRewards,
  otherClaimIdHandler,
  getGender,
  getQueryParams,
} from '../../../utils/helper';
import {
  validationProfilePatient,
  validationForTheConverterProfileByAdmin,
  convertFromGram,
  convertFromCm,
  convertInGram,
  convertInCm,
} from '../../../utils/validators';
import { useQueryClaimOptions, useUpdatePatientProfile } from '../../../graphql/patients';
import { Weight, HEIGHT, UserCountryEnum } from '../../../utils/enums';
import { TProperty } from '../types';
import style from './style.module.css';
import { spinner } from '../../../common/Loader';
import { TStatusOptions } from '../../../utils/model';
import { RewardsModalState } from '../../Table/types';
import { RewardsModal } from '../../Table/components/RewardsModal';
import {
  useCreatePatientRewards,
  useCreatePatientRewardsByHcp,
  useShutdownPatientRewards,
  useShutdownPatientRewardsByHcp,
} from '../../../graphql/rewards';
import useCheckRole from '../../../hooks/useCheckRole';
import PatientProfileForm from '../../Form/PatientProfile';

const PatientProfile = ({
  patient,
  isProfileUpdated,
  setProfileUpdated,
  refreshProfileData,
}: any): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const female = t?.invite_patient.female;
  const male = t?.invite_patient.male;
  const deactivated_access_to_rewards =
    t?.dashboard.admin.table.patient.deactivated_access_to_rewards;
  const patient_not_found = t?.common.patient_not_found;

  const location = useLocation();
  const { userId } = getQueryParams(location.search);

  const { isHcp, isAdmin } = useCheckRole();
  const patientCountry = patient.country;
  const initState = {
    isOpen: false,
    candidatId: null,
    firstName: '',
    lastName: '',
    hospitalName: '',
  };
  const genderOptions = [
    { value: 1, label: female },
    { value: 2, label: male },
  ];

  // Endpoints
  const { updating, _updateProfile, errorMessage, updatedProfileByAdmin } = useUpdatePatientProfile(
    isAdmin,
  );
  const {
    _onCreatePatientRewards,
    createRewardsLoading,
    createRewardsError,
    saccessCreatePatientRewards,
  } = useCreatePatientRewards();

  const {
    _onShutdownPatientRewards,
    shutdownRewardsLoading,
    shutdownRewardsError,
    saccessShutdownPatientRewards,
  } = useShutdownPatientRewards();

  const {
    _onCreatePatientRewardsByHcp,
    createRewardsByHcpLoading,
    createRewardsByHcpError,
    saccessCreatePatientRewardsByHcp,
  } = useCreatePatientRewardsByHcp();

  const {
    _onShutdownPatientRewardsByHcp,
    shutdownRewardsByHcpLoading,
    shutdownRewardsByHcpError,
    saccessShutdownPatientRewardsByHcp,
  } = useShutdownPatientRewardsByHcp();

  const { _getClaimOptions, claimOptions } = useQueryClaimOptions();

  // Local state
  const [weightToBack, setWeightToBack] = useState<string | number>(0);
  const [heightToBack, setHeightToBack] = useState<string | number>(0);
  const [rewardsDate, setRewardsDate] = useState<{ startDate: string; endDate: string }>({
    startDate: '',
    endDate: '',
  });
  const [turnOnRewardsModal, setTurnOnRewardsModal] = useState<RewardsModalState>(initState);
  const [isConfirmRewards, setIsConfirmRewards] = useState<boolean>(false);
  const [isWarningDeactivedRewards, setIsWarningDeactivedRewards] = useState<RewardsModalState>(
    initState,
  );

  useEffect(() => {
    if (patientCountry === UserCountryEnum.US) {
      _getClaimOptions();
    }
  }, [patientCountry]);

  const weightConverted = patient.weight
    ? patient.weightDim === Weight.Stone
      ? convertFromGram(patient.weight, patient.weightDim).join(',')
      : convertFromGram(patient.weight, patient.weightDim).toString()
    : null;
  const heightConverted = patient.height
    ? patient.heightDim === HEIGHT.ftinches
      ? convertFromCm(patient.height, patient.heightDim).join(',')
      : convertFromCm(patient.height, patient.heightDim).toString()
    : null;
  const patientRewards = getPatientRewards(patient.rewards);

  const initValuesUSProfile =
    patientCountry === UserCountryEnum.US
      ? {
          typeOfClaim: patient.patientInsurance?.claim
            ? {
                value: patient.patientInsurance.claim.id,
                label: patient.patientInsurance.claim.name,
              }
            : '',
          insuredIdNumber: patient.patientInsurance?.insuredIdNumber || '',
          policyGroup: patient.patientInsurance?.policyGroup || '',
          otherClaimId: otherClaimIdHandler(patient.patientInsurance?.otherClaimId) || '',
          insurancePlanName: patient.patientInsurance?.insurancePlanName || '',
        }
      : {};

  const initialValuesProfile: any = {
    firstName: patient.firstName || '',
    lastName: patient.lastName || '',
    phone: patient.phone || '',
    email: patient.email || '',
    gender: getGender(patient.gender, genderOptions), // defaultGender[patient.gender] || null,
    dob: (patient.dob && new Date(patient.dob)) || null,
    weight: weightConverted || '',
    weightDim: patient.weightDim || Weight.Kg,
    height: heightConverted || '',
    heightDim: patient.heightDim || HEIGHT.cm,
    nhsNumber: (patient.nhsNumber && nhsValueHandler(patient.nhsNumber, patientCountry)) || '',
    hospitalName:
      (patient.procedure &&
        patient.procedure.hospital && {
          value: patient.procedure.hospital.id,
          label: patient.procedure.hospital.name,
        }) ||
      '',
    procedureTypeId:
      patient.procedure && patient.procedure.type
        ? { value: patient.procedure.type.id.toString(), label: patient.procedure.type.name }
        : { value: '', label: '' },
    surgeryDate:
      (patient.procedure && patient.procedure.date && new Date(patient.procedure.date)) || null,
    status: patient.status,
    id: patient.id,
    isRewardsEnabled: patient.isRewardsEnabled,
    rewardsStartDate: patient.isRewardsEnabled && patientRewards ? patientRewards.startDate : '',
    rewardsEndDate: patient.isRewardsEnabled && patientRewards ? patientRewards.endDate : '',
    ...initValuesUSProfile,
  };

  const formik = useFormik({
    initialValues: initialValuesProfile,
    validate: (values) => validationForTheConverterProfileByAdmin(values, t),
    validationSchema: () => validationProfilePatient(t, patientCountry),
    onSubmit: (values: TProperty) => {
      if (userId) {
        const data: any = {
          id: Number(userId),
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          gender: values && values.gender && Number(values.gender.value),
          weight: Number(weightToBack),
          height: Number(heightToBack),
          surgeryDate: values.surgeryDate,
          dob: values.dob,
          heightDim: values.heightDim,
          hospitalName: values.hospitalName.label,
          nhsNumber: values.nhsNumber ? values.nhsNumber.replace(/\D/g, '') : null,
          phone: values.phone[0] !== '+' ? `+${values.phone}` : values.phone,
          procedureTypeId: Number(values.procedureTypeId.value),
          weightDim: values.weightDim,
        };
        if (patientCountry === UserCountryEnum.US) {
          if (values.typeOfClaim?.value) {
            data.claimId = Number(values.typeOfClaim.value);
          } else {
            data.claimId = null;
          }
          data.insuredIdNumber = values.insuredIdNumber ? values.insuredIdNumber : null;
          data.policyGroup = values.policyGroup ? values.policyGroup : null;
          if (values.otherClaimId) {
            data.otherClaimId = values.otherClaimId.replace(/┆/g, '').replace(/\s/g, '');
          } else {
            data.otherClaimId = null;
          }
          data.insurancePlanName = values.insurancePlanName ? values.insurancePlanName : null;
        }
        if (isHcp) {
          data.isAllowArchiveVideos = values.isAllowArchiveVideos;
        }
        const listHCP: any = []; // TODO delete AFTER update mutation with BE
        _updateProfile(data, listHCP);
      } else {
        toast.error(patient_not_found);
      }
    },
  });
  const { values } = formik;

  // Remove Save icon
  useEffect(() => {
    if (isProfileUpdated) {
      setProfileUpdated(() => false);
    }
  }, [values]);

  useEffect(() => {
    if (saccessCreatePatientRewards || saccessCreatePatientRewardsByHcp) {
      formik.setFieldValue('isRewardsEnabled', true);
      formik.setFieldValue('rewardsStartDate', rewardsDate.startDate);
      formik.setFieldValue('rewardsEndDate', rewardsDate.endDate);
      setIsConfirmRewards(() => true);
      setRewardsDate({ startDate: '', endDate: '' });
    }
  }, [saccessCreatePatientRewards, saccessCreatePatientRewardsByHcp]);

  useEffect(() => {
    if (saccessShutdownPatientRewards || saccessShutdownPatientRewardsByHcp) {
      formik.setFieldValue('isRewardsEnabled', false);
      formik.setFieldValue('rewardsStartDate', '');
      formik.setFieldValue('rewardsEndDate', '');
      setIsWarningDeactivedRewards(initState);
      const hospitalName = isHcp
        ? patient?.procedure?.hospital?.name || 'the On The Mend'
        : 'the On The Mend';
      const deactivated_access_to_rewards_with_hospital = deactivated_access_to_rewards?.replace(
        'the On The Mend',
        hospitalName,
      );
      toast.info(
        `${deactivated_access_to_rewards_with_hospital} ${patient.firstName} ${patient.lastName}`,
      );
    }
  }, [saccessShutdownPatientRewards, saccessShutdownPatientRewardsByHcp]);

  useEffect(() => {
    if (createRewardsError || createRewardsByHcpError) {
      toast.error(createRewardsError || createRewardsByHcpError);
    }
    if (shutdownRewardsError || shutdownRewardsByHcpError) {
      toast.error(shutdownRewardsError || shutdownRewardsByHcpError);
    }
  }, [
    createRewardsError,
    shutdownRewardsError,
    createRewardsByHcpError,
    shutdownRewardsByHcpError,
  ]);

  // Update status in formik after success update in database
  const refreshStatus = (mutableValue: TStatusOptions): void => {
    formik.setFieldValue('status', mutableValue.value);
  };

  useEffect(() => {
    if (updatedProfileByAdmin) {
      setProfileUpdated(() => true);
      refreshProfileData();
      // toast.info(`${patient.firstName} ${patient.lastName} ${updated_profile}`);
      // document.location.reload();
    }
  }, [updatedProfileByAdmin]);

  useEffect(() => {
    if (patient.weight) {
      setWeightToBack(patient.weight);
    }
    if (patient.height) {
      setHeightToBack(patient.height);
    }
  }, []);

  const weight = weightToBack;
  const height = heightToBack;

  const calcBMI = (): string | null => {
    if (formik.values.weight && formik.values.height) {
      const weightRes: any = convertInGram(formik.values.weight, formik.values.weightDim);
      const heightRes: any = convertInCm(formik.values.height, formik.values.heightDim);
      const result = weightRes / 1000 / Math.pow(heightRes / 100, 2);
      return parseFloat(result.toString()).toFixed(1);
    }
    return null;
  };

  const claimOptionsNormalize = useMemo(() => {
    if (claimOptions) {
      return getSelectOptions(claimOptions);
    }
    return [];
  }, [claimOptions]);

  const rewardsSwitchHandler = (checked: boolean): void => {
    const candidatForRewards = {
      isOpen: true,
      candidatId: patient.id,
      firstName: patient.firstName,
      lastName: patient.lastName,
      hospitalName: isHcp
        ? patient?.procedure?.hospital?.name || 'the On The Mend'
        : 'the On The Mend',
    };
    if (checked) {
      setTurnOnRewardsModal(() => candidatForRewards);
    }
    if (!checked) {
      setIsWarningDeactivedRewards(candidatForRewards);
    }
  };

  useEffect(() => {
    if (formik.values.weight) {
      const value = convertInGram(formik.values.weight, formik.values.weightDim);
      setWeightToBack(value);
    } else {
      setWeightToBack('');
    }
  }, [formik.values.weight]);

  useEffect(() => {
    if (formik.values.height) {
      const value = convertInCm(formik.values.height, formik.values.heightDim);
      setHeightToBack(value);
    } else {
      setHeightToBack('');
    }
  }, [formik.values.height]);

  const loadingJSX =
    (updating ||
      shutdownRewardsLoading ||
      createRewardsLoading ||
      createRewardsByHcpLoading ||
      shutdownRewardsByHcpLoading) &&
    spinner;

  return (
    <div className={style['profile__form-container']}>
      {loadingJSX}
      <PatientProfileForm
        formik={formik}
        // hospitalNames={hospitalNames}
        genderOptions={genderOptions}
        claimOptions={claimOptionsNormalize}
        weight={weight}
        height={height}
        calcBMI={calcBMI}
        rewardsSwitchHandler={rewardsSwitchHandler}
        errorMessage={errorMessage}
        refreshStatus={refreshStatus}
        isProfileUpdated={isProfileUpdated}
      />

      {/* Popups */}
      <RewardsModal
        _onCreatePatientRewards={isHcp ? _onCreatePatientRewardsByHcp : _onCreatePatientRewards}
        _onShutdownPatientRewards={
          isHcp ? _onShutdownPatientRewardsByHcp : _onShutdownPatientRewards
        }
        refreshTable={false}
        turnOnRewardsModal={turnOnRewardsModal}
        setTurnOnRewardsModal={setTurnOnRewardsModal}
        isConfirmRewards={isConfirmRewards}
        setIsConfirmRewards={setIsConfirmRewards}
        isWarningDeactivedRewards={isWarningDeactivedRewards}
        setIsWarningDeactivedRewards={setIsWarningDeactivedRewards}
        setRewardsDate={setRewardsDate}
        loading={!!loadingJSX}
      />
    </div>
  );
};

export default PatientProfile;
