import React, { ReactElement } from 'react';
import cx from 'classnames';

import styles from './styles.module.css';
import { DeleteIcon, PencilIcon } from '../../theme/icons';

export const Attachment = ({
  id,
  title,
  index,
  deleteAttachments,
  isEditIcon = false,
  editMethod,
  disable,
}: any): ReactElement => (
  <div
    id={id}
    key={`file${String(index)}`}
    className={cx({
      [styles.attachments__row]: true,
      [styles['attachments__row--disable']]: disable,
    })}
  >
    <div className={styles['attachments__file-name']}>{title}</div>
    <div className={styles['attachments__icons-wrapper']}>
      {isEditIcon && (
        <div
          className={styles['attachments__edit-icon']}
          onClick={disable ? (): null => null : editMethod}
          role="presentation"
        >
          <img src={PencilIcon} alt="organisation" />
        </div>
      )}
      <div
        className={styles['attachments__delete-icon']}
        onClick={disable ? (): null => null : deleteAttachments}
        role="presentation"
      >
        <DeleteIcon />
      </div>
    </div>
  </div>
);
