import React, { ReactElement, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  Tooltip,
  LabelList,
} from 'recharts';
import moment from 'moment';

import {
  GetState,
  getAllPatientProms,
  getCurrenLang,
  getCurrentFormatDate,
  getHcpName,
  getLoadingAllProms,
  getPatientPromsLoading,
} from '../../../redux/selector';
import style from '../styles.module.css';
import {
  MobilePortretScreenSize,
  chartTitleKeyQuestionnaries,
  getCompletedText,
  getDataForGraph,
  setDomain,
} from '../../../utils/helper_charts';
import { TooltipRecharts } from '../../../common/TooltipRecharts';
import { spinner } from '../../../common/Loader';
import { statusQuestionnaries } from '../../../utils/enums';
// import { LegendRecharts } from '../../../common/Legend';
// import { chartLegendStyles } from '../../../utils/variables';
import { setEqLegendValue } from '../../../redux/questionnaires';
import { OxfordChartProps, TQuestionary } from '../types';

export const Oxford = ({
  nameGraph,
  chartId,
  srcColumnName,
  loading,
}: OxfordChartProps): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const not_completed = t?.dashboard.hcp.profile_patient.questionnaires.not_completed;
  const date_completed = t?.dashboard.hcp.profile_patient.questionnaires.date_completed;
  const surgery_date = t?.dashboard.hcp.profile_patient.completion_and_steps.surgery_day;
  const no_data = t?.common.no_data;
  const contact_otm = t?.common.contact_otm;

  const allowedProms: any = useSelector((state: GetState) => getHcpName(state));
  const isAllowedProms = allowedProms.allowedPromsId.includes(chartId);

  const patientPromsData: any = useSelector<any>((state) => getAllPatientProms(state));
  const windowClientWidth = useSelector((state: any) => state.common.screen.innerWidth);
  const formatsDate = useSelector((state: GetState) => getCurrentFormatDate(state));
  const isMobileLandscapeScreen = windowClientWidth > MobilePortretScreenSize;
  const myContainer: any = useRef(null);
  const marginChart = {
    top: 20,
    left: -35, // -15,
    right: windowClientWidth >= 1000 ? -30 : 0, // 30,
    bottom: 100,
  };

  const domain = setDomain(t, nameGraph);

  const dataToOxford = useMemo(() => {
    const maxLengthDataToChart = 12;
    const data = getDataForGraph(patientPromsData, chartId);
    const dataLength = data?.length;
    if (dataLength > maxLengthDataToChart) {
      const result = data.slice(dataLength - maxLengthDataToChart);
      return result;
    }
    return data;
  }, [patientPromsData, chartId]);

  const initDate: any =
    dataToOxford &&
    dataToOxford.map((item: TQuestionary) => {
      const oxfordData: any = {
        chartTitle: item.chartTitle,
        [nameGraph]: '',
        display: item.completedDate
          ? moment(item.completedDate).format(formatsDate.momentFormat)
          : '',
        markerStart: item.joinDateAtChart,
        visibility: item.status,
      };

      if (item.chartTitleKey === chartTitleKeyQuestionnaries.surgeryDate) {
        oxfordData[nameGraph] = surgery_date;
        return { ...oxfordData };
      }
      if (item.status === statusQuestionnaries.COMPLETED) {
        if (item[srcColumnName]) {
          oxfordData[nameGraph] = item[srcColumnName];
          return { ...oxfordData };
        }
        oxfordData[nameGraph] = 0;
        return { ...oxfordData };
      }
      if (item.status === statusQuestionnaries.SKIPPED) {
        oxfordData[nameGraph] = not_completed;
        return { ...oxfordData };
      }
      return { ...oxfordData };
    });

  const renderCustomizedLabel = (props: any): ReactElement => {
    const { x, y, value, width } = props;
    const height =
      myContainer?.current?.state?.containerHeight - (marginChart.bottom + marginChart.top + 30);
    switch (value) {
      case surgery_date: // 'Surgery date'
        return (
          <foreignObject x={x - 4} y={20} width={22} height={height}>
            {/* DASHED LINE ---- */}
            <div
              style={{
                writingMode: 'vertical-rl',
                color: '#E2007A',
                height: '100%',
                width: '50%',
                borderRight: '1px dashed #E2007A',
              }}
            />
          </foreignObject>
        );
      case not_completed:
        return (
          <foreignObject x={x - 5} y={20} width={22} height={height}>
            <div
              style={{
                writingMode: 'vertical-rl',
                transform: 'rotate(180deg)',
                fontSize: '14px',
                color: '#9BA1AE',
                paddingTop: '5px',
                height: '100%',
              }}
            >
              {not_completed}
            </div>
          </foreignObject>
        );

      default:
        return (
          <text
            x={x + width / 2}
            y={y - 10}
            fill="#000"
            textAnchor="middle"
            dominantBaseline="middle"
            fontSize="14px"
          >
            {value}
          </text>
        );
    }
  };

  const renderCustomizedLabel2 = (props: any): ReactElement => {
    const { x, value, markerStart, display, visibility } = props;

    return (
      <foreignObject
        x={x - 17}
        y={
          myContainer?.current?.state?.containerHeight - (marginChart.top + marginChart.bottom + 5)
        }
        width={47}
        height={100}
      >
        <div
          style={{
            writingMode: 'vertical-rl',
            transform: 'rotate(180deg)',
            fontSize: '14px',
            color: '#9BA1AE',
            height: '100%',
            lineHeight: '14px',
            margin: '0 auto',
          }}
        >
          {value}
          <br />
          {!markerStart &&
            isMobileLandscapeScreen &&
            getCompletedText(visibility, date_completed, not_completed)}
          {!markerStart && isMobileLandscapeScreen && <br />}
          {display}
        </div>
      </foreignObject>
    );
  };

  // Custom Tooltip
  const renderTooltip = (payload: any): JSX.Element => {
    const pay = payload.payload;
    // if (windowClientWidth > 1000) {
    return <TooltipRecharts payload={pay} type="" t={t} />;
    // }
    // if (payload?.active) {
    //   dispatch(setEqLegendValue(pay));
    // }
    // return <></>;
  };

  // JSX
  const noData: any = !loading && initDate?.length === 0 && (
    <div className={style.proms__nodata}>{isAllowedProms ? no_data : contact_otm}</div>
  );

  return (
    <div className={style['proms-chart__container']}>
      {noData}

      <ResponsiveContainer width="100%" height="100%" ref={myContainer}>
        <BarChart data={initDate} margin={marginChart}>
          <CartesianGrid vertical={false} />
          <XAxis dataKey=" " axisLine={false} tickLine={false} />
          <YAxis
            stroke="#44C1D8"
            domain={domain}
            axisLine={false}
            tickLine={false}
            // @ts-ignore
            style={{
              fontSize: '14px',
            }}
          />
          {!noData && (
            <Tooltip
              allowEscapeViewBox={{ x: false, y: false }}
              offset={windowClientWidth > 1000 ? 0 : 500}
              content={renderTooltip}
            />
          )}
          {/* {!isOverView && windowClientWidth > 1000 && (
            <Legend
              verticalAlign="middle"
              content={<LegendRecharts type={type} t={t} procedureKey="" />}
              wrapperStyle={chartLegendStyles}
            />
          )} */}

          <Bar barSize={15} dataKey={nameGraph} fill="#44C1D8" isAnimationActive={false}>
            <LabelList dataKey={nameGraph} position="insideTop" content={renderCustomizedLabel} />
            <LabelList dataKey="chartTitle" position="bottom" content={renderCustomizedLabel2} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
      <span className={style['proms-chart__label--yAxis']} style={{ color: '#44C1D8' }}>
        {nameGraph}
      </span>
    </div>
  );
};
