import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Pagination, Switch } from 'antd';

import pageStyles from '../../styles.module.css';
import style from './style.module.css';
import { getCurrenLang } from '../../../redux/selector';
import { Loading, MainTitle } from '../../../common';
import { BellEmptyIcon } from '../../../theme/icons';
import { BreadcrumbAnt } from '../../../utils/routers/Breadcrumb';
import NotificationAction from '../../../common/Elements/NotificationAction';
import { TNotification } from '../../../components/SideBar/types';
import useCheckRole from '../../../hooks/useCheckRole';
import { NOTIF_STATUS } from '../../../utils/enums';
import { NotificationItem } from '../../../components/OverviewCards/Elements';
import { PAGE_SIZE_OPTIONS_FOR_VIDEO_EXERCISE } from '../../../utils/variables';
import {
  clearState,
  onChangePage,
  searchQueryInit,
  setNotificationsStatus,
  setSearchQuery,
} from '../../../redux/notifications';
import {
  useChangeStateNotifications,
  useGetHcpNotificationList,
} from '../../../graphql/notifications';
import useRedirectFromNotification from '../../../hooks/useRedirectFromNotification';
import SearchClient from '../../../components/Search/SearchClient';
import useDebounce from '../../../hooks/useDebounce';
import { initialPage } from '../../../utils/helper';

const NotificationPage = (): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const on = t?.common.on;
  const off = t?.common.off;
  const onlyShowUnread = t?.header.only_show_unread;
  const all_notifications = t?.notifications.all_notifications;
  const search_notif = t?.notifications.search_notif;
  const allNotificationsRead = t?.header.all_notifications_read;

  const { isAdmin } = useCheckRole();
  const { redirectFromNotification } = useRedirectFromNotification();

  // Endpoints
  const {
    _changeHcpNotifStatus,
    changeHcpNotifsStatusLoading,
    changeHcNotifsStatusError,
    changedHcpNotifsStatus,
  } = useChangeStateNotifications();
  // Get Hcp notifications
  const {
    _getHcpNotifications,
    loadingHcpNotifications,
    hcpNotificationsData,
  } = useGetHcpNotificationList();

  const redirectStateInit = {
    isRedirect: false,
    type: '',
    patientId: 0,
    patientStatus: '',
    notifId: 0,
    isUnread: true,
  };

  // Local state
  const [checkedNotifications, setCheckedNotifications] = useState<any>([]);
  const [isRedirectState, setRedirectState] = useState({ ...redirectStateInit });
  const [search, setSearch] = useState<string>('');

  // Redux
  const dispatch = useDispatch();
  const {
    currentPage,
    searchQuery,
    itemsPerPage,
    totalItems,
    notificationsStatus,
    notificationsList,
  }: any = useSelector<any>((state) => state.notifications);
  const checkedSwitch = notificationsStatus === NOTIF_STATUS.UNREAD;

  // After close page set current page to init number
  useEffect(
    () => () => {
      dispatch(clearState());
    },
    [],
  );

  // Get data after search
  useEffect(() => {
    dispatch(onChangePage({ currentPage: initialPage, itemsPerPage }));
    _getHcpNotifications(notificationsStatus, currentPage, itemsPerPage, searchQuery);
  }, [searchQuery]);

  // Select all notifs
  const checkAllItems = (e: any): void => {
    const { checked } = e.target;
    const checkedIds: any = [];
    if (checked) {
      notificationsList.map((notification: TNotification) => {
        checkedIds.push(notification.hcpNotificationId);
        return null;
      });
    }
    setCheckedNotifications(checkedIds);
  };
  // Select one notif
  const checkItem = (e: any, notifId: number): void => {
    const { checked } = e.target;
    if (checked) {
      setCheckedNotifications([...checkedNotifications, notifId]);
      return;
    }
    const newCheckedNotifications = checkedNotifications.filter(
      (checkedNotifId: number) => notifId !== checkedNotifId,
    );
    setCheckedNotifications(newCheckedNotifications);
  };

  // Switch notifikation status (read/unread)
  const switchHandler = (checked: boolean): void => {
    const newStatus = checked ? NOTIF_STATUS.UNREAD : NOTIF_STATUS.READ;
    _getHcpNotifications(newStatus, currentPage, itemsPerPage, searchQuery);
    dispatch(setNotificationsStatus(newStatus));
    setCheckedNotifications([]);
  };

  // Update notification list
  const updateList = (): void => {
    if (isAdmin) {
      return;
    }
    _getHcpNotifications(notificationsStatus, currentPage, itemsPerPage, searchQuery);
  };

  // Get Patient notificatons
  useEffect(() => {
    updateList();
  }, []);

  // If change status error show message
  useEffect(() => {
    if (changeHcNotifsStatusError) {
      toast(changeHcNotifsStatusError);
    }
  }, [changeHcNotifsStatusError]);

  // If changed notifications status
  useEffect(() => {
    if (changedHcpNotifsStatus) {
      setCheckedNotifications([]);
      updateList();
    }
  }, [changedHcpNotifsStatus]);

  // Change notification status
  const setReadStatus = (): void => {
    if (checkedNotifications.length) {
      _changeHcpNotifStatus(checkedNotifications);
    }
  };

  const debounceValue = useDebounce(search, 300);

  // Get search result from DB after delay
  useEffect(() => {
    const searchObj = { ...searchQueryInit };
    if (debounceValue.length >= 2) {
      searchObj.searchValue = debounceValue;
    }
    dispatch(setSearchQuery(searchObj));
  }, [debounceValue]);

  // Search exercise
  const changeSearch = ({ target: { value } }: any): void => {
    setSearch(value);
  };

  // Go to the correspond page
  const onChangePagination = (page: number, itemsPerPag?: number | undefined): void => {
    dispatch(onChangePage({ currentPage: page, itemsPerPage: itemsPerPag }));
    _getHcpNotifications(notificationsStatus, page, itemsPerPag || 15, searchQuery);
  };

  // Redirect to correspond page after click icon
  const redirectTo = (
    type: string,
    currentPatientId: number,
    currentPatientStatus: string,
    notifId: number,
    isUnread: boolean,
  ): void => {
    setRedirectState({
      isRedirect: true,
      type,
      patientId: currentPatientId,
      patientStatus: currentPatientStatus,
      notifId,
      isUnread,
    });

    if (isUnread) {
      _changeHcpNotifStatus([notifId]);
      return;
    }
    redirectFromNotification(type, currentPatientId, currentPatientStatus);
  };

  useEffect(() => {
    if (hcpNotificationsData && !loadingHcpNotifications && isRedirectState.isRedirect) {
      const {
        type,
        patientId: currentPatientId,
        patientStatus: currentPatientStatus,
      } = isRedirectState;
      redirectFromNotification(type, currentPatientId, currentPatientStatus);
      setRedirectState({ ...redirectStateInit });
    }
  }, [hcpNotificationsData]);

  // JSX
  const loadingJSX = (changeHcpNotifsStatusLoading || loadingHcpNotifications) && <Loading />;
  // Breadcrumb path
  const routes = [
    {
      path: all_notifications,
      breadcrumbName: all_notifications,
    },
  ];

  const noData =
    isAdmin ||
    (notificationsList?.length === 0 && !loadingJSX && (
      <div className={pageStyles.nodata}>{allNotificationsRead}</div>
    ));

  return (
    <div className={pageStyles.main__container}>
      {loadingJSX}
      <BreadcrumbAnt currentRoutes={routes} />
      <div className={pageStyles['main__flex-wrapper']}>
        {/* Profile form */}
        <div className={pageStyles['left-section']}>
          <MainTitle title={all_notifications} icon={<BellEmptyIcon />} />
          <div className={style['notification__search-wrapper']}>
            <SearchClient
              inputId="searchExercise"
              name="searchExercise"
              placeholder={search_notif}
              inputValue={search}
              changeFilter={changeSearch}
            />
          </div>

          <div className={style['notification__action-container']}>
            <NotificationAction
              t={t}
              checkAllItems={checkAllItems}
              isCheked={
                checkedNotifications.length === notificationsList.length && !noData && !loadingJSX
              }
              updateList={updateList}
              disabled={!checkedNotifications.length}
              setReadStatus={setReadStatus}
            />

            {/* Only show uread */}
            <div className={style['notifications__switch-container']}>
              <div className={style['notifications__switch-label']}>{onlyShowUnread}</div>
              <Switch
                checkedChildren={on}
                unCheckedChildren={off}
                checked={checkedSwitch}
                onChange={(checked: boolean): void => {
                  switchHandler(checked);
                }}
              />
            </div>
          </div>

          {/* List */}
          {notificationsList &&
            !noData &&
            notificationsList.map((notification: TNotification, index: number) => (
              <NotificationItem
                key={JSON.stringify(notification)}
                patientId={notification.patientId}
                patientStatus={notification.patientStatus} // "connected"
                data={notification}
                index={index}
                checkItem={checkItem}
                isHcpNotif
                isCheked={
                  checkedNotifications.length &&
                  checkedNotifications.includes(notification.hcpNotificationId)
                }
                redirectTo={redirectTo}
              />
            ))}
          {noData}

          <div className="wrapper_pagination">
            <Pagination
              size="small"
              pageSizeOptions={PAGE_SIZE_OPTIONS_FOR_VIDEO_EXERCISE}
              current={currentPage}
              pageSize={itemsPerPage}
              total={totalItems}
              onChange={onChangePagination}
              showSizeChanger
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationPage;
