import React, { ReactElement } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import styles from './styles.module.css';
import { GetState, getCurrenLang, getRewardSettingsData } from '../../redux/selector';
import { setOpenRewardSettingData } from '../../redux/rewards';

export const Reward = ({ index, data, isDisable }: any): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const set_reward = t?.hcp.rewards.set_reward;
  const edit_reward = t?.hcp.rewards.edit_reward;
  const at_sign_up = t?.hcp.rewards.at_sign_up;
  const week_t = t?.dashboard.hcp.profile_patient.completion_and_steps.week;

  const periodName = index === 0 ? at_sign_up : `${week_t || ''} ${index}`;
  const isEdit = !!data.name;
  const dispatch = useDispatch();

  // Redux
  const isOpenSettigsData = useSelector((state: GetState) => getRewardSettingsData(state));
  const rewardIsActive = isOpenSettigsData?.index === index;

  // Close form and clear settings state
  const closeSettings = (): void => {
    dispatch(setOpenRewardSettingData(null));
  };

  // Open current team setting
  const openSettings = (): void => {
    if (rewardIsActive) {
      closeSettings();
    } else {
      dispatch(setOpenRewardSettingData({ ...data, index }));
    }
  };

  return (
    <div className={styles.reward__row}>
      <div className={styles.reward__name}>{periodName}</div>
      <div className={styles.reward__logo}>
        <div className={styles['reward__logo-placeholder']}>
          {data.fileLink ? <img src={data.fileLink} alt="Logo" /> : '100 x 80'}
        </div>
      </div>
      <div className={styles.reward__description}>{data.description}</div>
      <div
        className={cx({
          [styles['reward__btn-set']]: true,
          [styles.green]: !isEdit,
          [styles.blue]: isEdit,
          [styles.orange]: rewardIsActive,
          [styles.grey]: isDisable,
        })}
        onClick={isDisable ? (): null => null : openSettings}
        aria-hidden
      >
        {isEdit ? edit_reward : set_reward}
      </div>
    </div>
  );
};
