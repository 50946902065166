import React, { ReactElement, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  LabelList,
  ComposedChart,
  Line,
} from 'recharts';
import moment from 'moment';

import {
  GetState,
  getAllPatientProms,
  getCurrenLang,
  getCurrentFormatDate,
  getHcpName,
  getLoadingAllProms,
  getPatientPromsLoading,
} from '../../../redux/selector';
import style from '../styles.module.css';
import {
  MobilePortretScreenSize,
  chartTitleKeyQuestionnaries,
  getCompletedText,
  getDataForGraph,
  maxSf,
  minSf,
} from '../../../utils/helper_charts';
import { TooltipRecharts } from '../../../common/TooltipRecharts';
import { HealthScoresType, PromsChartProps, TQuestionary } from '../types';
import { RenderDot } from './RenderDot';
import { statusQuestionnaries } from '../../../utils/enums';

export const Sf36 = ({ nameGraph, chartId, loading }: PromsChartProps): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const not_completed = t?.dashboard.hcp.profile_patient.questionnaires.not_completed;
  const date_completed = t?.dashboard.hcp.profile_patient.questionnaires.date_completed;
  const no_data = t?.common.no_data;
  const contact_otm = t?.common.contact_otm;

  const allowedProms: any = useSelector((state: GetState) => getHcpName(state));
  const isAllowedProms = allowedProms.allowedPromsId.includes(chartId);

  // const loadingProms = useSelector<any>((state) => getPatientPromsLoading(state));
  // const loadingAllProms = useSelector<any>((state) => getLoadingAllProms(state));
  const patientPromsData: any = useSelector<any>((state) => getAllPatientProms(state));
  // const allProms: any = useSelector<any>((state) => getAllProms(state));
  const windowClientWidth = useSelector((state: any) => state.common.screen.innerWidth);
  const formatsDate = useSelector((state: GetState) => getCurrentFormatDate(state));
  const isMobileLandscapeScreen = windowClientWidth > MobilePortretScreenSize;
  const myContainer: any = useRef(null);
  const marginChart = {
    top: 20,
    left: -35,
    right: windowClientWidth >= 1000 ? -30 : 0, // 30,
    bottom: 100,
  };

  const dataToSf36 = useMemo(() => {
    const maxLengthDataToChart = 12;
    const data = getDataForGraph(patientPromsData, chartId);
    const dataLength = data?.length;
    if (dataLength > maxLengthDataToChart) {
      const result = data.slice(dataLength - maxLengthDataToChart);
      return result;
    }
    return data;
  }, [patientPromsData, chartId]);

  const initData: any =
    dataToSf36?.length > 0 &&
    dataToSf36.map((item: TQuestionary) => {
      const sf36Data: any = {
        chartTitle: JSON.stringify({
          title: item.chartTitle,
          display: item.completedDate
            ? moment(item.completedDate).format(formatsDate.momentFormat)
            : '',
          markerStart: item.joinDateAtChart,
          visibility: item.status,
          chartTitleKey:
            item.status === statusQuestionnaries.SKIPPED ? not_completed : item.chartTitleKey,
        }),
      };
      const transformedDataSf: any = {
        colors: [],
      };
      if (item.healthScores?.length > 0) {
        item.healthScores.map((healthScore: HealthScoresType) => {
          if (item.status === statusQuestionnaries.COMPLETED) {
            transformedDataSf[healthScore.domain] = healthScore.score;
          } else {
            transformedDataSf[healthScore.domain] = null;
          }
          transformedDataSf.colors.push({
            domain: healthScore.domain,
            color: healthScore.color,
          });
          return null;
        });
      }
      const result = { ...sf36Data, ...transformedDataSf };
      return result;
    });

  // Labels for nullable periods
  const renderCustomizedLabel = (props: any): ReactElement => {
    const { x, value } = props;
    const parsedData = JSON.parse(value);
    const { chartTitleKey } = parsedData;
    const height =
      myContainer?.current?.state?.containerHeight - (marginChart.bottom + marginChart.top + 30);

    switch (chartTitleKey) {
      case chartTitleKeyQuestionnaries.surgeryDate:
        return (
          <foreignObject x={x - 10} y={20} width={22} height={height}>
            {/* DASHED LINE ---- */}
            <div
              style={{
                writingMode: 'vertical-rl',
                color: '#E2007A',
                height: '100%',
                width: '50%',
                borderRight: '1px dashed #E2007A',
              }}
            />
          </foreignObject>
        );
      case not_completed:
        return (
          <foreignObject x={x - 12} y={20} width={22} height={height}>
            <div
              style={{
                writingMode: 'vertical-rl',
                transform: 'rotate(180deg)',
                fontSize: '14px',
                color: '#9BA1AE',
                paddingTop: '5px',
                height: '100%',
              }}
            >
              {not_completed}
            </div>
          </foreignObject>
        );

      default:
        return <></>;
    }
  };

  // Custom titles for XAxis
  const renderCustomizedLabel2 = (props: any): ReactElement => {
    const { x, value } = props;
    const parsedData = JSON.parse(value);
    const { markerStart, display, visibility, title } = parsedData;

    return (
      <foreignObject
        x={x - 22}
        y={
          myContainer?.current?.state?.containerHeight - (marginChart.top + marginChart.bottom + 5)
        }
        width={47}
        height={100} // {40}
      >
        <div
          style={{
            writingMode: 'vertical-rl',
            transform: 'rotate(180deg)',
            fontSize: '14px',
            color: '#9BA1AE',
            height: '100%',
            lineHeight: '14px',
            margin: '0 auto',
          }}
        >
          {title}
          <br />
          {!markerStart &&
            isMobileLandscapeScreen &&
            getCompletedText(visibility, date_completed, not_completed)}
          {!markerStart && isMobileLandscapeScreen && <br />}
          {display}
        </div>
      </foreignObject>
    );
  };

  // Custom Tooltip
  const renderTooltip = (payload: any): JSX.Element => {
    const pay = payload.payload;
    return <TooltipRecharts payload={pay} type="" t={t} />;
  };

  // JSX
  const noData: any = !loading && initData?.length === 0 && (
    <div className={style.proms__nodata}>{isAllowedProms ? no_data : contact_otm}</div>
  );

  return (
    <div className={style['proms-chart__container']}>
      {noData}
      <ResponsiveContainer width="100%" height="100%" ref={myContainer}>
        <ComposedChart data={initData} margin={marginChart} syncId="anyId">
          <CartesianGrid vertical={false} />
          <XAxis dataKey=" " axisLine={false} tickLine={false} tick={(): null => null} />
          <YAxis
            stroke="#44C1D8"
            domain={[minSf, maxSf]}
            axisLine={false}
            tickLine={false}
            // @ts-ignore
            style={{
              fontSize: '14px',
              color: '#000',
            }}
          />
          {!noData && (
            <Tooltip
              allowEscapeViewBox={{ x: false, y: false }}
              offset={windowClientWidth > 1000 ? 0 : 500}
              content={renderTooltip}
            />
          )}
          {/* {!isOverView && windowClientWidth > 1000 && (
            <Legend
              verticalAlign="middle"
              content={<LegendRecharts type={type} t={t} procedureKey="" />}
              wrapperStyle={chartLegendStyles}
            />
          )} */}
          {initData &&
            initData[0]?.colors?.map((label: HealthScoresType) => (
              <Line
                type="monotone"
                dataKey={label.domain}
                key={label.domain}
                strokeWidth={3}
                stroke={label.color}
                activeDot={{ stroke: `${label.color}`, strokeWidth: 3 }}
                dot={RenderDot}
                isAnimationActive={false}
                connectNulls
              >
                <LabelList
                  dataKey="chartTitle"
                  position="insideTop"
                  content={renderCustomizedLabel}
                />
                <LabelList
                  dataKey="chartTitle"
                  position="bottom"
                  content={renderCustomizedLabel2}
                />
              </Line>
            ))}
        </ComposedChart>
      </ResponsiveContainer>
      <span className={style['proms-chart__label--yAxis']} style={{ color: '#44C1D8' }}>
        {nameGraph}
      </span>
    </div>
  );
};
