import React, { ReactElement } from 'react';
import cx from 'classnames';
import { sanitize } from 'dompurify';
import moment from 'moment';

import { getPatientAnswers } from '../../../../../utils/helper_charts';
import { CalculationDescription } from './common/Calculation';
import { RemoveQuestionnaire } from './common/RemoveQuestionnaire';
import style from './style.module.css';
import { Answer, OxfordContentProps, Question } from './types';

export const OxfordContent = ({
  questionnairesList,
  completedQuestionnaire,
  procedureKey,
  patient,
  index,
  onDeleteItem,
  formatDate,
  t,
}: OxfordContentProps): ReactElement => {
  const remove_questionnaire = t?.dashboard.hcp.profile_patient.questionnaires.remove_questionnaire;
  const each_of_the = t?.dashboard.hcp.profile_patient.questionnaires.each_of_the;
  const score_is_scored = t?.dashboard.hcp.profile_patient.questionnaires.score_is_scored;
  const during_the_past = t?.dashboard.hcp.profile_patient.questionnaires.during_the_past_4_weeks;
  const e_g = t?.dashboard.hcp.profile_patient.questionnaires.e_g;
  const this_results = t?.dashboard.hcp.profile_patient.questionnaires.this_results_in_continuous;
  const problems_with_your = t?.dashboard.hcp.profile_patient.questionnaires.problems_with_your;
  const tick = t?.dashboard.hcp.profile_patient.questionnaires.tick;

  const procedure = patient?.procedure?.type?.name || '';
  const questionnaire_name = 'Oxford';
  let procedureType: any = '';
  switch (procedureKey) {
    case 'PROCEDURE_1':
      procedureType = 'OxfordHipScore';
      break;
    case 'PROCEDURE_2':
      procedureType = 'OxfordKneeScore';
      break;
    case 'PROCEDURE_3':
      procedureType = 'OxfordShoulderScore';
      break;
    default:
      procedureType = '';
      break;
  }
  const oxfList =
    questionnairesList && questionnairesList.filter((item: any) => item.name === procedureType)[0];
  if (oxfList && oxfList.questions) {
    oxfList.questions.sort((a: any, b: any) => {
      if (a.id > b.id) {
        return 1;
      }
      if (a.id < b.id) {
        return -1;
      }
      return 0;
    });
  }

  const questionsListPage1 = oxfList && oxfList.questions && oxfList.questions.slice(0, 6);
  const questionsListPage2 = oxfList && oxfList.questions && oxfList.questions.slice(6);
  const { questions: completedQuestions, completedDate } = completedQuestionnaire;
  const patientAnswers = getPatientAnswers(completedQuestions);

  const tickQuestionJSX = (
    <div
      className={style['oxf__subTitle-change-descr']}
      dangerouslySetInnerHTML={{ __html: sanitize(tick) }}
    />
  );

  const getQuestionJSX = (list: any, startNumbering: number): ReactElement => (
    <>
      {list.map((question: Question, i: number) => (
        <div key={question.name} className={style.oxf__row}>
          <div className={style.oxf__numbering}>{i + startNumbering}.</div>
          <div className={style['oxf__question-cont']}>
            <div className={style['oxf__question-description']}>
              {during_the_past}
              ........
            </div>
            <div
              className={style.oxf__question}
              dangerouslySetInnerHTML={{ __html: sanitize(question.name) }}
            />
            <div className={style['oxf__answer-cont']}>
              {question.answers.map((answer: Answer) => (
                <div key={answer.name} className={style['oxf__answer-box-cont']}>
                  <div
                    className={style.oxf__answer}
                    dangerouslySetInnerHTML={{ __html: sanitize(answer.name) }}
                  />
                  <div className={style['oxf__answer-box']}>
                    {patientAnswers[question.id] === answer.id && <>&#10004;</>}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </>
  );

  const calc_description = questionsListPage1.length > 0 && (
    <>
      <p>{`${each_of_the} ${procedure} ${score_is_scored}`}</p>
      <span>{e_g}</span>
      <div className={style['oxf__question-cont']}>
        <div className={style['oxf__question-description']}>
          {during_the_past}
          ........
        </div>
        <div
          className={style.oxf__question}
          dangerouslySetInnerHTML={{ __html: sanitize(questionsListPage1[0].name) }}
        />
        <div className={style['oxf__answer-cont']}>
          {questionsListPage1[0].answers.map((answer: Answer, i: number) => (
            <div key={answer.name} className={style['oxf__answer-box-cont']}>
              <div
                className={style.oxf__answer}
                dangerouslySetInnerHTML={{ __html: sanitize(answer.name) }}
              />
              <div className={style['oxf__answer-box']} />
              <b>{4 - i}</b>
            </div>
          ))}
        </div>
      </div>
      <p>{this_results}</p>
    </>
  );

  return (
    <>
      <RemoveQuestionnaire onMethod={() => onDeleteItem(index)} title={remove_questionnaire} />
      <div className="questionnaire-to-PDF">
        <div
          className={cx({
            [style.pcs__container]: true,
            [style.container]: true,
            [style.description__container]: true,
          })}
        >
          <CalculationDescription
            name={`${questionnaire_name} ${procedure}`}
            content={calc_description}
            date={completedDate ? moment(completedDate).format(formatDate) : ''}
            t={t}
          />
        </div>
      </div>
      {/* PAGE 1 */}
      <div className="questionnaire-to-PDF">
        <div className={style.oxf__container}>
          <div className={style.oxf__title}>{`${problems_with_your} ${procedure}`}</div>
          <div className={style['oxf__subTitle-cont']}>
            <div className={style.oxf__subTitle}>
              {during_the_past}
              ..
            </div>
            {tickQuestionJSX}
          </div>
          {getQuestionJSX(questionsListPage1, 1)}
          <div className={style.oxf__footer}>
            The Oxford&nbsp;
            {procedure}
            &nbsp;Score © Department of Public Health, University of Oxford, Old Road Campus, Oxford
            OX3 7LF , UK.
          </div>
          <div className={style['oxf__footer-pto']}>P.T.O./</div>
        </div>
      </div>

      {/* PAGE 2 */}
      <div className="questionnaire-to-PDF">
        <div className={style.oxf__container}>
          <div className={style['oxf__subTitle-cont']}>
            <div className={style.oxf__subTitlePage2}>
              {during_the_past}
              ...
            </div>
            {tickQuestionJSX}
          </div>
          {getQuestionJSX(questionsListPage2, 7)}
          <div className={style.oxf__footer}>
            © Department of Public Health, University of Oxford, Old Road Campus, Oxford OX3 7LF ,
            UK.
          </div>
        </div>
      </div>
    </>
  );
};
