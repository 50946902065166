import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import cx from 'classnames';
import moment from 'moment';
import { Tooltip } from 'antd';

import styles from './styles.module.css';
import { Loading, VideoModal } from '../../../../common';
import { DeleteIcon } from '../../../../theme/icons';
import { storage } from '../../../../utils';
import { constants } from '../../../../utils/routers/book';
import { uploadVideoForPatientInfoByHcp } from '../../../../utils/validators';
import { GetState, getCurrenLang, getCurrentFormatDate } from '../../../../redux/selector';
import MediaSettings from '../MediaSettings';
import { SuccessNotifModal, WarnNotifModal } from '../../../../common/NotificationModal';
import { MEDIA_TYPE, SOURCE_PI } from '../../../../utils/enums';
import { MediaForPatientProps } from '../../types';
import { useUpdateHcpDocument } from '../../../../graphql/patients';
import { useDeleteHcpDocument } from '../../../../graphql/attachments';
import getPreview from '../Preview';

const MediaForPatient = ({
  data,
  updateVideoList,
  isPermissionEditProfile,
}: MediaForPatientProps): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const ok = t?.common.ok;
  const cancel = t?.common.cancel;
  const delete_text = t?.common.delete;
  const settings_t = t?.dashboard.hcp.profile_patient.video_bank.settings;
  const video_updated = t?.hcp.manage_patient_information?.video_updated;
  const sure_delete_patient_info = t?.hcp.org_patient_info?.sure_delete_patient_info;
  const individual_pi = t?.dashboard.hcp.profile_patient.individual_pi;
  const organisation_pi = t?.dashboard.hcp.profile_patient.organisation_pi;
  const you_dont_have_permission = t?.common.you_dont_have_permission;

  const sourceName = useMemo(() => {
    switch (data.source) {
      case SOURCE_PI.HCP:
        return individual_pi;
      case SOURCE_PI.HOSPITAL:
        return organisation_pi;
      default:
        return '';
    }
  }, [data, t]);
  const isHospital = data.source === SOURCE_PI.HOSPITAL;
  const isIndividual = data.source === SOURCE_PI.HCP;

  // Local state
  const [isSettingsOpen, setOpenSettings] = useState(false);
  const [isVideoModalOpen, setVideoModal] = useState(false);
  const [videoSrcState, setVideoSrcState] = useState<any>('');
  const [isSuccessModalOpen, setSuccessModal] = useState(false);
  const [isWarningDeleteMediaModalOpen, setWarningDeleteMediaModal] = useState(false);

  const fromLS = storage.get('user');
  const role = fromLS && fromLS.role;
  const isAdmin = role === constants.admin;
  const formatsDate = useSelector((state: GetState) => getCurrentFormatDate(state));

  // Endpoints
  const {
    _updateHcpDocument,
    errorUpdateHcpDocument,
    loadingUpdateHcpDocument,
    documentUpdated,
  } = useUpdateHcpDocument();

  const { _deleteDocument, deletedData, deleteError, deleteLoading } = useDeleteHcpDocument();

  // Open video player
  const videoModalOpen = (): void => {
    setVideoModal(() => true);
  };

  const { hcpWhoUpdated, lastChanged } = data;

  const initialValues = {
    id: data.id,
    name: data.name,
    description: data.description || '',
    isAllowDownloadVideos: data.isAllowDownloadVideos,
  };

  const formik = useFormik({
    initialValues,
    validationSchema: () => uploadVideoForPatientInfoByHcp(t, isAdmin, true),
    onSubmit: async (values: any) => {
      const request = {
        id: values.id,
        name: values.name.trim(),
        description: values.description ? values.description.trim() : null,
        isAllowDownloadVideos: values.isAllowDownloadVideos,
      };

      _updateHcpDocument(request);
    },
  });

  // If update document error show message
  useEffect(() => {
    if (errorUpdateHcpDocument) {
      toast.error(errorUpdateHcpDocument);
    }
  }, [errorUpdateHcpDocument]);

  // // If update document success show message
  useEffect(() => {
    if (documentUpdated) {
      setSuccessModal(() => true);
    }
  }, [documentUpdated]);

  // If error delete Patient Information show message
  useEffect(() => {
    if (deletedData) {
      setWarningDeleteMediaModal(() => false);
      updateVideoList();
    }
  }, [deletedData]);

  // If error delete Patient Information show message
  useEffect(() => {
    if (deleteError) {
      toast.error(deleteError);
    }
  }, [deleteError]);

  // Get Video link from AWS
  // const showMedia = async (): Promise<void> => {
  //   videoModalOpen();
  //   setVideoSrcState(data.videoUuid);
  // };

  // Clouses open modal windows
  const onclose = (): void => {
    setVideoModal(() => false);
  };

  // Cancel changes and clouse settings
  const onCancel = (): void => {
    formik.resetForm();
    setOpenSettings(() => false);
  };

  // Clouse success modal window
  const oncloseModal = (): void => {
    setOpenSettings(() => false);
    setSuccessModal(() => false);
    updateVideoList();
  };

  // Delete Patient Info
  const onDeleteInfo = (): void => {
    _deleteDocument({
      variables: {
        documentId: data.id,
      },
    });
  };

  // JSX
  const loadingJSX = (loadingUpdateHcpDocument || deleteLoading) && <Loading />;

  const warnDelPIContentJsx = (
    <div className={styles['video-exercise__modal-description']}>{sure_delete_patient_info}</div>
  );

  const actionBtnJsx = (
    <Tooltip title={isPermissionEditProfile ? '' : you_dont_have_permission}>
      <div
        className={cx({
          [styles['video-exercise__btn--settings']]: true,
          [styles['video-exercise__btn--settings-open']]: isSettingsOpen,
          [styles['video-exercise__btn--disabled']]: !isPermissionEditProfile,
        })}
        aria-hidden
        onClick={
          isPermissionEditProfile
            ? (): void => setOpenSettings(() => !isSettingsOpen)
            : (): null => null
        }
      >
        {settings_t}
      </div>
    </Tooltip>
  );

  return (
    <div className={styles['video-exercise__item']}>
      <div className={styles['video-exercise__row']}>
        {loadingJSX}
        {/* Thumbnail */}
        <div className={styles['video-exercise__block']}>
          <div
            className={cx({
              [styles.preview]: true,
              [styles['preview-not-allowed']]:
                data.mediaType === MEDIA_TYPE.VIDEO && !data.videoUuid,
            })}
          >
            {getPreview(data, videoModalOpen, setVideoSrcState)}
          </div>
        </div>
        {/* Name and Description */}{' '}
        {data.content ? (
          <div className={styles['video-exercise__name-container']}>
            <div
              className={styles['video-exercise__content']}
              dangerouslySetInnerHTML={{ __html: JSON.parse(data.content) }}
            />
          </div>
        ) : (
          <div className={styles['video-exercise__name-container']}>
            <div className={styles['video-exercise__name']}>{data.name || data.url || ''}</div>
            <div className={styles['video-exercise__description']}>{data.description}</div>
            {/* Sm screen */}
            <div className={styles['video-exercise__focus-sm']}>{sourceName}</div>
            <div className={styles['video-exercise__action--sm']}>{actionBtnJsx}</div>
          </div>
        )}
        {/* Setting last changed */}
        <div className={styles['video-exercise__date-container']}>
          {lastChanged && (
            <>
              <div className={styles['video-exercise__date']}>
                {moment(Number(lastChanged)).format(formatsDate.momentFormat)}
              </div>
              <div className={styles['video-exercise__date-author']}>{hcpWhoUpdated}</div>
            </>
          )}
        </div>
        {/* SOURCE */}
        <div className={styles['video-exercise__focus']}>{sourceName}</div>
        {/* Action */}
        <div className={styles['video-exercise__action']}>{actionBtnJsx}</div>
      </div>
      {/* Inividual Info settings */}
      {isSettingsOpen && isIndividual && (
        <Tooltip title={isPermissionEditProfile ? '' : you_dont_have_permission}>
          <div
            className={cx({
              [styles['settings__btn-delete-wraper']]: true,
              [styles.notAllowed]: !isPermissionEditProfile,
            })}
            role="presentation"
            onClick={
              isPermissionEditProfile
                ? (): void => setWarningDeleteMediaModal(() => true)
                : (): null => null
            }
          >
            <DeleteIcon />
            <span className={styles['settings__btn-delete-text']}>{delete_text}</span>
          </div>
        </Tooltip>
      )}

      {/* Hospital Info settings */}
      {isSettingsOpen && isHospital && (
        <MediaSettings
          formik={formik}
          onCancel={onCancel}
          loadingJSX={loadingJSX}
          setWarningDeleteMediaModal={setWarningDeleteMediaModal}
        />
      )}
      {/* Popups */}
      {isVideoModalOpen && <VideoModal videoSrc={videoSrcState} onclose={onclose} />}

      {/* Warning befor delete media content */}
      {isWarningDeleteMediaModalOpen && (
        <WarnNotifModal
          onClose={(): void => setWarningDeleteMediaModal(false)}
          content={warnDelPIContentJsx}
          cancelBtnName={cancel}
          actionBtnName={ok}
          actionMethod={onDeleteInfo}
        />
      )}

      {isSuccessModalOpen && (
        <SuccessNotifModal onClose={oncloseModal} description={video_updated} btnName={ok} />
      )}
    </div>
  );
};

export default MediaForPatient;
