import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';

import pageStyles from '../../styles.module.css';
import { getCurrenLang } from '../../../redux/selector';
import { BreadcrumbAnt } from '../../../utils/routers/Breadcrumb';
import { MainTitle } from '../../../common';
import { RewardsIcon } from '../../../theme/icons';
import RewardsAdmin from '../../../components/Rewards/RewardsAdmin';

const RewardsAdminPage = (): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const rewards_t = t?.common.rewards;

  // Breadcrumb path
  const routes = [
    {
      path: rewards_t,
      breadcrumbName: rewards_t,
    },
  ];

  return (
    <div className={pageStyles.main__container}>
      {/* {loadingJSX} */}
      <BreadcrumbAnt currentRoutes={routes} />
      <div className={pageStyles.main__wrapper}>
        <MainTitle title={rewards_t} icon={<RewardsIcon />} />
        <div className={pageStyles['left-section']}>
          <RewardsAdmin />
        </div>
      </div>
    </div>
  );
};

export default RewardsAdminPage;
