import React, { useState, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import cx from 'classnames';

import { getCurrenLang } from '../../../redux/selector';
import style from './style.module.css';
import { SelectDownIcon, SelectUpIcon, TickIcon } from '../../../theme/icons';

const PatientAnswer = ({ itemTitle, answer, i, isOverview }: any): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const action_plan = t?.dashboard.hcp.profile_patient.action_plan;
  const reviewed = t?.dashboard.hcp.profile_patient.reviewed;
  const reviewed_by_patient = t?.dashboard.hcp.profile_patient.reviewed_by_patient;

  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <div className={style['goals__field-container']}>
        <div
          className={cx({
            [style.goals__field]: true,
            [style['goals__field-overview']]: isOverview,
          })}
        >
          <div className={style['goals__field-label']}>{`${itemTitle} ${i + 1}`}</div>
          <div className={style['goals__field-value']}>{answer.goalValue}</div>
        </div>
        {answer.dateAnswer && !isOverview && (
          <div className={style['goals__reviewed-container']}>
            <TickIcon />
            <div className={style.goals__reviewed}>{reviewed}</div>
            <div
              aria-hidden
              onClick={(): void => setOpen(() => !isOpen)}
              className={style.goals__selector}
            >
              {isOpen ? <SelectDownIcon /> : <SelectUpIcon />}
            </div>
          </div>
        )}
      </div>
      {answer.dateAnswer && isOverview && (
        <div className={style['goals__date-answer']}>
          <TickIcon />
          {` ${reviewed_by_patient} ${
            answer.dateAnswer ? moment(answer.dateAnswer).format('Do MMM YYYY') : '-' // format(formatsDate.momentFormat)
          }`}
        </div>
      )}
      {(!isOverview || !answer.dateAnswer) && <div className={style['goals__margin-bottom']} />}
      {isOverview && <div className={style.goals__border} />}
      {isOpen && (
        <div className={style['goals__patient-answers']}>
          <div className={style['goals__date-answer']}>
            {`${reviewed_by_patient} ${
              answer.dateAnswer ? moment(answer.dateAnswer).format('Do MMMM YYYY') : '-' // moment(answer.dateAnswer).format(formatsDate.momentFormat) : '-'
            }`}
          </div>
          {answer.answer && (
            <>
              <div className={style['goals__title-answer']}>{answer.titleAnswer}</div>
              <div className={style.goals__answer}>{answer.answer}</div>
              {(answer.planA || answer.planB || answer.hcpHelp) && (
                <div className={style.goals__spacer} />
              )}
            </>
          )}

          {(answer.planA || answer.planB) && (
            <>
              <div className={style['goals__title-answer']}>{answer.titlePlans}</div>
              <div className={style['goals__subtitle-answer']}>{`${action_plan} A`}</div>
              <div className={style.goals__answer}>{answer.planA}</div>

              <div className={style['goals__subtitle-answer']}>{`${action_plan} B`}</div>
              <div className={style.goals__answer}>{answer.planB}</div>

              {answer.hcpHelp && <div className={style.goals__spacer} />}
            </>
          )}

          {answer.hcpHelp && (
            <>
              <div className={style['goals__title-answer']}>{answer.titleHcpHelp}</div>
              <div className={style.goals__answer}>{answer.hcpHelp}</div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default PatientAnswer;
