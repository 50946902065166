/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { ReactElement } from 'react';
import { Button } from '../Button/Button';
import { CloseIcon, CheckIcon } from '../../theme/icons';

import styles from './styles.module.css';

interface Confirmation {
  icon?: string;
  title?: string;
  contant: string;
  buttonTitle?: string;
  onClose?: string;
  buttonMethod?: () => void;
  contentClass?: any;
}

export const Confirmation = ({
  icon,
  title,
  onClose: close,
  contant,
  buttonTitle,
  buttonMethod,
}: Confirmation): ReactElement => {
  const onClose = (): void => {
    window.location.assign(close || '/');
  };

  return (
    <div className={styles.container}>
      {close && (
        <div className={styles.close} onClick={onClose} role="button" tabIndex={0}>
          <CloseIcon />
        </div>
      )}

      {icon === 'success' && (
        <div className={styles.icon}>
          <CheckIcon style={{ height: '46px' }} />
        </div>
      )}

      <div className={styles.message}>{title}</div>
      <div className={styles.contant}>{contant}</div>

      {buttonTitle && (
        <Button
          buttonType="submit"
          buttonName={buttonTitle}
          buttonMethod={buttonMethod}
          buttonClass={styles.btn__submit}
        />
      )}
    </div>
  );
};

export const ConfirmatioModal = ({
  title,
  contant,
  buttonTitle,
  buttonMethod,
  contentClass,
}: Confirmation): ReactElement => (
  <div className={styles.container_modal}>
    <div className={styles.close} role="button" tabIndex={0}>
      <CloseIcon onClick={buttonMethod} />
    </div>
    <div className={styles.icon}>
      <CheckIcon style={{ height: '46px' }} />
    </div>
    {title && <div className={styles.message}>{title}</div>}
    <div className={contentClass || styles.contant}>{contant}</div>
    {buttonTitle && (
      <Button
        buttonType="button"
        buttonName={buttonTitle}
        buttonMethod={buttonMethod}
        buttonClass={`${styles.btn__submit} ${styles['btn__submit--confirm']}`}
      />
    )}
  </div>
);
