import { ChartValuesType } from '../motivations';

export const FILL_PATIENTS_STEPS = 'FILL_PATIENTS_STEPS';
export const STEPS_LOADING = 'STEPS_LOADING';
export const STEPS_ERROR = 'STEPS_ERROR';

export const FILL_EXERCISE_COMPLETION = 'FILL_EXERCISE_COMPLETION';
export const EXERCISE_COMPLETION_LOADING = 'EXERCISE_COMPLETION_LOADING';
export const EXERCISE_COMPLETION_ERROR = 'EXERCISE_COMPLETION_ERROR';
export const FILL_EXERCISES_PER_PERIOD = 'FILL_EXERCISES_PER_PERIOD';
export const EXERC_PER_PERIOD_LOADING = 'EXERC_PER_PERIOD_LOADING';
export const FILL_NOT_COMPLETION_EXERCISE = 'FILL_NOT_COMPLETION_EXERCISE';
export const NOT_COMPLETION_EXERCISE_LOADING = 'NOT_COMPLETION_EXERCISE_LOADING';
export const NOT_COMPLETION_EXERCISE_ERROR = 'NOT_COMPLETION_EXERCISE_ERROR';

export type ErrorHttpAction = any;
export interface DataType {
  data: object[];
}
export interface ExercPerPeriodType {
  id: number;
  name: string;
  exerciseDone: any;
  timesPerDay: number;
}
export interface StepsDataType {
  graph: string;
  title: string;
  description: string;
  averagePercent: number;
  values: ChartValuesType[];
  axis: string;
}
export interface ExercComplDataType {
  graph: string;
  title: string;
  description: string;
  averagePercent: number;
  values: ChartValuesType[];
  axis: string;
}
export interface NotComplExercValuesType {
  name: string;
  numberOfSkippedExercises: number;
  numberOfStoppedExercises: number;
  averageNumberOfReps: number;
  reasons: {
    tooPainful: number;
    lackOfEquipment: number;
    noTime: number;
    didntUnderstand: number;
    fatigue: number;
    willDoLater: number;
    alreadyDone: number;
    noReason: number;
    restDay: number;
  };
}
export interface NotComplExercDataType {
  graph: string;
  title: string;
  description: string;
  axisNumber: string;
  axisReasons: string;
  values: NotComplExercValuesType[];
}

export interface PatientFillStep {
  type: typeof FILL_PATIENTS_STEPS;
  payload: StepsDataType[] | [];
}
export interface FillPatientExercCompl {
  type: typeof FILL_EXERCISE_COMPLETION;
  payload: ExercComplDataType[];
}
export interface FillPatientNotComplExer {
  type: typeof FILL_NOT_COMPLETION_EXERCISE;
  payload: NotComplExercDataType[];
}
export interface SetPerPeriodDataAction {
  type: typeof FILL_EXERCISES_PER_PERIOD;
  payload: ExercPerPeriodType[];
}

export interface LoadingActionType {
  type:
    | typeof EXERC_PER_PERIOD_LOADING
    | typeof STEPS_LOADING
    | typeof EXERCISE_COMPLETION_LOADING
    | typeof NOT_COMPLETION_EXERCISE_LOADING;
  payload: boolean;
}

export interface PatientErrorFetching {
  type:
    | typeof STEPS_ERROR
    | typeof EXERCISE_COMPLETION_ERROR
    | typeof NOT_COMPLETION_EXERCISE_ERROR;
  payload: ErrorHttpAction;
}

export type ActionTypes =
  | PatientFillStep
  | FillPatientExercCompl
  | LoadingActionType
  | PatientErrorFetching
  | SetPerPeriodDataAction
  | FillPatientNotComplExer;

export interface ExercisesType {
  steps: StepsDataType[] | [];
  stepsLoading: boolean;
  stepsError: any;
  exerciseComletion: ExercComplDataType[];
  exerciseComletionLoading: boolean;
  exerciseComletionError: any;
  exercisesPerPeriod: ExercPerPeriodType[];
  exercisesPerPeriodLoading: boolean;
  notCompletedExercises: NotComplExercDataType[];
  notCompletedExercisesLoading: boolean;
  notCompletedExercisesError: any;
}
